<template>
  <div>
     <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
              <div class="idb-block-title">
                <h2><span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span>Email Bounce</h2>
              </div>
              <div class="idb-block-content">
                <table class="mb-3">
                  <tr><td class="pr-5 font-weight-bold">Customer Name</td><td>{{bounceReport ? bounceReport.customerName : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Group Name</td><td>{{bounceReport ? bounceReport.groupName : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Sent Date</td><td>{{bounceReport ? formatDate(bounceReport.sentDate) : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Total</td><td>{{bounceReport ? bounceReport.total : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Sent</td><td>{{bounceReport ? bounceReport.sent : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Bounced</td><td>{{bounceReport ? bounceReport.bounced : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Halted</td><td>{{bounceReport ? bounceReport.halted : 'Loading'}}</td></tr>
                </table>
                <vue-good-table
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  @on-row-click="onRowClick"
                  :columns="columns"
                  :rows="bounceReports"
                  :lineNumbers="true"
                  :totalRows="totalRecords"
                  :search-options="{
                  enabled: true
                  }"
                  :pagination-options="{
                  enabled: true,
                  dropdownAllowAll: false,
                }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="loadingContent">
                    <h1>Loading...</h1>
                  </template>
                  <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">No bounced messages available to send</div>
                  </div>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="reloadItems"
                      class
                      variant="link"
                      title="Refresh Table"
                      v-b-popover.hover.top.d500="'Refresh the data in the table'"
                    >
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="printTable"
                      class
                      variant="link"
                      title="Print Table"
                      v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
                    >
                      <i class="fa fa-print pointer dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="exportTable"
                      class
                      variant="link"
                      title="Export Table"
                      v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
                    >
                      <i class="fa fa-share-square pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <!-- <router-link :to="{ path: '/payer/' + props.row.ukPayerId + '/edit', params: { ukPayerId: props.row.ukPayerId }, query: { retPath: 'manage' }  }"> eslint-disable-line -->
                    <span
                      v-if="props.column.field === 'status'"
                    >{{ displayStatus(props.row.status) }}</span>
                    <span v-else>{{ props.row[props.column.field] }}</span>
                    <!-- </router-link> -->
                  </template>
                </vue-good-table>
              </div>
              <div class="idb-block-footer noprint-footer">
                <b-button variant="primary" v-if="bounceReports.length > 0" @click="getBouncedData">Export Bounced Emails</b-button>
                <b-button variant="success" class="pull-right" v-if="bounceReport && bounceReports.length > 0" :disabled="hasInvalid"  @click="resend">{{ (bounceReport.isHalted ? 'Resume and resend failed messages' : 'Resend failed messages') }}</b-button>
              </div>
          </div>
        </b-col>
     </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import { saveAs } from 'file-saver'
export default {
  props: {
    bounceId: String
  },
  data () {
    return {
      bounceReports: [],
      columns: [
        {
          label: 'id',
          field: 'payerMessageBatchId',
          hidden: true
        },
        {
          label: 'Payer Reference',
          field: 'payerReference'
        },
        {
          label: 'Current Email',
          field: 'email'
        },
        {
          label: 'Bounced Email',
          field: 'bouncedAddress'
        },
        {
          label: 'Sent Date',
          field: 'sentDate',
          formatFn: this.formatDate
        },
        {
          label: 'Current Address Bounced',
          field: 'addressMatch'
        }
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: { },
        sort: {
          field: 'surname',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      hasInvalid: true,
      bounceReport: null
    }
  },
  methods: {
    async getData () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}EmailReporting/${this.bounceId}`)
      if (response !== null) {
        this.bounceReports = response.data.bounces
        this.hasInvalid = response.data.hasInvalidAddresses
        this.bounceReport = response.data.bounceReport
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.reloadItems()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params.sortType,
          field: this.columns[params.columnIndex].field
        }
      })
      this.reloadItems()
    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
      this.updateParams(params)
      this.reloadItems()
    },
    onPerPageChange (params) {
      console.log('onPerPageChange', params)
      this.updateParams({ perPage: params.currentPerPage })
      console.log('perpage', this.perPage)
      this.reloadItems()
    },
    onRowClick (params) {
      this.$router.push(`/collections/payer/${params.row.ukPayerId}/edit`)
    },
    onCellClick (params) {
      this.selectedRow = this.tableData[params.rowIndex]
    },
    onSearch (params) {
      console.log('in search', params)
      this.serverParams.searchKey = params.searchTerm
      this.reloadItems()
    },
    getBouncedData () {
      var outputObj = []
      for (var i = 0; i < this.bounceReports.length; i++) {
        outputObj.push({ ukPayerId: this.bounceReports[i].ukPayerId, reference: this.bounceReports[i].payerReference, email: this.bounceReports[i].email })
      }
      var output = this.convertToCsv(outputObj)
      const blob = new Blob([output], { type: 'text/csv' })
      saveAs(blob, `bounce-${this.bounceId}.csv`)
    },
    convertToCsv (objArray) {
      var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      var str = 'payerid, payerreference, email\r\n'
      for (var i = 0; i < array.length; i++) {
        var line = ''
        for (var index in array[i]) {
          if (line !== '') line += ','
          line += array[i][index]
        }
        str += line + '\r\n'
      }
      return str
    },
    async resend () {
      var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}EmailReporting/${this.bounceId}`)
      if (response) {
        await this.getData()
        this.$toastr.s('This communication has been resumed')
      }
    }
  },
  async mounted () {
    this.getData()
  }
}
</script>
