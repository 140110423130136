<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <help-icon docPath="/administration/customer/licence/" />
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>User Usage</h2>
          </div>
          <div class="idb-block-content">
            <pie-chart :chart-data="charts.user.data" :options="charts.user.options" :height="300"></pie-chart>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Group Usage</h2>
          </div>
          <div class="idb-block-content">
            <pie-chart
              :chart-data="charts.group.data"
              :options="charts.group.options"
              :height="300"
            ></pie-chart>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Service Account Usage</h2>
          </div>
          <div class="idb-block-content">
            <pie-chart
              :chart-data="charts.serviceAccount.data"
              :options="charts.serviceAccount.options"
              :height="300"
            ></pie-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Payments</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>BACS</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.bacs.value == 'Both' || licence.bacs.value == 'Direct')"
                  ></span>
                </div>
                <div class="form-group col-md-6">
                  <label>BACS Online</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.bacs.value == 'Both' || licence.bacs.value == 'Indirect')"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.bacs.value != 'Off'">
                <div
                  class="form-group col-md-6"
                  v-if="licence.bacs.value == 'Both' || licence.bacs.value == 'Direct'"
                >
                  <label>Allow Bank Grade BACS</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.bacs.bankGrade)"></span>
                </div>
                <div
                  class="form-group col-md-6"
                  v-if="licence.bacs.value == 'Both' || licence.bacs.value == 'Indirect'"
                >
                  <label>Allow Multi-Block BACS</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.bacs.proxyGroup)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.bacs.value != 'Off'">
                <div class="form-group col-md-6">
                  <label>BACS API</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.bacs.api)"></span>
                </div>
              </div>
              <hr />
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>FPS</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.fps.value == 'Both' || licence.fps.value == 'Direct')"
                  ></span>
                </div>
                <div class="form-group col-md-6">
                  <label>FPS Online</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.fps.value == 'Both' || licence.fps.value == 'Indirect')"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.fps.value != 'Off'">
                <div
                  class="form-group col-md-6"
                  v-if="licence.fps.value == 'Both' || licence.fps.value == 'Indirect'"
                >
                  <label>Allow Multi-Block FPS</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.bacs.proxyGroup)"
                  ></span>
                </div>
                <div class="form-group col-md-6">
                  <label>FPS API</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.fps.api)"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Automation</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Mapping</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.automation.value)"
                  ></span>
                </div>
                <div class="form-group col-md-6">
                  <label>Mapping Designer</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.automation.mappingDesigner)"
                  ></span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Folder Manager</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.automation.folderManager)"
                  ></span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Workflow</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.automation.workflow)"
                  ></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.automation.workflow">
                  <label>Workflow Designer</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.automation.workflowDesigner)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.automation.workflow">
                <div class="form-group col-md-6" v-if="licence.automation.workflow">
                  <label>Frequency</label>
                  <span class="form-control-plaintext">{{licence.automation.frequency}}</span>
                </div>
                <div class="form-group col-md-6">
                  <label>Live Workflows</label>
                  <span class="form-control-plaintext">{{licence.automation.liveWorkflows}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Bureau</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.bureau.value)"></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.bureau.value">
                  <label>Limit</label>
                  <span class="form-control-plaintext">{{licence.bureau.threshold}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Other</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>SMS Enabled</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.other.sms)"></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.other.sms">
                  <label>SMS Limit</label>
                  <span class="form-control-plaintext">{{licence.other.smsThreshold}}</span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Email Limit</label>
                  <span class="form-control-plaintext">{{licence.other.emailThreshold}}</span>
                </div>
                <div class="form-group col-md-6">
                  <label>Secure Cloud Storage</label>
                  <span class="form-control-plaintext">{{licence.other.storage + 'GB'}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-6">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Validation</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.validate.value)"></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.validate.value">
                  <label>UK Bank Account</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.validate.modCheck)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.validate.value">
                <div class="form-group col-md-6">
                  <label>IBAN</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.validate.iban)"></span>
                </div>
                <div class="form-group col-md-6">
                  <label>Credit Card</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.validate.cc)"></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.validate.value">
                <div class="form-group col-md-6">
                  <label>Limit</label>
                  <span class="form-control-plaintext">{{licence.validate.threshold}}</span>
                </div>
                <div class="form-group col-md-6">
                  <label>API</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.validate.api)"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Agent</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.agent.value)"></span>
                </div>
                <div class="form-group col-md-6">
                  <label>Use Own Certificate</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.agent.useOwnCertificate)"
                  ></span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Allow FTP Address Change</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.agent.allowFtpAddressChange)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Collections</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.collections.value)"
                  ></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.collections.value">
                  <label>API</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.collections.api)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.collections.value">
                <div class="form-group col-md-6">
                  <label>Onboarding</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.collections.onBoarding)"
                  ></span>
                </div>
                <div class="form-group col-md-6">
                  <label>Payer Portal</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.collections.payerPortal)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.collections.value">
                <div class="form-group col-md-6">
                  <label>Payer Limit</label>
                  <span class="form-control-plaintext">{{licence.collections.payerThreshold}}</span>
                </div>
              </div>
              <div class="form-row" v-if="licence.collections.value">
                <div class="form-group col-md-6">
                  <label>Allow Refunds</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.collections.refunds)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Hardware Security Module (HSM)</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.hsm.value)"></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.hsm.value">
                  <label>HSM Limit</label>
                  <span class="form-control-plaintext">{{licence.hsm.hsmThreshold}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Multi-Factor Authentication (MFA)</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.mfa.value)"></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.mfa.value">
                  <label>Authenticator</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.mfa.authenticator)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.mfa.value">
                <div class="form-group col-md-6" v-if="licence.mfa.value">
                  <label>USB Tokens</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.mfa.usbTokens)"></span>
                </div>
                <div class="form-group col-md-6" v-if="licence.mfa.value">
                  <label>Email</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.mfa.emails)"></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.mfa.value && licence.other.sms">
                <div class="form-group col-md-6" v-if="licence.mfa.value">
                  <label>SMS</label>
                  <span class="fa form-control-plaintext" :class="getClass(licence.mfa.sms)"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Anti-Fraud Module</h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Enabled</label>
                  <span
                    class="fa form-control-plaintext"
                    :class="getClass(licence.antiFraud.enabled)"
                  ></span>
                </div>
              </div>
              <div class="form-row" v-if="licence.antiFraud.enabled">
                <div class="form-group col-md-6">
                  <label>Whitelist Limit</label>
                  <span class="form-control-plaintext">{{licence.antiFraud.whitelistThreshold}}</span>
                </div>
                <div class="form-group col-md-6">
                  <label>Blacklist Limit</label>
                  <span class="form-control-plaintext">{{licence.antiFraud.blacklistThreshold}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import randomColor from 'randomcolor'

import PieChart from '@/Assets/Components/Charts/PieChart'

export default {
  name: 'ReadOnlyCustomerLicence',
  components: {
    PieChart
  },
  data () {
    return {
      usersAmount: 0,
      groupsAmount: 0,
      serviceAccountsAmount: 0,
      licence: {
        platform: {
          users: 10,
          groups: 3,
          serviceAccounts: 0
        },
        bacs: {
          value: 'Direct',
          api: false
        },
        fps: {
          value: 'Off',
          api: false
        },
        collections: {
          value: false,
          api: false,
          onBoarding: false,
          payerPortal: false,
          payerThreshold: 10,
          refunds: false
        },
        bureau: {
          value: false,
          api: false,
          threshold: 100
        },
        validate: {
          value: true,
          modCheck: false,
          iban: false,
          cc: false,
          api: false,
          threshold: 20000
        },
        automation: {
          value: true,
          workflow: false,
          mode: 'Managed',
          frequency: 'Hourly',
          liveWorkflows: 5,
          api: false,
          workflowDesigner: false,
          mappingDesigner: false
        },
        agent: {
          value: false,
          useOwnCertificate: false,
          allowFtpAddressChange: false
        },
        hsm: {
          value: false
        },
        mfa: {
          value: true,
          authenticator: true,
          usbTokens: false,
          sms: false,
          emails: false
        },
        other: {
          sms: false,
          smsThreshold: 10000,
          emailThreshold: 10000,
          storage: 15
        },
        antiFraud: {
          enabled: false
        }
      },
      charts: {
        user: {
          data: {},
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
              onClick: function () { }
            }
          }
        },
        group: {
          data: {},
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
              onClick: function () { }
            }
          }
        },
        serviceAccount: {
          data: {},
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
              onClick: function () { }
            }
          }
        }
      }
    }
  },
  methods: {
    async loadLicence () {
      try {
        this.$Progress.start()
        var results = await Promise.allSettled([
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/GroupCount`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/UserCount`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/ServiceAccountCount`)
        ])

        const licenceResponse = results[0].value
        const groupResponse = results[1].value
        const userResponse = results[2].value
        const serviceAccountResponse = results[3].value

        this.licence = licenceResponse.data
        if (licenceResponse.data.error) {
          this.$snapbar.w('There are objects in paygate which are unlicensed')
          this.errors = licenceResponse.data.error.errors
          var container = document.getElementById('base-container')
          container.scrollTop = 0
        }
        this.groupsAmount = groupResponse.data
        this.usersAmount = userResponse.data
        this.serviceAccountsAmount = serviceAccountResponse.data

        this.charts.user.data = {
          labels: ['Used', 'Remaining'],
          datasets: [{
            data: [this.usersAmount, this.licence.platform.users - this.usersAmount],
            backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
          }]
        }
        this.charts.group.data = {
          labels: ['Used', 'Remaining'],
          datasets: [{
            data: [this.groupsAmount, this.licence.platform.groups - this.groupsAmount],
            backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
          }]
        }

        this.charts.serviceAccount.data = {
          labels: ['Used', 'Remaining'],
          datasets: [{
            data: [this.serviceAccountsAmount, this.licence.platform.serviceAccounts - this.serviceAccountsAmount],
            backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
          }]
        }

        this.$Progress.finish()
      } catch {
        this.$Progress.fail()
      }
    },
    getClass (bool) {
      var classObj = {
        'fa-check': false,
        'fa-times': false,
        'text-success': false,
        'text-danger': false
      }

      if (bool) {
        classObj['fa-check'] = true
        classObj['text-success'] = true
      } else {
        classObj['fa-times'] = true
        classObj['text-danger'] = true
      }
      return classObj
    }
  },
  async created () {
    await this.loadLicence()
  }
}
</script>

<style>
</style>
