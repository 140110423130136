var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "input" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.label))]),
          this.isRequired
            ? _c("span", { staticClass: "required" }, [_vm._v("*")])
            : _vm._e(),
          _c("input", {
            staticClass: "form-control input-sm",
            class: { invalid: _vm.$v.value.$error },
            attrs: {
              type: "password",
              name: _vm.name,
              placeholder: _vm.placeholder
            },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                _vm.$emit("input", $event.target.value)
                _vm.$v.value.$touch()
              }
            }
          }),
          !_vm.$v.value.required
            ? _c("p", { staticClass: "validationText" }, [
                _vm._v("Required Field")
              ])
            : _vm._e(),
          !_vm.$v.value.maxLength
            ? _c("p", { staticClass: "validationText" }, [
                _vm._v("Max Length is " + _vm._s(this.maxLength))
              ])
            : _vm._e(),
          !_vm.$v.value.minLength
            ? _c("p", { staticClass: "validationText" }, [
                _vm._v("Min Length is " + _vm._s(this.minLength))
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }