var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "paymentNetwork" } }, [
    _c("div", { staticClass: "row form-group" }, [
      _c("div", { staticClass: "col-md-2" }, [_vm._v("Network")]),
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c("b-select", {
            attrs: { options: _vm.networkTypes },
            on: { change: _vm.getEngineConfig },
            model: {
              value: _vm.selectedNetworkType,
              callback: function($$v) {
                _vm.selectedNetworkType =
                  typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "selectedNetworkType"
            }
          })
        ],
        1
      )
    ]),
    _vm.paymentNetworkLoaded
      ? _c("div", [
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v("Live URL")
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-4",
                class: { invalid: _vm.$v.engineConfig.baseLiveUrl.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.engineConfig.baseLiveUrl.$model,
                      expression: "$v.engineConfig.baseLiveUrl.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.engineConfig.baseLiveUrl.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.engineConfig.baseLiveUrl,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "Live URL" },
                  model: {
                    value: _vm.$v.engineConfig.baseLiveUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.engineConfig, "baseLiveUrl", $$v)
                    },
                    expression: "$v.engineConfig.baseLiveUrl"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col-md-1" }),
            _c("div", { staticClass: "col-md-2" }, [_vm._v("Live Sub URL")]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.engineConfig.subLiveUrl,
                    expression: "engineConfig.subLiveUrl",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.engineConfig.subLiveUrl },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.engineConfig,
                      "subLiveUrl",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v("Test URL")
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-4",
                class: { invalid: _vm.$v.engineConfig.baseTestUrl.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.engineConfig.baseTestUrl.$model,
                      expression: "$v.engineConfig.baseTestUrl.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.engineConfig.baseTestUrl.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.engineConfig.baseTestUrl,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "Test URL" },
                  model: {
                    value: _vm.$v.engineConfig.baseTestUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.engineConfig, "baseTestUrl", $$v)
                    },
                    expression: "$v.engineConfig.baseTestUrl"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col-md-1" }),
            _c("div", { staticClass: "col-md-2" }, [_vm._v("Test Sub URL")]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.engineConfig.subTestUrl,
                    expression: "engineConfig.subTestUrl",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.engineConfig.subTestUrl },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.engineConfig,
                      "subTestUrl",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v("Live Application ID")
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-4",
                class: { invalid: _vm.$v.engineConfig.liveApplicationId.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.engineConfig.liveApplicationId.$model,
                      expression: "$v.engineConfig.liveApplicationId.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: {
                    value: _vm.$v.engineConfig.liveApplicationId.$model
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.engineConfig.liveApplicationId,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "Live application ID" },
                  model: {
                    value: _vm.$v.engineConfig.liveApplicationId,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.engineConfig, "liveApplicationId", $$v)
                    },
                    expression: "$v.engineConfig.liveApplicationId"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v("Test Application ID")
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-4",
                class: { invalid: _vm.$v.engineConfig.testApplicationId.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.engineConfig.testApplicationId.$model,
                      expression: "$v.engineConfig.testApplicationId.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: {
                    value: _vm.$v.engineConfig.testApplicationId.$model
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.engineConfig.testApplicationId,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "Test application ID" },
                  model: {
                    value: _vm.$v.engineConfig.testApplicationId,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.engineConfig, "testApplicationId", $$v)
                    },
                    expression: "$v.engineConfig.testApplicationId"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2" }, [_vm._v("Use Live URL")]),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("p-check", {
                  staticClass: "p-switch p-fill",
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.engineConfig.useLiveUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.engineConfig, "useLiveUrl", $$v)
                    },
                    expression: "engineConfig.useLiveUrl"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v("Single Payment Limit (£)")
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-4",
                class: {
                  invalid: _vm.$v.engineConfig.individualPaymentLimit.$error
                }
              },
              [
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "form-control",
                      model: {
                        value:
                          _vm.$v.engineConfig.individualPaymentLimit.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.engineConfig.individualPaymentLimit,
                            "$model",
                            $$v
                          )
                        },
                        expression:
                          "$v.engineConfig.individualPaymentLimit.$model"
                      }
                    },
                    "money",
                    _vm.money,
                    false
                  )
                )
              ],
              1
            ),
            _vm.$v.engineConfig.individualPaymentLimit.$dirty
              ? _c("span", [
                  !_vm.$v.engineConfig.individualPaymentLimit.required
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [_vm._v("Single Payment Limit is required")]
                      )
                    : _vm._e(),
                  !_vm.$v.engineConfig.individualPaymentLimit.min
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [_vm._v("Single Payment Limit must be greater than 0")]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _vm.selectedNetworkType === "BacstelIp"
            ? _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-2 required" }, [
                  _vm._v("Government Single Payment Limit (£)")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4",
                    class: {
                      invalid:
                        _vm.$v.engineConfig.govIndividualPaymentLimit.$error
                    }
                  },
                  [
                    _c(
                      "money",
                      _vm._b(
                        {
                          staticClass: "form-control",
                          model: {
                            value:
                              _vm.$v.engineConfig.govIndividualPaymentLimit
                                .$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.engineConfig.govIndividualPaymentLimit,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.engineConfig.govIndividualPaymentLimit.$model"
                          }
                        },
                        "money",
                        _vm.money,
                        false
                      )
                    )
                  ],
                  1
                ),
                _vm.$v.engineConfig.govIndividualPaymentLimit.$dirty &&
                _vm.selectedNetworkType === "BacstelIp"
                  ? _c("span", [
                      !_vm.$v.engineConfig.govIndividualPaymentLimit.minValue &&
                      _vm.selectedNetworkType === "BacstelIp"
                        ? _c(
                            "small",
                            {
                              staticClass:
                                "form-text text-danger small text-nowrap"
                            },
                            [
                              _vm._v(
                                "Government Single Payment Limit must be greater than 0"
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v("Max Items/Submission")
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-4",
                class: {
                  invalid: _vm.$v.engineConfig.maxRecordsPerSubmission.$error
                }
              },
              [
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "form-control",
                      model: {
                        value:
                          _vm.$v.engineConfig.maxRecordsPerSubmission.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.engineConfig.maxRecordsPerSubmission,
                            "$model",
                            $$v
                          )
                        },
                        expression:
                          "$v.engineConfig.maxRecordsPerSubmission.$model"
                      }
                    },
                    "money",
                    _vm.money,
                    false
                  )
                )
              ],
              1
            ),
            _vm.$v.engineConfig.maxRecordsPerSubmission.$dirty
              ? _c("span", [
                  !_vm.$v.engineConfig.maxRecordsPerSubmission.required
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [_vm._v("Max Items/Submission is required")]
                      )
                    : _vm._e(),
                  !_vm.$v.engineConfig.maxRecordsPerSubmission.min
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [_vm._v("Max Items/Submission must be greater than 0")]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c("br"),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.savePaymentNetworkSettings }
                },
                [_vm._v("Save Payment Network Settings")]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }