<template>
  <div>
    <select v-model="selectedPaygateId" class="form-control custom-select">
      <option
        v-for="customer in customers"
        :key="customer.paygateId"
        :value="customer.paygateId"
      >{{ customer.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  computed: {
    selectedPaygateId: {
      get: function () {
        return this.$store.state.common.paygateId
      },
      set: function (paygateId) {
        try {
          this.$store.dispatch('setPaygateId', paygateId)
          this.$store.commit('clearTableFilters')
        } catch (error) {
          console.error(error)
        }
      }
    },
    customers () {
      return this.$store.getters.customersOrdered
    }
  }
}
</script>
