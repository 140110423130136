var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showMetaDataPopup
        ? _c(
            "mappingMetaDataPopup",
            {
              on: {
                close: function($event) {
                  _vm.showMetaDataPopup = false
                  _vm.closeMetaDataPopup(true)
                },
                cancel: function($event) {
                  _vm.showMetaDataPopup = false
                  _vm.closeMetaDataPopup(false)
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Mapping Metadata")
              ])
            ]
          )
        : _vm._e(),
      _vm.showModal
        ? _c(
            "modal",
            {
              on: {
                close: function($event) {
                  _vm.showModal = false
                  _vm.closeCfgPopup(true)
                },
                cancel: function($event) {
                  _vm.showModal = false
                  _vm.closeCfgPopup(false)
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Node Configuration")
              ])
            ]
          )
        : _vm._e(),
      _vm.showFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showFileBrowser = false
                _vm.closeFilePopup()
              }
            }
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal-validation",
          attrs: {
            scrollable: "",
            centered: "",
            size: "huge",
            "hide-footer": "",
            title: "Mapping Validation"
          },
          on: { hidden: _vm.cancelValidationModal }
        },
        [
          _c(
            "div",
            [
              _c("vue-good-table", {
                attrs: {
                  columns: _vm.validation_columns,
                  rows: _vm.validationResponse,
                  lineNumbers: true,
                  "search-options": { enabled: true },
                  "pagination-options": {
                    enabled: true,
                    perPage: 10,
                    perPageDropdown: [10, 50, 100],
                    dropdownAllowAll: true,
                    rowsPerPageLabel: "Messages per page"
                  },
                  styleClass: "vgt-table striped bordered table-hover"
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field === "level"
                          ? _c(
                              "span",
                              [
                                props.row.level == "Invalid"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "danger" } },
                                      [_vm._v(_vm._s(props.row.level))]
                                    )
                                  : props.row.level === "Warning"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "warning" } },
                                      [_vm._v(_vm._s(props.row.level))]
                                    )
                                  : props.row.level === "Info"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "info" } },
                                      [_vm._v(_vm._s(props.row.level))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(props.row.level))
                                    ])
                              ],
                              1
                            )
                          : props.column.field === "createdAt"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reformatDate(
                                      props.formattedRow[props.column.field]
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : props.column.field === "updatedAt"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reformatDate(
                                      props.formattedRow[props.column.field]
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : props.column.field === "buttons"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value:
                                          "Drill down into this workflow event.",
                                        expression:
                                          "'Drill down into this workflow event.'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    staticClass: "pointer",
                                    attrs: {
                                      variant: "link",
                                      title: "View Workflow Event"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.viewWorkflowEvent(props)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-secondary" },
                                      [
                                        _c("i", {
                                          staticClass: "glyphicon ti-list"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "primary" },
              on: { click: _vm.hideValidationModal }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            scrollable: "",
            centered: "",
            size: "huge",
            "hide-footer": "",
            title: _vm.executionStatus
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3" } },
                [
                  _c("b-tab", { attrs: { title: "Mapped Data", active: "" } }, [
                    _c(
                      "div",
                      { staticClass: "sandboxtable" },
                      [
                        _c("vue-good-table", {
                          ref: "vgtmappedData",
                          attrs: {
                            columns: _vm.data_columns,
                            rows: _vm.rows,
                            "pagination-options": {
                              enabled: true,
                              perPage: 10,
                              perPageDropdown: [10, 50, 100],
                              dropdownAllowAll: false,
                              rowsPerPageLabel: "items per page"
                            },
                            lineNumbers: false,
                            "search-options": { enabled: true },
                            styleClass: "vgt-table striped bordered table-hover"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "11pt" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "table-column",
                              fn: function(props) {
                                return [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "11pt" } },
                                    [_vm._v(_vm._s(props.column.label))]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-tab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _vm._v(" Logs "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "ml-2",
                              attrs: { pill: "", variant: "primary" }
                            },
                            [_vm._v(_vm._s(_vm.sandboxData.numMessages))]
                          )
                        ],
                        1
                      ),
                      _c("vue-good-table", {
                        attrs: {
                          columns: _vm.log_columns,
                          rows: _vm.log_rows,
                          lineNumbers: true,
                          "search-options": { enabled: true },
                          "pagination-options": {
                            enabled: true,
                            perPage: 10,
                            perPageDropdown: [10, 50, 100],
                            dropdownAllowAll: true,
                            rowsPerPageLabel: "Logs per page"
                          },
                          styleClass: "vgt-table striped bordered table-hover"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "datetime"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reformatDate(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          )
                                        )
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "primary" },
              on: { click: _vm.hideExecutionModal }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { ref: "blockwidth", staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" Mapping Designer "),
                  _vm.mappingMetaData.title !== ""
                    ? _c("span", [_vm._v(":")])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.mappingMetaData.title) + " "),
                  _c("span", { staticClass: "pull-right" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            _vm.docUrl +
                            "/automation/paygate-mapping/mapping-designer/",
                          target: "_blank"
                        }
                      },
                      [_c("i", { staticClass: "far fa-question-circle" })]
                    )
                  ]),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.ie11
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-warning",
                        attrs: { role: "alert" }
                      },
                      [
                        _vm._m(0),
                        _c("p", [
                          _vm._v(
                            "Some features of the Mapping Designer may not work correctly on very old, unmaintained browsers such as Internet Explorer 11. Please consider using a modern browser such as Microsoft Edge, Google Chrome or Mozilla Firefox. "
                          )
                        ]),
                        _c("hr"),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(" Follow this "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.docUrl + "/troubleshooting/ie11/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("link")]
                          ),
                          _vm._v(" for more details.")
                        ])
                      ]
                    )
                  : _vm._e(),
                !_vm.hideUI && _vm.isLicenced
                  ? _c(
                      "b-navbar",
                      {
                        ref: "navbarref",
                        attrs: { toggleable: "md", variant: "light" }
                      },
                      [
                        _c("b-navbar-toggle", {
                          attrs: { target: "nav_collapse" }
                        }),
                        _c(
                          "b-collapse",
                          { attrs: { "is-nav": "", id: "nav_collapse" } },
                          [
                            _c(
                              "b-navbar-nav",
                              [
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "File"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuNewMapping($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-file mr-2 text-secondary"
                                        }),
                                        _vm._v("New")
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuLoadMapping($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-file-import mr-2 text-secondary"
                                        }),
                                        _vm._v("Load")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuSaveMapping($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-save mr-2 text-secondary"
                                        }),
                                        _vm._v("Save")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuSaveAsMapping($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-save mr-2 text-secondary"
                                        }),
                                        _vm._v("Save As")
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "Edit"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuUndo($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-undo font-lite mr-2 text-secondary"
                                          }),
                                          _vm._v("Undo")
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-sml text-muted"
                                          },
                                          [_vm._v("Ctrl+Z")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuRedo($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-redo font-lite mr-2 text-secondary"
                                          }),
                                          _vm._v("Redo")
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-muted"
                                          },
                                          [_vm._v("Ctrl+Y")]
                                        )
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      { on: { click: _vm.doCut } },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-cut font-lite mr-2 text-secondary"
                                          }),
                                          _vm._v("Cut")
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-muted"
                                          },
                                          [_vm._v("Ctrl+X")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      { on: { click: _vm.doCopy } },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-copy font-lite mr-2 text-secondary"
                                          }),
                                          _vm._v("Copy")
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-muted"
                                          },
                                          [_vm._v("Ctrl+C")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      { on: { click: _vm.doPaste } },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-paste font-lite mr-2 text-secondary"
                                          }),
                                          _vm._v("Paste")
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-muted"
                                          },
                                          [_vm._v("Ctrl+V")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "Mapping"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuMoveOrigin($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-map-marker font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Move to Origin")
                                      ]
                                    ),
                                    _vm.id
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.menuMappingDetail(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-list font-lite mr-2 text-secondary"
                                            }),
                                            _vm._v("Mapping Detail")
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuMetaData($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-database font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Mapping Metadata")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuDesignerSettings(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-cog font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Designer Settings")
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuClearCurrentDiagram(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-eraser font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Clear")
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuMappingAutomap(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-link font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Auto-map")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuMappingValidate(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-check font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Validate Mapping")
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "Nodes"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuAddImporter($event)
                                          }
                                        }
                                      },
                                      [_vm._v("Importer")]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-header",
                                      { attrs: { id: "header1" } },
                                      [_vm._v("Operators")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: { click: _vm.menuStringOperator }
                                      },
                                      [_vm._v("String Operators")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: { click: _vm.menuDateOperator }
                                      },
                                      [_vm._v("Date/Time Operators")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: { click: _vm.menuNumberOperator }
                                      },
                                      [_vm._v("Number Operators")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: { click: _vm.menuDataOperator }
                                      },
                                      [_vm._v("Data Operators")]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-header",
                                      { attrs: { id: "header1" } },
                                      [_vm._v("Payment Connectors")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.menuBacsConnector()
                                          }
                                        }
                                      },
                                      [_vm._v("BACS / Faster Payments")]
                                    )
                                  ],
                                  1
                                ),
                                _c("b-nav-item", { staticClass: "p-6" }),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Undo",
                                        expression: "'Undo'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      href: "#"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuUndo($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-undo text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Redo",
                                        expression: "'Redo'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      href: "#"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuRedo($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-redo text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Validate Mapping",
                                        expression: "'Validate Mapping'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.isLoading,
                                      id: "validate"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuMappingValidate($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-vial text-secondary",
                                      class: {
                                        // 'text-danger': validationlevel === 'Invalid',
                                        // 'text-warning': validationlevel === 'Warning',
                                        // 'text-info': validationlevel === 'Info',
                                        // 'text-success': validationlevel === 'Valid'
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Test the Mapping",
                                        expression: "'Test the Mapping'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      href: "#"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuExecuteMapping($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-play text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Remove Selected",
                                        expression: "'Remove Selected'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      href: "#",
                                      id: "remove"
                                    },
                                    on: { click: _vm.menuRemoveItem }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-times text-secondary"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-navbar-nav",
                              { staticClass: "ml-auto" },
                              [
                                _c("b-nav-form", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Override importer path",
                                        expression: "'Override importer path'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedFilePath,
                                        expression: "selectedFilePath"
                                      }
                                    ],
                                    staticClass:
                                      "form-control form-control-sm mr-2",
                                    attrs: {
                                      placeholder: "Override importer path",
                                      size: "45",
                                      type: "text"
                                    },
                                    domProps: { value: _vm.selectedFilePath },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.selectedFilePath =
                                          $event.target.value
                                      }
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value: "Open file browser",
                                          expression: "'Open file browser'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      staticClass:
                                        "btn btn-outline-secondary btn-sm",
                                      attrs: { disabled: _vm.isLoading },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.btnBrowseFile($event)
                                        }
                                      }
                                    },
                                    [_vm._v("...")]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.hideUI && _vm.isLicenced
                  ? _c("canvas", {
                      ref: "imagecanvas",
                      staticStyle: {
                        "background-color": "#ffffff",
                        display: "block"
                      },
                      attrs: {
                        id: "image-canvas",
                        width: _vm.canvasWidth,
                        height: _vm.canvasHeight
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "alert-heading" }, [
      _c("i", { staticClass: "fas fa-exclamation-triangle mr-2" }),
      _vm._v("Internet Explorer 11 Detected")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }