var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "viewSubmission" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.action.id !== undefined,
            expression: "action.id !== undefined"
          }
        ]
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.action.id !== "00000000-0000-0000-0000-000000000000",
                expression:
                  "action.id !== '00000000-0000-0000-0000-000000000000'"
              }
            ],
            attrs: { id: "actionDetails" }
          },
          [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [
                _vm._v(" " + _vm._s(this.action.title) + " "),
                _c("span", { staticClass: "pull-right" }, [
                  _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
                    _c("i", { staticClass: "far fa-question-circle" })
                  ])
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c("b-col", { attrs: { sm: "8" } }, [
              _vm.action.id !== "00000000-0000-0000-0000-000000000000"
                ? _c("div", [
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("strong", [_vm._v("Message")])
                      ]),
                      _c("div", { staticClass: "col-md-10" }, [
                        _vm._v(_vm._s(this.action.message))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.validationWarnings
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("strong", [_vm._v("Validation Messages")])
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "span",
                        { staticClass: "badge badge-warning badge-pill" },
                        [
                          _vm._v(
                            "Contains " +
                              _vm._s(this.numberOfWarnings) +
                              " " +
                              _vm._s(this.validationText)
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("strong", [_vm._v("Submission Reference")])
                ]),
                _c("div", { staticClass: "col-md-10" }, [
                  _vm._v(_vm._s(this.bacsSubmission.reference))
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("strong", [_vm._v("Group Name")])
                ]),
                _c("div", { staticClass: "col-md-10" }, [
                  _vm._v(_vm._s(this.groupName))
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("strong", [_vm._v("Submission File")])
                ]),
                _c("div", { staticClass: "col-md-10" }, [
                  _vm._v(_vm._s(this.importedFilename))
                ])
              ]),
              this.isMultiFile === true
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("b", [_vm._v("Select File")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-2" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              text: _vm.selectedFile,
                              split: "",
                              variant: "outline-secondary"
                            }
                          },
                          _vm._l(_vm.intelligibleSummaries, function(summary) {
                            return _c(
                              "b-dropdown-item",
                              {
                                key: summary.fileNumber,
                                attrs: { value: summary.fileNumber },
                                on: {
                                  click: function($event) {
                                    return _vm.selectSummary(summary)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(summary.vocalinkSubId))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]),
            _vm.isLoaded
              ? _c(
                  "b-tabs",
                  {
                    attrs: { lazy: "" },
                    on: { input: _vm.tabSelected },
                    model: {
                      value: _vm.tabIndex,
                      callback: function($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex"
                    }
                  },
                  [
                    _vm.bacsSubmission.paygateType === "Bureau"
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Bureau Summary" } },
                          [_c("bureauSummary")],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-tab",
                      { attrs: { title: "Summary", active: "" } },
                      [_c("intelligibleSummary")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Payments" } },
                      [_c("payments")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Standard18" } },
                      [_c("standard18")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Submission History" } },
                      [_c("submissionHistory")],
                      1
                    ),
                    _vm.validationWarnings === true
                      ? _c(
                          "b-tab",
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(" Validation Messages "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge badge-warning badge-pill"
                                },
                                [_vm._v(_vm._s(this.numberOfWarnings))]
                              )
                            ]),
                            _c("validationWarnings")
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }