var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Users "),
            _c("help-icon", { attrs: { docPath: "/administration/users/" } }),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                isLoading: _vm.isTableLoading,
                rows: _vm.rows,
                columns: _vm.columns,
                lineNumbers: true,
                mode: "remote",
                totalRows: _vm.totalRecords,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                },
                "on-row-click": _vm.onRowClick,
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "enabled"
                        ? _c(
                            "span",
                            [
                              _c("tick", {
                                model: {
                                  value: props.row.enabled,
                                  callback: function($$v) {
                                    _vm.$set(props.row, "enabled", $$v)
                                  },
                                  expression: "props.row.enabled"
                                }
                              })
                            ],
                            1
                          )
                        : props.column.field == "lockoutEnd"
                        ? _c("span", [
                            new Date(props.row.lockoutEnd) < new Date()
                              ? _c("span")
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    )
                                  )
                                ])
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Create a new user",
                          expression: "'Create a new user'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createUser($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-user-plus dimmedIcon pointer"
                      })
                    ]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.createUser }
          },
          [
            _c("i", { staticClass: "fa fa-user-plus rpad" }),
            _vm._v("Create User ")
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }