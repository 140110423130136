<template>
  <v-select
    label="name"
    :value="value"
    :reduce="group => group.groupId"
    @input="emitInput"
    :options="internalGroups"
    :clearable="clearable"
    v-if="internalGroups"
    :disabled="disabled"
  >
    <template v-slot:selected-option="option">
      <div class="group-colour" :style="{'background-color':getOptionColour(option)}"></div>
      <span>{{getGroupName(option)}}</span>
    </template>
    <template v-slot:option="option">
      <div class="group-colour" :style="{'background-color':option.colour}"></div>
      {{ option.name }}
    </template>
    <template v-slot:no-options="{search, searching}">
      <template v-if="groups == null">
        <i class="fas fa-spinner fa-pulse"></i>
        Groups are loading
      </template>
      <template v-else-if="searching">
        No results found for
        <em>{{ search }}</em>.
      </template>
      <em style="opacity: 0.5;" v-else>Sorry, no groups available</em>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
  props: {
    value: {
    },
    clearable: {
      type: Boolean,
      default: true
    },
    groups: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalGroups () {
      if (!this.groups) {
        return []
      }
      return this.groups
    }
  },
  components: {
    vSelect
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },
    getGroupName (option) {
      const object = this.groups.find(g => g.groupId === option.groupId)

      if (object !== undefined) {
        return object.name
      }

      return ''
    },
    getOptionColour (option) {
      const object = this.groups.find(g => g.groupId === option.groupId)

      if (object !== undefined) {
        return object.colour
      }
      return ''
    }
  },
  watch: {
    // If it's not in the list of groups set it to null
    value (val) {
      if (val !== null) {
        if (this.groups.some(() => true) && !this.groups.some(group => group.groupId === val)) {
          this.$emit('input', null)
        }
      }
    }
  }

}
</script>
