<template>
  <div>
    <div class="form-group" :class="[{'invalid': $v.value.password.$error},formGroupClass]">
      <!-- Label -->
      <label :class="labelClass" class="required">Password</label>
      <!-- Input -->
      <div :class="inputClass">
        <input
          v-model="$v.value.password.$model"
          class="form-control"
          type="password"
          autocomplete="new-password"
          @input="updatePasswords()"
        />
        <!-- Validation Messages -->
        <span v-if="$v.value.password.$dirty">
          <small
            class="form-text text-danger small"
            v-if="!$v.value.password.required"
          >A password is required</small>
          <small
            class="form-text text-danger small"
            v-if="$v.value.password.minLength != undefined && !$v.value.password.minLength"
          >The password has to have a minimum length of {{passwordComplexity.minLength}}</small>
          <small
            class="form-text text-danger small"
            v-if="$v.value.password.maxLength != undefined &&!$v.value.password.maxLength"
          >The password has to have a maximum length of {{passwordComplexity.maxLength}}</small>
          <small
            class="form-text text-danger small"
            v-if="$v.value.password.numbers != undefined &&!$v.value.password.numbers"
          >The password has to have at least {{passwordComplexity.numNumbers}} numeric characters</small>
          <small
            class="form-text text-danger small"
            v-if="$v.value.password.uppercase != undefined &&!$v.value.password.uppercase"
          >The password has to have at least {{passwordComplexity.numUppercase}} upper case letters</small>
          <small
            class="form-text text-danger small"
            v-if="$v.value.password.nonAlphaNumeric != undefined &&!$v.value.password.nonAlphaNumeric"
          >The password has to have at least {{passwordComplexity.numNonAlphaNumerics}} non-alphanumeric characters</small>
          <small
            class="form-text text-danger small"
            v-if="$v.value.password.regex != undefined && !$v.value.password.regex"
          >{{passwordComplexity.regExFailMessage}}</small>
        </span>
      </div>
    </div>
    <div class="form-group" :class="[{'invalid': $v.value.confirmPassword.$error},formGroupClass]">
      <!-- Label -->
      <label :class="labelClass" class="required">Confirm Password</label>
      <!-- Input -->
      <div :class="inputClass">
        <input
          v-model="$v.value.confirmPassword.$model"
          class="form-control"
          type="password"
          @input="updatePasswords()"
          autocomplete="new-password"
        />
        <!-- Validation Messages -->
        <span v-if="$v.value.confirmPassword.$dirty">
          <small
            class="form-text text-danger small"
            v-if="!$v.value.confirmPassword.required"
          >You must confirm your password</small>
          <small
            class="form-text text-danger small"
            v-if="!$v.value.confirmPassword.sameAsPassword"
          >Your passwords must match</small>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { required, sameAs, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
import { numberCount, upperCaseCount, nonAlphaNumericCount } from '@/Assets/Validators'

export default {
  name: 'changePasswordInputs',
  props: {
    userId: {
      type: String
    },
    value: {
      type: Object,
      required: true
    },
    defaultPasswordComplexity: {
      type: Object
    },
    formGroupClass: {
      type: String,
      default: 'col-md-5 col-sm-10'
    },
    inputClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'label-control'
    }
  },
  data () {
    return {
      passwordComplexity: null
    }
  },
  methods: {
    updatePasswords () {
      this.$emit('input', {
        password: this.value.password,
        confirmPassword: this.value.confirmPassword,
        valid: !this.$v.$invalid,
        $v: this.$v
      })
    }
  },
  async created () {
    if (this.defaultPasswordComplexity == null) {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}User/UserPasswordComplexity`, { showerror: true, errormessage: 'Failed to get Password Complexity' })
        this.passwordComplexity = response.data
      } catch {
      }
    } else {
      this.passwordComplexity = this.defaultPasswordComplexity
    }

    this.updatePasswords()
  },
  validations () {
    if (this.passwordComplexity && !this.passwordComplexity.excludedUsers.some((u) => u.id === this.userId)) {
      switch (this.passwordComplexity.complexityOption) {
        case 'Default':
          var baseValidations = {
            value: {
              password: { required },
              confirmPassword: { required, sameAsPassword: sameAs('password') }
            }
          }

          if (this.passwordComplexity.enforceMinLength) {
            baseValidations.value.password.minLength = minLength(this.passwordComplexity.minLength)
          }

          if (this.passwordComplexity.enforceMaxLength) {
            baseValidations.value.password.maxLength = maxLength(this.passwordComplexity.maxLength)
          }

          if (this.passwordComplexity.enforceNumbers) {
            baseValidations.value.password.numbers = numberCount(this.passwordComplexity.numNumbers)
          }

          if (this.passwordComplexity.enforceUppercase) {
            baseValidations.value.password.uppercase = upperCaseCount(this.passwordComplexity.numUppercase)
          }

          if (this.passwordComplexity.enforceNonAlphaNumeric) {
            baseValidations.value.password.nonAlphaNumeric = nonAlphaNumericCount(this.passwordComplexity.numNonAlphaNumerics)
          }

          return baseValidations
        case 'Regex': {
          const alpha = helpers.regex('alpha', new RegExp(this.passwordComplexity.regularExpression))
          return {
            value: {
              password: { required, regex: alpha },
              confirmPassword: { required, sameAsPassword: sameAs('password') }
            }
          }
        }
      }
    }
    return {
      value: {
        password: { required },
        confirmPassword: { required, sameAsPassword: sameAs('password') }
      }
    }
  }
}
</script>

<style>
</style>
