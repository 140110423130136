var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.serviceUserNumber.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3 required" }, [
            _vm._v("Service User Number")
          ]),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.serviceUserNumber.$model,
                    expression: "details.serviceUserNumber.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.details.serviceUserNumber.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.serviceUserNumber,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: { name: "service user number" },
                  model: {
                    value: _vm.details.serviceUserNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "serviceUserNumber", $$v)
                    },
                    expression: "details.serviceUserNumber"
                  }
                },
                [
                  _vm.details.serviceUserNumber.sunValidator != undefined &&
                  !_vm.details.serviceUserNumber.sunValidator
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v("Incorrect sun format")
                      ])
                    : _vm._e()
                ]
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "The format for a Service User Number is 6 numbers or a B followed by 5 numbers."
                )
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.importSchema.$error }
        },
        [
          _c("label", { staticClass: "label-control col-md-3 required" }, [
            _vm._v("Import Schema")
          ]),
          _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("b-form-select", {
                attrs: { options: _vm.importSchemas },
                model: {
                  value: _vm.details.importSchema.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.importSchema, "$model", $$v)
                  },
                  expression: "details.importSchema.$model"
                }
              }),
              _c("validation-messages", {
                attrs: { name: "import schema" },
                model: {
                  value: _vm.details.importSchema,
                  callback: function($$v) {
                    _vm.$set(_vm.details, "importSchema", $$v)
                  },
                  expression: "details.importSchema"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.details.importSchema.$model === "Mapping"
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "label-control col-md-3" }, [
              _vm._v("Mapping")
            ]),
            _c(
              "div",
              { staticClass: "col-md-5" },
              [
                _c("b-form-select", {
                  attrs: { options: _vm.mappings },
                  model: {
                    value: _vm.details.mappingId.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.details.mappingId, "$model", $$v)
                    },
                    expression: "details.mappingId.$model"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("bank-account-select", {
        attrs: { bankAccounts: _vm.bankAccounts },
        model: {
          value: _vm.details.bankAccountId,
          callback: function($$v) {
            _vm.$set(_vm.details, "bankAccountId", $$v)
          },
          expression: "details.bankAccountId"
        }
      }),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.tokenType.$error }
        },
        [
          _c("label", { staticClass: "label-control col-md-3 required" }, [
            _vm._v("Payment Signing Method")
          ]),
          _vm.hasHsm
            ? _c(
                "div",
                { staticClass: "col-md-5" },
                [
                  _c(
                    "p-radio",
                    {
                      staticClass: "p-default p-round",
                      attrs: { value: "Hsm", disabled: _vm.disabled },
                      model: {
                        value: _vm.details.tokenType.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.details.tokenType, "$model", $$v)
                        },
                        expression: "details.tokenType.$model"
                      }
                    },
                    [_vm._v("HSM")]
                  ),
                  _c(
                    "p-radio",
                    {
                      staticClass: "p-default p-round",
                      attrs: { value: "SmartCard", disabled: _vm.disabled },
                      model: {
                        value: _vm.details.tokenType.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.details.tokenType, "$model", $$v)
                        },
                        expression: "details.tokenType.$model"
                      }
                    },
                    [_vm._v("Smart Card")]
                  ),
                  _c("validation-messages", {
                    attrs: { name: "payment signing method" },
                    model: {
                      value: _vm.details.tokenType,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "tokenType", $$v)
                      },
                      expression: "details.tokenType"
                    }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "col-md-5" }, [_vm._v("Smart Card")])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.details.tokenType.$model == "Hsm",
              expression: "details.tokenType.$model == 'Hsm'"
            }
          ],
          staticClass: "form-group row",
          class: {
            invalid: _vm.details.certificateId.$error,
            warn: !_vm.selectedHSMCert.valid
          }
        },
        [
          _c("label", { staticClass: "label-control col-md-3 required" }, [
            _vm._v("HSM Certificate")
          ]),
          _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("b-form-select", {
                attrs: { options: _vm.hsmCertificates },
                model: {
                  value: _vm.details.certificateId.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.certificateId, "$model", $$v)
                  },
                  expression: "details.certificateId.$model"
                }
              }),
              _c("validation-messages", {
                attrs: { name: "HSM certificates" },
                model: {
                  value: _vm.details.certificateId,
                  callback: function($$v) {
                    _vm.$set(_vm.details, "certificateId", $$v)
                  },
                  expression: "details.certificateId"
                }
              }),
              !_vm.selectedHSMCert.valid
                ? _c("span", { staticClass: "validation-messages" }, [
                    _c("small", { staticClass: "form-text small" }, [
                      _vm._v("Certificate is expired")
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.details.tokenType.$model === "Hsm",
              expression: "details.tokenType.$model === 'Hsm'"
            }
          ],
          staticClass: "form-group row"
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("HSM PIN Required")
          ]),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("p-check", {
                staticClass: "p-switch p-fill",
                attrs: { color: "primary", disabled: _vm.disabled },
                model: {
                  value: _vm.details.hsmPinRequired.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.hsmPinRequired, "$model", $$v)
                  },
                  expression: "details.hsmPinRequired.$model"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.paymentLimit.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("Individual Payment Limit (£)")
          ]),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.paymentLimit.$model,
                    expression: "details.paymentLimit.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.details.paymentLimit.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.paymentLimit,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: { name: "Individual payment limit" },
                  model: {
                    value: _vm.details.paymentLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "paymentLimit", $$v)
                    },
                    expression: "details.paymentLimit"
                  }
                },
                [
                  _vm.details.paymentLimit.wholepounds != undefined &&
                  !_vm.details.paymentLimit.wholepounds
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v(
                          "individual item limit can only be in whole pounds"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v("Set to 0 for no limit")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.submissionLimit.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("Submission Limit (£)")
          ]),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.submissionLimit.$model,
                    expression: "details.submissionLimit.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.details.submissionLimit.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.submissionLimit,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: { name: "Submission payment limit" },
                  model: {
                    value: _vm.details.submissionLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "submissionLimit", $$v)
                    },
                    expression: "details.submissionLimit"
                  }
                },
                [
                  _vm.details.paymentLimit.wholepounds != undefined &&
                  !_vm.details.paymentLimit.wholepounds
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v(
                          "submission item limit can only be in whole pounds"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v("Set to 0 for no limit")
              ])
            ],
            1
          )
        ]
      ),
      false
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-form-label col-md-3" }, [
              _vm._v("Is Indirect Submitter Group")
            ]),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("p-check", {
                  staticClass: "p-switch p-fill",
                  attrs: { color: "primary", disabled: _vm.disabled },
                  model: {
                    value: _vm.details.isIndirectSubmitterGroup.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.details.isIndirectSubmitterGroup,
                        "$model",
                        $$v
                      )
                    },
                    expression: "details.isIndirectSubmitterGroup.$model"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-form-label col-md-3" }, [
          _vm._v("Segregate Group Roles")
        ]),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary", disabled: _vm.disabled },
              model: {
                value: _vm.details.segregateGroupRoles.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.segregateGroupRoles, "$model", $$v)
                },
                expression: "details.segregateGroupRoles.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("delete-payment-data", {
        attrs: { groupDetails: _vm.details, disabled: _vm.disabled }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }