var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "addBureauJobDetailModal",
      attrs: {
        id: "add-bureau-job-detail",
        title: "Add Bureau Job Detail",
        size: "xl",
        "hide-header-close": "",
        static: ""
      },
      on: { shown: _vm.load, ok: _vm.addJobDetail }
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Save")
      ]),
      _c("div", [_c("h3", [_vm._v("AddBureauJobDetail.vue")])]),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
            _c(
              "label",
              { attrs: { for: "jobDetailRecord.bureauCustomerId" } },
              [_vm._v("Bureau Customer")]
            )
          ]),
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c("vue-select", {
                attrs: {
                  single: "",
                  options: _vm.bureauCustomers,
                  label: "name",
                  closeOnSelect: true
                },
                on: { input: _vm.CheckBureauCustomerOnSelect },
                model: {
                  value: _vm.selectedBureauCustomer,
                  callback: function($$v) {
                    _vm.selectedBureauCustomer = $$v
                  },
                  expression: "selectedBureauCustomer"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
            _c("label", { attrs: { for: "jobDetailRecord.paymentfile" } }, [
              _vm._v("Payment File")
            ])
          ]),
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c("b-form-input", {
                ref: "filename",
                attrs: { type: "text" },
                on: {
                  input: function($event) {
                    return _vm.onFilenameChanged()
                  }
                },
                model: {
                  value: _vm.$v.jobDetailRecord.filename.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.jobDetailRecord.filename,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.jobDetailRecord.filename.$model"
                }
              }),
              _vm.jobDetailRecord.filename.length > 0
                ? _c("span", { staticClass: "small" }, [
                    _vm._v("Example: " + _vm._s(_vm.parsedFilename))
                  ])
                : _vm._e(),
              !_vm.filenameValid
                ? _c("span", [
                    _c("br"),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v("This filename is not valid")
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
            _c("label", [
              _c(
                "a",
                {
                  staticClass: "text-primary small",
                  on: {
                    click: function($event) {
                      return _vm.addLookup("[CUSTOMERREF]")
                    }
                  }
                },
                [_vm._v("[CUSTOMERREF]")]
              ),
              _vm._v(" will be replace with Customer Reference ")
            ])
          ]),
          _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
            _c("label", [
              _c(
                "a",
                {
                  staticClass: "text-primary small",
                  on: {
                    click: function($event) {
                      return _vm.addLookup("[SUN]")
                    }
                  }
                },
                [_vm._v("[SUN]")]
              ),
              _vm._v(
                " will be replace with customers Service Reference Number "
              )
            ])
          ]),
          _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
            _c("label", [
              _c(
                "a",
                {
                  staticClass: "text-primary small",
                  on: {
                    click: function($event) {
                      return _vm.addLookup("[DATETIME, DD-MM-YYYY]")
                    }
                  }
                },
                [_vm._v("[DATETIME, FORMAT]")]
              ),
              _vm._v(" will be replaced with the current formatted date/time ")
            ])
          ]),
          _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
            _c("label", { staticClass: "small" }, [
              _vm._v("* and ? can be used as a wildcards")
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
            _c("label", { attrs: { for: "jobDetailRecord.importSchema" } }, [
              _vm._v("Import Schema")
            ])
          ]),
          _c(
            "b-col",
            { attrs: { sm: "5" } },
            [
              _c("b-form-select", {
                attrs: { options: this.schemas },
                model: {
                  value: _vm.jobDetailRecord.importSchema,
                  callback: function($$v) {
                    _vm.$set(_vm.jobDetailRecord, "importSchema", $$v)
                  },
                  expression: "jobDetailRecord.importSchema"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.jobDetailRecord.importSchema == "Mapping"
        ? _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c(
                  "label",
                  { attrs: { for: "jobDetailRecord.importMapping" } },
                  [_vm._v("Mapping")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "5" } },
                [
                  _c("b-form-select", {
                    attrs: { options: this.mappings },
                    model: {
                      value: _vm.jobDetailRecord.importMapping,
                      callback: function($$v) {
                        _vm.$set(_vm.jobDetailRecord, "importMapping", $$v)
                      },
                      expression: "jobDetailRecord.importMapping"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }