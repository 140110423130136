<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Customer Certificates
        <help-icon docPath="/administration/customer/certificates/" />
        <favourite-icon />
      </h2>
    </div>
    <div class="idb-block-content">
      <div v-if="areErrors" class="alert alert-warning" id="errors">
        <p>The certificate cannot be deleted due to these errors</p>
        <ul>
          <li v-for="error in errors" :key="error">{{error}}</li>
        </ul>
      </div>
      <vue-good-table
        :paginationOptions="{
        enabled: true,
        perPage: 10,
      }"
        :rows="rows"
        :columns="columns"
        :searchOptions="{
        enabled: true
      }"
        :lineNumbers="true"
        styleClass="vgt-table striped bordered"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-b-popover.top.hover="'Is the date inbetween the valid from and valid to dates?'"
            v-if="props.column.field == 'isValid'"
          >
            <tick v-model="props.row.valid"></tick>
          </span>
          <span v-else-if="props.column.field == 'delete'">
            <button
              :disabled="isLoading"
              class="btn btn-danger"
              @click="deleteCertificate(props.row)"
            >Delete</button>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
      <button
        class="btn btn-primary"
        type="submit"
        :disabled="isLoading"
        @click="createCertificate"
      >Add Certificate</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import swal from 'sweetalert2'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [loading],
  computed: {
    ...mapGetters(['selectedCustomer']),
    areErrors () {
      return this.errors.some(() => true)
    }
  },
  watch: {
    selectedCustomer () { this.loadHsms() }
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'friendlyName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Valid From',
          field: 'validFrom',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: 'Valid To',
          field: 'validTo',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: 'Serial Number',
          field: 'serialNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Is Valid',
          field: 'isValid',
          tdClass: 'text-center'
        },
        {
          label: 'Delete',
          field: 'delete'
        }
      ],
      file: null,
      errors: []
    }
  },
  created () {
    this.loadHsms()
  },
  methods: {
    async loadHsms () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`)
        this.rows = response.data
        this.rows = this.rows.map((v) => { return { ...v, valid: this.isValid(v) } })
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    async createCertificate () {
      try {
        await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/Check`, { showerror: true })
        this.$router.push({ name: 'CreateCustomerCertificate' })
      } catch { }
    },
    isValid ({ validFrom, validTo }) {
      return moment().isBetween(validFrom, validTo)
    },
    async deleteCertificate ({ certificateId }) {
      try {
        // Get confirmation from the user that they really want to delete the customer
        var swalResult = await swal.fire({
          title: 'Delete Certificate',
          text: 'Are you sure you want to delete this certificate?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        // Do nothing as this will be if they clicked cancel
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        var deleteResponse = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`,
          {
            params: {
              certificateId
            },
            showload: true,
            showerror: true,
            errormessage: 'Certificate failed to delete'
          })
        this.errors = deleteResponse.data.errors
        if (this.areErrors) {
          this.$snapbar.w('Cannot delete certificate')
        } else {
          this.$snapbar.hide()

          this.loadHsms()
        }
      } catch { }
    }
  }
}

</script>
