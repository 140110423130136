var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " Payment files for '" + _vm._s(_vm.submissionReference) + "' "
          ),
          _c("help-icon"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "ml-5 mt-3" },
      [
        _c("b-row", [_vm._v("Job Id = " + _vm._s(_vm.jobId))]),
        _c("b-row", [
          _vm._v("Bureau Submission Id = " + _vm._s(_vm.bureauSubmissionId))
        ]),
        _c("b-row", [
          _vm._v("Submission Reference = " + _vm._s(_vm.submissionReference))
        ]),
        _c("b-row", [
          _vm._v("Contra Narrative = " + _vm._s(_vm.contraNarrative))
        ]),
        _c("b-row", [_vm._v("Payment Date = " + _vm._s(_vm.paymentDate))]),
        _c("b-row", [
          _vm._v("Processing Date = " + _vm._s(_vm.processingDate))
        ]),
        _c("b-row", [_vm._v("Paygate Id = " + _vm._s(_vm.paygateId))])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "idb-block-content overflowedtable" },
      [
        _c(
          "vue-good-table",
          {
            ref: "submissionFilesTable",
            attrs: {
              paginationOptions: {
                enabled: true,
                perPage: 10
              },
              rows: _vm.submissionDetails,
              columns: _vm.columns,
              lineNumbers: true,
              styleClass: "vgt-table striped bordered"
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "bureauCustomerName"
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { sm: "11" } },
                                  [
                                    _c("model-list-select", {
                                      attrs: {
                                        list: _vm.bureauCustomerOptions,
                                        "option-value": "bureauJobDetailId",
                                        "option-text": "bureauCustomerName",
                                        placeholder: "Select Customer"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.onSelectCustomerChange(
                                            props.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: props.row.bureauJobDetailId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            props.row,
                                            "bureauJobDetailId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row.bureauJobDetailId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-sm primary mt-1 reset-customer",
                                    attrs: { disabled: _vm.isLoading },
                                    on: {
                                      click: function($event) {
                                        return _vm.onResetSelectedCustomer(
                                          props.row.bureauSubmissionDetailId
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-times" })]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : props.column.field == "delete"
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-sm primary",
                              attrs: { disabled: _vm.isLoading },
                              on: {
                                click: function($event) {
                                  return _vm.onDeleteFileClick(
                                    props.row.bureauSubmissionDetailId
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          )
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(props.formattedRow[props.column.field]))
                        ])
                  ]
                }
              }
            ])
          },
          [
            _c(
              "div",
              { attrs: { slot: "table-actions" }, slot: "table-actions" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the table",
                        expression: "'Refresh the data in the table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: {
                      variant: "link",
                      title: "Refresh Table",
                      disabled: _vm.isLoading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.load($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-refresh pointer dimmedIcon" })]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Print out the contents of the Logs table",
                        expression:
                          "'Print out the contents of the Logs table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: {
                      variant: "link",
                      disabled: _vm.isLoading,
                      title: "Print Table"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.printTable($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-print pointer dimmedIcon" })]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Export the contents of the Logs table",
                        expression: "'Export the contents of the Logs table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: {
                      variant: "link",
                      disabled: _vm.isLoading,
                      title: "Export Table"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.exportTable($event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-share-square-o pointer dimmedIcon"
                    })
                  ]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.isLoading },
          on: { click: _vm.onBackClick }
        },
        [_vm._v("Back")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-primary ml-2",
          attrs: { disabled: _vm.isLoading },
          on: { click: _vm.onNextClick }
        },
        [_vm._v("Next")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }