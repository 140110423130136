var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: {
              docPath: "/paygate-collections/reporting/lookaheadreport/"
            }
          }),
          _vm._v("Look Ahead Report"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c("div", [
      _c("div", { staticClass: "idb-block-content" }, [
        _c("div", { staticClass: "row form-group mb-3" }, [
          _c("div", { staticClass: "col-md-2 required" }, [
            _vm._v(" Select a Group ")
          ]),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("group-select", {
                attrs: { groups: _vm.customerGroups, clearable: false },
                on: { input: _vm.reportChanged },
                model: {
                  value: _vm.group,
                  callback: function($$v) {
                    _vm.group = $$v
                  },
                  expression: "group"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row form-group mb-3" }, [
          _c("div", { staticClass: "col-md-2 required" }, [_vm._v(" Window ")]),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("b-form-select", {
                staticClass: "form-control",
                attrs: {
                  options: _vm.months,
                  "value-field": "id",
                  "text-field": "name"
                },
                on: { input: _vm.reportChanged },
                model: {
                  value: _vm.window,
                  callback: function($$v) {
                    _vm.window = $$v
                  },
                  expression: "window"
                }
              })
            ],
            1
          )
        ]),
        _vm.group === "00000000-0000-0000-0000-000000000000"
          ? _c("div", { staticClass: "row form-group mb-3" }, [
              _c("div", { staticClass: "col-md-2 required" }, [
                _vm._v(" Merge Groups ")
              ]),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("p-check", {
                    staticClass: "p-switch p-fill",
                    attrs: { color: "primary" },
                    on: { change: _vm.reportChanged },
                    model: {
                      value: _vm.mergeGroups,
                      callback: function($$v) {
                        _vm.mergeGroups = $$v
                      },
                      expression: "mergeGroups"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        [
          _c(
            "b-col",
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Monthly Collections" },
                      on: { click: _vm.renderMonthly }
                    },
                    [
                      _vm.loaded && _vm.monthlyDisplayed
                        ? _c(
                            "div",
                            { staticClass: "m-3" },
                            [
                              _c("look-ahead-bar-chart", {
                                ref: "unitChart",
                                attrs: {
                                  "chart-data": _vm.lookAheadData,
                                  options: _vm.chartOptions
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Monthly Collections (Cumulative)" },
                      on: { click: _vm.renderCumulative }
                    },
                    [
                      _vm.loaded && _vm.cumulativeDisplayed
                        ? _c(
                            "div",
                            { staticClass: "m-3" },
                            [
                              _c("look-ahead-bar-chart", {
                                ref: "cumulativeChart",
                                attrs: {
                                  "chart-data": _vm.cumulativeData,
                                  options: _vm.chartOptions
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "idb-block-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }