var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.docPath
    ? _c("span", { staticClass: "pull-right mr-2" }, [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.click } }, [
          _c("i", {
            staticClass: "text-primary far fa-question-circle question"
          })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }