var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("span", { staticClass: "pull-right" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.docUrl +
                          "/automation/paygate-designer/designersettings/",
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  )
                ]),
                _vm._v("Workflow/Mapping Designer Settings"),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("form", [
            _c("div", { staticClass: "idb-block-content" }, [
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-1" }),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c(
                      "p-check",
                      {
                        staticClass: "p-switch",
                        attrs: { name: "check", color: "primary " },
                        model: {
                          value: _vm.config.autosave,
                          callback: function($$v) {
                            _vm.$set(_vm.config, "autosave", $$v)
                          },
                          expression: "config.autosave"
                        }
                      },
                      [
                        _c("span", { staticClass: " ml-2" }, [
                          _vm._v("Enable Auto Save")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-7" }, [
                  _vm._v(
                    " The Mapping or Workflow is automatically saved in the background "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-sm-3" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { disabled: _vm.isLoading, variant: "primary" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.btnSave($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-save mr-2" }),
                        _vm._v("Save Settings")
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }