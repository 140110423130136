var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c("div", { staticClass: "cookie-message-box container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-9 col-push-2 cookie-message-text" },
            [
              _c("p", [
                _vm._v(
                  " We use cookies and similar technologies to recognize your repeat visits and preferences, as well as to measure the effectiveness of campaigns and analyze traffic. To learn more about cookies, including how to disable them, view our "
                ),
                _c("a", { attrs: { href: "#" }, on: { click: _vm.settings } }, [
                  _vm._v("Cookie Policy")
                ]),
                _vm._v(
                  '. By clicking "Accept" or using our site, you consent to the use of cookies unless you have disabled them. '
                )
              ])
            ]
          ),
          _c("div", { staticClass: "col-md-1" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-success",
                    on: { click: _vm.accept }
                  },
                  [_vm._v("Accept")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link btn-sm",
                    on: { click: _vm.settings }
                  },
                  [_vm._v("Change Settings")]
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }