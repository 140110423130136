var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-form-label col-md-2 offset-md-1" }, [
          _vm._v("Payment Approval Method")
        ]),
        _c(
          "div",
          { staticClass: "col-md-9" },
          [
            _c(
              "p-radio",
              {
                staticClass: "p-default p-round",
                attrs: { value: "Default" },
                model: {
                  value: _vm.details.paymentApprovalMethod.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.paymentApprovalMethod, "$model", $$v)
                  },
                  expression: "details.paymentApprovalMethod.$model"
                }
              },
              [_vm._v("Default")]
            ),
            _c(
              "p-radio",
              {
                staticClass: "p-default p-round",
                attrs: { value: "Number of Approvers" },
                on: {
                  change: function($event) {
                    return _vm.selectNumberOfApprovers()
                  }
                },
                model: {
                  value: _vm.details.paymentApprovalMethod.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.paymentApprovalMethod, "$model", $$v)
                  },
                  expression: "details.paymentApprovalMethod.$model"
                }
              },
              [_vm._v("Number of Approvers")]
            ),
            _c(
              "p-radio",
              {
                staticClass: "p-default p-round",
                attrs: { value: "Approval Range" },
                model: {
                  value: _vm.details.paymentApprovalMethod.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.paymentApprovalMethod, "$model", $$v)
                  },
                  expression: "details.paymentApprovalMethod.$model"
                }
              },
              [_vm._v("Approval Range")]
            )
          ],
          1
        )
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.details.paymentApprovalMethod.$model === "Number of Approvers"
          ? _c(
              "div",
              {
                staticClass: "form-group row",
                class: { invalid: _vm.details.numberOfApprovers.$error }
              },
              [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-2 offset-md-1" },
                  [_vm._v("Number of Approvers")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.details.numberOfApprovers.$model,
                          expression: "details.numberOfApprovers.$model",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", min: "1" },
                      domProps: { value: _vm.details.numberOfApprovers.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.details.numberOfApprovers,
                            "$model",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c("validation-messages", {
                      model: {
                        value: _vm.details.numberOfApprovers,
                        callback: function($$v) {
                          _vm.$set(_vm.details, "numberOfApprovers", $$v)
                        },
                        expression: "details.numberOfApprovers"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.details.paymentApprovalMethod.$model === "Approval Range"
          ? _c(
              "div",
              { staticClass: "form-group row" },
              [
                _c("approval-ranges", {
                  staticClass: "offset-md-3",
                  attrs: { ranges: _vm.group.approverAmountRanges }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.details.paymentApprovalMethod.$model !== "Default"
          ? _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "col-form-label col-md-2 offset-md-1" },
                [_vm._v("Approval Method")]
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.approvalMethods },
                    model: {
                      value: _vm.details.approvalMethod.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.details.approvalMethod, "$model", $$v)
                      },
                      expression: "details.approvalMethod.$model"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }