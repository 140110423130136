var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " BACS Payment details for '" +
              _vm._s(this.submission.submissionReference) +
              "' "
          ),
          _c("help-icon"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "ml-5 mt-3" },
      [
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Submission Reference")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(this.submission.submissionReference))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total number of debits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.totalDebitCount)))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total number of credits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.totalCreditCount)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total number of DDI")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.totalDDICount)))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total value of debits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("currency")(this.totalDebitValue)))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total value of credits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("currency")(this.totalCreditValue)))
            ])
          ],
          1
        ),
        _c("b-row"),
        _c("b-row")
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-content" }, [
      _c(
        "div",
        { attrs: { role: "tablist" } },
        [
          _vm._l(_vm.submissionSummaryItems, function(item) {
            return _c(
              "b-card",
              { key: item.fileNumber, attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-header",
                  {
                    staticClass: "p-2",
                    attrs: { "header-tag": "header", role: "tab" }
                  },
                  [
                    _c(
                      "b-row",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle",
                            value:
                              "item-" +
                              item.bureauSubmissionId +
                              item.fileNumber,
                            expression:
                              "'item-' + item.bureauSubmissionId + item.fileNumber"
                          }
                        ],
                        staticClass: "payment-header pt-0"
                      },
                      [
                        _c("b-col", { attrs: { cols: "1" } }, [
                          _vm._v(_vm._s(item.description))
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _vm._v(_vm._s(item.bureauCustomerName))
                            ]),
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _vm._v("SUN: " + _vm._s(item.serviceUserNumber))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _vm._v(
                                "Processing Date: " +
                                  _vm._s(item.processingDateDisplay)
                              )
                            ]),
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _vm._v(
                                "Payment Date: " + _vm._s(item.paymentDate)
                              )
                            ])
                          ],
                          1
                        ),
                        _c("b-col", { attrs: { cols: "2" } }, [
                          _vm._v("Contra: " + _vm._s(item.contraNarrative))
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _vm._v(
                                " Debits: " +
                                  _vm._s(
                                    _vm._f("number")(item.totalNumberOfDebits)
                                  ) +
                                  " ( "
                              ),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(item.totalDebitsValue)
                                  )
                                )
                              ]),
                              _vm._v(") ")
                            ]),
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _vm._v(
                                " Credits: " +
                                  _vm._s(
                                    _vm._f("number")(item.totalNumberOfCredits)
                                  ) +
                                  " ( "
                              ),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(item.totalCreditsValue)
                                  )
                                )
                              ]),
                              _vm._v(" ) ")
                            ])
                          ],
                          1
                        ),
                        _c("b-col", { attrs: { cols: "1" } }, [
                          item.hasMustFix
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-times-circle mr-1"
                                })
                              ])
                            : item.hasWarnings
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-triangle mr-1"
                                })
                              ])
                            : item.hasDuplicates
                            ? _c("span", [
                                _c("i", { staticClass: "fa fa-clone mr-1" })
                              ])
                            : item.hasMessages
                            ? _c("span", [
                                _c("i", { staticClass: "fa fa-info-circle" })
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticStyle: {
                                    "margin-top": "-10px",
                                    "margin-right": "-30px"
                                  },
                                  attrs: {
                                    variant: "link",
                                    size: "lg",
                                    "no-caret": ""
                                  }
                                },
                                [
                                  _c("template", { slot: "button-content" }, [
                                    _c("i", {
                                      staticClass: "fa fa-ellipsis-h",
                                      staticStyle: { color: "#878787" }
                                    }),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Search")
                                    ])
                                  ]),
                                  !_vm.readonly
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.groupUserRights.edit === false
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onInsertPaymentClick(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Insert Payment")]
                                      )
                                    : _vm._e(),
                                  !_vm.readonly
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onValidateBlockClick(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Validate Payments")]
                                      )
                                    : _vm._e(),
                                  !_vm.readonly
                                    ? _c("b-dropdown-divider")
                                    : _vm._e(),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onShowBlockDetailsClick(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Block Details")]
                                  ),
                                  !_vm.readonly
                                    ? _c("b-dropdown-divider")
                                    : _vm._e(),
                                  !_vm.readonly
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.groupUserRights.edit === false
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onZeroPaymentsForBlockClick(
                                                item.fileNumber
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Zero Payments")]
                                      )
                                    : _vm._e(),
                                  !_vm.readonly
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onDeleteBlock(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Remove Block")]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-collapse",
                  {
                    attrs: {
                      id: "item-" + item.bureauSubmissionId + item.fileNumber,
                      accordion: "my-accordion",
                      role: "tabpanel"
                    },
                    on: {
                      show: function($event) {
                        return _vm.onShowPayments(item)
                      },
                      hide: function($event) {
                        return _vm.onHidePayments()
                      }
                    }
                  },
                  [
                    _c("b-table", {
                      staticClass: "mt-0 mb-0",
                      attrs: {
                        id: "blockValidationMessages",
                        borderless: "",
                        small: "",
                        "thead-class": "hidden_header",
                        items: _vm.blockValidationMessages,
                        fields: _vm.blockValidationMessagesColumns
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(type)",
                            fn: function(data) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-pill table-pill",
                                    class: data.value
                                  },
                                  [_vm._v(_vm._s(data.value))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c(
                      "vue-good-table",
                      {
                        ref: "paymentsTable",
                        refInFor: true,
                        attrs: {
                          mode: "remote",
                          styleClass: "vgt-table striped bordered",
                          rows: _vm.payments,
                          columns: _vm.columns,
                          lineNumbers: true,
                          totalRows: _vm.totalRecords,
                          paginationOptions: {
                            enabled: true,
                            perPage: _vm.serverParams.perPage
                          }
                        },
                        on: {
                          "on-page-change": _vm.onPageChange,
                          "on-sort-change": _vm.onSortChange,
                          "on-column-filter": _vm.onColumnFilter,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-row-click": _vm.onManagePayment
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.label === "Amount"
                                    ? _c("span", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    : props.column.label === "Issues"
                                    ? _c("span", [
                                        props.row["fix"] > 0
                                          ? _c("span", [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      props.row
                                                        .presubvalMessage,
                                                    expression:
                                                      "props.row.presubvalMessage",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "fa fa-times-circle mr-1"
                                              })
                                            ])
                                          : props.row["warnings"] > 0
                                          ? _c("span", [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      props.row
                                                        .presubvalMessage,
                                                    expression:
                                                      "props.row.presubvalMessage",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "fa fa-exclamation-triangle mr-1"
                                              })
                                            ])
                                          : props.row["duplicates"] > 0
                                          ? _c("span", [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      props.row
                                                        .presubvalMessage,
                                                    expression:
                                                      "props.row.presubvalMessage",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                staticClass: "fa fa-clone mr-1"
                                              })
                                            ])
                                          : props.row["information"] > 0
                                          ? _c("span", [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      props.row
                                                        .presubvalMessage,
                                                    expression:
                                                      "props.row.presubvalMessage",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                staticClass: "fa fa-info-circle"
                                              })
                                            ])
                                          : _vm._e()
                                      ])
                                    : props.column.field == "delete"
                                    ? _c("span", [
                                        _c("span", [
                                          !_vm.readonly
                                            ? _c("i", {
                                                staticClass: "fa fa-trash",
                                                attrs: {
                                                  disabled:
                                                    _vm.groupUserRights
                                                      .delete === false
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.onDeletePaymentClick(
                                                      props
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ])
                                      ])
                                    : _c("span", [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            )
                                          )
                                        ])
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("template", { slot: "loadingContent" }, [
                          _c("h1", [_vm._v("Loading...")])
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "mt-2" }, [
                _vm._v(
                  "There are " +
                    _vm._s(this.submissionDetailCount) +
                    " block(s) in the submission"
                )
              ]),
              this.submissionDetailCount > 10
                ? _c(
                    "b-col",
                    [
                      _c("b-pagination", {
                        staticClass: "mt-2 float-right",
                        attrs: {
                          "total-rows": _vm.submissionDetailCount,
                          "per-page": 10,
                          size: "sm"
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadSubmissionSummary()
                          }
                        },
                        model: {
                          value: _vm.submissionDetailsPage,
                          callback: function($$v) {
                            _vm.submissionDetailsPage = $$v
                          },
                          expression: "submissionDetailsPage"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-footer" },
      [
        !_vm.readonly
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ml-1",
                attrs: {
                  disabled: _vm.isLoading || _vm.groupUserRights.add === false
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onImportedFilesViewClick()
                  }
                }
              },
              [_vm._v("View Imported Files")]
            )
          : _vm._e(),
        !_vm.readonly
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ml-1",
                attrs: {
                  disabled: _vm.isLoading || _vm.groupUserRights.add === false
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onAddFilesClick()
                  }
                }
              },
              [_vm._v("Add Files")]
            )
          : _vm._e(),
        !_vm.readonly
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ml-2",
                attrs: {
                  disabled: _vm.isLoading || _vm.groupUserRights.add === false
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onAddBlockClick()
                  }
                }
              },
              [_vm._v("Add Manual Block")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary ml-1",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onBulkDateEditorClick()
              }
            }
          },
          [_vm._v("Bulk Date Editor")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary ml-1",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onPreAuthorisationReportClick()
              }
            }
          },
          [_vm._v("Download Pre-Authorisation Report")]
        ),
        !_vm.readonly
          ? _c(
              "button",
              {
                staticClass: "btn btn-success ml-2",
                attrs: {
                  disabled:
                    _vm.isLoading || _vm.groupUserRights.submit === false
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onValidatePayments()
                  }
                }
              },
              [_vm._v("Validate Payments")]
            )
          : _vm._e(),
        !_vm.readonly
          ? _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: {
                  variant: "danger",
                  disabled: _vm.isLoading || _vm.groupUserRights.edit === false
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onZeroPaymentsForSubmissionClick()
                  }
                }
              },
              [_vm._v("Zero All Payments")]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _c("managePaymentModal", {
          attrs: {
            itemId: _vm.currentItemId,
            submissionId: _vm.submissionId,
            bureauJobId: _vm.submission.bureauJobId,
            isNew: _vm.currentIsNew,
            pkid: _vm.currentpkid
          },
          on: { submit: _vm.manageBlockModalClosed }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("manageBlockModal", {
          attrs: {
            itemId: _vm.currentItemId,
            submissionId: _vm.submissionId,
            bureauJobId: _vm.submission.bureauJobId,
            isNew: _vm.currentIsNew
          },
          on: { submit: _vm.manageBlockModalClosed }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("bulkDateEditorModal", {
          attrs: { submissionId: _vm.submissionId },
          on: { submit: _vm.manageBlockModalClosed }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }