var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [_c("b-col", [_c("div", { ref: "editor" })])],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", disabled: _vm.isEmpty },
                  on: { click: _vm.addNote }
                },
                [_vm._v("Add Note")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.rows, function(row, index) {
        return _c("div", { key: index, staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "media flex-wrap align-items-center" }, [
              _c("img", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "d-block ui-w-40 rounded-circle",
                attrs: {
                  src:
                    _vm.platformApi +
                    "User/GetAvatar/" +
                    row.userid +
                    "?access_token=" +
                    _vm.getAccessToken,
                  width: "30",
                  height: "30",
                  title: row.name
                }
              }),
              _c("div", { staticClass: "media-body ml-3" }, [
                _c("a", { attrs: { href: "javascript:void(0)" } }, [
                  _vm._v(_vm._s(row.name))
                ]),
                _c("div", { staticClass: "text-muted small" }, [
                  _vm._v(_vm._s(_vm.formatDate(row.creationdate)))
                ])
              ])
            ]),
            _c("div", {
              staticClass: "card-body",
              domProps: { innerHTML: _vm._s(row.notetext) }
            })
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }