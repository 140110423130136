<template>
  <actions-block
    v-bind:action="action"
    :status="status"
    :from="from"
    name="Bank Account"
    v-if="action.id"
  >
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Bank Account Details</h2>
      </div>
      <div class="idb-block-content">
        <div class="alert alert-secondary" role="alert" v-if="status === 'Edit'">
          The previous details are shown in
          <span class="del">red</span>, the new details in
          <span class="ins">green</span>.
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Reference</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.reference"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Description</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px;"
                v-for="diff in difference.description"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Sort Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.sortCode"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Account Number</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.accountNumber"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Currency</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.currency"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Intermediate Bank Id</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.intermediateBankId"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Bank Name and Address</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Bank Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.bankName"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Address</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.address1"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.address2"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.address3"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.address4"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.address5"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Post Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.postcode"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Country</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.country"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </actions-block>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'
import ActionsBlock from '@/Components/Platform/Actions/ActionsBlock'

export default {
  props: ['actionId', 'bankAccountId'],
  components: { 'actions-block': ActionsBlock },
  computed: {
    difference () {
      if (!this.bankAccount || !this.action.data) {
        if (this.status === 'Create') {
          this.bankAccount = this.action.data
        } else if (this.status === 'Delete' && this.bankAccount) {
          this.action.data = this.bankAccount
        } else {
          return {}
        }
      }
      return Object.keys(this.bankAccount).reduce((a, v) => {
        if (v === 'isItemActioned' || v === 'modifiedBy') {
          return a
        }

        if (this.bankAccount[v] === null || this.bankAccount[v] === undefined) {
          this.bankAccount[v] = ''
        }
        if (this.action.data[v] === null || this.action.data[v] === undefined) {
          this.action.data[v] = ''
        }

        var tempObj = {}
        tempObj[v] = diffSentences(this.bankAccount[v].toString(), this.action.data[v].toString())
        return Object.assign(a, tempObj)
      }, {})
    }
  },
  data () {
    return {
      bankAccount: null,
      action: { data: null },
      status: '',
      loading: true,
      from: {}
    }
  },

  async mounted () {
    // Get Action detail and the bank account
    try {
      this.$Progress.start()
      const actionResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })

      this.action = actionResponse.data
      this.status = this.action.actionSubClass

      if (this.status !== 'Create') {
        const bankResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.bankAccountId}`, { showerror: true, errormessage: 'Failed to get bank account' })
        if (this.status === 'Delete') {
          this.action.data = null
        }
        this.bankAccount = bankResponse.data
      }

      this.$Progress.finish()
    } catch {
      this.$Progress.fail()
    }

    // Get Countries
    if (this.action.data.country) {
      try {
        const countryPromises = [axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Lookup/Country`, { params: { code: this.action.data.country }, showerror: true, errormessage: 'Failed to get country' })]
        if (this.status !== 'Create' && this.bankAccount.country) {
          countryPromises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Lookup/Country`, { params: { code: this.bankAccount.country }, showerror: true, errormessage: 'Failed to get country' }))
        }
        this.$Progress.start()
        var countryResults = await Promise.all(countryPromises)

        const actionCountryResponse = countryResults[0]
        this.action.data.country = actionCountryResponse.data.description
        if (this.status !== 'Create' && this.bankAccount.country) {
          const bankAccountCountryResponse = countryResults[1]
          this.bankAccount.country = bankAccountCountryResponse.data.description
        }
        this.$Progress.finish()
      } catch {
        this.$Progress.fail()
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>
