var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "manageSchedule" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c(
                    "h2",
                    [
                      _c("help-icon", {
                        attrs: { docPath: "/paygate-collections/submissions/" }
                      }),
                      _vm._v("Process Submissions: " + _vm._s(_vm.title))
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-content" },
                  [
                    _c(
                      "b-container",
                      [
                        !_vm.detailsLoaded
                          ? _c("div", [_vm._v("Loading Submission Details")])
                          : _vm._e(),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.mode === "collection" &&
                          _vm.showFlaggedCard &&
                          _vm.flaggedPaymentCount > 0 &&
                          _vm.detailsLoaded
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-card",
                                    {
                                      staticStyle: { "margin-bottom": "0.8em" },
                                      attrs: {
                                        "bg-variant": "info",
                                        "text-variant": "white",
                                        title: "Payments Flagged For Review"
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "card-text",
                                          staticStyle: {
                                            "margin-bottom": "1.5em"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.flaggedPaymentCount) +
                                              " payment(s) have been flagged for review in this submission. "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "Invalid payments will not be sent with the current submission. "
                                          ),
                                          _c("br")
                                        ]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "FlaggedSubmissions",
                                              params: { groupId: _vm.groupId }
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            { attrs: { variant: "primary" } },
                                            [_vm._v("View Flagged Payments")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "warning" },
                                          on: {
                                            click: function($event) {
                                              _vm.showFlaggedCard = false
                                            }
                                          }
                                        },
                                        [_vm._v("Dismiss")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("b-row", [
                          _vm._v("Process a Direct Debit Submission")
                        ]),
                        _c(
                          "b-row",
                          { staticClass: "mt-20" },
                          [
                            _c("b-col", { attrs: { sm: "4" } }, [
                              _c("strong", [_vm._v("Group:")]),
                              _vm._v(" " + _vm._s(_vm.group) + " ")
                            ]),
                            _c("b-col", { attrs: { sm: "4" } }, [
                              _c("strong", [
                                _vm._v("Current Processing Date:")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(new Date(_vm.processingDate))
                                  ) +
                                  " "
                              )
                            ]),
                            _c("b-col", { attrs: { sm: "4" } }, [
                              _c("strong", [
                                _vm._v("Current Collection Date:")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(new Date(_vm.collectionDate))
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-20" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "col-sm-5",
                                attrs: {
                                  "label-cols": 5,
                                  "label-class": "required",
                                  label: "Submission Title"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "submissionTitleInput"
                                  },
                                  model: {
                                    value: _vm.submissionTitle,
                                    callback: function($$v) {
                                      _vm.submissionTitle = $$v
                                    },
                                    expression: "submissionTitle"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.mode === "collection"
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-sm-5",
                                    attrs: {
                                      "label-cols": 5,
                                      label: "Contra Narrative"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "contraNarrativeInput"
                                      },
                                      model: {
                                        value: _vm.contraNarrative,
                                        callback: function($$v) {
                                          _vm.contraNarrative = $$v
                                        },
                                        expression: "contraNarrative"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "primary",
                                      disabled:
                                        !_vm.canValidate || _vm.isLoading
                                    },
                                    on: { click: _vm.processSubmission }
                                  },
                                  [_vm._v("Validate Submission")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "primary",
                                      disabled:
                                        this.presubValResult === "Fixes" ||
                                        this.presubValResult === "" ||
                                        _vm.isLoading
                                    },
                                    on: { click: _vm.confirmProcessSubmission }
                                  },
                                  [_vm._v("Process Submission")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.saveStarted && _vm.progress < 100,
                                expression: "saveStarted && progress < 100"
                              }
                            ],
                            staticClass: "mt-20"
                          },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _vm._v(" " + _vm._s(_vm.stage) + " "),
                                _c("b-progress", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    value: _vm.progress,
                                    max: _vm.max,
                                    "show-progress": ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.totalRecords > 0
                          ? _c(
                              "b-row",
                              { staticClass: "mt-20" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "12" } },
                                  [
                                    _c(
                                      "vue-good-table",
                                      {
                                        ref: "table",
                                        attrs: {
                                          mode: "remote",
                                          columns: _vm.columns,
                                          rows: _vm.rows,
                                          lineNumbers: true,
                                          totalRows: _vm.totalRecords,
                                          paginationOptions:
                                            _vm.paginationOptions,
                                          "sort-options": _vm.sortOptions,
                                          styleClass:
                                            "vgt-table striped bordered",
                                          isLoading: _vm.isTableLoading
                                        },
                                        on: {
                                          "on-page-change": _vm.onPageChange,
                                          "on-sort-change": _vm.onSortChange,
                                          "on-per-page-change":
                                            _vm.onPerPageChange,
                                          "on-column-filter":
                                            _vm.onColumnFilter,
                                          "update:isLoading": function($event) {
                                            _vm.isTableLoading = $event
                                          },
                                          "update:is-loading": function(
                                            $event
                                          ) {
                                            _vm.isTableLoading = $event
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "table-row",
                                              fn: function(props) {
                                                return [
                                                  props.column.field ===
                                                  "messageSeverity"
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "b-badge",
                                                            {
                                                              attrs: {
                                                                variant: _vm.getSeverityClass(
                                                                  props.row
                                                                    .messageSeverity
                                                                ),
                                                                pill: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.row
                                                                    .messageSeverity
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : props.column.field ===
                                                      "itemId"
                                                    ? _c("span", [
                                                        _c("span", [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "/payer/" +
                                                                  props.row
                                                                    .itemId +
                                                                  "/edit"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "View Payer"
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.formattedRow[
                                                              props.column.field
                                                            ]
                                                          )
                                                        )
                                                      ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          767251289
                                        )
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "loadingContent" },
                                          [_c("h1", [_vm._v("Loading...")])]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "table-actions" },
                                            slot: "table-actions"
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value: "Clear filters",
                                                    expression:
                                                      "'Clear filters'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                staticClass: "btn btn-link",
                                                attrs: {
                                                  disabled: _vm.isLoading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.clearTableFilters(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "fa-stack",
                                                    staticStyle: {
                                                      "font-size": "10px"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      "Refresh the data in the table",
                                                    expression:
                                                      "'Refresh the data in the table'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  variant: "link",
                                                  disabled: _vm.isLoading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.load($event)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-sync pointer dimmedIcon"
                                                })
                                              ]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      "Print out the contents of the table",
                                                    expression:
                                                      "'Print out the contents of the table'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  variant: "link",
                                                  disabled: _vm.isLoading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.printTable(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-print pointer dimmedIcon"
                                                })
                                              ]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      "Export the contents of the table",
                                                    expression:
                                                      "'Export the contents of the table'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  variant: "link",
                                                  disabled: _vm.isLoading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.exportTable(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-share-square pointer dimmedIcon"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }