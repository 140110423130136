var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showFileBrowser = false
                _vm.closeFilePopup()
              }
            }
          })
        : _vm._e(),
      _vm._m(0),
      _c("hr"),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Service")]),
          _c("br"),
          _c(
            "p-radio",
            {
              attrs: { value: "BACS", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s2.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                },
                expression: "selectedNode.props.s2.value"
              }
            },
            [_vm._v("BACS")]
          ),
          _c(
            "p-radio",
            {
              attrs: { value: "Faster Payments", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s2.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                },
                expression: "selectedNode.props.s2.value"
              }
            },
            [_vm._v("Faster Payments")]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Submission Type")]),
        _c("br"),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedNode.props.s3.value,
                expression: "selectedNode.props.s3.value"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.selectedNode.props.s3,
                  "value",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", [_vm._v("Live")]),
            _c("option", [_vm._v("Structural Test")]),
            _vm.selectedNode.props.s2.value === "BACS"
              ? _c("option", [_vm._v("Full Test")])
              : _vm._e()
          ]
        ),
        !_vm.$v.selectedNode.props.s3.value.required &&
        _vm.$v.selectedNode.props.s3.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("A valid Submission Type is required!")
            ])
          : _vm._e()
      ]),
      _c("br"),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Download Format")]),
          _c("br"),
          _c(
            "p-radio",
            {
              attrs: { value: "XML", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s5.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                },
                expression: "selectedNode.props.s5.value"
              }
            },
            [_vm._v("XML")]
          ),
          _c(
            "p-radio",
            {
              attrs: { value: "HTML", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s5.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                },
                expression: "selectedNode.props.s5.value"
              }
            },
            [_vm._v("HTML")]
          ),
          _c(
            "p-radio",
            {
              attrs: { value: "PDF", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s5.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                },
                expression: "selectedNode.props.s5.value"
              }
            },
            [_vm._v("PDF")]
          )
        ],
        1
      ),
      _c("br"),
      _c("label", { staticClass: "required" }, [
        _vm._v("Save path (Including the filename)")
      ]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s6.value,
              expression: "selectedNode.props.s6.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s6.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s6.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s6,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s6.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFileBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s6.value.required &&
      _vm.$v.selectedNode.props.s6.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The Save Path field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s6.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s3.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v(" Send Submission "),
      _c("span", { staticClass: "pull-right" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("i", { staticClass: "far fa-question-circle" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Submission Summary")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }