var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", [
                _c(
                  "div",
                  [
                    _vm.nodeType === "workflowStart"
                      ? _c("WorkflowStartUi")
                      : _vm._e(),
                    _vm.nodeType === "copyFile" ? _c("CopyFileUi") : _vm._e(),
                    _vm.nodeType === "moveFile" ? _c("MoveFileUi") : _vm._e(),
                    _vm.nodeType === "renameFile"
                      ? _c("RenameFileUi")
                      : _vm._e(),
                    _vm.nodeType === "deleteFile"
                      ? _c("DeleteFileUi")
                      : _vm._e(),
                    _vm.nodeType === "fileJoiner"
                      ? _c("FileJoinerUi")
                      : _vm._e(),
                    _vm.nodeType === "fileSplitter"
                      ? _c("FileSplitterUi")
                      : _vm._e(),
                    _vm.nodeType === "emailFile" ? _c("EmailFileUi") : _vm._e(),
                    _vm.nodeType === "ftpFile" ? _c("FtpFileUi") : _vm._e(),
                    _vm.nodeType === "sftpFile" ? _c("SftpFileUi") : _vm._e(),
                    _vm.nodeType === "zipCompression"
                      ? _c("ZipFileUi")
                      : _vm._e(),
                    _vm.nodeType === "zipDecompression"
                      ? _c("UnzipFileUi")
                      : _vm._e(),
                    _vm.nodeType === "encryptFile"
                      ? _c("EncryptFileUi")
                      : _vm._e(),
                    _vm.nodeType === "decryptFile"
                      ? _c("DecryptFileUi")
                      : _vm._e(),
                    _vm.nodeType === "filenameVariable"
                      ? _c("FilenameVariableUi")
                      : _vm._e(),
                    _vm.nodeType === "delay" ? _c("DelayUi") : _vm._e(),
                    _vm.nodeType === "rule" ? _c("RulesUi") : _vm._e(),
                    _vm.nodeType === "bacsPreSubValidation"
                      ? _c("SubmissionValidationUi")
                      : _vm._e(),
                    _vm.nodeType === "bacsSignSubmission"
                      ? _c("SignSubmissionUi")
                      : _vm._e(),
                    _vm.nodeType === "bacsApproveSubmission"
                      ? _c("ApproveSubmissionUi")
                      : _vm._e()
                  ],
                  1
                ),
                _vm.nodeType === "bacsSubmission"
                  ? _c("SendSubmissionUi")
                  : _vm._e(),
                _vm.nodeType === "bacsReportDownload"
                  ? _c("BacsReportDownloadUi")
                  : _vm._e(),
                _vm.nodeType === "emailMessage"
                  ? _c("EmailMessageUi")
                  : _vm._e(),
                _vm.nodeType === "webhook" ? _c("WebhookUi") : _vm._e(),
                _vm.nodeType === "sendTweet" ? _c("SendTweetUi") : _vm._e(),
                _vm.nodeType === "smsMessage" ? _c("SmsMessageUi") : _vm._e(),
                _vm.nodeType === "addToWorkflowLog"
                  ? _c("LogEntryUi")
                  : _vm._e(),
                _vm.nodeType === "addToAuditLog"
                  ? _c("AuditLogEntryUi")
                  : _vm._e(),
                _vm.nodeType === "collectionsReportReconciliation"
                  ? _c("CollectionsReportReconciliationUi")
                  : _vm._e(),
                _vm.nodeType === "collectionsProcessor"
                  ? _c("CollectionsProcessorUi")
                  : _vm._e(),
                _vm.nodeType === "mapping" ? _c("MappingUi") : _vm._e(),
                _vm.nodeType === "workflow" ? _c("WorkflowUi") : _vm._e(),
                _vm.nodeType === "exporter" ? _c("ExporterUi") : _vm._e()
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }