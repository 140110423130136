<template>
  <div>
    <!-- Service User Number -->
    <div class="form-group row" :class="{invalid: details.serviceUserNumber.$error}">
      <label class="col-form-label col-md-3 required">Service User Number Test</label>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model.trim="details.serviceUserNumber.$model" />
        <validation-messages v-model="details.serviceUserNumber" name="service user number">
          <small
            class="form-text small"
            v-if="details.serviceUserNumber.sunValidator != undefined && !details.serviceUserNumber.sunValidator"
          >Incorrect sun format</small>
        </validation-messages>
        <small
          class="form-text text-muted"
        >The format for a Service User Number is 6 numbers or a B followed by 5 numbers.</small>
        <!-- Validation -->
      </div>
    </div>

    <!-- Payment Signing Method -->
    <div class="form-group row" :class="{invalid: details.tokenType.$error}">
      <label class="label-control col-md-3 required">Payment Signing Method</label>
      <div class="col-md-5" v-if="hasHsm">
        <p-radio
          class="p-default p-round"
          value="Hsm"
          v-model="details.tokenType.$model"
          :disabled="disabled"
        >HSM</p-radio>
        <p-radio
          class="p-default p-round"
          value="SmartCard"
          v-model="details.tokenType.$model"
          :disabled="disabled"
        >Smart Card</p-radio>
        <validation-messages v-model="details.tokenType" name="payment signing method"></validation-messages>
      </div>
      <div class="col-md-5" v-else>Smart Card</div>
    </div>

    <!-- HSM Certificate -->
    <div
      class="form-group row"
      :class="{invalid: details.certificateId.$error,warn: !selectedHSMCert.valid}"
      v-if="details.tokenType.$model == 'Hsm'"
    >
      <label class="label-control col-md-3 required">HSM Certificate</label>
      <div class="col-md-5">
        <b-form-select v-model="details.certificateId.$model" :options="hsmCertificates"></b-form-select>
        <validation-messages v-model="details.certificateId" name="HSM certificates"></validation-messages>
        <span class="validation-messages" v-if="!selectedHSMCert.valid">
          <small class="form-text small">Certificate is expired</small>
        </span>
      </div>
    </div>

    <!-- Individual Payment Limit -->
    <div class="form-group row" :class="{invalid: details.paymentLimit.$error}">
      <label class="col-form-label col-md-3">Individual Payment Limit</label>
      <div class="col-md-3">
        <input type="number" class="form-control" v-model.trim="details.paymentLimit.$model" />
        <!-- Validation -->
        <validation-messages v-model="details.paymentLimit" name="Individual payment limit">
          <small
            class="form-text small"
            v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
          >individual item limit can only be in whole pounds</small>
        </validation-messages>
        <small class="form-text text-muted">Set to 0 for no limit</small>
      </div>
    </div>

    <!-- Submission  Limit -->
    <div class="form-group row" :class="{invalid: details.submissionLimit.$error}">
      <label class="col-form-label col-md-3">Submission Payment Limit</label>
      <div class="col-md-3">
        <input type="number" class="form-control" v-model.trim="details.submissionLimit.$model" />
        <!-- Validation -->
        <validation-messages v-model="details.submissionLimit" name="Submission payment limit">
          <small
            class="form-text small"
            v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
          >submission item limit can only be in whole pounds</small>
        </validation-messages>
        <small class="form-text text-muted">Set to 0 for no limit</small>
      </div>
    </div>

    <!-- Delete Payment Data -->
    <div class="form-group row">
      <label class="col-form-label col-md-3">Delete Payment Data</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.deletePaymentData.$model"
        ></p-check>
      </div>
    </div>

    <!-- HSM PIN required -->
    <div v-show="details.tokenType.$model === 'Hsm'" class="form-group row">
      <label class="col-form-label col-md-3">HSM PIN Required</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.hsmPinRequired.$model"
        ></p-check>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  props: ['details', 'disabled', 'status'],
  computed: {
    selectedHSMCert () {
      var cert = this.hsmCertificates.find(c => c.value === this.details.certificateId.$model)

      if (cert == null) {
        return { valid: true }
      }

      return cert
    }
  },
  data () {
    return {
      hsmCertificates: []
    }
  },
  async created () {
    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, { params: { paygateid: this.$store.state.common.paygateId }, showerror: true, errormessage: 'Failed to get customer certificates' })
      this.hsmCertificates = response.data.map(certificate => {
        var validTo = moment(certificate.validTo)

        var valid = true
        if (moment().isAfter(validTo)) {
          valid = false
        }
        return { value: certificate.certificateId, text: certificate.friendlyName, valid }
      })

      if (this.status === 'Create') {
        this.hsmCertificates = this.hsmCertificates.filter(c => c.valid)
      }
    } catch { }
  }
}
</script>
