<template>
  <div class="idb-block">
    <loading v-bind:loading="loading"></loading>
    <div class="idb-block-title">
      <h2>
        Select bureau customer import file
        <span class="pull-right">
          <a href="#" target="_blank">
            <i class="far fa-question-circle"></i>
          </a>
        </span>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <div class="ml-5 mt-3" hidden>
      <b-row>Paygate Id = {{ this.paygateId }}</b-row>
    </div>

    <div class="idb-block-content">
      <b-row>
        <b-col sm="12">
          <dropzone
            id="my-dropzone"
            ref="fileUploader"
            :options="dropzoneOptions"
            @vdropzone-success="afterSuccess"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="fileRemoved"
            @vdropzone-sending="sendingEvent"
            @vdropzone-error="dropZoneError"
            :use-custom-slot="true"
            :useCustomSlot="true"
            :max-file-size-in-m-b="120"
            :max-number-of-files="10"
          >
            <div class="dropzone-custom-content">
              <h3
                class="dropzone-custom-title"
              >Drag and drop to upload your customer files for importing</h3>
              <div class="subtitle">...or click to select a file from your computer</div>
            </div>
          </dropzone>
        </b-col>
      </b-row>

      <br />

      <vue-good-table
        ref="customerImportFiles"
        :paginationOptions="{
          enabled: true,
          perPage: 10,
        }"
        :rows="importFiles"
        :columns="columns"
        :lineNumbers="true"
        :sort-options="{
          enabled: true,
        }"
        styleClass="vgt-table striped bordered"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'mapping'">
            <b-row>
              <b-col sm="12">
                <model-list-select
                  :id="'mappingSelector-' + props.row.id"
                  :list="mappings"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.mapping"
                  placeholder="Mapping"
                  :isDisabled="props.row.status !== 'Uploaded'"
                  @input="updateFileMapping(props.row)"
                ></model-list-select>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <span v-if="props.row.status == 'Uploaded'">
              <b-button
                v-if="props.row.mapping === ''"
                class="fa fa-file-import mr-1 disabled"
                id="Disabled"
                title="Please select a mapping"
                style="color: grey; padding: 0; border: none; background: none"
              >Please select mapping</b-button>

              <b-button
                v-else
                class="btn btn-primary ml-1"
                @click.stop="
                  onImportClick(props.row.bureauCustomerImportFileId)
                "
              >Import</b-button>
            </span>
            <span v-else-if="props.row.status == 'Editing'">
              <a
                class="btn btn-sm primary mt-1"
                @click.stop="
                  onDeleteFileClick(
                    props.row.bureauCustomerImportFileId,
                    props.row.filename
                  )
                "
              >
                <i class="fa fa-trash"></i>
              </a>
              <a
                class="btn btn-sm default mt-1"
                @click.stop="
                  onVerifyFileClick(props.row.bureauCustomerImportFileId)
                "
              >
                <i class="fa fa-arrow-right" />
              </a>
            </span>
            <span v-else>
              <small>
                <i>Please wait</i>
              </small>
            </span>
          </span>
          <span v-else>
            <b-row>
              <b-col sm="12" class="mt-2">
                {{
                props.formattedRow[props.column.field]
                }}
              </b-col>
            </b-row>
          </span>
        </template>

        <div slot="table-actions">
          <b-button
            @click.prevent="load"
            class
            variant="link"
            title="Refresh Table"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="printTable"
            class
            variant="link"
            title="Print Table"
            v-b-popover.hover.top.d500="
              'Print out the contents of the Logs table'
            "
          >
            <i class="fa fa-print pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            title="Export Table"
            v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
          >
            <i class="fa fa-file-export pointer dimmedIcon"></i>
          </b-button>
        </div>
      </vue-good-table>
    </div>

    <!-- <div class="idb-block-footer">
      <button class="btn btn-primary ml-1" @click="onImportClick()">
        Import
      </button>
    </div>-->

    <br />
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import Dropzone from 'vue2-dropzone'
import swal from 'sweetalert2'
import Utility from '@/Assets/Mixins/Utility'
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import colours from '@/Assets/Constants/colours'

export default {
  data () {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/UploadCustomerImportFile'
      },
      importFiles: [],
      columns: [
        {
          label: 'Id',
          field: 'bureauCustomerImportFileId',
          hidden: true
        },
        {
          label: 'Filename',
          field: 'filename',
          sortable: true
        },
        {
          label: 'Mapping',
          field: 'mapping',
          sortable: false
        },
        {
          label: 'Status',
          field: 'bureauFileStatus'
        },
        {
          field: 'actions',
          sortable: false
        }
      ],
      mappings: [],
      loading: false,
      BureauFileStatusHubUrl: '',
      BureauFileStatusConnection: null
    }
  },
  computed: {
    paygateId () { return this.$store.state.common.paygateId }
  },
  components: {
    Dropzone,
    ModelListSelect
  },
  mixins: [Utility],

  async mounted () {
    this.mappings = await this.loadMappings('bureauCustomerImporter')
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.BureauFileStatusConnection = new HubConnectionBuilder().withUrl(this.BureauFileStatusHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.BureauFileStatusConnection.start()
        this.BureauFileStatusConnection.on('RefreshBureauFileStatus', data => {
          console.log('signalR says: ', data)
          this.updateImportFileStatus(data)
        })
      })
  },
  created () {
    this.BureauFileStatusHubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/bureaufilestatushub'
    this.load()
  },

  methods: {
    async afterSuccess (file, response) {
      this.$toastr.s('File uploaded ' + file.name + ' successfully', 'Bureau Customer Import')
      await this.load()

      // this.importFiles.push(response)
      // file.previewElement.outerHTML = ''
    },
    async fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileRemoved (file, error, xhr) {
    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
    },
    dropZoneError (file, error, request) {
      this.$toastr.e('File upload ' + file.name + ' failed', 'Bureau Customer Import')
      console.error('Error in UploadFiles.DropZoneError')
      console.error(error)
    },
    template () {
      return `<div class="dz-preview dz-file-preview" style="width: 100%;">
                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-name style="padding: 1px 20px 0px;"></span><span data-dz-size></span>
                      <span data-dz-errormessage style="color: yellow;"></span>
                      <span><i class="fa fa-trash float-right" data-dz-remove></i></span>
                    </div>
                    <div class="dz-progress">
                      <span class="dz-upload" data-dz-uploadprogress></span>
                    </div>
                  </div>
                  <div class="dz-error-mark"><span><i class="fa fa-times"></i></span></div>
              </div>`
    },
    async onDeleteFileClick (id, filename) {
      var result = await swal({
        title: 'Delete Payment File',
        text: 'Are you sure you want to delete the file: ' + filename + ', this will delete the file from PayGate',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result) {
        try {
          // Delete from database and file system
          var response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/deleteImportFile/' + id, { showload: true })
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
          this.loading = false
        } finally {
          await this.load()
        }
      }
    },
    async load () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/getcustomerimportfiles/' + this.paygateId, { showload: true })
        this.importFiles = response.data
      } catch (error) {
        console.error('Error in Import.Load')
        console.error(error)
      }
    },
    async updateFileMapping (customerImportFile) {
      try {
        var response = await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/updateFileMapping', customerImportFile, { showload: true })
        this.$toastr.s(`Updated ${customerImportFile.filename}`, 'Bureau Customer Import')
      } catch (error) {
        this.$toastr.e(`Failed to update ${customerImportFile.filename}`, 'Bureau Customer Import')
        console.error(error)
      }
    },

    async onImportClick (id) {
      try {
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/importfile/' + id, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
        this.loading = false
      } finally {
        this.loading = false
      }
      await this.load()
    },

    updateImportFileStatus (data) {
      for (var j = 0; j < this.importFiles.length; j++) {
        if (this.importFiles[j].bureauCustomerImportFileId === data.importSessionId) {
          this.importFiles[j].bureauFileStatus = data.bureauFileStatus
          this.importFiles[j].status = data.bureauFileStatus
        }
      }
    },

    async onVerifyFileClick (id) {
      // need to put this into the route for the importbureaucustomerverify
      this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: id } })
    }
  }
}
</script>

<style scoped>
.dz-preview {
  min-height: 60px !important;
  margin: 5px !important;
}

.dz-details {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  height: 60px !important;
}

.dz-remove {
  visibility: hidden !important;
}

.vue-dropzone .dz-preview .dz-error-mark,
.vue-dropzone .dz-preview .dz-error-mark i {
  font-size: 1rem !important;
  margin-top: -3px !important;
  margin-left: 7px !important;
  color: red !important;
}

.dropzone .dz-preview .dz-progress {
  left: 80%;
}
</style>
