var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" Create a New Bureau Job "),
              _c("help-icon"),
              _c("favourite-icon")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { sm: "2" } }, [
                  _c(
                    "label",
                    { staticClass: "mt-2", attrs: { for: "filter" } },
                    [_vm._v("Group")]
                  )
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("group-select", {
                      attrs: { groups: _vm.groups, clearable: false },
                      on: { input: _vm.SilentSave },
                      model: {
                        value: _vm.bureauJob.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.bureauJob, "groupId", $$v)
                        },
                        expression: "bureauJob.groupId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-btn",
                      {
                        ref: "btnShow",
                        staticClass: "float-right",
                        attrs: { variant: "primary", disabled: _vm.isLoading },
                        on: {
                          click: function($event) {
                            return _vm.showCustomerSelector()
                          }
                        }
                      },
                      [_vm._v("Bulk Insert")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", { staticClass: "mt-2", attrs: { sm: "2" } }, [
                  _c("label", { attrs: { for: "name" } }, [_vm._v("Job Name")])
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      on: { change: _vm.SilentSave },
                      model: {
                        value: _vm.bureauJob.name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.bureauJob,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "bureauJob.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-row", [_c("hr")]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          id: "jobDetailsTable",
                          paginationOptions: _vm.paginationOptions,
                          "sort-options": _vm.sortOptions,
                          rows: _vm.rows,
                          columns: _vm.columns,
                          searchOptions: { enabled: true },
                          mode: "remote",
                          totalRows: _vm.totalRecords,
                          isLoading: _vm.isTableLoading,
                          styleClass: "vgt-table striped bordered"
                        },
                        on: {
                          "on-page-change": _vm.onPageChange,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch,
                          "on-sort-change": _vm.onSortChange,
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "actions"
                                  ? _c("span", [
                                      _c("button", {
                                        staticClass: "fa fa-copy mr1",
                                        staticStyle: {
                                          color: "orange",
                                          padding: "10",
                                          border: "none",
                                          background: "none"
                                        },
                                        attrs: {
                                          id: "onDuplicateJobDetailClick",
                                          title: "Duplicate Job Detail",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.onDuplicateJobDetailClick(
                                              props.row
                                            )
                                          }
                                        }
                                      }),
                                      _c("button", {
                                        staticClass: "fa fa-trash-alt mr1",
                                        staticStyle: {
                                          color: "red",
                                          padding: "10",
                                          border: "none",
                                          background: "none"
                                        },
                                        attrs: {
                                          id: "onDeleteJobDetailClick",
                                          title: "Delete Job Detail",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onDeleteJobDetailClick(
                                              props.row
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onCellClick(props.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Add Job Detail",
                                    expression: "'Add Job Detail'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onAddNewJobDetailClick($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus-circle pointer"
                                }),
                                _vm._v(" Add Job Detail ")
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                })
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "Print out the contents of the Logs table",
                                    expression:
                                      "'Print out the contents of the Logs table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.printTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-print pointer dimmedIcon"
                                })
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "Export the contents of the Logs table",
                                    expression:
                                      "'Export the contents of the Logs table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.exportTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-file-export pointer dimmedIcon"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.onSave()
                  }
                }
              },
              [_vm._v("Save")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _c("addBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.bureauJob.bureauJobId,
              bureauCustomers: _vm.bureauCustomerDisplay
            },
            on: { submit: _vm.addBureauJobDetailClosed }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }