<template>
  <div>
    <form @submit.prevent="checkValidation() && searchGDPR()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            General Data Protection Regulation (GDPR) Search
            <help-icon docPath="/administration/gdpr/" />
            <favourite-icon></favourite-icon>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="container-fluid">
            <div class="row">
              <p>Please note you must enter a First Name and/or Last Name to Redact Personal Information, in addition to Sort Code and Account Number.</p>
            </div>
            <div class="row">
              <!-- Sort Code -->
              <div class="form-group col" :class="{invalid: $v.search.sortCode.$error}">
                <label class="required">Sort Code</label>

                <the-mask
                  type="text"
                  class="form-control"
                  placeholder="Sort Code"
                  :mask="['##-##-##']"
                  :guide="false"
                  v-model="$v.search.sortCode.$model"
                />
                <!-- Validation -->
                <validation-messages v-model="$v.search.sortCode" name="Sort Code">
                  <small
                    class="form-text small"
                    v-if="$v.search.sortCode.exactLengthCustom != undefined && !$v.search.sortCode.exactLengthCustom"
                  >The sort code needs to be {{ $v.search.sortCode.$params.exactLengthCustom.exact }} numbers long</small>
                </validation-messages>
              </div>

              <!-- Account Number -->
              <div class="form-group col" :class="{invalid: $v.search.accountNumber.$error}">
                <label class="required">Bank Account Number</label>
                <the-mask
                  type="text"
                  class="form-control"
                  placeholder="Account Number"
                  :mask="['########']"
                  :guide="false"
                  v-model="$v.search.accountNumber.$model"
                />
                <!-- Validation -->
                <validation-messages v-model="$v.search.accountNumber" name="Bank Account Number">
                  <small
                    class="form-text small"
                    v-if="$v.search.accountNumber.exactLengthCustom != undefined && !$v.search.accountNumber.exactLengthCustom"
                  >The bank account number needs to be {{ $v.search.accountNumber.$params.exactLengthCustom.exact }} numbers long</small>
                </validation-messages>
              </div>
            </div>

            <div class="row">
              <!-- First Name -->
              <div class="form-group col" :class="{invalid: $v.search.firstName.$error}">
                <label>First Name</label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  v-model.trim="$v.search.firstName.$model"
                />
                <!-- Validation -->
                <validation-messages v-model="$v.search.firstName" name="First Name"></validation-messages>
              </div>

              <!-- Last Name -->
              <div class="form-group col" :class="{invalid: $v.search.lastName.$error}">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  v-model.trim="$v.search.lastName.$model"
                />
                <!-- Validation -->
                <validation-messages v-model="$v.search.lastName" name="Last Name"></validation-messages>
              </div>
            </div>

            <div class="row">
              <!-- Email-->
              <div class="form-group col-6" :class="{invalid: $v.search.email.$error}">
                <label>Email</label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  v-model.trim="$v.search.email.$model"
                />
                <!-- Validation -->
                <validation-messages v-model="$v.search.email" name="Email"></validation-messages>
              </div>
            </div>

            <div class="row" v-if="anyResults && finishedSearching">
              <div class="alert alert-warning col" role="alert">
                <h4
                  class="alert-heading"
                >Important - The information shown here is a sample of the data available, an export may contain additional data including notes</h4>
                <div>Files in your Secure Cloud folders are not searched for personal information and should be reviewed manually.</div>
                <div v-show="!firstNameOrLastName">
                  <hr />You have not entered a first and/or last name so are unable to redact personal information, once entered you must search again
                </div>
              </div>
            </div>
            <div class="row" v-if="anyResults && longRedaction">
              <div class="alert alert-info col" role="alert">
                <h4 class="alert-heading">This process is taking a while</h4>
                <div>Please be patient, redacting data can take a long while to complete, be assured it is still running.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="idb-block-footer button-footer">
          <button class="btn btn-primary" type="submit" :disabled="isLoading">Search</button>
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="fullClear"
            :disabled="isLoading"
          >Clear</button>
          <button
            class="btn btn-info"
            type="button"
            @click="save"
            v-if="finishedSearching && anyResults"
            :disabled="isLoading"
          >Export</button>

          <button
            class="btn btn-danger pull-right"
            type="button"
            @click="redactData"
            v-if="finishedSearching && anyResults"
            :disabled="isLoading || !firstNameOrLastName"
          >Redact Personal Information</button>
        </div>
      </div>
    </form>

    <div class="idb-block" v-if="payments.some(() => true)">
      <div class="idb-block-title">
        <h2>BACS/Faster Payment Records</h2>
      </div>
      <div class="idb-block-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="alert alert-light col mb-0" role="alert">
                <div>These are payment made through BACS or Faster Payments, if they are historical they can be redacted</div>
              </div>
              <vue-good-table
                :paginationOptions="{
                      enabled: true,
                      perPage: 10,
                      perPageDropdown: [10, 20, 30, 40, 50],
                      dropdownAllowAll:false
                    }"
                :rows="payments"
                :columns="paymentsColumns"
                :lineNumbers="true"
                :sort-options="{
                      enabled: true,
                      initialSortBy: [{ field: 'processingDate', type: 'desc' }]
                    }"
                styleClass="vgt-table striped bordered"
              ></vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="idb-block"
      v-if="collectionsPayers.some(() => true) || collectionsAuddis.some(() => true) || collectionsScheduledPayments.some(() => true)"
    >
      <div class="idb-block-title">
        <h2>Collections</h2>
      </div>
      <div class="idb-block-content">
        <div class="container-fluid">
          <!-- Payers -->
          <div class="row" v-if="collectionsPayers.some(() => true)">
            <div class="col">
              <div class="idb-block">
                <div class="idb-block-title" v-b-toggle.collectionsPayersCollapse>
                  Payers
                  <i class="fa fa-chevron-down"></i>
                  <i class="fa fa-chevron-up"></i>
                </div>
                <b-collapse id="collectionsPayersCollapse" visible>
                  <div class="alert alert-light col mb-0" role="alert">
                    <div>These are required for current direct debits, closed payers can be removed, to close a payer use the DDMS Payers screen</div>
                  </div>
                  <vue-good-table
                    :paginationOptions="{
                      enabled: true,
                      perPage: 10,
                      perPageDropdown: [10, 20, 30, 40, 50],
                      dropdownAllowAll:false
                    }"
                    :rows="collectionsPayers"
                    :columns="collectionsPayersColumns"
                    :lineNumbers="true"
                    :sort-options="{
                      enabled: true
                    }"
                    styleClass="vgt-table striped bordered"
                  ></vue-good-table>
                </b-collapse>
              </div>
            </div>
          </div>
          <!-- AUDDIS Instructions -->
          <div class="row" v-if="collectionsAuddis.some(() => true)">
            <div class="col">
              <div class="idb-block">
                <div class="idb-block-title" v-b-toggle.collectionsAuddisCollapse>
                  AUDDIS Instructions
                  <i class="fa fa-chevron-down"></i>
                  <i class="fa fa-chevron-up"></i>
                </div>
                <b-collapse id="collectionsAuddisCollapse" visible>
                  <div class="alert alert-light col mb-0" role="alert">
                    <div>These are pending payment instructions for live payments, they cannot be redacted as they are required for current processes</div>
                  </div>
                  <vue-good-table
                    :paginationOptions="{
                      enabled: true,
                      perPage: 10,
                      perPageDropdown: [10, 20, 30, 40, 50],
                      dropdownAllowAll:false
                    }"
                    :rows="collectionsAuddis"
                    :columns="collectionsAuddisColumns"
                    :lineNumbers="true"
                    :sort-options="{
                      enabled: true
                    }"
                    styleClass="vgt-table striped bordered"
                  ></vue-good-table>
                </b-collapse>
              </div>
            </div>
          </div>
          <!-- Scheduled Payments -->
          <div class="row" v-if="collectionsScheduledPayments.some(() => true)">
            <div class="col">
              <div class="idb-block">
                <div class="idb-block-title" v-b-toggle.collectionsScheduledPaymentsCollapse>
                  Scheduled Payments
                  <i class="fa fa-chevron-down"></i>
                  <i class="fa fa-chevron-up"></i>
                </div>
                <b-collapse id="collectionsScheduledPaymentsCollapse" visible>
                  <div class="alert alert-light col mb-0" role="alert">
                    <div>These are scheduled payments and cannot be removed</div>
                  </div>
                  <vue-good-table
                    :paginationOptions="{
                      enabled: true,
                      perPage: 10,
                      perPageDropdown: [10, 20, 30, 40, 50],
                      dropdownAllowAll:false
                    }"
                    :rows="collectionsScheduledPayments"
                    :columns="collectionsScheduledPaymentsColumns"
                    :lineNumbers="true"
                    :sort-options="{
                      enabled: true
                    }"
                    styleClass="vgt-table striped bordered"
                  ></vue-good-table>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="idb-block" v-if="audits.some(() => true)">
      <div class="idb-block-title">
        <h2>Audit Log</h2>
      </div>
      <div class="idb-block-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="alert alert-light col mb-0" role="alert">
                <div>These are audit entries containing personal data, they cannot be removed as they are required for fraud prevention</div>
              </div>
              <vue-good-table
                :paginationOptions="{
                      enabled: true,
                      perPage: 10,
                      perPageDropdown: [10, 20, 30, 40, 50],
                      dropdownAllowAll:false
                    }"
                :rows="audits"
                :columns="auditsColumns"
                :lineNumbers="true"
                :sort-options="{
                      enabled: true
                    }"
                styleClass="vgt-table striped bordered"
              ></vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { exactLength, email } from '@/Assets/Validators'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import { TheMask } from 'vue-the-mask'
import FileSaver from 'file-saver'
import { formatDate } from '@/Assets/Helpers/DateHelper'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [loading],
  components: {
    TheMask
  },
  name: 'GDPRSearch',
  computed: {
    anyResults () {
      return this.collectionsPayers.some(() => true) ||
        this.collectionsAuddis.some(() => true) ||
        this.collectionsScheduledPayments.some(() => true) ||
        this.audits.some(() => true) ||
        this.payments.some(() => true)
    },
    firstNameOrLastName () {
      return !!this.searched.firstName || !!this.searched.lastName
    }
  },
  data () {
    return {
      finishedSearching: false,
      longRedaction: false,
      search: {
        sortCode: null,
        accountNumber: null,
        firstName: null,
        lastName: null,
        email: null
      },
      searched: {
        firstName: null,
        lastName: null
      },
      payments: [],
      paymentsColumns: [
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Account Name',
          field: 'thirdPartyAccountName'
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber'
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode'
        },
        {
          label: 'Network Type',
          field: 'paymentNetworkType'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (value) => formatDate(value, 'DD/MM/YYY')
        },
        {
          label: 'Submission Status',
          field: 'submissionStatus'
        }
      ],
      collectionsPayers: [],
      collectionsPayersColumns: [
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'First Name',
          field: 'firstname'
        },
        {
          label: 'Last Name',
          field: 'surname'
        },
        {
          label: 'Postcode',
          field: 'postcode'
        },
        {
          label: 'Sortcode',
          field: 'sortcode'
        },
        {
          label: 'Bank Account',
          field: 'accountNumber'
        },
        {
          label: 'Telephone',
          field: 'tel1'
        },
        {
          label: 'Mobile',
          field: 'mobile'
        },
        {
          label: 'Status',
          field: 'status'
        }
      ],
      collectionsAuddis: [],
      collectionsAuddisColumns: [
        {
          label: 'Account Holder',
          field: 'accountHoldersName'
        },
        {
          label: 'First Name',
          field: 'firstname'
        }, {
          label: 'Last Name',
          field: 'surname'
        },
        {
          label: 'Collection Date',
          field: 'collectionDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'Do MMMM yyyy'
        },
        {
          label: 'Sortcode',
          field: 'sortcode'
        },
        {
          label: 'Bank Account',
          field: 'accountNumber'
        },
        {
          label: 'Amount',
          field: 'amount'
        }
      ],
      collectionsScheduledPayments: [],
      collectionsScheduledPaymentsColumns: [
        {
          label: 'Payer Reference',
          field: 'accountHoldersName'
        },
        {
          label: 'First Name',
          field: 'firstname'
        },
        {
          label: 'Last Name',
          field: 'surname'
        },
        {
          label: 'Account Holder',
          field: 'accountHoldersName'
        },
        {
          label: 'Sortcode',
          field: 'sortcode'
        },
        {
          label: 'Bank Account',
          field: 'accountNumber'
        },
        {
          label: 'Collection Date',
          field: 'collectionDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'Do MMMM yyyy'
        },
        {
          label: 'Amount',
          field: 'amount'
        }
      ],
      audits: [],
      auditsColumns: [
        {
          label: 'Code',
          field: 'auditCode'
        },
        {
          label: 'type',
          field: 'auditType'
        },
        {
          label: 'Title',
          field: 'auditTitle'
        },
        {
          label: 'Body',
          field: 'auditBody'
        },
        {
          label: 'Date',
          field: 'createdDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss '
        }
      ]
    }
  },
  methods: {
    async searchGDPR () {
      this.clear()
      this.finishedSearching = false
      this.longRedaction = false
      var failed = false

      try {
        this.$Progress.start()
        var paymentsPromise = axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}GDPR/Payments`, this.search, { showerror: true, errormessage: 'Failed to search payments' })
        var collectionsPromise = axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}GDPR/Collections`, this.search, { showerror: true, errormessage: 'Failed to search collections' })
        var auditsPromise = axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}GDPR/Audits`, this.search, { showerror: true, errormessage: 'Failed to search audits' })
        // We need this to disable the redact personal information bit
        this.searched.firstName = this.search.firstName
        this.searched.lastName = this.search.lastName

        var results = await Promise.all([collectionsPromise, auditsPromise, paymentsPromise])
        var paymentsResponse = results[2].data
        this.payments = paymentsResponse.gdprPayments
        var collectionsResponse = results[0].data
        this.collectionsPayers = collectionsResponse.gdprCollectionsUkPayers
        this.collectionsAuddis = collectionsResponse.gdprAuddisInstructions
        this.collectionsScheduledPayments = collectionsResponse.gdprScheduledPayments

        this.audits = results[1].data
      } catch {
        this.$Progress.fail()
        failed = true
      } finally {
        this.finishedSearching = true
        this.$Progress.finish()
        if (!failed) {
          if ((this.payments.some(() => true) || this.collectionsPayers.some(() => true) || this.collectionsAuddis.some(() => true) || this.collectionsScheduledPayments.some(() => true) || this.audits.some(() => true))) {
            this.$toastr.s('GDPR search has identified data matching the selected search criteria', 'Found Results')
          } else {
            this.$toastr.w('GDPR search has identified NO data matching the selected search criteria', 'No Results')
          }
        }
      }
    },
    async save () {
      try {
        this.$Progress.start()
        const now = new Date()
        const json = {
          reportDate: now,
          collections: {
            UkPayers: this.collectionsPayers,
            auddis: this.collectionsAuddis,
            scheduledPayments: this.collectionsScheduledPayments
          },
          payments: this.payments,
          audits: this.audits
        }
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}GDPR/Report`, this.search, { showerror: true })
        var file = new Blob([JSON.stringify(json)], { type: 'text/plain' })
        FileSaver.saveAs(file, `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-gdpr-report.json`)
      } catch {
        this.$Progress.fail()
        this.$toastr.e('There was a problem exporting', 'Failed')
      } finally {
        this.$Progress.finish()
      }
    },
    async redactData () {
      try {
        this.longRedaction = false
        // Get confirmation from the user that they really want to delete the customer
        var swalResult = await swal.fire({
          title: 'Redact Personal Information?',
          html: `<div>This will permanently redact this individual's personal information!</div> 
          <div>Information used for unsubmitted payments or needed for ongoing processes will not be redacted</div>
          <div class="mb-1"><strong>Are you REALLY sure you want to do this?strong></div>
          <div>Please type <code>Redact Data</code> to confirm </div>`,
          icon: 'warning',
          input: 'text',
          inputValidator: result => new Promise((resolve, reject) => {
            result.toLowerCase() === 'redact data' ? resolve() : resolve('You need to write "Redact Data" to confirm')
          }),
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Redact Data',
          cancelButtonText: 'Cancel'
        })

        if (!swalResult.isConfirmed) {
          throw new Error('Cancled')
        }

        this.$Progress.start()
        var paymentsPromise = axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}GDPR/Payments`, { data: { ...this.search } }, { showerror: true, errormessage: 'Failed to redact payment data' })
        var collectionsPromise = axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}GDPR/Collections`, { data: { ...this.search } }, { showerror: true, errormessage: 'Failed to redact collections data' })

        var timeout = setTimeout(() => {
          this.longRedaction = true
        }, 20000)

        try {
          await Promise.all([collectionsPromise, paymentsPromise])
          this.$toastr.s('Data will be redacted where possible', 'Redacted')
        } catch {
          this.$snapbar.e('Data Redaction has failed')
          this.$Progress.fail()
        } finally {
          this.$Progress.finish()
          await this.searchGDPR()
        }
      } catch (error) {
        this.$toastr.w('Data Redaction has been cancelled', 'Cancelled')
      } finally {
        if (timeout) {
          clearTimeout(timeout)
        }
        this.longRedaction = false
      }
    },
    clear () {
      this.payments = []
      this.collectionsPayers = []
      this.collectionsAuddis = []
      this.collectionsScheduledPayments = []
      this.audits = []
    },
    fullClear () {
      this.clear()
      this.search.sortCode = null
      this.search.accountNumber = null
      this.search.firstName = null
      this.search.lastName = null
      this.search.email = null
      this.$v.$reset()
    }
  },
  validations () {
    return {
      search: {
        sortCode: { required, exactLengthCustom: exactLength(6) },
        accountNumber: { required, exactLengthCustom: exactLength(8) },
        firstName: {},
        lastName: {},
        email: { email }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-chevron-up,
.fa-chevron-down {
  float: right;
}

.collapsed > .fa-chevron-up,
:not(.collapsed) > .fa-chevron-down {
  display: none;
}
</style>
