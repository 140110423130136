export default {
  agent: 'LicenceAgent',
  automation: 'LicenceAutomation',
  automation_workflow: 'LicenceAutomationWorkflow',
  bacs: 'LicenceBACS',
  bureau: 'LicenceBureau',
  collections: 'LicenceCollections',
  collectionsOnBoarding: 'LicenceCollectionsOnBoarding',
  collectionsPayerPortal: 'LicenceCollectionsPayerPortal',
  collectionsRefunds: 'LicenceCollectionsRefunds',
  fps: 'LicenceFPS',
  hsm: 'LicenceHSM',
  validate: 'LicenceValidate',
  validate_cc: 'LicenceValidateCC',
  validate_iban: 'LicenceValidateIban',
  validate_modcheck: 'LicenceValidateModcheck',
  mfa: 'LicenceMFA',
  mfa_authenticator: 'LicenceAuthenticator',
  mfa_email: 'LicenceMFAEmail',
  mfa_sms: 'LicenceMFASMS',
  mfa_usb_tokens: 'LicenceMFAUSBTokens',
  other_sms: 'LicenceOtherSms'

}
