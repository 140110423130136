<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Audit Details
          <help-icon docPath="/administration/customer/auditlogs#details" />
        </h2>
      </div>

      <div class="idb-block-content">
        <form>
          <div class="form-group row">
            <label for="auditId" class="col-sm-2 text-right col-form-label">Audit Tamper Detection</label>
            <div class="col-sm-10 form-control-plaintext" v-if="!loading">
              <div
                v-if="audit.imported"
              >The audit has been imported and is exempt from tamper protection</div>
              <div v-else-if="audit.checksumValid">
                <tick :value="true"></tick>&nbsp;The audit
                has
                <b>not</b> been tampered with
              </div>
              <div v-else-if="!audit.checksumValid">
                <tick :value="false"></tick>&nbsp;The audit
                <b>has</b>
                been tampered with
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="auditId" class="col-sm-2 text-right col-form-label">Audit ID</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="auditId"
                v-model="audit.auditId"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="auditCode" class="col-sm-2 text-right col-form-label">Audit Code</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="auditCode"
                v-model="audit.auditCode"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="auditSeverity" class="col-sm-2 text-right col-form-label">Audit Severity</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="auditSeverity"
                v-model="audit.severity"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="auditType" class="col-sm-2 text-right col-form-label">Audit Type</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="auditType"
                v-model="audit.auditType"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="auditTitle" class="col-sm-2 text-right col-form-label">Audit Title</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="auditTitle"
                v-model="audit.auditTitle"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="auditBody" class="col-sm-2 text-right col-form-label">Audit Body</label>
            <div class="col-sm-10">
              <p readonly class="form-control-plaintext" id="extraInfo">{{audit.auditBody}}</p>
            </div>
          </div>

          <div class="form-group row">
            <label for="extraInfo" class="col-sm-2 text-right col-form-label">Extra Info</label>
            <div class="col-sm-10">
              <p
                v-for="info in extraInfo"
                :key="info"
                readonly
                class="form-control-plaintext"
                id="extraInfo"
              >{{info}}</p>
            </div>
          </div>

          <div class="form-group row">
            <label for="createdDate" class="col-sm-2 text-right col-form-label">Created Date</label>
            <div class="col-sm-10">
              <span
                type="text"
                readonly
                class="form-control-plaintext"
                id="createdDate"
              >{{audit.createdDate|formatDate('DD/MM/YYYY HH:mm:ss')}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label for="username" class="col-sm-2 text-right col-form-label">Username</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="username"
                v-model="audit.username"
              />
            </div>
          </div>
        </form>
      </div>

      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="$router.go(-1)">Back</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: ['auditId'],
  computed: {
    ...mapGetters(['selectedCustomer']),
    extraInfo () {
      if (this.audit.extraInfo) {
        return this.audit.extraInfo.split('\\\\n')
      }
      return []
    }
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'CustomerAudits' }) }
  },
  data () {
    return {
      audit: {},
      loading: true
    }
  },
  mounted () {
    this.loadAudit()
  },
  methods: {
    async loadAudit () {
      try {
        this.loading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Audits/${this.auditId}`)
        this.audit = response.data
      } catch { } finally {
        this.loading = false
      }
    }
  }
}
</script>
