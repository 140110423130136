<template>
  <div id="manageSchedule">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/submissions/" />Process Submissions<favourite-icon></favourite-icon></h2>
          </div>
          <div class="idb-block-content">
            <b-container>
              <vue-good-table mode="remote"
              ref="table"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :columns="columns"
              :rows="submissions"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                enabled: true
                }"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              @on-row-click="onRowClick" @on-cell-click="handleClick" styleClass="vgt-table striped bordered">
              <template slot="loadingContent">
                <h1>Loading...</h1>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'viewBtn'">
                  <span style="font-weight: bold; color: blue;">View</span>
                </span>
                <span v-else-if="props.column.field == 'processBtn'">
                  <i v-if="props.row.submissionType == 'Collections' && props.row.flaggedPaymentsCount > 0" v-b-tooltip.hover :title="`${props.row.flaggedPaymentsCount} payment(s) flagged for attention!`" class="fa fa-exclamation-circle text-danger"></i>
                  &nbsp;<router-link :to="{ name: 'ProcessCollection', params: { groupId: props.row.groupId, mode: props.row.submissionType === 'Collections' ? 'collection' : 'auddis'} }"><span>Process Submission</span></router-link>

                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
              <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">
                    No Submissions Available
                  </div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="printTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Print out the contents of the table'">
                    <i class="fa fa-print pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                    <i class="fa fa-share-square pointer dimmedIcon"></i>
                  </b-button>
                </div>
              </vue-good-table>
            </b-container>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import papa from 'papaparse'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],  
  data () {
    return {
      submissions: [],
      columns: [
        {
          'field': 'flaggedPaymentsCount',
          'hidden': true
        },
        {
          label: 'Group',
          field: 'group'
        },
        {
          label: 'Submission Type',
          field: 'submissionType'
        },
        {
          label: 'Debit Count',
          field: 'debitCount'
        },
        {
          label: 'Total Debit Value (£)',
          field: 'totalDebitValue',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right'
        },
        {
          label: 'DDI Count',
          field: 'ddiCount'
        },
        {
          label: 'Credit Count',
          field: 'creditCount'
        },
        {
          label: 'Total Credit Value (£)',
          field: 'totalCreditValue',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right'
        },
        {
          label: '',
          field: 'processBtn',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'group', type: 'asc' }]
      },
      dates: { proc: new Date(), coll: new Date() }      
    }
  },
  methods: {
    getSubmissions () {
      this.$store.dispatch('loadSubmissions')
    },
    load: async function () {
      await this.$store.dispatch('loadSubmissions', this.buildGoodTableQuery())
        .then(response => {
          this.submissions = this.$store.getters.submissions
          var meta = this.$store.getters.submissionMetaData
          this.totalRecords = meta.totalItems
          console.log('meta in reload items', meta.totalItems)
        })
        .catch(error => {
          this.$toastr.e(error)
        })
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    async exportTable () {
      var serverParams = this.buildGoodTableQuery()
      var params = {
        params: {
          page: 1,
          perPage: this.totalRecords,
          columnFilters: serverParams.columnFilters,
          sort: serverParams.sort,
          searchKey: serverParams.searchKey
        },
        showload: true
      }
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/`, params)      
      var rows = response.data.data
      var csvString = papa.unparse(rows)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'all-submissions.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'all-submissions.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    onRowClick (params) {

    },
    handleClick (params) {
      if (params.column.field !== 'processBtn') {
        let auddis = params.row.submissionType === 'AUDDIS'
        if (auddis) {
          this.$router.push(`/collections/submissions/${params.row.groupId}/viewauddis`)
        } else {
          this.$router.push(`/collections/submissions/${params.row.groupId}/view`)
        }
      }
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    }
  },
  computed: {
    selectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    customerGroups () {
      return this.$store.state.groups.groups
    }
  },
  validations: {
    group: { required }
  },
  async mounted () {
    // await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.groupId })
    console.log('process form created')
    try {
      var response = await this.$store.dispatch('loadSubmissions', this.serverParams)
      if (response) {
        this.submissions = response
        var meta = this.$store.getters.submissionMetaData
        this.totalRecords = meta.totalItems
        console.log('meta in reload items', meta.totalItems)
      }
    } catch (err) {
      console.log('an error occurred loading the submissions')
      console.log(err)
    }
  }
}
</script>
