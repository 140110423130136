var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8" }, [
    _c("div", { staticClass: "alert alert-info" }, [
      _vm._v(
        'If a "Greater than" 0 range is not set, this will automatically be applied and will require 1 approver'
      )
    ]),
    _c("table", { staticClass: "table table-striped table-bordered" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("Greater than (or equal to)")]),
          _c("th", [_vm._v("Number of Approvers")]),
          _c("th", [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "button" },
                on: { click: _vm.addRange }
              },
              [_vm._v("Add New Range")]
            )
          ])
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.ranges.$each.$iter, function(range, index) {
          return _c("tr", { key: index }, [
            _c(
              "td",
              { class: { invalid: range.greaterThan.$error } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: range.greaterThan.$model,
                      expression: "range.greaterThan.$model",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number", step: "0.01" },
                  domProps: { value: range.greaterThan.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        range.greaterThan,
                        "$model",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c(
                  "validation-messages",
                  {
                    attrs: { name: "number" },
                    model: {
                      value: range.greaterThan,
                      callback: function($$v) {
                        _vm.$set(range, "greaterThan", $$v)
                      },
                      expression: "range.greaterThan"
                    }
                  },
                  [
                    !range.greaterThan.valid
                      ? _c(
                          "small",
                          {
                            staticClass: "form-text small validation-messages"
                          },
                          [
                            _vm._v(
                              "You cannot have duplicate greater than values"
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c(
              "td",
              { class: { invalid: range.numberOfApprovers.$error } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: range.numberOfApprovers.$model,
                      expression: "range.numberOfApprovers.$model"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number", min: "1" },
                  domProps: { value: range.numberOfApprovers.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        range.numberOfApprovers,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "number" },
                  model: {
                    value: range.numberOfApprovers,
                    callback: function($$v) {
                      _vm.$set(range, "numberOfApprovers", $$v)
                    },
                    expression: "range.numberOfApprovers"
                  }
                })
              ],
              1
            ),
            _c("td", [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.removeRange(index)
                    }
                  }
                },
                [_vm._v("Delete")]
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }