<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-content">
            <div v-for="licence in licences" :key="licence">
              <!-- Validate Pages -->
              <div v-if="licence == licenceConstanst.validate_iban">
                <div class="alert alert-warning">
                  <h4 class="alert-heading">You are not licensed for IBAN validation</h4>
                  <hr />
                  <p>Please contact your paygate account manager if you would like to purchase this feature.</p>
                </div>
              </div>
              <div v-else-if="licence == licenceConstanst.validate_modcheck">
                <div class="alert alert-warning">
                  <h4 class="alert-heading">You are not licensed for Bank Account validation</h4>
                  <hr />
                  <p>Please contact your paygate account manager if you would like to purchase this feature.</p>
                </div>
              </div>
              <div v-else-if="licence == licenceConstanst.validate_cc">
                <div class="alert alert-warning">
                  <h4 class="alert-heading">You are not licensed for Credit Card validation</h4>
                  <hr />
                  <p>Please contact your paygate account manager if you would like to purchase this feature.</p>
                </div>
              </div>

              <div v-else-if="licence === licenceConstanst.mfa_usb_tokens">
                <div class="alert alert-warning">
                  <h4 class="alert-heading">You are not licensed to use USB Tokens</h4>
                  <hr />
                  <p>USB Tokens allow you to progress actions and submissions via a secure, USB-based, token.</p>
                  <p>Please contact your paygate account manager if you would like to purchase this feature.</p>
                </div>
              </div>
              <div v-else-if="licence === licenceConstanst.hsm">
                <div class="alert alert-warning">
                  <h4 class="alert-heading">You are not licensed to use HSM certificates</h4>
                  <hr />
                  <p>HSM certificates allow you to securely sign payments.</p>
                  <p>Please contact your paygate account manager if you would like to purchase this feature.</p>
                </div>
              </div>
              <div
                v-else-if="licence == licenceConstanst.bureau"
              >You are not licenced for paygate bureau</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import licence from '@/Assets/Constants/licence'

export default {
  props: ['licences'],
  data () {
    return {
      licenceConstanst: licence
    }
  },
  mounted () {
    this.$snapbar.w('You are not licensed for that feature', 'Not Licensed')
  },
  beforeRouteEnter (to, from, next) {
    if (!to.params.licences) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  }
}
</script>

<style>
</style>
