var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "intelligibleSummary" } }, [
    _vm._m(0),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(1),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.selectedSummary.creditRecordCount)))
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _vm._v("£" + _vm._s(_vm.formatValue(_vm.selectedSummary.creditValue)))
      ]),
      _c("div", { staticClass: "col-md-1" }),
      _vm._m(2),
      _c("div", { staticClass: "col-md-2" }, [
        _vm._v(_vm._s(_vm.submissionType))
      ])
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(3),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.selectedSummary.debitRecordCount)))
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _vm._v("£" + _vm._s(_vm.formatValue(_vm.selectedSummary.debitValue)))
      ]),
      _c("div", { staticClass: "col-md-1" }),
      _vm._m(4),
      _c("div", { staticClass: "col-md-2" }, [
        _vm._v(_vm._s(_vm.selectedSummary.serviceUserNumber))
      ])
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(5),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.selectedSummary.creditContraCount)))
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _vm._v(
          "£" + _vm._s(_vm.formatValue(_vm.selectedSummary.creditContraValue))
        )
      ]),
      _c("div", { staticClass: "col-md-1" }),
      _vm._m(6),
      _c("div", { staticClass: "col-md-2" }, [
        _vm._v(_vm._s(_vm.selectedSummary.workCode))
      ])
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(7),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.selectedSummary.debitContraCount)))
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _vm._v(
          "£" + _vm._s(_vm.formatValue(_vm.selectedSummary.debitContraValue))
        )
      ]),
      _c("div", { staticClass: "col-md-1" }),
      _vm._m(8),
      _c("div", { staticClass: "col-md-2" }, [
        _vm._v(_vm._s(_vm.processingDate))
      ])
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(9),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.selectedSummary.ddiRecordCount)))
      ]),
      _c("div", { staticClass: "col-md-3" }),
      _vm._m(10),
      _c("div", { staticClass: "col-md-2" }, [
        _vm._v(_vm._s(_vm.selectedContraNarrative))
      ])
    ]),
    _c("div", { staticClass: "row form-group" }),
    _vm.actionId !== undefined
      ? _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.downloadReport }
              },
              [
                _c("span", {
                  staticClass: "far fa-arrow-alt-circle-down",
                  staticStyle: { "padding-right": "5px" }
                }),
                _vm._v("Download Submission Report ")
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row form-group" }, [
      _c("div", { staticClass: "col-md-2" }),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _c("strong", [_vm._v("Items")])
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _c("strong", [_vm._v("Total")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Credits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Submission Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Debits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Service User Number")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Credit Contras")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Work Code")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Debit Contras")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Processing Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("DDIs")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Contra Narrative")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }