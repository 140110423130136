<template>
  <div>
    <h3>Rule<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <div class="alert alert-info">A rule is a self contained business rule that acts on imported data in a PayGate workflow.  You can create a rule to split a payment file into two or more groups based on, say, the amount.
      <br>
      For a brief tutorial video check out this <a href="https://www.google.co.uk" target="_blank">link</a>.  To read the in-depth rules user guide, click on this <a href="https://www.google.co.uk" target="_blank">link</a>.
    </div>
    <br/>
  <div class="row">
    <div class="col-md-1 col-sm-12">
      <label>Group</label>
    </div>
    <div class="col-md-4 col-sm-12">
      <group-select v-model="selectedNode.props.s1.value" :groups="groupOptions" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
    </div>
    <div class="col-md-2 offset-md-2 col-sm-12">
      <label
        class="required"
        title="dataType"
        v-b-popover.hover.top.d500="'Sets the data type used for auto-mapping.'"
      >Data Type</label>
    </div>
    <div class="col-md-3 col-sm-12">
      <select v-model="selectedNode.props.s2.value" class="form-control" @change="dataTypeChanged">
        <option
          v-for="option in dataTypeMode"
          :key="option.index"
          :value="option.value"
        >{{ option.text }}</option>
      </select>
    </div>
  </div>

    <div class="form-group">
      <br>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-4"><strong>Item</strong></div>
        <div class="col-md-2"><strong>Condition</strong></div>
        <div class="col-md-2"><strong>Value</strong></div>
        <div class="col-md-1"><strong>If True</strong></div>
        <div class="col-md-1"><strong>If False</strong></div>
      </div>
      <hr>
      <div class="row mb-4" v-for="(rule, index) in rulesCollection" :key="rule.index">
        <div class="col-md-1">{{index + 1}}
          <i v-if="!rule.isValid" class="fas fa-exclamation-triangle ml-2"></i></div>
        <div class="col-md-4">
          <div v-if="selectedDataType === 'bacsPayments'" class>
            <v-select
              v-model="rule.item"
              @input="selectedDataTypeChanged"
              :options="bacsDataTypes">
            </v-select>
          </div>
          <div v-else-if="selectedDataType === 'bacsBureauCustomers'" class>
            <v-select
              v-model="rule.type"
              @input="selectedDataTypeChanged"
              :options="bureauCustomerDataTypes"
            ></v-select>
          </div>
        </div>
        <div class="col-md-2">
          <v-select
            v-model="rule.condition"
            @input="selectedConditionChanged"
            :options="conditions">
          </v-select>
        </div>
        <div class="col-md-2"><input type="text" @change="valueChanged" class="form-control" v-model="rule.value" /></div>
        <div class="col-md-1 "><input type="number" class="form-control" v-model="rule.route" /></div>
        <div class="col-md-1 "><input type="number" class="form-control" v-model="rule.notRoute" /></div>
        <div class="col-md-1">
          <b-button  class="float-right" variant="outline-danger"  @click.prevent="btnRemoveRule(index)">x</b-button>
        </div>
      </div>
    </div>
    <b-button  class="float-right mt-2" variant="outline-primary" @click.prevent="btnAddRule">Add</b-button>
    <br/>
    <br/>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        :disabled="!areAllRulesValid"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import axios from 'axios'
import vSelect from 'vue-select'
import { required, maxLength } from 'vuelidate/lib/validators'
// import { all } from 'q'
export default {
  components: {
    'v-select': vSelect
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  computed: {
    areAllRulesValid: function () {
      // Check rule validity
      let allValid = true
      for (const rule of this.rulesCollection) {
        console.log(rule)
        if (!rule.isValid) allValid = false
      }
      return allValid
    }
  },
  data () {
    return {
      groupOptions: [],
      mappingOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      rulesCollection: [],
      selectedDataType: 'bacsPayments',
      dataTypeMode: [
        { text: 'BACS/FPS Payments', value: 'bacsPayments' },
        { text: 'Bureau Customers', value: 'bacsBureauCustomers' },
        { text: 'UK DDMS Payers', value: 'ukDdmsPayers' },
        { text: 'UK DDMS Variable Update', value: 'ukDdmsVar' }
      ],
      conditions: [
        {
          label: '=',
          value: 'equalTo',
          requiresValue: true,
          supportedTypes: ['string', 'number', 'date']
        },
        {
          label: '!=',
          value: 'notEqualTo',
          requiresValue: true,
          supportedTypes: ['string', 'number', 'date']
        },
        {
          label: '<',
          value: 'LessThan',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: '<=',
          value: 'lessThanOrEqualTo',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: '>',
          value: 'moreThan',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: '>=',
          value: 'moreThanOrEqualTo',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: 'Starts with',
          value: 'startsWith',
          requiresValue: true,
          supportedTypes: ['string']
        },
        {
          label: 'Ends with',
          value: 'endsWith',
          requiresValue: true,
          supportedTypes: ['string']
        },
        {
          label: 'Contains',
          value: 'contains',
          requiresValue: true,
          supportedTypes: ['string']
        },
        {
          label: 'Matches',
          value: 'matches',
          requiresValue: true,
          supportedTypes: ['string', 'number', 'date']
        },
        {
          label: 'Is Today',
          value: 'isToday',
          requiresValue: false,
          supportedTypes: ['date']
        },
        {
          label: 'Is in past',
          value: 'isInPast',
          requiresValue: false,
          supportedTypes: ['date']
        },
        {
          label: 'Is in future',
          value: 'isInFuture',
          requiresValue: false,
          supportedTypes: ['date']
        }
      ],
      bacsDataTypes: [
        {
          label: 'Third-party Sortcode',
          value: 'thirdPartySortcode',
          type: 'string'
        },
        {
          label: 'Third-party Account Number',
          value: 'thirdPartyAccountNumber',
          type: 'string'
        },
        {
          label: 'Third-party Account Type',
          value: 'thirdPartyAccountType',
          type: 'string'
        },
        {
          label: 'Third-party Account Name',
          value: 'thirdPartyAccountName',
          type: 'string'
        },
        {
          label: 'Originating Sortcode',
          value: 'originatingSortcode',
          type: 'string'
        },
        {
          label: 'Originating Account Number',
          value: 'originatingAccountNumber',
          type: 'string'
        },
        {
          label: 'Originating Account Name',
          value: 'originatingAccountName',
          type: 'string'
        },
        {
          label: 'Service User Reference',
          value: 'Service User Reference',
          type: 'string'
        },
        {
          label: 'Transaction Code',
          value: 'transactionCode',
          type: 'string'
        },
        {
          label: 'Amount',
          value: 'amount',
          type: 'number'
        },
        {
          label: 'Date',
          value: 'processingdate',
          type: 'date'
        },
        {
          label: 'RTI Reference',
          value: 'rtiReference',
          type: 'string'
        },
        {
          label: 'Service User Number',
          value: 'serviceUserNumber',
          type: 'string'
        },
        {
          label: 'Numeric Reference',
          value: 'numericReference',
          type: 'number'
        },
        {
          label: 'File Number',
          value: 'fileNumber',
          type: 'number'
        }
      ],
      bureauCustomerDataTypes: [
        {
          label: 'Company Name',
          value: 'companyName',
          type: 'string'
        },
        {
          label: 'Contact First Name',
          value: 'contactFirstName',
          type: 'string'
        },
        {
          label: 'Contact Surname',
          value: 'contactSurname',
          type: 'string'
        },
        {
          label: 'Address 1',
          value: 'address1',
          type: 'string'
        },
        {
          label: 'Address 2',
          value: 'address2',
          type: 'string'
        },
        {
          label: 'Address 3',
          value: 'address3',
          type: 'string'
        },
        {
          label: 'Address 4',
          value: 'address4',
          type: 'string'
        },
        {
          label: 'Address 5',
          value: 'address5',
          type: 'string'
        },
        {
          label: 'Postcode',
          value: 'postcode',
          type: 'string'
        },
        {
          label: 'Telephone 1',
          value: 'telephone1',
          type: 'string'
        },
        {
          label: 'Telephone 2',
          value: 'telephone2',
          type: 'string'
        },
        {
          label: 'Website URL',
          value: 'websiteUrl',
          type: 'string'
        },
        {
          label: 'Email',
          value: 'email',
          type: 'string'
        },
        {
          label: 'SUN',
          value: 'sun',
          type: 'string'
        },
        {
          label: 'Payment Limit',
          value: 'paymentLimit',
          type: 'string'
        },
        {
          label: 'Submission Limit',
          value: 'submissionLimit',
          type: 'string'
        },
        {
          label: 'Contra Narrative',
          value: 'contraNarrative',
          type: 'string'
        },
        {
          label: 'Bank Name',
          value: 'bankName',
          type: 'string'
        },
        {
          label: 'Bank Reference',
          value: 'bankReference',
          type: 'string'
        },
        {
          label: 'Bank Address 1',
          value: 'bankAddress1',
          type: 'string'
        },
        {
          label: 'Bank Address 2',
          value: 'bankAddress2',
          type: 'string'
        },
        {
          label: 'Bank Address 3',
          value: 'bankAddress3',
          type: 'string'
        },
        {
          label: 'Bank Address 4',
          value: 'bankAddress4',
          type: 'string'
        },
        {
          label: 'Bank Address 5',
          value: 'bankAddress5',
          type: 'string'
        },
        {
          label: 'Bank Postcode',
          value: 'bankPostcode',
          type: 'string'
        },
        {
          label: 'Sortcode',
          value: 'sortcode',
          type: 'string'
        },
        {
          label: 'Account Number',
          value: 'accountNumber',
          type: 'string'
        },
        {
          label: 'Default Export Schema',
          value: 'defaultExportSchema',
          type: 'string'
        },
        {
          label: 'Default Export Mapping',
          value: 'defaultExportMapping',
          type: 'string'
        },
        {
          label: 'Default Filename',
          value: 'defaultFilename',
          type: 'string'
        },
        {
          label: 'Notes',
          value: 'notes',
          type: 'string'
        }
      ]
    }
  },
  methods: {
    btnSave: function () {
      // Check rule validity
      let allValid = true
      for (const rule of this.rulesCollection) {
        console.log(rule)
        if (!rule.isValid) allValid = false
      }

      if (!allValid) {
        console.log('Wow invalid')
        return
      }

      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }

      const rulesData = JSON.stringify(this.rulesCollection)
      console.log('rulesData')
      console.log(rulesData)
      this.selectedNode.props.s3.value = rulesData

      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnAddRule: function () {
      this.rulesCollection.push({
        isValid: false
      })
    },
    btnRemoveRule: function (index) {
      this.rulesCollection.splice(index, 1)
    },
    dataTypeChanged: function () {
      if (this.rulesCollection.length > 0) {
        for (const i of this.rulesCollection) {
          if (i.item) i.item = ''
        }
      }
    },
    selectedDataTypeChanged: function () {
      this.isRuleValid()
    },
    selectedConditionChanged: function () {
      this.isRuleValid()
    },
    valueChanged: function () {
      this.isRuleValid()
    },
    isRuleValid: function () {
      let found = false
      for (let i = 0; i < this.rulesCollection.length; i++) {
        const rule = this.rulesCollection[i]
        console.log('rule.type: ' + rule.item.type)
        if (rule.condition) {
          for (const st of rule.condition.supportedTypes) {
            console.log('st: ' + st)
            if (st === rule.item.type) {
              console.log('Found: ' + st)
              found = true
              break
            }
          }
          console.log('rule.condition.requiresValue: ' + rule.condition.requiresValue)
          console.log('rule.value: ' + rule.value)
          if (rule.condition.requiresValue && !rule.value) {
            console.log('rule value!')
            found = false
          }
        }
        console.log('return: ' + found)
        this.rulesCollection[i].isValid = found
      }
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    if (!this.paygateId) {
      this.$toastr.e('Error getting list of available groups. (001)', 'Error', 'error')
      return
    }
    let res
    try {
      res = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`)
      this.groupOptions = res.data.data
    } catch (e) {
      this.$snapbar.e('Error getting list of available groups. (002)')
    }
    this.rulesCollection = JSON.parse(this.selectedNode.props.s3.value)
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    console.log('selectedNode')
    console.log(this.selectedNode)
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .modal-container {
    width: 80%;
    height: 90%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    max-height: 90vh;
    overflow-y: auto;
  }

  .invalid {
    border-color: red;
    border-width: 2px;
  }

  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

  .fa-exclamation-triangle {
    color: #bc0000;
  }

</style>
