<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Password Complexity Policy
            <help-icon docPath="/administration/securitypolicy/passwordcomplexitypolicy/" />
          </h2>
        </div>

        <div class="idb-block-content">
          <!-- Description -->
          <div class="form-group row">
            <label
              class="col-form-label col-md-12"
            >Also known as Password Strength, Password Complexity determines how strong user passwords are. These rules are used when a user tries to change their password or a new password is set for the user. Password complexity can be controlled either using the standard options of password min/max length, number of upper case letters etc., alternatively you can use a regular expression to validate the password complexity.</label>
          </div>

          <!-- Password History -->
          <div class="form-group row">
            <label
              class="col-form-label col-md-4 offset-md-1"
            >New password different to previous n passwords</label>
            <div class="col-md-7">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.enforceUnique.$model"
                @change="$v.policy.numUnique.$touch()"
              ></p-check>
            </div>
          </div>

          <!-- Number of passwords in history -->
          <transition name="fade">
            <div
              class="form-group row"
              v-if="$v.policy.enforceUnique.$model"
              :class="{invalid: $v.policy.numUnique.$error}"
            >
              <label class="col-form-label col-md-4 offset-md-1">Number of passwords in history</label>
              <div class="col-md-2">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="$v.policy.numUnique.$model"
                  min="1"
                  max="10"
                />
                <validation-messages v-model="$v.policy.numUnique"></validation-messages>
              </div>
            </div>
          </transition>

          <!-- Enforce history check retrospectively -->
          <div class="form-group row">
            <label class="col-form-label col-md-4 offset-md-1">Enforce retrospectively</label>
            <div class="col-md-7">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.enforceRetrospectively.$model"
              ></p-check>
            </div>
          </div>

          <!-- Exclude specific users -->
          <div class="form-group row">
            <label
              class="col-form-label col-md-4 offset-md-1"
            >Exclude specific users from password complexity rules</label>
            <div class="col-md-7">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.excludeSpecificUsers.$model"
              ></p-check>
            </div>
          </div>

          <!-- Excluded users -->
          <transition name="fade">
            <div class="form-group row" v-if="$v.policy.excludeSpecificUsers.$model">
              <label
                class="col-form-label col-md-4 offset-md-1"
              >Exclude specific users from password complexity rules</label>
              <div class="col-md-5">
                <vue-select
                  multiple
                  v-model="$v.policy.excludedUsers.$model"
                  :options="users"
                  :closeOnSelect="false"
                ></vue-select>
                <validation-messages v-model="$v.policy.excludedUsers"></validation-messages>
              </div>
            </div>
          </transition>

          <!-- Use Standard Complexity Settings -->
          <div class="form-group row">
            <div class="col-md-7 offset-md-1">
              <p-radio
                class="p-default p-round"
                value="Default"
                name="complexityOption"
                v-model="$v.policy.complexityOption.$model"
              >Use Standard Complexity</p-radio>
            </div>
          </div>

          <!-- Minimum Length -->
          <transition name="fade">
            <div v-if="$v.policy.complexityOption.$model == 'Default'" class="pl-5">
              <div class="form-group" :class="{invalid: $v.policy.minLength.$error}">
                <div class="row">
                  <label class="col-form-label col-md-4 offset-md-1">Minimum length</label>
                  <div class="col-md-1">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      disabled
                      v-model="$v.policy.enforceMinLength.$model"
                    ></p-check>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="number"
                      class="form-control"
                      min="7"
                      max="20"
                      v-model.number="$v.policy.minLength.$model"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 offset-md-6">
                    <validation-messages v-model="$v.policy.minLength"></validation-messages>
                  </div>
                </div>
              </div>

              <!-- Maximum Length -->
              <div class="form-group" :class="{invalid: $v.policy.maxLength.$error}">
                <div class="row">
                  <label class="col-form-label col-md-4 offset-md-1">Maximum length</label>
                  <div class="col-md-1">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.policy.enforceMaxLength.$model"
                      @change="$v.policy.maxLength.$touch()"
                    ></p-check>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="number"
                      class="form-control"
                      min="7"
                      max="20"
                      :disabled="!$v.policy.enforceMaxLength.$model"
                      v-model.number="$v.policy.maxLength.$model"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 offset-md-6">
                    <validation-messages v-model="$v.policy.maxLength"></validation-messages>
                  </div>
                </div>
              </div>

              <!-- Number of upper case letters -->
              <div class="form-group" :class="{invalid: $v.policy.numUppercase.$error}">
                <div class="row">
                  <label
                    class="col-form-label col-md-4 offset-md-1"
                  >Include at least n upper-case letters</label>
                  <div class="col-md-1">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.policy.enforceUppercase.$model"
                      @change="$v.policy.numUppercase.$touch()"
                    ></p-check>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      :disabled="!$v.policy.enforceUppercase.$model"
                      v-model.number="$v.policy.numUppercase.$model"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 offset-md-6">
                    <validation-messages v-model="$v.policy.numUppercase">
                      <small
                        class="form-text small"
                        v-if="!$v.policy.numUppercase.maxValueComplex"
                      >The combination of upper-case, numeric and non-alphanumeric characters must be lower than the max length</small>
                    </validation-messages>
                  </div>
                </div>
              </div>

              <!-- Number of numeric characters -->
              <div class="form-group" :class="{invalid: $v.policy.numNumbers.$error}">
                <div class="row">
                  <label
                    class="col-form-label col-md-4 offset-md-1"
                  >Include at least n numeric characters</label>
                  <div class="col-md-1">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="policy.enforceNumbers"
                    ></p-check>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      :disabled="!$v.policy.enforceNumbers.$model"
                      v-model.number="$v.policy.numNumbers.$model"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 offset-md-6">
                    <validation-messages v-model="$v.policy.numNumbers">
                      <small
                        class="form-text small"
                        v-if="!$v.policy.numNumbers.maxValueComplex"
                      >The combination of upper-case, numeric and non-alphanumeric characters must be lower than the max length</small>
                    </validation-messages>
                  </div>
                </div>
              </div>

              <!-- Number of non alpha-numeric characters -->
              <div class="form-group" :class="{invalid: $v.policy.numNonAlphaNumerics.$error}">
                <div class="row">
                  <label
                    class="col-form-label col-md-4 offset-md-1"
                  >Include at least n non-alphanumeric characters</label>
                  <div class="col-md-1">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.policy.enforceNonAlphaNumeric.$model"
                    ></p-check>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      :disabled="!$v.policy.enforceNonAlphaNumeric.$model"
                      v-model.number="$v.policy.numNonAlphaNumerics.$model"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 offset-md-6">
                    <validation-messages v-model="$v.policy.numNonAlphaNumerics">
                      <small
                        class="form-text small"
                        v-if="!$v.policy.numUppercase.maxValueComplex"
                      >The combination of upper-case, numeric and non-alphanumeric characters must be lower than the max length</small>
                    </validation-messages>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- Use Regular Expression Settings -->

          <div class="form-group row">
            <div class="col-md-7 offset-md-1">
              <p-radio
                class="p-default p-round"
                value="Regex"
                name="complexityOption"
                v-model="policy.complexityOption"
              >Use Regular Expression</p-radio>
            </div>
          </div>

          <transition name="fade">
            <!-- Regular Expression -->
            <div v-if="policy.complexityOption == 'Regex'" class="pl-5">
              <div class="form-group row" :class="{invalid: $v.policy.regularExpression.$error}">
                <label class="col-form-label col-md-2 offset-md-1">Regex</label>
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="$v.policy.regularExpression.$model"
                  />
                  <validation-messages v-model="$v.policy.regularExpression">
                    <small
                      class="form-text small"
                      v-if="!$v.policy.regularExpression.isRegex"
                    >Please enter a valid regex</small>
                  </validation-messages>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="resetRegularExpression"
                  >Default</button>
                </div>
              </div>

              <!-- Regular Expression Test -->
              <div
                class="form-group row"
                :class="{invalid: (regexValid != null && !regexValid), valid:(regexValid != null && regexValid)}"
              >
                <label class="col-form-label col-md-2 offset-md-1">Test Sample</label>
                <div class="col-md-6">
                  <input type="text" class="form-control" v-model="regexSample" @input="testRegex" />
                  <small
                    class="form-text small validation-messages"
                    v-if="(regexValid != null && !regexValid)"
                  >The Regex doesn't match the sample</small>

                  <small
                    class="form-text small validation-messages"
                    v-if="(regexValid != null && regexValid)"
                  >The Regex matches the sample</small>
                </div>
              </div>

              <!-- Regular Expression Fail Message -->

              <div class="form-group row">
                <label class="col-form-label col-md-2 offset-md-1">Message on mis-match</label>
                <div class="col-md-6">
                  <textarea
                    type="text"
                    rows="3"
                    class="form-control"
                    v-model="policy.regExFailMessage"
                  ></textarea>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="idb-block-footer">
          <button type="submit" class="btn btn-primary" :disabled="isLoading">Save</button>
          <button
            type="button"
            @click="reset"
            class="btn btn-outline-warning ml-3"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { minValue, maxValue, numeric, requiredIf } from 'vuelidate/lib/validators'
import VueSelect from 'vue-select'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    VueSelect
  },
  computed: {
    totalRulesNumber () {
      if (this.$v.policy.enforceMaxLength.$model) {
        let value = 0
        if (this.$v.policy.enforceUppercase.$model) {
          value += this.$v.policy.numUppercase.$model
        }

        if (this.$v.policy.enforceNumbers.$model) {
          value += this.$v.policy.numNumbers.$model
        }

        if (this.$v.policy.enforceNonAlphaNumeric.$model) {
          value += this.$v.policy.numNonAlphaNumerics.$model
        }
        return value
      }
      return this.$v.policy.maxLength.$model - 1
    },
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      errors: [],
      policy: {
        complexityOption: 'Default',
        enforceMinLength: true,
        enforceMaxLength: false,
        enforceUppercase: false,
        enforceNumbers: false,
        enforceNonAlphaNumeric: false,
        enforceUnique: true,
        minLength: 7,
        maxLength: 15,
        numUppercase: 0,
        numNumbers: 0,
        numNonAlphaNumerics: 0,
        numUnique: 3,
        regularExpression: '(?=^.{8,15}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W])(?!.*\\s).*$',
        regExFailMessage: 'Password must be 8-15 characters long with at least one digit, one upper-case letter, one lower-case letter and one non alphanumeric character.',
        enforceRetrospectively: false,
        excludeSpecificUsers: false,
        excludedUsers: []
      },
      defaultRegularExpression: '(?=^.{8,15}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W])(?!.*\\s).*$',
      defaultRegExFailMessage: 'Password must be 8-15 characters long with at least one digit, one upper-case letter, one lower-case letter and one non alphanumeric character.',
      users: [],
      regexValid: null,
      regexSample: ''
    }
  },

  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PasswordComplexity`, this.policy,
          { showload: true, showerror: true, errormessage: 'Password complexity failed to save' })
        this.$toastr.s('Password Complexity policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        complexityOption: 'Default',
        enforceMinLength: true,
        enforceMaxLength: false,
        enforceUppercase: false,
        enforceNumbers: false,
        enforceNonAlphaNumeric: false,
        enforceUnique: true,
        minLength: 7,
        maxLength: 15,
        numUppercase: 0,
        numNumbers: 0,
        numNonAlphaNumerics: 0,
        numUnique: 3,
        regularExpression: '(?=^.{8,15}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W])(?!.*\\s).*$',
        regExFailMessage: 'Password must be 8-15 characters long with at least one digit, one upper-case letter, one lower-case letter and one non alphanumeric character.',
        enforceRetrospectively: false,
        excludeSpecificUsers: false,
        excludedUsers: []
      }
      this.regexSample = ''
      this.regexValid = null
      this.errors = []
      this.$toastr.i('Password Complexity policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        this.users = await this.$store.dispatch('getUsersAsDropDownSource')
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PasswordComplexity`,
          { showload: true, showerror: true, errormessage: 'Password complexity failed to load' })
        this.policy = response.data
      } catch { }
    },
    resetRegularExpression () {
      this.policy.regularExpression = this.defaultRegularExpression
      this.policy.regExFailMessage = this.defaultRegExFailMessage
    },
    testRegex () {
      try {
        var regex = new RegExp(this.$v.policy.regularExpression.$model)
        this.regexValid = regex.test(this.regexSample)
      } catch (e) {
        this.regexValid = false
      }
    }
  },
  validations () {
    return {
      policy: {
        complexityOption: {},
        enforceRetrospectively: {},
        excludeSpecificUsers: {},
        enforceUnique: {},
        excludedUsers: {},
        enforceMinLength: {},
        enforceMaxLength: {},
        enforceUppercase: {},
        enforceNumbers: {},
        enforceNonAlphaNumeric: {},
        numUnique: {
          required: requiredIf(function () {
            return this.policy.enforceUnique
          }),
          numeric,
          minValue: (value, model) => {
            return !model.enforceUnique ? true : minValue(1)(value)
          },
          maxValue: (value, model) => {
            return !model.enforceUnique ? true : maxValue(10)(value)
          }
        },
        minLength: {
          required: requiredIf((value) => {
            return value.complexityOption === 'Default'
          }),
          numeric,
          minValue: (value, model) => {
            return !model.enforceMinLength ? true : minValue(7)(value)
          },
          maxValue: (value, model) => {
            return !model.enforceMinLength ? true : model.enforceMaxLength && !!model.maxLength ? maxValue(model.maxLength)(value) : true
          }
        },
        maxLength: {
          required: requiredIf((value) => {
            return value.enforceMaxLength
          }),
          numeric,
          minValue: (value, model) => {
            return !model.enforceMaxLength ? true : minValue(model.minLength)(value)
          },
          maxValue: (value, model) => {
            return !model.enforceMaxLength ? true : maxValue(20)(value)
          }
        },
        numUppercase: {
          required: requiredIf((value) => {
            return value.enforceUppercase
          }),
          numeric,
          minValue: (value, model) => {
            return !model.enforceUppercase ? true : minValue(0)(value)
          },
          maxValueComplex: (value, model) => {
            return !model.enforceUppercase ? true : this.totalRulesNumber <= this.policy.maxLength
          }
        },
        numNumbers: {
          required: requiredIf((value) => {
            return value.enforceNumbers
          }),
          numeric,
          minValue: (value, model) => {
            return !model.enforceNumbers ? true : minValue(0)(value)
          },
          maxValueComplex: (value, model) => {
            return !model.enforceNumbers ? true : this.totalRulesNumber <= this.policy.maxLength
          }
        },
        numNonAlphaNumerics: {
          required: requiredIf((value) => {
            return value.enforceNonAlphaNumeric
          }),
          numeric,
          minValue: (value, model) => {
            return !model.enforceNonAlphaNumeric ? true : minValue(0)(value)
          },
          maxValueComplex: (value, model) => {
            return !model.enforceNonAlphaNumeric ? true : this.totalRulesNumber <= this.policy.maxLength
          }
        },
        regularExpression: {
          required: requiredIf((value) => {
            return value.complexityOption === 'Regex'
          }),
          isRegex: (value, model) => {
            try {
              if (model.complexityOption === 'Default') {
                return true
              }
              new RegExp(value) //eslint-disable-line
              return true
            } catch (e) {
              return false
            }
          }
        },
        regexSample: {}
      }
    }
  }
}
</script>

<style>
.no-padding {
  padding-left: 0px;
}
</style>
