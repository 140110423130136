var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.action.id
    ? _c(
        "actions-block",
        {
          attrs: {
            action: _vm.action,
            status: _vm.status,
            from: _vm.from,
            name: "Group"
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [_vm._v("Group Information")])
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.status === "Edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-secondary",
                        attrs: { role: "alert" }
                      },
                      [
                        _vm._v(" The previous details are shown in "),
                        _c("span", { staticClass: "del" }, [_vm._v("red")]),
                        _vm._v(", the new details in "),
                        _c("span", { staticClass: "ins" }, [_vm._v("green")]),
                        _vm._v(". ")
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-tabs",
                  { attrs: { lazy: "" } },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "Group Details", active: "" } },
                      [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 text-right col-form-label",
                              attrs: { for: "description" }
                            },
                            [_vm._v("Reference")]
                          ),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              { staticClass: "form-control-plaintext" },
                              _vm._l(_vm.difference.name, function(diff) {
                                return _c(
                                  "span",
                                  {
                                    key: diff.count,
                                    class: {
                                      ins: diff.added,
                                      del: diff.removed
                                    },
                                    staticStyle: { "margin-right": "5px" }
                                  },
                                  [_vm._v(_vm._s(diff.value))]
                                )
                              }),
                              0
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 text-right col-form-label",
                              attrs: { for: "description" }
                            },
                            [_vm._v("Description")]
                          ),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              { staticClass: "form-control-plaintext" },
                              _vm._l(_vm.difference.description, function(
                                diff
                              ) {
                                return _c(
                                  "span",
                                  {
                                    key: diff.value,
                                    class: {
                                      ins: diff.added,
                                      del: diff.removed
                                    },
                                    staticStyle: { "margin-right": "5px" }
                                  },
                                  [_vm._v(_vm._s(diff.value))]
                                )
                              }),
                              0
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 text-right col-form-label",
                              attrs: { for: "description" }
                            },
                            [_vm._v("Type")]
                          ),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              { staticClass: "form-control-plaintext" },
                              _vm._l(_vm.difference.groupType, function(diff) {
                                return _c(
                                  "span",
                                  {
                                    key: diff.value,
                                    class: {
                                      ins: diff.added,
                                      del: diff.removed
                                    },
                                    staticStyle: { "margin-right": "5px" }
                                  },
                                  [_vm._v(_vm._s(diff.value))]
                                )
                              }),
                              0
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm.group
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Group Users" } },
                          [
                            _c("group-users-details", {
                              attrs: {
                                status: _vm.status,
                                groupUserRights: _vm.group.groupUserRights,
                                actionUserRights:
                                  _vm.action.data.groupUserRights
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group && _vm.group.groupType === _vm.groupTypes.bacs
                      ? _c(
                          "b-tab",
                          { attrs: { title: "BACS Config" } },
                          [
                            _c("bacs-details", {
                              attrs: {
                                groupTypeDetails: _vm.group.groupTypeDetails,
                                actionTypeDetails:
                                  _vm.action.data.groupTypeDetails
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group &&
                    _vm.group.groupType === _vm.groupTypes.bacsOnline
                      ? _c(
                          "b-tab",
                          { attrs: { title: "BACS Online Config" } },
                          [
                            _c("bacs-online-details", {
                              attrs: {
                                groupTypeDetails: _vm.group.groupTypeDetails,
                                actionTypeDetails:
                                  _vm.action.data.groupTypeDetails
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group && _vm.group.groupType === _vm.groupTypes.fps
                      ? _c(
                          "b-tab",
                          { attrs: { title: "FPS Config" } },
                          [
                            _c("fps-details", {
                              attrs: {
                                groupTypeDetails: _vm.group.groupTypeDetails,
                                actionTypeDetails:
                                  _vm.action.data.groupTypeDetails
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group &&
                    _vm.group.groupType === _vm.groupTypes.fpsOnline
                      ? _c(
                          "b-tab",
                          { attrs: { title: "FPS Online Config" } },
                          [
                            _c("fps-online-details", {
                              attrs: {
                                groupTypeDetails: _vm.group.groupTypeDetails,
                                actionTypeDetails:
                                  _vm.action.data.groupTypeDetails
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group && _vm.group.groupType === _vm.groupTypes.bureau
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Bureau Config" } },
                          [
                            _c("bureau-details", {
                              attrs: {
                                groupTypeDetails: _vm.group.groupTypeDetails,
                                actionTypeDetails:
                                  _vm.action.data.groupTypeDetails
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group &&
                    _vm.group.groupType === _vm.groupTypes.collections
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Collections Config" } },
                          [
                            _c("collections-details", {
                              attrs: {
                                groupTypeDetails: _vm.group.groupTypeDetails,
                                actionTypeDetails:
                                  _vm.action.data.groupTypeDetails
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group &&
                    (_vm.group.groupType === _vm.groupTypes.bacs ||
                      _vm.group.groupType === _vm.groupTypes.collection ||
                      _vm.group.groupType === _vm.groupTypes.sepa ||
                      _vm.group.groupType === _vm.groupTypes.fps ||
                      _vm.group.groupType === _vm.groupTypes.bacsOnline ||
                      _vm.group.groupType === _vm.groupTypes.fpsOnline)
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Approval Settings" } },
                          [
                            _c("approval-settings-details", {
                              attrs: {
                                group: _vm.group,
                                actionGroup: _vm.action.data
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showAutoFileImportTab
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Auto File Import Config" } },
                          [
                            _c("auto-file-import-details", {
                              attrs: {
                                status: _vm.status,
                                groupAutoFileImport:
                                  _vm.group.groupTypeDetails
                                    .bacsAutoFileImportConfig,
                                actionAutoFileImport:
                                  _vm.action.data.groupTypeDetails
                                    .bacsAutoFileImportConfig
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showAntiFraudListTab
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Anti-Fraud Lists" } },
                          [
                            _c("group-anti-fraud-list-details", {
                              attrs: {
                                status: _vm.status,
                                groupAntiFraudLists:
                                  _vm.group.antiFraudGroupLists,
                                actionAntiFraudLists:
                                  _vm.action.data.antiFraudGroupLists
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }