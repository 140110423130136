var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Log Viewer"), _c("favourite-icon")], 1)
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c("div", { staticClass: "row form-group" }, [
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "p-check",
                {
                  staticClass: "p-switch p-fill",
                  attrs: { color: "primary" },
                  on: { change: _vm.selectedModuleChanged },
                  model: {
                    value: _vm.allCustomers,
                    callback: function($$v) {
                      _vm.allCustomers = $$v
                    },
                    expression: "allCustomers"
                  }
                },
                [_vm._v("Include all customers")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "p-check",
                {
                  staticClass: "p-switch p-fill",
                  attrs: { color: "primary" },
                  on: { change: _vm.toggleTimer },
                  model: {
                    value: _vm.autoRefresh,
                    callback: function($$v) {
                      _vm.autoRefresh = $$v
                    },
                    expression: "autoRefresh"
                  }
                },
                [_vm._v("Auto Refresh (30 seconds)")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "p-check",
                {
                  staticClass: "p-switch p-fill",
                  attrs: {
                    color: "primary",
                    "data-toggle": "collapse",
                    "data-target": "#advancedCollapse"
                  },
                  model: {
                    value: _vm.showAdvanced,
                    callback: function($$v) {
                      _vm.showAdvanced = $$v
                    },
                    expression: "showAdvanced"
                  }
                },
                [_vm._v("Advanced")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "container-fluid mt-2 collapse",
              attrs: { id: "advancedCollapse" }
            },
            [
              _c("div", { staticClass: "card card-body" }, [
                _c("div", { staticClass: "row form-group" }, [
                  _c("div", { staticClass: "col-md-2 required" }, [
                    _vm._v(" Select a Module ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("vue-select", {
                        attrs: {
                          options: _vm.sections.map(function(x) {
                            return x.label
                          }),
                          multiple: "",
                          "select-size": 4
                        },
                        on: { input: _vm.selectedModuleChanged },
                        model: {
                          value: _vm.selectedModule,
                          callback: function($$v) {
                            _vm.selectedModule = $$v
                          },
                          expression: "selectedModule"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.setModule(true)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-check" }),
                          _vm._v(" Select All")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "outline-danger" },
                          on: {
                            click: function($event) {
                              return _vm.setModule(false)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-times" }),
                          _vm._v(" Clear")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row form-group" }, [
                  _c("div", { staticClass: "col-md-2 required" }, [
                    _vm._v(" Log Level ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("vue-select", {
                        attrs: {
                          options: _vm.logLevels.map(function(x) {
                            return x.label
                          }),
                          multiple: "",
                          "select-size": 4
                        },
                        on: { input: _vm.selectedModuleChanged },
                        model: {
                          value: _vm.logLevel,
                          callback: function($$v) {
                            _vm.logLevel = $$v
                          },
                          expression: "logLevel"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.setLevels(true)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-check" }),
                          _vm._v(" Select All")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "outline-danger" },
                          on: {
                            click: function($event) {
                              return _vm.setLevels(false)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-times" }),
                          _vm._v(" Clear")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("Date Filtering Options")
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c(
                        "p-check",
                        {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.useStartDate,
                            callback: function($$v) {
                              _vm.useStartDate = $$v
                            },
                            expression: "useStartDate"
                          }
                        },
                        [_vm._v("Start Date")]
                      )
                    ],
                    1
                  ),
                  _vm.useStartDate
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "input-group date" },
                          [
                            _c("date-picker", {
                              attrs: {
                                config: _vm.startDateOptions,
                                wrap: true,
                                disabled: !_vm.useStartDate
                              },
                              on: { input: _vm.selectedModuleChanged },
                              model: {
                                value: _vm.start,
                                callback: function($$v) {
                                  _vm.start = $$v
                                },
                                expression: "start"
                              }
                            }),
                            _vm._m(0)
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c(
                        "p-check",
                        {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.useEndDate,
                            callback: function($$v) {
                              _vm.useEndDate = $$v
                            },
                            expression: "useEndDate"
                          }
                        },
                        [_vm._v("End Date")]
                      )
                    ],
                    1
                  ),
                  _vm.useEndDate
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "input-group date" },
                          [
                            _c("date-picker", {
                              attrs: {
                                disabled: !_vm.useEndDate,
                                config: _vm.endDateOptions,
                                wrap: true
                              },
                              on: { input: _vm.selectedModuleChanged },
                              model: {
                                value: _vm.end,
                                callback: function($$v) {
                                  _vm.end = $$v
                                },
                                expression: "end"
                              }
                            }),
                            _vm._m(1)
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          )
        ]),
        _c(
          "vue-good-table",
          {
            ref: "table",
            attrs: {
              columns: _vm.columns,
              rows: _vm.output,
              mode: "remote",
              styleClass: "vgt-table striped bordered",
              lineNumbers: true,
              totalRows: _vm.totalRecords,
              "search-options": {
                enabled: true
              },
              paginationOptions: _vm.paginationOptions,
              "sort-options": _vm.sortOptions,
              isLoading: _vm.isTableLoading
            },
            on: {
              "on-page-change": _vm.onPageChange,
              "on-sort-change": _vm.onSortChange,
              "on-column-filter": _vm.onColumnFilter,
              "on-per-page-change": _vm.onPerPageChange,
              "on-search": _vm.onSearch,
              "update:isLoading": function($event) {
                _vm.isTableLoading = $event
              },
              "update:is-loading": function($event) {
                _vm.isTableLoading = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "level"
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  pill: "",
                                  variant: _vm.getSeverityClass(props.row.level)
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(props.formattedRow[props.column.field])
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(_vm._s(props.formattedRow[props.column.field]))
                        ])
                  ]
                }
              }
            ])
          },
          [
            _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
              _c("span", { staticStyle: { "text-align": "center" } }, [
                _vm._v("No entries have been found matching the criteria.")
              ])
            ]),
            _c(
              "div",
              { attrs: { slot: "table-actions" }, slot: "table-actions" },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Clear filters",
                        expression: "'Clear filters'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    staticClass: "btn btn-link",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.clearTableFilters($event)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "fa-stack",
                        staticStyle: { "font-size": "10px" }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                        }),
                        _c("i", {
                          staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                        })
                      ]
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the table",
                        expression: "'Refresh the data in the table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { disabled: _vm.isLoading, variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.load($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-sync pointer dimmedIcon" })]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value:
                          "Export the contents of the table (limited to 500 rows)",
                        expression:
                          "'Export the contents of the table (limited to 500 rows)'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.exportTable($event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-file-export pointer dimmedIcon"
                    })
                  ]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "far fa-calendar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "far fa-calendar" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }