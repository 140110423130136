<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Actions Report
              <help-icon docPath="/administration/reports/actionsreport/" />
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-group row">
              <label class="col-form-label col-md-2">Only show actions with no one to action them</label>
              <div class="col">
                <p-check class="p-switch p-fill" color="primary" v-model="onlyHanging"></p-check>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button class="btn btn-primary" @click="search" :disabled="isLoading">
              <i class="glyphicon ti-pie-chart rpad"></i>Generate Report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="idb-block" v-if="dataLoaded">
          <div class="idb-block-title">
            <h4>Actions</h4>
          </div>
          <div class="idb-block-content">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :rows="rows"
              :columns="columns"
              styleClass="vgt-table striped bordered cursor-default"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'icon'">
                  <span>
                    <i class="fa" :class="getIcon(props.row)"></i>
                  </span>
                </span>
                <span v-else-if="props.column.field == 'createdBy'">
                  <img
                    :src="`${platformApi}User/GetAvatar/${props.row.createdBy}?access_token=${getAccessToken}`"
                    class="rounded-circle"
                    width="30"
                    height="30"
                    v-b-popover.top.hover="props.row.createdByName"
                  />
                </span>
                <span v-else-if="props.column.field == 'users'">
                  <span
                    class="badge badge-pill"
                    :class="{'badge-success' : props.row.users.length >= props.row.amountOfApproversNeeded, 'badge-danger': props.row.users.length < props.row.amountOfApproversNeeded}"
                    v-b-popover.top.hover="props.row.users.reduce((a, u) => `${a}${a ? ', ': ''}${u.username}`,'')"
                  >{{props.row.users.length}}</span>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import { getIcon } from '@/Assets/Components/Actions/actionsHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'ActionReport',
  mixins: [loading],
  computed: {
    ...mapGetters(['getAccessToken'])
  },
  data () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      dataLoaded: false,
      onlyHanging: false,
      rows: [],
      columns: [
        {
          label: '',
          field: 'icon',
          sortable: false
        },
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Message',
          field: 'message'
        },
        {
          label: 'Created Date',
          field: 'createdDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: 'Created By',
          field: 'createdBy',
          sortFn (x, y, col, rowX, rowY) {
            x = rowX.createdByName
            y = rowY.createdByName

            if (x == null) { x = '' }
            if (y == null) { y = '' }

            return (x < y ? -1 : (x > y ? 1 : 0))
          }
        },
        {
          label: 'Amount of users needed to progress action',
          field: 'amountOfApproversNeeded'
        },
        {
          label: 'Users who can progress action',
          field: 'users',
          sortFn (x, y, col, rowX, rowY) {
            x = x.length
            y = y.length
            return (x < y ? -1 : (x > y ? 1 : 0))
          }
        }

      ],
      paginationOptions: { enabled: true, perPage: 10, setCurrentPage: 1 }
    }
  },
  methods: {
    async search () {
      var reportResponse = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Actions`, { onlyHanging: this.onlyHanging }, { showload: true, showerror: true, errormessage: 'Failed to load action report' })
      this.rows = reportResponse.data
      this.dataLoaded = true
    },
    getIcon
  }
}
</script>

<style>
</style>
