import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'

export default [
  {
    menu_title: 'Workflow',
    menu_icon: 'ti-control-shuffle',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser])
    },
    child_routes: [
      {
        path: '/automation/workflow/workflows',
        menu_title: 'Workflows',
        menu_icon: 'ti-control-shuffle'
      },
      {
        path: '/automation/workflow/triggers',
        menu_title: 'Triggers',
        counterData: 'triggerCount',
        menu_icon: 'ti-pulse'
      },
      {
        path: '/automation/workflow/designer',
        menu_title: 'Workflow Designer',
        menu_icon: 'ti-view-grid'
      }
    ]
  },
  {
    menu_title: 'Mapping',
    menu_icon: 'ti-layout',
    child_routes: [
      {
        path: '/automation/mapping/mappings',
        menu_title: 'Mappings'
      },
      {
        path: '/automation/mapping/import/importers',
        menu_title: 'Importers',
        menu_icon: 'ti-import'
      },
      {
        path: '/automation/mapping/designer',
        menu_title: 'Mapping Designer',
        menu_icon: 'ti-layout-sidebar-right'
      }
    ]
  },
  {
    menu_title: 'Logging',
    menu_icon: 'ti-view-list',
    child_routes: [
      {
        path: '/automation/workflow/triggerTelemetry',
        menu_title: 'Workflow & Mapping Logs'
      },
      {
        path: '/automation/system/triggerexplorer',
        menu_title: 'Trigger Explorer',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser])
        }
      },
      {
        path: '/automation/workflow/manager',
        menu_title: 'Workflow Manager',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser])
        }
      }
    ]
  },
  {
    menu_title: 'Automation Tools',
    menu_icon: 'ti-panel',
    child_routes: [
      {
        path: '/automation/config/duplication',
        menu_title: 'Duplication',
        menu_icon: 'ti-layers'
      },
      {
        path: '/automation/config/foldermanager',
        menu_title: 'Folder Manager',
        menu_icon: 'ti-folder'
      },
      {
        path: '/automation/config/designersettings',
        menu_title: 'Designer Settings'
      },
      {
        path: '/automation/system/triggerexplorer',
        menu_title: 'Trigger Explorer'
      },
      {
        path: '/automation/config/workflowqueue',
        menu_title: 'Workflow Queue',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser])
        }
      }
    ]
  }
]
