var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group row", class: { invalid: _vm.value.$error } },
    [
      _c("label", { staticClass: "label-control col-md-3 required" }, [
        _vm._v("Bank Account")
      ]),
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _c("b-form-select", {
            attrs: { options: _vm.bankAccounts },
            model: {
              value: _vm.value.$model,
              callback: function($$v) {
                _vm.$set(_vm.value, "$model", $$v)
              },
              expression: "value.$model"
            }
          }),
          _c("validation-messages", {
            attrs: { name: "bank account" },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      _vm.canViewBanks
        ? _c("div", { staticClass: "col-md-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button" },
                on: { click: _vm.viewGroupBankDetails }
              },
              [_vm._v("View")]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }