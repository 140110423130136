<template>
  <div id="validationWarnings">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-column-filter="onColumnFilter"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :pagination-options="{
            enabled: true,
            perPage: 10,
            dropdownAllowAll: false,
            setCurrentPage: serverParams.page
          }"
          :sort-options="{
            enabled: false
          }"
          styleClass="vgt-table striped bordered">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'messageSeverity'">
              <span v-bind:class="getSeverityClass(props.row.messageSeverity)" class="badge badge-pill pull-left">{{props.row.messageSeverity}}</span>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="table-actions">
            <b-button
              @click.prevent="createPresubPdfReport"
              variant="link"
              v-b-popover.hover.top.d500="'Shows a summary of the validation messages'"
            >
              <i class="fa fa-file-pdf pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="createPresubCsvReport"
              variant="link"
              v-b-popover.hover.top.d500="'Export validation messages to CSV'"
            >
              <i class="fa fa-file-excel pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      submissionId: '',
      columns: [
        {
          label: 'File',
          field: 'fileNumber',
          type: 'number'
        },
        {
          label: 'Record',
          field: 'recordNumber',
          type: 'number'
        },
        {
          label: 'Severity',
          field: 'messageSeverity'
        },
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Description',
          field: 'description'
        },
        {
          label: 'Area',
          field: 'presubValArea',
          type: 'number'
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        download: true,
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      saveStarted: false,
      max: 100,
      progress: 0,
      presubValResponse: {},
      getTaskProgressUrl: '',
      endTaskUrl: '',
      groupBankAccount: {},
      downloadUrl: '',
      importFileResponse: {},
      bacsGroup: {},
      messageSeverity: '',
      subReference: ''
    }
  },

  methods: {
    getSeverityClass (severity) {
      if (severity === 'Duplicate') {
        return 'badge-warning'
      } else {
        return 'badge-' + severity.toLowerCase()
      }
    },

    getButtonClass () {
      if (this.presubValResponse.validationResult === 'Warnings') {
        return 'btn btn-light'
      } else {
        return 'btn btn-primary'
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems(false)
    },

    onPerPageChange (params) {
      this.rows.length = 0
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems(true)
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params.sortType,
          field: this.columns[params.columnIndex].field
        }
      })
      this.loadItems(true)
    },

    onColumnFilter (params) {
      this.updateParams(params)
      this.loadItems(true)
    },

    loadItems (resetStart) {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        messageSeverity: this.messageSeverity,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: false,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: resetStart,
        download: false
      })

      return axios({
        method: 'POST',
        url: this.downloadUrl,
        data: json
      })
        .then(response => {
          this.rows = response.data.data
          this.totalRecords = response.data.count
          this.numberOfWarnings = this.totalRecords
          this.$store.dispatch('setPresubValMessages', response.data)
          this.$store.dispatch('setServerParams', this.serverParams)
        })
    },

    async createPresubPdfReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationReport?submissionId=' + this.submissionId + '&submissionReference=' + this.subReference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },
    async createPresubCsvReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationCsvFile?submissionId=' + this.submissionId + '&submissionReference=' + this.subReference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },

  mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.fileNumber = this.$store.getters.fileNumber
    this.downloadUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getPresubValidationMessages'
    var viewSubmissionData = this.$store.getters.viewSubmissionData
    var bacsSubmission = this.$store.getters.bacsSubmission
    this.subReference = bacsSubmission.reference
    if (viewSubmissionData.presubValMessagesLoaded === false) {
      this.loadItems(true)
      viewSubmissionData.presubValMessagesLoaded = true
      this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
    } else {
      this.serverParams = this.$store.getters.serverParams
      var presubValMessages = this.$store.getters.presubValMessages
      this.rows = presubValMessages.data
      this.totalRecords = presubValMessages.count
    }
  }
}
</script>
