var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "downloadBureauCustomerReports" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Bureau Customer Configuration Report "),
                _vm._m(0),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-default p-curve",
                      attrs: { id: "reportAllNotInJob" },
                      on: {
                        change: function($event) {
                          return _vm.reportAllNotInJob()
                        }
                      },
                      model: {
                        value: _vm.checkedAllNotInJob,
                        callback: function($$v) {
                          _vm.checkedAllNotInJob = $$v
                        },
                        expression: "checkedAllNotInJob"
                      }
                    },
                    [_vm._v("Select All Bureau Customers Not In Jobs")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-default p-curve",
                      attrs: { id: "reportAllData" },
                      on: {
                        change: function($event) {
                          return _vm.reportAllJobs()
                        }
                      },
                      model: {
                        value: _vm.checkedAllJobs,
                        callback: function($$v) {
                          _vm.checkedAllJobs = $$v
                        },
                        expression: "checkedAllJobs"
                      }
                    },
                    [_vm._v("Select All Bureau Jobs")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-default p-curve",
                      attrs: { id: "reportAllCustomers" },
                      on: {
                        change: function($event) {
                          return _vm.reportAllCustomers()
                        }
                      },
                      model: {
                        value: _vm.checkedAllCustomers,
                        callback: function($$v) {
                          _vm.checkedAllCustomers = $$v
                        },
                        expression: "checkedAllCustomers"
                      }
                    },
                    [_vm._v("Select All Bureau Customers")]
                  )
                ],
                1
              )
            ]),
            _c("hr"),
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(2),
              _c("div", { staticClass: "col-md-2" }, [_vm._v("Bureau Jobs")]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("vue-select", {
                    attrs: {
                      multiple: "",
                      label: "name",
                      options: _vm.BureauJobDisplay,
                      closeOnSelect: false
                    },
                    on: { input: _vm.CheckBureauJobsDropdownEntries },
                    model: {
                      value: _vm.SelectedBureauJobs,
                      callback: function($$v) {
                        _vm.SelectedBureauJobs = $$v
                      },
                      expression: "SelectedBureauJobs"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-default p-curve",
                      attrs: { id: "inverseCustomerSelection" },
                      on: {
                        change: _vm.inverseBureauCustomerSelectionSelected
                      },
                      model: {
                        value: _vm.inverseBureauCustomerSelection,
                        callback: function($$v) {
                          _vm.inverseBureauCustomerSelection = $$v
                        },
                        expression: "inverseBureauCustomerSelection"
                      }
                    },
                    [_vm._v("Report Bureau Customers Not In Job")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _vm._v("Bureau Customers")
              ]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("vue-select", {
                    attrs: {
                      multiple: "",
                      label: "name",
                      options: _vm.BureauCustomersDisplay,
                      closeOnSelect: false
                    },
                    on: { input: _vm.CheckBureauCustomerDropdownEntries },
                    model: {
                      value: _vm.SelectedBureauCustomers,
                      callback: function($$v) {
                        _vm.SelectedBureauCustomers = $$v
                      },
                      expression: "SelectedBureauCustomers"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    paginationOptions: _vm.paginationOptions,
                    rows: _vm.rows,
                    columns: _vm.columns,
                    totalRows: _vm.totalRecords,
                    lineNumbers: true,
                    sortOptions: {
                      enabled: true
                    },
                    styleClass: "vgt-table striped bordered"
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-row-click": _vm.onRowClick,
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-column-filter": _vm.onColumnFilter,
                    "on-per-page-change": _vm.onPerPageChange
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Refresh the data in the table",
                              expression: "'Refresh the data in the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.loadTable($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-sync pointer dimmedIcon"
                          })
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Print out the contents of the table",
                              expression:
                                "'Print out the contents of the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.printTable($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-print pointer dimmedIcon"
                          })
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Export the contents of the table",
                              expression: "'Export the contents of the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.exportTable($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-file-export pointer dimmedIcon"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "col-md-12" }, [
      _c("h4", [_c("u", [_vm._v("Presets")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "col-md-12" }, [
      _c("h4", [_c("u", [_vm._v("Search")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }