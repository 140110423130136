var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Login Policy "),
                _c("help-icon", {
                  attrs: {
                    docPath: "/administration/securitypolicy/loginpolicy/"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _vm._m(0),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-4 offset-md-1" },
                  [_vm._v("Enforce two-factor authentication")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary", disabled: !_vm.mfaEnabled },
                      model: {
                        value: _vm.$v.policy.checkTokenAtLogin.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.checkTokenAtLogin,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.checkTokenAtLogin.$model"
                      }
                    })
                  ],
                  1
                ),
                _vm.$v.policy.checkTokenAtLogin.$model &&
                !_vm.$v.policy.allowAuthenticator.$model &&
                !_vm.$v.policy.allowEmail.$model &&
                !_vm.$v.policy.allowSMS.$model &&
                !_vm.$v.policy.allowUSBToken.$model
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("small", { staticClass: "form-text text-warning" }, [
                        _vm._v("You must select at least one two-factor option")
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm.$v.policy.checkTokenAtLogin.$model
                ? _c("div", { staticClass: "offset-md-1" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-4 offset-md-1" },
                        [_vm._v("Allow Authenticator")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-1" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: {
                              color: "primary",
                              disabled: !_vm.mfaOptions.some(function(mfa) {
                                return mfa === "Authenticator"
                              })
                            },
                            on: {
                              change: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.deselect.apply(
                                  void 0,
                                  argsArray.concat(["Authenticator"])
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.policy.allowAuthenticator.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.policy.allowAuthenticator,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.policy.allowAuthenticator.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-4 offset-md-1" },
                        [_vm._v("Allow Email")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-1" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: {
                              color: "primary",
                              disabled: !_vm.mfaOptions.some(function(mfa) {
                                return mfa === "Email"
                              })
                            },
                            on: {
                              change: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.deselect.apply(
                                  void 0,
                                  argsArray.concat(["Email"])
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.policy.allowEmail.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.policy.allowEmail,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.policy.allowEmail.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-4 offset-md-1" },
                        [_vm._v("Allow SMS")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-1" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: {
                              color: "primary",
                              disabled: !_vm.mfaOptions.some(function(mfa) {
                                return mfa === "SMS"
                              })
                            },
                            on: {
                              change: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.deselect.apply(
                                  void 0,
                                  argsArray.concat(["SMS"])
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.policy.allowSMS.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.policy.allowSMS, "$model", $$v)
                              },
                              expression: "$v.policy.allowSMS.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-4 offset-md-1" },
                        [_vm._v("Allow USB Tokens")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-1" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: {
                              color: "primary",
                              disabled: !_vm.mfaOptions.some(function(mfa) {
                                return mfa === "USB Token"
                              })
                            },
                            on: {
                              change: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.deselect.apply(
                                  void 0,
                                  argsArray.concat(["USB Token"])
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.policy.allowUSBToken.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.policy.allowUSBToken,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.policy.allowUSBToken.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.policy.defaultTwoFactor.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "col-form-label col-md-4 offset-md-1 required"
                          },
                          [_vm._v("Default Method")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("b-form-select", {
                              staticClass: "form-control",
                              attrs: { options: _vm.approvalMethods },
                              model: {
                                value: _vm.$v.policy.defaultTwoFactor.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.policy.defaultTwoFactor,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.policy.defaultTwoFactor.$model"
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "This will restrict all users to this choice, you can override this setting per user in the user edit screen"
                                )
                              ]
                            ),
                            _c("validation-messages", {
                              attrs: { name: "default two factor method" },
                              model: {
                                value: _vm.$v.policy.defaultTwoFactor,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.policy,
                                    "defaultTwoFactor",
                                    $$v
                                  )
                                },
                                expression: "$v.policy.defaultTwoFactor"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-4 offset-md-1" },
                  [_vm._v("Lock out inactive accounts")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.$v.policy.lockOutInactiveAccounts.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.lockOutInactiveAccounts,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.lockOutInactiveAccounts.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.policy.lockOutInactiveAccounts
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.policy.maximumInactivityDays.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "col-form-label col-md-4 offset-md-1 required"
                          },
                          [_vm._v("Number of days inactivity")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value:
                                    _vm.$v.policy.maximumInactivityDays.$model,
                                  expression:
                                    "$v.policy.maximumInactivityDays.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number", min: "1" },
                              domProps: {
                                value:
                                  _vm.$v.policy.maximumInactivityDays.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.policy.maximumInactivityDays,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "number of inactive days" },
                              model: {
                                value: _vm.$v.policy.maximumInactivityDays,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.policy,
                                    "maximumInactivityDays",
                                    $$v
                                  )
                                },
                                expression: "$v.policy.maximumInactivityDays"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm.$v.policy.lockOutInactiveAccounts.$model
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-4 offset-md-1"
                          },
                          [
                            _vm._v(
                              "Exclude specific accounts from auto-lockout"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-1" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value:
                                  _vm.$v.policy.excludeSpecificUsers.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.policy.excludeSpecificUsers,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.policy.excludeSpecificUsers.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.policy.excludeSpecificUsers
                          ? _c(
                              "div",
                              {
                                staticClass: "form-group row",
                                class: {
                                  invalid: _vm.$v.policy.excludedUsers.$error
                                }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-form-label col-md-4 offset-md-1"
                                  },
                                  [_vm._v("Excluded users")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-5" },
                                  [
                                    _c("vue-select", {
                                      attrs: {
                                        multiple: "",
                                        options: _vm.users,
                                        closeOnSelect: false
                                      },
                                      model: {
                                        value:
                                          _vm.$v.policy.excludedUsers.$model,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.policy.excludedUsers,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$v.policy.excludedUsers.$model"
                                      }
                                    }),
                                    _c("validation-messages", {
                                      model: {
                                        value: _vm.$v.policy.excludedUsers,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.policy,
                                            "excludedUsers",
                                            $$v
                                          )
                                        },
                                        expression: "$v.policy.excludedUsers"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-form-label col-md-11 offset-md-1" }, [
        _vm._v("Governs how paygate behaves when a user attempts to Login")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }