var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "signSubmission" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.actionLoaded && _vm.isLoaded,
              expression: "actionLoaded && isLoaded"
            }
          ]
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button", disabled: this.enableSign === false },
              on: { click: _vm.startSignSubmission }
            },
            [_vm._v(_vm._s(_vm.signButtonText))]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-danger pull-right",
              attrs: {
                type: "button",
                disabled: this.enableSign === false && this.canCancel === false
              },
              on: { click: _vm.cancelSubmission }
            },
            [_vm._v("Cancel Submission")]
          )
        ]
      ),
      _vm.showeSigner
        ? _c("div", { attrs: { id: "eSigner" } }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.eSignerHtml) } })
          ])
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSmsRequest,
            expression: "showSmsRequest"
          }
        ],
        staticClass: "row form-group"
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSmsRequest,
              expression: "showSmsRequest"
            }
          ]
        },
        [
          _c("div", { staticClass: "row form-group" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-md-2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.securityCode,
                    expression: "securityCode"
                  }
                ],
                ref: "securityCodeFocus",
                staticClass: "form-control",
                attrs: { name: "securityCode" },
                domProps: { value: _vm.securityCode },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.verifySecurityCode($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.securityCode = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light pull-right",
                  attrs: {
                    type: "button",
                    disabled: this.verifyingCode === true
                  },
                  on: { click: _vm.verifySecurityCode }
                },
                [_vm._v("Verify Code")]
              )
            ])
          ])
        ]
      ),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Security Code")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }