<template>
  <div id="createBacsSubFromFile">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Create BACS Submission From File
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2 required">
                <strong>Select a Group</strong>
              </div>
              <div class="col-md-4">
                <group-select
                  v-model="selectedGroupId"
                  :groups="groups"
                  @input="selectGroup()"
                  :clearable="false"
                ></group-select>
              </div>
              <div class="col-md-1">
                <button
                  class="btn btn-outline-secondary"
                  :disabled="this.groupBankLoaded === false"
                  @click="viewGroupBankDetails"
                >View</button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 required">
                <strong>Submission Reference</strong>
              </div>
              <div class="col-md-6">
                <input
                  name="reference"
                  v-model="submissionData.reference"
                  v-on:input="referenceChanged($event.target.value)"
                  class="form-control"
                  style="width: 300px;"
                  maxlength="150"
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 required">
                <strong>Payment Date</strong>
              </div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="paymentDate"
                  name="paymentDate"
                  id="paymentDate"
                  v-model="paymentDate"
                  format="dd/MM/yyyy"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  :disabledDates="disabledDates"
                  v-on:selected="paymentDateChanged"
                  @focusin.native="dateOnfocus"
                  :monday-first="true"
                  :disabled="disablePicker"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>BACS Processing Date</strong>
              </div>
              <div class="col-md-2">{{ processingDate }}</div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Contra Narrative</strong>
              </div>
              <div class="col-md-6">
                <input
                  name="contraNarrative"
                  v-model="submissionData.contraNarrative"
                  class="form-control"
                  style="width: 300px;"
                  maxlength="50"
                />
              </div>
            </div>
            <br />
            <div v-show="showDropzone" class="row form-group">
              <div id="dzUpload" class="col-md-12">
                <vue-dropzone
                  id="drop1"
                  ref="fileUploader"
                  v-bind:use-custom-dropzone-options="true"
                  :options="dropzoneOptions"
                  v-on:vdropzone-success="afterSuccess"
                  v-on:vdropzone-file-added="fileAdded"
                ></vue-dropzone>
              </div>
            </div>
            <div v-show="initRestrictedFileBrowser" class="row form-group">
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="showRestrictedFileBrowser"
                >Select Submission File</button>
              </div>
            </div>
            <div v-show="showFtpBrowser" class="row form-group">
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="btnFtpFileBrowser"
                >Select Submission File</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
    <fileBrowser v-if="showFtpFileBrowser" @close="closeFileBrowserPopup()" :groupId=selectedGroupId>
      <h3 slot="header">Select Submission File</h3>
    </fileBrowser>
  </div>
</template>

<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import bacsCommon from '@/Lib/BacsCommon.js'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import bacsMixin from '@/Lib/BacsMixin.js'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import FileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
import swal from 'sweetalert2'

export default {
  name: 'createBacsSubFromFile',
  mixins: [
    bacsMixin
  ],

  components: {
    plugin: Plugin,
    fileBrowser: FileBrowser
  },

  data: () => ({
    dropzoneUrl: '',
    groups: null,
    pgeGroups: [],
    pgoGroups: [],
    selectedGroupId: '',
    PGEGROUPTYPE: '0',
    PGOGROUPTYPE: '3',
    selectedGroup: {},
    submissionData: {},
    dropzoneOptions: {
      url: '',
      language: {
        dictDefaultMessage: 'Drop a file here or click to select a file to upload.'
      },
      timeout: 180000,
      maxFiles: 1,
      maxFilesize: 200 // MBs
    },
    groupSelected: false,
    subRef: '',
    processingDates: {},
    paymentDate: '',
    processingDate: '',
    disabledDates: {},
    disableDatePicker: true,
    changeSubmission: false,
    bacsGroup: {},
    bearerToken: '',
    paygateId: '',
    userId: '',
    createUser: {},
    signingUser: {},
    useRestrictedFileBrowser: false,
    useFtpFileBrowser: false,
    useDropzone: false,
    showFtpFileBrowser: false,
    browser: '',
    plugin: undefined,
    pluginLoaded: false,
    pluginResponse: {},
    deviceType: 'Restricted File Browser',
    currentPage: '/payments/bacspayments/createBacsSubFromFile',
    componentId: 3, // RFB component in version string returned by plugin.
    expectedVersion: '',
    bacsLicenceSetting: '',
    pluginUpdateNotInstalled: false,
    groupBankAccount: {},
    groupBankLoaded: false,
    helpUrl: ''
  }),
  computed: {
    showDropzone: function () {
      const refNotJustSpaces = this.subRef.replace(/\s/g, '').length > 0
      return this.groupSelected && refNotJustSpaces && this.useDropzone === true
    },
    showFtpBrowser: function () {
      const refNotJustSpaces = this.subRef.replace(/\s/g, '').length > 0
      return this.groupSelected && refNotJustSpaces && this.useFtpFileBrowser === true
    },
    initRestrictedFileBrowser: function () {
      const refNotJustSpaces = this.subRef.replace(/\s/g, '').length > 0
      return this.groupSelected && refNotJustSpaces && this.useRestrictedFileBrowser === true && this.pluginUpdateNotInstalled === false
    },
    disablePicker: function () {
      return this.disableDatePicker || this.paymentDate.length === 0
    }
  },
  methods: {
    async selectGroup () {
      this.selectedGroup = this.groups.find(i => i.groupId === this.selectedGroupId)
      this.submissionData.groupId = this.selectedGroup.groupId
      this.submissionData.groupName = this.selectedGroup.name
      this.groupSelected = true
      this.$store.commit('setSelectedGroup', this.selectedGroup)
      this.isModalVisible = false

      await this.getSelectedGroupDetails()

      this.createUser = this.bacsGroup.groupUserRights.find(i => i.userId === this.userId)
      if (this.createUser.uploadType === this.deviceType) { // 'Restricted File Browser'
        // Check for web extension and plugin...
        try {
          await this.$store.commit('setDeviceType', this.deviceType)
          await this.$store.dispatch('checkPlugin')
        } catch (error) {
          this.$toastr.w(error.message, 'Warning')
        }

        this.useRestrictedFileBrowser = true
        this.useFtpFileBrowser = false
        this.useDropzone = false
      } else if (this.createUser.uploadType === 'Secure Cloud Folder') {
        this.$store.commit('setModalPopupString', this.createUser.uploadPath)
        this.useFtpFileBrowser = true
        this.useRestrictedFileBrowser = false
        this.useDropzone = false
      } else {
        // Dropzone-browser dialog.
        this.useDropzone = true
        this.useRestrictedFileBrowser = false
        this.useFtpFileBrowser = false
      }

      this.disableDatePicker = this.bacsGroup.groupTypeDetails.importSchema === 'Standard18'
      var payload = { paygateId: this.paygateId, groupId: this.selectedGroupId, platformUrl: this.$platformApiBaseUrl }
      this.groupBankLoaded = false
      await this.$store.dispatch('getGroupBankAccount', payload)
      this.groupBankAccount = await this.$store.getters.groupBankAccount
      this.groupBankLoaded = true

      this.signingUser = this.bacsGroup.groupUserRights.find(i => i.sign === true)
      if (this.signingUser === undefined) {
        this.$toastr.w('There are no users who can sign a submission created for this group.')
      }
    },

    async getSelectedGroupDetails () {
      await this.$store.dispatch('getSubBacsGroup', this.selectedGroupId)
      this.bacsGroup = this.$store.getters.bacsGroup
      this.disableDatePicker = this.bacsGroup.importSchema === 'Standard18'
    },

    async viewGroupBankDetails () {
      await swal.fire({
        title: '"' + this.submissionData.groupName + '" group bank details',
        html: bacsCommon.viewGroupBankDetails(this.groupBankAccount, this.submissionData.groupName),
        icon: 'info'
      })
    },

    referenceChanged (value) {
      this.subRef = value
    },

    paymentDateChanged (value) {
      this.$store.dispatch('getNextProcessingDate', value.toJSON())
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
        })
    },

    dateOnfocus () {
    },

    afterSuccess (file, response) {
      this.submissionData.submissionId = response.submissionId
      this.submissionData.filename = response.filename
      this.submissionData.reference = this.subRef
      this.submissionData.processingDate = this.processingDate
      this.submissionData.paymentDate = this.paymentDate
      this.submissionData.paymentNetworkType = 'BacstelIp'
      this.submissionData.submissionType = 'BACS'
      this.submissionData.createPage = 'createBacsSubFromFile'
      this.submissionData.usedFtpFileBrowser = this.useFtpFileBrowser
      if (this.submissionData.contraNarrative === undefined) {
        this.submissionData.contraNarrative = ''
      }
      this.$store.dispatch('setSubmissionData', this.submissionData)
      this.$router.push('/payments/bacspayments/importingpayments')
    },

    fileAdded (file) {
      // eslint-disable-next-line
      this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
    },

    async loadGroups (groupType) {
      var payload = { paygateId: this.paygateId, groupType: groupType, groupRole: ['create'] }
      await this.$store.dispatch('getGroups', payload)

      if (groupType === this.PGEGROUPTYPE) {
        this.pgeGroups = this.$store.getters.groups
      } else {
        this.pgoGroups = this.$store.getters.groups
      }
    },

    async populateGroupCombo () {
      this.groups = []
      for (var i = 0; i < this.pgeGroups.length; i++) {
        if (this.pgeGroups[i].importSchema !== 'None') {
          this.groups.push(this.pgeGroups[i])
        }
      }

      for (i = 0; i < this.pgoGroups.length; i++) {
        if (this.pgoGroups[i].importSchema !== 'None') {
          this.groups.push(this.pgoGroups[i])
        }
      }

      this.groups.sort((a, b) => a.name.localeCompare(b.name))
    },

    showRestrictedFileBrowser () {
      if (this.browser === 'Firefox') {
        this.showRestrictedFileBrowserFirefox()
      } else if (this.browser === 'Chrome') {
        this.showRestrictedFileBrowserChome()
      } else if (this.browser === 'IE') {
        this.showRestrictedFileBrowserIE()
      }
    },

    showRestrictedFileBrowserChome () {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'UploadFromRestrictedFolder', StartDirectory: this.createUser.uploadPath, DirectoryLocked: this.createUser.directoryLocked, BearerToken: this.bearerToken, Url: this.dropzoneUrl }, (response) => {
          var pluginResponse = common.parsePluginResponse(response.response)
          if (pluginResponse.SelectedFileName !== null) {
            var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
            this.afterSuccess(null, fileBrowserResponse)
          } else if (pluginResponse.ErrorMessage !== null) {
            this.$snapbar.e(pluginResponse.ErrorMessage)
          }
        })
        resolve()
      })
    },

    showRestrictedFileBrowserFirefox () {
      this.$store.dispatch('setWebExtPluginResponse', null)

      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          if (pluginResponse.SelectedFileName !== null) {
            var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
            this.afterSuccess(null, fileBrowserResponse)
            resolve()
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
          }
        }, { once: true })

        // eslint-disable-next-line
        var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'UploadFromRestrictedFolder', StartDirectory: this.createUser.uploadPath, DirectoryLocked: this.createUser.directoryLocked, BearerToken: this.bearerToken, Url: this.dropzoneUrl } })
        document.dispatchEvent(pluginEvent)
      })
    },

    showRestrictedFileBrowserIE () {
      this.plugin = document.PayGatePlugin
      var result = this.plugin.UploadFromRestrictedFolder(this.createUser.uploadPath, this.createUser.directoryLocked, this.bearerToken, this.dropzoneUrl, 1000)
      if (result.indexOf('Error') === -1) {
        var i = result.indexOf('_')
        var submissionId = result.substring(0, i)
        var filename = result.substring(i + 1)
        var response = { submissionId: submissionId, filename: filename }
        this.afterSuccess(null, response)
      } else {
        this.$snapbar.e(result)
      }
    },

    async loadLicence () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
      this.bacsLicenceSetting = response.data.bacs.value
    },

    async checkLicence () {
      var licenceOk = true
      if (this.bacsLicenceSetting === 'Direct' || this.bacsLicenceSetting === 'Both') {
        await this.loadGroups(this.PGEGROUPTYPE)
      }
      if (this.bacsLicenceSetting === 'Indirect' || this.bacsLicenceSetting === 'Both') {
        await this.loadGroups(this.PGOGROUPTYPE)
      }
      if (this.bacsLicenceSetting === 'Off') {
        this.$snapbar.w('You are not licensed to create BACS submissions.')
        licenceOk = false
      }

      return licenceOk
    },

    async getCustomer () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'customers')
      if (!response.data.data[0].allowLiveSubmissions) {
        this.$snapbar.e('Your customer is set in \'Test Mode\' so is not allowed to send live submissions at this time.')
      }
    },

    pluginNotInstalled () {
      this.pluginUpdateNotInstalled = true
    },

    async closeFileBrowserPopup () {
      this.showFtpFileBrowser = false
      var selectedFileName = this.$store.state.mapping.modalPopupString
      // Check user has selected a file.
      var i = selectedFileName.lastIndexOf('.')
      if (i >= 0) {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getNewSubmissionId`)
        var fileBrowserResponse = { submissionId: response.data, filename: selectedFileName }
        this.afterSuccess(null, fileBrowserResponse)
      }
    },

    btnFtpFileBrowser () {
      this.showFtpFileBrowser = true
    }
  },

  created () {
    this.dropzoneOptions.url = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/uploadFile'
    this.dropzoneUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/uploadFile'
  },

  async mounted () {
    this.userId = this.$store.getters.getClaim('sub').value
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/bacs-payments/createbacssubmissionfromfile/'

    await this.loadLicence()
    if (!await this.checkLicence()) {
      return
    }

    await this.populateGroupCombo()

    this.browser = bacsCommon.getBrowser()
    // if (this.browser === 'IE') {
    //   this.expectedVersion = '2' // Active X plugin for IE.
    // } else {
    //   this.expectedVersion = '1' // Native messaging plugin for Chrome and Firefox
    // }

    if (this.groups.length > 0) {
      // this.groups.sort((a, b) => a.name.localeCompare(b.name))
      await this.$store.dispatch('setGroups', this.groups)
    } else {
      this.$snapbar.w('You don\'t have permission to create a submission for any groups.')
    }

    await this.getCustomer()

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })

    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'

    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.groups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group.
      if (this.selectedGroup !== undefined) {
        this.selectedGroupId = defaultGroupId
        await this.selectGroup()
      }
    }

    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate

        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }

        if (this.bacsGroup.groupTypeDetails === undefined || this.bacsGroup.groupTypeDetails === null) {
          this.disableDatePicker = false
        } else {
          this.disableDatePicker = this.bacsGroup.groupTypeDetails.importSchema === 'Standard18'
        }

        this.changeSubmission = this.$route.query.changeSubmission
        if (this.changeSubmission === 'true') {
          this.submissionData = this.$store.getters.submissionData
          this.selectedGroupId = this.submissionData.groupId
          this.groupSelected = true
          this.subRef = this.submissionData.reference
          this.processingDate = this.submissionData.processingDate
          this.paymentDate = this.submissionData.paymentDate
          this.changeSubmission = false

          this.selectedGroup = this.groups.find(i => i.groupId === this.submissionData.groupId)
          this.selectGroup()
        } else {
          var extLoaded = this.$route.query.pluginAccessible
          if (extLoaded === 'true') {
            this.pluginCheckComplete = true
            this.selectedGroup = this.$store.getters.selectedGroup
            if (this.selectedGroup !== null && this.selectedGroup !== undefined && this.selectedGroup.groupId !== undefined) {
              this.selectGroup()
            }
          }
        }
      })
  },

  destroyed () {
    if (this.browser === 'Firefox') {
      document.removeEventListener('getPluginResponseWebPage', this.initPluginEvent)
      document.removeEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
    }
  }
}
</script>
