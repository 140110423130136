var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("span", { staticClass: "pull-right" }),
                _vm._v("Workflow Queue"),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("form", [
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-12 col-lg-6 mb-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v("Backlog Queue")
                        ]),
                        _vm.row_data_backlogQueue.length > 0
                          ? _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "primary" } },
                              [_vm._v(_vm._s(_vm.row_data_backlogQueue.length))]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "vue-good-table",
                      {
                        ref: "table1",
                        attrs: {
                          paginationOptions: _vm.paginationOptions,
                          "sort-options": _vm.sortOptions,
                          isLoading: _vm.isTableLoading,
                          columns: _vm.columns,
                          rows: _vm.rowsBacklogQueue,
                          lineNumbers: true,
                          "search-options": { enabled: true },
                          styleClass: "vgt-table striped bordered table-hover"
                        },
                        on: {
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "on-page-change": _vm.onPageChange,
                          "on-sort-change": _vm.onSortChange,
                          "on-column-filter": _vm.onColumnFilter,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch,
                          "on-row-click": _vm.onRowClick
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "lastExecutionDate"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          ) +
                                          " "
                                      ),
                                      props.formattedRow[props.column.field]
                                        ? _c("div")
                                        : _vm._e()
                                    ])
                                  : props.column.field == "buttons"
                                  ? _c("span", [
                                      _c("div", { staticClass: "dropdown" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link dropdown-toggle",
                                            attrs: {
                                              type: "button",
                                              id: "dropdownMenuButton",
                                              "data-toggle": "dropdown",
                                              "aria-haspopup": "true",
                                              "aria-expanded": "false"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "glyphicon text-secondary ti-more-alt"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              "aria-labelledby":
                                                "dropdownMenuButton"
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.btnRemoveFrombBacklog(
                                                      props
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Remove from queue")]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          ) +
                                          " "
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear filters",
                                    expression: "'Clear filters'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.clearTableFilters($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fa-stack",
                                    staticStyle: { "font-size": "10px" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-sync-alt pointer dimmedIcon pointer"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-12 col-lg-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v("Processing Queue")
                        ]),
                        _vm.row_data_processingQueue.length > 0 &&
                        _vm.row_data_processingQueue.length <= 5
                          ? _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "primary" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.row_data_processingQueue.length)
                                )
                              ]
                            )
                          : _vm.row_data_processingQueue.length > 0 &&
                            _vm.row_data_processingQueue.length <= 9
                          ? _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "warning" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.row_data_processingQueue.length)
                                )
                              ]
                            )
                          : _vm.row_data_processingQueue.length > 0 &&
                            _vm.row_data_processingQueue.length === 10
                          ? _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "danger" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.row_data_processingQueue.length)
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "vue-good-table",
                      {
                        ref: "table2",
                        attrs: {
                          paginationOptions: _vm.paginationOptions,
                          "sort-options": _vm.sortOptions,
                          isLoading: _vm.isTableLoading,
                          columns: _vm.columns,
                          rows: _vm.rowsProcessingQueue,
                          lineNumbers: true,
                          "search-options": { enabled: true },
                          styleClass: "vgt-table striped bordered table-hover"
                        },
                        on: {
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "on-page-change": _vm.onPageChange,
                          "on-sort-change": _vm.onSortChange,
                          "on-column-filter": _vm.onColumnFilter,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch,
                          "on-row-click": _vm.onRowClick
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "lastExecutionDate"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          ) +
                                          " "
                                      ),
                                      props.formattedRow[props.column.field]
                                        ? _c("div")
                                        : _vm._e()
                                    ])
                                  : props.column.field == "buttons"
                                  ? _c("span", [
                                      _c("div", { staticClass: "dropdown" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link dropdown-toggle",
                                            attrs: {
                                              type: "button",
                                              id: "dropdownMenuButton",
                                              "data-toggle": "dropdown",
                                              "aria-haspopup": "true",
                                              "aria-expanded": "false"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "glyphicon text-secondary ti-more-alt"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              "aria-labelledby":
                                                "dropdownMenuButton"
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.btnRemoveFromProcessing(
                                                      props
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Remove from queue")]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          ) +
                                          " "
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear filters",
                                    expression: "'Clear filters'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.clearTableFilters($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fa-stack",
                                    staticStyle: { "font-size": "10px" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-sync-alt pointer dimmedIcon pointer"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }