var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "importedPayments" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c("h2", [
              _vm._v(" Submission Payments "),
              _c("span", { staticClass: "pull-right" }, [
                _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
                  _c("i", { staticClass: "far fa-question-circle" })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm.showImportMessages === true
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card",
                      {
                        attrs: {
                          "border-variant": _vm.importMessagesVariant,
                          header: _vm.messageHeader,
                          "header-bg-variant": _vm.importMessagesVariant,
                          "header-text-variant": "white"
                        }
                      },
                      _vm._l(_vm.importFileResponse.responseMessages, function(
                        msg
                      ) {
                        return _c("div", { key: msg.messageText }, [
                          _c("div", [_vm._v(_vm._s(msg.messageText))])
                        ])
                      }),
                      0
                    ),
                    _c("br")
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "row form-group" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _vm._m(0),
                _c("div", { staticClass: "row" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        staticStyle: {
                          padding: "0 0 0 0",
                          "white-space": "normal",
                          "text-align": "left"
                        },
                        on: { click: this.viewGroupDetails }
                      },
                      [_vm._v(_vm._s(_vm.submissionData.groupName))]
                    )
                  ]),
                  _vm._m(2),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-2",
                      staticStyle: {
                        "overflow-wrap": "break-word",
                        "word-wrap": "break-word"
                      }
                    },
                    [_vm._v(_vm._s(this.submissionData.reference))]
                  ),
                  _vm._m(3),
                  _c("div", { staticClass: "col-md-1 alertValueRight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatCount(
                          _vm.importFileResponse.totalNumberOfCredits
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "col-md-2 alertValueRight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatValue(
                          _vm.importFileResponse.totalCreditsValue
                        )
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._v(_vm._s(_vm.displayedPaymentDate))
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.submissionData.submissionType) +
                          " Processing Date"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._v(_vm._s(_vm.formatDate(this.selectedProcessingDate)))
                  ]),
                  _vm._m(5),
                  _c("div", { staticClass: "col-md-1 alertValueRight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatCount(
                          _vm.importFileResponse.totalNumberOfDebits
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "col-md-2 alertValueRight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatValue(_vm.importFileResponse.totalDebitsValue)
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm._m(6),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-2",
                      staticStyle: {
                        "overflow-wrap": "break-word",
                        "word-wrap": "break-word"
                      }
                    },
                    [_vm._v(_vm._s(this.selectedContraNarrative))]
                  ),
                  _vm._m(7),
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._v(_vm._s(this.submissionData.filename))
                  ]),
                  _vm._m(8),
                  _c("div", { staticClass: "col-md-1 alertValueRight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatCount(
                          _vm.importFileResponse.totalNumberOfDdis
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "col-md-2" })
                ])
              ])
            ]),
            this.fileBlocks > 1
              ? _c("div", { staticClass: "row form-group" }, [
                  _vm._m(9),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            text: _vm.selectedFile,
                            split: "",
                            variant: "outline-secondary"
                          }
                        },
                        _vm._l(_vm.fileDetails, function(detail) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: detail.fileNumber,
                              attrs: { value: detail.fileNumber },
                              on: {
                                click: function($event) {
                                  return _vm.selectFileDetail(detail)
                                }
                              }
                            },
                            [_vm._v(_vm._s(detail.filename))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.totalSubmissionPayments > 0
              ? _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "vue-good-table",
                        {
                          ref: "payments",
                          attrs: {
                            mode: "remote",
                            columns: _vm.columns,
                            rows: _vm.rows,
                            totalRows: _vm.totalRecords,
                            "pagination-options": {
                              enabled: true,
                              perPage: 10,
                              dropdownAllowAll: false
                            },
                            styleClass: "vgt-table striped bordered"
                          },
                          on: {
                            "on-page-change": _vm.onPageChange,
                            "on-sort-change": _vm.onSortChange,
                            "on-per-page-change": _vm.onPerPageChange,
                            "on-column-filter": _vm.onColumnFilter,
                            "on-row-mouseenter": _vm.onRowMouseover,
                            "on-row-mouseleave": _vm.onRowMouseleave,
                            "on-row-click": _vm.onRowClick
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "table-row",
                                fn: function(props) {
                                  return [
                                    props.column.field ===
                                    "displayProcessingDate"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  props.row
                                                    .displayProcessingDate
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            )
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1803139167
                          )
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "emptystate" },
                              slot: "emptystate"
                            },
                            [
                              _vm._v(" Click the "),
                              _c("strong", [_vm._v("Create Payment")]),
                              _vm._v(" button or the "),
                              _c("i", {
                                staticClass:
                                  "fa fa-plus-circle pointer dimmedIcon"
                              }),
                              _vm._v(
                                " icon to create the first payment in this submission. "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Create a new payment",
                                      expression: "'Create a new payment'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled:
                                      this.groupUserRights.add === false ||
                                      this.disableEditing === true,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.createBacsPayment($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-plus pointer dimmedIcon"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Reset amounts to zero",
                                      expression: "'Reset amounts to zero'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled:
                                      this.groupUserRights.edit === false ||
                                      this.disableEditing === true,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.resetAmountsToZero($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-power-off dimmedIcon"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Save submission file",
                                      expression: "'Save submission file'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled:
                                      this.bacsGroup.groupTypeDetails
                                        .importSchema === "Standard18" ||
                                      this.disableEditing === true,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.saveSubmissionFile($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-file pointer dimmedIcon"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value:
                                        "Reset payment table options and filters",
                                      expression:
                                        "'Reset payment table options and filters'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled: this.disableEditing === true,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.refreshGrid($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-sync pointer dimmedIcon"
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions-bottom" },
                              slot: "table-actions-bottom"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row",
                                  staticStyle: { padding: "8px 0 8px 8px" }
                                },
                                [
                                  _c("div", { staticClass: "col-md-2" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-outline-primary",
                                        attrs: {
                                          disabled: this.disableCreate === true
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.createBacsPayment($event)
                                          }
                                        }
                                      },
                                      [_vm._v("Add Payment")]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.totalSubmissionPayments === 0
              ? _c("div", [
                  _c("br"),
                  _vm._m(10),
                  _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary",
                          attrs: { disabled: this.disableCreate === true },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.createBacsPayment($event)
                            }
                          }
                        },
                        [_vm._v("Add Payment")]
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  disabled: this.disableValidatePayments
                },
                on: { click: _vm.initPresubVal }
              },
              [_vm._v("Validate Payments")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary ml-3",
                attrs: {
                  type: "button",
                  disabled: this.validationStarted === true
                },
                on: { click: _vm.changeSubmission }
              },
              [_vm._v("Change Submission")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary ml-3",
                attrs: { type: "button", disabled: this.disableSubInProgress },
                on: { click: _vm.saveInProgressSubmission }
              },
              [_vm._v("Save Submission Progress")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.editAction,
                    expression: "this.editAction"
                  }
                ],
                staticClass: "btn btn-danger pull-right ml-3",
                attrs: {
                  type: "button",
                  disabled: this.validationStarted === true
                },
                on: { click: _vm.cancelEditAction }
              },
              [_vm._v("Cancel Submission")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validationStarted,
                    expression: "validationStarted"
                  }
                ],
                staticClass: "btn btn-danger pull-right",
                attrs: {
                  type: "button",
                  disabled: this.validationCancelled === true
                },
                on: { click: _vm.cancelValidation }
              },
              [_vm._v("Cancel Validation")]
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.validationStarted,
                  expression: "validationStarted"
                }
              ],
              staticClass: "row form-group"
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validationStarted,
                    expression: "validationStarted"
                  }
                ],
                staticClass: "row form-group"
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-progress",
                      {
                        staticClass: "mb-3",
                        attrs: {
                          striped: "",
                          animated: true,
                          max: _vm.max,
                          "show-progress": ""
                        }
                      },
                      [
                        _c("b-progress-bar", {
                          attrs: {
                            value: _vm.progress,
                            label: _vm.progress + "%"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      [
        _c("editPaymentModal", {
          attrs: {
            payment: _vm.currentPayment,
            newPayment: _vm.newPayment,
            txCodes: _vm.txCodes,
            isMultiDate: _vm.isMultiDate,
            disabledDates: _vm.disabledDates,
            selPaymentDate: _vm.selectedPaymentDate,
            canSavePayment: _vm.canSavePayment,
            canDeletePayment: _vm.canDeletePayment,
            submissionReference: _vm.submissionData.reference,
            importResponse: _vm.importFileResponse,
            maxAmount: _vm.maxAmount,
            networkType: _vm.submissionData.paymentNetworkType
          },
          on: {
            deletePayment: _vm.paymentDeleted,
            savePayment: _vm.paymentSaved,
            close: _vm.editCancelled
          },
          model: {
            value: _vm.isModalVisible,
            callback: function($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8" }),
      _c("div", { staticClass: "col-md-1" }),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _c("strong", [_vm._v("Items")])
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _c("strong", [_vm._v("Total (£)")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Group Name")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Reference")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("strong", [_vm._v("Credits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Payment Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("strong", [_vm._v("Debits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Contra Narrative")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Submission File")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("strong", [_vm._v("DDIs")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", [_vm._v("Select File")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row form-group" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm._v(" Click the "),
        _c("strong", [_vm._v("Add Payment")]),
        _vm._v(" button to create the first payment in this submission. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }