<template>
  <div id="viewFlaggedSubmissions">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/submissions/" />Orphaned Submissions<favourite-icon></favourite-icon></h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Select a Group
              </div>
              <div class="col-md-4">
                <group-select
                  v-model="group"
                  :groups="customerGroups"
                  @input="customerGroupsChanged()"
                  :clearable="false"
                ></group-select>
              </div>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Earliest Date
              </div>
              <div class="col-md-4">
              <div class="right-inner-addon">
                <datepicker id="endPaymentDatePicker"
                  v-model="endDate"
                  :disabledDates="disabledRangeDates"
                  :format="formatDate" @input="load()" input-class="form-control datepicker"
                /><i class="fa fa-calendar form-control-feedback"></i>
              </div>
              </div>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Latest Date
              </div>
              <div class="col-md-4">
              <div class="right-inner-addon">
                <datepicker id="startPaymentDatePicker"
                    v-model="startDate"
                    :disabledDates="disabledRangeDatesEnd"
                    :format="formatDate"
                      @input="load()" input-class="form-control datepicker"
                  /><i class="fa fa-calendar form-control-feedback"></i></div>
              </div>
            </div>
            <vue-good-table
              ref="table" mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-cell-click="onCellClick"
              :columns="columns"
              :rows="tableData"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                enabled: true
                }"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              @on-row-click="onRowClick"
              :isLoading.sync="isTableLoading"
              styleClass="vgt-table striped bordered">
              <template slot="table-row" slot-scope="props">
                <span v-if="selectedRow && selectedRow.paymentComposite === props.row.paymentComposite">
                  <span v-if="props.column.field === 'amount'">
                    <currency-input
                              v-model="props.row.amount"
                              @input="amountChanged(props)"
                              currency="GBP"
                              locale="en"
                            />
                  </span>
                  <span v-else-if="props.column.field === 'processingDate'">
                    <datepicker v-model="props.row.processingDate" @selected="processingDateChanged" :disabledDates="disabledDates" />
                  </span>
                  <span v-else-if="props.column.field === 'viewPayerBtn'">
                    <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                        <span>View Payer</span>
                      </router-link>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </span>
                <span v-else>
                  <span v-if="props.column.field === 'viewPayerBtn'">
                    <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                        <span>View Payer</span>
                      </router-link>
                  </span>
                  <span v-else-if="props.column.field === 'amount'">
                      <i v-if="props.row.pendingVariableAmount || props.row.amount == 0" v-b-popover.hover.top.d500="'Variable payment amount required, 0 amounts are invalid under BACS rules.'" class="fa fa-exclamation-circle text-warning mr-2"></i>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </span>
              </template>
              <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">
                    No Flagged Payments Found
                  </div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="printTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Print out the contents of the table'">
                    <i class="fa fa-print pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                    <i class="fa fa-share-square pointer dimmedIcon"></i>
                  </b-button>
                </div>
              </vue-good-table>
            </div>
            <div class="idb-block-footer mt-3">
                <b-btn type="link" variant="primary" :disabled="isLoading || !hasChanges" @click.prevent="saveSubmissionChanges">
                  <i class="fa fa-save mr-2" aria-hidden="true"></i>Save Changes
                </b-btn>
                <b-btn type="link" class="float-right" variant="danger"  :disabled="isLoading || !hasChanges" @click.prevent="cancelSubmissionChanges">
                  <i class="fa fa-times mr-2"></i>Cancel Changes
                </b-btn>
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import swal from 'sweetalert2'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import papa from 'papaparse'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
export default {
  mixins: [tableFilterMixin, loading],
  props: {
    groupId: String
  },
  components: {
    Datepicker
  },
  data () {
    return {
      columns: [
        {
          label: 'First Name',
          field: 'firstName',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Surname',
          field: 'surname',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Reference',
          field: 'payerReference',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Transaction Code',
          field: 'txCode',
          width: '180px'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right amountField'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (val) => new Date(val).toLocaleDateString(),
          width: '180px'
        },
        {
          label: 'Plan Reference',
          field: 'planReference'
        },
        {
          label: '',
          field: 'viewPayerBtn',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      },
      dates: { proc: new Date(), coll: new Date() },
      selectedRow: null,
      originalState: [],
      tableData: [],
      hasChanges: false,
      startDate: new Date(Date.now() - 24 * 3600 * 1000),
      endDate: new Date(),
      group: '00000000-0000-0000-0000-000000000000',
      totalRecords: 0
    }
  },
  methods: {
    back () {
      this.$router.push('/collections/submissions/')
    },
    getSubmissions () {
      this.$store.dispatch('loadSubmissions')
    },
    load: async function () {
      try {
        if (this.group == null) {
          this.group = '00000000-0000-0000-0000-000000000000'
        }
        var response = await axios.get(this.group !== '00000000-0000-0000-0000-000000000000' ? `${process.env.VUE_APP_DDMS_API_URL}reporting/orphans/${this.group}` : `${process.env.VUE_APP_DDMS_API_URL}reporting/orphans`, {
          params: {
            minDate: moment(this.endDate).format('DD-MM-YYYY'),
            maxDate: moment(this.startDate).format('DD-MM-YYYY'),
            ...this.buildGoodTableQuery()
          }
        })
        this.originalState = response.data.item1
        this.tableData = response.data.item1
        this.totalRecords = response.data.item2
      } catch (error) {
        this.$toastr.e(error)
      }
    },
    printTable () {
      window.print()
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      var minDate = moment(this.endDate).format('DD-MM-YYYY')
      var maxDate = moment(this.startDate).format('DD-MM-YYYY')
      query.perPage = this.totalRecords
      var response = await axios.get(this.group !== '00000000-0000-0000-0000-000000000000' ? `${process.env.VUE_APP_DDMS_API_URL}reporting/orphans/${this.group}` : `${process.env.VUE_APP_DDMS_API_URL}reporting/orphans`, {
        params: {
        minDate,
        maxDate,        
        ...query }
      , showload: true })
      var csvString = papa.unparse(response.data.item1)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {        
        window.navigator.msSaveBlob(blob, `orphaned-submissions-report-${minDate}-to-${maxDate}.csv`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = `orphaned-submissions-report-${minDate}-to-${maxDate}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    processingDateChanged (date) {
      var dateOffset = (24 * 60 * 60 * 1000) * 1
      let procDate = new Date(date.getTime())
      procDate = new Date(procDate.setTime(procDate.getTime() - dateOffset))
      // CORRECT DATE.
      let procDateValid = false
      while (!procDateValid) {
        if (procDate.getDay() === 6 || procDate.getDay() === 0 || this.$store.getters.nonProcessingDates.includes(procDate)) {
          procDate = new Date(procDate.setTime(procDate.getTime() - dateOffset))
        } else {
          procDateValid = true
        }
      }
      this.selectedRow.processingDate = procDate.toISOString()
      for (var i = 0; i < this.tableData.length; i++) {
        if ((this.tableData[i].paymentComposite !== '' && this.tableData[i].paymentComposite === this.selectedRow.paymentComposite) || (this.tableData[i].paymentComposite === '' && this.tableData[i].ukddmsPendingPaymentId === this.selectedRow.ukddmsPendingPaymentId)) {
          this.tableData[i].dateChanged = true
          this.hasChanges = true
        }
      }
    },
    amountChanged (props) {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].paymentComposite === this.selectedRow.paymentComposite) {
          this.tableData[i].amount = props.row.amount
          this.tableData[i].amountChanged = true
          this.hasChanges = true
        }
      }
    },
    async saveSubmissionChanges () {
      var invalid = false
      for (var i = 0; i < this.tableData.length; i++) {
        const payment = this.tableData[i]
        if (payment.amountChanged && !payment.dateChanged) {
          invalid = true
        }
      }
      var result
      if (!invalid) {
        result = await swal.fire({
          icon: 'warning',
          title: 'Save submission',
          text: 'Collection amounts and dates have been changed. Saving the payments will remove them from the current submission.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel'
        })

        if (result.isConfirmed) {
          var response = await this.$store.dispatch('updateSubmission', this.tableData)
          if (response) {
            this.$toastr.s('Submission updated')
          }
          this.$store.dispatch('updateCurrentCollectionsSubmissionCount')
          await this.load()
          this.hasChanges = false
          this.selectedRow = null
          this.$set(this.columns[6], 'hidden', true)
        }
      } else {
        result = await swal.fire({
          icon: 'warning',
          title: 'Validation',
          text: 'Collection amounts have been changed without updating the processing date. Please update the processing date.\'',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel'
        })
        console.log(result)
      }
    },
    cancelSubmissionChanges () {
      this.$store.dispatch('resetData')
      this.tableData = _.cloneDeep(this.originalState)
      this.hasChanges = false
      this.selectedRow = null
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async customerGroupsChanged () {
      await this.load()
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      console.log(params)
      this.selectedRow = this.tableData[params.rowIndex]
    }
  },
  computed: {
    selectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    disabledDates: function () {
      let maxDate = new Date()
      let minDate = new Date(this.dates.proc)
      if (this.selectedRow) {
        const start = new Date(this.selectedRow.processingDate)
        console.log(start.getDate())
        // minDate = new Date(start.getTime())
        minDate = new Date()
        minDate.setDate(minDate.getDate() + this.advancedNoticeWithMedia)
        // maxDate = new Date(start.getTime())
        // maxDate.setDate(maxDate.getDate() + 3)
        maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        console.log(minDate)
        console.log(maxDate)
        if (this.selectedRow.isNew) {
          minDate = new Date()
          maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        }
      }
      return {
        days: [0, 6],
        // daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    advancedNoticeWithMedia () {
      var notice = 0
      var groupConfig = this.$store.getters.collectionsGroupConfig
      if (groupConfig.advanceNoticeInDays) {
        notice = notice + groupConfig.advanceNoticeInDays
      }
      if (groupConfig.defaultPayerMessageType === 0) {
        notice = notice + 5
      }
      var weekendDays = 0
      for (var i = 0; i < notice; i++) {
        if (i % 5 === 0) {
          weekendDays = weekendDays + 2
        }
      }
      notice = notice + weekendDays
      return notice
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'All Payers', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    disabledRangeDates: function () {
      const maxDate = new Date(this.startDate)
      const minDate = new Date('0001-01-01T00:00:00Z')
      return {
        to: minDate,
        from: maxDate
      }
    },
    disabledRangeDatesEnd: function () {
      const maxDate = new Date(Date.now() - 24 * 3600 * 1000)
      const minDate = new Date(this.endDate)
      return {
        to: minDate,
        from: maxDate
      }
    }
  },
  validations: {
    group: { required }
  },
  async mounted () {
    await this.loadGroups(this.paygateId)
    await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
    var resp = await this.$store.dispatch('getScheduleProcessingDates')
    this.dates.proc = resp.proc
    this.dates.coll = resp.coll
    this.endDate = this.endDate.setMonth(this.endDate.getMonth() - 6)
    await this.load()
  }
}
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
