var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "pull-right mr-2" }, [
    _c("a", { attrs: { href: "#" }, on: { click: _vm.toggle } }, [
      _c("i", {
        staticClass: "fa-star star",
        class: _vm.active ? "fas" : "far"
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }