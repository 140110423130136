var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: { docPath: "/paygate-collections/plans/scheduledchanges/" }
          }),
          _vm._v("Scheduled Plan Changes"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c("div", [
      _c(
        "div",
        { staticClass: "m-3" },
        [
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2 required" }, [
              _vm._v(" Select a Group ")
            ]),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("group-select", {
                  attrs: { groups: _vm.customerGroups, clearable: false },
                  on: { input: _vm.customerGroupsChanged },
                  model: {
                    value: _vm.group,
                    callback: function($$v) {
                      _vm.group = $$v
                    },
                    expression: "group"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-row",
            { staticClass: "planRow" },
            [
              _c(
                "b-tabs",
                {
                  attrs: {
                    vertical: "",
                    "nav-wrapper-class": "col-3 vertical-tab-container",
                    "nav-class": "w-90 ml-2 vertical-tab-list"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function() {
                        return [
                          _vm._v(
                            " There are no plan templates associated with this group. "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.activeTabIndex,
                    callback: function($$v) {
                      _vm.activeTabIndex = $$v
                    },
                    expression: "activeTabIndex"
                  }
                },
                _vm._l(_vm.paymentPlans, function(paymentPlan, index) {
                  return _c(
                    "b-tab",
                    {
                      key: index,
                      attrs: { title: paymentPlan.name },
                      on: {
                        click: function($event) {
                          return _vm.planSelected(paymentPlan)
                        }
                      }
                    },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols": 7,
                                horizontal: "",
                                label: "Current Regular Amount"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control w-100",
                                attrs: { type: "text", disabled: true },
                                model: {
                                  value: paymentPlan.plan.regularAmount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      paymentPlan.plan,
                                      "regularAmount",
                                      $$v
                                    )
                                  },
                                  expression: "paymentPlan.plan.regularAmount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vue-good-table",
                        {
                          ref: "futurePlanTable",
                          refInFor: true,
                          attrs: {
                            columns: _vm.columns,
                            rows: _vm.currentFpc,
                            lineNumbers: true,
                            totalRows: _vm.totalRecords,
                            rowStyleClass: _vm.rowStyler,
                            "search-options": {
                              enabled: false
                            },
                            "pagination-options": {
                              enabled: false
                            },
                            styleClass: "vgt-table striped bordered"
                          },
                          on: {
                            "on-row-click": _vm.onRowClick,
                            "on-cell-click": _vm.onCellClick
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "table-row",
                                fn: function(props) {
                                  return [
                                    props.row.subs && props.row.subs.length > 0
                                      ? _c(
                                          "span",
                                          _vm._l(props.row.subs, function(
                                            sub,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                class:
                                                  index > 0 ? "subRow" : "",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.switchToSubEdit(
                                                      props.index,
                                                      index,
                                                      props.column.label
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                props.column.label == "Trigger"
                                                  ? _c(
                                                      "span",
                                                      [
                                                        !_vm.isEditState(
                                                          props.index,
                                                          index
                                                        ) && index === 0
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  sub.triggerType ===
                                                                    0
                                                                    ? _vm.formatFpcDate(
                                                                        sub.startDate
                                                                      )
                                                                    : sub.paymentsMade
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        !_vm.isEditState(
                                                          props.index,
                                                          index
                                                        ) && index !== 0
                                                          ? _c("span")
                                                          : _vm._e(),
                                                        sub.triggerType === 0 &&
                                                        _vm.isEditState(
                                                          props.index,
                                                          index
                                                        )
                                                          ? _c("datepicker", {
                                                              attrs: {
                                                                disabledDates:
                                                                  _vm.disabledDates
                                                              },
                                                              model: {
                                                                value:
                                                                  sub.startDate,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    sub,
                                                                    "startDate",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "sub.startDate"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        sub.triggerType === 1 &&
                                                        _vm.isEditState(
                                                          props.index,
                                                          index
                                                        )
                                                          ? _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    sub.paymentsMade,
                                                                  expression:
                                                                    "sub.paymentsMade"
                                                                }
                                                              ],
                                                              attrs: {
                                                                type: "number"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  sub.paymentsMade
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    sub,
                                                                    "paymentsMade",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                props.column.field ==
                                                "changeAmount"
                                                  ? _c("span", [
                                                      !_vm.isEditState(
                                                        props.index,
                                                        index
                                                      )
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.row.subs
                                                                  .length > 1
                                                                  ? index +
                                                                      1 +
                                                                      ": "
                                                                  : ""
                                                              ) +
                                                                _vm._s(
                                                                  _vm.formatValue(
                                                                    sub
                                                                  )
                                                                )
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm.isEditState(
                                                        props.index,
                                                        index
                                                      )
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  sub.changeAmount,
                                                                expression:
                                                                  "sub.changeAmount"
                                                              }
                                                            ],
                                                            staticStyle: {
                                                              width: "60px"
                                                            },
                                                            attrs: {
                                                              type: "number"
                                                            },
                                                            domProps: {
                                                              value:
                                                                sub.changeAmount
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  sub,
                                                                  "changeAmount",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ])
                                                  : props.column.field ==
                                                    "changeType"
                                                  ? _c(
                                                      "span",
                                                      [
                                                        !_vm.isEditState(
                                                          props.index,
                                                          index
                                                        )
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatChangeType(
                                                                    sub
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm.isEditState(
                                                          props.index,
                                                          index
                                                        )
                                                          ? _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.changeTypes,
                                                                  "text-field":
                                                                    "name",
                                                                  "value-field":
                                                                    "value"
                                                                },
                                                                model: {
                                                                  value:
                                                                    sub.changeType,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      sub,
                                                                      "changeType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "sub.changeType"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : props.column.field ==
                                                    "triggerType"
                                                  ? _c(
                                                      "span",
                                                      [
                                                        !_vm.isEditState(
                                                          props.index,
                                                          index
                                                        )
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatTriggerType(
                                                                    sub.triggerType
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm.isEditState(
                                                          props.index,
                                                          index
                                                        )
                                                          ? _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.triggerTypes,
                                                                  "text-field":
                                                                    "name",
                                                                  "value-field":
                                                                    "value"
                                                                },
                                                                model: {
                                                                  value:
                                                                    sub.triggerType,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      sub,
                                                                      "triggerType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "sub.triggerType"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : props.column.label == ""
                                                  ? _c("span", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              sub.orderIndex,
                                                            expression:
                                                              "sub.orderIndex"
                                                          }
                                                        ],
                                                        attrs: {
                                                          type: "hidden"
                                                        },
                                                        domProps: {
                                                          value: sub.orderIndex
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              sub,
                                                              "orderIndex",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      props.row.subs.length >
                                                        1 &&
                                                      !_vm.isEditState(
                                                        props.index,
                                                        index
                                                      ) &&
                                                      !sub.applied
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: !_vm.canMove(
                                                                props.row.subs,
                                                                index,
                                                                -1
                                                              )
                                                                ? "disabled-order"
                                                                : null,
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.moveUp(
                                                                    props.row
                                                                      .subs,
                                                                    index,
                                                                    props.index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-arrow-up mr-2",
                                                                attrs: {
                                                                  title:
                                                                    "Move Up"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.row.subs.length >
                                                        1 &&
                                                      !_vm.isEditState(
                                                        props.index,
                                                        index
                                                      ) &&
                                                      !sub.applied
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: !_vm.canMove(
                                                                props.row.subs,
                                                                index,
                                                                1
                                                              )
                                                                ? "disabled-order"
                                                                : null,
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.moveDown(
                                                                    props.row
                                                                      .subs,
                                                                    index,
                                                                    props.index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-arrow-down mr-2",
                                                                attrs: {
                                                                  title:
                                                                    "Move Down"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      sub.applied && index === 0
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-lock ml-2",
                                                            attrs: {
                                                              title: "Locked"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm.isEditState(
                                                        props.index,
                                                        index
                                                      )
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    title:
                                                                      "Confirm Changes"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.saveFpc(
                                                                        sub
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-check mr-1"
                                                                  }),
                                                                  _vm._v(
                                                                    " Confirm "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading,
                                                                    variant:
                                                                      "outline-warning",
                                                                    title:
                                                                      "Cancel Changes"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.cancelFpc(
                                                                        sub
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-times mr-1"
                                                                  }),
                                                                  _vm._v(
                                                                    " Cancel "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-danger",
                                                                    title:
                                                                      "Delete",
                                                                    disabled:
                                                                      props.row
                                                                        .applied ||
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteFpc(
                                                                        sub
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-trash mr-1"
                                                                  }),
                                                                  _vm._v(
                                                                    " Delete "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ])
                                                  : _c("span")
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("template", { slot: "loadingContent" }, [
                            _c("h1", [_vm._v("Loading...")])
                          ]),
                          _c(
                            "div",
                            {
                              attrs: { slot: "emptystate" },
                              slot: "emptystate"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vgt-center-align vgt-text-disabled"
                                },
                                [_vm._v("No Plan Templates Available")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Add payment",
                                      expression: "'Add payment'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    title: "Add Payment",
                                    disabled: _vm.isLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addPayment($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-plus pointer dimmedIcon"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm.selectedPlan != null
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                variant: "primary",
                                disabled: _vm.isLoading
                              },
                              on: { click: _vm.saveFutureChanges }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save mr-2",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v("Save ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "idb-block-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }