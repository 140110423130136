var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: { docPath: "/paygate-collections/portal/" }
            }),
            _vm._v("Portal Messages"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "m-3" },
          [
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-sm-4",
                    attrs: { "label-cols": 2, horizontal: "", label: "Group" }
                  },
                  [
                    _c("group-select", {
                      attrs: { id: "groupSelect", groups: _vm.customerGroups },
                      on: { input: _vm.customerGroupsChanged },
                      model: {
                        value: _vm.groupId,
                        callback: function($$v) {
                          _vm.groupId = $$v
                        },
                        expression: "groupId"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-footer" })
    ]),
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c("h2", [
          _c(
            "span",
            { staticClass: "pull-right" },
            [
              _c(
                "p-check",
                {
                  staticClass: "p-switch p-fill",
                  attrs: { id: "giftAidCheck", color: "primary" },
                  on: {
                    change: function($event) {
                      _vm.unreadOnly != _vm.unreadOnly
                    }
                  },
                  model: {
                    value: _vm.unreadOnly,
                    callback: function($$v) {
                      _vm.unreadOnly = $$v
                    },
                    expression: "unreadOnly"
                  }
                },
                [_vm._v("Show only unread")]
              )
            ],
            1
          ),
          _vm._v("Messages")
        ])
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c("inbox", {
              attrs: { messages: _vm.messages, unreadOnly: _vm.unreadOnly },
              on: { input: _vm.getPayerMessages }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-footer" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }