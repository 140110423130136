var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "nonProcessingDays" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.nonProcessingDaysLoaded,
            expression: "nonProcessingDaysLoaded"
          }
        ]
      },
      [
        _c("div", { staticClass: "row form-group" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "vue-good-table",
                {
                  attrs: {
                    columns: _vm.columns,
                    rows: _vm.nonProcessingDays,
                    "pagination-options": {
                      enabled: true,
                      perPage: 10,
                      dropdownAllowAll: false
                    },
                    styleClass: "vgt-table striped bordered"
                  },
                  on: { "on-row-click": _vm.onRowClick },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field === "northernIrelandOnly"
                            ? _c("span", [
                                props.row.northernIrelandOnly === true
                                  ? _c(
                                      "span",
                                      [
                                        _c("p-check", {
                                          staticClass: "p-switch p-fill",
                                          attrs: {
                                            color: "primary",
                                            checked: "",
                                            disabled: true
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c("p-check", {
                                          staticClass: "p-switch p-fill",
                                          attrs: {
                                            color: "primary",
                                            disabled: true
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(props.formattedRow[props.column.field])
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Create a new non-processing day",
                              expression: "'Create a new non-processing day'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: {
                            variant: "link",
                            title: "Create Non-Processing Day"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.createNonProcessingDay($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus pointer dimmedIcon"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("editNonProcessingDay", {
          attrs: {
            nonProcessingDay: _vm.currentNonProcessingDay,
            newNonProcessingDay: _vm.newNonProcessingDay
          },
          on: {
            close: _vm.editCancelled,
            savedNonProcessingDay: _vm.savedNonProcessingDay
          },
          model: {
            value: _vm.isModalVisible,
            callback: function($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }