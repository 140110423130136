var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("span", { staticClass: "pull-right" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.docUrl +
                          "/automation/configuration/duplicationsettings/",
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  )
                ]),
                _vm._v("Workflow: Duplication Settings"),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("form", [
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-1" }),
                  _c("div", { staticClass: "col-md-4" }, [
                    _vm._v(" Enable File Duplication Checking ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("p-check", {
                        staticClass: "p-switch",
                        attrs: { name: "check", color: "primary " },
                        model: {
                          value: _vm.config.dupeCheckingEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.config, "dupeCheckingEnabled", $$v)
                          },
                          expression: "config.dupeCheckingEnabled"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("transition", { attrs: { name: "fadein" } }, [
                        !_vm.config.dupeCheckingEnabled
                          ? _c("div", [
                              _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle mr-2 text-warning"
                              }),
                              _vm._v(
                                "Warning, file duplication checking is currently disabled. "
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ]),
                _c("br"),
                _c("br"),
                _c("transition", { attrs: { name: "fadein" } }, [
                  _vm.config.dupeCheckingEnabled
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-1" }),
                          _c("div", { staticClass: "col-md-3" }, [
                            _vm._v(" Duplication criteria ")
                          ])
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-2" }),
                          _c("div", { staticClass: "col-md-4" }, [
                            _vm._v(" Duplicate Filename ")
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch",
                                attrs: { name: "check", color: "primary " },
                                on: { change: _vm.dupeFilenameClick },
                                model: {
                                  value: _vm.config.dupeFilenameEnabled,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.config,
                                      "dupeFilenameEnabled",
                                      $$v
                                    )
                                  },
                                  expression: "config.dupeFilenameEnabled"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-2" }),
                          _c("div", { staticClass: "col-md-4" }, [
                            _vm._v(" Duplicate File content ")
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch",
                                attrs: { name: "check", color: "primary " },
                                on: { change: _vm.dupeFileHashClick },
                                model: {
                                  value: _vm.config.dupeFileHashEnabled,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.config,
                                      "dupeFileHashEnabled",
                                      $$v
                                    )
                                  },
                                  expression: "config.dupeFileHashEnabled"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-2" }),
                          _c("div", { staticClass: "col-md-4" }, [
                            _vm._v(" Ignore older duplicate files ")
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-2" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch",
                                attrs: { name: "check", color: "primary " },
                                model: {
                                  value: _vm.config.dupeBlockAgeEnabled,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.config,
                                      "dupeBlockAgeEnabled",
                                      $$v
                                    )
                                  },
                                  expression: "config.dupeBlockAgeEnabled"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("br"),
                        _vm.config.dupeBlockAgeEnabled
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-3" }),
                              _c("div", { staticClass: "col-md-3" }, [
                                _vm._v(" Age (in Days) ")
                              ]),
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.config.dupeBlockAge,
                                      expression: "config.dupeBlockAge"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    autocomplete: "off",
                                    type: "number",
                                    min: "1",
                                    max: "1000",
                                    step: "1"
                                  },
                                  domProps: { value: _vm.config.dupeBlockAge },
                                  on: {
                                    change: _vm.tbChanged,
                                    paste: function($event) {
                                      $event.preventDefault()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.config,
                                        "dupeBlockAge",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ],
              1
            ),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-sm-3" },
                  [
                    !_vm.commsError
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value:
                                  "Save the current duplication configuration",
                                expression:
                                  "'Save the current duplication configuration'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.btnSave($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save mr-2" }),
                            _vm._v("Save")
                          ]
                        )
                      : _vm._e(),
                    !_vm.commsError
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value:
                                  "Permanently delete all duplication entries.",
                                expression:
                                  "'Permanently delete all duplication entries.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "pull-right",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "danger"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteDupes($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-undo mr-2" }),
                            _vm._v("Reset Duplication Tracking")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }