<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/alerts/" />Alerts
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div>
        <vue-good-table
          :isLoading.sync="isTableLoading"
          mode="remote"
          ref="table"
          :columns="columns"
          :rows="rows"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-row-click="onRowChange"
          :totalRows="totalRecords"
          :search-options="{
            enabled: true
          }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          styleClass="vgt-table striped bordered"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'alertLevel'" class="alertContainer">
              <b-badge
                pill
                :variant="getSeverityClass(props.row.alertLevel)"
              >{{props.formattedRow[props.column.field]}}</b-badge>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the Alert Table'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import papa from 'papaparse'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      rows: [],
      columns: [
        { label: 'Alert Level', field: 'alertLevel', tdClass: 'text-center', width: '120px' },
        { label: 'Title', field: 'title' },
        { label: 'Message Type', field: 'payerHistoryMessageType' },
        {
          label: 'Date',
          field: 'generationDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        { label: 'Group', field: 'groupName' },
        { field: 'actions', hidden: true }
      ],
      serverParams: {
        sort: [{ field: 'generationDate', type: 'asc' }]
      }
    }
  },
  methods: {
    async load () {
      // get
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}alerts/`, { params: this.buildGoodTableQuery(), paygateid: this.$store.state.common.paygateId, showload: true }).then((response) => {
        this.rows = response.data.item1
        this.totalRecords = response.data.item2
      })
    },
    onRowChange (e) {
      console.log(e)
      this.$router.push({ path: `/collections/payer/${e.row.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'danger'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (date) {
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${day}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    async exportTable () {
      const query = {}
      query.sort = this.serverParams.sort.field + ':' + this.serverParams.sort.type
      query.perPage = this.totalRecords
      query.page = 1
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}alerts/`, { params: query, paygateid: this.$store.state.common.paygateId, showload: true })
      var rows = response.data.item1
      var csvString = papa.unparse(rows)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'collections-alerts.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'collections-alerts.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }
  }
}
</script>
<style scoped>
.alertContainer {
  margin-left: auto;
  margin-right: auto;
}
.alertContainer .badge {
  font-weight: normal;
  text-transform: capitalize;
}
</style>
