<template>
  <div id="paymentNetwork">
    <div class="row form-group">
      <div class="col-md-2">Network</div>
      <div class="col-md-2">
        <b-select
          v-model.trim="selectedNetworkType"
          :options="networkTypes"
          @change="getEngineConfig"
        />
      </div>
    </div>
    <div v-if="paymentNetworkLoaded">
      <div class="row form-group">
        <div class="col-md-2 required">Live URL</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.baseLiveUrl.$error}">
          <input type="text" class="form-control" v-model.trim="$v.engineConfig.baseLiveUrl.$model" />
          <validation-messages v-model="$v.engineConfig.baseLiveUrl" name="Live URL" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2">Live Sub URL</div>
        <div class="col-md-2">
          <input type="text" class="form-control" v-model.trim="engineConfig.subLiveUrl" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 required">Test URL</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.baseTestUrl.$error}">
          <input type="text" class="form-control" v-model.trim="$v.engineConfig.baseTestUrl.$model" />
          <validation-messages v-model="$v.engineConfig.baseTestUrl" name="Test URL" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2">Test Sub URL</div>
        <div class="col-md-2">
          <input type="text" class="form-control" v-model.trim="engineConfig.subTestUrl" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 required">Live Application ID</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.liveApplicationId.$error}">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.engineConfig.liveApplicationId.$model"
          />
          <validation-messages
            v-model="$v.engineConfig.liveApplicationId"
            name="Live application ID"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 required">Test Application ID</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.testApplicationId.$error}">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.engineConfig.testApplicationId.$model"
          />
          <validation-messages
            v-model="$v.engineConfig.testApplicationId"
            name="Test application ID"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2">Use Live URL</div>
        <div class="col-md-4">
          <p-check class="p-switch p-fill" color="primary" v-model="engineConfig.useLiveUrl"></p-check>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 required">Single Payment Limit (£)</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.individualPaymentLimit.$error}">
          <money
            class="form-control"
            v-model="$v.engineConfig.individualPaymentLimit.$model"
            v-bind="money"
          />
        </div>
        <span v-if="$v.engineConfig.individualPaymentLimit.$dirty">
          <small
            v-if="!$v.engineConfig.individualPaymentLimit.required"
            class="form-text text-danger small text-nowrap"
          >Single Payment Limit is required</small>
          <small
            v-if="!$v.engineConfig.individualPaymentLimit.min"
            class="form-text text-danger small text-nowrap"
          >Single Payment Limit must be greater than 0</small>
        </span>
      </div>
      <div v-if="selectedNetworkType === 'BacstelIp'" class="row form-group">
        <div class="col-md-2 required">Government Single Payment Limit (£)</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.govIndividualPaymentLimit.$error}">
          <money
            class="form-control"
            v-model="$v.engineConfig.govIndividualPaymentLimit.$model"
            v-bind="money"
          />
        </div>
        <span v-if="$v.engineConfig.govIndividualPaymentLimit.$dirty && selectedNetworkType === 'BacstelIp'">
          <small
            v-if="!$v.engineConfig.govIndividualPaymentLimit.minValue && selectedNetworkType === 'BacstelIp'"
            class="form-text text-danger small text-nowrap"
          >Government Single Payment Limit must be greater than 0</small>
        </span>
      </div>
      <div class="row form-group">
        <div class="col-md-2 required">Max Items/Submission</div>
        <div class="col-md-4" :class="{invalid: $v.engineConfig.maxRecordsPerSubmission.$error}">
          <money
            class="form-control"
            v-model="$v.engineConfig.maxRecordsPerSubmission.$model"
            v-bind="money"
          />
        </div>
        <span v-if="$v.engineConfig.maxRecordsPerSubmission.$dirty">
          <small
            v-if="!$v.engineConfig.maxRecordsPerSubmission.required"
            class="form-text text-danger small text-nowrap"
          >Max Items/Submission is required</small>
          <small
            v-if="!$v.engineConfig.maxRecordsPerSubmission.min"
            class="form-text text-danger small text-nowrap"
          >Max Items/Submission must be greater than 0</small>
        </span>
      </div>
      <br />
      <div class="row form-group">
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="savePaymentNetworkSettings"
          >Save Payment Network Settings</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, numeric, url, minValue } from 'vuelidate/lib/validators'
import { Money } from 'v-money'

export default {
  components: { Money },
  data () {
    return {
      networkTypes: ['BacstelIp', 'SecureIp'],
      selectedNetworkType: '',
      engineConfig: {},
      paymentNetworkLoaded: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      }
    }
  },

  methods: {
    async getEngineConfig () {
      this.$v.$reset()
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getBacsEngineConfig?networkType=` + this.selectedNetworkType)
      this.engineConfig = response.data
      this.$store.dispatch('setPaymentNetworkData', this.engineConfig)
      this.paymentNetworkLoaded = true
    },

    async savePaymentNetworkSettings () {
      this.$v.$touch()
      console.log('this.$v.$invalid: ' + this.$v.$invalid)
      if (this.$v.$invalid) {
        return false
      }

      console.log('settings: ' + JSON.stringify(this.engineConfig))
      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/admin/saveBacsEngineConfig',
        data: this.engineConfig
      })

      if (response.data.status === 'Updated') {
        this.$toastr.s(response.data.toastMessage)
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    }
  },

  validations () {
    return {
      engineConfig: {
        baseLiveUrl: { required, url },
        baseTestUrl: { required, url },
        liveApplicationId: { required },
        testApplicationId: { required },
        individualPaymentLimit: { required, numeric, min: minValue(1) },
        maxRecordsPerSubmission: { required, numeric, min: minValue(1) },
        govIndividualPaymentLimit: {
          minValue: function () {
            if (this.selectedNetworkType === 'SecureIp') {
              return true
            } else {
              return this.selectedNetworkType === 'BacstelIp' && this.engineConfig.govIndividualPaymentLimit > 0
            }
          }
        }
      }
    }
  },

  async mounted () {
    this.paymentNetworkLoaded = false
    this.engineConfig = this.$store.getters.paymentNetworkData
    if (this.engineConfig !== null && Object.keys(this.engineConfig).length > 0 && this.engineConfig.constructor === Object) {
      this.selectedNetworkType = this.networkTypes[this.engineConfig.paymentNetworkType]
      this.paymentNetworkLoaded = true
    }
  }
}
</script>
