<template>
  <div id="viewSubmission">
    <div v-show="action.id !== undefined">
      <div v-show="action.id !== '00000000-0000-0000-0000-000000000000'" id="actionDetails">
        <div class="idb-block-title">
          <h2>
            {{ this.action.title }}
            <span class="pull-right">
              <a :href="helpUrl" target="_blank">
                <i class="far fa-question-circle"></i>
              </a>
            </span>
          </h2>
        </div>
      </div>
      <div class="idb-block-content">
        <b-col sm="8">
          <div v-if="action.id !== '00000000-0000-0000-0000-000000000000'">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Message</strong>
              </div>
              <div class="col-md-10">{{ this.action.message }}</div>
            </div>
          </div>
          <div v-if="validationWarnings" class="row form-group">
            <div class="col-md-2">
              <strong>Validation Messages</strong>
            </div>
            <div class="col-md-2">
              <span
                class="badge badge-warning badge-pill"
              >Contains {{ this.numberOfWarnings }} {{ this.validationText }}</span>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2">
              <strong>Submission Reference</strong>
            </div>
            <div class="col-md-10">{{ this.bacsSubmission.reference }}</div>
          </div>
          <div class="row form-group">
            <div class="col-md-2">
              <strong>Group Name</strong>
            </div>
            <div class="col-md-10">{{ this.groupName }}</div>
          </div>
          <div class="row form-group">
            <div class="col-md-2">
              <strong>Submission File</strong>
            </div>
            <div class="col-md-10">{{ this.importedFilename }}</div>
          </div>
          <div v-if="this.isMultiFile === true" class="row form-group">
            <div class="col-md-2">
              <b>Select File</b>
            </div>
            <div class="col-md-2">
              <b-dropdown :text="selectedFile" split variant="outline-secondary">
                <b-dropdown-item
                  v-for="summary in intelligibleSummaries"
                  :key="summary.fileNumber"
                  :value="summary.fileNumber"
                  @click="selectSummary(summary)"
                >{{ summary.vocalinkSubId }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-col>

        <b-tabs v-if="isLoaded" v-on:input="tabSelected" lazy v-model="tabIndex">
          <b-tab v-if="bacsSubmission.paygateType === 'Bureau'" title="Bureau Summary">
            <bureauSummary></bureauSummary>
          </b-tab>

          <b-tab title="Summary" active>
            <intelligibleSummary></intelligibleSummary>
          </b-tab>
          <b-tab title="Payments">
            <payments></payments>
          </b-tab>
          <b-tab title="Standard18">
            <standard18></standard18>
          </b-tab>
          <b-tab title="Submission History">
            <submissionHistory></submissionHistory>
          </b-tab>
          <b-tab v-if="validationWarnings === true">
            <template slot="title">
              Validation Messages
              <span
                class="badge badge-warning badge-pill"
              >{{ this.numberOfWarnings }}</span>
            </template>
            <validationWarnings></validationWarnings>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import IntelligibleSummary from '@/Views/Bacs/Common/IntelligibleSummary.vue'
import Payments from '@/Views/Bacs/Common/Payments.vue'
import Standard18 from '@/Views/Bacs/Common/Standard18.vue'
import SubmissionHistory from '@/Views/Bacs/Common/SubmissionHistory.vue'
import ValidationWarnings from '@/Views/Bacs/Common/ValidationWarnings.vue'
import BureauSummary from '@/Views/Bacs/Common/BureauSummary.vue'
import bacsMixin from '@/Lib/BacsMixin.js'
import roles from '@/Assets/Constants/roles'

export default {
  components: {
    intelligibleSummary: IntelligibleSummary,
    payments: Payments,
    standard18: Standard18,
    submissionHistory: SubmissionHistory,
    validationWarnings: ValidationWarnings,
    bureauSummary: BureauSummary
  },
  mixins: [
    bacsMixin
  ],
  computed: {
    validationText: function () {
      if (this.numberOfWarnings === 1) {
        return 'validation message'
      } else {
        return 'validation messages'
      }
    }
  },
  data () {
    return {
      submissionData: {},
      submissionId: '',
      selectedFileNumber: 1,
      serviceUserNumber: '',
      isMultiFile: false,
      intelligibleSummaries: {},
      contraNarratives: {},
      tabIndex: 0,
      validationWarnings: false,
      numberOfWarnings: 0,
      bacsSubmission: {},
      isLoaded: false,
      viewSubmissionData: {},
      action: {},
      groupName: '',
      tokenType: '',
      messageCountPerFile: {},
      valMessageClasses: 'badge badge-warning badge-pill',
      helpUrl: '',
      importedFilename: '',
      selectedFile: '', // Only relevant for multi-block PGO Standard18 files.
      BureauSummaryData: { // data required for the Bureau Summaries tab
        totalDebitCount: 0,
        totalDebitValue: 0,
        totalCreditCount: 0,
        totalCreditValue: 0,
        totalDDICount: 0
      }
    }
  },

  methods: {
    async onDownloadFullPaymentReportClick () {
      try {
        var url = process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getfullpaymentreport?id=' + this.submissionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log('Error from get presub report', JSON.stringify(ex))
      }
    },

    async onPreSubmissionReportClick () {
      try {
        var url = process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getpresubmissionreport?id=' + this.submissionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log('Error from get presub report', JSON.stringify(ex))
      }
    },

    tabSelected (tabIndex) {
    },

    selectSummary (summary) {
      this.serviceUserNumber = summary.serviceUserNumber
      this.selectedFile = summary.vocalinkSubId
      this.$store.dispatch('setFileNumber', summary.fileNumber)
      this.viewSubmissionData = this.$store.getters.viewSubmissionData
      this.viewSubmissionData.paymentsLoaded = false
      this.viewSubmissionData.presubValMessagesLoaded = false
      this.numberOfWarnings = this.messageCountPerFile[summary.fileNumber - 1]

      this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
      if (this.tabIndex > 0) {
        this.tabIndex = 0
      } else {
        this.tabIndex = 1
        setTimeout(() => {
          this.changeTab()
        }, 100)
      }
    },

    changeTab () {
      this.tabIndex = 0
    },

    async getContraNarratives () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getContraNarratives?submissionId=` + this.submissionId, { showload: true })
      this.contraNarratives = response.data
      if (this.contraNarratives.length > 0) {
        this.$store.dispatch('setContraNarratives', this.contraNarratives)
      }
    },

    async getIntelligibleSummaries () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getIntelligibleSummaries?submissionId=` + this.submissionId, { showload: true })
      this.intelligibleSummaries = response.data
      if (this.intelligibleSummaries.length > 0) {
        this.$store.dispatch('setIntelligibleSummaries', this.intelligibleSummaries)
        this.isMultiFile = this.intelligibleSummaries.length > 1
        this.serviceUserNumber = this.intelligibleSummaries[0].serviceUserNumber
        this.selectedFile = this.intelligibleSummaries[0].vocalinkSubId
        this.viewSubmissionData.summariesLoaded = true
        this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
      }
    },

    async getBacsSubmission () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsSubmission?submissionId=` + this.submissionId)
      this.bacsSubmission = response.data
      this.$store.dispatch('setBacsSubmission', this.bacsSubmission)
      this.$store.dispatch('getSubBacsGroup', this.bacsSubmission.groupId)
    },

    async getPresubValidationResult () {
      var presubValResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPresubValidationResult?submissionId=` + this.submissionId)
      this.validationWarnings = presubValResponse.data.validationResult === 'Warnings' || presubValResponse.data.validationResult === 'Info'
      if (presubValResponse.data.validationResult === 'Info') {
        this.valMessageClasses = 'badge badge-info badge-pill'
      }
      this.messageCountPerFile = presubValResponse.data.presubValMessagesForFile
      this.numberOfWarnings = this.messageCountPerFile[0]
    },

    async getAction () {
      var actionId = this.$store.getters.actionId
      if (actionId !== undefined) {
        if (actionId !== '00000000-0000-0000-0000-000000000000') {
          var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + actionId
          var response = await axios.get(url, { action: true })
          this.action = response.data
        } else {
          this.action.id = '00000000-0000-0000-0000-000000000000'
        }
      }
    },

    async getBacsEngineConfig () {
      await this.$store.dispatch('getBacsEngineConfig', this.bacsSubmission.paymentNetworkType)
    },

    async getGroupDetails () {
      try {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'groups/' + this.bacsSubmission.groupId
        var response = await axios.get(url)
        const groupDetails = response.data
        this.groupName = groupDetails.name
        this.tokenType = groupDetails.groupTypeDetails.tokenType
      } catch (err) {
        console.log(err)
        if (this.groupName === '' || this.groupName === undefined) {
          this.groupName = this.$store.getters.selectedRowGroupName
        }
      }
    },

    async getBureauSummaries () {
      try {
        var BureauSummaryResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissionsummaryinfoafterpresubval/' + this.submissionId, { showload: true })
        console.log('BureauSummaryResponse', BureauSummaryResponse)
        this.BureauSummaryData.totalDebitCount = BureauSummaryResponse.data.debitCount
        this.BureauSummaryData.totalDebitValue = BureauSummaryResponse.data.debitValue
        this.BureauSummaryData.totalCreditCount = BureauSummaryResponse.data.creditCount
        this.BureauSummaryData.totalCreditValue = BureauSummaryResponse.data.creditValue
        this.BureauSummaryData.totalDDICount = BureauSummaryResponse.data.ddiCount
        this.BureauSummaryData.totalFiles = BureauSummaryResponse.data.totalFiles
        this.BureauSummaryData.contraCount = BureauSummaryResponse.data.contraCount
        this.$store.dispatch('setBureauSummaryData', this.BureauSummaryData)
      } catch (err) {
        console.log(err)
      }
    },

    async getCustomer () {
      if (!this.$store.getters.isInRole(roles.SystemUser)) {
        var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'customers')
        if (!response.data.data[0].allowLiveSubmissions) {
          this.$snapbar.e('Your customer is set in \'Test Mode\' so is not allowed to send live submissions at this time.')
        }
      }
    },

    async getFileDetails () {
      if (this.submissionId !== undefined) {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/GetFileDetails?submissionId=${this.submissionId}&showImportedFilename=true`)
        var fileDetails = response.data
        if (fileDetails !== null && fileDetails.length > 0) {
          this.importedFilename = fileDetails[0].filename
        }
      }
    }
  },

  async created () {
    this.$store.dispatch('setParentDataLoaded', false)
    this.submissionId = this.$store.getters.submissionId
    this.$store.dispatch('setSubmissionId', this.submissionId)
    this.$store.dispatch('setFileNumber', this.selectedFileNumber)

    this.viewSubmissionData = {
      summariesLoaded: false,
      paymentsLoaded: false,
      standard18Loaded: false,
      moreStnd18: false,
      fileNumber: 0,
      fromRecordNumber: 0,
      toRecordNumber: 0,
      presubValMessagesLoaded: false,
      historyLoaded: false
    }

    this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
    this.$store.dispatch('setStandard18', '')

    await this.getCustomer()
    await this.getContraNarratives()
    await this.getIntelligibleSummaries()
    await this.getBacsSubmission()
    await this.getFileDetails()
    await this.getPresubValidationResult()
    await this.getBacsEngineConfig()
    await this.getAction()
    await this.getGroupDetails()
    if (this.isMultiFile) {
      await this.getBureauSummaries()
    }
    this.isLoaded = true
    this.$store.dispatch('setParentDataLoaded', true)

    if (this.action.actionSubClass === 'Sign' || this.action.actionSubClass === 'Commit') {
      if (this.tokenType === 'Hsm') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/signsubmissionwithhsm/'
      } else if (this.tokenType === 'SmartCard') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/signsubmissionwithsmartcard/'
      } else if (this.tokenType === 'SecurityCode') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/commitsms/'
      } else if (this.tokenType === 'UsbToken') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/commitusbtoken/'
      } else if (this.tokenType === 'None') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/commitwithoutauthentication/'
      }
    } else if (this.action.actionSubClass === 'Approve') {
      this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/approvesubmission/'
    } else if (this.action.actionSubClass === 'Send') {
      this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/sendsubmission/'
    }
  }
}
</script>
