// Authentication Routes
const Auth = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Authentication/AuthCallback')
const AuthSilent = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Authentication/AuthCallbackSilent')
const Unauthorized = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Authorization/Unauthorized')
const NotLicenced = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Authorization/NotLicenced')

export default {
  callbacks: [
    {
      path: '/callback',
      component: Auth,
      meta: {
        anon: true
      }
    },
    {
      path: '/silentrenew',
      component: AuthSilent,
      meta: {
        anon: true
      }
    }
  ],
  authorization: [
    {
      name: 'Unauthorized',
      path: 'unauthorized',
      component: Unauthorized,
      meta: {
        anon: true
      }
    },
    {
      name: 'NotLicenced',
      path: 'NotLicenced',
      component: NotLicenced,
      mate: {
        anon: true
      },
      props: route => ({ ...route.params, ...route.query })
    }
  ]
}
