var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showModalFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showModalFileBrowser = false
                _vm.closePopup()
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _vm.isNew
                ? _c("h2", [
                    _c("span", { staticClass: "pull-right" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.docUrl +
                              "/automation/paygate-mapping/importers/fixedlengthimporter/",
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "far fa-question-circle" })]
                      )
                    ]),
                    _vm._v(" Create a Fixed Length importer")
                  ])
                : _c("h2", [
                    _c("span", { staticClass: "pull-right" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.docUrl +
                              "/automation/paygate-mapping/importers/fixedlengthimporter/",
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "far fa-question-circle" })]
                      )
                    ]),
                    _vm._v(" Edit a Fixed Length Importer")
                  ])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "The title of the importer.",
                          expression: "'The title of the importer.'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "required"
                    },
                    [_vm._v("Title")]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-10 col-sm-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.importer.title,
                        expression: "importer.title"
                      }
                    ],
                    staticClass: "form-control",
                    class: { invalid: _vm.$v.importer.title.$error },
                    attrs: { type: "text" },
                    domProps: { value: _vm.importer.title },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.importer, "title", $event.target.value)
                        },
                        function($event) {
                          return _vm.$v.importer.title.$touch()
                        }
                      ]
                    }
                  }),
                  !_vm.$v.importer.title.required &&
                  _vm.$v.importer.title.$dirty
                    ? _c("p", { staticClass: "validationText" }, [
                        _vm._v("The title field is required!")
                      ])
                    : _vm._e(),
                  !_vm.$v.importer.title.maxLength
                    ? _c("p", { staticClass: "validationText" }, [
                        _vm._v(
                          "Maximum length is " +
                            _vm._s(
                              _vm.$v.importer.title.$params.maxLength.max
                            ) +
                            " characters!"
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value:
                            "An optional text field for you to write a discription of the importer.",
                          expression:
                            "'An optional text field for you to write a discription of the importer.' ",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ]
                    },
                    [_vm._v("Description")]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-10 col-sm-12" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.importer.description,
                        expression: "importer.description"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { rows: "4", cols: "60" },
                    domProps: { value: _vm.importer.description },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.importer,
                            "description",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.$emit("textarea", $event.target.value)
                        }
                      ],
                      blur: function($event) {
                        return _vm.$v.importer.description.$touch()
                      }
                    }
                  }),
                  !_vm.$v.importer.description.maxLength
                    ? _c("p", { staticClass: "validationText" }, [
                        _vm._v(
                          "Maximum length is " +
                            _vm._s(
                              _vm.$v.importer.description.$params.maxLength.max
                            ) +
                            " characters!"
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value:
                            "The file path that PayGate will use when this mapping is run to locate the file.",
                          expression:
                            "'The file path that PayGate will use when this mapping is run to locate the file.'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ]
                    },
                    [_vm._v("File Location")]
                  )
                ]),
                _c("div", { staticClass: "col-md-10 col-sm-12 mb-3" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.importer.filePath,
                          expression: "importer.filePath"
                        }
                      ],
                      staticClass: "form-control",
                      class: { invalid: _vm.$v.importer.filePath.$error },
                      attrs: { type: "text" },
                      domProps: { value: _vm.importer.filePath },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.importer,
                              "filePath",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$v.importer.filePath.$touch()
                          }
                        ]
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "input-group-append" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-secondary" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.btnFileBrowser($event)
                              }
                            }
                          },
                          [_vm._v("...")]
                        )
                      ],
                      1
                    )
                  ]),
                  !_vm.$v.importer.filePath.maxLength
                    ? _c("p", { staticClass: "validationText" }, [
                        _vm._v(
                          "Maximum length is " +
                            _vm._s(
                              _vm.$v.importer.filePath.$params.maxLength.max
                            ) +
                            " characters!"
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Sets the data type used for auto-mapping.",
                          expression:
                            "'Sets the data type used for auto-mapping.'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "required"
                    },
                    [_vm._v("Data Type")]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-4 col-sm-12" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDataType,
                          expression: "selectedDataType"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedDataType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.dataTypeMode, function(option) {
                      return _c(
                        "option",
                        { key: option.id, domProps: { value: option.value } },
                        [_vm._v(" " + _vm._s(option.text) + " ")]
                      )
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-1 col-sm-12" })
              ])
            ])
          ]),
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [
                _vm._v("File Cleaner "),
                _c("span", { staticClass: "pull-right" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.docUrl +
                          "/automation/paygate-mapping/importers/filecleaning/",
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "form-group col-md-1 col-sm-12" },
                    [
                      _c("p-check", {
                        staticClass: "p-switch",
                        attrs: { name: "check", color: "primary " },
                        model: {
                          value: _vm.cleanOnImport,
                          callback: function($$v) {
                            _vm.cleanOnImport = $$v
                          },
                          expression: "cleanOnImport"
                        }
                      })
                    ],
                    1
                  ),
                  _c("transition", { attrs: { name: "fadein" } }, [
                    _vm.cleanOnImport
                      ? _c(
                          "div",
                          { staticClass: "form-group col-md-2 col-sm-12" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.cleanerRules",
                                    modifiers: { cleanerRules: true }
                                  },
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "Set the rules PayGate will follow when cleaning an imported file.",
                                    expression:
                                      "'Set the rules PayGate will follow when cleaning an imported file.'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.btnCleanRules($event)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-broom" }),
                                _vm._v(" Cleaner Rules"),
                                _vm.cleanerRules.filter(function(x) {
                                  return x.enabled
                                }).length
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge badge-pill badge-primary ml-2"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.cleanerRules.filter(function(
                                              x
                                            ) {
                                              return x.enabled
                                            }).length
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "fadein" } }, [
                    _vm.cleanOnImport
                      ? _c(
                          "div",
                          { staticClass: "col-md-2 col-sm-12 text-right" },
                          [
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "The importer will substitute all characters not found in the following character set with a whitespace character.",
                                    expression:
                                      "'The importer will substitute all characters not found in the following character set with a whitespace character.'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ]
                              },
                              [_vm._v("Character Set")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "fadein" } }, [
                    _vm.cleanOnImport
                      ? _c(
                          "div",
                          { staticClass: "form-group col-md-2 col-sm-12" },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedCharset,
                                    expression: "selectedCharset"
                                  }
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedCharset = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(_vm.charset, function(option, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index + 1,
                                    domProps: { value: option.value }
                                  },
                                  [_vm._v(" " + _vm._s(option.text) + " ")]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "fadein" } }, [
                    _vm.cleanOnImport
                      ? _c("div", { staticClass: "col-md-1 col-sm-12" })
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "fadein" } }, [
                    _vm.cleanOnImport
                      ? _c(
                          "div",
                          { staticClass: "col-md-2 col-sm-12" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.substitutionRules",
                                    modifiers: { substitutionRules: true }
                                  },
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "Set the rules PayGate will follow when making substitutions to a imported file.",
                                    expression:
                                      "'Set the rules PayGate will follow when making substitutions to a imported file.'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "outline-primary" }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon fas fa-exchange-alt"
                                }),
                                _vm._v(" Substitution"),
                                _vm.substitutionRules.length
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge badge-pill badge-primary ml-2"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.substitutionRules.length)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm.cleanOnImport
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: "cleanerRules",
                              accordion: "accordion"
                            }
                          },
                          [
                            _c(
                              "b-card",
                              { attrs: { title: "File Cleaner Rules" } },
                              [
                                _c("br"),
                                _vm._l(_vm.cleanerRules, function(
                                  column,
                                  index
                                ) {
                                  return _c("div", { key: index }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-1" }),
                                      _c("div", { staticClass: "col-md-11" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pretty p-icon p-smooth"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: column.enabled,
                                                  expression: "column.enabled"
                                                }
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  column.enabled
                                                )
                                                  ? _vm._i(
                                                      column.enabled,
                                                      null
                                                    ) > -1
                                                  : column.enabled
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = column.enabled,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          column,
                                                          "enabled",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          column,
                                                          "enabled",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      column,
                                                      "enabled",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "state p-primary"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon fa fa-check"
                                                }),
                                                _c("label", [
                                                  _vm._v(_vm._s(column.name))
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("br")
                                  ])
                                })
                              ],
                              2
                            ),
                            _c("br")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.cleanOnImport
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: "substitutionRules",
                              accordion: "accordion"
                            }
                          },
                          [
                            _c(
                              "b-card",
                              { attrs: { title: "Substitution Rules" } },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("span", { staticClass: "small" }, [
                                      _vm._v(
                                        " Substitute the 'From' character with the value of the 'To' character. "
                                      )
                                    ])
                                  ])
                                ]),
                                _c("br"),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group col-md-12 col-sm-12"
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-md-1" }),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          _c("strong", [_vm._v("Enabled")])
                                        ]),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          _c("strong", [_vm._v("From")])
                                        ]),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          _c("strong", [_vm._v("To")])
                                        ])
                                      ]),
                                      _vm._l(_vm.substitutionRules, function(
                                        column,
                                        index
                                      ) {
                                        return _c("div", { key: index }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "row columnSection"
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "col-md-1"
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pretty p-icon p-smooth"
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              column.enabled,
                                                            expression:
                                                              "column.enabled"
                                                          }
                                                        ],
                                                        attrs: {
                                                          type: "checkbox"
                                                        },
                                                        domProps: {
                                                          checked: Array.isArray(
                                                            column.enabled
                                                          )
                                                            ? _vm._i(
                                                                column.enabled,
                                                                null
                                                              ) > -1
                                                            : column.enabled
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                column.enabled,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    column,
                                                                    "enabled",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    column,
                                                                    "enabled",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                column,
                                                                "enabled",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "state p-primary"
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon fa fa-check"
                                                          }),
                                                          _c("label")
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-1" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: column.from,
                                                        expression:
                                                          "column.from"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      maxlength: "1"
                                                    },
                                                    domProps: {
                                                      value: column.from
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          column,
                                                          "from",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "col-md-1"
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-1" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: column.to,
                                                        expression: "column.to"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      maxlength: "1"
                                                    },
                                                    domProps: {
                                                      value: column.to
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          column,
                                                          "to",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "col-md-1"
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-5 text-right"
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.d500",
                                                          value:
                                                            "Removes the substitute rule from the importer.",
                                                          expression:
                                                            "'Removes the substitute rule from the importer.'",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            d500: true
                                                          }
                                                        }
                                                      ],
                                                      attrs: {
                                                        variant: "link",
                                                        size: "sm"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.btnRemoveSubstitutionRule(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "text-secondary glyphicon ti-trash text-danger",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ])
                                      }),
                                      _c("br"),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-12 text-right"
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      "Add a new substitution rule to the importer.",
                                                    expression:
                                                      "'Add a new substitution rule to the importer.'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  variant:
                                                    "btn btn-outline-primary float-right"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.btnAddSubstitutionRule(
                                                      _vm.index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "icon fa fa-plus"
                                                }),
                                                _vm._v(" Add Rule")
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [
                _vm._v("File Pre-processing "),
                _c("span", { staticClass: "pull-right" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.docUrl +
                          "/automation/paygate-mapping/importers/filepreprocessing/",
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  )
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _c("div", { staticClass: "row mb-4" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "form-group col-md-1 col-sm-12" },
                    [
                      _c("p-check", {
                        staticClass: "p-switch",
                        attrs: { name: "check", color: "primary " },
                        model: {
                          value: _vm.enablePreProcessor,
                          callback: function($$v) {
                            _vm.enablePreProcessor = $$v
                          },
                          expression: "enablePreProcessor"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("transition", { attrs: { name: "fadein" } }, [
                  _vm.enablePreProcessor
                    ? _c(
                        "div",
                        [
                          _c(
                            "draggable",
                            {
                              staticClass: "list-group",
                              attrs: {
                                list: _vm.preProcessingRules,
                                group: "preprop",
                                handle: ".handle"
                              }
                            },
                            _vm._l(_vm.preProcessingRules, function(
                              item,
                              index
                            ) {
                              return _c("div", { key: index + 1 }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-1 text-center " },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-align-justify handle"
                                      })
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "pretty p-icon p-smooth" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.enabled,
                                              expression: "item.enabled"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(item.enabled)
                                              ? _vm._i(item.enabled, null) > -1
                                              : item.enabled
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = item.enabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "enabled",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "enabled",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "enabled", $$c)
                                              }
                                            }
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "state p-primary" },
                                          [
                                            _c("i", {
                                              staticClass: "icon fa fa-check"
                                            }),
                                            _c("label", [
                                              _vm._v(_vm._s(item.description))
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7 text-right" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.btnRemovePreProcessingRule(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName:
                                                  "v-b-popover.hover.top.d500",
                                                value:
                                                  "Removes the pre-processing rule from the importer.",
                                                expression:
                                                  "'Removes the pre-processing rule from the importer.'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                  d500: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "text-secondary glyphicon ti-trash text-danger",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                item.hasValueA
                                  ? _c("div", { staticClass: "row" }, [
                                      item.hasValueA
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-md-2 text-right"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(item.valueALabel) + " "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      item.hasValueA
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.valueADefault,
                                                    expression:
                                                      "item.valueADefault"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  invalid:
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueADefault.$error
                                                },
                                                attrs: { type: "textbox" },
                                                domProps: {
                                                  value: item.valueADefault
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "valueADefault",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.$v.preProcessingRules.$each[
                                                        index
                                                      ].valueADefault.$touch()
                                                    }
                                                  ]
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueADefault.required
                                        ? _c(
                                            "p",
                                            { staticClass: "validationText" },
                                            [_vm._v("The field is required!")]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                item.hasValueB
                                  ? _c("div", { staticClass: "row" }, [
                                      item.hasValueB
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-md-2 text-right"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(item.valueBLabel) + " "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      item.hasValueB
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.valueBDefault,
                                                    expression:
                                                      "item.valueBDefault"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  invalid:
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueBDefault.$error
                                                },
                                                attrs: { type: "textbox" },
                                                domProps: {
                                                  value: item.valueBDefault
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "valueBDefault",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.$v.preProcessingRules.$each[
                                                        index
                                                      ].valueBDefault.$touch()
                                                    }
                                                  ]
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueBDefault.required
                                        ? _c(
                                            "p",
                                            { staticClass: "validationText" },
                                            [_vm._v("The field is required!")]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                item.hasValueC
                                  ? _c("div", { staticClass: "row" }, [
                                      item.hasValueC
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-md-2 text-right"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(item.valueCLabel) + " "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      item.hasValueC
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.valueCDefault,
                                                    expression:
                                                      "item.valueCDefault"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  invalid:
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueCDefault.$error
                                                },
                                                attrs: { type: "textbox" },
                                                domProps: {
                                                  value: item.valueCDefault
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "valueCDefault",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.$v.preProcessingRules.$each[
                                                        index
                                                      ].valueCDefault.$touch()
                                                    }
                                                  ]
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueCDefault.required
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [_vm._v("The field is required!")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueCDefault.minValue
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [
                                              _vm._v(
                                                "Min value is " +
                                                  _vm._s(
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueCDefault.$params
                                                      .minValue.min
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueCDefault.maxValue
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [
                                              _vm._v(
                                                "Max value is " +
                                                  _vm._s(
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueCDefault.$params
                                                      .maxValue.max
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueCDefault.integer
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [_vm._v("Must be a whole number")]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                item.hasValueD
                                  ? _c("div", { staticClass: "row" }, [
                                      item.hasValueD
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-md-2 text-right"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(item.valueDLabel) + " "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      item.hasValueD
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.valueDDefault,
                                                    expression:
                                                      "item.valueDDefault"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  invalid:
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueDDefault.$error
                                                },
                                                attrs: { type: "textbox" },
                                                domProps: {
                                                  value: item.valueDDefault
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        item,
                                                        "valueDDefault",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.$v.preProcessingRules.$each[
                                                        index
                                                      ].valueDDefault.$touch()
                                                    }
                                                  ]
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueDDefault.required
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [_vm._v("The field is required!")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueDDefault.minValue
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [
                                              _vm._v(
                                                "Min value is " +
                                                  _vm._s(
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueDDefault.$params
                                                      .minValue.min
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueDDefault.maxValue
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [
                                              _vm._v(
                                                "Max value is " +
                                                  _vm._s(
                                                    _vm.$v.preProcessingRules
                                                      .$each[index]
                                                      .valueDDefault.$params
                                                      .maxValue.max
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.preProcessingRules.$each[index]
                                        .valueDDefault.integer
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 validationText"
                                            },
                                            [_vm._v("Must be a whole number")]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _c("hr")
                              ])
                            }),
                            0
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-5" }),
                            _c("div", { staticClass: "col-md-5" }),
                            _c(
                              "div",
                              { staticClass: "col-md-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value:
                                          "Add a new Pre-Processor rule to the importer.",
                                        expression:
                                          "'Add a new Pre-Processor rule to the importer.'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      variant:
                                        "btn btn-outline-primary float-right"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.btnAddPreProcessorRule(
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "icon fa fa-plus" }),
                                    _vm._v(" Add Rule")
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _c("transition", { attrs: { name: "fadein" } }, [
                  _vm.columnsOverlap
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "alert alert-warning",
                              attrs: { role: "alert" }
                            },
                            [
                              _c("strong", [_vm._v("Warning")]),
                              _vm._v(
                                " One or more of the columns overlap. This might indicate a configuration problem."
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("transition", { attrs: { name: "fadein" } }, [
                  _vm.duplicateDataTypes > 0
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "alert alert-danger",
                              attrs: { role: "alert" }
                            },
                            [
                              _c("strong", [_vm._v("Error")]),
                              _vm._v(
                                " Two or more of the columns have the same data type."
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._m(3),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-md-12 col-sm-12" },
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "fadein", tag: "ul" } },
                        _vm._l(this.importer.configuration, function(
                          column,
                          index
                        ) {
                          return _c(
                            "li",
                            { key: index + 1, staticClass: "list-group-item " },
                            [
                              index == 0
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-2" }, [
                                      _vm._v(" Start Position ")
                                    ]),
                                    _c("div", { staticClass: "col-2" }, [
                                      _vm._v(" Length of Field ")
                                    ]),
                                    _c("div", { staticClass: "col-2" }, [
                                      _vm._v(" End Position ")
                                    ]),
                                    _c("div", { staticClass: "col-5" }, [
                                      _vm._v(" Data Type ")
                                    ]),
                                    _c("div", { staticClass: "col-1" })
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: column.start,
                                        expression: "column.start"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      invalid:
                                        _vm.$v.importer.configuration.$each[
                                          index
                                        ].start.$error
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      step: "1"
                                    },
                                    domProps: { value: column.start },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            column,
                                            "start",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          _vm.$v.importer.configuration.$each[
                                            index
                                          ].start.$touch()
                                          _vm.checkOverlap()
                                        }
                                      ]
                                    }
                                  }),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .start.required
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [_vm._v("The start value is required!")]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .start.minValue
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [
                                          _vm._v(
                                            "Min start value is " +
                                              _vm._s(
                                                _vm.$v.importer.configuration
                                                  .$each[index].start.$params
                                                  .minValue.min
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .start.maxValue
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [
                                          _vm._v(
                                            "Max start value is " +
                                              _vm._s(
                                                _vm.$v.importer.configuration
                                                  .$each[index].start.$params
                                                  .maxValue.max
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .start.integer
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [_vm._v("Start must be a whole number")]
                                      )
                                    : _vm._e()
                                ]),
                                _c("div", { staticClass: "col-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: column.width,
                                        expression: "column.width"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      invalid:
                                        _vm.$v.importer.configuration.$each[
                                          index
                                        ].width.$error
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      step: "1"
                                    },
                                    domProps: { value: column.width },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            column,
                                            "width",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          _vm.$v.importer.configuration.$each[
                                            index
                                          ].width.$touch()
                                          _vm.checkOverlap()
                                        }
                                      ]
                                    }
                                  }),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .width.required
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [
                                          _vm._v(
                                            "The length value is required!"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .width.minValue
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [
                                          _vm._v(
                                            "Min length value is " +
                                              _vm._s(
                                                _vm.$v.importer.configuration
                                                  .$each[index].start.$params
                                                  .minValue.min
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .width.maxValue
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [
                                          _vm._v(
                                            "Max length value is " +
                                              _vm._s(
                                                _vm.$v.importer.configuration
                                                  .$each[index].start.$params
                                                  .maxValue.max
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.importer.configuration.$each[index]
                                    .width.integer
                                    ? _c(
                                        "div",
                                        { staticClass: "validationText" },
                                        [
                                          _vm._v(
                                            "Length must be a whole number"
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _c("div", { staticClass: "col-2" }, [
                                  _c(
                                    "label",
                                    {
                                      class: {
                                        errorText:
                                          _vm.computeEndPosition(
                                            column.width,
                                            column.start
                                          ) === "Error"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.computeEndPosition(
                                              column.width,
                                              column.start
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-5" }, [
                                  _vm.selectedDataType === "bacsPayments"
                                    ? _c(
                                        "div",
                                        {},
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.bacsDataTypes
                                            },
                                            on: { input: _vm.changedDataType },
                                            model: {
                                              value: column.strongType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  column,
                                                  "strongType",
                                                  $$v
                                                )
                                              },
                                              expression: "column.strongType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm.selectedDataType ===
                                      "bacsBureauCustomers"
                                    ? _c(
                                        "div",
                                        {},
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              options:
                                                _vm.bureauCustomerDataTypes
                                            },
                                            model: {
                                              value: column.strongType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  column,
                                                  "strongType",
                                                  $$v
                                                )
                                              },
                                              expression: "column.strongType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName:
                                              "v-b-popover.hover.top.d500",
                                            value:
                                              "Removes the column from the Column Parameters.",
                                            expression:
                                              "'Removes the column from the Column Parameters.'",
                                            modifiers: {
                                              hover: true,
                                              top: true,
                                              d500: true
                                            }
                                          }
                                        ],
                                        attrs: { variant: "link" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.btnRemoveColumn(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "text-secondary glyphicon ti-trash text-danger",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      ),
                      _c("br"),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Add a new column to this importer.",
                              expression:
                                "'Add a new column to this importer.'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          staticClass: "btn btn-outline-primary float-right",
                          attrs: { "aria-hidden": "true" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.btnAddColumn($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus mr-2" }),
                          _vm._v("Add Column")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block" }, [
            !_vm.isNew
              ? _c("div", { staticClass: "idb-block-title" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "span",
                      { staticClass: "col-md-12 col-sm-12" },
                      [
                        _c("strong", [_vm._v("Mappings using this importer")]),
                        _vm.boundMappings.length > 0
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-primary ml-2"
                              },
                              [_vm._v(_vm._s(_vm.boundMappings.length))]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Refresh the list of bound mappings.",
                                expression:
                                  "'Refresh the list of bound mappings.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.getBoundMappings($event)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-sync-alt pointer" })]
                        )
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            !_vm.isNew
              ? _c("div", { staticClass: "idb-block-content" }, [
                  _vm.boundMappings.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.boundMappings, function(m) {
                          return _c(
                            "div",
                            {
                              key: m.index,
                              staticClass: "row",
                              attrs: { value: m.value }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12 ml-5" },
                                [
                                  _vm._v(_vm._s(m.metaData.title) + " ( "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "small",
                                      attrs: {
                                        tag: "a",
                                        to:
                                          "/automation/mapping/mappingdetail/" +
                                          m._id
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _vm._v(" )")
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", [!_vm.isNew ? _c("span") : _vm._e()]),
                  _c("br")
                ])
              : _vm._e(),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-sm-3" },
                  [
                    _vm.isNew
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Creates and saves a new importer.",
                                expression:
                                  "'Creates and saves a new importer.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "mr-3",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.createImporter($event)
                              }
                            }
                          },
                          [_vm._v("Create Importer")]
                        )
                      : _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value:
                                  "Update the current importer saving any changes.",
                                expression:
                                  "'Update the current importer saving any changes.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "mr-3",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateImporter($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-edit mr-2" }),
                            _vm._v("Save")
                          ]
                        ),
                    !_vm.isNew
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Create a clone of this importer",
                                expression: "'Create a clone of this importer'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "mr-3",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "outline-primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.cloneImporter($event)
                              }
                            }
                          },
                          [_vm._v("Clone")]
                        )
                      : _vm._e(),
                    !_vm.isNew
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Permanently delete this importer.",
                                expression:
                                  "'Permanently delete this importer.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "pull-right",
                            attrs: {
                              disabled:
                                !_vm.isNew && _vm.boundMappings.length > 0,
                              variant: "danger"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteImporter($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-trash mr-2" }),
                            _vm._v("Delete Importer")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("label", [_vm._v("Clean File on Import")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("label", [_vm._v("Enable Pre-processing")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Columns")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { title: "Description" } }, [
          _vm._v("Column Parameters")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }