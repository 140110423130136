<template>
  <div id="downloadBureauCustomerReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Bureau Customer Configuration Report
              <span class="pull-right">
                <a href="#" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>

          <div class="idb-block-content">
            <div class="row form-group col-md-12">
              <span class="col-md-12">
                <h4>
                  <u>Presets</u>
                </h4>
              </span>
              <div class="col-md-3">
                <p-check
                  class="p-default p-curve"
                  id="reportAllNotInJob"
                  @change="reportAllNotInJob()"
                  v-model="checkedAllNotInJob"
                >Select All Bureau Customers Not In Jobs</p-check>
              </div>
              <div class="col-md-2">
                <p-check
                  class="p-default p-curve"
                  id="reportAllData"
                  @change="reportAllJobs()"
                  v-model="checkedAllJobs"
                >Select All Bureau Jobs</p-check>
              </div>
              <div class="col-md-3">
                <p-check
                  class="p-default p-curve"
                  id="reportAllCustomers"
                  @change="reportAllCustomers()"
                  v-model="checkedAllCustomers"
                >Select All Bureau Customers</p-check>
              </div>
            </div>
            <hr />
            <div class="row form-group col-md-12">
              <span class="col-md-12">
                <h4>
                  <u>Search</u>
                </h4>
              </span>
              <div class="col-md-2">Bureau Jobs</div>
              <div class="col-md-6">
                <vue-select
                  multiple
                  v-model="SelectedBureauJobs"
                  label="name"
                  :options="BureauJobDisplay"
                  :closeOnSelect="false"
                  @input="CheckBureauJobsDropdownEntries"
                ></vue-select>
              </div>
              <div class="col-md-2">
                <p-check
                  class="p-default p-curve"
                  id="inverseCustomerSelection"
                  @change="inverseBureauCustomerSelectionSelected"
                  v-model="inverseBureauCustomerSelection"
                >Report Bureau Customers Not In Job</p-check>
              </div>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-2">Bureau Customers</div>
              <div class="col-md-6">
                <vue-select
                  multiple
                  v-model="SelectedBureauCustomers"
                  label="name"
                  :options="BureauCustomersDisplay"
                  :closeOnSelect="false"
                  @input="CheckBureauCustomerDropdownEntries"
                ></vue-select>
              </div>
            </div>
          </div>

          <!-- TABLE -->

          <div class="idb-block-content">
            <vue-good-table
              mode="remote"
              :paginationOptions="paginationOptions"
              :rows="rows"
              :columns="columns"
              :totalRows="totalRecords"
              :lineNumbers="true"
              :sortOptions="{
                        enabled: true
                      }"
              @on-search="onSearch"
              @on-row-click="onRowClick"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              ref="table"
              styleClass="vgt-table striped bordered"
            >
              <div slot="table-actions">
                <b-button
                  @click.prevent="loadTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="printTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Print out the contents of the table'"
                >
                  <i class="fa fa-print pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Export the contents of the table'"
                >
                  <i class="fa fa-file-export pointer dimmedIcon"></i>
                </b-button>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
export default {
  mixins: [Utility, tableFilterMixin],
  components: {
    VueSelect
  },

  data () {
    return {
      isTableLoading: true,
      BureauCustomersLoaded: false,
      BureauJobsLoaded: false,
      checkedAllCustomers: true,
      checkedAllJobs: false,
      checkedAllNotInJob: false,
      SelectedBureauCustomers: [],
      BureauCustomers: [],
      BureauCustomersDisplay: [],
      BureauJobs: [],
      BureauJobDisplay: [],
      SelectedBureauJobs: [],
      inverseBureauCustomerSelection: false,
      serverParams: {
        sort: [{ field: 'bureaucustomername', type: 'asc' }]
      },
      // page: 1,
      // perPage: 10,
      // totalRecords: 0,
      // searchTerm: '',
      rows: [],
      columns: [ // BureauJobId, BureauJobName, BureauCustomerId,  BureauCustomerName, ServiceUserNumber, BankReference, SortCode, AccountNum, Filename
        {
          label: 'Bureau Customer Name',
          field: 'bureauCustomerName'
        },
        {
          label: 'Bureau Job Name',
          field: 'bureauJobName'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Filename',
          field: 'filename'
        },
        {
          label: 'BankReference',
          field: 'bankReference'
        },
        {
          label: 'Sort Code',
          field: 'sortCode'
        },
        {
          label: 'Account Number',
          field: 'accountNumber'
        }
      ],
      highGuid: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF'
    }
  },

  methods: {
    async load () {
      this.isTableLoading = true

      var jobIdList = []
      var jobIndex = 0
      for (jobIndex = 0; jobIndex < this.SelectedBureauJobs.length; ++jobIndex) {
        jobIdList.push(this.SelectedBureauJobs[jobIndex].id)
      }

      var customerIdList = []
      var customerIndex = 0
      for (customerIndex = 0; customerIndex < this.SelectedBureauCustomers.length; ++customerIndex) {
        customerIdList.push(this.SelectedBureauCustomers[customerIndex].id)
      }

      var searchData = {
        checkedAllCustomers: this.checkedAllCustomers,
        checkedAllJobs: this.checkedAllJobs,
        checkedAllNotInJobs: this.checkedAllNotInJob,
        inverseBureauCustomerSelection: this.inverseBureauCustomerSelection,
        selectedBureauJobs: jobIdList,
        selectedBureauCustomers: customerIdList
      }
      console.log('searchData', searchData)
      this.serverParams.searchData = searchData
      //      query.params.searchData = searchData

      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/loadTable', { params: { ...this.buildGoodTableQuery(), searchData }, showload: true })
      console.log('loadTableResponse', response)
      this.rows = response.data.data
      this.isTableLoading = true
      // this.totalRecords = response.data.meta.totalItems
      // this.page = 1
    },
    // onSearch (params) {
    //   this.searchTerm = params.searchTerm
    //   this.loadTable()
    // },
    // onPageChange (params) {
    //   this.page = params.currentPage
    //   this.loadTable()
    // },
    // onPerPageChange (params) {
    //   this.perPage = params.currentPerPage
    //   this.loadTable()
    // },
    // async onSortChange (params) {
    //   console.log('sortchange params', params)
    //   this.sort = params
    //   // this.sort.field = '${params[0].field}'
    //   // this.sort.type = {'${params[0].type}'}

    //   //      this.sort = `${[this.columns[params.columnIndex].field]} ${params.sortType}`
    //   //      this.sort = {'${params[0].field}', '${params[0].type}'}
    //   console.log('this.sort', this.sort)
    //   await this.loadTable()
    // },
    // onColumnFilter (params) {
    //   this.updateParams(params)
    //   this.loadTable()
    // },
    onRowClick (params) {
      // var id = params.row['bureauCustomerId']
      // this.$router.push({ name: 'BureauCustomerDetails', params: { id: id } })
    },
    async reportAllNotInJob () {
      this.checkedAllCustomers = false
      this.checkedAllJobs = false
      this.inverseBureauCustomerSelection = false
      this.SelectedBureauCustomers = []
      this.SelectedBureauJobs = []
      await this.load()
    },

    async reportAllCustomers () {
      this.checkedAllNotInJob = false
      this.checkedAllJobs = false
      this.inverseBureauCustomerSelection = false
      this.SelectedBureauCustomers = []
      this.SelectedBureauJobs = []
      await this.load()
    },

    async reportAllJobs () {
      this.checkedAllNotInJob = false
      this.checkedAllCustomers = false
      this.inverseBureauCustomerSelection = false
      this.SelectedBureauCustomers = []
      this.SelectedBureauJobs = []
      await this.load()
    },

    async inverseBureauCustomerSelectionSelected () {
      this.checkedAllNotInJob = false
      this.checkedAllCustomers = false
      this.checkedAllData = false
      if (this.SelectedBureauJobs.length === 0) {
        this.inverseBureauCustomerSelection = false
      }
      console.log('this.inverseBureauCustomerSelection', this.inverseBureauCustomerSelection)
      if (this.inverseBureauCustomerSelection) {
        this.SelectedBureauCustomers = []
      }
      await this.load()
    },

    async getBureauJobs () {
      console.log('attempting get bureau jobs')

      var result = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getgroupjobsmodel')
      this.BureauJobs = result.data
      this.BureauJobDisplay = [] // reset the display array
      console.log('Bureau Jobs Returned', this.bureauJobs)

      var BureauJobSelectAllOption = {
        id: this.highGuid,
        name: 'All Bureau Jobs...',
        disabled: false
      }
      this.BureauJobDisplay.push(BureauJobSelectAllOption)

      var index = 0
      for (index = 0; index < this.BureauJobs.length; ++index) {
        var currentJob = {
          id: this.BureauJobs[index].bureauJobId,
          name: this.BureauJobs[index].bureauJobName + ' (' + this.BureauJobs[index].groupName + ')',
          disabled: false
        }
        this.BureauJobDisplay.push(currentJob)
      }
      this.BureauJobsLoaded = true
      console.log('Bureau Jobs Display', this.BureauJobDisplay)
    },
    async getBureauCustomers () {
      console.log('inside get bcs')

      var query = {
        params: {
          fields: 'name,serviceusernumber,bureaucustomerid'
        }
      }
      query.params.selectedBureauJobs = this.SelectedBureauJobs
      console.log('getBureauCustomers', query)

      await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', query)
        .then(response => {
          console.log('getBureauCustomers response', response)
          this.BureauCustomers = response.data.data
          this.BureauCustomersDisplay = [] // reset the display array

          var BureauCustomerSelectAllOption = {
            id: this.highGuid,
            serviceUserNumber: '000000',
            name: 'All Bureau Customers...',
            disabled: false
          }

          this.BureauCustomersDisplay.push(BureauCustomerSelectAllOption)

          var index = 0
          for (index = 0; index < this.BureauCustomers.length; ++index) {
            var currentBC = {
              id: this.BureauCustomers[index].bureauCustomerId,
              serviceUserNumber: this.BureauCustomers[index].serviceUserNumber,
              name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
              disabled: false
            }
            this.BureauCustomersDisplay.push(currentBC)
          }
          console.log('BureauCustomerDisplay', this.BureauCustomersDisplay)
          this.BureauCustomersLoaded = true
        })
        .catch(error => console.error(error))

      console.log('getBureauCustomers bureaucustomers', this.BureauCustomers)
    },
    async CheckBureauCustomerDropdownEntries (value) {
      // Clear Down Other Entries
      this.checkedAllCustomers = false
      this.checkedAllJobs = false
      this.checkedAllNotInJob = false
      this.inverseBureauCustomerSelection = false
      console.log('CheckBureauCustomerDropdownEntries value', value)
      var bureauCustomerIdAdded = value.id

      if (bureauCustomerIdAdded === this.highGuid) {
        console.log('special case - add that one and disable the rest')
        this.SelectedBureauCustomers = []
        this.SelectedBureauCustomers.push(value)
        console.log('this.SelectedBureauCustomers after slice', this.SelectedBureauCustomers)

        // disable all other entries
        var i = 0
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === bureauCustomerIdAdded) {
            this.BureauCustomersDisplay[i].disabled = true
          } else {
            this.BureauCustomersDisplay[i].disabled = false
          }
        }
      } else {
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === this.highGuid) {
            this.BureauCustomersDisplay[i].disabled = false
          } else {
            this.BureauCustomersDisplay[i].disabled = true
          }
        }
        // if (p > 0 && (this.SelectedBureauCustomers[0].id === 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF')) {
        //   this.SelectedBureauCustomers.shift()
        // }
      }
      console.log('this.BureauCustomersDisplay after slice', this.BureauCustomersDisplay)
      await this.load()
    },

    async CheckBureauJobsDropdownEntries (value) {
      this.checkedAllCustomers = false
      this.checkedAllJobs = false
      this.checkedAllNotInJob = false

      console.log('CheckBureauJobsDropdownEntries value', value)
      var p = this.SelectedBureauJobs.length - 1
      var bureauJobIdAdded = this.SelectedBureauJobs[p].id

      if (bureauJobIdAdded === this.highGuid) {
        console.log('special case - add that one and disable the rest')
        this.SelectedBureauJobs = this.SelectedBureauJobs.slice(this.SelectedBureauJobs.length - 1)
        console.log('this.SelectedBureauJobs after slice', this.SelectedBureauJobs)

        // disable all other entries
        var i = 0
        for (i = 0; i < this.BureauJobDisplay.length; ++i) {
          if (this.BureauJobDisplay[i].id === bureauJobIdAdded) {
            this.BureauJobDisplay[i].disabled = false
          } else {
            this.BureauJobDisplay[i].disabled = true
          }
        }
      } else {
        for (i = 0; i < this.BureauJobDisplay.length; ++i) {
          if (this.BureauJobDisplay[i].id === this.highGuid) {
            this.BureauJobDisplay[i].disabled = true
          } else {
            this.BureauJobDisplay[i].disabled = false
          }
        }
        if (p > 0 && (this.SelectedBureauJobs[0].id === this.highGuid)) {
          this.SelectedBureauJobs.shift()
        }
      }
      console.log('this.BureauJobDisplay after slice', this.BureauJobDisplay)
      await this.load()
    }
  },
  // First load
  async mounted () {

  },
  async created () {
    //    this.sort = 'bureauCustomerName asc'
    this.isTableLoading = true
    console.log('calling get bcs')
    await this.getBureauJobs()
    await this.getBureauCustomers()
    this.isTableLoading = false
    await this.load()
  }
}
</script>
