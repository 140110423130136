<template>
  <div>
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              <!-- <span class="pull-right">
                <a href="#" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span> -->
              Sftp Requests
            </h2>
          </div>
          <div class="idb-block-content">
            <p>Below are incoming requests from users to obtain SFTP details for use with Agent.</p>
            <vue-good-table
              ref="table"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-row-click="onRowClick"
              :columns="columns"
              :rows="sftpRequests"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                    enabled: true
                    }"
              :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
              styleClass="vgt-table striped bordered"
            >
              <template slot="loadingContent">
                <h1>Loading...</h1>
              </template>
              <div slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">No Pending SFTP Requests</div>
              </div>
              <div slot="table-actions">
                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  title="Refresh Table"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="printTable"
                  class
                  variant="link"
                  title="Print Table"
                  v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
                >
                  <i class="fa fa-print pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  title="Export Table"
                  v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
                >
                  <i class="fa fa-share-square pointer dimmedIcon"></i>
                </b-button>
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- <router-link :to="{ path: '/payer/' + props.row.ukPayerId + '/edit', params: { ukPayerId: props.row.ukPayerId }, query: { retPath: 'manage' }  }"> eslint-disable-line -->
                <span v-if="props.column.field === 'status'">{{ displayStatus(props.row.status) }}</span>
                <span v-else>{{ props.row[props.column.field] }}</span>
                <!-- </router-link> -->
              </template>
            </vue-good-table>
          </div>
          <div class="idb-block-footer noprint-footer"></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      sftpRequests: [],
      columns: [
        { 
          hidden: true,
          field: 'agentSftpDetailsRequestId'
        },
        { 
          hidden: true,
          field: 'userId'
        },
        { 
          hidden: true,
          field: 'paygateId'
        },        
        { 
          label: 'User Name',
          field: 'userName'
        },
        { 
          label: 'Customer Name',
          field: 'customerName'
        },
        { 
          label: 'Customer Code',
          field: 'customerCode'
        },
        { 
          label: 'Email Address',
          field: 'emailAddress'
        },
        { 
          label: 'Date Requested',
          field: 'timeRequested',
          formatFn: this.formatDate
        },
        { 
          field: 'publicKey',
          hidden: true
        },
        { 
          hidden: true,
          field: 'actioned'
        }
      ],
      serverParams: {
        sort: [{ field: 'timeRquested', type: 'desc' }]
      },
      totalRecords: 0,
      originalState: [],
      tableData: [],
      totalRecords: 0
    }
  },
  methods: {
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_AGENT_API_URL}sftprequests/`, { params: { ...this.buildGoodTableQuery() }})
      if (response !== null) {
        this.sftpRequests = response.data.items
        this.totalRecords = response.data.count
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },    
    onRowClick (params) {
      this.$router.push(`/admin/system/sftprequests/${params.row.agentSftpDetailsRequestId}`)
    },
    onCellClick (params) {
      this.selectedRow = this.tableData[params.rowIndex]
    }    
  },
  async mounted () {
    this.load()
  }
}
</script>
