var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" " + _vm._s(_vm.bureauJob.name) + " "),
              _vm._m(0),
              _c("favourite-icon")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { sm: "2" } }, [
                  _c(
                    "label",
                    { staticClass: "mt-2", attrs: { for: "filter" } },
                    [_vm._v("Group")]
                  )
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("group-select", {
                      attrs: { groups: _vm.groups, clearable: false },
                      model: {
                        value: _vm.bureauJob.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.bureauJob, "groupId", $$v)
                        },
                        expression: "bureauJob.groupId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-btn",
                      {
                        ref: "btnShow",
                        staticClass: "float-right",
                        attrs: {
                          variant: "outline-primary",
                          disabled: _vm.isLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.showCustomerSelector()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-list pointer" }),
                        _vm._v(" Bulk Insert ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-row", [_c("hr")]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          id: "jobDetailsTable",
                          paginationOptions: _vm.paginationOptions,
                          "sort-options": _vm.sortOptions,
                          rows: _vm.rows,
                          columns: _vm.columns,
                          searchOptions: { enabled: true },
                          mode: "remote",
                          totalRows: _vm.totalRecords,
                          isLoading: _vm.isTableLoading,
                          styleClass: "vgt-table striped bordered"
                        },
                        on: {
                          "on-page-change": _vm.onPageChange,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch,
                          "on-sort-change": _vm.onSortChange,
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "actions"
                                  ? _c("span", [
                                      _c("button", {
                                        staticClass: "fa fa-copy mr1",
                                        staticStyle: {
                                          color: "orange",
                                          padding: "10",
                                          border: "none",
                                          background: "none"
                                        },
                                        attrs: {
                                          id: "onDuplicateJobDetailClick",
                                          title: "Duplicate Job Detail",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.onDuplicateJobDetailClick(
                                              props.row
                                            )
                                          }
                                        }
                                      }),
                                      _c("button", {
                                        staticClass: "fa fa-trash-alt mr1",
                                        staticStyle: {
                                          color: "red",
                                          padding: "10",
                                          border: "none",
                                          background: "none"
                                        },
                                        attrs: {
                                          id: "onDeleteJobDetailClick",
                                          title: "Delete Job Detail",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onDeleteJobDetailClick(
                                              props.row
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onCellClick(props.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Add Job Detail",
                                    expression: "'Add Job Detail'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onAddNewJobDetailClick($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus-circle pointer"
                                }),
                                _vm._v(" Add Job Detail ")
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                })
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "Print out the contents of the Logs table",
                                    expression:
                                      "'Print out the contents of the Logs table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.printTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-print pointer dimmedIcon"
                                })
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "Export the contents of the Logs table",
                                    expression:
                                      "'Export the contents of the Logs table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.exportTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-file-export pointer dimmedIcon"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: { variant: "danger", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.onDeleteClick()
                  }
                }
              },
              [_vm._v("Delete")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.showModal }
              },
              [
                _c("i", { staticClass: "glyphicon ti-layers rpad" }),
                _vm._v("Clone Bureau Job ")
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            lazy: "",
            size: "lg",
            id: "customerselector",
            title: "Select Bureau Customer(s)",
            "hide-footer": ""
          }
        },
        [
          _c("bureau-customer-selector", {
            attrs: { customerIdsInJob: _vm.getCustomerIdsFromRows() },
            on: { hideCustomerSelector: _vm.hideCustomerSelector }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "clone-modal",
          attrs: {
            id: "cloneModal",
            title: "Clone Bureau Job",
            "ok-title": "Clone",
            "cancel-variant": "secondary-outline",
            "no-close-on-backdrop": "",
            centered: true
          },
          on: { ok: _vm.cloneBureauJob, hidden: _vm.resetModal }
        },
        [
          _c(
            "div",
            {
              staticClass: "form-bureaujob row",
              class: { invalid: _vm.$v.bureauJob.clonedName.$error }
            },
            [
              _c("label", { staticClass: "col-form-label col-md-3 required" }, [
                _vm._v("New Name")
              ]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.bureauJob.clonedName.$model,
                        expression: "$v.bureauJob.clonedName.$model"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.$v.bureauJob.clonedName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.bureauJob.clonedName,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c(
                    "validation-messages",
                    {
                      attrs: { name: "new name" },
                      model: {
                        value: _vm.$v.bureauJob.clonedName,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.bureauJob, "clonedName", $$v)
                        },
                        expression: "$v.bureauJob.clonedName"
                      }
                    },
                    [
                      _vm.$v.bureauJob.clonedName.notSameAs != undefined &&
                      !_vm.$v.bureauJob.clonedName.notSameAs
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v(
                              "Cloned name cannot be the same as the original name"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        [
          _c("addBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.id,
              bureauCustomers: _vm.bureauCustomerDisplay
            },
            on: { submit: _vm.addBureauJobDetailClosed }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("editBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.id,
              bureauCustomers: _vm.bureauCustomerDisplay,
              bureauJobDetailId: _vm.selectedJobDetailRecordId
            },
            on: { submit: _vm.editBureauJobDetailClosed }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }