<template>
  <div>
    <loading v-bind:loading="loading"></loading>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Create a New Bureau Job
          <help-icon></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <b-row>
          <b-col sm="2">
            <label class="mt-2" for="filter">Group</label>
          </b-col>
          <b-col sm="3">
            <group-select
              v-model="bureauJob.groupId"
              :groups="groups"
              :clearable="false"
              @input="SilentSave"
            ></group-select>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              class="float-right"
              @click="showCustomerSelector()"
              ref="btnShow"
              :disabled="isLoading"
            >Bulk Insert</b-btn>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col sm="2" class="mt-2">
            <label for="name">Job Name</label>
          </b-col>
          <b-col sm="3">
            <b-form-input type="text" v-model.trim="bureauJob.name" @change="SilentSave"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <hr />
        </b-row>

        <!-- table -->

        <b-row>
          <b-col sm="12">
            <vue-good-table
              id="jobDetailsTable"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :rows="rows"
              :columns="columns"
              :searchOptions="{ enabled: true }"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              ref="table"
              :isLoading.sync="isTableLoading"
              styleClass="vgt-table striped bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="fa fa-copy mr1"
                    id="onDuplicateJobDetailClick"
                    style="color: orange;
                           padding: 10;
                           border: none;
                           background: none;"
                    @click.stop="onDuplicateJobDetailClick(props.row)"
                    title="Duplicate Job Detail"
                    :disabled="isLoading"
                  ></button>
                  <button
                    class="fa fa-trash-alt mr1"
                    id="onDeleteJobDetailClick"
                    style="color: red;
                           padding: 10;
                           border: none;
                           background: none;"
                    @click="onDeleteJobDetailClick(props.row)"
                    title="Delete Job Detail"
                    :disabled="isLoading"
                  ></button>
                </span>
                <span v-else @click="onCellClick(props.row)">
                  {{
                  props.formattedRow[props.column.field]
                  }}
                </span>
              </template>

              <div slot="table-actions">
                <b-button
                  @click.prevent="onAddNewJobDetailClick"
                  class
                  variant="primary"
                  size="sm"
                  v-b-popover.hover.top.d500="'Add Job Detail'"
                >
                  <i class="fa fa-plus-circle pointer"></i>
                  Add Job Detail
                </b-button>

                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="printTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
                >
                  <i class="fa fa-print pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
                >
                  <i class="fa fa-file-export pointer dimmedIcon"></i>
                </b-button>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

      <div class="idb-block-footer">
        <b-button variant="primary" @click="onSave()" :disabled="isLoading">Save</b-button>
      </div>
    </div>

    <div>
      <addBureauJobDetailModal
        :bureauJobId="bureauJob.bureauJobId"
        :bureauCustomers="bureauCustomerDisplay"
        v-on:submit="addBureauJobDetailClosed"
      ></addBureauJobDetailModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import Utility from '@/Assets/Mixins/Utility'
import AddBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/AddBureauJobDetail.vue'

export default {
  components: { addBureauJobDetailModal: AddBureauJobDetail },
  mixins: [Utility, loading, tableFilterMixin],
  data () {
    return {
      groups: [],
      bureauCustomers: [],
      bureauCustomerDisplay: [],
      bureauJob: {},
      loading: false,

      // table
      rows: [],
      columns: [
        {
          label: 'Job Details Id',
          field: 'bureauJobDetailId',
          hidden: true
        },
        {
          label: 'Bureau Customer Id',
          field: 'bureauCustomerId',
          hidden: true
        },
        {
          label: 'Customer Name (SUN)',
          field: 'customerName'
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          hidden: true
        },
        {
          label: 'Payment File/URL',
          field: 'filename'
        },
        {
          label: 'Import Schema',
          field: 'importSchema'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }]
      }

    }
  },
  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  async created () {
    this.bureauJob.bureauJobId = ''
    this.bureauJob.name = ''
    this.bureauJob.paygateId = this.paygateId
    await this.load()
  },
  methods: {
    // ================================== Supporting Methods

    showCustomerSelector () {
      // ToDo
    },
    onAddNewJobDetailClick () {
      console.log('Add New Bureau Job Detail Click')
      this.$bvModal.show('add-bureau-job-detail')
    },
    async addBureauJobDetailClosed () {
      console.log('Add New Job Closed')
      await this.getBureauJobDetails()
    },

    // ================================== Initial Load

    load: _.debounce(async function () {
      await this.getBureauGroupData()
      await this.loadCreateBureauJob()
      await this.getBureauJobDetails()
      await this.getBureauCustomerData()
    }, 800),

    async getBureauGroupData () {
      const getGroupsResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaugroups/getbureaugroups', { showload: true })
      this.groups = getGroupsResponse.data
      console.log('bureauGroups Getter', getGroupsResponse)
    },

    async loadCreateBureauJob () {
      var bureauJob = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaujob/CreateBureauJob', { showload: true })
      this.bureauJob = bureauJob.data
      console.log('cached bureaujob', bureauJob)
    },

    async getBureauJobDetails () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJobDetails/', { params: { ...this.buildGoodTableQuery(), id: this.bureauJob.bureauJobId }, showload: true })
        this.rows = response.data.data
      } catch (e) { console.log('Error for table load in get bureau job detail', e) } finally {
        this.isTableLoading = false
      }
    },
    async getBureauCustomerData () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/getbureaucustomerlist', { showload: true })
      console.log('bureaujob getter', response)
      this.bureauCustomers = response.data

      this.bureauCustomerDisplay = []

      var index = 0
      for (index = 0; index < this.bureauCustomers.length; ++index) {
        var current = {
          id: this.bureauCustomers[index].bureauCustomerId,
          name: this.bureauCustomers[index].display,
          disabled: false
        }
        this.bureauCustomerDisplay.push(current)
      }
    },
    // ================================== Save

    async SilentSave () {
      console.log('Silent Saving')

      var data = {
        name: this.bureauJob.name,
        bureauJobId: this.bureauJob.bureauJobId,
        groupId: this.bureauJob.groupId,
        bureauJobDetails: this.rows
      }

      var silentSaveResult = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/CreateBureauJobSilentSave', data, { showload: false })
      console.log('SilentSave: ', silentSaveResult.data.errorText)
    },

    async onSave () {
      console.log('redevelop this')
      var data = {
        name: this.bureauJob.name,
        bureauJobId: this.bureauJob.bureauJobId,
        groupId: this.bureauJob.groupId,
        bureauJobDetails: this.rows
      }

      var persistResult = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/PersistJobInBureauJobCreate', data, { showload: true })
      console.log('Persisted result', persistResult)

      if (persistResult.data.toastType === 2) {
        this.$toastr.s(persistResult.data.toastMessage)
        // navigate immediately
        this.$router.push({ name: 'ManageJob', params: { id: this.bureauJob.bureauJobId } })
      } else {
        this.$toastr.e(persistResult.data.toastMessage)
      }
    }
  }
}

</script>

<style scoped>
input,
select {
  font-size: 100%;
  border: none;
  width: 100%;
}
</style>
