<template>
  <div>
    <!-- Service User Number -->
    <div class="form-group row" :class="{invalid: details.serviceUserNumber.$error}">
      <label class="col-form-label col-md-3 required">Service User Number</label>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model.trim="details.serviceUserNumber.$model" />
        <!-- Validation -->
        <validation-messages v-model="details.serviceUserNumber" name="service user number">
          <small
            class="form-text small"
            v-if="details.serviceUserNumber.sunValidator != undefined && !details.serviceUserNumber.sunValidator"
          >Incorrect sun format</small>
        </validation-messages>
        <small
          class="form-text text-muted"
        >The format for a Service User Number is 6 numbers or a B followed by 5 numbers.</small>
      </div>
    </div>

    <!-- Import Schema -->
    <div class="form-group row" :class="{invalid: details.importSchema.$error}">
      <label class="label-control col-md-3 required">Import Schema</label>
      <div class="col-md-5">
        <b-form-select v-model="details.importSchema.$model" :options="importSchemas"></b-form-select>
        <validation-messages v-model="details.importSchema" name="import schema"></validation-messages>
      </div>
    </div>

    <!-- Mapping -->
    <div class="form-group row" v-if="details.importSchema.$model === 'Mapping'">
      <label class="label-control col-md-3">Mapping</label>
      <div class="col-md-5">
        <b-form-select v-model="details.mappingId.$model" :options="mappings"></b-form-select>
      </div>
    </div>

    <!-- Bank Account -->
    <bank-account-select v-model="details.bankAccountId" :bankAccounts="bankAccounts"></bank-account-select>

    <!-- Payment Signing Method -->
    <div class="form-group row" :class="{invalid: details.tokenType.$error}">
      <label class="label-control col-md-3 required">Payment Signing Method</label>
      <div class="col-md-5" v-if="hasHsm">
        <p-radio
          class="p-default p-round"
          value="Hsm"
          v-model="details.tokenType.$model"
          :disabled="disabled"
        >HSM</p-radio>
        <p-radio
          class="p-default p-round"
          value="SmartCard"
          v-model="details.tokenType.$model"
          :disabled="disabled"
        >Smart Card</p-radio>
        <validation-messages v-model="details.tokenType" name="payment signing method"></validation-messages>
      </div>
      <div class="col-md-5" v-else>Smart Card</div>
    </div>

    <!-- HSM Certificate -->
    <div
      class="form-group row"
      :class="{invalid: details.certificateId.$error, warn: !selectedHSMCert.valid}"
      v-if="details.tokenType.$model == 'Hsm'"
    >
      <label class="label-control col-md-3 required">HSM Certificate</label>
      <div class="col-md-5">
        <b-form-select v-model="details.certificateId.$model" :options="hsmCertificates"></b-form-select>
        <validation-messages v-model="details.certificateId" name="HSM certificates"></validation-messages>
        <span class="validation-messages" v-if="!selectedHSMCert.valid">
          <small class="form-text small">Certificate is expired</small>
        </span>
      </div>
    </div>

    <!-- HSM PIN required -->
    <div v-show="details.tokenType.$model === 'Hsm'" class="form-group row">
      <label class="col-form-label col-md-3">HSM PIN Required</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.hsmPinRequired.$model"
        ></p-check>
      </div>
    </div>

    <!-- Individual Payment Limit -->
    <div class="form-group row" :class="{invalid: details.paymentLimit.$error}">
      <label class="col-form-label col-md-3">Individual Payment Limit (£)</label>
      <div class="col-md-3">
        <input type="number" class="form-control" v-model.trim="details.paymentLimit.$model" />
        <!-- Validation -->
        <validation-messages v-model="details.paymentLimit" name="Individual payment limit">
          <small
            class="form-text small"
            v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
          >individual item limit can only be in whole pounds</small>
        </validation-messages>
        <small class="form-text text-muted">Set to 0 for no limit</small>
      </div>
    </div>

    <!-- Submission  Limit -->
    <div class="form-group row" :class="{invalid: details.submissionLimit.$error}">
      <label class="col-form-label col-md-3">Submission Limit (£)</label>
      <div class="col-md-3">
        <input type="number" class="form-control" v-model.trim="details.submissionLimit.$model" />
        <!-- Validation -->
        <validation-messages v-model="details.submissionLimit" name="Submission payment limit">
          <small
            class="form-text small"
            v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
          >submission item limit can only be in whole pounds</small>
        </validation-messages>
        <small class="form-text text-muted">Set to 0 for no limit</small>
      </div>
    </div>

    <!-- Is indirect submitter group -->
    <div v-if="false" class="form-group row">
      <label class="col-form-label col-md-3">Is Indirect Submitter Group</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.isIndirectSubmitterGroup.$model"
        ></p-check>
      </div>
    </div>

    <!-- Bank grade group -->
    <div v-show="bacsLicenceSettings.bankGrade === true" class="form-group row">
      <label class="col-form-label col-md-3">Bank Grade Group</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.bankGradeGroup.$model"
        ></p-check>
      </div>
    </div>

    <!-- Segregate Group Roles -->
    <div class="form-group row">
      <label class="col-form-label col-md-3">Segregate Group Roles</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.segregateGroupRoles.$model"
        ></p-check>
      </div>
    </div>

    <!-- Delete Payment Data -->
    <delete-payment-data :groupDetails="details" :disabled="disabled"></delete-payment-data>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import bankAccountSelect from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/BankAccountSelect'
import deletePaymentData from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/DeletePaymentData'

export default {
  props: ['details', 'disabled', 'status'],
  components: {
    bankAccountSelect,
    deletePaymentData
  },
  computed: {
    hasHsm () {
      return this.$store.getters.hasLicence('LicenceHSM')
    },
    selectedHSMCert () {
      var cert = this.hsmCertificates.find(c => c.value === this.details.certificateId.$model)

      if (cert == null) {
        return { valid: true }
      }

      return cert
    }
  },
  data () {
    return {
      hsmCertificates: [],
      importSchemas: [
        { value: 'None', text: 'None' },
        { value: 'Legacy', text: 'Legacy' },
        { value: 'Standard18', text: 'Standard 18' },
        { value: 'Mapping', text: 'Mapping' }
      ],
      bankAccounts: [],
      mappings: [],
      bacsLicenceSettings: {}
    }
  },
  async created () {
    if (!this.hasHsm) {
      this.details.tokenType.$model = 'SmartCard'
    }

    try {
      this.$Progress.start()
      var results = await Promise.allSettled([
        axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`, { showerror: true, errormessage: 'Failed to get bank accounts' }),
        axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showerror: true, errormessage: 'Failed to get licence' }),
        axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, { params: { paygateid: this.$store.state.common.paygateId }, showerror: true, errormessage: 'Failed to get customer certificates' }),
        axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingTitles/bacs`, { showerror: true, errormessage: 'Failed to get bacs mappings' })
      ])

      // Bank accounts
      this.bankAccounts = results[0].value.data.map(bankAccount => {
        return { value: bankAccount.bankAccountId, text: bankAccount.reference }
      })

      // Bacs Lincene
      this.bacsLicenceSettings = results[1].value.data.bacs

      // Certificates
      this.hsmCertificates = results[2].value.data.map(certificate => {
        var validTo = moment(certificate.validTo)

        var valid = true
        if (moment().isAfter(validTo)) {
          valid = false
        }

        return { value: certificate.certificateId, text: certificate.friendlyName, valid }
      })

      if (this.status === 'Create') {
        this.hsmCertificates = this.hsmCertificates.filter(c => c.valid)
      }

      // Mappings
      this.mappings = results[3].value.data.mappings.map(mapping => {
        return { value: mapping._id, text: mapping.title }
      })
    } catch {
      this.$Progress.fail()
    } finally {
      this.$Progress.finish()
    }
  }
}

</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
