var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Name")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.name))])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Description")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.description))])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Group Type")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.groupType))])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Service User Number")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.serviceUserNumber))])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Payment Siging Method")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.tokenType))])
          ])
        ])
      ]),
      _vm.group.details.tokenType == "Hsm"
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("HSM Certificate")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [_vm._v(_vm._s(_vm.group.details.friendlyName))])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Individual Payment Limit")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(_vm._s(_vm._f("number")(_vm.group.details.paymentLimit)))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Submission Payment Limit")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("number")(_vm.group.details.submissionLimit))
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Delete Payment Data")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            [
              _c("tick", {
                attrs: { coloured: false },
                model: {
                  value: _vm.group.details.deletePaymentData,
                  callback: function($$v) {
                    _vm.$set(_vm.group.details, "deletePaymentData", $$v)
                  },
                  expression: "group.details.deletePaymentData"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm.group.details.tokenType == "Hsm"
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("HSM PIN Required")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c(
                "div",
                { staticClass: "form-control-plaintext" },
                [
                  _c("tick", {
                    attrs: { coloured: false },
                    model: {
                      value: _vm.group.details.hsmPinRequired,
                      callback: function($$v) {
                        _vm.$set(_vm.group.details, "hsmPinRequired", $$v)
                      },
                      expression: "group.details.hsmPinRequired"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("group-config-users", { attrs: { users: _vm.group.users } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }