var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "validationWarnings" } }, [
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "vue-good-table",
            {
              attrs: {
                mode: "remote",
                columns: _vm.columns,
                rows: _vm.rows,
                totalRows: _vm.totalRecords,
                "pagination-options": {
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false,
                  setCurrentPage: _vm.serverParams.page
                },
                "sort-options": {
                  enabled: false
                },
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-per-page-change": _vm.onPerPageChange,
                "on-column-filter": _vm.onColumnFilter
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field === "messageSeverity"
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                staticClass: "badge badge-pill pull-left",
                                class: _vm.getSeverityClass(
                                  props.row.messageSeverity
                                )
                              },
                              [_vm._v(_vm._s(props.row.messageSeverity))]
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(props.formattedRow[props.column.field]) +
                                " "
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Shows a summary of the validation messages",
                          expression:
                            "'Shows a summary of the validation messages'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createPresubPdfReport($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-pdf pointer dimmedIcon"
                      })
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Export validation messages to CSV",
                          expression: "'Export validation messages to CSV'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createPresubCsvReport($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-excel pointer dimmedIcon"
                      })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }