<template>
  <div>
    <!-- Delete Payment Data -->
    <div class="form-group row">
      <label class="col-form-label col-md-3">Delete Payment Data</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="groupDetails.deletePaymentData.$model"
          @change="changeDeleteData()"
        ></p-check>
      </div>
    </div>
    <div v-show="groupDetails.showDeleteOptions">
      <div class="form-group row">
        <label class="col-form-label col-md-3">Delete historical submission data when saving</label>
        <div class="col-md-3">
          <p-check
            class="p-switch p-fill"
            color="primary"
            v-model="groupDetails.deleteHistoricalPayments.$model"
          ></p-check>
        </div>
      </div>
      <div v-show="groupDetails.deleteHistoricalPayments.$model" class="form-group row">
        <label class="label-control col-md-3">Delete data older than</label>
        <div class="col-md-2">
          <b-form-select v-model="groupDetails.paymentDeletionPeriod.$model" :options="timePeriods"></b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['groupDetails', 'disabled'],
  data () {
    return {
      timePeriods: [
        { value: '0', text: 'All' },
        { value: '3', text: '3 Months' },
        { value: '6', text: '6 Months' },
        { value: '12', text: '12 Months' }
      ]
    }
  },
  methods: {
    changeDeleteData () {
      this.groupDetails.showDeleteOptions = this.groupDetails.deletePaymentData.$model
    }
  },
  mounted () {
  }
}
</script>
