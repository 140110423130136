var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Day/Time Restrictions "),
                _c("help-icon", {
                  attrs: {
                    docPath: "/administration/securitypolicy/timerestrictions/"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _vm._m(0),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-3 offset-md-1" },
                  [_vm._v("Enforce Day/Time Restrictions")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.$v.policy.enforceRestrictions.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.enforceRestrictions,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.enforceRestrictions.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.policy.enforceRestrictions
                  ? _c(
                      "div",
                      { staticClass: "ml-5" },
                      [
                        _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-2 offset-md-1" }),
                          _c("div", { staticClass: "col-md-2" }, [
                            _vm._v("Start Time")
                          ]),
                          _c("div", { staticClass: "col-md-2" }, [
                            _vm._v("End Time")
                          ]),
                          _c("div", { staticClass: "col-md-2" }, [
                            _vm._v("Allow Day")
                          ])
                        ]),
                        _vm._l(_vm.$v.policy.daySettings.$each.$iter, function(
                          daysetting
                        ) {
                          return _c(
                            "div",
                            {
                              key: daysetting.day.$model,
                              staticClass: "row mb-2"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-2 offset-md-1" },
                                [_vm._v(_vm._s(daysetting.day.$model))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-2",
                                  class: {
                                    invalid: daysetting.timeStart.$error
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: daysetting.timeStart.$model,
                                        expression:
                                          "daysetting.timeStart.$model"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "time",
                                      step: "3600",
                                      disabled: !daysetting.allowDay.$model
                                    },
                                    domProps: {
                                      value: daysetting.timeStart.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          daysetting.timeStart,
                                          "$model",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "validation-messages",
                                    {
                                      model: {
                                        value: daysetting.timeStart,
                                        callback: function($$v) {
                                          _vm.$set(daysetting, "timeStart", $$v)
                                        },
                                        expression: "daysetting.timeStart"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "override" }, [
                                        !daysetting.timeStart.maxValue
                                          ? _c(
                                              "small",
                                              {
                                                staticClass: "form-text small"
                                              },
                                              [
                                                _vm._v(
                                                  "The start time cannot be later or the same as the end time"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-2",
                                  class: { invalid: daysetting.timeEnd.$error }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: daysetting.timeEnd.$model,
                                        expression: "daysetting.timeEnd.$model"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "time",
                                      step: "3600",
                                      disabled: !daysetting.allowDay.$model
                                    },
                                    domProps: {
                                      value: daysetting.timeEnd.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          daysetting.timeEnd,
                                          "$model",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "validation-messages",
                                    {
                                      model: {
                                        value: daysetting.timeEnd,
                                        callback: function($$v) {
                                          _vm.$set(daysetting, "timeEnd", $$v)
                                        },
                                        expression: "daysetting.timeEnd"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "override" }, [
                                        !daysetting.timeEnd.minValue
                                          ? _c(
                                              "small",
                                              {
                                                staticClass: "form-text small"
                                              },
                                              [
                                                _vm._v(
                                                  "The end time cannot be earlier or the same as the start time"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-2",
                                  class: {
                                    invalid: !_vm.$v.policy.daySettings
                                      .atLeastOne
                                  }
                                },
                                [
                                  _c("p-check", {
                                    on: { change: daysetting.$touch },
                                    model: {
                                      value: daysetting.allowDay.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          daysetting.allowDay,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "daysetting.allowDay.$model"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            class: {
                              invalid: !_vm.$v.policy.daySettings.atLeastOne
                            }
                          },
                          [
                            !_vm.$v.policy.daySettings.atLeastOne
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "offset-md-7 col-md-2 validation-messages"
                                  },
                                  [
                                    _c(
                                      "small",
                                      { staticClass: "form-text small" },
                                      [
                                        _vm._v(
                                          "You must have at least one day enabled"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("div", { staticClass: "col-md-10 offset-md-1" }, [
        _c("p", [
          _vm._v(
            "You can configure paygate to only allow users to login on certain days or between certain times of the day. To disallow the entire day uncheck the Day checkbox.To allow only part of a day check the day checkbox and set a start and end time. e.g. A start time of 0800 and and end time of 1730 will only allow a user to login between 0800 and 1730 for that day"
          )
        ]),
        _c("hr")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }