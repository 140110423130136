var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.watchedFolder.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("Watched Folder")
          ]),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.watchedFolder.$model,
                    expression: "details.watchedFolder.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", disabled: true },
                domProps: { value: _vm.details.watchedFolder.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.watchedFolder,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: {
                    name: "watched folder if any other settings have been set"
                  },
                  model: {
                    value: _vm.details.watchedFolder,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "watchedFolder", $$v)
                    },
                    expression: "details.watchedFolder"
                  }
                },
                [
                  _vm.details.watchedFolder.uniqueWatchedFolderValidator !==
                    null &&
                  _vm.details.watchedFolder.uniqueWatchedFolderValidator !==
                    undefined &&
                  !_vm.details.watchedFolder.uniqueWatchedFolderValidator
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v(
                          " This watched folder is already being used by another group "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "col-md-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.btnWatchedFolderBrowser($event)
                  }
                }
              },
              [_vm._v("...")]
            )
          ]),
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger pull-right",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.btnClearSettings($event)
                  }
                }
              },
              [_vm._v("Clear Settings")]
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.defaultSubmissionReference.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("Default Sub Reference")
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.details.defaultSubmissionReference.$model,
                  expression: "details.defaultSubmissionReference.$model",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", maxlength: "100" },
              domProps: {
                value: _vm.details.defaultSubmissionReference.$model
              },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.details.defaultSubmissionReference,
                    "$model",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.defaultContraNarrative.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("Default Contra Narrative")
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.details.defaultContraNarrative.$model,
                  expression: "details.defaultContraNarrative.$model",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.details.defaultContraNarrative.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.details.defaultContraNarrative,
                    "$model",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ]
      ),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-form-label col-md-3" }, [
          _vm._v("Stop on Duplicate File")
        ]),
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              model: {
                value: _vm.details.stopOnDuplicateFile.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.stopOnDuplicateFile, "$model", $$v)
                },
                expression: "details.stopOnDuplicateFile.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-form-label col-md-3" }, [
          _vm._v("Stop on Pre-sub Val Fix")
        ]),
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              model: {
                value: _vm.details.stopOnFix.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.stopOnFix, "$model", $$v)
                },
                expression: "details.stopOnFix.$model"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details.stopOnFix.$model,
                expression: "!details.stopOnFix.$model"
              }
            ],
            staticClass: "col-form-label col-md-3 required"
          },
          [_vm._v("Remove Fix Payments")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details.stopOnFix.$model,
                expression: "!details.stopOnFix.$model"
              }
            ],
            staticClass: "col-md-1"
          },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              on: {
                change: function($event) {
                  return _vm.removeFixPaymentsChange()
                }
              },
              model: {
                value: _vm.details.removeFixPayments.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.removeFixPayments, "$model", $$v)
                },
                expression: "details.removeFixPayments.$model"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.details.stopOnFix.$model &&
                _vm.details.removeFixPayments.$model,
              expression:
                "!details.stopOnFix.$model && details.removeFixPayments.$model"
            }
          ],
          staticClass: "form-group row"
        },
        [
          _c("div", { staticClass: "col-md-4" }),
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.details.removeFixPayments.$model === true,
                  expression: "details.removeFixPayments.$model === true"
                }
              ],
              staticClass: "col-form-label col-md-3 required"
            },
            [_vm._v("Removed Fix Action")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.details.removeFixPayments.$model === true,
                  expression: "details.removeFixPayments.$model === true"
                }
              ],
              staticClass: "col-md-2"
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.removedPaymentActions },
                model: {
                  value: _vm.details.removedFixesAction.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.removedFixesAction, "$model", $$v)
                  },
                  expression: "details.removedFixesAction.$model"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-form-label col-md-3" }, [
          _vm._v("Stop on Pre-sub Val Warning")
        ]),
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              model: {
                value: _vm.details.stopOnWarning.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.stopOnWarning, "$model", $$v)
                },
                expression: "details.stopOnWarning.$model"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details.stopOnWarning.$model,
                expression: "!details.stopOnWarning.$model"
              }
            ],
            staticClass: "col-form-label col-md-3 required"
          },
          [_vm._v("Remove Warning Payments")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details.stopOnWarning.$model,
                expression: "!details.stopOnWarning.$model"
              }
            ],
            staticClass: "col-md-1"
          },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              on: {
                change: function($event) {
                  return _vm.removeWarningPaymentsChange()
                }
              },
              model: {
                value: _vm.details.removeWarningPayments.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.removeWarningPayments, "$model", $$v)
                },
                expression: "details.removeWarningPayments.$model"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.details.stopOnWarning.$model &&
                _vm.details.removeWarningPayments.$model,
              expression:
                "!details.stopOnWarning.$model && details.removeWarningPayments.$model"
            }
          ],
          staticClass: "form-group row"
        },
        [
          _c("div", { staticClass: "col-md-4" }),
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.details.removeWarningPayments.$model === true,
                  expression: "details.removeWarningPayments.$model === true"
                }
              ],
              staticClass: "col-form-label col-md-3 required"
            },
            [_vm._v("Removed Warning Action")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.details.removeWarningPayments.$model === true,
                  expression: "details.removeWarningPayments.$model === true"
                }
              ],
              staticClass: "col-md-2"
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.removedPaymentActions },
                model: {
                  value: _vm.details.removedWarningsAction.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.removedWarningsAction, "$model", $$v)
                  },
                  expression: "details.removedWarningsAction.$model"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-form-label col-md-3" }, [
          _vm._v("Stop on Pre-sub Val Duplicate")
        ]),
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              model: {
                value: _vm.details.stopOnDuplicate.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.stopOnDuplicate, "$model", $$v)
                },
                expression: "details.stopOnDuplicate.$model"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details.stopOnDuplicate.$model,
                expression: "!details.stopOnDuplicate.$model"
              }
            ],
            staticClass: "col-form-label col-md-3 required"
          },
          [_vm._v("Remove Duplicate Payments")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details.stopOnDuplicate.$model,
                expression: "!details.stopOnDuplicate.$model"
              }
            ],
            staticClass: "col-md-1"
          },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { color: "primary" },
              on: {
                change: function($event) {
                  return _vm.removeDuplicatePaymentsChange()
                }
              },
              model: {
                value: _vm.details.removeDuplicatePayments.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.removeDuplicatePayments, "$model", $$v)
                },
                expression: "details.removeDuplicatePayments.$model"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.details.stopOnDuplicate.$model &&
                _vm.details.removeDuplicatePayments.$model,
              expression:
                "!details.stopOnDuplicate.$model && details.removeDuplicatePayments.$model"
            }
          ],
          staticClass: "form-group row"
        },
        [
          _c("div", { staticClass: "col-md-4" }),
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.details.removeDuplicatePayments.$model === true,
                  expression: "details.removeDuplicatePayments.$model === true"
                }
              ],
              staticClass: "col-form-label col-md-3 required"
            },
            [_vm._v("Removed Duplicate Action")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.details.removeDuplicatePayments.$model === true,
                  expression: "details.removeDuplicatePayments.$model === true"
                }
              ],
              staticClass: "col-md-2"
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.removedPaymentActions },
                model: {
                  value: _vm.details.removedDuplicatesAction.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.removedDuplicatesAction, "$model", $$v)
                  },
                  expression: "details.removedDuplicatesAction.$model"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.fileSaveFolder.$error }
        },
        [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("File Save Folder")
          ]),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.fileSaveFolder.$model,
                    expression: "details.fileSaveFolder.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", disabled: true },
                domProps: { value: _vm.details.fileSaveFolder.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.fileSaveFolder,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("validation-messages", {
                attrs: {
                  name:
                    "file save folder if any removed payments are saved to a new file"
                },
                model: {
                  value: _vm.details.fileSaveFolder,
                  callback: function($$v) {
                    _vm.$set(_vm.details, "fileSaveFolder", $$v)
                  },
                  expression: "details.fileSaveFolder"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "col-md-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.btnFileSaveFolderBrowser($event)
                  }
                }
              },
              [_vm._v("...")]
            )
          ])
        ]
      ),
      _vm.showWatchedFolderBrowser
        ? _c(
            "folderBrowser",
            {
              on: {
                close: function($event) {
                  return _vm.closeWatchedFolderPopup()
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Watched Folder Path")
              ])
            ]
          )
        : _vm._e(),
      _vm.showFileSaveFolderBrowser
        ? _c(
            "folderBrowser",
            {
              on: {
                close: function($event) {
                  return _vm.closeFileSaveFolderPopup()
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("File Save Path")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }