var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "importingPayments" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-progress",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        striped: "",
                        animated: true,
                        max: _vm.max,
                        "show-progress": ""
                      }
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.progress,
                          label: _vm.progress + "%"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row form-group" }, [
              _vm._v(
                " Importing file " +
                  _vm._s(_vm.fileImportedCount + _vm.fileErrorCount) +
                  " of " +
                  _vm._s(_vm.totalFiles) +
                  ": " +
                  _vm._s(_vm.currentFilename) +
                  " "
              ),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.filePendingCount) + " files remaining "),
              _c("br"),
              _vm._v(
                " " + _vm._s(_vm.fileErrorCount) + " files cannot be imported "
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Importing payment files, please wait...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }