var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-form-label col-md-3" }, [
        _vm._v("Delete Payment Data")
      ]),
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("p-check", {
            staticClass: "p-switch p-fill",
            attrs: { color: "primary", disabled: _vm.disabled },
            on: {
              change: function($event) {
                return _vm.changeDeleteData()
              }
            },
            model: {
              value: _vm.groupDetails.deletePaymentData.$model,
              callback: function($$v) {
                _vm.$set(_vm.groupDetails.deletePaymentData, "$model", $$v)
              },
              expression: "groupDetails.deletePaymentData.$model"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.groupDetails.showDeleteOptions,
            expression: "groupDetails.showDeleteOptions"
          }
        ]
      },
      [
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-form-label col-md-3" }, [
            _vm._v("Delete historical submission data when saving")
          ]),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("p-check", {
                staticClass: "p-switch p-fill",
                attrs: { color: "primary" },
                model: {
                  value: _vm.groupDetails.deleteHistoricalPayments.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.groupDetails.deleteHistoricalPayments,
                      "$model",
                      $$v
                    )
                  },
                  expression: "groupDetails.deleteHistoricalPayments.$model"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.groupDetails.deleteHistoricalPayments.$model,
                expression: "groupDetails.deleteHistoricalPayments.$model"
              }
            ],
            staticClass: "form-group row"
          },
          [
            _c("label", { staticClass: "label-control col-md-3" }, [
              _vm._v("Delete data older than")
            ]),
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("b-form-select", {
                  attrs: { options: _vm.timePeriods },
                  model: {
                    value: _vm.groupDetails.paymentDeletionPeriod.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.groupDetails.paymentDeletionPeriod,
                        "$model",
                        $$v
                      )
                    },
                    expression: "groupDetails.paymentDeletionPeriod.$model"
                  }
                })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }