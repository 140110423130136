const ContactSupport = () => import(/* webpackChunkName: "about-contact-support" */ '@/Components/About/ContactSupport')
const PrivacyPolicy = () => import(/* webpackChunkName: "about-privacy-policy" */ '@/Components/About/PrivacyPolicy')
const About = () => import(/* webpackChunkName: "about" */ '@/Components/About/About')
const Cookie = () => import(/* webpackChunkName: "about-cookie" */ '@/Assets/Components/Cookies/CookiePolicy')
const Team = () => import(/* webpackChunkName: "about-team" */ '@/Components/About/Team')

export default [
  {
    name: 'ContactSupport',
    path: '/about/contactsupport',
    component: ContactSupport,
    meta: {
      title: 'Contact Support'
    }
  },
  {
    name: 'PrivacyPolicy',
    path: '/about/privacypolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy'
    }
  },
  {
    name: 'About',
    path: '/about/about',
    component: About,
    meta: {
      title: 'About'
    }
  },
  {
    name: 'Cookies',
    path: '/Cookies',
    component: Cookie,
    meta: {
      title: 'Cookies'
    }
  },
  {
    name: 'Team',
    path: '/about/team',
    component: Team,
    meta: {
      title: 'Team'
    }
  },
  {
    name: 'Help',
    path: 'about/help',
    beforeEnter () { window.open(process.env.VUE_APP_DOCUMENTATION_ROOT_URL, '_blank') }
  },
  {
    name: 'UUDDLRLRBA',
    path: '/about/UUDDLRLRBA',
    component: Team,
    meta: {
      title: 'Team'
    }
  }

]
