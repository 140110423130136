<template>
  <div id="downloadBureauReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              <h2>
                Download {{ this.networkName }} Reports
                <span class="pull-right">
                  <a href="#" target="_blank">
                    <i class="far fa-question-circle"></i>
                  </a>
                </span>
                <favourite-icon></favourite-icon>
              </h2>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Select a Group</strong>
              </div>
              <div class="col-sm-3">
                <group-select
                  v-model="groupId"
                  :groups="groups"
                  @input="changeGroup()"
                  :clearable="false"
                  :disabled="this.isLoggedIn"
                ></group-select>
              </div>

              <div v-if="isLoggedIn === false" class="row form-group">
                <div class="col-sm-3">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="loginStart"
                    :disabled="this.enableLogin === false"
                  >Login</button>
                </div>
                <div class="col-md-1">
                  <span style="font-size: 200%" class="fa fa-unlock"></span>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-light"
                    v-on:click="viewVocalinkCertificate"
                    :disabled="this.enableLogin === false"
                  >Vocalink Certificate</button>
                </div>
              </div>
              <div v-else>
                <div class="row form-group">
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-primary"
                      v-on:click="logoff"
                      :disabled="this.loggingIn === true"
                    >Log Off</button>
                  </div>
                  <div class="col-md-3">
                    <span style="font-size: 200%" class="fa fa-lock"></span>&nbsp;&nbsp;Securely logged into Vocalink
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="btn btn-light"
                      v-on:click="viewVocalinkCertificate"
                      :disabled="this.loggingIn === true"
                    >Vocalink Certificate</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="BureauCustomersLoaded === true && isLoggedIn === true"
              class="row form-group"
            >
              <div class="row form-group col-md-12">
                <div class="col-md-2">Bureau Customers</div>
                <div class="col-md-3">
                  <vue-select
                    multiple
                    v-model="SelectedBureauCustomers"
                    label="name"
                    :options="BureauCustomersDisplay"
                    :closeOnSelect="false"
                    @input="CheckBureauCustomerDropdownEntries"
                  ></vue-select>
                </div>
              </div>
            </div>

            <br />
            <div v-if="isLoggedIn === true">
              <div class="row form-group">
                <div class="col-md-2">Report Types</div>
                <div class="col-md-3">
                  <b-select v-model.trim="selectedReportType" :options="reportTypes" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2">Report Period</div>
                <div class="col-md-3">
                  <b-select v-model.trim="selectedReportPeriod" :options="reportPeriods" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2">Previously Accessed</div>
                <div class="col-md-3">
                  <b-select v-model.trim="selectedReportAccessed" :options="reportAccessed" />
                </div>
              </div>
              <br />
              <div class="row form-group">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="getReportList"
                    :disabled="this.enableGetReportList === false"
                  >Get Report List</button>
                </div>
              </div>
              <div v-if="haveReportList === true" class="row form-group">
                <div class="col-md-12">
                  <vue-good-table
                    ref="reportList"
                    @on-cell-click="viewReportModal"
                    :columns="columns"
                    :rows="rows"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionInfoClass: 'report-row-selected',
                      clearSelectionText: ''
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: 10,
                      dropdownAllowAll: false
                    }"
                    styleClass="vgt-table striped bordered"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'viewReportXml'">
                        <span class="grid-link">XML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportHtml'">
                        <span class="grid-link">HTML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportCsv'">
                        <span class="grid-link">CSV</span>
                      </span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                    <div slot="selected-row-actions" style="width: 800px;">
                      <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-3">Download Format</div>
                        <div class="col-md-2">
                          <b-select
                            v-model.trim="selectedDownloadFormat"
                            :options="downloadFormats"
                          />
                        </div>
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="btn btn-primary pull-right"
                            v-on:click="downloadReports"
                            :disabled="this.selectedDownloadFormat === ''"
                          >Download</button>
                        </div>
                      </div>
                    </div>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="eSigner" v-show="showeSigner">
      <span v-html="eSignerHtml"></span>
    </div>
    <div>
      <viewReportModal
        v-model="isViewReportModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import ViewReportModal from '@/Views/Bacs/BacsReports/ViewReportModal.vue'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import VueSelect from 'vue-select'

export default {
  components: {
    plugin: Plugin,
    viewReportModal: ViewReportModal,
    VueSelect
  },
  data () {
    return {
      // loading: true,
      groups: [],
      group: {},
      groupId: null,
      networkType: '',
      networkName: '',
      paygateId: '',
      browser: '',
      BUREAUGROUPTYPE: '1',
      vocalinkCertificate: {},
      // Bureau customer selection
      BureauCustomersLoaded: false,
      SelectedBureauCustomers: [],
      BureauCustomers: [],
      BureauCustomersDisplay: [],

      // Report Specific Variables
      reportTypes: {},
      reportPeriods: {},
      reportAccessed: {},
      downloadFormats: {},
      selectedReportType: '',
      selectedReportPeriod: '',
      selectedReportAccessed: '',
      selectedDownloadFormat: '',
      selectedRetrievalUrl: '',
      eSignerHtml: '',
      selectedReportFormat: 0,
      haveReportList: false,
      isViewReportModalVisible: false,
      showeSigner: false,
      reportContent: '',
      reportDownload: {},
      // Table variables - may need to change as just nicked em
      columns: [
        {
          label: 'Report Name',
          field: 'reportName'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Generated',
          field: 'formattedGenerationTimestamp'
        },
        {
          label: 'Processing Date',
          field: 'formattedProcessingDay'
        },
        {
          label: 'Prev Accessed',
          field: 'previouslyAccessed'
        },
        {
          label: 'Retrieval Url',
          field: 'retrievalUrl',
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportXml'
        },
        {
          label: 'View Report',
          field: 'viewReportHtml'
        },
        {
          label: 'View Report',
          field: 'viewReportCsv'
        }
      ],
      rows: [],
      // Login to Vocalink Variables
      isLoggedIn: false,
      loggingIn: false,
      isLoaded: false,
      base64Signature: '',
      licenceSetting: '',
      canProceed: true,
      pluginUpdateNotInstalled: false,
      authResponse: '',
      userPin: ''
    }
  },
  watch: {
    isLoggedIn: function () {
      if (this.isLoggedIn === true) {
        this.getBureauCustomers()
      }
    },
    SelectedBureauCustomers: function () {
      console.log('SelectedBureauCustomers changed', this.SelectedBureauCustomers)
    }
  },

  async created () {
  },
  computed: {
    enableLogin: function () {
      return this.isLoaded === true &&
        this.groupId !== '' &&
        this.loggingIn === false &&
        this.canProceed === true &&
        this.pluginUpdateNotInstalled === false
    },

    enableGetReportList: function () {
      return this.selectedReportType !== '' &&
        this.selectedReportPeriod !== '' &&
        this.selectedReportAccessed !== ''
      //        && this.isLoading === false
      // this.loading === false
    }

  },
  methods: {
    CheckBureauCustomerDropdownEntries (value) {
      console.log('CheckBureauCustomerDropdownEntries value', value)
      var p = this.SelectedBureauCustomers.length - 1
      var bureauCustomerIdAdded = this.SelectedBureauCustomers[p].id
      if (bureauCustomerIdAdded === 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF' || bureauCustomerIdAdded === '00000000-0000-0000-0000-000000000000') {
        console.log('special case - add that one and disable the rest')
        this.SelectedBureauCustomers = this.SelectedBureauCustomers.slice(this.SelectedBureauCustomers.length - 1)
        console.log('this.SelectedBureauCustomers after slice', this.SelectedBureauCustomers)

        // disable all other entries
        var i = 0
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === bureauCustomerIdAdded) {
            this.BureauCustomersDisplay[i].disabled = false
          } else {
            this.BureauCustomersDisplay[i].disabled = true
          }
        }
      } else {
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF' || this.BureauCustomersDisplay[i].id === '00000000-0000-0000-0000-000000000000') {
            this.BureauCustomersDisplay[i].disabled = true
          } else {
            this.BureauCustomersDisplay[i].disabled = false
          }
        }
        if (p > 0 && (this.SelectedBureauCustomers[0].id === 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF' || this.SelectedBureauCustomers[0].id === '00000000-0000-0000-0000-000000000000')) {
          this.SelectedBureauCustomers.shift()
        }
      }
      console.log('this.BureauCustomersDisplay after slice', this.BureauCustomersDisplay)
    },

    async getServiceNumbersToDownload () {
      console.log('getServiceNumbersToDownload start')
      if (this.SelectedBureauCustomers.length === 0) {
        return ''
        //  return this.group.groupTypeDetails.serviceUserNumber
      } else {
        var sunList = []
        var index
        for (index = 0; index < this.SelectedBureauCustomers.length; ++index) {
          if (sunList.indexOf(this.SelectedBureauCustomers[index].serviceUserNumber) === -1) {
            sunList.push(this.SelectedBureauCustomers[index].serviceUserNumber)
          }
        }
        var sunListCSV = sunList.toString()
        return sunListCSV
      }
    },
    async getBureauCustomers () {
      var query = {
        params: {
          fields: 'name,serviceusernumber,bureaucustomerid'
        }
      }
      query.params.paygateId = this.$store.state.common.paygateId
      query.params.sort = 'name asc'
      query.params.bureauCustomerList = 'bureauCustomerList'
      console.log('getBureauCustomers', query)

      await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', query, { showload: true })
        .then(response => {
          console.log('getBureauCustomers response', response)
          this.BureauCustomers = response.data.data
          this.BureauCustomersDisplay = [] // reset the display array
          var BureauCustomerIndirectOption = {
            id: '00000000-0000-0000-0000-000000000000',
            serviceUserNumber: '',
            name: 'Certificate Level Reports',
            disabled: false
          }
          var BureauLevelReportOption = {
            id: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
            serviceUserNumber: this.group.groupTypeDetails.serviceUserNumber,
            name: 'BureauLevelReports',
            disabled: false
          }
          this.BureauCustomersDisplay.push(BureauCustomerIndirectOption)
          this.BureauCustomersDisplay.push(BureauLevelReportOption)

          var index = 0
          for (index = 0; index < this.BureauCustomers.length; ++index) {
            var currentBC = {
              id: this.BureauCustomers[index].bureauCustomerId,
              serviceUserNumber: this.BureauCustomers[index].serviceUserNumber,
              name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
              disabled: false
            }
            this.BureauCustomersDisplay.push(currentBC)
          }
          console.log('BureauCustomerDisplay', this.BureauCustomersDisplay)
          this.BureauCustomersLoaded = true
        })
        .catch(error => console.error(error))

      console.log('getBureauCustomers bureaucustomers', this.BureauCustomers)
    },
    closeViewReport () {
      this.isViewReportModalVisible = false
    },

    async getGroupsForUser () {
      try {
        console.log('Getting groups')
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaubacsreports/getgroupsforuser', { showload: true })
        if (response.data.bureauBacsReportGroups.length > 0) {
          this.groups = response.data.bureauBacsReportGroups
        } else {
          this.groups = []
        }
      } catch (e) {
        console.log('catch block for getgroupsforuser')
      }
    },
    async changeGroup (groupValue) {
      this.groupSelected = true
      this.group = this.groups.find(i => i.groupId === this.groupId)
      this.$store.commit('setSelectedGroup', this.groupId)
      await this.getSelectedGroupDetails()

      // // To show group bank details if user clicks "View" button.
      // var payload = { 'paygateId': this.paygateId, 'bankAccountId': this.bacsGroup.groupTypeDetails.bankAccountId, 'platformUrl': this.$platformApiBaseUrl }
      // this.$store.dispatch('getGroupBankAccount', payload)
      this.loggingIn = false
    },
    async viewVocalinkCertificate () {
      if (this.vocalinkCertificate.issuer === undefined) {
        var success = await this.getVocalinkCertificate()
        if (success === true) {
          this.$swal({
            title: 'Vocalink Certificate',
            html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
            type: 'info',
            animation: false
          })
        }
      } else {
        this.$swal({
          title: 'Vocalink Certificate',
          html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
          type: 'info',
          animation: false
        })
      }
    },
    async getVocalinkCertificate () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getVocalinkCertificate?networkType=` + this.networkType, { showload: true })
      if (response.data.success === true) {
        this.vocalinkCertificate = response.data.vocalinkCertificate
      } else {
        this.$toastr.e(response.data.errorMessage)
      }

      return response.data.success
    },
    async logoff () {
      var json = JSON.stringify({
        networkType: this.networkType,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogoff',
        data: json,
        showload: true
      })
      console.log('logoffResponse', response)
      this.authResponse = response.data
      this.isLoggedIn = false
      this.loggingIn = false
      this.isLoaded = true
      this.canProceed = true
      this.haveReportList = false
    },

    async getSelectedGroupDetails () {
      console.log('getSelectedGroupDetails groupId', this.groupId)
      if (this.groupId === undefined || this.groupId === null) {
        return
      }
      await this.$store.dispatch('getSubBacsGroup', this.groupId)
      this.group = this.$store.getters.bacsGroup
    },
    async viewReportModal (params) {
      console.log('params: ' + JSON.stringify(params))
      var cellClicked = params.column.field
      console.log('cellClicked: ' + cellClicked)

      if (cellClicked === 'viewReportXml' || cellClicked === 'viewReportHtml' || cellClicked === 'viewReportCsv') {
        if (this.networkType === 'BacstelIp') {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 0
          } else {
            this.selectedReportFormat = 1
          }
        } else {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 2
          } else {
            this.selectedReportFormat = 6
          }
        }

        console.log('selectedReportFormat: ' + this.selectedReportFormat)

        // this.loading = true
        var json = JSON.stringify({
          networkType: this.networkType,
          downloadUrl: params.row.retrievalUrl,
          downloadFormat: this.selectedReportFormat,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
          data: json,
          showload: true
        })

        // this.loading = false
        this.reportContent = response.data
        this.isViewReportModalVisible = true
      }
    },
    async downloadReports () {
      console.log('downloadReports')
      // this.loading = true
      var selectedReports = this.$refs.reportList.selectedRows
      var selectedReportUrls = []
      for (var i = 0; i < selectedReports.length; i++) {
        var tmpUrl = selectedReports[i].retrievalUrl
        console.log('retrieval url: ' + tmpUrl)
        selectedReportUrls.push(tmpUrl)
      }
      console.log('selected reports: ' + JSON.stringify(selectedReportUrls))

      var json = JSON.stringify({
        networkType: this.networkType,
        downloadUrls: selectedReportUrls,
        downloadFormat: this.selectedDownloadFormat,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadReports',
        data: json,
        responseType: 'blob',
        showload: true
      })

      // this.loading = false
      const blob = new Blob([response.data])
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']
      console.log('disposition: ' + disposition)
      console.log('headers: ' + JSON.stringify(response.headers))

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    },
    async saveBacsReportUserPreferences () {
      console.log('saveBacsReportUserPreferences')
      var json = JSON.stringify({
        networkType: this.networkType,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        groupId: this.groupId
      })
      console.log('saveBacsReportUserPreferences json', json)
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/saveBacsReportUserPreferences',
        data: json,
        showload: true
      })
    },
    // THESE METHODS BORROWED, I MEAN, RIPPED OFF FROM MARK
    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getDropdownValues?networkType=` + this.networkType, { showload: true })
      this.reportTypes = response.data.reportTypeLookups
      this.reportPeriods = response.data.periodLookups
      this.reportAccessed = response.data.accessedLookups
      this.downloadFormats = response.data.downloadFormatLookups
    },

    async getBacsReportUserPreferences (ignoreGroup) {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getBacsReportUserPreferences?networkType=` + this.networkType, { showload: true })

      if (response.data !== null) {
        this.selectedReportType = response.data.reportType
        this.selectedReportPeriod = response.data.reportPeriod
        this.selectedReportAccessed = response.data.reportAccessed

        if (ignoreGroup === false) {
          this.groupId = response.data.groupId
          this.changeGroup()
        }
      } else {
        this.selectedReportType = this.reportTypes[0].value
      }
    },
    async loginStart () {
      console.log('loginStart')
      // this.loading = true
      if (this.group.groupTypeDetails.tokenType === 'SmartCard') {
        // Check for web extension and plugin...
        try {
          console.log('check plugin')
          await this.$store.commit('setDeviceType', 'SmartCard')
          await this.$store.dispatch('checkPlugin')
          if (this.pluginUpdateNotInstalled === false) {
            await this.signChallenge()
          }
        } catch (error) {
          this.$toastr.w(error.message, 'Warning')
        }
      } else {
        await this.signChallenge()
      }
      // this.loading = false
    },

    async signChallenge () {
      console.log('signChallenge')
      this.isLoggedIn = false
      this.loggingIn = true
      this.canProceed = false
      this.canCancel = false

      await this.getVocalinkLoginChallenge()

      if (this.authResponse.success === true) {
        // Sign either with HSM or smartcard depending on the submission group.
        this.group = this.$store.getters.bacsGroup
        if (this.group.groupTypeDetails.tokenType !== 'SmartCard') {
          this.signChallengeWithHsm()
        } else {
          var useeSigner = this.$store.getters.getUseeSigner
          if (useeSigner === false) {
            if (this.browser === 'IE') {
              this.signWithActiveXPlugin()
            } else {
              this.startSignNonActiveXPlugin()
            }
          } else {
            this.signWitheSigner()
          }
        }
      } else {
        this.loggingIn = false
      }
    },

    async getVocalinkLoginChallenge () {
      // this.loading = true
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/comms/getVocalinkLoginChallenge?networkType=' + this.networkType + '&groupId=' + this.groupId, { showload: true })
      this.authResponse = response.data
      console.log('auth response: ' + JSON.stringify(this.authResponse))
      if (this.authResponse.success === true) {
        this.vocalinkCertificate = this.authResponse.vocalinkCertificate
      } else {
        if (!this.authResponse.licenceError) {
          this.$toastr.e(this.authResponse.errorMessage)
        } else {
          this.$swal({
            title: 'Licence Warning',
            text: this.authResponse.errorMessage,
            type: 'warning'
          })
        }
      }

      // this.loading = false
    },

    async callHsm () {
      console.log('callHsm')
      var thumbprint = ''
      var paygateType = bacsCommon.getPaygateType(this.group.groupType)
      console.log('signChallengeWithHSM paygateType', paygateType)

      if (paygateType !== 'Online') {
        thumbprint = this.group.groupTypeDetails.certificate.thumbprint
      }

      var json = JSON.stringify({
        certificateThumbprint: thumbprint,
        loginChallenge: this.authResponse.loginChallenge,
        isPaygateOnline: paygateType === 'Online',
        userPin: this.userPin,
        hsmPinRequired: this.group.groupTypeDetails.hsmPinRequired
      })
      console.log('signChallengeWithHSM json', json)
      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithHsm',
        data: json,
        showload: true
      })
      console.log('signChallengeWithHSM Response', response)
      return response.data
    },

    async signChallengeWithHsm () {
      console.log('signChallengeWithHsm pin required', this.group.groupTypeDetails.hsmPinRequired)
      // await this.hsmPreSigning()
      // await this.showVocalinkChallenge()
      if (this.group.groupTypeDetails.hsmPinRequired) {
        this.userPin = await bacsCommon.showVocalinkChallengePin(this.authResponse.loginChallenge)
        console.log('HSM PIN: ' + this.hsmPin)
      } else {
        await bacsCommon.showVocalinkChallengeNoPin(this.authResponse.loginChallenge)
      }

      // this.loading = true
      var signResponse = await this.callHsm()
      console.log('signResponse', signResponse)

      if (signResponse.success === true) {
        this.base64Signature = signResponse.tag
        await this.vocalinkLogin()

        if (this.authResponse.success === true) {
          this.isLoggedIn = true
        } else {
          this.$toastr.e(this.authResponse.errorMessage)
        }
      } else {
        this.$toastr.e(signResponse.errorMessage)
        this.loggingIn = false
        this.canProceed = true
      }

      // this.loading = false
    },

    async signWithActiveXPlugin () {
      console.log('signWithActiveXPlugin')
      this.plugin = document.PayGatePlugin
      this.plugin.InitSmartCardSigning()
      this.plugin.SubmissionLength = this.authResponse.loginChallenge.length
      this.plugin.SubmissionData = this.authResponse.loginChallenge
      this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '')
      if (this.base64Signature.indexOf('Error') === -1) {
        // this.loading = true
        await this.vocalinkLogin()
        // this.loading = false
        if (this.authResponse.success === true) {
          this.isLoggedIn = true
        } else {
          this.loggingIn = false
          this.$toastr.e(this.authResponse.errorMessage)
          this.canCancel = true
          this.canProceed = true
        }
      } else {
        this.loggingIn = false
        this.$toastr.e(this.base64Signature)
        this.canCancel = true
        this.canProceed = true
        this.isLoggedIn = false
        this.loggingIn = false
      }
    },

    async startSignNonActiveXPlugin () {
      console.log('startSignNonActiveXPlugin')
      if (this.browser === 'Chrome') {
        this.processSignatureChrome()
      } else if (this.browser === 'Firefox') {
        this.processSignatureFirefox()
      }
    },

    processSignatureFirefox () {
      console.log('processSignatureFirefox')
      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          // console.log('pluginResponse: ' + pluginResponse)
          if (pluginResponse.Base64Signature !== null) {
            this.base64Signature = pluginResponse.Base64Signature
            this.vocalinkLogin()
            if (this.authResponse.success === true) {
              this.isLoggedIn = true
            } else {
              this.$toastr.e(this.authResponse.errorMessage)
            }
            resolve()
          } else {
            this.canCancel = true
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
            reject(new Error(pluginResponse.ErrorMessage))
          }
        }, { once: true })

        var pluginEvent = new CustomEvent('clientCallPlugin', { detail: { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge } })
        document.dispatchEvent(pluginEvent)
      })
    },

    async loadLicence () {
      console.log('loadLicence start')
      console.log('loadLicence url', process.env.VUE_APP_PLATFORM_API_URL + 'licence')

      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
      console.log('response from load license', response)
      console.log('loadLicence networkType', this.networkType)
      if (this.networkType === 'BacstelIp') {
        this.licenceSetting = response.data.bacs.value
      } else {
        this.licenceSetting = response.data.fps.value
      }
    },

    async checkLicence () {
      var licenceOk = true
      console.log('this.licenceSetting', this.licenceSetting)
      await this.loadGroups(this.BUREAUGROUPTYPE)
      return licenceOk
    },

    async loadGroups (groupType) {
      console.log('grouptype in load groups', groupType)
      var payload = { paygateId: this.paygateId, groupType: this.BUREAUGROUPTYPE, groupRole: ['GroupAdministrator'] }
      // var payload = { 'paygateId': this.paygateId, 'groupType': 'bureau' }
      await this.$store.dispatch('getGroups', payload)
      this.groups = this.$store.getters.groups
      console.log('this.groups from loadgroups', this.groups)
    },
    processSignatureChrome () {
      console.log('processSignatureChrome')
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge }, (response) => {
          var pluginResponse = common.parsePluginResponse(response.response)
          // console.log('pluginResponse: ' + JSON.stringify(pluginResponse))
          if (pluginResponse.Base64Signature !== null) {
            this.base64Signature = pluginResponse.Base64Signature
            this.vocalinkLogin()
            if (this.authResponse.success === true) {
              this.isLoggedIn = true
            } else {
              this.$toastr.e(this.authResponse.errorMessage)
            }
          } else if (pluginResponse.ErrorMessage !== null) {
            this.$toastr.e(pluginResponse.ErrorMessage)
            this.canCancel = true
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
          }
        })
        resolve()
      })
    },

    async signWitheSigner () {
      console.log('Download Reports signWitheSigner')
      var cookies = this.authResponse.cookieCollection
      var domain = ''
      var name = ''
      var value = ''
      if (cookies.length > 0) {
        var cookie = cookies[0]
        domain = cookie.domain
        name = cookie.name
        value = cookie.value
      }

      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWitheSigner?submissionId=00000000-0000-0000-0000-000000000000&actionId=00000000-0000-0000-0000-000000000000'
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&cookieName=' + name
      url += '&cookieValue=' + value
      url += '&cookieDomain=' + domain
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.groupId

      var response = await axios.get(url, { showload: true })
      this.eSignerHtml = response.data
      this.showeSigner = true
    },

    async geteSignerSignature () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/geteSignerSignature?submissionId=` + this.submissionId, { showload: true })
      this.base64Signature = response.data
      await this.vocalinkLogin()
      // this.loading = false
      if (this.authResponse.success === true) {
        this.isLoggedIn = true
      } else {
        this.$toastr.e(this.authResponse.errorMessage)
      }
    },

    async vocalinkLogin () {
      var json = JSON.stringify({
        networkType: this.networkType,
        base64Signature: this.base64Signature,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogin',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.loggingIn = false
    },

    async getReportList () {
      console.log('getReportList start')
      // this.loading = true
      this.rows = []
      var servicenumber = await this.getServiceNumbersToDownload()
      console.log('servicenumber', servicenumber)
      var json = JSON.stringify({
        networkType: this.networkType,
        serviceUserNumber: servicenumber,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        authResponse: this.authResponse
      })
      console.log('getReportList json', json)

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/getReportList',
        data: json,
        showload: true
      })
      console.log('getReportList response', response)

      if (response.data.success === true) {
        this.rows = response.data.availableReports.report
        this.haveReportList = true

        if (this.networkType === 'BacstelIp') {
          this.columns[8].hidden = true
        } else {
          this.columns[7].hidden = true
        }

        await this.saveBacsReportUserPreferences()
      } else {
        this.$toastr.e(response.data.errorMessage)
      }
      // this.loading = false
    },

    pluginNotInstalled () {
      this.pluginUpdateNotInstalled = true
    }

  },

  // First load
  async mounted () {
    // this.loading = true
    this.networkType = this.$route.query.networkType
    this.networkName = this.networkType === 'BacstelIp' ? 'Bureau BACS' : 'Bureau FPS'
    this.browser = bacsCommon.getBrowser()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    console.log('this.browser', this.browser)
    console.log('this.paygateId', this.paygateId)
    // MAY NEED TO PUT A GROUP TYPE HERE
    await this.loadLicence()

    if (!await this.checkLicence()) {
      console.log('check licence failed')
      // this.loading = false
      return
    }
    console.log('check license finished')
    await this.getEnums()
    console.log('start of getsignature')

    // Check if redirected here after signing with eSigner.
    var getSignature = this.$route.query.getSignature
    console.log('end of getsignature')
    console.log('getSignature', getSignature)
    console.log('start of extLoaded')
    var extLoaded = this.$route.query.pluginAccessible
    console.log('end of extLoaded')
    console.log('extLoaded', extLoaded)
    if (getSignature === 'true') {
      console.log('getSignature')
      // this.loading = true
      this.authResponse = {}
      var cookie = {}
      cookie.name = this.$route.query.cookieName
      cookie.value = this.$route.query.cookieValue
      cookie.domain = this.$route.query.cookieDomain

      var cookieCollection = []
      cookieCollection.push(cookie)
      this.authResponse.cookieCollection = cookieCollection
      this.authResponse.cookieValueIsBase64 = true
      this.authResponse.loginChallenge = this.$route.query.challenge

      // TEMP ID just to get signature from database.
      this.submissionId = this.$route.query.submissionId
      this.networkType = this.$route.query.networkType
      this.groupId = this.$route.query.groupId

      await this.geteSignerSignature()
      await this.getBacsReportUserPreferences(true)
      this.changeGroup()
      // this.loading = false
    } else if (extLoaded === 'true') {
      // console.log('extLoaded: ' + extLoaded)
      if (this.browser === 'Firefox') {
        document.addEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
        document.addEventListener('getPluginResponseWebPage', this.initPluginEvent)
      }

      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true

      this.groupId = this.$store.getters.selectedGroup
      if (this.selectedGroupId !== null && this.groupId !== undefined) {
        await this.getBacsReportUserPreferences(true)
        await this.changeGroup()
        this.loginStart()
      }
    } else if (extLoaded === 'false') {
      this.isLoggedIn = false
      this.isLoaded = true
      this.groupId = this.$store.getters.selectedGroup

      if (this.groupId !== null && this.groupId !== undefined) {
        await this.getBacsReportUserPreferences(true)
        await this.changeGroup()
      }
    } else {
      console.log('getBacsReportUserPreferences start')
      await this.getBacsReportUserPreferences(false)
      this.isLoggedIn = false
      this.isLoaded = true
    }

    this.numberOfSubmissionBytes = 1 // Not signing a submission but want to use eSigner if no plugin loaded when using IE.
    // this.loading = false
  }
}
</script>
