<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Show import progress for '{{ this.submission.submissionReference }}'
        <help-icon></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <!-- <div class="ml-5 mt-3">
      <b-row>Job Id = {{ this.submission.jobId }}</b-row>
      <b-row>Bureau Submission Id = {{ this.submission.bureauSubmissionId }}</b-row>
      <b-row>Group Id = {{ this.submission.groupId }}</b-row>
      <b-row>Submission Reference = {{ this.submission.submissionReference }}</b-row>
      <b-row>Contra Narrative = {{ this.submission.contraNarrative }}</b-row>
      <b-row>Payment Date = {{ this.submission.paymentDate }}</b-row>
      <b-row>Processing Date = {{ this.submission.processingDate }}</b-row>
      <b-row>Paygate Id = {{ this.paygateId }}</b-row>
    </div>-->

    <div class="idb-block-content">
      <vue-good-table
        ref="paymentFileImportProgress"
        mode="remote"
        :columns="columns"
        :rows="bureauSubmissionDetails"
        :lineNumbers="true"
        :totalRows="totalRecords"
        :search-options="{
            enabled: false
            }"
        :pagination-options="{
            enabled: false
          }"
        styleClass="vgt-table striped bordered"
      >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">No payment files in job</div>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Status'">
            <div class="percentageContainer">
              <div
                class="percentage"
                :class="buildPercentageBarClass(props.row.percentComplete)"
                :style="{ width: props.row.percentComplete +'%' }"
              >&nbsp;</div>
              <div
                class="percentageLabel"
                v-if="props.row.percentComplete == 0"
              >{{ props.row.status }}</div>
              <div
                class="percentageLabel"
                v-if="props.row.percentComplete != 0"
              >{{ props.row.percentComplete }}% {{ props.row.status }}</div>
            </div>
          </span>
          <span v-else-if="props.column.label === 'Issues'">
            <div>
              <a>
                <i
                  v-if="props.row.importMessages != undefined && props.row.importMessages.length > 0"
                  class="fa fa-exclamation-triangle"
                  style="padding: 0px; 0px"
                  v-b-popover.hover.left="props.row.importMessages.join('\n')"
                  title="Import Messages"
                ></i>
              </a>
            </div>
          </span>
          <span v-else>
            <div>{{props.formattedRow[props.column.field]}}</div>
          </span>
        </template>
      </vue-good-table>
    </div>

    <div class="idb-block-footer">
      <button class="btn btn-primary ml-1" @click="onViewPaymentsClick">View Payments</button>
      <button class="btn btn-primary ml-1" @click="onAddFilesClick">Add Files</button>
    </div>

    <br />
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'

export default {
  data () {
    return {
      hubUrl: '',
      getTaskProgressUrl: '',
      endTaskUrl: '',
      bureauSubmissionDetails: [],
      totalRecords: 0,
      bureauCustomersDictionary: {},
      submissionId: '',
      submission: [],
      columns: [
        {
          label: 'Filename',
          field: 'paymentFilename',
          sortable: false,
          hidden: true
        },
        {
          label: 'Customer',
          field: 'bureauCustomerName',
          sortable: false,
          hidden: true
        },
        {
          label: 'Bureau Job Detail Id',
          field: 'bureauJobDetailId',
          hidden: true
        },
        {
          label: 'Description',
          field: 'description',
          width: '30%',
          sortable: false
        },
        {
          label: 'Number of Payments',
          field: 'numberOfPayments'
        },
        {
          label: 'Invalid Rows',
          field: 'invalidRowCount',
          hidden: true
        },
        {
          label: 'Status',
          field: 'percentComplete',
          width: '15%'
        },
        {
          label: 'Issues',
          field: 'issues'
        }
      ]
    }
  },
  components: {},

  beforeDestroy () {
    if (this.statusConnection !== null) {
      this.statusConnection.stop()
    }
  },
  async created () {
    this.submissionId = this.$route.params.id
    var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.submissionId, { showload: true })
    console.log('importValidateProgress response', response)
    this.submission = response.data
    await this.loadSubmissionDetails()
    await this.import()
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.statusConnection = new HubConnectionBuilder().withUrl(this.hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.statusConnection.start()

        this.statusConnection.on('Refresh', data => {
          console.log('Refresh Data in mounted', data)
          this.bureauSubmissionDetails.forEach(bureauSubmissionDetail => {
            if (bureauSubmissionDetail.bureauSubmissionDetailId === data.bureauSubmissionDetail.bureauSubmissionDetailId) {
              bureauSubmissionDetail.invalidRowCount = data.bureauSubmissionDetail.invalidRowCount
              bureauSubmissionDetail.numberOfPayments = data.bureauSubmissionDetail.numberOfPayments
              bureauSubmissionDetail.percentComplete = data.bureauSubmissionDetail.percentComplete
              bureauSubmissionDetail.status = data.bureauSubmissionDetail.status
            }

            if (data.bureauSubmissionDetail.importMessages !== null) {
              bureauSubmissionDetail.importMessages = data.bureauSubmissionDetail.importMessages
            }
          })
        })
      })

    this.hubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/bureauStatusHub?paygateId=' + this.submission.paygateId
    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submission.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submission.submissionId
  },
  methods: {
    buildPercentageBarClass (percentDone) {
      var retVal = ''
      switch (percentDone) {
        case 0:
          retVal = 'NoPadding'
          break
        case 100:
          retVal = 'Completed'
          break
        default:
          retVal = 'Uploading'
          break
      }
      return retVal
    },
    validationCompleted (row) {
      var percentage = 0
      if (row.totalRows > 0) {
        percentage = ((row.validRows + row.invalidRows) / row.totalRows) * 100
      }
      return Math.ceil(percentage)
    },
    async import () {
      try {
        console.log('Import')
        console.log('IMPORT METHOD submission object', this.submission)
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/importpaymentfiles', { submissionId: this.submission.bureauSubmissionId }, { showload: false })
        console.log(response)
      } catch (error) {
        console.error('Error in import')
        console.error(error)
      }
    },
    onViewPaymentsClick () {
      // Show validation results?
      this.$router.push({ name: 'PaymentDetails' })
    },
    onAddFilesClick () {
      this.$router.push({ name: 'UploadPaymentFiles' })
    },
    async loadSubmissionDetails () {
      try {
        console.log('Loading submission details submission object', this.submission)
        var bureauSubmissionDetails = []
        var params = { params: { sort: 'description' } }
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissiondetails/' + this.submission.bureauSubmissionId, params, { showload: true })
        bureauSubmissionDetails = response.data.data
        this.bureauSubmissionDetails = bureauSubmissionDetails
      } catch (error) {
        console.error('Error in loadSubmissionDetails')
        console.error(error)
      }
      this.importDetails = bureauSubmissionDetails
    }
  }
}
</script>

<style scoped>
.percentageContainer {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
}

.percentage {
  position: absolute;
  display: block;
  width: 0%;
  z-index: 0;
}

.percentageLabel {
  position: absolute;
  display: block;
  width: 100%;
  z-index: 1;
  text-align: center;
}

.numericAligner {
  text-align: center;
  padding: 10px 39px 10px 38px;
}

.fileNameAligner {
  padding: 10px 0px 10px 0px;
}

.NoPadding {
  padding: 0;
}

.messageContainer {
  padding: 10px 39px 10px 38px;
  width: 100%;
  display: block;
  text-align: center;
}

.Error,
.DuplicateReferences,
.Deleted {
  background-color: rgb(243, 102, 102);
  color: white;
}

.Warning {
  background-color: rgb(241, 196, 112);
}

.Pending {
  background-color: rgb(241, 196, 112);
}

.Uploading,
.Validating {
  /* background-color: lightskyblue; */
  background-color: #86da9a;
}

.Complete {
  background-color: #86da9a;
}

.Uploading {
  /* background-color: lightskyblue; */
  background-color: #86da9a;
}

.Completed {
  background-color: #86da9a;
}
</style>
