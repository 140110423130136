<template>
  <div>
    <form novalidate v-on:submit.prevent v-if="user != null">
      <b-modal
        id="fileOptionsModal"
        ref="fileOptionsModal"
        variant="primary"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        title="Choose the method by which the user will select files."
        centered
        ok-only
        ok-title="Done"
        @ok="okMethod"
        v-model="show"
      >
        <div class="form-group row">
          <label class="col-form-label col-md-5">Upload Type</label>
          <div class="col-md-7">
            <select v-model="user.uploadType.$model" class="form-control" @change="resetPath">
              <option
                v-for="uploadType in uploadTypes"
                v-bind:key="uploadType"
                v-bind:value="uploadType"
              >{{ uploadType }}</option>
            </select>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="user.uploadType.$model == 'Secure Cloud Folder'"
          :class="{ invalid: user.uploadPath.$error }"
        >
          <label class="col-form-label col-md-5">File Upload Directory</label>
          <div class="col-md-7">
            <input v-model="user.uploadPath.$model" class="form-control" />
            <validation-messages v-model="user.uploadPath" name="File Upload Directory"></validation-messages>
            <br />
            <button
              type="button"
              class="btn btn-outline-primary"
              @click.prevent="btnFolderBrowser"
            >Select Folder</button>
          </div>
        </div>
        <div v-if="user.uploadType.$model == 'Restricted File Browser'">
          <div class="form-group row" :class="{ invalid: user.uploadPath.$error }">
            <label class="col-form-label col-md-5">Restricted Path</label>
            <div class="col-md-7">
              <input v-model="user.uploadPath.$model" class="form-control" />
              <validation-messages v-model="user.uploadPath" name="Restricted Path"></validation-messages>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-5">User Cannot Browse Sub-folders</label>
            <div class="col-md-7">
              <p-check class="p-default p-curve" v-model="user.directoryLocked.$model"></p-check>
            </div>
          </div>
        </div>
      </b-modal>
      <folderBrowser v-if="showFolderBrowser" @close="closeFolderPopup()">
        <h3 slot="header">Folder Path</h3>
      </folderBrowser>
    </form>
  </div>
</template>
<script>

import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'

export default {
  components: {
    folderBrowser: folderBrowser
  },
  name: 'GroupFileUploadOptionsModal',
  props: ['user', 'ok'],
  data () {
    return {
      uploadTypes: ['Browser Dialog', 'Secure Cloud Folder', 'Restricted File Browser'],
      showFolderBrowser: false
    }
  },
  computed: {
    show: {
      get () {
        return this.user !== null
      },
      set () {
        return false
      }
    }
  },
  methods: {
    resetPath () {
      this.user.uploadPath.$model = ''
    },
    okMethod () {
      this.ok()
    },
    closeFolderPopup () {
      this.showFolderBrowser = false
      this.user.uploadPath.$model = this.$store.state.mapping.modalPopupString
      if (this.user.uploadPath.$model === '//') {
        this.user.uploadPath.$model = '/'
      }
    },

    btnFolderBrowser () {
      this.showFolderBrowser = true
    }
  }
}
</script>
