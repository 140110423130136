export default [
  // Collections starts here
  {
    menu_title: 'Alerts',
    menu_icon: 'ti-alert',
    path: '/collections/alerts',
    counterData: 'collectionsAlertCount'
  },
  {
    menu_title: 'Payers',
    menu_icon: 'ti-user',
    path: '/collections/payer/manage'
  },
  {
    menu_title: 'Submissions',
    menu_icon: 'ti-export',
    path: '/collections/submissions/',
    counterData: 'collectionsSubmissionCount',
    severityData: 'submissionSeverityLevel'
  },
  {
    menu_title: 'Cancel Submission',
    menu_icon: 'ti-time',
    path: '/collections/cancelsubmissions'
  },
  {
    menu_title: 'Messages',
    menu_icon: 'ti-email',
    child_routes: [
      {
        path: '/collections/message/queue',
        menu_title: 'Message Queue'
      },
      {
        path: '/collections/message/templates',
        menu_title: 'Message Templates'
      },
      {
        path: '/collections/message/grouptemplates',
        menu_title: 'Assign Templates'
      },
      {
        path: '/collections/message/bulkmessaging',
        menu_title: 'Bulk Messaging'
      },
      {
        path: '/collections/letters/out',
        menu_title: 'Letters Outbox'
      }
    ]
  },
  {
    menu_title: 'Reconciliation',
    menu_icon: 'ti-loop',
    child_routes: [{
      path: '/collections/reconciliation/downloadReports?networkType=BacstelIp',
      menu_title: 'Synchronise BACS Data'
    },
    {
      path: '/collections/reconciliation/report/',
      menu_title: 'History'
    }
    ]
  },
  {
    menu_title: 'Import / Export',
    menu_icon: 'ti-exchange-vertical',
    child_routes: [{
      path: '/collections/data/import',
      menu_title: 'Import Data'
    },
    {
      path: '/collections/data/export',
      menu_title: 'Export Data'
    },
    {
      path: '/collections/data/variableamount',
      menu_title: 'Update Variable Amounts'
    }
    ]
  },
  {
    menu_title: 'Plans',
    menu_icon: 'ti-calendar',
    child_routes: [
      {
        path: '/collections/plans/designer',
        menu_title: 'Plan Designer'
      },
      {
        path: '/collections/plans/scheduledchanges',
        menu_title: 'Scheduled Changes'
      },
      {
        path: '/collections/plans/suppression',
        menu_title: 'Suppression'
      }
    ]
  },
  {
    menu_title: 'Reports',
    menu_icon: 'ti-bar-chart',
    child_routes: [
      {
        path: '/collections/reporting/lookahead/',
        menu_title: 'Look Ahead Report'
      },
      {
        path: '/collections/reporting/orphaned/',
        menu_title: 'Orphaned Submissions'
      },
      {
        path: '/collections/reporting/messaging/',
        menu_title: 'Messaging'
      }
    ]
  },  
  {
    menu_title: 'Portal',
    menu_icon: 'ti-comments',
    child_routes: [
      {
        path: '/collections/portal/messages/',
        menu_title: 'Inbox',
        counterData: 'collectionsPortalMessages'
      },      
      {
        path: '/collections/portal/sendbulk/',
        menu_title: 'Send Bulk Messages'
      },
      {
        path: '/collections/portal/migrate/',
        menu_title: 'Bulk Account Creation'
      }
    ]
  }
]
