<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/plans/plansuppression/" />Plan Suppression<favourite-icon></favourite-icon></h2>
    </div>
    <div>
      <div class="m-3">
        <div class="row form-group">
          <div class="col-md-2 required">
            Select a Group
          </div>
          <div class="col-md-4">
            <group-select
              v-model="group"
              :groups="customerGroups"
              @input="getPlans"
              :clearable="false"
            ></group-select>
            </div>
        </div>
        <b-row  class="planRow">
            <b-tabs vertical nav-wrapper-class="col-3 vertical-tab-container" nav-class="w-90 vertical-tab-list ml-2" v-model="activeTabIndex">
              <template slot="empty">
                    There are no plan templates associated with this group.
              </template>
              <b-tab v-for="(paymentPlan, index) in paymentPlans" :key="index" @click="planSelected(paymentPlan, index)" :title="paymentPlan.name">
                <vue-good-table
                :columns="columns"
                :rows="paymentPlan.planSuppressions"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :rowStyleClass="rowStyler"
                :search-options="{
                enabled: false
                }"
                :pagination-options="{
                enabled: false
              }"
                @on-row-click="onRowClick"
                @on-cell-click="onCellClick"
                styleClass="vgt-table striped bordered"
                ref="futurePlanTable"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Plan Templates Available</div>
                </div>
                <div slot="table-actions">
                  <b-button
                    @click.prevent="addSuppression"
                    class
                    variant="link"
                    title="Add Suppression"
                    v-b-popover.hover.top.d500="'Add Suppression'"
                  >
                    <i class="fa fa-plus pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="editRowIndex == props.index">
                    <span v-if="props.column.field === 'startDate'">
                      <datepicker v-model="props.row.startDate" />
                    </span>
                    <span v-if="props.column.field === 'endDate'">
                      <datepicker v-model="props.row.endDate" />
                    </span>
                    <span v-if="props.column.label === ''">
                      <b-button variant="primary" @click="saveSupp(props)">
                        <i class="fa fa-check mr-1"></i>Confirm
                      </b-button>
                      <b-button variant="outline-warning" @click="cancelSupp(props)">
                        <i class="fa fa-times mr-1"></i>Cancel
                      </b-button>
                      <b-button variant="outline-danger" @click="deleteSupp(props)">
                        <i class="fa fa-times mr-1"></i>Delete
                      </b-button>
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="props.column.label == ''">
                      <span :id="'locked'+props.index" v-if="isLocked(props.row)">
                        <i class="fa fa-lock"></i>
                      </span>
                      <span :id="'unlocked'+props.index" v-if="!isLocked(props.row)">
                        <i class="fa fa-lock-open"></i>
                      </span>
                      <!-- Tooltip title specified via prop title -->
                      <b-tooltip :target="isLocked(props.row) ? 'locked'+props.index : 'unlocked'+props.index" :title="isLocked(props.row) ? 'Cannot be edited, as suppresion has already been applied' : 'Can be edited'"></b-tooltip>
                    </span>
                    <span>{{props.formattedRow[props.column.field]}}</span>
                  </span>
                </template>
              </vue-good-table>
              </b-tab>
            </b-tabs>
        </b-row>
      </div>
    </div>
    <div class="idb-block-footer">
      <b-button v-if="selectedPaymentPlan !== null" variant="primary" :disabled="isLoading" @click="savePlanTemplate"><i class="fa fa-save rpad" aria-hidden="true"></i>Save</b-button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import EventBus from '@/Lib/eventBus'
import Datepicker from 'vuejs-datepicker'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: {
    Datepicker
  },
  data () {
    return {
      group: '',
      suppressions: [],
      columns: [
        {
          label: 'Start Date',
          field: 'startDate',
          formatFn: (value) => {
            if (value.getDate) {
              value = value.toISOString()
            }
            let retVal = value
            if (value.split) {
              retVal = value.split('T')[0]
            }
            return retVal
          }
        },
        {
          label: 'End Date',
          field: 'endDate',
          formatFn: (value) => {
            if (value.getDate) {
              value = value.toISOString()
            }
            let retVal = value
            if (value.split) {
              retVal = value.split('T')[0]
            }
            return retVal
          }
        },
        {
          label: '',
          field: 'control'
        }
      ],
      paymentPlans: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'surname', type: 'asc' }],
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      editRowIndex: -1,
      selectedPaymentPlan: {
        reference: '',
        recurrenceEndType: 0,
        recurrenceType: 0,
        ordinal: 1,
        firstAmount: 0,
        regularAmount: 0,
        totalPayments: 0,
        firstPaymentDate: new Date(),
        giftAid: false,
        variable: false,
        dayOfYear: 1 },
      selectedPlanTemplateId: null,
      selectedPlan: null,
      activeTabIndex: 0
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'Shared Templates', 'paygateId': this.paygateid, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  async created (e) {
    await this.$store.dispatch('populateAllCollectionsGroups')
  },
  async mounted (e) {
    this.loadGroups(this.paygateId)
    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
    }
    EventBus.$on('paygateIdChange', onPaygateChange)
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
    this.group = '00000000-0000-0000-0000-000000000000'
    this.getPlans()
  },
  methods: {
    loadGroups (paygateId) {
      this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async customerGroupsChanged (e) {
      this.selectedPaymentPlan = null
      this.selectedPlan = null
      await this.getPlans()
    },
    async getPlans () {
      var groupId = this.group === null ? '00000000-0000-0000-0000-000000000000' : this.group
      var resp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/group/${groupId}`, { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true })
      this.paymentPlans = resp.data
      if (this.paymentPlans.length > 0) {
        this.planSelected(this.paymentPlans[0])
      }
    },
    planSelected (plan, index) {
      this.suppressions = []
      this.selectedPlan = plan
      this.selectedPaymentPlan = plan
      this.selectedPlanTemplateId = plan.paymentPlanTemplateId
      if (plan.templateJson !== 'undefined') {
        this.selectedPlan = JSON.parse(plan.templateJson)
      }
      if (!this.selectedPaymentPlan.planSuppressions) {
        this.selectedPaymentPlan.planSuppressions = []
      }
      this.suppressions = _.clone(this.selectedPaymentPlan.planSuppressions)
      this.activeTabIndex = index
    },
    addSuppression () {
      var newSuppression = {
        ukDdmsPlanSuppressionId: '00000000-0000-0000-0000-000000000000',
        planTemplateId: this.selectedPlanTemplateId,
        startDate: new Date(),
        endDate: new Date()
      }
      this.selectedPaymentPlan.planSuppressions.push(newSuppression)
      this.editRowIndex = this.selectedPaymentPlan.planSuppressions.length - 1
    },
    reloadItems: async function () {

    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {

    },
    onSortChange (params) {

    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
    },
    onPerPageChange (params) { },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (params.column.field !== 'control' && !this.isLocked(params.row)) {
        this.oldVersion = _.clone(params.row)
        this.editRowIndex = params.rowIndex
      }
    },
    onSearch (params) {

    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    rowStyler (row) {
      return row.status === 10 && (row !== this.selectedRow) ? 'deleted' : ''
    },
    saveSupp (e) {
      this.selectedPaymentPlan.planSuppressions[e.index] = e.row
      // this.selectedPaymentPlan.planSuppressions[e.index] = this.suppressions[e.index]
      this.editRowIndex = -1
    },
    cancelSupp (e) {
      this.selectedPaymentPlan.planSuppressions[e.index] = this.suppressions[e.index]
      this.editRowIndex = -1
    },
    deleteSupp (e) {
      this.selectedPaymentPlan.planSuppressions.splice(e.index, 1)
      this.editRowIndex = -1
    },
    async savePlanTemplate (e) {
      try {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}plansuppression/${this.selectedPlanTemplateId}`, this.selectedPaymentPlan.planSuppressions, { showload: true })
        if (response) {
          console.log(response)
          this.$toastr.s('Saved')
        }
      } catch (ex) {
        console.log(ex)
        this.$toastr.e('An exception occurred saving the future changes.')
      }
    },
    isLocked (row) {
      var now = new Date()
      return row.startDate < now || row.endDate < now
    }
  }
}
</script>
<style scoped>
.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active, .vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0
}

.planRow .tabs {
  width: 100%
}
</style>
