<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Login Policy
            <help-icon docPath="/administration/securitypolicy/loginpolicy/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row">
            <label
              class="col-form-label col-md-11 offset-md-1"
            >Governs how paygate behaves when a user attempts to Login</label>
          </div>
          <!-- Enforce 2 factor authentication -->
          <div class="form-group row">
            <label class="col-form-label col-md-4 offset-md-1">Enforce two-factor authentication</label>
            <div class="col-md-1">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.checkTokenAtLogin.$model"
                :disabled="!mfaEnabled"
              ></p-check>
            </div>
            <div
              class="col-md-4"
              v-if="$v.policy.checkTokenAtLogin.$model && !$v.policy.allowAuthenticator.$model && !$v.policy.allowEmail.$model && !$v.policy.allowSMS.$model  && !$v.policy.allowUSBToken.$model"
            >
              <small class="form-text text-warning">You must select at least one two-factor option</small>
            </div>
          </div>

          <div v-if="$v.policy.checkTokenAtLogin.$model" class="offset-md-1">
            <!-- Allow Authenticator -->
            <div class="form-group row">
              <label class="col-form-label col-md-4 offset-md-1">Allow Authenticator</label>
              <div class="col-md-1">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="$v.policy.allowAuthenticator.$model"
                  :disabled="!mfaOptions.some(mfa => mfa === 'Authenticator')"
                  @change="deselect(...arguments,'Authenticator')"
                ></p-check>
              </div>
            </div>

            <!-- Allow Email -->
            <div class="form-group row">
              <label class="col-form-label col-md-4 offset-md-1">Allow Email</label>
              <div class="col-md-1">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="$v.policy.allowEmail.$model"
                  :disabled="!mfaOptions.some(mfa => mfa === 'Email')"
                  @change="deselect(...arguments,'Email')"
                ></p-check>
              </div>
            </div>

            <!-- Allow SMS -->
            <div class="form-group row">
              <label class="col-form-label col-md-4 offset-md-1">Allow SMS</label>
              <div class="col-md-1">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="$v.policy.allowSMS.$model"
                  :disabled="!mfaOptions.some(mfa => mfa === 'SMS')"
                  @change="deselect(...arguments,'SMS')"
                ></p-check>
              </div>
            </div>

            <!-- Allow USB Tokens -->
            <div class="form-group row">
              <label class="col-form-label col-md-4 offset-md-1">Allow USB Tokens</label>
              <div class="col-md-1">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="$v.policy.allowUSBToken.$model"
                  :disabled="!mfaOptions.some(mfa => mfa === 'USB Token')"
                  @change="deselect(...arguments,'USB Token')"
                ></p-check>
              </div>
            </div>

            <!-- Approval Method -->
            <div class="form-group row" :class="{invalid: $v.policy.defaultTwoFactor.$error}">
              <label class="col-form-label col-md-4 offset-md-1 required">Default Method</label>
              <div class="col-md-3">
                <b-form-select
                  v-model="$v.policy.defaultTwoFactor.$model"
                  :options="approvalMethods"
                  class="form-control"
                ></b-form-select>
                <small
                  class="form-text text-muted"
                >This will restrict all users to this choice, you can override this setting per user in the user edit screen</small>

                <validation-messages
                  v-model="$v.policy.defaultTwoFactor"
                  name="default two factor method"
                ></validation-messages>
              </div>
            </div>
          </div>

          <!-- Lockout inactive accounts -->
          <div class="form-group row">
            <label class="col-form-label col-md-4 offset-md-1">Lock out inactive accounts</label>
            <div class="col-md-1">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.lockOutInactiveAccounts.$model"
              ></p-check>
            </div>
          </div>

          <!-- Number of days inactivity for auto-lockout -->
          <transition name="fade">
            <div
              class="form-group row"
              v-if="policy.lockOutInactiveAccounts"
              :class="{invalid: $v.policy.maximumInactivityDays.$error}"
            >
              <label class="col-form-label col-md-4 offset-md-1 required">Number of days inactivity</label>
              <div class="col-md-2">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="$v.policy.maximumInactivityDays.$model"
                  min="1"
                />
                <validation-messages
                  v-model="$v.policy.maximumInactivityDays"
                  name="number of inactive days"
                ></validation-messages>
              </div>
            </div>
          </transition>
          <div v-if="$v.policy.lockOutInactiveAccounts.$model">
            <!-- Exclude specific users from lockout -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 offset-md-1"
              >Exclude specific accounts from auto-lockout</label>
              <div class="col-md-1">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="$v.policy.excludeSpecificUsers.$model"
                ></p-check>
              </div>
            </div>

            <!-- Excluded users -->
            <transition name="fade">
              <div
                class="form-group row"
                v-if="policy.excludeSpecificUsers"
                :class="{invalid: $v.policy.excludedUsers.$error}"
              >
                <label class="col-form-label col-md-4 offset-md-1">Excluded users</label>
                <div class="col-md-5">
                  <vue-select
                    multiple
                    v-model="$v.policy.excludedUsers.$model"
                    :options="users"
                    :closeOnSelect="false"
                  ></vue-select>
                  <validation-messages v-model="$v.policy.excludedUsers"></validation-messages>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="idb-block-footer">
          <button type="submit" class="btn btn-primary" :disabled="isLoading">Save</button>
          <button
            type="button"
            class="btn btn-outline-warning ml-3"
            @click="reset"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import { requiredIf, minValue, maxValue, numeric } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    VueSelect
  },
  computed: {
    approvalMethods () {
      const result = []

      if (this.policy.allowAuthenticator) {
        result.push({ value: 'Authenticator', text: 'Authenticator' })
      }

      if (this.policy.allowEmail) {
        result.push({ value: 'Email', text: 'Email' })
      }

      if (this.policy.allowSMS) {
        result.push({ value: 'SMS', text: 'SMS' })
      }

      if (this.policy.allowUSBToken) {
        result.push({ value: 'USB Token', text: 'USB Token' })
      }

      return result
    },
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['mfaOptions', 'mfaEnabled', 'selectedCustomer'])
  },
  data () {
    return {
      errors: [],
      policy: {
        checkTokenAtLogin: false,
        allowAuthenticator: false,
        allowEmail: false,
        allowSMS: false,
        allowUSBToken: false,
        defaultTwoFactor: null,
        lockOutInactiveAccounts: false,
        maximumInactivityDays: 30,
        excludeSpecificUsers: false,
        excludedUsers: []
      },
      users: []
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/LoginPolicy`, this.policy,
          { showload: true, showerror: true, errormessage: 'Login policy failed to save' })
        this.$toastr.s('Login policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        checkTokenAtLogin: false,
        allowAuthenticator: false,
        allowEmail: false,
        allowSMS: false,
        allowUSBToken: false,
        defaultTwoFactor: null,
        lockOutInactiveAccounts: false,
        maximumInactivityDays: 30,
        excludeSpecificUsers: false,
        excludedUsers: []
      }
      this.errors = []
      this.$toastr.i('Login policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        this.users = await this.$store.dispatch('getUsersAsDropDownSource')
        await this.$store.dispatch('loadTwoFactorOptions')
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/LoginPolicy`,
          { showload: true, showerror: true, errormessage: 'Login policy failed to load' })
        this.policy = response.data
      } catch { }
    },
    deselect (value, method) {
      if (value === false && this.policy.defaultTwoFactor === method) {
        this.policy.defaultTwoFactor = null
        this.$nextTick(() => {
          this.$v.policy.defaultTwoFactor.$reset()
        })
      }
    }
  },
  validations () {
    return {
      policy: {
        checkTokenAtLogin: {},
        lockOutInactiveAccounts: {},
        excludeSpecificUsers: {},
        excludedUsers: {},
        allowAuthenticator: {},
        allowEmail: {},
        allowSMS: {},
        allowUSBToken: {},
        defaultTwoFactor: {
          select: requiredIf(value => value.checkTokenAtLogin)
        },
        maximumInactivityDays: {
          required: requiredIf(value => value.lockOutInactiveAccounts),
          numeric,
          minValue: (value, model) => {
            return model.lockOutInactiveAccounts ? minValue(1)(value) : true
          },
          maxVal: maxValue(365)
        }
      }
    }
  }
}
</script>
