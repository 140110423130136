var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("b-select", {
                    attrs: { options: _vm.customerTypes },
                    on: {
                      change: function($event) {
                        return _vm.getLockedSubmissions()
                      }
                    },
                    model: {
                      value: _vm.selectedCustomerType,
                      callback: function($$v) {
                        _vm.selectedCustomerType =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "selectedCustomerType"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchComplete === true,
                    expression: "searchComplete === true"
                  }
                ],
                staticClass: "row form-group"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12",
                    staticStyle: {
                      "overflow-x": "auto",
                      "white-space": "nowrap"
                    }
                  },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          columns: _vm.columns,
                          rows: _vm.rows,
                          lineNumbers: false,
                          totalRows: _vm.totalRecords,
                          "pagination-options": {
                            enabled: true,
                            perPage: _vm.rowsPerPage,
                            dropdownAllowAll: false,
                            setCurrentPage: _vm.currentPage
                          },
                          styleClass: "vgt-table striped bordered"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field === "unlockButton"
                                  ? _c("span", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function($event) {
                                              return _vm.onRowClick(
                                                props.row.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Unlock")]
                                      )
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          "line-height": "40px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          )
                                        )
                                      ]
                                    )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh unlocked submissions",
                                    expression:
                                      "'Refresh unlocked submissions'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.refreshGrid($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [
        _vm._v(" Unlock Submissions "),
        _c("span", { staticClass: "pull-right" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Customers")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }