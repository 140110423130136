var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              _vm.setSaved() && _vm.checkValidation() && _vm.saveGroup()
            }
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" " + _vm._s(_vm.status) + " Group "),
                  _c("help-icon", { attrs: { docPath: _vm.docPath } })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.group.isItemActioned
                  ? _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v(
                        "This group has a pending action against it and cannot be edited"
                      )
                    ])
                  : _vm._e(),
                !_vm.approvable
                  ? _c("div", { staticClass: "alert alert-warning" }, [
                      _c("b", [_vm._v("Warning")]),
                      _vm._v(" This group does not have enough approvers ")
                    ])
                  : _vm._e(),
                _c(
                  "b-tabs",
                  { ref: "tabs", on: { input: _vm.changedTab } },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Group Details",
                          "title-link-class": {
                            invalid: _vm.$v.group.mainGroupValidation.$error
                          },
                          active: ""
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.$v.group.name.$error }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label col-md-3 required"
                              },
                              [_vm._v("Name")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.group.name.$model,
                                      expression: "$v.group.name.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.group.isItemActioned
                                  },
                                  domProps: { value: _vm.$v.group.name.$model },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.group.name,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _c("validation-messages", {
                                  attrs: { name: "name" },
                                  model: {
                                    value: _vm.$v.group.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$v.group, "name", $$v)
                                    },
                                    expression: "$v.group.name"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.$v.group.description.$error }
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Description")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.group.description.$model,
                                      expression: "$v.group.description.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    rows: "3",
                                    disabled: _vm.group.isItemActioned
                                  },
                                  domProps: {
                                    value: _vm.$v.group.description.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.group.description,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _c("validation-messages", {
                                  attrs: { name: "description" },
                                  model: {
                                    value: _vm.$v.group.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$v.group, "description", $$v)
                                    },
                                    expression: "$v.group.description"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.$v.group.description.$error }
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Colour Tag")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("swatches", {
                                  model: {
                                    value: _vm.group.colour,
                                    callback: function($$v) {
                                      _vm.$set(_vm.group, "colour", $$v)
                                    },
                                    expression: "group.colour"
                                  }
                                }),
                                _c("validation-messages", {
                                  attrs: { name: "description" },
                                  model: {
                                    value: _vm.$v.group.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$v.group, "description", $$v)
                                    },
                                    expression: "$v.group.description"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.$v.group.groupType.$error }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label col-md-3 required"
                              },
                              [_vm._v("Type")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _vm.status === "Create"
                                  ? _c("b-form-select", {
                                      attrs: {
                                        options: _vm.availableGroupTypes,
                                        title: "Type"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.$v.$reset()
                                        }
                                      },
                                      model: {
                                        value: _vm.$v.group.groupType.$model,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.group.groupType,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression: "$v.group.groupType.$model"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.status !== "Create"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.$v.group.groupType.$model,
                                          expression:
                                            "$v.group.groupType.$model"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", disabled: "" },
                                      domProps: {
                                        value: _vm.$v.group.groupType.$model
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.$v.group.groupType,
                                            "$model",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _c("validation-messages", {
                                  attrs: { name: "type" },
                                  model: {
                                    value: _vm.$v.group.groupType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$v.group, "groupType", $$v)
                                    },
                                    expression: "$v.group.groupType"
                                  }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      " This is the group type, once created it cannot be altered "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Group Users",
                          "title-link-class": {
                            invalid: _vm.$v.group.groupUserRights.$error
                          }
                        }
                      },
                      [
                        _vm.loadUsers
                          ? _c("group-users", {
                              attrs: { group: _vm.$v.group }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.group.groupType === _vm.groupTypes.bacs
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "BACS Config",
                              "title-link-class": {
                                invalid:
                                  _vm.$v.groupTypeDetails.groupTypeDetails
                                    .$error
                              }
                            }
                          },
                          [
                            _c("bacs-config", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                disabled: _vm.group.isItemActioned,
                                status: _vm.status
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.bureau
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "Bureau Config",
                              "title-link-class": {
                                invalid: _vm.$v.groupTypeDetails.$error
                              }
                            }
                          },
                          [
                            _c("bureau-config", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                disabled: _vm.group.isItemActioned,
                                status: _vm.status
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.collections
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "Collections Config",
                              "title-link-class": {
                                invalid: _vm.$v.groupTypeDetails.$error
                              }
                            }
                          },
                          [
                            _c("collections-config", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                disabled: _vm.group.isItemActioned,
                                groupid: _vm.groupId,
                                status: _vm.status
                              },
                              on: { triggerTouch: _vm.triggerTouch }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.bacsOnline
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "BACS Online Config",
                              "title-link-class": {
                                invalid:
                                  _vm.$v.groupTypeDetails.groupTypeDetails
                                    .$error
                              }
                            }
                          },
                          [
                            _c("bacs-online-config", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                disabled: _vm.group.isItemActioned
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.fps
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "FPS Config",
                              "title-link-class": {
                                invalid:
                                  _vm.$v.groupTypeDetails.groupTypeDetails
                                    .$error
                              }
                            }
                          },
                          [
                            _c("fps-config", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                disabled: _vm.group.isItemActioned,
                                status: _vm.status
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.fpsOnline
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "FPS Online Config",
                              "title-link-class": {
                                invalid:
                                  _vm.$v.groupTypeDetails.groupTypeDetails
                                    .$error
                              }
                            }
                          },
                          [
                            _c("fps-online-config", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                disabled: _vm.group.isItemActioned
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.bacs ||
                    _vm.group.groupType === _vm.groupTypes.sepa ||
                    _vm.group.groupType === _vm.groupTypes.fps ||
                    _vm.group.groupType === _vm.groupTypes.bacsOnline ||
                    _vm.group.groupType === _vm.groupTypes.fpsOnline
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "Approval Settings",
                              "title-link-class": {
                                invalid: _vm.$v.approvalSettings.$error
                              }
                            }
                          },
                          [
                            _c("approval-settings", {
                              attrs: {
                                details: _vm.$v.groupTypeDetails,
                                group: _vm.$v.group
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.bacs ||
                    _vm.group.groupType === _vm.groupTypes.bacsOnline ||
                    _vm.group.groupType === _vm.groupTypes.fps ||
                    _vm.group.groupType === _vm.groupTypes.fpsOnline
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "Auto File Import Config",
                              "title-link-class": {
                                invalid:
                                  _vm.$v.groupTypeDetails
                                    .bacsAutoFileImportConfig.$error
                              }
                            }
                          },
                          [
                            _c("auto-file-import-config", {
                              attrs: {
                                details:
                                  _vm.$v.groupTypeDetails
                                    .bacsAutoFileImportConfig
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.group.groupType === _vm.groupTypes.bacs ||
                    _vm.group.groupType === _vm.groupTypes.bacsOnline ||
                    _vm.group.groupType === _vm.groupTypes.fps ||
                    _vm.group.groupType === _vm.groupTypes.fpsOnline
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Anti-Fraud Lists" } },
                          [
                            _vm.loadAntiFraudLists
                              ? _c("group-anti-fraud-lists", {
                                  attrs: {
                                    group: _vm.group,
                                    disabled: _vm.group.isItemActioned,
                                    groupId: _vm.groupId
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    disabled:
                      _vm.group.isItemActioned || _vm.isLoading || _vm.isSaving,
                    type: "submit"
                  }
                },
                [_vm._v(_vm._s(_vm.status === "Edit" ? "Save" : _vm.status))]
              ),
              _vm.status === "Edit"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger pull-right",
                      attrs: {
                        disabled: _vm.group.isItemActioned || _vm.isLoading,
                        type: "button"
                      },
                      on: { click: _vm.deleteGroup }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-trash rpad" }),
                      _vm._v("Delete Group ")
                    ]
                  )
                : _vm._e(),
              _vm.status === "Create"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger pull-right",
                      attrs: { disabled: _vm.isLoading, type: "button" },
                      on: { click: _vm.back }
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              _vm.status === "Edit" && _vm.canAddGroups
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary ml-3",
                      attrs: { disabled: _vm.isLoading, type: "button" },
                      on: { click: _vm.showModal }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-layers rpad" }),
                      _vm._v("Clone ")
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "clone-modal",
          attrs: {
            id: "cloneModal",
            title: "Clone Group",
            "ok-title": "Clone",
            "cancel-variant": "secondary-outline",
            "no-close-on-backdrop": "",
            centered: true
          },
          on: { ok: _vm.cloneGroup, hidden: _vm.resetModal }
        },
        [
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.group.clonedName.$error }
            },
            [
              _c("label", { staticClass: "col-form-label col-md-3 required" }, [
                _vm._v("New Name")
              ]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.group.clonedName.$model,
                        expression: "$v.group.clonedName.$model"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.$v.group.clonedName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.group.clonedName,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c(
                    "validation-messages",
                    {
                      attrs: { name: "new name" },
                      model: {
                        value: _vm.$v.group.clonedName,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.group, "clonedName", $$v)
                        },
                        expression: "$v.group.clonedName"
                      }
                    },
                    [
                      _vm.$v.group.clonedName.notSameAs != undefined &&
                      !_vm.$v.group.clonedName.notSameAs
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v(
                              "Cloned name cannot be the same as the original name"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }