var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Agent Details "),
            _c("help-icon", {
              attrs: { docPath: "/administration/customer/details/" }
            })
          ],
          1
        )
      ]),
      !_vm.agentSetup && _vm.loaded
        ? _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "alert alert-info" }, [
              _vm._v(
                "Agent has currently not been initialised, please press the button below to start this process (This is usually a one time setup)"
              )
            ]),
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.setupAgent } },
              [_vm._v("Setup Agent")]
            )
          ])
        : _vm._e(),
      _vm.agentSetup
        ? _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "label-control col-md-3" }, [
                _vm._v(" Agent Initialisation Token "),
                _c("br"),
                _vm.isSystemAdmin
                  ? _c("small", { staticClass: "text-warning" }, [
                      _vm._v(
                        "System Administrators can't download Customer Agent Initialisation Tokens."
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "input-group" }, [
                  (_vm.pinRevealed ? "text" : "password") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sftpKeyPin,
                            expression: "sftpKeyPin"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { readonly: "", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.sftpKeyPin)
                            ? _vm._i(_vm.sftpKeyPin, null) > -1
                            : _vm.sftpKeyPin
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.sftpKeyPin,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.sftpKeyPin = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.sftpKeyPin = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.sftpKeyPin = $$c
                            }
                          }
                        }
                      })
                    : (_vm.pinRevealed ? "text" : "password") === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sftpKeyPin,
                            expression: "sftpKeyPin"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { readonly: "", type: "radio" },
                        domProps: { checked: _vm._q(_vm.sftpKeyPin, null) },
                        on: {
                          change: function($event) {
                            _vm.sftpKeyPin = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sftpKeyPin,
                            expression: "sftpKeyPin"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          readonly: "",
                          type: _vm.pinRevealed ? "text" : "password"
                        },
                        domProps: { value: _vm.sftpKeyPin },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.sftpKeyPin = $event.target.value
                          }
                        }
                      }),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary",
                        attrs: { type: "button", disabled: _vm.isSystemAdmin },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showKey($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.pinRevealed ? "Hide" : "Reveal"))]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: _vm.pinRevealed
                          ? "btn-outline-primary"
                          : " btn-outline-secondary",
                        attrs: {
                          type: "button",
                          disabled: !_vm.pinRevealed || _vm.isSystemAdmin
                        },
                        on: { click: _vm.copyPin }
                      },
                      [_vm._v("Copy Token")]
                    )
                  ])
                ])
              ])
            ]),
            !_vm.whitelistDisabled
              ? _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "label-control col-md-3" }, [
                    _vm._v("Enforce IP Address Restrictions")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("p-check", {
                        staticClass: "p-switch p-fill",
                        attrs: { color: "primary" },
                        model: {
                          value: _vm.$v.policy.enforceIpRestrictions.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.policy.enforceIpRestrictions,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.policy.enforceIpRestrictions.$model"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.agentSetup
        ? _c("div", { staticClass: "idb-block-footer" }, [
            !_vm.whitelistDisabled
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: { click: _vm.saveWhitelist }
                  },
                  [_vm._v("Save Changes")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-2",
                attrs: { type: "submit" },
                on: { click: _vm.regenerateToken }
              },
              [_vm._v("Regenerate Token")]
            )
          ])
        : _vm._e()
    ]),
    _vm.agentSetup && _vm.policy.enforceIpRestrictions
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10 offset-md-1 pl-5",
                      class: {
                        invalid: !_vm.$v.policy.ipAddressItem.atLeastOne
                      }
                    },
                    [
                      _c(
                        "table",
                        { staticClass: "table table-striped table-bordered" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v("Active")
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  "IP Address (V4 format) with or without a CIDR"
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addNewAddress }
                                  },
                                  [_vm._v("Add New Address")]
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.$v.policy.ipAddressItem.$each.$iter,
                              function(ipAddress, index) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    { attrs: { scope: "row" } },
                                    [
                                      _c("p-check", {
                                        attrs: { disabled: true },
                                        model: {
                                          value: ipAddress.active.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              ipAddress.active,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "ipAddress.active.$model"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    {
                                      class: {
                                        invalid: ipAddress.ipText.$error
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: ipAddress.ipText.$model,
                                            expression:
                                              "ipAddress.ipText.$model",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: ipAddress.ipText.$model
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              ipAddress.ipText,
                                              "$model",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _c("validation-messages", {
                                        model: {
                                          value: ipAddress.ipText,
                                          callback: function($$v) {
                                            _vm.$set(ipAddress, "ipText", $$v)
                                          },
                                          expression: "ipAddress.ipText"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeAddress(index)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ])
                                ])
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _c(
                        "validation-messages",
                        {
                          model: {
                            value: _vm.$v.policy.ipAddressItem,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.policy, "ipAddressItem", $$v)
                            },
                            expression: "$v.policy.ipAddressItem"
                          }
                        },
                        [
                          _c("template", { slot: "override" }, [
                            !_vm.$v.policy.ipAddressItem.atLeastOne
                              ? _c(
                                  "small",
                                  { staticClass: "form-text small" },
                                  [
                                    _vm._v(
                                      "Please enter at least one IP address restriction"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ],
                        2
                      ),
                      _c("small", [
                        _vm._v(
                          'Allowed IP Address restrictions require manual changes to be made, only addresses with "Active" checked are in use.'
                        )
                      ])
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-success ml-2",
                attrs: { type: "button" },
                on: { click: _vm.addMyIpAddress }
              },
              [_vm._v("Add My IP Address")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Allowed IP Addresses")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }