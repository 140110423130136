var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "sendSubmission" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.actionLoaded === true,
              expression: "actionLoaded === true"
            }
          ]
        },
        [
          _vm.isLoggedIn === false
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: this.enableLogin === false
                      },
                      on: { click: _vm.loginStart }
                    },
                    [_vm._v("Login")]
                  )
                ]),
                _vm._m(0),
                _c("div", { staticClass: "col-md-1" }),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary",
                      attrs: {
                        type: "button",
                        disabled: this.isLoggedIn === false
                      },
                      on: { click: _vm.viewVocalinkCertificate }
                    },
                    [_vm._v(_vm._s(_vm.certificateButtonText))]
                  )
                ]),
                _c("div", { staticClass: "col-md-7" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger pull-right",
                      attrs: {
                        type: "button",
                        disabled:
                          this.enableLogin === false && this.canCancel === false
                      },
                      on: { click: _vm.cancelSubmission }
                    },
                    [_vm._v("Cancel Submission")]
                  )
                ])
              ])
            : _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-1" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary",
                        attrs: {
                          type: "button",
                          disabled: this.sendStarted === true
                        },
                        on: { click: _vm.logoff }
                      },
                      [_vm._v("Log Off")]
                    )
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("span", {
                      staticClass: "fa fa-lock",
                      staticStyle: { "font-size": "200%" }
                    }),
                    _vm._v(
                      " Securely logged into " + _vm._s(_vm.networkName) + " "
                    )
                  ]),
                  _c("div", { staticClass: "col-md-1" }),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary",
                        attrs: {
                          type: "button",
                          disabled: this.sendStarted === true
                        },
                        on: { click: _vm.viewVocalinkCertificate }
                      },
                      [_vm._v(_vm._s(_vm.certificateButtonText))]
                    )
                  ]),
                  _c("div", { staticClass: "col-md-5" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger pull-right",
                        attrs: {
                          type: "button",
                          disabled: this.sendStarted === true
                        },
                        on: { click: _vm.cancelSubmission }
                      },
                      [_vm._v("Cancel Submission")]
                    )
                  ])
                ]),
                _c("br"),
                _c("div", { staticClass: "row form-group" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._v("Submission Type")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("b-select", {
                        attrs: { options: _vm.submissionTypes },
                        model: {
                          value: _vm.selectedSubType,
                          callback: function($$v) {
                            _vm.selectedSubType =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "selectedSubType"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "button",
                          disabled: this.sendStarted === true
                        },
                        on: { click: _vm.sendSubmission }
                      },
                      [_vm._v("Send Submission")]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: this.sendStarted === true,
                          expression: "this.sendStarted === true"
                        }
                      ],
                      staticClass: "col-md-10"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger pull-right",
                          attrs: { type: "button" },
                          on: { click: _vm.cancelSendTask }
                        },
                        [_vm._v("Abort Submission")]
                      )
                    ]
                  )
                ]),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sendStarted,
                      expression: "sendStarted"
                    }
                  ],
                  staticClass: "row form-group"
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sendStarted,
                        expression: "sendStarted"
                      }
                    ],
                    staticClass: "row form-group"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-progress",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              striped: "",
                              animated: true,
                              max: _vm.max,
                              "show-progress": ""
                            }
                          },
                          [
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.progress,
                                label: _vm.progress + "%"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showeSigner,
              expression: "showeSigner"
            }
          ],
          attrs: { id: "eSigner" }
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.eSignerHtml) } })]
      ),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("span", {
        staticClass: "fa fa-unlock",
        staticStyle: { "font-size": "200%" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }