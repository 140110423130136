var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _c("help-icon", {
                attrs: { docPath: "/paygate-collections/portal/" }
              }),
              _vm._v("Verification Settings"),
              _c("favourite-icon")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-sm-5",
                    attrs: {
                      "label-cols": 5,
                      "label-class": "required",
                      horizontal: "",
                      label: "Email Address"
                    }
                  },
                  [
                    _c("b-form-select", {
                      attrs: {
                        options: _vm.emailAddresses,
                        "value-field": "id",
                        "text-field": "emailAddress"
                      },
                      model: {
                        value: _vm.emailAddress,
                        callback: function($$v) {
                          _vm.emailAddress = $$v
                        },
                        expression: "emailAddress"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-sm-5",
                    attrs: {
                      "label-cols": 5,
                      "label-class": "required",
                      horizontal: "",
                      label: "Email Subject"
                    }
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: { type: "text", id: "subjectInput" },
                      on: {
                        input: function($event) {
                          return _vm.delayTouch(_vm.$v.emailSubject)
                        }
                      },
                      model: {
                        value: _vm.emailSubject,
                        callback: function($$v) {
                          _vm.emailSubject = $$v
                        },
                        expression: "emailSubject"
                      }
                    }),
                    _vm.$v.emailSubject.$dirty
                      ? _c("b-col", [
                          _vm.$v.emailSubject.$invalid
                            ? _c(
                                "label",
                                {
                                  staticClass: "text-danger small",
                                  attrs: { id: "subjectRequiredLabel" }
                                },
                                [_vm._v("Please ensure a subject is provided")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-form-group",
              [
                _c("label", [_vm._v("Email Message Body")]),
                _c("wysiwyg", {
                  on: {
                    input: function($event) {
                      return _vm.delayTouch(_vm.$v.emailBody)
                    }
                  },
                  model: {
                    value: _vm.emailBody,
                    callback: function($$v) {
                      _vm.emailBody = $$v
                    },
                    expression: "emailBody"
                  }
                }),
                _vm.$v.emailBody.$dirty
                  ? _c("b-col", [
                      _vm.$v.emailBody.$invalid
                        ? _c(
                            "label",
                            {
                              staticClass: "text-danger small",
                              attrs: { id: "emailBodyRequiredLabel" }
                            },
                            [_vm._v("Ensure the Email body includes {####}.")]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            ),
            false
              ? _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-5",
                        attrs: {
                          "label-cols": 5,
                          "label-class": "required",
                          horizontal: "",
                          label: "SMS Message"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { type: "text", id: "smsInput" },
                          on: {
                            input: function($event) {
                              return _vm.delayTouch(_vm.$v.smsBody)
                            }
                          },
                          model: {
                            value: _vm.smsBody,
                            callback: function($$v) {
                              _vm.smsBody = $$v
                            },
                            expression: "smsBody"
                          }
                        }),
                        _vm.$v.smsBody.$dirty
                          ? _c("b-col", [
                              _vm.$v.smsBody.$invalid
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "text-danger small",
                                      attrs: { id: "smsBodyRequiredLabel" }
                                    },
                                    [
                                      _vm._v(
                                        "Ensure the SMS body includes {####}."
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  disabled: _vm.$v.$invalid || _vm.isLoading
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveConfiguration($event)
                  }
                }
              },
              [_vm._v("Save")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c("p", [
              _vm._v(
                "These are the domains in which the portal site can be displayed within a non-paygate environment."
              )
            ]),
            _c(
              "vue-good-table",
              {
                attrs: {
                  mode: "remote",
                  "search-options": {
                    enabled: true
                  },
                  "sort-options": {
                    enabled: false,
                    initialSortBy: { field: "url", type: "desc" }
                  },
                  "pagination-options": {
                    enabled: true,
                    dropdownAllowAll: false
                  },
                  totalRows: _vm.totalRecords,
                  columns: _vm.columns,
                  rows: _vm.urls,
                  styleClass: "vgt-table striped bordered"
                },
                on: {
                  "on-row-click": _vm.onRowClick,
                  "on-page-change": _vm.onPageChange,
                  "on-column-filter": _vm.onColumnFilter,
                  "on-per-page-change": _vm.onPerPageChange,
                  "on-search": _vm.onSearch
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "verified"
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-fw",
                                class: props.row.verified
                                  ? "fa-check"
                                  : "fa-times"
                              })
                            ])
                          : props.column.field == "buttons"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteUrl(
                                          props.row["portalallowedurlid"]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-trash-alt" }),
                                    _vm._v(" Remove ")
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(props.formattedRow[props.column.field])
                              )
                            ])
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "table-actions" }, slot: "table-actions" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Refresh the data in the table",
                            expression: "'Refresh the data in the table'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link", title: "Refresh Table" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.loadUrls($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-redo pointer dimmedIcon"
                        })
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "Print out the contents of the Allowed Urls table",
                            expression:
                              "'Print out the contents of the Allowed Urls table'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link", title: "Print Table" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.printTable($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-print pointer dimmedIcon"
                        })
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Export the contents of the Emails table",
                            expression:
                              "'Export the contents of the Emails table'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link", title: "Export Table" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.exportTable($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-share-square pointer dimmedIcon"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "idb-block-footer noprint-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.create($event)
                }
              }
            },
            [_vm._v("Add Url")]
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    class: _vm.columnClass,
                    attrs: {
                      "label-cols": _vm.labelCols,
                      horizontal: "",
                      label: "Generate API Key"
                    }
                  },
                  [
                    _c(
                      "b-input-group",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "secondary",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.generateApiKey($event)
                                      }
                                    }
                                  },
                                  [_vm._v("Get")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "apiKeyInput",
                            type: "text",
                            disabled: true
                          },
                          model: {
                            value: _vm.apiKey,
                            callback: function($$v) {
                              _vm.apiKey = _vm._n($$v)
                            },
                            expression: "apiKey"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _vm.currentFile.portalStyleId !==
              "00000000-0000-0000-0000-000000000000" &&
            _vm.currentFile.addedDate !== "0001-01-01T00:00:00"
              ? _c("div", [
                  _c("p", [
                    _c("strong", [_vm._v("Current stylesheet:")]),
                    _vm._v(" " + _vm._s(_vm.currentFile.stylesheet))
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Last Updated:")]),
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(_vm.currentFile.addedDate))
                    )
                  ])
                ])
              : _vm._e(),
            _c(
              "b-container",
              [
                !_vm.useDropzone
                  ? _c("dropzoneLike", {
                      ref: "serverFileUploader",
                      attrs: {
                        dropzoneOptions: _vm.dropzoneOptions,
                        dropzoneUrl: _vm.dropzoneUrl,
                        secureUrl: _vm.secureUrl,
                        groupId: _vm.group
                      },
                      on: {
                        fileAdded: _vm.publicFileAdded,
                        fileRemoved: _vm.publicFileRemoved
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm.useDropzone
              ? _c(
                  "vue-dropzone",
                  {
                    ref: "fileUploader",
                    attrs: {
                      id: "drop1",
                      url: _vm.dropzoneUrl,
                      options: _vm.dropzoneOptions,
                      "use-custom-slot": true,
                      "accepted-file-types": _vm.dropzoneOptions.acceptedFiles,
                      "max-file-size-in-m-b": 200,
                      useCustomDropzoneOptions: true,
                      "use-custom-drop-zone-options": true,
                      useCustomSlot: true
                    },
                    on: {
                      "vdropzone-success": _vm.afterSuccess,
                      "vdropzone-file-added": _vm.fileAdded,
                      "vdropzone-removed-file": _vm.fileRemoved,
                      "vdropzone-sending": _vm.sendingEvent
                    }
                  },
                  [
                    _c("div", { staticClass: "dropzone-custom-content" }, [
                      _c("h3", { staticClass: "dropzone-custom-title" }, [
                        _vm._v(_vm._s(_vm.dropzoneOptions.title))
                      ]),
                      _c("div", { staticClass: "subtitle" }, [
                        _vm._v(_vm._s(_vm.dropzoneOptions.subtitle))
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "idb-block-footer noprint-footer" })
      ]),
      _c(
        "b-modal",
        {
          ref: "urlModal",
          attrs: {
            id: "urlModal",
            title: "Allowed Url",
            size: "lg",
            "hide-header-close": "",
            "cancel-variant": "secondary-outline",
            lazy: ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c(
                  "label",
                  { staticClass: "required", attrs: { for: "urlInputInput" } },
                  [_vm._v("Allowed Url")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c("b-form-input", {
                    attrs: { id: "urlInput", type: "text" },
                    model: {
                      value: _vm.selectedUrl.url,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedUrl,
                          "url",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "selectedUrl.url"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Portal URL")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("API Access Key")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Manage Portal Stylesheet")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }