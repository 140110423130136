var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Bureau Customer Information "),
            _vm._m(0),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _vm.status === "Edit"
            ? _c(
                "div",
                {
                  staticClass: "alert alert-secondary",
                  attrs: { role: "alert" }
                },
                [
                  _vm._v(" The previous details are shown in "),
                  _c("span", { staticClass: "del" }, [_vm._v("red")]),
                  _vm._v(", the new details in "),
                  _c("span", { staticClass: "ins" }, [_vm._v("green")]),
                  _vm._v(". ")
                ]
              )
            : _vm._e(),
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                { attrs: { title: "Bureau Customer Details", active: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: { for: "bureauCustomer.customerReference" }
                          },
                          [_vm._v("Unique Customer Reference")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", readonly: "" },
                            model: {
                              value: _vm.bureauCustomer.customerReference,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "customerReference",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.customerReference"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "bureauCustomer.name" }
                          },
                          [_vm._v("Name")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.$v.bureauCustomer.name.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.bureauCustomer.name.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.bureauCustomer.name.$dirty
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.bureauCustomer.name.required
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("A Bureau Customer Name is required")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "bureauCustomer.serviceUserNumber" }
                          },
                          [_vm._v("Service User Number")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 6 },
                            model: {
                              value:
                                _vm.$v.bureauCustomer.serviceUserNumber.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.serviceUserNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.serviceUserNumber.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.bureauCustomer.serviceUserNumber.$dirty
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.bureauCustomer.serviceUserNumber.required
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("A Service User Number is required")]
                              )
                            : _vm._e(),
                          !_vm.$v.bureauCustomer.serviceUserNumber.sunValidator
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("The Service User Number is not valid")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "bureauCustomer.defaultImportFilename"
                            }
                          },
                          [_vm._v("Default Import Filename")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 250 },
                            model: {
                              value: _vm.bureauCustomer.defaultImportFilename,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "defaultImportFilename",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.defaultImportFilename"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: { for: "bureauCustomer.defaultImportSchema" }
                          },
                          [_vm._v("Default Import Schema")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.schemas },
                            model: {
                              value: _vm.bureauCustomer.defaultImportSchema,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "defaultImportSchema",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.defaultImportSchema"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.bureauCustomer.defaultImportSchema == "Mapping"
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "bureauCustomer.defaultImportMapping"
                                  }
                                },
                                [_vm._v("Default Import Mapping")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.mappings },
                                model: {
                                  value:
                                    _vm.bureauCustomer.defaultImportMapping,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "defaultImportMapping",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "bureauCustomer.defaultImportMapping"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.contraNarrative" } },
                          [_vm._v("Contra Narrative")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 255 },
                            model: {
                              value: _vm.bureauCustomer.contraNarrative,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "contraNarrative",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.contraNarrative"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "bureauCustomer.tags" } }, [
                          _vm._v("Tags")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("input-tag", {
                            model: {
                              value: _vm.bureauCustomer.tags,
                              callback: function($$v) {
                                _vm.$set(_vm.bureauCustomer, "tags", $$v)
                              },
                              expression: "bureauCustomer.tags"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Bank Details" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.bankName" } },
                          [_vm._v("Reference")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.$v.bureauCustomer.bankReference.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.bankReference,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.bankReference.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { staticClass: "required" }, [
                          _vm._v("Sort Code")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("the-mask", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            ref: "softCode",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              mask: ["##-##-##"],
                              guide: true
                            },
                            model: {
                              value: _vm.$v.bureauCustomer.sortCode.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.sortCode,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.bureauCustomer.sortCode.$model"
                            }
                          }),
                          _vm.$v.bureauCustomer.sortCode.$dirty
                            ? _c("span", [
                                !_vm.$v.bureauCustomer.sortCode.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [_vm._v("Sort Code is required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.sortCode.minLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Sort Code must be at least " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer.sortCode
                                                .$params.minLength.min
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.sortCode.maxLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Sort Code must be less than " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer.sortCode
                                                .$params.maxLength.max
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.sortCodeValid &&
                                _vm.$v.bureauCustomer.sortCode.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-warning small text-nowrap"
                                      },
                                      [_vm._v(_vm._s(_vm.sortCodeErrorMessage))]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _vm.sortCodeValid
                            ? _c("b-col", [
                                _c("i", { staticClass: "fas fa-check mt-2" })
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "bureauCustomer.accountNumber" }
                          },
                          [_vm._v("Account Number")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("the-mask", {
                            ref: "originatingAccountNumber",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              mask: ["########"],
                              guide: false
                            },
                            model: {
                              value: _vm.$v.bureauCustomer.accountNumber.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.accountNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.accountNumber.$model"
                            }
                          }),
                          _vm.$v.bureauCustomer.accountNumber.$dirty
                            ? _c("span", [
                                !_vm.$v.bureauCustomer.accountNumber.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [_vm._v("Account Number is required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.accountNumber.minLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Account Number must be at least " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer
                                                .accountNumber.$params.minLength
                                                .min
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.accountNumber.maxLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Account Number must be less than " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer
                                                .accountNumber.$params.maxLength
                                                .max
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.accountNumberValid &&
                                _vm.$v.bureauCustomer.accountNumber.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-warning small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.accountNumberErrorMessage)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _vm.accountNumberValid == true
                            ? _c("b-col", [
                                _c("i", { staticClass: "fas fa-check mt-2" })
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.bankName" } },
                          [_vm._v("Bank Name")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.$v.bureauCustomer.bankName.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.bankName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.bureauCustomer.bankName.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.bankAddress1" } },
                          [_vm._v("Address")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine2,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine3,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine3",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine4,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine4",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine4"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", {
                          attrs: { for: "bureauCustomer.bankAddressLocality" }
                        })
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLocality,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLocality",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLocality"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.bankAddressTown" } },
                          [_vm._v("Town")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressTown,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressTown",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressTown"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: { for: "bureauCustomer.bankAddressCounty" }
                          },
                          [_vm._v("County")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressCounty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressCounty",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressCounty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: { for: "bureauCustomer.bankAddressPostcode" }
                          },
                          [_vm._v("Post Code")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 10 },
                            model: {
                              value: _vm.bureauCustomer.bankAddressPostcode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressPostcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressPostcode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Payment Limits" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.paymentLimit" } },
                          [_vm._v("Payment Limit")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("currency-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.$v.bureauCustomer.paymentLimit.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.paymentLimit,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.paymentLimit.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.submissionLimit" } },
                          [_vm._v("Submission Limit")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("currency-input", {
                            attrs: { type: "text" },
                            model: {
                              value:
                                _vm.$v.bureauCustomer.submissionLimit.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.submissionLimit,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.submissionLimit.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Contact Info" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.contactFirstName" } },
                          [_vm._v("Forename")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.contactFirstName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "contactFirstName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.contactFirstName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.contactSurname" } },
                          [_vm._v("Surname")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.bureauCustomer.contactSurname,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "contactSurname",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.contactSurname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "bureauCustomer.address1" } },
                                [_vm._v("Address")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressLine1,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine1",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressLine2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine2",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressLine3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine3",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine3"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressLine4,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine4",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine4"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c("label", {
                                attrs: { for: "bureauCustomer.addressLocality" }
                              })
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressLocality,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLocality",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLocality"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: { for: "bureauCustomer.addressTown" }
                                },
                                [_vm._v("Town")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "5" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressTown,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressTown",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressTown"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: { for: "bureauCustomer.addressCounty" }
                                },
                                [_vm._v("County")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "5" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value: _vm.bureauCustomer.addressCounty,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressCounty",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressCounty"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.addressPostcode" } },
                          [_vm._v("Postcode")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: "10" },
                            model: {
                              value: _vm.bureauCustomer.addressPostcode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "addressPostcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.addressPostcode"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { sm: "2" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info",
                            attrs: {
                              disabled:
                                this.bureauCustomer.addressPostcode == "" ||
                                this.bureauCustomer.addressPostcode == null
                            },
                            on: {
                              click: function($event) {
                                return _vm.getAddress()
                              }
                            }
                          },
                          [_vm._v("Lookup Address")]
                        )
                      ])
                    ],
                    1
                  ),
                  this.addressOptions.length > 0 && !this.addressFound
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "addressOptions" } },
                                [_vm._v("Addresses")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "8" } },
                            [
                              _c("b-form-select", {
                                staticClass: "mb3",
                                attrs: { options: this.addressOptions },
                                model: {
                                  value: _vm.selectedAddress,
                                  callback: function($$v) {
                                    _vm.selectedAddress = $$v
                                  },
                                  expression: "selectedAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.telephone" } },
                          [_vm._v("Telephone")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bureauCustomer.telephone,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "telephone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.telephone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.emailAddress" } },
                          [_vm._v("Email")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bureauCustomer.emailAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "emailAddress",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.emailAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.websiteUrl" } },
                          [_vm._v("Web Site")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bureauCustomer.websiteUrl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "websiteUrl",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.websiteUrl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Notes" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomer.notes" } },
                          [_vm._v("Notes")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              type: "text",
                              rows: "6",
                              placeholder: "Notes..."
                            },
                            model: {
                              value: _vm.bureauCustomer.notes,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "notes",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.$v.bureauCustomer.$invalid },
            on: {
              click: function($event) {
                return _vm.onSaveClick()
              }
            }
          },
          [_vm._v("Save")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-danger float-right",
            on: {
              click: function($event) {
                return _vm.onDeleteClick()
              }
            }
          },
          [_vm._v("Delete")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }