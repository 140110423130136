<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()">
    </fileBrowser>
    <h3>SFTP (SSH) File Transfer<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label>Transfer Type</label>
      <br>
      <p-radio value="Upload" color="primary" v-model="selectedNode.props.s7.value">Upload</p-radio>
      <p-radio value="Download" color="primary" v-model="selectedNode.props.s7.value">Download</p-radio>
    </div>
    <br/>
    <div class="form-group">
      <label class="required" >Host SFTP Server Address</label>
      <input type="url"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The Server Address field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">Port</label>
      <input
        type="number"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.int1.value.$error}"
        @input="$v.selectedNode.props.int1.value.$touch()"
        v-model="selectedNode.props.int1.value">
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.required && $v.selectedNode.props.int1.value.$dirty ">The Port field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.minValue">Minimum Port value is {{ $v.selectedNode.props.int1.value.$params.minValue.min }}!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.maxValue">Maximum Port value is {{ $v.selectedNode.props.int1.value.$params.maxValue.max }}!</p>
    </div>
    <br/>
    <label class="required" >Local Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFolderBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">The local folder field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required" >Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s4.value.$error}"
        @input="$v.selectedNode.props.s4.value.$touch()"
        v-model="selectedNode.props.s4.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.required && $v.selectedNode.props.s4.value.$dirty ">The File field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.selectedNode.props.s4.value.$params.maxLength.max }} characters!</p>
    <br/>
    <div class="form-group">
      <label class="">Remote folder</label>
      <input type="text" class="form-control" v-model="selectedNode.props.s2.value">
      <!-- <p-check v-if="selectedNode.props.s7.value==='Upload'" name="check" class="p-switch mt-4" color="primary" v-model="selectedNode.props.b4.value">Overwrite existing remote files</p-check> -->
    </div>
    <br/>
    <div class="font-weight-bold">Authentication</div>
      <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b1.value">Anonymous</p-check>
    </div>
    <br/>
    <transition name="fadein">
      <div v-if="!selectedNode.props.b1.value">
        <div class="form-group">
          <p-radio value="Password" color="primary" v-model="selectedNode.props.s8.value">Password</p-radio>
          <p-radio value="Pvt Key" color="primary" v-model="selectedNode.props.s8.value">Pvt Key</p-radio>
          <p-radio value="Pvt Key &amp; Password" color="primary" v-model="selectedNode.props.s8.value">Pvt Key &amp; Password</p-radio>
        </div>
        <br/>
        <div class="form-group">
          <label class="required">Username</label>
          <input
            type="text"
            class="form-control"
            v-model="selectedNode.props.s5.value">
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Password' || selectedNode.props.s8.value === 'Pvt Key & Password'">
          <label class="required">Password</label>
          <input type="password" class="form-control" v-model="selectedNode.props.pw1.value">
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Pvt Key' || selectedNode.props.s8.value === 'Pvt Key & Password'">
          <label class="required">Pvt Key Path</label>
          <input type="text" class="form-control" v-model="selectedNode.props.s6.value">
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Pvt Key' || selectedNode.props.s8.value === 'Pvt Key & Password'">
          <label class="required">Pvt Key PIN</label>
          <input type="password" class="form-control" v-model="selectedNode.props.pw2.value">
        </div>
      </div>
    </transition>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button variant="success" @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength, integer, minValue, maxValue } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s4: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        int1: {
          value: {
            required,
            integer,
            maxValue: maxValue(65535),
            minValue: minValue(1)
          }
        }
      }
    }
  },
  data () {
    return {
      showFolderBrowser: false,
      showFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s2.value = utils.sanitisePath(this.selectedNode.props.s2.value)
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
      this.showFileBrowser = true
    },
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showFolderBrowser = true
    },
    closeFilePopup () {
      console.log('Closed closeFilePopup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s4.value = filename
    },
    closeFolderPopup () {
      console.log('closeFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s3.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
