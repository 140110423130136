<template>
  <form @submit.prevent="!isLoading && checkValidation() && validate()">
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">
              Card Validation
              <help-icon docPath="/validate/cards/" />

              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div
              class="form-group col-md-8"
              :class="{'warn': $v.card.number.$error, 'valid':card.result != null && card.result.valid,'invalid': card.result != null && !card.result.valid}"
            >
              <!-- Label -->
              <label class="label-control required">Card Number</label>
              <!-- Input -->
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="$v.card.number.$model"
                  @input="onlyDigits"
                  v-focus
                  autocomplete="cc-number"
                />
                <img
                  v-if="card.image"
                  class="input-group-addon img-fluid p-0 m-0 card-addon"
                  v-bind:src="card.image"
                />
              </div>
              <!-- Validation Messages -->
              <span v-if="$v.card.number.$dirty">
                <small
                  class="form-text text-warning small"
                  v-if="!$v.card.number.required"
                >A card number is required</small>
                <small
                  class="form-text text-warning small"
                  v-if="!$v.card.number.minLength"
                >The card number is required to be at least {{validation.minLength}} numbers long</small>
                <small
                  class="form-text text-warning small"
                  v-if="!$v.card.number.maxLength"
                >The card number is required to be less than 19 numbers long</small>
              </span>
              <span v-if="card.result != null">
                <small
                  v-if="card.result.valid"
                  class="form-text text-success small"
                >The card is valid!</small>
                <small
                  v-if="!card.result.valid"
                  class="form-text text-danger small"
                >The card is invalid!</small>
              </span>
            </div>
          </div>
          <div class="idb-block-footer">
            <button type="submit" class="btn btn-primary" :disabled="isLoading">Validate</button>

            <button type="button" class="btn btn-outline-primary ml-3" @click="clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="card.result != null && card.result.valid">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Card Details</h2>
          </div>
          <div class="idb-block-content">
            <form novalidate v-on:submit.prevent class="col-md-12">
              <div class="form-group row">
                <label class="label-control col-md-3">Issuer</label>
                <span
                  class="form-control-static"
                >{{card.result.issuer?card.result.issuer:'No issuer found'}}</span>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Scheme</label>
                <span
                  class="form-control-static"
                >{{card.result.cardScheme?card.result.cardScheme:'No card scheme found'}}</span>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Type</label>
                <span
                  class="form-control-static"
                >{{card.result.productType?card.result.productType:'No product type found'}}</span>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Usage</label>
                <span
                  class="form-control-static"
                >{{card.result.usage?card.result.usage:'No usage found'}}</span>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Industry</label>
                <span
                  class="form-control-static"
                >{{card.result.majorIndustryIndicator?card.result.majorIndustryIndicator:'No industry found'}}</span>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Notes</label>
                <span
                  class="form-control-static"
                >{{card.result.notes?card.result.notes:'No notes found'}}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'

function getCardType (accountNumber) {
  // start without knowing the credit card type
  var result = 'unknown'

  // first check for MasterCard
  if (/^5[1-5]/.test(accountNumber)) {
    result = 'mastercard'
  } else if (/^4/.test(accountNumber)) {
    // then check for Visa
    result = 'visa'
  }
  return result
}

export default {
  name: 'cardValidation',
  mixins: [loading],
  data: function () {
    return {
      loading: false,
      card: {
        number: '',
        type: 'card',
        image: '',
        result: null
      },
      validation: {
        minLength: 4,
        maxLength: 19
      }
    }
  },
  methods: {
    async validate () {
      const response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}Card`, {
        params: {
          cardNumber: this.card.number,
          showload: true,
          showerror: true,
          errormessage: 'Failed to validate credit card'
        }
      })

      this.card.result = response.data
    },
    onlyDigits: function () {
      this.card.number = this.card.number.replace(/\s+|\D+/g, '')

      if (this.card.number.length === 0) {
        this.card.result = null
        this.$v.card.number.$reset()
        this.card.type = 'card'
        this.card.image = ''
      } else {
        // validate card type here
        this.card.type = getCardType(this.card.number)

        switch (this.card.type) {
          case 'mastercard':
            this.validation.minLength = 16
            this.card.image = '/img/cards/mastercard.svg'
            break
          case 'visa':
            this.validation.minLength = 13
            this.card.image = '/img/cards/visa.svg'
            break
          default:
            this.card.type = 'card'
            this.validation.minLength = 4
            this.card.image = ''
            break
        }
      }
    },
    clear: function () {
      this.card.number = ''
      this.card.result = null
      this.$v.card.number.$reset()
      this.card.type = 'card'
      this.card.image = ''
    }
  },
  validations () {
    return {
      card: {
        number: { required, minLength: minLength(this.validation.minLength), maxLength: maxLength(this.validation.maxLength) }
      }
    }
  }
}
</script>
<style>
.card-addon {
  background-color: rgba(0, 0, 0, 0);
  width: 38px;
  height: 38px;
  border: 0;
  margin-left: 38px !important;
}
</style>
