var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "createFasterPaymentSubFromFile" } },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" Create Faster Payment Submission From File "),
                  _c("span", { staticClass: "pull-right" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.helpUrl, target: "_blank" } },
                      [_c("i", { staticClass: "far fa-question-circle" })]
                    )
                  ]),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("group-select", {
                      attrs: { groups: _vm.groups, clearable: false },
                      on: {
                        input: function($event) {
                          return _vm.selectGroup()
                        }
                      },
                      model: {
                        value: _vm.selectedGroupId,
                        callback: function($$v) {
                          _vm.selectedGroupId = $$v
                        },
                        expression: "selectedGroupId"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      attrs: { disabled: this.groupBankLoaded === false },
                      on: { click: _vm.viewGroupBankDetails }
                    },
                    [_vm._v("View")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.submissionData.reference,
                        expression: "submissionData.reference"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "300px" },
                    attrs: { name: "reference", maxlength: "150" },
                    domProps: { value: _vm.submissionData.reference },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.submissionData,
                            "reference",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.referenceChanged($event.target.value)
                        }
                      ]
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.submissionData.contraNarrative,
                        expression: "submissionData.contraNarrative"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "300px" },
                    attrs: { name: "contraNarrative", maxlength: "50" },
                    domProps: { value: _vm.submissionData.contraNarrative },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.submissionData,
                          "contraNarrative",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("br"),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDropzone,
                      expression: "showDropzone"
                    }
                  ],
                  staticClass: "row form-group"
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-12", attrs: { id: "dzUpload" } },
                    [
                      _c("vue-dropzone", {
                        ref: "fileUploader",
                        attrs: {
                          id: "drop1",
                          "use-custom-dropzone-options": true,
                          options: _vm.dropzoneOptions
                        },
                        on: {
                          "vdropzone-success": _vm.afterSuccess,
                          "vdropzone-file-added": _vm.fileAdded
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.initRestrictedFileBrowser,
                      expression: "initRestrictedFileBrowser"
                    }
                  ],
                  staticClass: "row form-group"
                },
                [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.showRestrictedFileBrowser }
                      },
                      [_vm._v("Select Submission File")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFtpBrowser,
                      expression: "showFtpBrowser"
                    }
                  ],
                  staticClass: "row form-group"
                },
                [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.btnFtpFileBrowser }
                      },
                      [_vm._v("Select Submission File")]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } }),
      _vm.showFtpFileBrowser
        ? _c(
            "fileBrowser",
            {
              attrs: { groupId: _vm.selectedGroupId },
              on: {
                close: function($event) {
                  return _vm.closeFileBrowserPopup()
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Select Submission File")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 required" }, [
      _c("strong", [_vm._v("Select a Group")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 required" }, [
      _c("strong", [_vm._v("Submission Reference")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Contra Narrative")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }