<template>
  <div id="payments">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table ref="payments"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-column-filter="onColumnFilter"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :pagination-options="{
            enabled: true,
            perPage: 10,
            dropdownAllowAll: false,
            setCurrentPage: serverParams.page
          }"
          styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'displayProcessingDate'">
                {{ formatDate(props.row.displayProcessingDate) }}
              </span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
            <div slot="table-actions">
              <b-button
                @click.prevent="createPaymentReport"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Shows a summary of the payments'"
              >
                <i class="fa fa-file-pdf pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="createPaymentCsvFile"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Export payment details to CSV'"
              >
                <i class="fa fa-file-excel pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="refreshGrid"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Reset payment table options and filters'"
              >
                <i class="fa fa-sync pointer dimmedIcon"></i>
              </b-button>
            </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import moment from 'moment'

export default {
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      submissionId: '',
      fileNumber: 1,
      intelligibleSummaries: {},
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'No.',
          field: 'recordNumber',
          type: 'number',
          hidden: true
        },
        {
          label: 'Third Party Name',
          field: 'thirdPartyAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Orig Acc Name',
          field: 'originatorAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'User Ref',
          field: 'userReference',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'TX Code',
          field: 'transactionCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Amount (£)',
          field: 'amountText',
          thClass: 'text-right',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Processing Date',
          field: 'displayProcessingDate',
          hidden: true
        },
        {
          label: 'RTI',
          field: 'freeFormat',
          hidden: true
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      }
    }
  },

  methods: {
    formatDate: function (dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems(false)
    },

    onPerPageChange (params) {
      this.rows.length = 0
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems(true)
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems(true)
    },

    onColumnFilter (params) {
      this.appliedFilter = true
      this.updateParams(params)
      this.loadItems(true)
    },

    async refreshGrid () {
      this.serverParams.columnFilters = {}
      this.serverParams.sort.field = ''
      this.serverParams.sort.type = ''
      this.serverParams.page = 1
      this.serverParams.perPage = 10
      this.updateParams(this.serverParams)
      this.$refs.payments.reset()
      this.$refs.payments.$refs.paginationBottom.perPage = this.serverParams.perPage
      this.$refs.payments.$refs.paginationBottom.handlePerPage()
      await this.loadItems(true)
    },

    loadItems (resetStart) {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: this.showPresubValMessages,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: resetStart,
        columnFilters: this.serverParams.columnFilters
      })

      return axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/payment/get/',
        data: json,
        showload: true
      })
        .then(response => {
          this.rows = response.data.payments
          if (this.rows.length > 0) {
            this.$store.dispatch('setPayments', response.data)
            this.$store.dispatch('setServerParams', this.serverParams)
            this.setupTable(response.data)
          }
        })
        .catch(error => console.log(error.message))
    },

    setupTable (response) {
      if (response.filtersSet === 0) {
        if (this.intelligibleSummaries[this.fileNumber - 1] !== undefined) {
          this.totalRecords = this.intelligibleSummaries[this.fileNumber - 1].totalRecordCount
        }
      } else {
        this.totalRecords = response.data.filteredRecordCount
      }

      if (this.rows[0].processingDate.length === 0) {
        this.columns[9].hidden = true
      } else {
        this.columns[9].hidden = false
        this.isMultiDate = true
      }
      if (this.rows[0].freeFormat === '0000') {
        this.columns[10].hidden = true
      } else {
        this.columns[10].hidden = false
      }
    },

    async createPaymentReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentReport?submissionId=' + this.submissionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async createPaymentCsvFile () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentCsvFile?submissionId=' + this.submissionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },

  mounted () {
    this.submissionId = this.$store.getters.submissionId

    this.fileNumber = this.$store.getters.fileNumber
    this.intelligibleSummaries = this.$store.getters.intelligibleSummaries

    var viewSubmissionData = this.$store.getters.viewSubmissionData
    if (viewSubmissionData.paymentsLoaded === false) {
      this.loadItems(true)
      viewSubmissionData.paymentsLoaded = true
      this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
    } else {
      this.serverParams = this.$store.getters.serverParams
      var response = this.$store.getters.payments
      this.rows = response.payments
      if (this.rows !== undefined) {
        this.setupTable(response)
      } else {
        this.rows = []
      }
    }
  }
}
</script>
