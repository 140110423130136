<template>
  <div id="payGateOnlineBureauConfig">
    <div v-if="payGateOnlineBureauConfigLoaded">
      <!-- HSM slots -->
      <div class="row form-group">
        <div class="col-md-2 required">HSM Slots</div>
        <div class="col-md-5">
          <b-form-select v-model="selectedSlotValue" :options="hsmSlots" @input="selectHsmSlot"></b-form-select>
        </div>
      </div>

      <!-- Slot certificates -->
      <div v-if="slotSelected === true" class="row form-group">
        <div class="col-md-2 required">HSM Slot Certificates</div>
        <div class="col-md-5">
          <b-form-select
            v-model="selectedCertificateValue"
            :options="slotCertificates"
            @input="selectSlotCertificate"
          ></b-form-select>
        </div>
      </div>

      <!-- Live service user number -->
      <div class="row form-group">
        <div class="col-md-2 required">Live Service User Number</div>
        <div
          class="col-md-6"
          :class="{invalid: $v.payGateOnlineBureauConfig.liveServiceUserNumber.$error}"
        >
          <input
            name="liveSUN"
            v-model.trim="$v.payGateOnlineBureauConfig.liveServiceUserNumber.$model"
            class="form-control"
            style="width: 100px;"
          />
          <validation-messages
            v-model="$v.payGateOnlineBureauConfig.liveServiceUserNumber"
            name="Live Service User Number"
          >
            <small
              class="form-text small"
              v-if="$v.payGateOnlineBureauConfig.liveServiceUserNumber.sunValidator != undefined && !$v.payGateOnlineBureauConfig.liveServiceUserNumber.sunValidator"
            >Incorrect bureau SUN format. The format for a bureau SUN is a B followed by 5 numbers.</small>
          </validation-messages>
        </div>
      </div>

      <!-- Test service user number -->
      <div class="row form-group">
        <div class="col-md-2 required">Test Service User Number</div>
        <div
          class="col-md-6"
          :class="{invalid: $v.payGateOnlineBureauConfig.testServiceUserNumber.$error}"
        >
          <input
            name="testSUN"
            v-model.trim="$v.payGateOnlineBureauConfig.testServiceUserNumber.$model"
            class="form-control"
            style="width: 100px;"
          />
          <validation-messages
            v-model="$v.payGateOnlineBureauConfig.testServiceUserNumber"
            name="Test Service User Number"
          >
            <small
              class="form-text small"
              v-if="$v.payGateOnlineBureauConfig.testServiceUserNumber.sunValidator != undefined && !$v.payGateOnlineBureauConfig.testServiceUserNumber.sunValidator"
            >Incorrect bureau SUN format. The format for a bureau SUN is a B followed by 5 numbers.</small>
          </validation-messages>
        </div>
      </div>

      <!-- Admin Email Address List -->
      <div class="row form-group">
        <div class="col-md-2 required">Admin Email Address</div>
        <div
          class="col-md-5"
          :class="{invalid: $v.payGateOnlineBureauConfig.adminEmailAddressList.$error}"
        >
          <input
            v-model.trim="$v.payGateOnlineBureauConfig.adminEmailAddressList.$model"
            class="form-control"
          />
          <validation-messages
            v-model="$v.payGateOnlineBureauConfig.adminEmailAddressList"
            name="Admin Email Address List"
          />
        </div>
      </div>

      <br />
      <div class="row form-group">
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="updatePaygateOnlineBureauConfig"
          >Save Paygate Online Bureau Config</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, helpers } from 'vuelidate/lib/validators'
import { email } from '@/Assets/Validators'


const sunValidator = helpers.regex('alpha', /^B\d{5}$/)

export default {

  data () {
    return {
      payGateOnlineBureauConfigLoaded: false,
      payGateOnlineBureauConfig: {},
      hsmSlotsAndCertificates: [],
      hsmSlots: [],
      selectedSlotValue: '',
      selectedCertificateValue: '',
      slotSelected: false,
      slotCertificates: []
    }
  },

  methods: {
    async getPaygateOnlineBureauConfig () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getPaygateOnlineBureauConfig`, { showload: true })
      this.payGateOnlineBureauConfig = response.data
    },

    async getHsmSlotsAndCertificates () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getHsmSlotsAndCertificates`, { showload: true })
      this.hsmSlotsAndCertificates = response.data
      this.hsmSlots = this.hsmSlotsAndCertificates.map(hsmSlot => {
        return { value: hsmSlot.slotName, text: hsmSlot.tokenLabel }
      })

      if (this.payGateOnlineBureauConfig !== null && this.payGateOnlineBureauConfig.hsmSlot !== null) {
        this.selectedSlotValue = this.payGateOnlineBureauConfig.hsmSlot
        this.selectHsmSlot(this.payGateOnlineBureauConfig.hsmSlot)
      }

      if (this.payGateOnlineBureauConfig !== null && this.payGateOnlineBureauConfig.hsmCertificateThumbprint !== null) {
        this.selectedCertificateValue = this.payGateOnlineBureauConfig.hsmCertificateThumbprint
      }

      this.payGateOnlineBureauConfigLoaded = true
    },

    selectHsmSlot (slot) {
      var tmpSlot = this.hsmSlotsAndCertificates.find(i => i.slotName === slot)
      if (tmpSlot !== undefined) {
        this.slotCertificates = tmpSlot.slotCertificates.map(certificate => {
          return { value: certificate.certificateThumbprint, text: certificate.certificateLabel }
        })

        this.slotCertificates.sort((a, b) => a.text.localeCompare(b.text))

        this.payGateOnlineBureauConfig.hsmSlot = tmpSlot.slotName
        this.payGateOnlineBureauConfig.hsmSlotTokenLabel = tmpSlot.tokenLabel
        this.slotSelected = true
      }
    },

    selectSlotCertificate (certificate) {
      var selectedCert = this.slotCertificates.find(i => i.value === certificate)
      this.payGateOnlineBureauConfig.hsmCertificateThumbprint = this.selectedCertificateValue
      this.payGateOnlineBureauConfig.hsmCertificateLabel = selectedCert.text
    },

    async updatePaygateOnlineBureauConfig () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/admin/updatePaygateOnlineBureauConfig',
        data: this.payGateOnlineBureauConfig,
        showload: true
      })

      if (response.data.status === 'Updated') {
        this.$toastr.s(response.data.toastMessage)
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    }
  },

  validations () {
    return {
      payGateOnlineBureauConfig: {
        liveServiceUserNumber: { required, sunValidator },
        testServiceUserNumber: { required, sunValidator },
        adminEmailAddressList: { required, email }
      }
    }
  },

  async mounted () {
    this.payGateOnlineBureauConfigLoaded = false
    this.selectedSlotValue = ''
    this.selectedCertificateValue = ''
    this.payGateOnlineBureauConfig = this.$store.getters.payGateOnlineBureauConfigData

    if (this.payGateOnlineBureauConfig === null || (Object.keys(this.payGateOnlineBureauConfig).length === 0 && this.payGateOnlineBureauConfig.constructor === Object)) {
      await this.getPaygateOnlineBureauConfig()
      await this.getHsmSlotsAndCertificates()
      this.$store.dispatch('setPayGateOnlineBureauConfigData', this.payGateOnlineBureauConfig)
    } else {
      await this.getHsmSlotsAndCertificates()
      this.selectedSlotValue = this.payGateOnlineBureauConfig.hsmSlot
      this.selectedCertificateValue = this.payGateOnlineBureauConfig.hsmCertificateThumbprint
    }
  }
}
</script>
