var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [_vm._v("Privacy Policy")])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("h4", [_vm._v("Personal information")]),
          _c("p", [
            _vm._v(
              " In general, you can visit the Paygate Solutions website without telling us who you are or revealing any personal information about yourself. If some parts of our website invite you to register and provide information about yourself, such as the support sections, the information that you supply will relate to the customer relationship that we have with you or your organisation. Your personal information will be retained only for as long as necessary to fulfil the purposes for which the information was collected or as required by law. If you give us personal information via the Internet, it is our intent to let you know how it will be used. We will use your personal information to provide the services and information you request. We may also use it to: Make the Paygate Solutions Website easier for you to use by not having to enter information more than once Help Paygate Solutions customise content most relevant to you Notify you about important news concerning Paygate Solutions, Jonas Software and/or its subsidiaries In the process of administering the Paygate Solutions website, we maintain and track usage through web server logs. Web server logs provide such information as what types of browsers are accessing our website, which pages receive high traffic and the times of day our servers experience significant load. We use this information to improve the content and navigation features of our website, but not to personally identify you. "
            )
          ]),
          _c("h4", [_vm._v("Correcting/updating personal information")]),
          _c("p", [
            _vm._v(
              " If a visitor's personal Information changes, we provide a way to correct, update or remove any personal information held on that visitor in our active databases. We may need to retain archive copies of that personal information where required for legal or audit purposes. "
            )
          ]),
          _c("h4", [_vm._v("How Paygate Solutions shares it")]),
          _c("p", [
            _vm._v(
              " Paygate Solutions will not sell, rent or lease your personal information to anyone else. We may share the personal data you provide with our professional advisors, sales force, tied intermediaries or website developers who are acting on our behalf for the uses described above, except as provided below (see Consent). "
            )
          ]),
          _c("h4", [_vm._v("Cookies")]),
          _c("p", [
            _vm._v(
              " We collect standard information about visits to our website through the collection of log files, Google Analytics and other similar lead trackers. These log files track user preferences, page visits, and browser and operating system usage. These logs and analytical systems do not attempt to collect any personal information that can identify a visitor. They are used to ensure our website is built to provide a superior browsing experience. Our website uses several cookies for tracking purposes: "
            )
          ]),
          _c("h5", [_vm._v("Google Analytics")]),
          _c("p", [
            _vm._v(
              " The Google Analytics cookies are used to help Google track usage of our website. We use this analytics information to compile data on key pages, key words in search terms and to gain a better understanding of the usage of our website. This cookie does not collect any personal information. "
            )
          ]),
          _c("h5", [_vm._v("EU cookie law")]),
          _c("p", [
            _vm._v(
              " This cookie is left on your computer when you dismiss the cookie alert we show on our website. This allows us to ensure you are not reminded about cookies when you next visit our website. "
            )
          ]),
          _c("h4", [_vm._v("Consent")]),
          _c("p", [
            _vm._v(
              " By using the Paygate Solutions website, you consent to the computer storage and processing of your personal data by Paygate Solutions, our subsidiaries and professional advisors. The personally identifiable information you have provided will be used and shared as described above. We reserve the right to disclose any and all pertinent customer information to law enforcement or other government officials as, in our sole discretion, believe necessary or appropriate. "
            )
          ]),
          _c("h4", [_vm._v("Security")]),
          _c("p", [
            _vm._v(
              " We will take all reasonable precautions to protect our website visitors' information. When visitors submit information via the Paygate Solutions website, we try to ensure that information is protected both on and off-line. As a minimum we use encryption and other methods to protect sensitive information on-line but we may employ other methods or forms of security from time to time as well. Off-line, your information is protected by a number of strictly regulated processes and measures. "
            )
          ]),
          _c("h4", [_vm._v("Third party links")]),
          _c("p", [
            _vm._v(
              " Links to third party websites from the Paygate Solutions website are provided solely as a convenience to you. If you use these links, you will leave the Paygate Solutions website. Paygate Solutions has not reviewed these third-party websites and does not control them. We assume no responsibility for any of these websites, their content or their privacy policies. We do not endorse or make any representations about them or any information, software or other products or materials found there, or any results that may be obtained from using them. If you decide to access any of the third-party websites linked to the Paygate Solutions Website, you do so at your own risk. "
            )
          ]),
          _c("h4", [_vm._v("Your acceptance of these terms")]),
          _c("p", [
            _vm._v(
              " By using Paygate Solutions' website, you signify your assent to this Privacy Statement. If we update or change this Privacy Statement, the changes will be made on this page. Your continued use of the Paygate Solutions website following the posting of changes to this Privacy Statement will mean you accept those changes. "
            )
          ]),
          _c("h4", [_vm._v("How to contact Paygate Solutions")]),
          _c("p", [
            _vm._v(
              " Should you have questions or concerns about this Privacy Statement, please letter post or e-mail Paygate Solutions at: "
            )
          ]),
          _c("address", [
            _vm._v(
              " Paygate Solutions Ltd. Gladstone House, Hithercroft Road, Wallingford, Oxfordshire. OX10 9BT Tel: "
            ),
            _c("a", { attrs: { href: "tel:+441462708444" } }, [
              _vm._v("+44 (0) 1462 708 444")
            ]),
            _vm._v(" Email: "),
            _c("a", { attrs: { href: "emailto:support@paygate.uk" } }, [
              _vm._v("support@paygate.uk")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }