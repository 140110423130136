<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Submissions In Progress
          <span class="pull-right">
            <a href="#" target="_blank">
              <i class="far fa-question-circle"></i>
            </a>
          </span>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          styleClass="vgt-table striped bordered"
          :searchOptions="{
                          enabled: true
                        }"
          :sort-options="sortOptions"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-sort-change="onSortChange"
          ref="table"
          :isLoading.sync="isTableLoading"
        >
          <!-- <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>-->

          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'delete' && !props.row.preventDelete">
              <a class="btn btn-sm primary" @on-cell-click="onCellClick" :disabled="isLoading">
                <i class="fa fa-trash"></i>
              </a>
            </span>
            <span v-else>
              <b-row>
                <b-col sm="12" class="mt-1">{{ props.formattedRow[props.column.field] }}</b-col>
              </b-row>
            </span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="printTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
            >
              <i class="fa fa-print pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
            >
              <i class="fa fa-file-export pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import Utility from '@/Assets/Mixins/Utility'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'

export default {
  mixins: [Utility, tableFilterMixin, loading],
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Bureau Submission Id',
          field: 'bureauSubmissionId',
          hidden: true
        }, {
          label: 'Submission Reference',
          field: 'submissionReference'
        },
        {
          label: 'Job',
          field: 'bureauJobName'
        },
        {
          label: 'Last Modification',
          field: 'lastUpdateDate'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          field: 'delete'
        }
      ],
      serverParams: {
        sort: [{ field: 'lastUpdateDate', type: 'desc' }]
      },
      totalRecords: 0
      // loading: false
    }
  },
  created () {
    this.load()
  },
  methods: {
    load: _.debounce(async function () {
      try {
        this.isTableLoading = true
        console.log('pending submissions table')
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/getpendingsubmissionsquery`, { params: { ...this.buildGoodTableQuery() }, showerror: true, errormessage: 'Failed loading submissions' })
        console.log(' response for pending submissions table', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    }, 500),
    async onRowClick (data) {
      console.log('pending rowclick', data)

      switch (data.row.lastScreenState) {
        case 'BureauManualBacsAddInitialFiles':
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauManualFPSAddInitialFiles':
          this.$router.push({ name: 'ManualFPSSubmissionAddFiles', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauManualBacsImporting':
          this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauManualFPSImporting':
          this.$router.push({ name: 'ManualFPSImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoBacsImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoFPSImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauBacsViewPayments':
          this.$router.push({ name: 'PaymentDetails', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauFPSViewPayments':
          this.$router.push({ name: 'PaymentDetailsFPS', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauActionsPage':
          var actionUrl = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getAction?submissionId=' + data.row.bureauSubmissionId, { showload: true })
          if (actionUrl.data.tag) {
            console.log('making route json')
            var routeJson = {}
            routeJson.id = actionUrl.data.messageId
            routeJson.actionedObjectId = data.row.bureauSubmissionId
            routeJson.actionClass = 'Bacs'
            routeJson.actionSubClass = 'Sign'
            var testReturnRouteObject = returnRouteObject(routeJson)
            console.log('testReturnRouteObject', testReturnRouteObject)
            this.$router.replace(returnRouteObject(routeJson))
          } else {
            this.autoReturnToHomePage = true
            this.$router.push('/')
          }
          break
      }

      // var readonly = data.row.preventDelete
      // if (readonly) {
      //   this.$router.push({ name: 'PaymentDetails', params: { id: data.row.bureauSubmissionId } })
      // } else {
      //   this.$router.push({ name: 'UploadPaymentFiles', params: { id: data.row.bureauSubmissionId } })
      // }
    },
    async onCellClick (params) {
      if (params.column.field === 'delete') {
        const submissionId = params.row.bureauSubmissionId
        const submissionReference = params.row.submissionReference
        console.log(`deleting submission ${submissionId}`)
        event.stopPropagation()

        const swalResult = await swal.fire({
          title: 'Delete Submission',
          text: `Are you sure you want to delete the submission: ${submissionReference}`,
          icon: 'warning',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })

        if (swalResult.isConfirmed) {
          try {
            this.loading = true
            const response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteSubmission/' + submissionId, {})
            console.info('Delete submission response:')
            console.info(response)
            if (response.data.status === 'Deleted') {
              this.$toastr.s(`Bureau Submission ${submissionReference} deleted`, 'Bureau Submission')
              this.load()
            } else {
              console.error('Failed to delete bureau job')
              console.error(response)
            }
          } catch (error) {
            console.error('Failed to call API')
            console.error(error)
          }
          this.loading = false
        }
      }
    }
  }
}
</script>
