<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Trigger Explorer<span class="pull-right"><a :href="`${docUrl}/automation/logging/triggerexplorer/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content" v-if="displaytems">
          <div class="alert alert-info">
            The Trigger Explorer displays near real-time information about the state of your <strong>time-based</strong> triggers: Cron Triggers and Schedule Triggers.
          </div>
          <div class="row">
            <div class="col-md-2 col-xs-12 col-sm-12 font-weight-bold small">Name</div>
            <div class="col-md-2 col-xs-12 col-sm-12 font-weight-bold small">Type</div>
            <div class="col-md-2 col-xs-12 col-sm-12 font-weight-bold small">Next Invocation</div>
            <div class="col-md-3 col-xs-12 col-sm-12 font-weight-bold small">Bound Workflows</div>
            <div class="col-md-3 col-xs-12 col-sm-12 font-weight-bold small"></div>
          </div>
          <hr>
          <div v-for="(item, index) in triggerData" :key="index">
            <div class="row" >
              <div class="col-md-2 col-xs-12 col-sm-12 small">
                <span v-if="item.trigger.triggerType == 'scheduleTrigger'">
                  <router-link tag="a" :to="'/automation/workflow/triggerconfig/scheduleTrigger/' + item.id" class="">{{item.trigger.title}}</router-link>
                </span>
                <span v-if="item.trigger.triggerType == 'cronTrigger'">
                  <router-link tag="a" :to="'/automation/workflow/triggerconfig/cronTrigger/' + item.id" class="">{{item.trigger.title}}</router-link>
                </span>
              </div>
              <div class="col-md-2 col-xs-12 col-sm-12 small">
                <span  v-if="item.trigger.triggerType == 'scheduleTrigger'"><i class="far fa-calendar-alt"></i> Schedule Trigger</span>
                <span  v-else-if="item.trigger.triggerType == 'cronTrigger'"><i class="far fa-clock"></i> Cron Trigger</span>
                <span v-if="item.trigger.enabled === false" class="ml-1">(Disabled)</span>
              </div>
              <div class="col-md-2 col-xs-12 col-sm-12 small"  >
                <span v-if="item.trigger.enabled">{{reformatDate(item.nextInvocation)}}</span>
              </div>
              <div class="col-md-2 col-xs-12 col-sm-12 small">
                <div class="mb-2" v-for="(wf, index) in item.trigger.boundWorkflows" :key="index">
                  <span v-if="wf.enabled" v-b-popover.hover.top.d500="'The workflow is enabled'"><i class="far fa-check-circle text-success"></i></span>
                  <span v-else v-b-popover.hover.top.d500="'The workflow is disabled and will not execute when the trigger fires.'"><i class="far fa-times-circle text-danger "></i></span>
                  {{wf.Title}} (
                  <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + wf.id" class="">Edit</router-link>
                  )
                </div>
              </div>
              <div class="col-md-4 col-xs-12 col-sm-12 small">
                <button @click="btnCancelNext(item)" v-b-popover.hover.top.d500="'Cancels the next invocation of the trigger.'" class="btn btn-small btn-outline-danger m-1"><i class="fas fa-times"></i> Cancel next</button>
                <button @click="btnReset(item)" v-b-popover.hover.top.d500="'Resets any cancelled invocations.'" class="btn btn-small btn-outline-warning m-1"><i class="fas fa-redo"></i> Reset</button>
                <button @click="btnTelemetry(item)" v-b-popover.hover.top.d500="'Displays logs from workflows that this trigger has created.'" class="btn btn-small btn-outline-primary m-1"><i class="fas fa-list"></i> Logs</button>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div class="idb-block-footer" v-if="displaytems">
          <div>
            <button class="btn btn-primary" @click="getTriggerData"><i class="fas fa-sync-alt "></i> Refresh Results</button>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  name: 'my-component',
  mixins: [loading],
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      interval: null,
      triggerData: [],
      displaytems: true
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async btnReset (trigger) {
      this.$snapbar.hide()
      try {
        await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}resettrigger/${trigger.id}`, { showload: true })
        await this.getTriggerData()
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to reset this trigger.')
        } else {
          this.$snapbar.e(`Could not reset this trigger - ${e.message}`)
        }
      }
    },
    async btnCancelNext (trigger) {
      this.$snapbar.hide()
      try {
        await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}cancelnexttrigger/${trigger.id}`, { showload: true })
        await this.getTriggerData()
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to cancel this trigger.')
        } else {
          this.$snapbar.e(`Could not cancel this trigger - ${e.message}`)
        }
      }
    },
    btnTelemetry (trigger) {
      this.$router.push(`/automation/workflow/triggerTelemetry/${trigger.id}`)
    },
    async getTriggerData () {
      this.$snapbar.hide()
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}triggerexplorer`, { showload: true })
        if (res && res.data) {
          this.triggerData = res.data
        } else {
          this.triggerData = []
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this data.')
        } else {
          this.$snapbar.e(`Could not view this trigger - ${e.message}`)
        }
        this.triggerData = []
      }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    }

  },
  created: async function () {
    this.$snapbar.hide()
    this.displaytems = Store.getters.isInRoles([roles.SystemUser]) || Store.getters.isInRoles([roles.ManageWorkflow])
    if (this.displaytems) {
      await this.getTriggerData()
    } else {
      this.$snapbar.e('You are not permitted to view this page.')
    }
  },
  mounted: function () {
    if (this.displaytems) {
      this.interval = setInterval(function () {
        this.getTriggerData()
      }.bind(this), 30000)
    }
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      await this.getTriggerData()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
