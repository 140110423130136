var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-panel" },
    [
      _c("vue-perfect-scrollbar", { attrs: { settings: _vm.settings } }, [
        _c("div", { staticClass: "idb-sidebar-nav" }, [
          _c("div", { staticClass: "ps-container ps-theme-default" }, [
            _c("nav", { staticClass: "navigation scroll-area" }, [
              _c(
                "ul",
                { staticClass: "idb-nav list-unstyled list-hover-slide" },
                [
                  _vm._l(_vm.$store.state.menu.menu.routes, function(module) {
                    return [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: module.show,
                              expression: "module.show"
                            }
                          ],
                          key: module.path,
                          staticClass: "nav-item",
                          class: { "active open": module.active },
                          attrs: { id: module.name }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.open(
                                    module,
                                    _vm.$store.state.menu.menu
                                  )
                                }
                              }
                            },
                            [
                              _c("div", [
                                _c("i", { class: module.menu_icon }),
                                _c(
                                  "span",
                                  { staticClass: "menu-title" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(module.menu_title) + " "
                                    ),
                                    _vm.showParentAlert(module)
                                      ? _c(
                                          "b-badge",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              pill: "",
                                              variant: _vm.getSeverityLevelClass(
                                                _vm.getHighestChildSeverityLevel(
                                                  module
                                                )
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.childAlertCount(module) > 1
                                                  ? "!"
                                                  : _vm.childAlertCount(module)
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "float-right" }, [
                                  _c("i", { staticClass: "ti-angle-down" })
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "idb-nav sub-menu list-unstyled list-hover-slide"
                            },
                            [
                              _vm._l(module.routes, function(menu) {
                                return [
                                  menu.child_routes != null
                                    ? _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: menu.show,
                                              expression: "menu.show"
                                            }
                                          ],
                                          key: menu.menu_title,
                                          staticClass: "nav-item",
                                          class: { "active open": menu.active }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "nav-link",
                                              attrs: {
                                                href: "#",
                                                id: menu.name
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.open(menu, module)
                                                }
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c("i", {
                                                  class: menu.menu_icon,
                                                  staticStyle: { width: "20px" }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "menu-title" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          menu.menu_title
                                                        ) +
                                                        " "
                                                    ),
                                                    _vm.showSubMenuAlert(
                                                      module,
                                                      menu.menu_title
                                                    )
                                                      ? _c(
                                                          "b-badge",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              pill: "",
                                                              variant: "danger"
                                                            }
                                                          },
                                                          [_vm._v("!")]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "float-right"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "ti-angle-down"
                                                    })
                                                  ]
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "list-unstyled sub-menu"
                                            },
                                            _vm._l(menu.child_routes, function(
                                              subMenu
                                            ) {
                                              return _c(
                                                "router-link",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: subMenu.show,
                                                      expression: "subMenu.show"
                                                    }
                                                  ],
                                                  key: subMenu.path,
                                                  attrs: {
                                                    to: subMenu.path,
                                                    tag: "li",
                                                    id: subMenu.name
                                                  }
                                                },
                                                [
                                                  _c("a", [
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              subMenu.menu_title
                                                            ) +
                                                            " "
                                                        ),
                                                        subMenu.counterData !==
                                                          undefined &&
                                                        _vm.fromGetter(
                                                          subMenu.counterData
                                                        ).count !== 0
                                                          ? _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  pill: "",
                                                                  variant: _vm.getSeverityLevelClass(
                                                                    subMenu.severityData
                                                                      ? _vm.fromGetter(
                                                                          subMenu.severityData
                                                                        )
                                                                      : 0
                                                                  )
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    !_vm.fromGetter(
                                                                      subMenu.counterData
                                                                    )
                                                                      .treatAsBoolean
                                                                      ? _vm.fromGetter(
                                                                          subMenu.counterData
                                                                        ).count
                                                                      : "!"
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            }),
                                            1
                                          )
                                        ]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: menu.show,
                                              expression: "menu.show"
                                            }
                                          ],
                                          key: menu.menu_title,
                                          staticClass: "nav-item",
                                          attrs: { to: menu.path, tag: "li" }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "nav-link",
                                              on: {
                                                click: function($event) {
                                                  return _vm.open(menu, module)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                class: menu.menu_icon,
                                                staticStyle: { width: "20px" }
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "menu-title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(menu.menu_title) +
                                                      " "
                                                  ),
                                                  menu.counterData !==
                                                    undefined &&
                                                  _vm.fromGetter(
                                                    menu.counterData
                                                  ).count !== 0
                                                    ? _c(
                                                        "b-badge",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            pill: "",
                                                            variant: _vm.getSeverityLevelClass(
                                                              menu.severityData
                                                                ? _vm.fromGetter(
                                                                    menu.severityData
                                                                  )
                                                                : 0
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              !_vm.fromGetter(
                                                                menu.counterData
                                                              ).treatAsBoolean
                                                                ? _vm.fromGetter(
                                                                    menu.counterData
                                                                  ).count
                                                                : "!"
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }