<template>
  <div>
    <form
      @submit.prevent="checkValidation('There are validation errors on the form', [passwords.$v]) && saveUser()"
      novalidate
    >
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            <div v-if="!canManageUsers">
              View User
              <help-icon :docPath="docPath" />
            </div>
            <div v-else>
              {{status}} {{type}}
              <help-icon :docPath="docPath" />
            </div>
          </h2>
        </div>
        <div class="idb-block-content">
          <div
            class="alert alert-warning"
            v-if="user.isItemActioned"
          >This user has a pending action against it and cannot be edited</div>
          <!-- Name -->
          <div
            class="form-group row"
            :class="{invalid: $v.user.name.$error}"
            v-if="!user.apiServiceAccount"
          >
            <label class="col-form-label col-md-3 required">Name</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="$v.user.name.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
              />
              <!-- Validation -->
              <validation-messages v-model="$v.user.name" name="name"></validation-messages>
            </div>
          </div>

          <!-- Email -->
          <div
            class="form-group row"
            :class="{invalid: $v.user.email.$error || $v.emailBounced.$invalid}"
            v-if="!user.apiServiceAccount"
          >
            <label class="col-form-label col-md-3 required">Email</label>
            <div class="col-md-5">
              <input
                type="email"
                class="form-control"
                v-model.trim="$v.user.email.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
                autocomplete="off"
              />
              <!-- Validation -->
              <validation-messages v-model="$v.user.email" name="email"></validation-messages>
              <validation-messages v-model="$v.emailBounced" name="Email Bounced">
                <small
                  class="form-text small"
                >This email address has bounced in the past, and may be invalid.</small>
              </validation-messages>
              <small class="form-text text-muted">This will be used to login to the application</small>
            </div>
          </div>

          <!-- Enabled -->
          <div
            v-if="status === 'Edit'"
            class="form-group row"
            :class="{invalid: $v.user.enabled.$error}"
          >
            <label class="col-form-label col-md-3">Enabled</label>
            <div class="col-md-5">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.user.enabled.$model"
                :disabled="!canManageUsers"
              ></p-check>
              <!-- Validation -->
              <validation-messages v-model="$v.user.enabled" name="enabled"></validation-messages>
            </div>
          </div>
          <div v-if="status === 'Create'">
            <!-- Password -->
            <change-password-inputs
              v-model="passwords"
              formGroupClass="row"
              inputClass="col-md-5"
              labelClass="col-form-label col-md-3"
            ></change-password-inputs>
          </div>
          <!-- Mobile Phone -->
          <div
            class="form-group row"
            :class="{invalid: $v.user.phoneNumber.$error}"
            v-if="!user.apiServiceAccount"
          >
            <label class="col-form-label col-md-3">Mobile Phone</label>
            <div class="col-md-5">
              <input
                type="tel"
                class="form-control"
                v-model.trim="$v.user.phoneNumber.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
              />
              <!-- Validation -->
              <validation-messages v-model="$v.user.phoneNumber" name="phone number">
                <small
                  class="form-text small"
                  v-if="!$v.user.phoneNumber.phoneNumber"
                >This is not a valid phone number</small>
              </validation-messages>
            </div>
          </div>

          <!-- Email Action -->
          <div
            class="form-group row"
            :class="{invalid: $v.user.emailAction.$error}"
            v-if="!user.apiServiceAccount"
          >
            <label class="col-form-label col-md-3">Email Action</label>
            <div class="col-md-5">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.user.emailAction.$model"
                :disabled="!canManageUsers"
              ></p-check>
              <!-- Validation -->
              <validation-messages v-model="$v.user.emailAction" name="email action"></validation-messages>
            </div>
          </div>

          <div
            class="form-group row"
            v-if="!user.apiServiceAccount && mfaEnabled && checkTokenAtLogin && twoFactorMethods.length > 2"
          >
            <label class="col-form-label col-md-3">Default Login Two Factor Method</label>
            <div class="col-md-3">
              <b-form-select
                v-model="$v.user.defaultTwoFactor.$model"
                :options="twoFactorMethods"
                :disabled="!canManageUsers"
              />
              <small
                class="form-text text-muted"
              >This will restrict this user to this choice of 2FA at login</small>
            </div>
          </div>

          <!-- Individual Item Limit -->
          <div class="form-group row" :class="{invalid: $v.user.paymentLimit.$error}">
            <label class="col-form-label col-md-3">Individual Item Limit (£)</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model="$v.user.paymentLimit.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
                v-integer
              />
              <!-- Validation -->
              <validation-messages v-model="$v.user.paymentLimit" name="individual item limit">
                <small
                  class="form-text small"
                  v-if="$v.user.paymentLimit.wholepounds != undefined && !$v.user.paymentLimit.wholepounds"
                >individual item limit can only be in whole pounds</small>
              </validation-messages>
              <small class="form-text text-muted">Set to 0 for no limit</small>
            </div>
          </div>

          <!-- Submission Limit -->
          <div class="form-group row" :class="{invalid: $v.user.submissionLimit.$error}">
            <label class="col-form-label col-md-3">Submission Limit (£)</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                step="1"
                v-model.trim="$v.user.submissionLimit.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
                v-integer
              />
              <!-- Validation -->
              <validation-messages v-model="$v.user.submissionLimit" name="submission limit">
                <small
                  class="form-text small"
                  v-if="$v.user.submissionLimit.wholepounds != undefined && !$v.user.submissionLimit.wholepounds"
                >submission limit can only be in whole pounds</small>
              </validation-messages>
              <small class="form-text text-muted">Set to 0 for no limit</small>
            </div>
          </div>

          <!-- Approval Limit -->
          <div class="form-group row" :class="{invalid: $v.user.approvalLimit.$error}">
            <label class="col-form-label col-md-3">Approval Limit (£)</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="$v.user.approvalLimit.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
                v-integer
              />
              <!-- Validation -->
              <validation-messages v-model="$v.user.approvalLimit" name="approval limit">
                <small
                  class="form-text small"
                  v-if="$v.user.approvalLimit.wholepounds != undefined && !$v.user.approvalLimit.wholepounds"
                >approval limit can only be in whole pounds</small>
              </validation-messages>
              <small class="form-text text-muted">Set to 0 for no limit</small>
            </div>
          </div>

          <!-- HSM PIN -->
          <div
            class="form-group row"
            :class="{invalid: $v.user.hsmPin.$error}"
            v-if="!user.apiServiceAccount"
          >
            <label class="col-form-label col-md-3">HSM and Approval Password</label>
            <div class="col-md-5">
              <input
                :type="hsmPinToggled? 'text': 'password'"
                class="form-control"
                v-model.trim="$v.user.hsmPin.$model"
                :disabled="user.isItemActioned"
                :readonly="!canManageUsers"
              />
              <span
                :class="{'fa-eye-slash': hsmPinToggled, 'fa-eye': !hsmPinToggled }"
                class="fa fa-fw field-icon"
                @mousedown="toggleHSMPin(true)"
                @mouseup="toggleHSMPin(false)"
              ></span>
              <!-- Validation -->
              <validation-messages v-model="$v.user.hsmPin" name="HSM and approval password"></validation-messages>
            </div>
          </div>
          <br />
          <b-tabs>
            <b-tab title="Common">
              <roles
                roleType="Common"
                :userRoles="user.rolesToAdd"
                @rolesChanged="rolesChanged"
                :disabled="user.isItemActioned || !canManageUsers"
                :roles="databaseRoles.Common"
              ></roles>
            </b-tab>
            <b-tab title="Bureau">
              <roles
                roleType="Bureau"
                :userRoles="user.rolesToAdd"
                @rolesChanged="rolesChanged"
                :disabled="user.isItemActioned || !canManageUsers"
                :roles="databaseRoles.Bureau"
              ></roles>
            </b-tab>
            <b-tab title="Agent">
              <roles
                roleType="Agent"
                :userRoles="user.rolesToAdd"
                @rolesChanged="rolesChanged"
                :disabled="user.isItemActioned || !canManageUsers"
                :roles="databaseRoles.Agent"
              ></roles>
            </b-tab>
            <b-tab title="Automation">
              <roles
                roleType="Automation"
                :userRoles="user.rolesToAdd"
                @rolesChanged="rolesChanged"
                :disabled="user.isItemActioned || !canManageUsers"
                :roles="databaseRoles.Automation"
              ></roles>
            </b-tab>
            <b-tab title="Collections">
              <roles
                roleType="Collections"
                :userRoles="user.rolesToAdd"
                @rolesChanged="rolesChanged"
                :disabled="user.isItemActioned || !canManageUsers"
                :roles="databaseRoles.Collections"
              ></roles>
            </b-tab>
            <b-tab title="System" v-if="corvidId === user.paygateId">
              <roles
                roleType="System"
                :userRoles="user.rolesToAdd"
                @rolesChanged="rolesChanged"
                :disabled="user.isItemActioned || !canManageUsers"
                :roles="databaseRoles.System"
              ></roles>
            </b-tab>
          </b-tabs>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            :disabled="user.isItemActioned || isLoading"
            type="submit"
            v-if="canManageUsers"
          >{{status === 'Edit'?'Save':status}}</button>
          <button
            v-if="status === 'Edit' && canManageUsers && !user.apiServiceAccount"
            class="btn btn-outline-primary ml-3"
            :disabled="user.isItemActioned || isLoading"
            type="button"
            @click="showCloneModel"
          >
            <i class="glyphicon ti-layers rpad"></i>Clone
          </button>
          <button
            class="btn btn-outline-primary ml-3"
            type="button"
            :disabled="isLoading"
            @click="openChangePasswordModal"
            v-if="status !== 'Create' && canManageUsers && !user.apiServiceAccount"
          >Reset Password</button>
          <button
            class="btn btn-outline-primary ml-3"
            type="button"
            :disabled="isLoading"
            @click="unlockUser"
            v-if="status !== 'Create' && canManageUsers && user.isLockedOut && !user.apiServiceAccount"
          >Unlock User</button>
          <button
            class="btn btn-outline-primary ml-3"
            type="button"
            :disabled="isLoading"
            @click="openViewLoginHistory"
            v-if="status !== 'Create' && canManageUsers"
          >View Login History</button>
          <button
            class="btn btn-danger pull-right ml-3"
            type="button"
            :disabled="isLoading"
            @click="cancel"
          >Cancel</button>
          <button
            v-if="status === 'Edit' && canManageUsers && !user.apiServiceAccount"
            class="btn btn-danger pull-right"
            @click="deleteUser"
            :disabled="user.isItemActioned || isLoading"
            type="button"
          >
            <i class="glyphicon ti-trash rpad"></i>Delete User
          </button>
        </div>
      </div>
    </form>

    <b-modal
      id="change-password"
      ref="change-password"
      title="Reset User Password"
      no-close-on-backdrop
      :centered="true"
    >
      <form @submit.prevent="changePassword()" novalidate v-if="!user.apiServiceAccount">
        <div class="alert alert-danger" v-if="anyErrors" role="alert">
          <span v-for="error in passwordErrors" v-bind:key="error">{{error}}</span>
        </div>

        <change-password-inputs
          ref="change-password-inputs"
          v-model="passwords"
          v-bind:user-id="userId"
          formGroupClass="col-md-10"
        ></change-password-inputs>
      </form>
      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-secondary-outline"
          @click="closeChangePasswordModal"
        >Cancel</button>
        <button
          type="submit"
          class="btn btn-primary"
          :disable="passwords.loading"
          @click="changePassword()"
        >Reset Password</button>
      </template>
    </b-modal>

    <b-modal
      id="cloneModal"
      ref="clone-modal"
      title="Clone User"
      ok-title="Clone"
      cancel-variant="secondary-outline"
      @ok="cloneUser"
      @hidden="resetModal"
      no-close-on-backdrop
      :centered="true"
      @shown="setCloneModalFocus"
    >
      <div class="form-group row" :class="{invalid: $v.user.clonedName.$error}">
        <label class="col-form-label col-md-3 required">New Name</label>
        <div class="col-md-9">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.user.clonedName.$model"
            ref="defaultElement"
          />
          <!-- Validation -->
          <validation-messages v-model="$v.user.clonedName" name="new name">
            <small
              class="form-text small"
              v-if="$v.user.clonedName.notSameAs !== undefined && !$v.user.clonedName.notSameAs"
            >Cloned name cannot be the same as the original name</small>
          </validation-messages>
        </div>
      </div>
      <div class="form-group row" :class="{invalid: $v.user.clonedEmail.$error}">
        <label class="col-form-label col-md-3 required">New Email</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model.trim="$v.user.clonedEmail.$model" />
          <!-- Validation -->
          <validation-messages v-model="$v.user.clonedEmail" name="new email">
            <small
              class="form-text small"
              v-if="$v.user.clonedEmail.notSameAs !== undefined && !$v.user.clonedEmail.notSameAs"
            >Cloned email cannot be the same as the original email</small>
          </validation-messages>
        </div>
      </div>
      <div class="form-group row" :class="{invalid: $v.user.clonedPassword.$error}">
        <label class="col-form-label col-md-3 required">Password</label>
        <div class="col-md-5">
          <input type="password" class="form-control" v-model.trim="$v.user.clonedPassword.$model" />
          <!-- Validation -->
          <validation-messages v-model="$v.user.clonedPassword" name="password"></validation-messages>
        </div>
      </div>
      <div class="form-group row" :class="{invalid: $v.user.clonedConfirmPassword.$error}">
        <label class="col-form-label col-md-3 required">Confirm Password</label>
        <div class="col-md-5">
          <input
            type="password"
            class="form-control"
            v-model.trim="$v.user.clonedConfirmPassword.$model"
          />
          <!-- Validation -->
          <validation-messages v-model="$v.user.clonedConfirmPassword" name="confirm password">
            <small
              class="form-text small"
              v-if="!$v.user.clonedConfirmPassword.sameAs"
            >Passwords do not match</small>
          </validation-messages>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="viewLoginHistory"
      ref="login-history"
      title="Login History For The Past Month"
      ok-title="Close"
      :ok-only="true"
      @ok="$refs['login-history'].hide()"
      no-close-on-backdrop
      :centered="true"
      size="lg"
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Time</th>
            <th scope="col">Application</th>
            <th scope="col">IP Address</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="login in loginHistory" :key="login.createdDate">
            <th>{{login.auditTitle}}</th>
            <td>{{login.createdDate | formatDate('DD/MM/YYYY HH:mm')}}</td>
            <td>{{login.application}}</td>
            <td>{{login.ipAddress}}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import Roles from './Roles.vue'
import { required, maxLength, sameAs, numeric, requiredIf, not, minValue, maxValue } from 'vuelidate/lib/validators'
import swal from 'sweetalert2'
import axios from 'axios'
import changePasswordInputs from '@/Assets/Components/UserSettings/ChangePassword/ChangePasswordInputs'
import _ from 'lodash'
import roles from '@/Assets/Constants/roles'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'

import { phoneNumber, email } from '@/Assets/Validators'
import { mapGetters } from 'vuex'

export default {
  mixins: [DataLeaveMixin, loading],
  props: ['status', 'userId'],
  components: {
    Roles,
    changePasswordInputs
  },
  computed: {
    corvidId () {
      return process.env.VUE_APP_CORVID_ID
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    corvidUser () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    customerAdmin () {
      return this.$store.getters.isInRole(roles.CustomerAdministrator)
    },
    canManageUsers () {
      return this.$store.getters.isInRole(roles.ManageUsers)
    },
    anyErrors () {
      // some with this will return true if anything exists in JS
      return this.passwordErrors.some(x => x)
    },
    docPath () {
      switch (this.status) {
        case 'Create':
          return '/administration/users/createuser/'
        case 'Edit':
          return '/administration/users/edituser/'
        default:
          return null
      }
    },
    type () {
      if (this.user.apiServiceAccount) {
        return 'Service Account'
      }
      return 'User'
    },
    ...mapGetters(['selectedCustomer', 'twoFactorMethods', 'mfaEnabled'])
  },
  data () {
    return {
      user: {
        name: null,
        email: null,
        enabled: false,
        phoneNumber: null,
        password: null,
        confirmPassword: null,
        paygateId: null,
        paymentLimit: null,
        submissionLimit: null,
        approvalLimit: null,
        rolesToAdd: [],
        defaultTwoFactor: null,
        hsmPin: null,
        emailAction: false,
        apiServiceAccount: false,
        clonedName: null,
        clonedEmail: null,
        clonedPassword: null,
        clonedConfirmPassword: null,
        isLockedOut: false
      },
      databaseRoles: {
        Common: [],
        Payments: [],
        Bureau: [],
        Agent: [],
        Automation: [],
        Collections: [],
        System: []
      },
      passwords: {
        password: null,
        confirmPassword: null,
        valid: null
      },
      passwordLoading: false,
      passwordErrors: [],
      roles: {},
      emailBounced: false,
      cloning: false,
      hsmPinToggled: false,
      checkTokenAtLogin: false,
      loginHistory: []
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.loadUser()
    } else {
      this.user.paygateId = this.paygateId
    }
    await this.$store.dispatch('loadLoginPolicy')
    await this.loadRoles()
    await this.loadLoginPolicy()
  },
  methods: {
    async saveUser () {
      try {
        this.user.rolesToAdd = Object.keys(this.roles).reduce((a, v) => a.concat(this.roles[v]), [])
        if (this.status === 'Create') {
          this.user.password = this.passwords.password
          this.user.confirmPassword = this.passwords.confirmPassword
          const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Users`, this.user,
            { showload: true, showerror: true, errormessage: 'User failed to create' })
          if (response.data.status === 'Action') {
            this.$toastr.s(`New ${this.type} needs to be approved`, 'Needs Approval')
            this.$v.$reset()
            this.$router.push({ name: 'Users' })
          } else {
            this.$toastr.s(`New ${this.type} created`, 'Created')
            this.$v.$reset()
            this.$router.push({ name: 'Users' })
          }
        } else {
          const response = await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Users`, this.user,
            { showload: true, showerror: true, errormessage: `${this.type} failed to save` })

          if (response.data.status === 'Action') {
            this.$toastr.s(`${this.type} update needs to be approved`, 'Needs Approval')
            this.$v.$reset()
            await this.loadUser()
          } else {
            this.$toastr.s(`${this.type} updated`, 'Updated')
            this.$v.$reset()
            await this.loadUser()
            if (this.userId === this.$store.getters.getUserId) {
              await this.$store.dispatch('loadUser')
            }
          }
        }
      } catch { } finally {
        this.$v.$reset()
      }
    },
    async deleteUser () {
      try {
        // Get confirmation from the user that they really want to delete the customer
        var result = await swal.fire({
          title: 'Delete User',
          text: 'Are you sure you want to delete this user?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch (error) {
        console.error(error)
      }

      if (!result.isConfirmed) {
        return
      }

      try {
        const response = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}Users/${this.user.id}`,
          { showload: true, showerror: true, errormessage: 'User failed to delete' })
        if (response.data.status === 'Action') {
          this.$toastr.s('User deletion needs to be approved', 'Needs Approval')
          await this.loadUser()
        } else {
          this.$toastr.s('User deleted', 'Deleted')
          this.$router.push({ name: 'Users' })
        }
      } catch { }
    },
    async openViewLoginHistory () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Users/LoginHistory`,
          { showload: true, showerror: true, errormessage: 'Failed to get user login history' })

        this.loginHistory = response.data
        this.$refs['login-history'].show()
      } catch { }
    },
    openChangePasswordModal () {
      this.$refs['change-password'].show()
    },
    closeChangePasswordModal () {
      this.$refs['change-password'].hide()
    },
    async changePassword () {
      this.passwordLoading = true
      this.$refs['change-password-inputs'].$v.$touch()
      try {
        if (!this.passwords.valid) {
          this.$toastr.e('There are validation errors on the form', 'Validation')
        } else {
          var requestData = { newPassword: this.passwords.password, confirmNewPassword: this.passwords.confirmPassword, userId: this.userId }

          var response = await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}User/ChangePasswordAdmin`, requestData, { showload: true })
          if (response.data.success) {
            this.$refs['change-password'].hide()
            this.$toastr.s('The user\'s password has been reset', 'Password Reset')
          } else {
            this.passwordErrors = response.data.errors
          }
        }
      } catch (e) {
        this.$toastr.e('Something went wrong with resetting the user\'s password', 'Error')
      } finally {
        this.passwordLoading = false
      }
    },
    async loadUser () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Users/${this.userId}`,
          { showload: true, showerror: true, errormessage: `${this.type} failed to load` })
        this.user = response.data
      } catch { }
    },
    async loadLoginPolicy () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/LoginPolicy`,
          { showload: true, showerror: true, errormessage: 'Login policy failed to load' })
        this.checkTokenAtLogin = response.data.checkTokenAtLogin
      } catch { }
    },
    async unlockUser () {
      try {
        await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Users/Unlock/${this.userId}`, { showload: true })
        this.$toastr.s('User Unlocked')

        await this.loadUser()
      } catch (e) {
        this.$toastr.e('There was a problem unlocking the user', 'Problem')
      }
    },
    async loadRoles () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Role`,
          {
            showload: true, showerror: true, errormessage: 'Failed to load roles'
          })
        const data = _.groupBy(response.data, v => v.module)
        this.databaseRoles = data
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    cancel () {
      this.$router.back()
    },
    rolesChanged (type, roles) {
      this.roles[type] = roles
    },
    toggleHSMPin (value) {
      this.hsmPinToggled = value
    },
    showCloneModel () {
      this.user.clonedName = ''
      const i = this.user.email.indexOf('@')
      if (i >= 0) {
        this.user.clonedEmail = this.user.email.substring(i, this.user.email.length)
      }
      this.$refs['clone-modal'].show()
      this.cloning = true
    },
    setCloneModalFocus () {
      this.$refs.defaultElement.focus()
    },
    resetModal () {
      this.cloning = false
      this.user.clonedName = ''
      this.user.clonedEmail = ''
    },
    async cloneUser (e) {
      this.$v.user.clonedName.$touch()
      this.$v.user.clonedEmail.$touch()
      this.$v.user.clonedPassword.$touch()
      this.$v.user.clonedConfirmPassword.$touch()
      e.preventDefault()
      if (this.$v.user.clonedName.$invalid || this.$v.user.clonedEmail.$invalid) {
        this.$toastr.e('There are validation errors on the form', 'Validation')
      } else {
        try {
          const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Users/Clone`, this.user, { showload: true, showerror: true })
          this.$v.$reset()
          if (response.data.status === 'Action') {
            this.$toastr.s('New user needs to be approved', 'Needs Approval')
            this.$router.push({ name: 'Users' })
          } else {
            this.$toastr.s('User cloned', 'Cloned')
            this.$router.push({ name: 'UserEdit', params: { userId: response.data.id } })
          }
        } catch { }
      }
    },
    checkIfBounced: _.debounce(async function () {
      if (this.user.email !== '') {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emailreporting/checkemail?emailAddress=${encodeURIComponent(this.user.email)
          }`)
        this.emailBounced = response.data
      }
    }, 500)
  },
  watch: {
    'user.email': function () {
      this.checkIfBounced()
    },
    selectedCustomer () { this.$router.push({ name: 'Users' }) }
  },
  validations () {
    if (this.status === 'Create') {
      return {
        user: {
          name: { required, maxLen: maxLength(50) },
          email: { required, email },
          phoneNumber: { phoneNumber },
          submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
          paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
          approvalLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
          defaultTwoFactor: {},
          hsmPin: {},
          emailAction: {},
          clonedName: {
            required: requiredIf(() => {
              return this.cloning
            }),
            maxLength: maxLength(50),
            notSameAs: not(sameAs('name'))
          },
          clonedEmail: {
            required: requiredIf(() => {
              return this.cloning
            }),
            notSameAs: not(sameAs('email')),
            email
          },
          clonedPassword: {
            required: requiredIf(() => {
              return this.cloning
            })
          },
          clonedConfirmPassword: {
            required: requiredIf(() => {
              return this.cloning
            }),
            sameAs: sameAs('clonedPassword')
          }
        },
        emailBounced: { sameAs: sameAs(() => false) }
      }
    }

    if (this.user.apiServiceAccount) {
      return {
        user: {
          name: { required, maxLen: maxLength(50) },
          email: {},
          enabled: {},
          phoneNumber: {},
          submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
          paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
          approvalLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
          defaultTwoFactor: {},
          hsmPin: {},
          emailAction: {},
          clonedName: {
            required: requiredIf(() => {
              return this.cloning
            }),
            maxLength: maxLength(50),
            notSameAs: not(sameAs('name'))
          },
          clonedEmail: {
            required: requiredIf(() => {
              return this.cloning
            }),
            notSameAs: not(sameAs('email')),
            email
          },
          clonedPassword: {
            required: requiredIf(() => {
              return this.cloning
            })
          },
          clonedConfirmPassword: {
            required: requiredIf(() => {
              return this.cloning
            }),
            sameAs: sameAs('clonedPassword')
          }
        },
        emailBounced: { sameAs: sameAs(() => false) }
      }
    }

    return {
      user: {
        name: { required, maxLen: maxLength(50) },
        email: { required, email },
        enabled: {},
        phoneNumber: { phoneNumber },
        submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
        paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
        approvalLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
        defaultTwoFactor: {},
        hsmPin: {},
        emailAction: {},
        clonedName: {
          required: requiredIf(() => {
            return this.cloning
          }),
          maxLength: maxLength(50),
          notSameAs: not(sameAs('name'))
        },
        clonedEmail: {
          required: requiredIf(() => {
            return this.cloning
          }),
          notSameAs: not(sameAs('email')),
          email
        },
        clonedPassword: {
          required: requiredIf(() => {
            return this.cloning
          })
        },
        clonedConfirmPassword: {
          required: requiredIf(() => {
            return this.cloning
          }),
          sameAs: sameAs('clonedPassword')
        }
      },
      emailBounced: { sameAs: sameAs(() => false) }
    }
  }
}

</script>
<style scoped>
.field-icon {
  position: absolute;
  top: 12px;
  right: 32px;
  z-index: 3;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
