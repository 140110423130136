var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Submissions In Progress "),
            _vm._m(0),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                paginationOptions: _vm.paginationOptions,
                rows: _vm.rows,
                columns: _vm.columns,
                lineNumbers: true,
                styleClass: "vgt-table striped bordered",
                searchOptions: {
                  enabled: true
                },
                "sort-options": _vm.sortOptions,
                mode: "remote",
                totalRows: _vm.totalRecords,
                isLoading: _vm.isTableLoading
              },
              on: {
                "on-row-click": _vm.onRowClick,
                "on-cell-click": _vm.onCellClick,
                "on-page-change": _vm.onPageChange,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch,
                "on-sort-change": _vm.onSortChange,
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "delete" && !props.row.preventDelete
                        ? _c("span", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-sm primary",
                                attrs: { disabled: _vm.isLoading },
                                on: { "on-cell-click": _vm.onCellClick }
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            )
                          ])
                        : _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mt-1",
                                      attrs: { sm: "12" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Refresh the data in the table",
                          expression: "'Refresh the data in the table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-sync pointer dimmedIcon" })]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Print out the contents of the Logs table",
                          expression:
                            "'Print out the contents of the Logs table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.printTable($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-print pointer dimmedIcon" })]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Export the contents of the Logs table",
                          expression: "'Export the contents of the Logs table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.exportTable($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-export pointer dimmedIcon"
                      })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }