var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("span", { staticClass: "pull-right" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.docUrl + "/gettingstarted/",
                  target: "_blank"
                }
              },
              [_c("i", { staticClass: "far fa-question-circle" })]
            )
          ]),
          _vm._v(" Contact Support "),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c("div", { staticClass: "idb-block-content" }, [
      _vm._m(0),
      _c("div", { staticClass: "pl-3" }, [_vm._v("Gladstone House")]),
      _c("div", { staticClass: "pl-3" }, [_vm._v("Hithercroft Road")]),
      _c("div", { staticClass: "pl-3" }, [_vm._v("Wallingford")]),
      _c("div", { staticClass: "pl-3" }, [_vm._v("Oxfordshire")]),
      _c("div", { staticClass: "pl-3" }, [_vm._v("OX10 9BT")]),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c("div", { staticClass: "pl-3 pt-2" }, [
        _vm._v("Online help guide: "),
        _c(
          "a",
          {
            attrs: { href: _vm.docUrl + "/gettingstarted/", target: "_blank" }
          },
          [_vm._v("help.paygateservice.com")]
        )
      ]),
      _vm._m(5),
      _vm.hasAgent
        ? _c("div", { staticClass: "pl-3 pt-2" }, [
            _vm._v("Agent Installer: "),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.cdnUrl + "/downloads/Paygate.Agent.Installer.msi",
                  target: "_blank"
                }
              },
              [_vm._v("Paygate.Agent.Installer.msi (v.3.1.0.0)")]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-3" }, [
      _c("strong", [_vm._v("Customer Support")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-3 pt-3" }, [
      _vm._v("Tel: "),
      _c("a", { attrs: { href: "tel:+441462708444" } }, [
        _vm._v("+44 (0) 1462 708 444")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-3 pt-1" }, [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:support@paygate.uk" } }, [
        _vm._v("support@paygate.uk")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-3 pt-5" }, [
      _c("strong", [_vm._v("Other useful links")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-3 pt-3" }, [
      _vm._v("Service Status: "),
      _c(
        "a",
        { attrs: { href: "https://status.paygate.cloud/", target: "_blank" } },
        [_vm._v("status.paygateservice.com")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-3 pt-2" }, [
      _vm._v("paygate.uk: "),
      _c(
        "a",
        { attrs: { href: "https://www.paygate.uk/", target: "_blank" } },
        [_vm._v("https://www.paygate.uk/")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }