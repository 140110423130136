var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "downloadBureauReports" } },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [
                _c(
                  "h2",
                  [
                    _vm._v(
                      " Download " + _vm._s(this.networkName) + " Reports "
                    ),
                    _vm._m(0),
                    _c("favourite-icon")
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "col-sm-3" },
                  [
                    _c("group-select", {
                      attrs: {
                        groups: _vm.groups,
                        clearable: false,
                        disabled: this.isLoggedIn
                      },
                      on: {
                        input: function($event) {
                          return _vm.changeGroup()
                        }
                      },
                      model: {
                        value: _vm.groupId,
                        callback: function($$v) {
                          _vm.groupId = $$v
                        },
                        expression: "groupId"
                      }
                    })
                  ],
                  1
                ),
                _vm.isLoggedIn === false
                  ? _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              disabled: this.enableLogin === false
                            },
                            on: { click: _vm.loginStart }
                          },
                          [_vm._v("Login")]
                        )
                      ]),
                      _vm._m(2),
                      _c("div", { staticClass: "col-md-1" }),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light",
                            attrs: {
                              type: "button",
                              disabled: this.enableLogin === false
                            },
                            on: { click: _vm.viewVocalinkCertificate }
                          },
                          [_vm._v("Vocalink Certificate")]
                        )
                      ])
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col-md-3" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                disabled: this.loggingIn === true
                              },
                              on: { click: _vm.logoff }
                            },
                            [_vm._v("Log Off")]
                          )
                        ]),
                        _vm._m(3),
                        _c("div", { staticClass: "col-md-1" }),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: {
                                type: "button",
                                disabled: this.loggingIn === true
                              },
                              on: { click: _vm.viewVocalinkCertificate }
                            },
                            [_vm._v("Vocalink Certificate")]
                          )
                        ])
                      ])
                    ])
              ]),
              _vm.BureauCustomersLoaded === true && _vm.isLoggedIn === true
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "row form-group col-md-12" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Bureau Customers")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("vue-select", {
                            attrs: {
                              multiple: "",
                              label: "name",
                              options: _vm.BureauCustomersDisplay,
                              closeOnSelect: false
                            },
                            on: {
                              input: _vm.CheckBureauCustomerDropdownEntries
                            },
                            model: {
                              value: _vm.SelectedBureauCustomers,
                              callback: function($$v) {
                                _vm.SelectedBureauCustomers = $$v
                              },
                              expression: "SelectedBureauCustomers"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _c("br"),
              _vm.isLoggedIn === true
                ? _c("div", [
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Report Types")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.reportTypes },
                            model: {
                              value: _vm.selectedReportType,
                              callback: function($$v) {
                                _vm.selectedReportType =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedReportType"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Report Period")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.reportPeriods },
                            model: {
                              value: _vm.selectedReportPeriod,
                              callback: function($$v) {
                                _vm.selectedReportPeriod =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedReportPeriod"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Previously Accessed")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.reportAccessed },
                            model: {
                              value: _vm.selectedReportAccessed,
                              callback: function($$v) {
                                _vm.selectedReportAccessed =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedReportAccessed"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              disabled: this.enableGetReportList === false
                            },
                            on: { click: _vm.getReportList }
                          },
                          [_vm._v("Get Report List")]
                        )
                      ])
                    ]),
                    _vm.haveReportList === true
                      ? _c("div", { staticClass: "row form-group" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "vue-good-table",
                                {
                                  ref: "reportList",
                                  attrs: {
                                    columns: _vm.columns,
                                    rows: _vm.rows,
                                    "select-options": {
                                      enabled: true,
                                      selectOnCheckboxOnly: true,
                                      selectionInfoClass: "report-row-selected",
                                      clearSelectionText: ""
                                    },
                                    "pagination-options": {
                                      enabled: true,
                                      perPage: 10,
                                      dropdownAllowAll: false
                                    },
                                    styleClass: "vgt-table striped bordered"
                                  },
                                  on: { "on-cell-click": _vm.viewReportModal },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "table-row",
                                        fn: function(props) {
                                          return [
                                            props.column.field ===
                                            "viewReportXml"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("XML")]
                                                  )
                                                ])
                                              : props.column.field ===
                                                "viewReportHtml"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("HTML")]
                                                  )
                                                ])
                                              : props.column.field ===
                                                "viewReportCsv"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("CSV")]
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.formattedRow[
                                                        props.column.field
                                                      ]
                                                    )
                                                  )
                                                ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2375276843
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { width: "800px" },
                                      attrs: { slot: "selected-row-actions" },
                                      slot: "selected-row-actions"
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-md-4" }),
                                        _c("div", { staticClass: "col-md-3" }, [
                                          _vm._v("Download Format")
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-2" },
                                          [
                                            _c("b-select", {
                                              attrs: {
                                                options: _vm.downloadFormats
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedDownloadFormat,
                                                callback: function($$v) {
                                                  _vm.selectedDownloadFormat =
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                },
                                                expression:
                                                  "selectedDownloadFormat"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col-md-3" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary pull-right",
                                              attrs: {
                                                type: "button",
                                                disabled:
                                                  this
                                                    .selectedDownloadFormat ===
                                                  ""
                                              },
                                              on: { click: _vm.downloadReports }
                                            },
                                            [_vm._v("Download")]
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showeSigner,
              expression: "showeSigner"
            }
          ],
          attrs: { id: "eSigner" }
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.eSignerHtml) } })]
      ),
      _c(
        "div",
        [
          _c("viewReportModal", {
            attrs: {
              reportData: _vm.reportContent,
              reportFormat: _vm.selectedReportFormat
            },
            on: { close: _vm.closeViewReport },
            model: {
              value: _vm.isViewReportModalVisible,
              callback: function($$v) {
                _vm.isViewReportModalVisible = $$v
              },
              expression: "isViewReportModalVisible"
            }
          })
        ],
        1
      ),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Select a Group")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("span", {
        staticClass: "fa fa-unlock",
        staticStyle: { "font-size": "200%" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("span", {
        staticClass: "fa fa-lock",
        staticStyle: { "font-size": "200%" }
      }),
      _vm._v(" Securely logged into Vocalink ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }