var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalGroups
    ? _c("v-select", {
        attrs: {
          label: "name",
          value: _vm.value,
          reduce: function(group) {
            return group.groupId
          },
          options: _vm.internalGroups,
          clearable: _vm.clearable,
          disabled: _vm.disabled
        },
        on: { input: _vm.emitInput },
        scopedSlots: _vm._u(
          [
            {
              key: "selected-option",
              fn: function(option) {
                return [
                  _c("div", {
                    staticClass: "group-colour",
                    style: { "background-color": _vm.getOptionColour(option) }
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.getGroupName(option)))])
                ]
              }
            },
            {
              key: "option",
              fn: function(option) {
                return [
                  _c("div", {
                    staticClass: "group-colour",
                    style: { "background-color": option.colour }
                  }),
                  _vm._v(" " + _vm._s(option.name) + " ")
                ]
              }
            },
            {
              key: "no-options",
              fn: function(ref) {
                var search = ref.search
                var searching = ref.searching
                return [
                  _vm.groups == null
                    ? [
                        _c("i", { staticClass: "fas fa-spinner fa-pulse" }),
                        _vm._v(" Groups are loading ")
                      ]
                    : searching
                    ? [
                        _vm._v(" No results found for "),
                        _c("em", [_vm._v(_vm._s(search))]),
                        _vm._v(". ")
                      ]
                    : _c("em", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v("Sorry, no groups available")
                      ])
                ]
              }
            }
          ],
          null,
          false,
          4017392030
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }