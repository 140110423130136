var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "CreateJobFromManualSubmissionModal",
      attrs: {
        id: "create-job-from-manual-submission",
        title: "Create Bureau Job From Submission",
        size: "xl",
        "hide-header-close": "",
        static: ""
      },
      on: { shown: _vm.load, ok: _vm.saveNewJobDetails }
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Save")
      ]),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
            _c("label", { attrs: { for: "jobDetailRecord.paymentfile" } }, [
              _vm._v("New Bureau Job Name")
            ])
          ]),
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c("b-form-input", {
                ref: "BureauJobName",
                attrs: { type: "text" },
                on: {
                  input: function($event) {
                    return _vm.onJobNameChanged()
                  }
                },
                model: {
                  value: _vm.$v.newJobModel.BureauJobName.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.newJobModel.BureauJobName,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.newJobModel.BureauJobName.$model"
                }
              }),
              !_vm.bureauJobNameValid
                ? _c("span", [
                    _c("br"),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v("The new bureau job name is not valid")
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }