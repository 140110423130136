var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " Pre-Submission Validation for '" +
              _vm._s(this.submissionDetails.submissionReference) +
              "' "
          ),
          _c("help-icon"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "ml-5 mt-3 mb-3" },
      [
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Submission Reference")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(this.submissionDetails.submissionReference))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total number of debits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.totalDebitCount)))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total number of DDI")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.totalDDICount)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Contra Narrative:")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(this.submissionDetails.contraNarrative))
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total value of debits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("currency")(this.totalDebitValue)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Payment Date")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(
                _vm._s(
                  _vm._f("formatDate")(
                    this.submissionDetails.paymentDate,
                    "DD/MM/YYYY"
                  )
                )
              )
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total number of credits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.totalCreditCount)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Processing Date")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(
                _vm._s(
                  _vm._f("formatDate")(
                    this.submissionDetails.processingDate,
                    "DD/MM/YYYY"
                  )
                )
              )
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Total value of credits")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("currency")(this.totalCreditValue)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Submission Status")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(this.bureauSubmissionStatus))
            ])
          ],
          1
        ),
        _c("b-row"),
        _c("br"),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Must Fix Errors")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.numberOfMustFix)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Warnings")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.numberOfWarnings)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [_vm._v("Duplicates")]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.numberOfDuplicates)))
            ])
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v("Information Messages")
            ]),
            _c("b-col", { attrs: { sm: "2" } }, [
              _vm._v(_vm._s(_vm._f("number")(this.numberOfMessages)))
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm.validationMessages.length > 0
      ? _c("hr", { staticClass: "mt-2" })
      : _vm._e(),
    _vm.validationMessages.length > 0
      ? _c(
          "div",
          { staticClass: "ml-5 mr-5" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    _c("b-table", {
                      staticClass: "mt-2",
                      attrs: {
                        borderless: "",
                        small: "",
                        "caption-top": "",
                        striped: true,
                        items: _vm.validationMessages,
                        fields: _vm.validationFields,
                        "per-page": 10
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(messageSeverity)",
                            fn: function(data) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-pill table-pill",
                                    class: data.value
                                  },
                                  [_vm._v(_vm._s(data.value))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3527705608
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "idb-block-footer" },
      [
        _c(
          "b-row",
          { staticClass: "ml-2" },
          [
            _c("b-col", { attrs: { sm: "12" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ml-2",
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    click: function($event) {
                      return _vm.onViewPaymentsClick()
                    }
                  }
                },
                [_vm._v("View Payments")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-success ml-2",
                  attrs: {
                    disabled: !(_vm.numberOfMustFix == 0) || _vm.isLoading
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.onSubmitClick()
                    }
                  }
                },
                [_vm._v("Process Submission")]
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }