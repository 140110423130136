export default {
  methods: {
    dateOnfocus () {
      setTimeout((ev) => {
        this.$refs.paymentDate.isOpen || this.$refs.paymentDate.showCalendar(ev)
      }, 250)
    },

    fromDateOnfocus () {
      setTimeout((ev) => {
        this.$refs.createdFrom.isOpen || this.$refs.createdFrom.showCalendar(ev)
      }, 250)
    },

    toDateOnfocus () {
      setTimeout((ev) => {
        this.$refs.createdTo.isOpen || this.$refs.createdTo.showCalendar(ev)
      }, 250)
    },

    saveFile (response) {
      const blob = new Blob([response.data], { type: 'who/cares' })
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    },

    formatValue (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    formatCount (count) {
      try {
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
      } catch (e) {
        return 0
      }
    }
  }
}
