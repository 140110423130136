var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "submissionHistory" } }, [
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("vue-good-table", {
            attrs: {
              mode: "remote",
              columns: _vm.columns,
              rows: _vm.rows,
              lineNumbers: true,
              "pagination-options": {
                enabled: false
              },
              "sort-options": {
                enabled: false
              },
              styleClass: "vgt-table striped bordered"
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "userId"
                      ? _c("span", [
                          _c("img", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "rounded-circle",
                            attrs: {
                              src:
                                _vm.platformApi +
                                "User/GetAvatar/" +
                                props.row.userId +
                                "?access_token=" +
                                _vm.getAccessToken,
                              width: "30",
                              height: "30",
                              title: props.row.Username
                            }
                          })
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(props.formattedRow[props.column.field]))
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }