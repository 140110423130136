var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("hr"),
    _c(
      "div",
      [
        _vm._l(_vm.items, function(item) {
          return _c(
            "b-row",
            { key: item.id },
            [
              _c("b-col", [
                _c("strong", [_vm._v(_vm._s(item.status))]),
                _vm._v(" " + _vm._s(item.paymentFilename))
              ]),
              _c(
                "b-col",
                [
                  _c("b-progress", {
                    attrs: {
                      value: item.percentComplete,
                      max: 100,
                      variant: "success"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        _vm.items.length === 0
          ? _c(
              "b-row",
              [
                _c("b-col", [
                  _vm._v("You do not currently have any active tasks")
                ])
              ],
              1
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("Bureau Task Summary")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }