<template>
  <b-modal
    id="create-job-from-manual-submission"
    ref="CreateJobFromManualSubmissionModal"
    title="Create Bureau Job From Submission"
    size="xl"
    hide-header-close
    static
    @shown="load"
    @ok="saveNewJobDetails"
  >
    <div slot="modal-ok">Save</div>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.paymentfile">New Bureau Job Name</label>
      </b-col>
      <b-col sm="6">
        <b-form-input
          ref="BureauJobName"
          type="text"
          @input="onJobNameChanged()"
          v-model.trim="$v.newJobModel.BureauJobName.$model"
        ></b-form-input>
        <span v-if="!bureauJobNameValid">
          <br />
          <span class="text-danger small">The new bureau job name is not valid</span>
        </span>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'
import { maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import VueSelect from 'vue-select'

export default {
  mixins: [Utility, loading],
  components: {
    VueSelect
  },
  props: ['manualBureauJobId'],
  data () {
    return {
      //      bureauJobName: '',
      newJobModel: {

        BureauJobName: ''
      },
      bureauJobNameValid: false
    }
  },
  async created () {
    console.log('modal add job from manual job of idL ', this.manualBureauJobId)
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('')
  },

  methods: {
    load () {
      // Loader
    },

    // Data Handlers
    onJobNameChanged () {
      var jobName = this.newJobModel.BureauJobName

      if (!jobName || jobName.length > 255 || jobName.length === 0) {
        this.bureauJobNameValid = false
      }
      // Check for ..
      if (jobName.includes('..')) {
        console.log('2')
        this.bureauJobNameValid = false
      }

      // Check for \\
      if (jobName.includes('\\\\')) {
        console.log('3')
        this.bureauJobNameValid = false
      }

      // Check for ports
      if (/^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i.test(jobName)) {
        console.log('4')
        this.bureauJobNameValid = false
      }
      this.bureauJobNameValid = true
    },

    // Writeback
    async saveNewJobDetails () {
      console.log('Save New Job Details Clicked')
      try {
        var payload = {
          name: this.newJobModel.BureauJobName,
          manualBureauJobId: this.manualBureauJobId
        }

        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/createjobfrommanualsubmission', payload, { showload: true })
        // this.$toastr.s(`Updated ${submissionDetail.paymentFilename}`, 'Update Bureau Submission')
        console.log(response)

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e('Failed to create new job', 'Create new job from manual bureau submission')
        console.error('Error in creating new bureau job from manual job', error)
      }
    }
  },
  validations: {
    newJobModel: {
      BureauJobName: { maxLength: maxLength(255) }
    }
  }
}

</script>
