<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>
                <help-icon docPath="/paygate-collections/payers/viewschedule/" />View Schedule
              </h2>
            </div>
            <div class="idb-block-content">
              <b-row class="mb-3">
                <b-col>
                  <strong class="mr-2">Name:</strong>
                  <router-link :to="'/collections/payer/'+id+'/edit'">{{payerName}}</router-link>
                </b-col>
                <b-col>
                  <strong class="mr-2">Reference:</strong>
                  <router-link :to="'/collections/payer/'+id+'/edit'">{{reference}}</router-link>
                </b-col>
                <b-col></b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <strong class="mr-2">Frequency:</strong>
                  {{frequency}}
                </b-col>
                <b-col>
                  <strong class="mr-2">Direct Debit Type:</strong>
                  {{type}}
                </b-col>
                <b-col>
                  <strong class="mr-2">Step:</strong>
                  {{step}}
                </b-col>
              </b-row>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="createPayerMessages"
                    >Create Payer Messages</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="removeQueued"
                      v-if="createPayerMessages"
                    >Remove Queued Messages</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <transition name="fade">
                      <div class="form-horizontal">
                        <b-button
                          @click="clearAdvanceNotices"
                          :disabled="isLoading"
                          variant="outline-danger"
                        >Remove Advance Notice Messages</b-button>
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <div class="form-horizontal">
                      <b-button @click="goToQueue" variant="outline-secondary">View Queued Messages</b-button>
                    </div>
                  </div>
                </div>
              </div>
              <b-row>
                <b-col md="12">
                  <vue-good-table
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    :columns="columns"
                    :rows="schedule"
                    :lineNumbers="true"
                    :totalRows="totalRecords"
                    :rowStyleClass="rowStyler"
                    :search-options="{
                    enabled: true
                    }"
                    :isLoading.sync="isTableLoading"
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    @on-row-click="onRowClick"
                    styleClass="vgt-table striped bordered"
                    ref="table"
                  >
                    <template slot="loadingContent">
                      <h1>Loading...</h1>
                    </template>
                    <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">No Schedule Available</div>
                    </div>
                    <div slot="table-actions">
                      <p-check
                        class="p-switch p-fill"
                        color="primary"
                        v-model="includeHistoric" @change="load"                      
                      >Include Historic</p-check>
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button
                        @click.prevent="addPayment"
                        class
                        variant="link"
                        :disabled="isLoading"
                        v-if="allowInsert"
                        v-b-popover.hover.top.d500="'Add payment'"
                      >
                        <i class="fa fa-plus pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="load"
                        class
                        variant="link"
                        :disabled="isLoading"
                        v-b-popover.hover.top.d500="'Refresh the data in the table'"
                      >
                        <i class="fa fa-sync pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="printTable"
                        class
                        variant="link"
                        v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
                      >
                        <i class="fa fa-print pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="exportTable"
                        class
                        variant="link"
                        :disabled="isLoading"
                        v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
                      >
                        <i class="fa fa-share-square pointer dimmedIcon"></i>
                      </b-button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-if="selectedRow && (props.row.paymentComposite !== '' && props.row.paymentComposite === selectedRow.paymentComposite) || (props.row.paymentComposite === '' && props.row.ukddmsPendingPaymentId == selectedRow.ukddmsPendingPaymentId)"
                      >
                        <span v-if="props.column.label==='Status'">
                          <b-input-group>
                            <b-select
                              v-model="props.row.status"
                              @change="statusChanged(props.row, props)"
                            >
                              <option :value="9">Pending First Submission</option>
                              <option :value="8">Pending Regular Submission</option>
                              <option :value="7">Pending Re Presentation</option>
                              <option :value="6">Pending Final Submission</option>
                              <option :value="10">Deleted</option>
                            </b-select>
                            <b-input-group-append v-if="allowDelete">
                              <b-button
                                variant="danger"
                                @click="deleteSchedule(props.row, props)"
                                :disabled="isLoading"
                                v-if="props.row.status!==10 && allowDelete"
                              >
                                <i class="fa fa-ban mr-1"></i>Delete
                              </b-button>
                              <b-button
                                variant="danger"
                                @click="undeleteSchedule(props.row, props)"
                                :disabled="isLoading"
                                v-if="props.row.status===10 && allowDelete"
                              >
                                <i class="fa fa-check mr-1"></i>Undelete
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </span>
                        <span v-if="props.column.field==='amount'">
                          <span class="text-nowrap">
                            <i
                              v-if="props.row.pendingVariableAmount || props.row.amount == 0"
                              v-b-tooltip.hover
                              title="Variable payment amount required!"
                              class="fa fa-exclamation-circle text-warning mr-2"
                            ></i>
                            <currency-input
                              v-model="props.row.amount"
                              @input="amountChanged(props)"
                              currency="GBP"
                              locale="en"
                            />
                          </span>
                        </span>
                        <span v-if="props.column.field==='transactionCode'">
                          <b-select
                            type="text"
                            v-model="props.row.transactionCode"
                            @change="transactionCodeChanged(props.row, props)"
                          >
                            <option :value="'01'">01</option>
                            <option :value="'17'">17</option>
                            <option :value="'18'">18</option>
                            <option :value="'19'">19</option>
                          </b-select>
                        </span>
                        <span v-if="props.column.label==='Collection Date'">
                          <datepicker
                            v-model="props.row.collectionDate"
                            @selected="collectionDateChanged"
                            :disabledDates="disabledDates"
                          />
                        </span>
                        <span
                          v-if="props.column.label==='Processing Date'"
                        >{{props.formattedRow[props.column.field]}}</span>
                        <span v-if="props.column.field === 'editMode'">
                          <input type="checkbox" v-model="props.row.editMode" />
                        </span>
                        <span v-if="props.column.label === ''">
                          <b-button
                            variant="outline-danger"
                            @click="deleteSchedule"
                            v-if="props.row.status!==10 && allowDelete"
                            :disabled="isLoading"
                          >
                            <i class="fa fa-ban mr-1"></i>Delete
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="undeleteSchedule"
                            v-if="props.row.status===10 && allowDelete"
                            :disabled="isLoading"
                          >
                            <i class="fa fa-check mr-1"></i>Undelete
                          </b-button>
                        </span>
                      </span>
                      <span v-else>
                        <span v-if="props.column.field == 'amount'">
                          <i
                            v-if="props.row.pendingVariableAmount || props.row.amount === 0"
                            v-b-tooltip.hover
                            title="Variable payment amount required!"
                            class="fa fa-exclamation-circle text-danger"
                          ></i>
                          {{props.formattedRow[props.column.field]}}
                        </span>
                        <span v-else-if="props.column.label == 'Status'">
                          <span v-html="props.formattedRow[props.column.field]"></span>
                          <i
                            v-if="hasMessage(props.row.paymentComposite)"
                            @click.stop="selectMessage(props.row.paymentComposite)"
                            v-b-tooltip.hover
                            title="Message"
                            class="fa fa-envelope ml-3"
                          ></i>
                        </span>
                        <span v-else>{{props.formattedRow[props.column.field]}}</span>
                      </span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </div>
            <div class="idb-block-footer">
              <b-btn
                type="link"
                variant="primary"
                :disabled="isLoading"
                v-if="allowInsert"
                @click.prevent="saveSchedule"
              >
                <i class="fa fa-save mr-2" aria-hidden="true"></i>Save Changes
              </b-btn>
              <b-btn
                type="link"
                class="ml-2"
                variant="outline-primary"
                v-if="allowInsert"
                :disabled="isLoading"
                @click.prevent="addPayment"
              >
                <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Payment
              </b-btn>
              <b-btn
                type="link"
                class="float-right"
                variant="danger"
                :disabled="isLoading"
                v-if="allowInsert"
                @click.prevent="cancelSchedule"
              >
                <i class="fa fa-times mr-2"></i>Cancel Changes
              </b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- BEGIN VIEW MESSAGE -->
    <b-modal id="message-modal" ref="message-modal">
      <template v-slot:modal-title>
        <h4 class="modal-title">
          <i class="fa fa-envelope"></i>
          {{selectedMessage.messageTitle}}
        </h4>
      </template>
      {{selectedMessage.messageBody}}
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-default" @click="$bvModal.hide('message-modal')">
          <i class="fa fa-times"></i> Close
        </button>
      </template>
    </b-modal>
    <!-- END VIEW MESSAGE -->
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import papa from 'papaparse'
import axios from 'axios'
import PrettyCheck from 'pretty-checkbox-vue/check'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  async created () {
    this.$store.dispatch('getCurrentPayerGroupIdFromId', this.id)
    // this.$store.dispatch('getNonProcessingDates')
    this.groupConfig = await this.$store.dispatch('getGroupConfigurationFromPayerId', { id: this.id })
  },
  components: {
    Datepicker,
    pCheck: PrettyCheck
  },
  props: {
    // payer ID
    id: String,
    // plan ID
    planId: String
  },
  data () {
    return {
      schedule: [],
      changes: [],
      columns: [
        {
          label: 'Status',
          field: this.statusText
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right'
        },
        {
          label: 'Transaction Code',
          field: 'transactionCode',
          width: '180px'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: 'Collection Date',
          field: 'collectionDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'collectiondate', type: 'asc' }]
      },
      selectedRow: null,
      statae: [
        { value: 9, text: 'Pending First Submission' },
        { value: 8, text: 'Pending Regular Submission' },
        { value: 7, text: 'Pending Re Presentation' },
        { value: 6, text: 'Pending Final Submission' },
        { value: 10, text: 'Deleted' }
      ],
      txCodes: ['01', '17', '18', '19'],
      groupConfig: null,
      messages: [],
      selectedMessage: {
        messageTitle: '',
        messageBody: ''
      },
      actioncomposite: '',
      createPayerMessages: true,
      removeQueued: false,
      includeHistoric: false
    }
  },
  computed: {
    firstPaymentEnabled () {
      return this.$store.getters.scheduleMetaData.firstPaymentTxTotal === 0
    },
    lastPaymentEnabled () {
      return this.$store.getters.scheduleMetaData.lastPaymentTxTotal === 0
    },
    actionColHidden: function () {
      let hidden = true
      for (var i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].editMode) {
          hidden = false
        }
      }
      console.log('hidden:' + hidden)
      return hidden
    },
    disabledDates: function () {
      let maxDate = new Date()
      let minDate = new Date()
      if (this.selectedRow) {
        const start = new Date(this.defaultCollectionDate)
        console.log(start.getDate())
        // minDate = new Date(start.getTime())
        minDate = this.defaultCollectionDate
        maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        console.log(minDate)
        console.log(maxDate)
        if (this.selectedRow.isNew) {
          minDate = this.defaultCollectionDate
          maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        }
      }
      return {
        days: [0, 6],
        // daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    allowInsert () {
      return this.$store.getters.isInRole('InsertPayment') && !this.isClosed
    },
    allowAmendment () {
      return this.$store.getters.isInRole('AmendPayment') && !this.isClosed
    },
    allowDelete () {
      return this.$store.getters.isInRole('DeletePayment') && !this.isClosed
    },
    advancedNoticeWithMedia () {
      var notice = 0
      if (this.groupConfig.advanceNoticeInDays) {
        notice = notice + this.groupConfig.advanceNoticeInDays
      }
      if (this.groupConfig.defaultPayerMessageType === 0) {
        notice = notice + 5
      }
      var weekendDays = 0
      for (var i = 0; i < notice; i++) {
        if (i % 5 === 0) {
          weekendDays = weekendDays + 2
        }
      }
      notice = notice + weekendDays
      return notice
    },
    defaultCollectionDate () {
      var earliest = new Date()
      earliest = new Date(earliest.setDate(earliest.getDate() + this.advancedNoticeWithMedia))
      if (earliest.getDay() === 5) {
        earliest = new Date(earliest.setDate(earliest.getDate() + 2))
      }
      if (earliest.getDay() === 6) {
        earliest = new Date(earliest.setDate(earliest.getDate() + 1))
      }
      return earliest
    },
    defaultProcessingDate () {
      var earliest = new Date(this.defaultCollectionDate)
      earliest = new Date(earliest.setDate(earliest.getDate() - 1))
      if (earliest.getDay() === 5) {
        earliest = new Date(earliest.setDate(earliest.getDate() - 1))
      }
      if (earliest.getDay() === 6) {
        earliest = new Date(earliest.setDate(earliest.getDate() - 2))
      }
      return earliest
    },
    payerName () {
      return this.$store.state.payer.currentPayer.bankAccountDetails.accountHoldersName
    },
    frequency () {
      var freq = ''
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        switch (this.currentPlan.recurrenceType) {
          case 0:
            freq = 'Weekly'
            break
          case 1:
            freq = 'Monthly'
            break
          case 2:
            freq = 'Yearly'
            break
        }
      }
      return freq
    },
    reference () {
      return this.currentPlan !== undefined && this.currentPlan !== null ? this.currentPlan.reference : ''
    },
    type () {
      var type = ''
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        switch (this.currentPlan.recurrenceEndType) {
          case 0:
            type = 'One Off'
            break
          case 1:
            type = 'Until Date'
            break
          case 2:
            type = `Until Number Of Payments ${this.totalPayments}`
            break
          case 3:
            type = 'Ongoing'
            break
          case 4:
            type = 'Ad Hoc'
            break
        }
      }
      return type
    },
    step () {
      return this.currentPlan !== undefined && this.currentPlan !== null ? this.currentPlan.step : 1
    },
    totalPayments () {
      return this.currentPlan !== undefined && this.currentPlan !== null ? this.currentPlan.totalPayments : 0
    },
    currentPlan () {
      var plan = null
      for (var i = 0; i < this.$store.state.payer.currentPayer.paymentPlans.length; i++) {
        if (this.$store.state.payer.currentPayer.paymentPlans[i].paymentPlanId === this.planId) {
          plan = this.$store.state.payer.currentPayer.paymentPlans[i]
        }
      }
      return plan
    },
    payer () {
      return this.$store.state.payer.currentPayer
    },
    payerStatus () {
      return this.payer.status
    },
    isClosed () {
      return this.payerStatus === 3
    }
  },
  mounted: async function () {
    this.$store.dispatch('getNonProcessingDates')
    await this.$store.dispatch('setCurrentDDMSPayerWithId', { id: this.id })
    await this.getPayerMessages()
    if (this.$route.query.actioncomposite) {
      this.actioncomposite = this.$route.query.actioncomposite
      var index = this.$store.getters.schedule.findIndex(x => x.paymentComposite === this.actioncomposite)
      while (index === -1) {
        this.paginationOptions.setCurrentPage++
        this.updateParams({ page: this.paginationOptions.setCurrentPage })
        await this.load()
        index = this.$store.getters.schedule.findIndex(x => x.paymentComposite === this.actioncomposite)
      }
    }
  },
  methods: {
    splitProcessingDate (rowObj) {
      // return rowObj.processingDate.split('T')[0]
      var date = Date.parse(rowObj.processingDate)
      return date
    },
    splitCollectionDate (rowObj) {
      // return rowObj.collectionDate.split('T')[0]
      return Date.parse(rowObj.collectionDate)
    },
    statusText (rowObj) {
      var outText = ''
      switch (rowObj.status) {
        case 9:
          outText = 'Pending First Submission'
          break
        case 8:
          outText = 'Pending Regular Submission'
          break
        case 7:
          outText = 'Pending Re Presentation'
          break
        case 6:
          outText = 'Pending Final Submission'
          break
        case 5:
          outText = 'Being Processed'
          break
        case 4:
          outText = 'Submitted'
          break
        case 3:
          outText = 'Failed'
          break
        case 2:
          outText = 'Error'
          break
        case 1:
          outText = 'Suspended'
          break
        case 0:
          outText = 'Closed'
          break
        case 10:
          outText = 'Deleted'
          break
        case 11:
          outText = '<i class="fas fa-share mr-2"></i>Refunded'
          break
        default:
          outText = 'Value not set yet'
          break
      }
      if (rowObj.suppressed) {
        outText += ' (suppressed)'
      }
      return outText
    },
    load: async function () {
      if (this.planId === '00000000-0000-0000-0000-000000000000') {
        this.$toastr.e('Invalid plan id specified.')
        this.$router.push({ path: '/collections/payer/manage' })
      } else {
        try {
          var response = await this.$store.dispatch('getCurrentPayerScheduleFromId', { id: this.id, planId: this.planId, serverParams: this.buildGoodTableQuery(), includeHistoric: this.includeHistoric })
          if (response) {
            this.schedule = this.$store.getters.schedule
            var meta = this.$store.getters.scheduleMetaData
            this.totalRecords = meta.totalItems
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      }
    },
    async printTable () {
      this.enablePagination = false
      await this.load()
      window.print()
      this.enablePagination = true
    },
    async exportTable () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}schedule/${this.id}/${this.planId}/`, {
          params: {
            page: 1,
            perPage: this.totalRecords,
            columnFilters: this.serverParams.columnFilters,
            sort: this.serverParams.sort,
            searchKey: this.serverParams.searchKey,
            paygateid: this.serverParams.paygateId
          },
          showload: true
        })
        if (response) {
          var csvString = papa.unparse(response.data.data)
          var blob = new Blob([csvString])
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, this.id + '-payer-schedule.csv')
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
            a.download = this.reference + '-payer-alert.csv'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }
      } catch {
        this.$toastr.e('Could not export schedule.')
      }
    },
    onRowClick (params) {
      if (this.allowAmendment && !this.isClosed) {
        if (this.selectedRow) {
          if ((params.row !== this.selectedRow && (params.row.status > 5 && params.row.status < 11))) {
            this.schedule = this.$store.getters.schedule
            this.selectedRow = params.row
            this.selectedRow.editMode = true
          }
        } else {
          if (params.row.status > 5 && params.row.status < 11) {
            this.selectedRow = params.row
            this.selectedRow.editMode = true
          }
        }
      }
      // this.$router.push({ name: 'EditPayer', params: { id: id } })
    },
    findUpTag (el, tag) {
      while (el.parentNode) {
        el = el.parentNode
        if (el.tagName === tag) {
          return el
        }
      }
      return null
    },
    async saveSchedule () {
      var success = await this.$store.dispatch('updateEntireSchedule', { schedule: this.schedule, createPayerMessages: this.createPayerMessages, removeQueued: this.removeQueued })
      if (success) {
        this.$toastr.s('Schedule Updated')
        this.selectedRow.pendingVariableAmount = false
        this.selectedRow = null
        await this.load()
      } else {
        if (this.$store.state.schedule.badDate) {
          this.$toastr.e('That date falls within advance notice period, pick a later date.')
        } else if (this.$store.state.schedule.noViableAmendments) {
          this.$toastr.e('Could not update the schedule, please ensure amounts and collection dates are correct and try again.')
        } else if (this.$store.state.schedule.tooManyFirsts) {
          this.$toastr.e('Could not update the schedule, too many First Payments (transaction code 01) set. Only one is allowed.')
        } else if (this.$store.state.schedule.tooManyFinals) {
          this.$toastr.e('Could not update the schedule, too many Final Payments (transaction code 19) set. Only one is allowed.')
        } else {
          this.$toastr.e('Schedule failed to update')
        }
      }
    },
    cancelSchedule () {
      this.selectedRow = null
      this.load()
    },
    async deleteSchedule (e, props) {
      e.status = 10
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    goToQueue () {
      this.$router.push(`/collections/payer/${this.id}/edit?openAt=messagequeue`)
    },
    async clearAdvanceNotices () {
      try {
        var result = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/advancenotices/${this.id}/${this.planId}`, { showload: true })
        if (result) {
          this.$toastr.s('Advance Notices Removed.')
        }
      } catch {
        this.$toastr.e('Failed to remove Advance Notices.')
      }
    },
    async undeleteSchedule (e, props) {
      e.status = e.originalStatus
      switch (e.transactionCode) {
        case '01':
          e.status = 9
          break
        case '17':
          e.status = 8
          break
        case '18':
          e.status = 7
          break
        case '19':
          e.status = 6
          break
      }
      e.changed = true
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    addPayment () {
      var examplePayment
      if (this.schedule.length > 0) {
        examplePayment = _.clone(this.schedule[0], true)
        examplePayment.paymentAmendmentId = null
        examplePayment.ukddmsPendingPaymentId = uuidv4()
        examplePayment.originalDate = this.defaultCollectionDate
        examplePayment.processingDate = this.defaultProcessingDate
        examplePayment.collectionDate = this.defaultCollectionDate
      } else {
        examplePayment = {
          ukddmsPendingPaymentId: uuidv4(),
          ukPayerId: this.id,
          groupId: this.$store.getters.currentPayerGroupId,
          paymentPlanId: this.planId,
          originalDate: this.defaultCollectionDate,
          paymentComposite: '',
          amount: 0,
          processingDate: this.defaultProcessingDate,
          collectionDate: this.defaultCollectionDate,
          suppressed: false,
          pendingVariableAmount: false
        }
      }
      examplePayment.paymentComposite = ''
      examplePayment.isNew = true
      examplePayment.editMode = true
      examplePayment.status = 8
      examplePayment.transactionCode = '17'
      this.schedule.unshift(examplePayment)
      if (this.schedule.length > 10) {
        this.schedule.pop()
      }
      this.totalRecords = this.schedule.length
      this.selectedRow = examplePayment
    },
    collectionDateChanged (date) {
      if (this.selectedRow.isNew) {
        this.selectedRow.originalDate = new Date(date.getTime()).toISOString()
      }
      var dateOffset = (24 * 60 * 60 * 1000) * 1
      let collDate = new Date(date.getTime())
      // collDate = new Date(collDate.setTime(collDate.getTime() - dateOffset))
      // CORRECT DATE.
      let collDateValid = false
      while (!collDateValid) {
        if (collDate.getDay() === 6 || collDate.getDay() === 0 || (this.$store.getters.nonProcessingDates !== undefined && this.$store.getters.nonProcessingDates.includes(collDate))) {
          collDate = new Date(collDate.setTime(collDate.getTime() - dateOffset))
        } else {
          collDateValid = true
        }
      }
      var procDate = new Date(collDate.getTime())
      procDate = new Date(procDate.setDate(procDate.getDate() - 1))
      if (procDate.getDay() === 5) {
        procDate = new Date(procDate.setDate(procDate.getDate() - 1))
      }
      if (procDate.getDay() === 6) {
        procDate = new Date(procDate.setDate(procDate.getDate() - 2))
      }
      this.selectedRow.collectionDate = collDate.toISOString()
      for (var i = 0; i < this.schedule.length; i++) {
        if ((this.schedule[i].paymentComposite !== '' && this.schedule[i].paymentComposite === this.selectedRow.paymentComposite) || (this.schedule[i].paymentComposite === '' && this.schedule[i].ukddmsPendingPaymentId === this.selectedRow.ukddmsPendingPaymentId)) {
          this.schedule[i].collectionDate = collDate.toISOString()
          this.schedule[i].processingDate = procDate.toISOString()
          this.schedule[i].changed = true
        }
      }
    },
    amountChanged (props) {
      for (var i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].paymentComposite === this.selectedRow.paymentComposite) {
          this.schedule[i].amount = props.row.amount
          this.schedule[i].changed = true
        }
      }
    },
    rowStyler (row) {
      var cssClass = ''
      if (row.status === 10 && (row !== this.selectedRow)) {
        cssClass = 'deleted'
      }
      if (row.suppressed && (row !== this.selectedRow)) {
        cssClass = 'suppressed'
      }
      if (row.paymentComposite === this.actioncomposite) {
        cssClass += ' messageSelected'
      }
      return cssClass
    },
    transactionCodeChanged (e, props) {
      switch (e.transactionCode) {
        case '01':
          e.status = 9
          this.setOtherRowsToUndeleted(e)
          break
        case '17':
          e.status = 8
          this.setOtherRowsToUndeleted(e)
          break
        case '18':
          e.status = 7
          this.setOtherRowsToUndeleted(e)
          break
        case '19':
          e.status = 6
          this.setOtherRowsToDeleted(e)
          break
      }
      e.changed = true
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    statusChanged (e, props) {
      switch (e.status) {
        case 9:
          e.transactionCode = '01'
          this.setOtherRowsToUndeleted(e)
          break
        case 8:
          e.transactionCode = '17'
          this.setOtherRowsToUndeleted(e)
          break
        case 7:
          e.transactionCode = '18'
          this.setOtherRowsToUndeleted(e)
          break
        case 6:
          e.transactionCode = '19'
          this.setOtherRowsToDeleted(e)
          break
      }
      e.changed = true
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    setOtherRowsToDeleted (row) {
      for (var i = 0; i < this.schedule.length; i++) {
        if (new Date(this.schedule[i].processingDate) > new Date(row.processingDate)) {
          if (this.schedule[i].status !== 10) {
            this.schedule[i].originalStatus = this.schedule[i].status
          }
          this.schedule[i].status = 10
          this.schedule[i].changed = true
        }
      }
    },
    setOtherRowsToUndeleted (row) {
      for (var i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].originalStatus && this.schedule[i].originalStatus !== 10) {
          this.schedule[i].status = this.schedule[i].originalStatus
          this.schedule[i].changed = true
        }
      }
    },
    isInPast (row) {
      return new Date(row.processingDate) < new Date()
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    async getPayerMessages () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/payer/${this.id}`, { showload: true })
      if (response.data) {
        this.messages = response.data
      }
    },
    hasMessage (paymentComposite) {
      return this.messages.some(msg => msg.paymentComposite === paymentComposite)
    },
    selectMessage (paymentComposite) {
      this.selectedMessage = this.messages.find((msg) => { return msg.paymentComposite === paymentComposite })
      this.$bvModal.show('message-modal')
    },
    amountFormatter (value) {
      return value.toFixed(2)
    }
  }
}
</script>
<style scoped>
.deleted * {
  color: #cccccc;
}
.suppressed * {
  color: #cccccc;
}
.alert {
  border: solid 1px red;
}
.messageSelected td {
  background-color: #17a2b8 !important;
  color: #ffffff !important;
}
</style>
