var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("hr"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "spacer150" }),
    _c("hr"),
    _c(
      "div",
      { staticClass: "pull-right" },
      [
        _c(
          "b-button",
          {
            staticClass: "btnPad",
            attrs: { variant: "danger" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnCancel($event)
              }
            }
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "success" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnSave($event)
              }
            }
          },
          [_vm._v(" OK ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("Sign Submission"),
      _c("span", { staticClass: "pull-right" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("i", { staticClass: "far fa-question-circle" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }