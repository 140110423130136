var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "manageSchedule" } },
        [
          _c(
            "b-row",
            { attrs: { mb: "4" } },
            [
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } },
                [
                  _c("div", { staticClass: "idb-block" }, [
                    _c("div", { staticClass: "idb-block-title" }, [
                      _c(
                        "h2",
                        [
                          _c("help-icon", {
                            attrs: {
                              docPath:
                                "/paygate-collections/payers/viewschedule/"
                            }
                          }),
                          _vm._v("View Schedule ")
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "idb-block-content" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-col",
                              [
                                _c("strong", { staticClass: "mr-2" }, [
                                  _vm._v("Name:")
                                ]),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/collections/payer/" + _vm.id + "/edit"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.payerName))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c("strong", { staticClass: "mr-2" }, [
                                  _vm._v("Reference:")
                                ]),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/collections/payer/" + _vm.id + "/edit"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.reference))]
                                )
                              ],
                              1
                            ),
                            _c("b-col")
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c("b-col", [
                              _c("strong", { staticClass: "mr-2" }, [
                                _vm._v("Frequency:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.frequency) + " ")
                            ]),
                            _c("b-col", [
                              _c("strong", { staticClass: "mr-2" }, [
                                _vm._v("Direct Debit Type:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.type) + " ")
                            ]),
                            _c("b-col", [
                              _c("strong", { staticClass: "mr-2" }, [
                                _vm._v("Step:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.step) + " ")
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "p-check",
                                  {
                                    staticClass: "p-switch p-fill",
                                    attrs: { color: "primary" },
                                    model: {
                                      value: _vm.createPayerMessages,
                                      callback: function($$v) {
                                        _vm.createPayerMessages = $$v
                                      },
                                      expression: "createPayerMessages"
                                    }
                                  },
                                  [_vm._v("Create Payer Messages")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _vm.createPayerMessages
                                  ? _c(
                                      "p-check",
                                      {
                                        staticClass: "p-switch p-fill",
                                        attrs: {
                                          id: "previewCheck",
                                          color: "primary"
                                        },
                                        model: {
                                          value: _vm.removeQueued,
                                          callback: function($$v) {
                                            _vm.removeQueued = $$v
                                          },
                                          expression: "removeQueued"
                                        }
                                      },
                                      [_vm._v("Remove Queued Messages")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("transition", { attrs: { name: "fade" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-horizontal" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isLoading,
                                            variant: "outline-danger"
                                          },
                                          on: { click: _vm.clearAdvanceNotices }
                                        },
                                        [
                                          _vm._v(
                                            "Remove Advance Notice Messages"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "div",
                                { staticClass: "form-horizontal" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-secondary" },
                                      on: { click: _vm.goToQueue }
                                    },
                                    [_vm._v("View Queued Messages")]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "vue-good-table",
                                  {
                                    ref: "table",
                                    attrs: {
                                      mode: "remote",
                                      columns: _vm.columns,
                                      rows: _vm.schedule,
                                      lineNumbers: true,
                                      totalRows: _vm.totalRecords,
                                      rowStyleClass: _vm.rowStyler,
                                      "search-options": {
                                        enabled: true
                                      },
                                      isLoading: _vm.isTableLoading,
                                      paginationOptions: _vm.paginationOptions,
                                      "sort-options": _vm.sortOptions,
                                      styleClass: "vgt-table striped bordered"
                                    },
                                    on: {
                                      "on-page-change": _vm.onPageChange,
                                      "on-sort-change": _vm.onSortChange,
                                      "on-column-filter": _vm.onColumnFilter,
                                      "on-per-page-change": _vm.onPerPageChange,
                                      "on-search": _vm.onSearch,
                                      "update:isLoading": function($event) {
                                        _vm.isTableLoading = $event
                                      },
                                      "update:is-loading": function($event) {
                                        _vm.isTableLoading = $event
                                      },
                                      "on-row-click": _vm.onRowClick
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "table-row",
                                        fn: function(props) {
                                          return [
                                            (_vm.selectedRow &&
                                              props.row.paymentComposite !==
                                                "" &&
                                                props.row.paymentComposite ===
                                                  _vm.selectedRow
                                                    .paymentComposite) ||
                                            (props.row.paymentComposite ===
                                              "" &&
                                              props.row
                                                .ukddmsPendingPaymentId ==
                                                _vm.selectedRow
                                                  .ukddmsPendingPaymentId)
                                              ? _c("span", [
                                                  props.column.label ===
                                                  "Status"
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c(
                                                                "b-select",
                                                                {
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.statusChanged(
                                                                        props.row,
                                                                        props
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.row
                                                                        .status,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        props.row,
                                                                        "status",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "props.row.status"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps: {
                                                                        value: 9
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Pending First Submission"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps: {
                                                                        value: 8
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Pending Regular Submission"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps: {
                                                                        value: 7
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Pending Re Presentation"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps: {
                                                                        value: 6
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Pending Final Submission"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps: {
                                                                        value: 10
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Deleted"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm.allowDelete
                                                                ? _c(
                                                                    "b-input-group-append",
                                                                    [
                                                                      props.row
                                                                        .status !==
                                                                        10 &&
                                                                      _vm.allowDelete
                                                                        ? _c(
                                                                            "b-button",
                                                                            {
                                                                              attrs: {
                                                                                variant:
                                                                                  "danger",
                                                                                disabled:
                                                                                  _vm.isLoading
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteSchedule(
                                                                                    props.row,
                                                                                    props
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-ban mr-1"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                "Delete "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      props.row
                                                                        .status ===
                                                                        10 &&
                                                                      _vm.allowDelete
                                                                        ? _c(
                                                                            "b-button",
                                                                            {
                                                                              attrs: {
                                                                                variant:
                                                                                  "danger",
                                                                                disabled:
                                                                                  _vm.isLoading
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.undeleteSchedule(
                                                                                    props.row,
                                                                                    props
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-check mr-1"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                "Undelete "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  props.column.field ===
                                                  "amount"
                                                    ? _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-nowrap"
                                                          },
                                                          [
                                                            props.row
                                                              .pendingVariableAmount ||
                                                            props.row.amount ==
                                                              0
                                                              ? _c("i", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "b-tooltip",
                                                                      rawName:
                                                                        "v-b-tooltip.hover",
                                                                      modifiers: {
                                                                        hover: true
                                                                      }
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "fa fa-exclamation-circle text-warning mr-2",
                                                                  attrs: {
                                                                    title:
                                                                      "Variable payment amount required!"
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "currency-input",
                                                              {
                                                                attrs: {
                                                                  currency:
                                                                    "GBP",
                                                                  locale: "en"
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.amountChanged(
                                                                      props
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    props.row
                                                                      .amount,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "amount",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "props.row.amount"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  props.column.field ===
                                                  "transactionCode"
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "b-select",
                                                            {
                                                              attrs: {
                                                                type: "text"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.transactionCodeChanged(
                                                                    props.row,
                                                                    props
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .transactionCode,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    "transactionCode",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "props.row.transactionCode"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  domProps: {
                                                                    value: "01"
                                                                  }
                                                                },
                                                                [_vm._v("01")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  domProps: {
                                                                    value: "17"
                                                                  }
                                                                },
                                                                [_vm._v("17")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  domProps: {
                                                                    value: "18"
                                                                  }
                                                                },
                                                                [_vm._v("18")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  domProps: {
                                                                    value: "19"
                                                                  }
                                                                },
                                                                [_vm._v("19")]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  props.column.label ===
                                                  "Collection Date"
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("datepicker", {
                                                            attrs: {
                                                              disabledDates:
                                                                _vm.disabledDates
                                                            },
                                                            on: {
                                                              selected:
                                                                _vm.collectionDateChanged
                                                            },
                                                            model: {
                                                              value:
                                                                props.row
                                                                  .collectionDate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  props.row,
                                                                  "collectionDate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "props.row.collectionDate"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  props.column.label ===
                                                  "Processing Date"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.formattedRow[
                                                              props.column.field
                                                            ]
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  props.column.field ===
                                                  "editMode"
                                                    ? _c("span", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                props.row
                                                                  .editMode,
                                                              expression:
                                                                "props.row.editMode"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "checkbox"
                                                          },
                                                          domProps: {
                                                            checked: Array.isArray(
                                                              props.row.editMode
                                                            )
                                                              ? _vm._i(
                                                                  props.row
                                                                    .editMode,
                                                                  null
                                                                ) > -1
                                                              : props.row
                                                                  .editMode
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  props.row
                                                                    .editMode,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "editMode",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "editMode",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  props.row,
                                                                  "editMode",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        })
                                                      ])
                                                    : _vm._e(),
                                                  props.column.label === ""
                                                    ? _c(
                                                        "span",
                                                        [
                                                          props.row.status !==
                                                            10 &&
                                                          _vm.allowDelete
                                                            ? _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-danger",
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.deleteSchedule
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-ban mr-1"
                                                                  }),
                                                                  _vm._v(
                                                                    "Delete "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          props.row.status ===
                                                            10 &&
                                                          _vm.allowDelete
                                                            ? _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-danger",
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.undeleteSchedule
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-check mr-1"
                                                                  }),
                                                                  _vm._v(
                                                                    "Undelete "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ])
                                              : _c("span", [
                                                  props.column.field == "amount"
                                                    ? _c("span", [
                                                        props.row
                                                          .pendingVariableAmount ||
                                                        props.row.amount === 0
                                                          ? _c("i", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "fa fa-exclamation-circle text-danger",
                                                              attrs: {
                                                                title:
                                                                  "Variable payment amount required!"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              props
                                                                .formattedRow[
                                                                props.column
                                                                  .field
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : props.column.label ==
                                                      "Status"
                                                    ? _c("span", [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              props
                                                                .formattedRow[
                                                                props.column
                                                                  .field
                                                              ]
                                                            )
                                                          }
                                                        }),
                                                        _vm.hasMessage(
                                                          props.row
                                                            .paymentComposite
                                                        )
                                                          ? _c("i", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "fa fa-envelope ml-3",
                                                              attrs: {
                                                                title: "Message"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.selectMessage(
                                                                    props.row
                                                                      .paymentComposite
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.formattedRow[
                                                              props.column.field
                                                            ]
                                                          )
                                                        )
                                                      ])
                                                ])
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("template", { slot: "loadingContent" }, [
                                      _c("h1", [_vm._v("Loading...")])
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "emptystate" },
                                        slot: "emptystate"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vgt-center-align vgt-text-disabled"
                                          },
                                          [_vm._v("No Schedule Available")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "table-actions" },
                                        slot: "table-actions"
                                      },
                                      [
                                        _c(
                                          "p-check",
                                          {
                                            staticClass: "p-switch p-fill",
                                            attrs: { color: "primary" },
                                            on: { change: _vm.load },
                                            model: {
                                              value: _vm.includeHistoric,
                                              callback: function($$v) {
                                                _vm.includeHistoric = $$v
                                              },
                                              expression: "includeHistoric"
                                            }
                                          },
                                          [_vm._v("Include Historic")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName:
                                                  "v-b-popover.hover.top.d500",
                                                value: "Clear filters",
                                                expression: "'Clear filters'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                  d500: true
                                                }
                                              }
                                            ],
                                            staticClass: "btn btn-link",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.clearTableFilters(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "fa-stack",
                                                staticStyle: {
                                                  "font-size": "10px"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-filter fa-stack-1x dimmedIcon"
                                                }),
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-ban fa-stack-2x dimmedIcon"
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm.allowInsert
                                          ? _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value: "Add payment",
                                                    expression: "'Add payment'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  variant: "link",
                                                  disabled: _vm.isLoading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.addPayment(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-plus pointer dimmedIcon"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName:
                                                  "v-b-popover.hover.top.d500",
                                                value:
                                                  "Refresh the data in the table",
                                                expression:
                                                  "'Refresh the data in the table'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                  d500: true
                                                }
                                              }
                                            ],
                                            attrs: {
                                              variant: "link",
                                              disabled: _vm.isLoading
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.load($event)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-sync pointer dimmedIcon"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName:
                                                  "v-b-popover.hover.top.d500",
                                                value:
                                                  "Print out the contents of the Logs table",
                                                expression:
                                                  "'Print out the contents of the Logs table'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                  d500: true
                                                }
                                              }
                                            ],
                                            attrs: { variant: "link" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.printTable($event)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-print pointer dimmedIcon"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName:
                                                  "v-b-popover.hover.top.d500",
                                                value:
                                                  "Export the contents of the Logs table",
                                                expression:
                                                  "'Export the contents of the Logs table'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                  d500: true
                                                }
                                              }
                                            ],
                                            attrs: {
                                              variant: "link",
                                              disabled: _vm.isLoading
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.exportTable($event)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-share-square pointer dimmedIcon"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "idb-block-footer" },
                      [
                        _vm.allowInsert
                          ? _c(
                              "b-btn",
                              {
                                attrs: {
                                  type: "link",
                                  variant: "primary",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.saveSchedule($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-save mr-2",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v("Save Changes ")
                              ]
                            )
                          : _vm._e(),
                        _vm.allowInsert
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  type: "link",
                                  variant: "outline-primary",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.addPayment($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus mr-2",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v("Add Payment ")
                              ]
                            )
                          : _vm._e(),
                        _vm.allowInsert
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  type: "link",
                                  variant: "danger",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.cancelSchedule($event)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-times mr-2" }),
                                _vm._v("Cancel Changes ")
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "message-modal",
          attrs: { id: "message-modal" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h4", { staticClass: "modal-title" }, [
                    _c("i", { staticClass: "fa fa-envelope" }),
                    _vm._v(" " + _vm._s(_vm.selectedMessage.messageTitle) + " ")
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("message-modal")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-times" }), _vm._v(" Close ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.selectedMessage.messageBody) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }