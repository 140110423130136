import axios from 'axios'

// initial state
const state = {
  paygateId: null,
  customers: [],
  idToken: null,
  corvidUser: true
}

// getters
const getters = {
  customersOrdered: state => {
    return state.customers.sort((a, b) => {
      var textA = a.name.toUpperCase()
      var textB = b.name.toUpperCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })
  },
  selectedCustomer: state => {
    return state.paygateId
  }
}

// actions
const actions = {
  setPaygateId ({ commit }, paygateId) {
    commit('setPaygateId', paygateId)
  },
  resetPaygateId ({ commit, state }) {
    commit('resetPaygateId')
  },
  checkPaygateId ({ commit, state }) {
    if (state.paygateId !== null && state.paygateId !== undefined) {
      if (!state.customers.some((c) => c.paygateId === state.paygateId)) {
        commit('setPaygateId', this.state.common.customers[0].paygateId)
      }
    }
  },
  async loadCustomersList ({ commit, state, dispatch }) {
    try {
      const response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'customers', { params: { fields: 'paygateId,name' } })
      await commit('addCustomersToList', response.data.data)
      await dispatch('checkPaygateId')
      if (state.paygateId === null) {
        if (response.data.data.length > 1) {
          commit('setPaygateId', process.env.VUE_APP_CORVID_ID)
        } else {
          commit('setPaygateId', response.data.data[0].paygateId)
        }
      }
    } catch (error) {
      console.error(error)
    }
  },
  addCustomerToList ({ commit }, customer) {
    commit('addCustomerToList', customer)
  },
  removeCustomerFromList ({ commit }, paygateId) {
    commit('removeCustomerFromList', paygateId)
  },
  updateCustomerInList ({ commit }, customer) {
    commit('updateCustomerInList', customer)
  }
}

// mutations
const mutations = {
  setPaygateId (state, paygateId) {
    state.paygateId = paygateId
  },
  resetPaygateId (state) {
    state.paygateId = 'undefined'
  },
  addCustomersToList (state, customers) {
    state.customers = customers
  },
  addCustomerToList (state, customer) {
    state.customers.push(customer)
  },
  removeCustomerFromList (state, paygateId) {
    const index = state.customers.findIndex(x => x.paygateId === paygateId)
    if (typeof index !== 'undefined') {
      state.customers.splice(index, 1)
    }
  },
  updateCustomerInList (state, customer) {
    const index = state.customers.findIndex(x => x.paygateId === customer.paygateId)
    if (typeof index !== 'undefined') {
      state.customers[index].name = customer.name
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
