<template>
  <form @submit.prevent="!isLoading && checkValidation() && validate()">
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">
              UK Bank Account Validation
              <help-icon docPath="/validate/ukbankaccount/" />
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div
                class="form-group col-md-4 col-sm-12"
                :class="{
                  'valid':sortCodeValid,
                  'invalid': sortCodeInvalid
                  }"
              >
                <!-- Label -->
                <label class="label-control required">Sort Code</label>
                <!-- Input -->
                <div style="display: flex;">
                  <the-mask
                    type="text"
                    class="form-control"
                    v-model="bankAccount.sortCode"
                    v-focus
                    :mask="['##-##-##']"
                    :guide="true"
                    ref="sortCode"
                    @input="sortCodeInput"
                  />
                  <div style="width:38px;margin-left: 20px;float:right">
                    <span
                      v-if="sortCodeValid"
                      style="font-size: 200%; color: green;"
                      class="fa fa-check-circle"
                    ></span>
                    <span
                      v-if="sortCodeInvalid"
                      style="font-size: 200%; color: red"
                      class="fa fa-times-circle"
                    ></span>
                  </div>
                </div>
                <span v-if="$v.bankAccount.sortCode.$error">
                  <small
                    class="form-text text-danger small"
                    v-if="!$v.bankAccount.sortCode.required"
                  >A Sort Code is required</small>
                  <small
                    class="form-text text-danger small"
                    v-if="!$v.bankAccount.sortCode.minLength || !$v.bankAccount.sortCode.maxLength"
                  >A Sort Code number is required to be 6 numbers long</small>
                </span>
              </div>
              <div
                class="form-group col-md-7 col-sm-12"
                :class="{
                  'valid':accountNumberValid,
                  'invalid': accountNumberInvalid
                  }"
              >
                <!-- Label -->
                <label class="label-control">Account Number</label>
                <!-- Input -->
                <div style="display: flex;">
                  <the-mask
                    type="text"
                    class="form-control"
                    v-model="bankAccount.accountNumber"
                    :mask="['########']"
                    :guide="false"
                    @input="accountNumberInput"
                    ref="accountNumber"
                  />
                  <div style="width:38px;margin-left: 20px;float:right">
                    <span
                      v-if="accountNumberValid"
                      style="font-size: 200%; color: green;"
                      class="fa fa-check-circle"
                    ></span>
                    <span
                      v-if="accountNumberInvalid"
                      style="font-size: 200%; color: red"
                      class="fa fa-times-circle"
                    ></span>
                  </div>
                </div>
                <span v-if="$v.bankAccount.accountNumber.$error">
                  <small
                    class="form-text text-danger small"
                    v-if="!$v.bankAccount.accountNumber.minLength || !$v.bankAccount.accountNumber.maxLength"
                  >An Account number is required to be 8 numbers long</small>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-8 col-sm-12">
                <span v-if="bankAccount.result != null">
                  <small
                    v-if="bankAccount.result.valid"
                    class="form-text text-success small"
                  >The Bank Account is valid!</small>
                  <small
                    v-if="!bankAccount.result.valid && (!bankAccount.result.institutionName || bankAccount.accountNumber.length === 8)"
                    class="form-text text-danger small"
                  >{{bankAccount.result.errorText}}</small>
                </span>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button type="submit" class="btn btn-primary" :disabled="isLoading">Validate</button>

            <button
              type="button"
              class="btn btn-outline-primary ml-3"
              @click="clear"
              :disabled="isLoading"
            >Clear</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="bankAccount.result != null && showBankDetails">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">Bank Details</h2>
          </div>
          <div class="idb-block-content">
            <form novalidate v-on:submit.prevent class="col-md-12">
              <div class="form-group row">
                <label class="label-control col-md-3">Bank</label>
                <span class="form-control-static col-auto">{{bankAccount.result.institutionName}}</span>
              </div>
              <div class="form-group row">
                <label class="label-control col-md-3">Branch</label>
                <span class="form-control-static col-auto">{{bankAccount.result.branchTitle}}</span>
              </div>
              <div class="form-group row">
                <label class="label-control col-md-3">Address</label>
                <address class="form-control-static col-auto" v-html="address"></address>
              </div>
              <div class="form-group row" v-show="bankAccount.result.bic">
                <label class="label-control col-md-3">BIC</label>
                <span class="form-control-static col-auto">{{bankAccount.result.bic}}</span>
              </div>
              <div class="form-group row">
                <label class="label-control col-md-3">Account Code</label>
                <span class="form-control-static col-auto">{{bankAccount.result.accountCode}}</span>
              </div>
              <div class="row" v-if="subBranches.length > 1">
                <div class="col-md-12">
                  <span>This sortcode links to multiple sub-branches</span>
                  <vue-good-table
                    :columns="subBranchColumns"
                    :rows="this.subBranches"
                    styleClass="vgt-table striped condensed"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bankAccount.result != null && showBankDetails">
      <div class="row">
        <div class="col-md-6 col-sm-12" v-if="bacsDetails">
          <div class="idb-block validate-big-block">
            <div class="idb-block-title">
              <img src="/img/bankAccountLogos/bacs.png" class="bank-logo" />
            </div>
            <div class="idb-block-content">
              <form novalidate v-on:submit.prevent class="col-md-12">
                <div class="form-group row">
                  <label
                    class="label-control col-md-10"
                    v-if="bacsDetails.status == 'M'"
                  >Bank office accepts Bacs payments.</label>
                  <label
                    class="label-control col-md-10"
                    v-else-if="bacsDetails.status == 'A'"
                  >Bank office accepts Bacs payments (sponsored).</label>
                  <label
                    class="label-control col-md-10"
                    v-else-if="bacsDetails.status == 'N'"
                  >Bank office does not accept Bacs payments.</label>
                  <label class="label-control col-md-10" v-else>No Data Available.</label>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-2">
                    <span
                      class="fa"
                      :class="{'fa-check text-success':!bacsDetails.transactionsDisallowedAu,'fa-times text-danger':bacsDetails.transactionsDisallowedAu}"
                    ></span>
                  </label>
                  <span class="form-control-static col-md-10">Direct Debits</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-2">
                    <span
                      class="fa"
                      :class="{'fa-check text-success':!bacsDetails.transactionsDisallowedBs,'fa-times text-danger':bacsDetails.transactionsDisallowedBs}"
                    ></span>
                  </label>
                  <span class="form-control-static col-md-10">Credits</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-2">
                    <span
                      class="fa"
                      :class="{'fa-check text-success':!bacsDetails.transactionsDisallowedCr,'fa-times text-danger':bacsDetails.transactionsDisallowedCr}"
                    ></span>
                  </label>
                  <span class="form-control-static col-md-10">Claims for Unpaid Cheques</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-2">
                    <span
                      class="fa"
                      :class="{'fa-check text-success':!bacsDetails.transactionsDisallowedCu,'fa-times text-danger':bacsDetails.transactionsDisallowedCu}"
                    ></span>
                  </label>
                  <span class="form-control-static col-md-10">Interest Payments</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-2">
                    <span
                      class="fa"
                      :class="{'fa-check text-success':!bacsDetails.transactionsDisallowedDr,'fa-times text-danger':bacsDetails.transactionsDisallowedDr}"
                    ></span>
                  </label>
                  <span class="form-control-static col-md-10">Dividend Payments</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-2">
                    <span
                      class="fa"
                      :class="{'fa-check text-success':!bacsDetails.transactionsDisallowedDV,'fa-times text-danger':bacsDetails.transactionsDisallowedDV}"
                    ></span>
                  </label>
                  <span class="form-control-static col-md-10">Direct Debit Instructions</span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" v-if="fpsDetails">
          <div class="idb-block validate-big-block">
            <div class="idb-block-title">
              <img src="/img/bankAccountLogos/fps.png" class="bank-logo" />
            </div>
            <div class="idb-block-content">
              <form novalidate v-on:submit.prevent class="col-md-12">
                <div class="form-group row">
                  <label
                    class="label-control col-md-12"
                    v-if="fpsDetails.status == 'M'"
                  >Bank office is an FPS Member - Accepts Faster Payments.</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="fpsDetails.status == 'A'"
                  >Bank office is an Agency Bank - Accepts Faster Payments.</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="fpsDetails.status == 'N'"
                  >Bank office does not accept Faster Payments.</label>
                  <label
                    class="label-control col-md-12"
                    v-else
                  >Bank office does not accept Faster Payments.</label>
                </div>
                <div class="form-group row" v-if="fpsDetails.fpsRedirectionFromFlag == 'R'">
                  <label
                    class="label-control col-md-12"
                  >The bank office is set as the redirection sorting code for one or more bank offices</label>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Handling Bank</label>
                  <span class="form-control-static col-auto">{{handlingBank}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Settlement Bank</label>
                  <span class="form-control-static col-auto">{{fpsDetails.settlementBankName}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Date Last Changed</label>
                  <span
                    class="form-control-static col-auto"
                  >{{fpsDetails.effectiveDateOfLastChange | formatDate}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Date Closed</label>
                  <span
                    class="form-control-static col-auto"
                  >{{fpsDetails.dateClosedInFps| formatDate}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Redirection Sortcode</label>
                  <span class="form-control-static col-auto">{{fpsDetails.redirectToSortingCode}}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="chapsDetails">
          <div class="idb-block validate-big-block">
            <div class="idb-block-title">
              <img src="/img/bankAccountLogos/chaps.png" class="bank-logo" />
            </div>
            <div class="idb-block-content">
              <form novalidate v-on:submit.prevent class="col-md-12">
                <div class="form-group row">
                  <label
                    class="label-control col-md-12"
                    v-if="chapsDetails.status == 'D'"
                  >Bank office accepts Chaps Sterling payments. (Direct).</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="chapsDetails.status == 'I'"
                  >Bank office accepts Chaps Sterling payments. (Indirect).</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="chapsDetails.status == 'N'"
                  >Bank office does not accept Chaps Sterling payments.</label>
                  <label class="label-control col-md-12" v-else>No Data Available.</label>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">CHAPS Sterling ID</label>
                  <span class="form-control-static col-auto">{{chapsDetails.settlementMember}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Routing BIC</label>
                  <span
                    class="form-control-static col-auto"
                  >{{chapsDetails.routing1}} {{chapsDetails.routing2}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Date last changed</label>
                  <span
                    class="form-control-static col-auto"
                  >{{chapsDetails.effectiveDateOfLastChange|formatDate}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Date closed</label>
                  <span
                    class="form-control-static col-auto"
                  >{{chapsDetails.dateClosedIn |formatDate}}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="cccDetails">
          <div class="idb-block validate-big-block">
            <div class="idb-block-title">
              <img src="/img/bankAccountLogos/ccc.png" class="img-responsive bank-logo" />
            </div>
            <div class="idb-block-content">
              <form novalidate v-on:submit.prevent class="col-md-12">
                <div class="form-group row">
                  <label
                    class="label-control col-md-12"
                    v-if="cccDetails.status == 'M'"
                  >Owning bank of bank office is a C&CC scheme member.</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="cccDetails.status == 'F'"
                  >Bank office is a full agency.</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="cccDetails.status == 'D'"
                  >Bank office is a debit agency.</label>
                  <label
                    class="label-control col-md-12"
                    v-else-if="cccDetails.status == 'N'"
                  >Bank office does not accept C&CC payments.</label>
                  <label class="label-control col-md-12" v-else>No Data Available.</label>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Settlement Bank</label>
                  <span class="form-control-static col-auto">{{cccDetails.settlementBank}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Debt Agency Sortcode</label>
                  <span class="form-control-static col-auto">{{cccDetails.debitAgencySortingCode}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Date last changed</label>
                  <span
                    class="form-control-static col-auto"
                  >{{cccDetails.effectiveDateOfLastChange|formatDate}}</span>
                </div>
                <div class="form-group row">
                  <label class="label-control col-md-6 col-lg-4 col-xl-3">Date closed</label>
                  <span
                    class="form-control-static col-auto"
                  >{{cccDetails.dateClosedInCcc |formatDate}}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import loading from '@/Assets/Mixins/LoadingMixin'

function addressFormatter (addressObject, joinString) {
  var addressArr = []

  if (addressObject.address1) {
    addressArr.push(addressObject.address1)
  }
  if (addressObject.address2) {
    addressArr.push(addressObject.address2)
  }
  if (addressObject.address3) {
    addressArr.push(addressObject.address3)
  }
  if (addressObject.address4) {
    addressArr.push(addressObject.address4)
  }
  if (addressObject.county) {
    addressArr.push(addressObject.county)
  }
  if (addressObject.town) {
    addressArr.push(addressObject.town)
  }
  if (addressObject.postcode) {
    addressArr.push(addressObject.postcode)
  }

  return addressArr.join(joinString)
}

export default {
  name: 'bankAccountValidation',
  mixins: [loading],
  data: function () {
    return {
      loading: false,
      showBankDetails: false,
      bankAccount: {
        sortCode: '',
        accountNumber: '',
        result: null
      },

      validation: {
        sortCodeMax: 8,
        sortCodeMin: 6
      },
      subBranches: [],
      bacsDetails: null,
      fpsDetails: null,
      chapsDetails: null,
      cccDetails: null,
      subBranchColumns: [
        {
          label: 'Sub-Branch Name',
          field: 'institutionName'
        }, {
          label: 'Title',
          field: 'branchTitle'
        }, {
          label: 'Address',
          field: this.addressField
        }
      ]
    }
  },
  computed: {
    address () {
      return addressFormatter(this.bankAccount.result, '<br/>')
    },
    handlingBank () {
      if (this.fpsDetails) {
        let handlingBank = this.fpsDetails.handlingBankName

        switch (this.fpsDetails.handlingBankConnectionType) {
          case '01':
            handlingBank += ' - FPS Member'
            break
          case '02':
            handlingBank += ' - Direct Agency'
            break
          case '04':
            handlingBank += ' - Responding third party beneficiary'
            break
          case '05':
            handlingBank += ' - Nonresponding third party beneficiary'
            break
        }
        return handlingBank
      }
      return ''
    },
    sortCodeInvalid () {
      var result = this.$v.bankAccount.sortCode.$error

      if (this.bankAccount.result != null) {
        if (!this.bankAccount.result.valid && this.bankAccount.result.errorCode === '1501') {
          result = true
        }

        if (this.bankAccount.result.valid && (this.bankAccount.result.institutionName == null && this.bankAccount.result.institutionName === '')) {
          result = true
        }

        if (this.bankAccount.result.errorCode === '1502' && (this.bankAccount.result.institutionName == null && this.bankAccount.result.institutionName === '')) {
          result = true
        }
      }

      return result
    },
    sortCodeValid () {
      var result = false
      if (this.bankAccount.result != null) {
        if (this.bankAccount.result.valid || (this.bankAccount.result.institutionName != null && this.bankAccount.result.institutionName !== '')) {
          result = true
        }
      }

      return result
    },
    accountNumberInvalid () {
      var result = this.$v.bankAccount.accountNumber.$error

      if (this.bankAccount.result != null) {
        if (!this.bankAccount.result.valid && this.bankAccount.result.errorCode === '1501') {
          result = true
        }

        if (!this.bankAccount.result.valid && this.bankAccount.result.accountNumber != null) {
          result = true
        }
      }

      return result
    },
    accountNumberValid () {
      if (this.bankAccount.result != null) {
        return this.bankAccount.result.valid
      }

      return false
    }
  },
  methods: {
    sortCodeInput () {
      this.$v.bankAccount.sortCode.$reset()
      if (this.$v.bankAccount.sortCode.$model.length === 6) {
        this.$refs.accountNumber.$el.focus()
      }
    },
    accountNumberInput () {
      this.$v.bankAccount.accountNumber.$reset()
    },
    async validate () {
      this.showBankDetails = false
      const data = { sortCode: this.bankAccount.sortCode }

      if (this.bankAccount.accountNumber.length === 8) {
        data.accountNumber = this.bankAccount.accountNumber
      }

      if (this.$v.$error) {
        return
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}BankAccount`, {
          params: data,
          showload: true,
          showerror: true,
          errormessage: 'Failed to validate bank account'
        })

        this.bankAccount.result = response.data

        if (this.bankAccount.result.valid || (this.bankAccount.result.institutionName != null && this.bankAccount.result.institutionName !== '')) {
          this.showBankDetails = true
          this.getSubBranch()
          this.getBacs()
          this.getFps()
          this.getChaps()
          this.getCcc()
        }
      } catch { }
    },
    async getSubBranch () {
      if (this.bankAccount.result.numSubBranch > 1) {
        var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}BankAccount/GetSubBranches`, {
          params: {
            sortCode: this.bankAccount.sortCode,
            fid: this.bankAccount.result.fid
          }
        })

        this.subBranches = response.data
      } else {
        this.subBranches = []
      }
    },
    async getBacs () {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}BankAccount/GetBacsDetails`, {
        params: {
          fid: this.bankAccount.result.fid
        }
      })
      this.bacsDetails = response.data
    },
    async getFps () {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}BankAccount/GetFpsDetails`, {
        params: {
          fid: this.bankAccount.result.fid
        }
      })
      this.fpsDetails = response.data
    },
    async getChaps () {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}BankAccount/GetChapsDetails`, {
        params: {
          fid: this.bankAccount.result.fid
        }
      })
      this.chapsDetails = response.data
    },
    async getCcc () {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}BankAccount/GetCccDetails`, {
        params: {
          fid: this.bankAccount.result.fid
        }
      })
      this.cccDetails = response.data
    },
    clear: function () {
      this.bankAccount.sortCode = ''
      this.bankAccount.accountNumber = ''
      this.bankAccount.result = null
      this.subBranches = []
      this.bacsDetails = null
      this.fpsDetails = null
      this.chapsDetails = null
      this.cccDetails = null
      this.$nextTick(() => {
        this.$refs.sortCode.$el.focus()

        this.$v.$reset()
      })
    },
    addressField: function (rowObj) {
      return addressFormatter(rowObj, ', ')
    }
  },
  components: {
    TheMask
  },
  mounted () {
    this.$refs.sortCode.$el.focus()
  },
  validations () {
    return {
      bankAccount: {
        sortCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
        accountNumber: { minLength: minLength(8), maxLength: maxLength(8) }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bank-logo {
  max-height: 80px;
}

.validate-big-block {
  min-height: 550px;
}
</style>
