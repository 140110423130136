var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "Designer" },
    [
      _vm.showMetaDataPopup
        ? _c(
            "workflowMetaDataPopup",
            {
              on: {
                close: function($event) {
                  _vm.showMetaDataPopup = false
                  _vm.closeMetaDataPopup(true)
                },
                cancel: function($event) {
                  _vm.showMetaDataPopup = false
                  _vm.closeMetaDataPopup(false)
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Workflow Metadata")
              ])
            ]
          )
        : _vm._e(),
      _vm.showModal
        ? _c("modal", {
            on: {
              close: function($event) {
                _vm.showModal = false
              }
            }
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal-validation",
          attrs: {
            scrollable: "",
            centered: "",
            size: "huge",
            "hide-footer": "",
            title: "Workflow Validation"
          },
          on: { hidden: _vm.cancelValidationModal }
        },
        [
          _c(
            "div",
            [
              _c("vue-good-table", {
                attrs: {
                  columns: _vm.validation_columns,
                  rows: _vm.validationResponse,
                  lineNumbers: true,
                  "search-options": {
                    enabled: true
                  },
                  "pagination-options": {
                    enabled: true,
                    perPage: 10,
                    perPageDropdown: [10, 50, 100],
                    dropdownAllowAll: true,
                    rowsPerPageLabel: "Messages per page"
                  },
                  styleClass: "vgt-table striped bordered table-hover"
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field === "level"
                          ? _c(
                              "span",
                              [
                                props.row.level == "Invalid"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "danger" } },
                                      [_vm._v(_vm._s(props.row.level))]
                                    )
                                  : props.row.level === "Warning"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "warning" } },
                                      [_vm._v(_vm._s(props.row.level))]
                                    )
                                  : props.row.level === "Info"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "info" } },
                                      [_vm._v(_vm._s(props.row.level))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(props.row.level))
                                    ])
                              ],
                              1
                            )
                          : props.column.field === "createdAt"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reformatDate(
                                      props.formattedRow[props.column.field]
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : props.column.field === "updatedAt"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reformatDate(
                                      props.formattedRow[props.column.field]
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : props.column.field === "buttons"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value:
                                          "Drill down into this workflow event.",
                                        expression:
                                          "'Drill down into this workflow event.'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    staticClass: "pointer",
                                    attrs: {
                                      variant: "link",
                                      title: "View Workflow Event"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.viewWorkflowEvent(props)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-secondary" },
                                      [
                                        _c("i", {
                                          staticClass: "glyphicon ti-list"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "primary" },
              on: { click: _vm.hideValidationModal }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            scrollable: "",
            centered: "",
            size: "huge",
            "hide-footer": "",
            title: _vm.executionPopupTitle
          },
          on: { hidden: _vm.cancelTelemetryModal }
        },
        [
          _c(
            "div",
            [
              _vm.showTelemetryWarning
                ? _c(
                    "b-alert",
                    {
                      attrs: {
                        variant: "warning",
                        dismissible: "",
                        show: _vm.showTelemetryWarning
                      }
                    },
                    [
                      _vm._v("Realtime Telemetry has stopped updating."),
                      _c("br"),
                      _c("small", [
                        _vm._v(
                          "This is normal for long running workflows and the workflow execution is not affected."
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Events", active: "" } },
                    [
                      _c(
                        "vue-good-table",
                        {
                          attrs: {
                            columns: _vm.columns,
                            rows: _vm.rows,
                            lineNumbers: true,
                            "search-options": { enabled: true },
                            "pagination-options": {
                              enabled: true,
                              perPage: 10,
                              perPageDropdown: [10, 50, 100],
                              dropdownAllowAll: true,
                              rowsPerPageLabel: "Events per page"
                            },
                            styleClass: "vgt-table striped bordered table-hover"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field === "currentStatus"
                                    ? _c(
                                        "span",
                                        [
                                          props.row.currentStatus == "Failed"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: "danger"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currentStatus
                                                    )
                                                  )
                                                ]
                                              )
                                            : props.row.currentStatus ===
                                              "Complete"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: "success"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currentStatus
                                                    )
                                                  )
                                                ]
                                              )
                                            : props.row.currentStatus ===
                                              "Completed with warnings"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: "warning"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currentStatus
                                                    )
                                                  )
                                                ]
                                              )
                                            : props.row.currentStatus ===
                                              "Processing"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: "primary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currentStatus
                                                    )
                                                  )
                                                ]
                                              )
                                            : props.row.currentStatus ===
                                              "Warning"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: "warning"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currentStatus
                                                    )
                                                  )
                                                ]
                                              )
                                            : props.row.currentStatus ===
                                              "Paused"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: "warning"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currentStatus
                                                    )
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.row.currentStatus
                                                  )
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : props.column.field === "createdAt"
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.reformatDate(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : props.column.field === "updatedAt"
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.reformatDate(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : props.column.field === "buttons"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-popover",
                                                  rawName:
                                                    "v-b-popover.hover.top.d500",
                                                  value:
                                                    "Drill down into this workflow event.",
                                                  expression:
                                                    "'Drill down into this workflow event.'",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true,
                                                    d500: true
                                                  }
                                                }
                                              ],
                                              staticClass: "pointer",
                                              attrs: {
                                                variant: "link",
                                                title: "View Workflow Event"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.viewWorkflowEvent(
                                                    props
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-secondary"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "glyphicon ti-list"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    title: "Refresh Table"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.getEventData($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-sync-alt pointer dimmedIcon pointer"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _vm._v(" Logs "),
                          _vm.log_rows.length > 0
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "ml-2",
                                  attrs: { pill: "", variant: "primary" }
                                },
                                [_vm._v(_vm._s(_vm.log_rows.length))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "vue-good-table",
                        {
                          attrs: {
                            columns: _vm.log_columns,
                            rows: _vm.log_rows,
                            lineNumbers: true,
                            "search-options": {
                              enabled: true
                            },
                            "pagination-options": {
                              enabled: true,
                              perPage: 10,
                              perPageDropdown: [10, 50, 100],
                              dropdownAllowAll: true,
                              rowsPerPageLabel: "Logs per page"
                            },
                            styleClass: "vgt-table striped bordered table-hover"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field == "datetime"
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.reformatDate(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    title: "Refresh Table"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.getLogData($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-sync-alt pointer dimmedIcon pointer"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "primary" },
              on: { click: _vm.hideExecutionModal }
            },
            [_vm._v("Close")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 ml-2",
              attrs: { variant: "outline-primary" },
              on: { click: _vm.btnWorkflowTelemetry }
            },
            [_vm._v("Workflow Telemetry")]
          )
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { ref: "blockwidth", staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v("Workflow Designer"),
                  _vm.workflowMetaData.title !== ""
                    ? _c("span", [_vm._v(": ")])
                    : _vm._e(),
                  _vm._v(_vm._s(_vm.workflowMetaData.title)),
                  _c("span", { staticClass: "pull-right" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.docUrl + "/automation/paygate-designer/",
                          target: "_blank"
                        }
                      },
                      [_c("i", { staticClass: "far fa-question-circle" })]
                    )
                  ]),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.ie11
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-warning alert-dismissible",
                        attrs: { role: "alert" }
                      },
                      [
                        _vm._m(0),
                        _c("p", [
                          _vm._v(
                            "Some features of the Workflow Designer may not work correctly on very old, unmaintained browsers such as Internet Explorer 11. Please consider using a modern browser such as Microsoft Edge, Google Chrome or Mozilla Firefox. "
                          )
                        ]),
                        _c("hr"),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(" Follow this "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.docUrl + "/troubleshooting/ie11/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("link")]
                          ),
                          _vm._v(" for more details.")
                        ])
                      ]
                    )
                  : _vm._e(),
                !_vm.hideUI && _vm.isLicenced
                  ? _c(
                      "b-navbar",
                      {
                        ref: "navbar",
                        attrs: { toggleable: "md", type: "light" }
                      },
                      [
                        _c("b-navbar-toggle", {
                          attrs: { target: "nav_collapse" }
                        }),
                        _c(
                          "b-collapse",
                          { attrs: { "is-nav": "", id: "nav_collapse" } },
                          [
                            _c(
                              "b-navbar-nav",
                              [
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "File"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { disabled: _vm.isLoading },
                                        on: { click: _vm.menuNewWorkflow }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-file font-lite mr-2"
                                        }),
                                        _vm._v("New")
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          href: "#",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuLoadWorkflow($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-file-import font-lite mr-2"
                                        }),
                                        _vm._v("Load")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          href: "#",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuSaveWorkflow($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-save font-lite mr-2"
                                        }),
                                        _vm._v("Save")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          href: "#",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuSaveAsWorkflow(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-save font-lite mr-2"
                                        }),
                                        _vm._v("Save As")
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "Edit"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          href: "#",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuUndo($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-undo font-lite mr-2"
                                          }),
                                          _vm._v("Undo")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-sml text-muted"
                                          },
                                          [_vm._v("Ctrl+Z")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          href: "#",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuRedo($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-redo font-lite mr-2"
                                          }),
                                          _vm._v("Redo")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-sml text-muted"
                                          },
                                          [_vm._v("Ctrl+Y")]
                                        )
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { disabled: _vm.isLoading },
                                        on: { click: _vm.doCut }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-cut font-lite mr-2"
                                          }),
                                          _vm._v("Cut")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-sml text-muted"
                                          },
                                          [_vm._v("Ctrl+X")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { disabled: _vm.isLoading },
                                        on: { click: _vm.doCopy }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-copy font-lite mr-2"
                                          }),
                                          _vm._v("Copy")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-sml text-muted"
                                          },
                                          [_vm._v("Ctrl+C")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { disabled: _vm.isLoading },
                                        on: { click: _vm.doPaste }
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-paste font-lite mr-2"
                                          }),
                                          _vm._v("Paste")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "float-right text-sml text-muted"
                                          },
                                          [_vm._v("Ctrl+V")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "Workflow"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          disabled: _vm.isLoading,
                                          href: "#"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuMoveOrigin($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-map-marker font-lite mr-2"
                                        }),
                                        _vm._v("Move to Origin")
                                      ]
                                    ),
                                    _vm.id
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              disabled: _vm.isLoading,
                                              href: "#"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.menuWorkflowDetail(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-list font-lite mr-2"
                                            }),
                                            _vm._v("Workflow Detail")
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          disabled: _vm.isLoading,
                                          href: "#"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuMetaData($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-database font-lite mr-2"
                                        }),
                                        _vm._v("Workflow Metadata")
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuDesignerSettings(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-cog font-lite mr-2 text-secondary"
                                        }),
                                        _vm._v("Designer Settings")
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          disabled: _vm.isLoading,
                                          href: "#"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuClearCurrentDiagram(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-eraser font-lite mr-2"
                                        }),
                                        _vm._v("Clear")
                                      ]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          disabled: _vm.isLoading,
                                          href: "#"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.menuValidate($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-check font-lite mr-2"
                                        }),
                                        _vm._v("Validate Workflow")
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item-dropdown",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      text: "Nodes"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.addNode("workflowStart")
                                          }
                                        }
                                      },
                                      [_vm._v("Workflow Start")]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-header",
                                      { attrs: { id: "header1" } },
                                      [_vm._v("Automation")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.mapping()
                                          }
                                        }
                                      },
                                      [_vm._v("Mapping")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.workflow()
                                          }
                                        }
                                      },
                                      [_vm._v("Workflow")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.bacsAutomation()
                                          }
                                        }
                                      },
                                      [_vm._v("Bacs Automation")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.bacsBureauAutomation()
                                          }
                                        }
                                      },
                                      [_vm._v("Bacs Bureau Automation")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.collectionsAutomation()
                                          }
                                        }
                                      },
                                      [_vm._v("Collections Automation")]
                                    ),
                                    _c("b-dropdown-divider"),
                                    _c(
                                      "b-dropdown-header",
                                      { attrs: { id: "header1" } },
                                      [_vm._v("Workflow Nodes")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.fileOperationsPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("File Operations")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.fileTransferPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("File Transfer")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.messagingPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("Messaging")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.encryptionPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("Encryption")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.compressionPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("Compression")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.loggingPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("Logging & Audits")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.flowPopup()
                                          }
                                        }
                                      },
                                      [_vm._v("Flow")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Undo",
                                        expression: "'Undo'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuUndo($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-undo text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Redo",
                                        expression: "'Redo'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuRedo($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-redo text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Validate Workflow",
                                        expression: "'Validate Workflow'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.isLoading,
                                      id: "validate"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuValidate($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-vial text-secondary",
                                      class: {
                                        // 'text-danger': validationlevel === 'Invalid',
                                        // 'text-warning': validationlevel === 'Warning',
                                        // 'text-info': validationlevel === 'Info',
                                        // 'text-success': validationlevel === 'Valid'
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Execute the Workflow",
                                        expression: "'Execute the Workflow'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuExecuteWorkflow($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-play text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Remove Selected",
                                        expression: "'Remove Selected'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.isLoading,
                                      id: "remove"
                                    },
                                    on: { click: _vm.menuRemoveItem }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times text-secondary"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.hideUI && _vm.isLicenced
                  ? _c("canvas", {
                      ref: "imagecanvas",
                      staticStyle: {
                        "background-color": "#fff",
                        display: "block"
                      },
                      attrs: {
                        id: "image-canvas",
                        width: _vm.canvasWidth,
                        height: _vm.canvasHeight
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._m(1)
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "alert-heading" }, [
      _c("i", { staticClass: "fas fa-exclamation-triangle mr-2" }),
      _vm._v("Internet Explorer 11 Detected")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-footer" }, [_c("div", {})])
  }
]
render._withStripped = true

export { render, staticRenderFns }