import roles from '@/Assets/Constants/roles'

const CreateBacsSubFromFile = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/BacsPayments/CreateBacsSubFromFile.vue')
const CreateBacsSubUsingEditor = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/BacsPayments/CreateBacsSubUsingEditor.vue')
const CreateFasterPaymentSubFromFile = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/FasterPayments/CreateFasterPaymentSubFromFile.vue')
const CreateFasterPaymentSubUsingEditor = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/FasterPayments/CreateFasterPaymentSubUsingEditor.vue')
const SubmissionsInProgress = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/BacsPayments/SubmissionsInProgress.vue')

const ImportingPayments = () => import(/* webpackChunkName: "bacs-payments" */ '@/Views/Bacs/BacsPayments/ImportingPayments.vue')
const ViewImportedPayments = () => import(/* webpackChunkName: "bacs-payments" */ '@/Views/Bacs/BacsPayments/ViewImportedPayments.vue')

const PresubValidationResult = () => import(/* webpackChunkName: "bacs-presubval" */ '@/Views/Bacs/BacsPayments/PreSubValidationResult')
const PresubValidationMessages = () => import(/* webpackChunkName: "bacs-presubval" */ '@/Views/Bacs/BacsPayments/PreSubValidationMessages')
const PresubValidationAllMessages = () => import(/* webpackChunkName: "bacs-presubval" */ '@/Views/Bacs/BacsPayments/PreSubValidationAllMessages')

const ActionWrapper = () => import(/* webpackChunkName: "bacs-action" */ '@/Views/Bacs/BacsActions/ActionWrapper.vue')

const DownloadReports = () => import(/* webpackChunkName: "bacs-reports" */ '@/Views/Bacs/BacsReports/DownloadReports')
const ViewReport = () => import(/* webpackChunkName: "bacs-reports" */ '@/Views/Bacs/BacsReports/ViewReport')

const SearchSubmissions = () => import(/* webpackChunkName: "bacs-search" */ '@/Views/Bacs/Search/SearchSubmissions')
const SearchPayments = () => import(/* webpackChunkName: "bacs-search" */ '@/Views/Bacs/Search/SearchPayments')
const SearchSentSubmissions = () => import(/* webpackChunkName: "bacs-search" */ '@/Views/Bacs/Search/SearchSentSubmissions')

const SubmissionWrapper = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/Search/SubmissionWrapper')
const ViewSubmissionSummary = () => import(/* webpackChunkName: "bacs-submission" */ '@/Views/Bacs/Common/ViewSubmissionSummary')

const BacsConfig = () => import(/* webpackChunkName: "bacs-config" */ '@/Views/Bacs/BacsAdmin/BacsConfig')
const UnlockSubmissions = () => import(/* webpackChunkName: "bacs-config" */ '@/Views/Bacs/BacsAdmin/UnlockSubmissions')

export default [
  {
    path: '/payments/bacspayments/createBacsSubFromFile',
    component: CreateBacsSubFromFile,
    meta: {
      title: 'Create BACS Submission From File'
    }
  },
  {
    path: '/payments/bacspayments/createBacsSubUsingEditor',
    component: CreateBacsSubUsingEditor,
    meta: {
      title: 'Create BACS Submission Using Editor'
    }
  },
  {
    path: '/payments/fasterpayments/createFasterPaymentSubFromFile',
    component: CreateFasterPaymentSubFromFile,
    meta: {
      title: 'Create Faster Payment Submission From File'
    }
  },
  {
    path: '/payments/fasterpayments/createFasterPaymentSubUsingEditor',
    component: CreateFasterPaymentSubUsingEditor,
    meta: {
      title: 'Create Faster Payment Submission Using Editor'
    }
  },
  {
    path: '/payments/bacspayments/submissionsInProgress',
    component: SubmissionsInProgress,
    meta: {
      title: 'Submissions In Progress'
    }
  },
  {
    path: '/payments/bacspayments/importingPayments',
    component: ImportingPayments,
    meta: {
      title: 'Importing Payments'
    }
  },
  {
    path: '/payments/bacspayments/viewImportedPayments',
    component: ViewImportedPayments,
    meta: {
      title: 'Imported Payments'
    }
  },
  {
    path: '/payments/bacspayments/presubValidationResult',
    component: PresubValidationResult,
    meta: {
      title: 'Pre-sub Validation Result'
    }
  },
  {
    path: '/payments/bacspayments/presubValidationMessages',
    component: PresubValidationMessages,
    meta: {
      title: 'Pre-sub Validation Messages'
    }
  },
  {
    path: '/payments/bacspayments/presubValidationAllMessages',
    component: PresubValidationAllMessages,
    meta: {
      title: 'Pre-sub Validation All Messages'
    }
  },
  {
    path: '/payments/bacsActions/actionWrapper',
    component: ActionWrapper,
    meta: {
      title: 'BACS Action'
    },
    props: route => ({ ...route.params, ...route.query })
  },
  {
    path: '/payments/common/viewSubmissionSummary',
    component: ViewSubmissionSummary,
    meta: {
      title: 'View Submission Summary'
    },
    props: route => ({ ...route.params, ...route.query })
  },
  {
    path: '/payments/bacsReports/downloadReports',
    component: DownloadReports,
    meta: {
      title: 'Download BACS Reports'
    }
  },
  {
    path: '/payments/fpsReports/downloadReports',
    component: DownloadReports,
    meta: {
      title: 'Download FPS Reports'
    }
  },
  {
    path: '/payments/bacsReports/viewReport',
    component: ViewReport,
    meta: {
      title: 'View Report'
    },
    props: route => ({ ...route.params, ...route.query })
  },
  {
    path: '/payments/search/submissionWrapper',
    component: SubmissionWrapper,
    meta: {
      title: 'View Submission'
    },
    props: route => ({ ...route.params, ...route.query })
  },
  {
    path: '/payments/search/searchSubmissions',
    component: SearchSubmissions,
    meta: {
      title: 'Search Submissions'
    }
  },
  {
    path: '/payments/search/searchPayments',
    component: SearchPayments,
    meta: {
      title: 'Search Payments'
    }
  },
  {
    path: '/payments/search/searchSentSubmissions',
    component: SearchSentSubmissions,
    meta: {
      title: 'Search Sent Submissions',
      auth: roles.SystemUser
    }
  },
  {
    path: '/payments/bacsAdmin/bacsConfig',
    component: BacsConfig,
    meta: {
      title: 'BACS Config',
      auth: roles.SystemUser
    }
  },
  {
    path: '/payments/bacsAdmin/unlockSubmissions',
    component: UnlockSubmissions,
    meta: {
      title: 'Unlock Submissions',
      auth: roles.SystemUser
    }
  }
]
