import axios from 'axios'

const state = {
  submissionData: {},
  processingDates: {},
  nextProcessingDate: '',
  nextPaymentDate: '',
  importFileResponse: {},
  payment: {},
  presubValResponse: {},
  submissionId: '',
  actionId: '',
  fileNumber: 0,
  bacsSubmission: {},
  intelligibleSummaries: {},
  contraNarratives: {},
  viewSubmissionData: {},
  payments: {},
  serverParams: {},
  standard18: '',
  presubValMessages: {},
  submissionHistory: {},
  reportDownload: {},
  parentDataLoaded: false,
  bacsEngineConfig: {},
  searchParams: {},
  searchResults: [],
  currentSearchResultsPage: 1,
  searchRowsPerPage: 10,
  webExtPluginResponse: '',
  downloadLinkText: '',
  actionLoadedFromPath: '',
  browserBackButtonClicked: false,
  selectedRowGroupName: '',
  initialSortBy: {},
  bureauSummaryData: {}
}

const mutations = {
  setBureauSummaryData (state, bureauSummaryData) {
    state.bureauSummaryData = bureauSummaryData
  },

  setSubmissionData (state, submissionData) {
    state.submissionData = submissionData
  },

  setProcessingDates (state, processingDates) {
    state.processingDates = processingDates
  },

  setNextProcessingDate (state, nextProcessingDate) {
    state.nextProcessingDate = nextProcessingDate
  },

  setNextPaymentDate (state, nextPaymentDate) {
    state.nextPaymentDate = nextPaymentDate
  },

  setImportFileResponse (state, importFileResponse) {
    state.importFileResponse = importFileResponse
  },

  setPayment (state, payment) {
    state.payment = payment
  },

  setPresubValResponse (state, presubValResponse) {
    state.presubValResponse = presubValResponse
  },

  setSubmissionId (state, submissionId) {
    state.submissionId = submissionId
  },

  setFileNumber (state, fileNumber) {
    state.fileNumber = fileNumber
  },

  setActionId (state, actionId) {
    state.actionId = actionId
  },

  setBacsSubmission (state, bacsSubmission) {
    state.bacsSubmission = bacsSubmission
  },

  setIntelligibleSummaries (state, intelligibleSummaries) {
    state.intelligibleSummaries = intelligibleSummaries
  },

  setContraNarratives (state, contraNarratives) {
    state.contraNarratives = contraNarratives
  },

  setViewSubmissionData (state, viewSubmissionData) {
    state.viewSubmissionData = viewSubmissionData
  },

  setPayments (state, payments) {
    state.payments = payments
  },

  setServerParams (state, serverParams) {
    state.serverParams = serverParams
  },

  setStandard18 (state, standard18) {
    state.standard18 = standard18
  },

  setPresubValMessages (state, presubValMessages) {
    state.presubValMessages = presubValMessages
  },

  setSubmissionHistory (state, submissionHistory) {
    state.submissionHistory = submissionHistory
  },

  setReportDownload (state, reportDownload) {
    state.reportDownload = reportDownload
  },

  setParentDataLoaded (state, parentDataLoaded) {
    state.parentDataLoaded = parentDataLoaded
  },

  setBacsEngineConfig (state, bacsEngineConfig) {
    state.bacsEngineConfig = bacsEngineConfig
  },

  setSearchParams (state, searchParams) {
    state.searchParams = searchParams
  },

  setSearchResults (state, searchResults) {
    state.searchResults = searchResults
  },

  setCurrentSearchResultsPage (state, currentSearchResultsPage) {
    state.currentSearchResultsPage = currentSearchResultsPage
  },

  setSearchRowsPerPage (state, searchRowsPerPage) {
    state.searchRowsPerPage = searchRowsPerPage
  },

  setWebExtPluginResponse (state, webExtPluginResponse) {
    state.webExtPluginResponse = webExtPluginResponse
  },

  setDownloadLinkText (state, downloadLinkText) {
    state.downloadLinkText = downloadLinkText
  },

  setActionLoadedFromPath (state, actionLoadedFromPath) {
    state.actionLoadedFromPath = actionLoadedFromPath
  },

  setBrowserBackButtonClicked (state, browserBackButtonClicked) {
    state.browserBackButtonClicked = browserBackButtonClicked
  },

  setSelectedRowGroupName (state, selectedRowGroupName) {
    state.selectedRowGroupName = selectedRowGroupName
  },

  setInitialSortBy (state, initialSortBy) {
    state.initialSortBy = initialSortBy
  }
}

const getters = {
  submissionData: state => {
    return state.submissionData
  },

  processingDates: state => {
    return state.processingDates
  },

  nextProcessingDate: state => {
    return state.nextProcessingDate
  },

  nextPaymentDate: state => {
    return state.nextPaymentDate
  },

  importFileResponse: state => {
    return state.importFileResponse
  },

  payment: state => {
    return state.payment
  },

  presubValResponse: state => {
    return state.presubValResponse
  },

  submissionId: state => {
    return state.submissionId
  },

  fileNumber: state => {
    return state.fileNumber
  },

  actionId: state => {
    return state.actionId
  },

  bacsSubmission: state => {
    return state.bacsSubmission
  },

  intelligibleSummaries: state => {
    return state.intelligibleSummaries
  },

  contraNarratives: state => {
    return state.contraNarratives
  },

  viewSubmissionData: state => {
    return state.viewSubmissionData
  },

  bureauSummaryData: state => {
    return state.bureauSummaryData
  },

  payments: state => {
    return state.payments
  },

  serverParams: state => {
    return state.serverParams
  },

  standard18: state => {
    return state.standard18
  },

  presubValMessages: state => {
    return state.presubValMessages
  },

  submissionHistory: state => {
    return state.submissionHistory
  },

  reportDownload: state => {
    return state.reportDownload
  },

  parentDataLoaded: state => {
    return state.parentDataLoaded
  },

  bacsEngineConfig: state => {
    return state.bacsEngineConfig
  },

  searchParams: state => {
    return state.searchParams
  },

  searchResults: state => {
    return state.searchResults
  },

  currentSearchResultsPage: state => {
    return state.currentSearchResultsPage
  },

  searchRowsPerPage: state => {
    return state.searchRowsPerPage
  },

  getWebExtPluginResponse: state => {
    return state.webExtPluginResponse
  },

  getDownloadLinkText: state => {
    return state.downloadLinkText
  },

  actionLoadedFromPath: state => {
    return state.actionLoadedFromPath
  },

  browserBackButtonClicked: state => {
    return state.browserBackButtonClicked
  },

  selectedRowGroupName: state => {
    return state.selectedRowGroupName
  },

  initialSortBy: state => {
    return state.initialSortBy
  }
}

const actions = {
  setBureauSummaryData ({ commit }, bureauSummaryData) {
    commit('setBureauSummaryData', bureauSummaryData)
  },

  setSubmissionData ({ commit }, submissionData) {
    commit('setSubmissionData', submissionData)
  },

  async getProcessingDates ({ commit, dispatch }) {
    var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate`)
    commit('setProcessingDates', response.data)
  },

  async getNextProcessingDate ({ commit, payload }, paymentDate) {
    var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + paymentDate)
    commit('setNextProcessingDate', response.data)
  },

  async getNextPaymentDate ({ commit, payload }, processingDate) {
    var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getPaymentDateFromProcessingDate?processingDate=` + processingDate)
    commit('setNextPaymentDate', response.data)
  },

  setImportFileResponse ({ commit }, importFileResponse) {
    commit('setImportFileResponse', importFileResponse)
  },

  setPayment ({ commit }, payment) {
    commit('setPayment', payment)
  },

  setPresubValResponse ({ commit }, presubValResponse) {
    commit('setPresubValResponse', presubValResponse)
  },

  setSubmissionId ({ commit }, submissionId) {
    commit('setSubmissionId', submissionId)
  },

  setFileNumber ({ commit }, fileNumber) {
    commit('setFileNumber', fileNumber)
  },

  setActionId ({ commit }, actionId) {
    commit('setActionId', actionId)
  },

  setBacsSubmission ({ commit }, bacsSubmission) {
    commit('setBacsSubmission', bacsSubmission)
  },

  setIntelligibleSummaries ({ commit }, intelligibleSummaries) {
    commit('setIntelligibleSummaries', intelligibleSummaries)
  },

  setContraNarratives ({ commit }, contraNarratives) {
    commit('setContraNarratives', contraNarratives)
  },

  setViewSubmissionData ({ commit }, viewSubmissionData) {
    commit('setViewSubmissionData', viewSubmissionData)
  },

  setPayments ({ commit }, payments) {
    commit('setPayments', payments)
  },

  setServerParams ({ commit }, serverParams) {
    commit('setServerParams', serverParams)
  },

  setStandard18 ({ commit }, standard18) {
    commit('setStandard18', standard18)
  },

  setPresubValMessages ({ commit }, presubValMessages) {
    commit('setPresubValMessages', presubValMessages)
  },

  setSubmissionHistory ({ commit }, submissionHistory) {
    commit('setSubmissionHistory', submissionHistory)
  },

  setReportDownload ({ commit }, reportDownload) {
    commit('setReportDownload', reportDownload)
  },

  setParentDataLoaded ({ commit }, parentDataLoaded) {
    commit('setParentDataLoaded', parentDataLoaded)
  },

  async getBacsEngineConfig ({ commit }, paymentNetworkType) {
    var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getBacsEngineConfig?networkType=` + paymentNetworkType)
    commit('setBacsEngineConfig', response.data)
  },

  setSearchParams ({ commit, dispatch }, searchParams) {
    commit('setSearchParams', searchParams)
  },

  setSearchResults ({ commit, dispatch }, searchResults) {
    commit('setSearchResults', searchResults)
  },

  setCurrentSearchResultsPage ({ commit, dispatch }, currentSearchResultsPage) {
    commit('setCurrentSearchResultsPage', currentSearchResultsPage)
  },

  setSearchRowsPerPage ({ commit, dispatch }, searchRowsPerPage) {
    commit('setSearchRowsPerPage', searchRowsPerPage)
  },

  setWebExtPluginResponse ({ commit, dispatch }, webExtPluginResponse) {
    commit('setWebExtPluginResponse', webExtPluginResponse)
  },

  setDownloadLinkText ({ commit, dispatch }, downloadLinkText) {
    commit('setDownloadLinkText', downloadLinkText)
  },

  setActionLoadedFromPath ({ commit, dispatch }, actionLoadedFromPath) {
    commit('setActionLoadedFromPath', actionLoadedFromPath)
  },

  setBrowserBackButtonClicked ({ commit, dispatch }, browserBackButtonClicked) {
    commit('setBrowserBackButtonClicked', browserBackButtonClicked)
  },

  setSelectedRowGroupName ({ commit, dispatch }, selectedRowGroupName) {
    commit('setSelectedRowGroupName', selectedRowGroupName)
  },

  setInitialSortBy ({ commit, dispatch }, initialSortBy) {
    commit('setInitialSortBy', initialSortBy)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
