var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "presubValResults" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c("h2", [
              _vm._v(" Pre-Submission Validation Summary "),
              _c("span", { staticClass: "pull-right" }, [
                _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
                  _c("i", { staticClass: "far fa-question-circle" })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group" }, [
              this.presubValResponse.totalPresubValMessages > 0
                ? _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "vue-good-table",
                        {
                          attrs: {
                            mode: "remote",
                            columns: _vm.columns,
                            rows: _vm.rows,
                            "sort-options": { enabled: false },
                            styleClass: "vgt-table striped bordered"
                          },
                          on: { "on-row-click": _vm.onRowClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "table-row",
                                fn: function(props) {
                                  return [
                                    props.column.field === "messageSeverity"
                                      ? _c("span", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-pill pull-left",
                                              class: _vm.getSeverityClass(
                                                props.row.messageSeverity
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.row.messageSeverity
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : props.column.field === "hyperlink"
                                      ? _c("span", [
                                          _c(
                                            "span",
                                            { staticClass: "viewLink" },
                                            [_vm._v("View Items")]
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            )
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4169033856
                          )
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown",
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Download pre-sub val messages",
                                      expression:
                                        "'Download pre-sub val messages'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  staticClass:
                                    "btn btn btn-link dropdown-toggle",
                                  attrs: {
                                    href: "#",
                                    role: "button",
                                    id: "dropdownMenuLink",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-file-alt pointer dimmedIcon pointer"
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: {
                                    "aria-labelledby": "dropdownMenuLink"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createPresubPdfReport(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-file-csv faIconGray"
                                      }),
                                      _vm._v(
                                        " Create Pre-sub validation messages PDF report"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createPresubPaymentsPdfReport(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-file-alt faIconGray"
                                      }),
                                      _vm._v(
                                        " Create Pre-sub payments PDF report"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createPresubCsvReport(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-file-csv faIconGray"
                                      }),
                                      _vm._v(
                                        " Create Pre-sub validation CSV file"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createPresubPaymentsCsvReport(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-file-alt faIconGray"
                                      }),
                                      _vm._v(
                                        " Create Pre-sub payments CSV file"
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "row form-group" }, [
              this.presubValResponse.presubValSummaries !== undefined &&
              this.presubValResponse.presubValSummaries.length > 1
                ? _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary pull-right",
                        attrs: { id: "viewAll", type: "button" },
                        on: { click: _vm.viewAll }
                      },
                      [_vm._v("View All Messages")]
                    )
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "row form-group" }, [
              this.presubValResponse.totalPresubValMessages === 0
                ? _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "text-center",
                          attrs: {
                            "bg-variant": "success",
                            "text-variant": "white"
                          }
                        },
                        [
                          _c("b-card-text", [
                            _vm._v(
                              "Pre-submission validation completed without issue."
                            )
                          ])
                        ],
                        1
                      ),
                      _c("br")
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            this.presubValResponse.validationResult !== "Fixes" &&
            this.presubValResponse.validationResult !== "Aborted"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      id: "save",
                      type: "button",
                      disabled: this.saveStarted === true
                    },
                    on: { click: _vm.startSave }
                  },
                  [_vm._v("Process Submission")]
                )
              : _vm._e(),
            this.presubValResponse.validationResult !== "Valid"
              ? _c(
                  "button",
                  {
                    class: _vm.getButtonClass(),
                    attrs: {
                      id: "edit",
                      type: "button",
                      disabled: this.saveStarted === true
                    },
                    on: { click: _vm.editPayments }
                  },
                  [_vm._v("Edit Payments")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary ml-3",
                attrs: { disabled: this.saveStarted === true },
                on: { click: _vm.viewSubmissionSummary }
              },
              [_vm._v("Submission Summary")]
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.saveStarted,
                  expression: "saveStarted"
                }
              ],
              staticClass: "row form-group"
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saveStarted,
                    expression: "saveStarted"
                  }
                ],
                staticClass: "row form-group"
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-progress",
                      {
                        staticClass: "mb-3",
                        attrs: {
                          striped: "",
                          animated: true,
                          max: _vm.max,
                          "show-progress": ""
                        }
                      },
                      [
                        _c("b-progress-bar", {
                          attrs: {
                            value: _vm.progress,
                            label: _vm.progress + "%"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }