var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "bureauSummary" } }, [
    _vm._m(0),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(1),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.totalCreditCount)))
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _vm._v(
          "£" + _vm._s(_vm.formatValue(_vm.BureauSummaryData.totalCreditValue))
        )
      ]),
      _c("div", { staticClass: "col-md-1" })
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(2),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.totalDebitCount)))
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _vm._v(
          "£" + _vm._s(_vm.formatValue(_vm.BureauSummaryData.totalDebitValue))
        )
      ]),
      _c("div", { staticClass: "col-md-1" })
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(3),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.totalDDICount)))
      ]),
      _c("div", { staticClass: "col-md-3" })
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(4),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.contraCount)))
      ]),
      _c("div", { staticClass: "col-md-3" })
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _vm._m(5),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.totalFiles)))
      ]),
      _c("div", { staticClass: "col-md-3" })
    ]),
    _c("div", { staticClass: "row form-group" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row form-group" }, [
      _c("div", { staticClass: "col-md-2" }),
      _c("div", { staticClass: "col-md-1 alertValueRight" }, [
        _c("strong", [_vm._v("Items")])
      ]),
      _c("div", { staticClass: "col-md-2 alertValueRight" }, [
        _c("strong", [_vm._v("Total")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Credits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Debits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("DDIs")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Contras")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Total Files")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }