var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [_vm._v(" " + _vm._s(_vm.status) + " News Story "), _c("help-icon")],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.newsStory.title.$error }
          },
          [
            _c("label", { staticClass: "label-control col-md-3 required" }, [
              _vm._v("Title")
            ]),
            _c(
              "div",
              { staticClass: "col-md-9" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.newsStory.title.$model,
                      expression: "$v.newsStory.title.$model"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.newsStory.title.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.newsStory.title,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "title" },
                  model: {
                    value: _vm.$v.newsStory.title,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.newsStory, "title", $$v)
                    },
                    expression: "$v.newsStory.title"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.newsStory.intro.$error }
          },
          [
            _c("label", { staticClass: "label-control col-md-3 required" }, [
              _vm._v("Intro")
            ]),
            _c(
              "div",
              { staticClass: "col-md-9" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.newsStory.intro.$model,
                      expression: "$v.newsStory.intro.$model"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.newsStory.intro.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.newsStory.intro,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "intro" },
                  model: {
                    value: _vm.$v.newsStory.intro,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.newsStory, "intro", $$v)
                    },
                    expression: "$v.newsStory.intro"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.newsStory.imageUrl.$error }
          },
          [
            _c("label", { staticClass: "label-control col-md-3 required" }, [
              _vm._v("Image URL")
            ]),
            _c(
              "div",
              { staticClass: "col-md-9" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.newsStory.imageUrl.$model,
                      expression: "$v.newsStory.imageUrl.$model"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", title: "Image" },
                  domProps: { value: _vm.$v.newsStory.imageUrl.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.newsStory.imageUrl,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "image URL" },
                  model: {
                    value: _vm.$v.newsStory.imageUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.newsStory, "imageUrl", $$v)
                    },
                    expression: "$v.newsStory.imageUrl"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "label-control col-md-3" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value:
                      "Determines if the story is enabled or disabled. Disabled stories will not be shown to users.",
                    expression:
                      "'Determines if the story is enabled or disabled. Disabled stories will not be shown to users.'",
                    modifiers: { hover: true, top: true }
                  }
                ]
              },
              [_vm._v("Enabled")]
            )
          ]),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("p-check", {
                staticClass: "p-switch",
                attrs: { name: "check", color: "primary " },
                model: {
                  value: _vm.newsStory.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.newsStory, "enabled", $$v)
                  },
                  expression: "newsStory.enabled"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "label-control col-md-3" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value:
                      "Determines if the story is sticky.  Sticky stories always appear at the top of the story list giving it a prominent position.",
                    expression:
                      "'Determines if the story is sticky.  Sticky stories always appear at the top of the story list giving it a prominent position.'",
                    modifiers: { hover: true, top: true }
                  }
                ]
              },
              [_vm._v("Sticky")]
            )
          ]),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("p-check", {
                staticClass: "p-switch",
                attrs: { name: "check", color: "primary " },
                model: {
                  value: _vm.newsStory.sticky,
                  callback: function($$v) {
                    _vm.$set(_vm.newsStory, "sticky", $$v)
                  },
                  expression: "newsStory.sticky"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.newsStory.order.$error }
          },
          [
            _c("label", { staticClass: "label-control col-md-3" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top",
                      value:
                        "Determines the order, sticky will overwite this and always be at the top, if there are conflicting orders then created date will be used",
                      expression:
                        "'Determines the order, sticky will overwite this and always be at the top, if there are conflicting orders then created date will be used'",
                      modifiers: { hover: true, top: true }
                    }
                  ]
                },
                [_vm._v("Order")]
              )
            ]),
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.newsStory.order.$model,
                      expression: "$v.newsStory.order.$model"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number", min: "1" },
                  domProps: { value: _vm.$v.newsStory.order.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.newsStory.order,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "order number" },
                  model: {
                    value: _vm.$v.newsStory.order,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.newsStory, "order", $$v)
                    },
                    expression: "$v.newsStory.order"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.newsStory.body.$error }
          },
          [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("div", { ref: "editor" }),
                _c("validation-messages", {
                  attrs: { name: "body" },
                  model: {
                    value: _vm.$v.newsStory.body,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.newsStory, "body", $$v)
                    },
                    expression: "$v.newsStory.body"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("br")
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit", disabled: _vm.isLoading },
            on: {
              click: function($event) {
                _vm.checkValidation() && _vm.saveStory()
              }
            }
          },
          [_vm._v(_vm._s(_vm.status === "Edit" ? "Save" : _vm.status))]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary-outline pull-right ml-3",
            attrs: { type: "button", disabled: _vm.isLoading },
            on: { click: _vm.back }
          },
          [_vm._v("Cancel")]
        ),
        _vm.status === "Edit"
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger pull-right",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.deleteStory }
              },
              [_vm._v("Delete")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }