var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "importedPayments" } }, [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Imported Files Summary "),
            _c("help-icon"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-md-12" }, [
        _c("span", [
          _c("b", [_vm._v("Submission Reference:")]),
          _vm._v(" " + _vm._s(_vm.submissionReference) + " ")
        ])
      ]),
      _c("div", { staticClass: "col-md-12" }, [
        _c("span", [
          _c("b", [_vm._v("Bureau Job Template:")]),
          _vm._v(" " + _vm._s(_vm.bureauJobName) + " ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c("vue-good-table", {
            ref: "table",
            attrs: {
              paginationOptions: _vm.paginationOptions,
              "sort-options": _vm.sortOptions,
              rows: _vm.rows,
              columns: _vm.columns,
              searchOptions: { enabled: true },
              mode: "remote",
              totalRows: _vm.totalRecords,
              isLoading: _vm.isTableLoading,
              styleClass: "vgt-table striped bordered"
            },
            on: {
              "on-page-change": _vm.onPageChange,
              "on-per-page-change": _vm.onPerPageChange,
              "on-search": _vm.onSearch,
              "on-sort-change": _vm.onSortChange,
              "update:isLoading": function($event) {
                _vm.isTableLoading = $event
              },
              "update:is-loading": function($event) {
                _vm.isTableLoading = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field === "filename"
                      ? _c("span", [
                          props.row.bureauFileImportStatus === "Error"
                            ? _c("div", {
                                staticClass: "group-colour",
                                style: { "background-color": "red" }
                              })
                            : _c("div", {
                                staticClass: "group-colour",
                                style: { "background-color": "green" }
                              }),
                          _vm._v(" " + _vm._s(props.row.filename) + " ")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "idb-block-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary ml-2",
          attrs: { disabled: _vm.isTableLoading },
          on: {
            click: function($event) {
              return _vm.onViewPaymentsClick()
            }
          }
        },
        [_vm._v("View Payments")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }