<template>
  <div style="height:100%">
    <div class="d-flex justify-content-between mb-20">
      <h4 v-if="actionsCount === 0">You have no actions</h4>
      <h4
        class="m-0"
        v-else
      >You have a total of {{actionsCount}} action{{actionsCount > 1 ? 's' :''}}</h4>
    </div>
    <vue-perfect-scrollbar class="scroll-area" :settings="settings">
      <div class="idb-full-block">
        <div class="table-responsive">
          <table class="table table-middle">
            <tbody
              class="clickable"
              @click="goToAction(action)"
              v-for="action in actions"
              :key="action.id"
            >
              <tr>
                <td style="width: 85px;">
                  <div class="pos-relative actions-avatar-image">
                    <img
                      :src="`${platformApi}User/GetAvatar/${action.createdBy}?access_token=${getAccessToken}`"
                      class="rounded-circle artical-user"
                      width="64"
                      height="64"
                    />
                    <span
                      class="actions-widget-badge badge badge-label-sm badge-pill fa-notify"
                      :class="badgeColour(action)"
                    >&nbsp;</span>
                  </div>
                </td>
                <td>
                  <div v-if="action.rejected">
                    <h4>
                      <span class="text-danger">Rejected:</span>
                      {{action.title.replace("Rejected:",'')}}
                    </h4>
                  </div>
                  <div v-else>
                    <h4>{{action.title}}</h4>
                  </div>

                  <h6>{{action.message}}</h6>
                  <p
                    class="fs-12 artical-author"
                    :class="dateColour(action)"
                  >{{action.createdDate | formatDate}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import Moment from 'moment'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper.js'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'ActionsWidget',
  components: {
    VuePerfectScrollbar
  },
  computed: {
    actions () {
      return this.$store.getters.actionListVariable(this.amountToGet)
    },
    isConnected () {
      return this.$store.state.actionsStore.socket.isConnected
    },
    amountToGet () {
      return this.widget.options.find(o => o.label === 'Amount of Actions to show').value
    },
    ...mapGetters(['getAccessToken', 'actionsCount'])
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      settings: {
        maxScrollbarLength: 60
      }
    }
  },
  methods: {
    dateColour ({ createdDate }) {
      createdDate = Moment(createdDate)
      const diff = createdDate.diff(Moment(), 'days')

      if (diff >= 0) {
        return 'text-success'
      } else if (diff > -7) {
        return 'text-warning'
      } else {
        return 'text-danger'
      }
    },

    badgeColour ({ createdDate }) {
      createdDate = Moment(createdDate)
      const diff = createdDate.diff(Moment(), 'days')

      if (diff >= 0) {
        return 'badge-success'
      } else if (diff > -7) {
        return 'badge-warning'
      } else {
        return 'badge-danger'
      }
    },
    goToAction (action) {
      this.$router.push(returnRouteObject(action))
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-widget-badge {
  padding: 10px !important;
  position: absolute;
  top: 0%;
  left: 0%;
}

.scroll-area {
  margin-top: 20px;
  height: calc(100% - 25px);
  min-height: unset;
}
.clickable {
  cursor: pointer;
}

.actions-avatar-image {
  padding-bottom: 25px;
}
</style>
