var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.saveLicence()
          }
        }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(0),
              _c("div", { staticClass: "idb-block-content" }, [
                _vm.areErrors
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: { id: "errors" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "The licence cannot be saved due to these errors, please fix the problems in this list by either changing the related entities or amending the licence"
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error.message }, [
                              _vm._v(_vm._s(error.message))
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-md-10" }, [
                  _c("div", { staticClass: "container-fluid" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "row top-buffer" }, [
                      _c("div", { staticClass: "col-md-2" }, [_vm._v("Users")]),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-3",
                          class: {
                            invalid: _vm.$v.licence.platform.users.$error
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.licence.platform.users.$model,
                                expression: "$v.licence.platform.users.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number" },
                            domProps: {
                              value: _vm.$v.licence.platform.users.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.licence.platform.users,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("validation-messages", {
                            attrs: { name: "limit" },
                            model: {
                              value: _vm.$v.licence.platform.users,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.licence.platform, "users", $$v)
                              },
                              expression: "$v.licence.platform.users"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.usersAmount,
                              expression: "usersAmount"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { readonly: "" },
                          domProps: { value: _vm.usersAmount },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.usersAmount = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.availableUsers,
                              expression: "availableUsers"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { readonly: "" },
                          domProps: { value: _vm.availableUsers },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.availableUsers = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "row top-buffer" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Groups")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-3",
                          class: {
                            invalid: _vm.$v.licence.platform.groups.$error
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.licence.platform.groups.$model,
                                expression: "$v.licence.platform.groups.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number" },
                            domProps: {
                              value: _vm.$v.licence.platform.groups.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.licence.platform.groups,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("validation-messages", {
                            attrs: { name: "limit" },
                            model: {
                              value: _vm.$v.licence.platform.groups,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.licence.platform, "groups", $$v)
                              },
                              expression: "$v.licence.platform.groups"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.groupsAmount,
                              expression: "groupsAmount"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { readonly: "" },
                          domProps: { value: _vm.groupsAmount },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.groupsAmount = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.availableGroups,
                              expression: "availableGroups"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { readonly: "" },
                          domProps: { value: _vm.availableGroups },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.availableGroups = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "row top-buffer" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Service Accounts")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-3",
                          class: {
                            invalid:
                              _vm.$v.licence.platform.serviceAccounts.$error
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.$v.licence.platform.serviceAccounts
                                    .$model,
                                expression:
                                  "$v.licence.platform.serviceAccounts.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number" },
                            domProps: {
                              value:
                                _vm.$v.licence.platform.serviceAccounts.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.licence.platform.serviceAccounts,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("validation-messages", {
                            attrs: { name: "limit" },
                            model: {
                              value: _vm.$v.licence.platform.serviceAccounts,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.licence.platform,
                                  "serviceAccounts",
                                  $$v
                                )
                              },
                              expression: "$v.licence.platform.serviceAccounts"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.serviceAccountAmount,
                              expression: "serviceAccountAmount"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { readonly: "" },
                          domProps: { value: _vm.serviceAccountAmount },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.serviceAccountAmount = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.availableServiceAccounts,
                              expression: "availableServiceAccounts"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { readonly: "" },
                          domProps: { value: _vm.availableServiceAccounts },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.availableServiceAccounts = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-lg-6" }, [
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(2),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.bacs.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("BACS")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.paymentValues },
                          model: {
                            value: _vm.$v.licence.bacs.value.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.bacs.value, "$model", $$v)
                            },
                            expression: "$v.licence.bacs.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "bacs" },
                          model: {
                            value: _vm.$v.licence.bacs.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.bacs, "value", $$v)
                            },
                            expression: "$v.licence.bacs.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.bacs.value.$model == "Both" ||
                _vm.$v.licence.bacs.value.$model == "Direct"
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Allow Bank Grade BACS")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: { color: "primary" },
                            model: {
                              value: _vm.$v.licence.bacs.bankGrade.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.licence.bacs.bankGrade,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.licence.bacs.bankGrade.$model"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.$v.licence.bacs.value.$model == "Both" ||
                _vm.$v.licence.bacs.value.$model == "Indirect"
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Allow Multi-Block BACS")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: { color: "primary" },
                            model: {
                              value: _vm.$v.licence.bacs.proxyGroup.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.licence.bacs.proxyGroup,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.licence.bacs.proxyGroup.$model"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.$v.licence.bacs.value.$model !== "Off"
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.licence.bacs.api.$error }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("BACS API")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value: _vm.$v.licence.bacs.api.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.bacs.api,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.bacs.api.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.fps.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Faster Payments Service (FPS)")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.paymentValues },
                          model: {
                            value: _vm.$v.licence.fps.value.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.fps.value, "$model", $$v)
                            },
                            expression: "$v.licence.fps.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "fps" },
                          model: {
                            value: _vm.$v.licence.fps.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.fps, "value", $$v)
                            },
                            expression: "$v.licence.fps.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.fps.value.$model == "Foo" ||
                _vm.$v.licence.fps.value.$model == "Bar"
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Allow Bank Grade FPS")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: { color: "primary" },
                            model: {
                              value: _vm.$v.licence.fps.bankGrade.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.licence.fps.bankGrade,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.licence.fps.bankGrade.$model"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.$v.licence.fps.value.$model == "Both" ||
                _vm.$v.licence.fps.value.$model == "Indirect"
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Allow Multi-Block FPS")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: { color: "primary" },
                            model: {
                              value: _vm.$v.licence.fps.proxyGroup.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.licence.fps.proxyGroup,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.licence.fps.proxyGroup.$model"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.$v.licence.fps.value.$model !== "Off"
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.licence.fps.api.$error }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("FPS API")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value: _vm.$v.licence.fps.api.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.fps.api,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.fps.api.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(3),
              _c("div", { staticClass: "idb-block-content" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col form-group",
                      class: { invalid: _vm.$v.licence.automation.value.$error }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("label", { staticClass: "col-md-6" }, [
                          _vm._v("Mapping")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: {
                                color: "primary",
                                readonly: "",
                                disabled: ""
                              },
                              model: {
                                value: _vm.$v.licence.automation.value.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.automation.value,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.automation.value.$model"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "mapping" },
                              model: {
                                value: _vm.$v.licence.automation.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.automation,
                                    "value",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.automation.value"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col form-group",
                      class: {
                        invalid:
                          _vm.$v.licence.automation.mappingDesigner.$error
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("label", { staticClass: "col-md-6" }, [
                          _vm._v("Mapping Designer")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value:
                                  _vm.$v.licence.automation.mappingDesigner
                                    .$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.automation.mappingDesigner,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.automation.mappingDesigner.$model"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "mapping designer" },
                              model: {
                                value:
                                  _vm.$v.licence.automation.mappingDesigner,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.automation,
                                    "mappingDesigner",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.automation.mappingDesigner"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: {
                      invalid: _vm.$v.licence.automation.folderManager.$error
                    }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Folder Manager")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value:
                              _vm.$v.licence.automation.folderManager.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.automation.folderManager,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.licence.automation.folderManager.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "folder manager" },
                          model: {
                            value: _vm.$v.licence.automation.folderManager,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.automation,
                                "folderManager",
                                $$v
                              )
                            },
                            expression: "$v.licence.automation.folderManager"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6 form-group",
                      class: {
                        invalid: _vm.$v.licence.automation.workflow.$error
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("label", { staticClass: "col-md-6" }, [
                          _vm._v("Workflow")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value:
                                  _vm.$v.licence.automation.workflow.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.automation.workflow,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.automation.workflow.$model"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "workflow" },
                              model: {
                                value: _vm.$v.licence.automation.workflow,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.automation,
                                    "workflow",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.automation.workflow"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm.$v.licence.automation.workflow.$model
                    ? _c(
                        "div",
                        {
                          staticClass: "col form-group",
                          class: {
                            invalid:
                              _vm.$v.licence.automation.workflowDesigner.$error
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("label", { staticClass: "col-md-6" }, [
                              _vm._v("Workflow Designer")
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch p-fill",
                                  attrs: { color: "primary" },
                                  model: {
                                    value:
                                      _vm.$v.licence.automation.workflowDesigner
                                        .$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.licence.automation
                                          .workflowDesigner,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.licence.automation.workflowDesigner.$model"
                                  }
                                }),
                                _c("validation-messages", {
                                  attrs: { name: "workflow designer" },
                                  model: {
                                    value:
                                      _vm.$v.licence.automation
                                        .workflowDesigner,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.licence.automation,
                                        "workflowDesigner",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.licence.automation.workflowDesigner"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]),
                _vm.$v.licence.automation.workflow.$model
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.automation.frequency.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Frequency")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.triggerFrequency },
                                model: {
                                  value:
                                    _vm.$v.licence.automation.frequency.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.automation.frequency,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.automation.frequency.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "frequency" },
                                model: {
                                  value: _vm.$v.licence.automation.frequency,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.automation,
                                      "frequency",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.automation.frequency"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid:
                              _vm.$v.licence.automation.liveWorkflows.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Live Workflows")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.$v.licence.automation.liveWorkflows
                                        .$model,
                                    expression:
                                      "$v.licence.automation.liveWorkflows.$model"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number" },
                                domProps: {
                                  value:
                                    _vm.$v.licence.automation.liveWorkflows
                                      .$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.licence.automation.liveWorkflows,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "Live Workflows" },
                                model: {
                                  value:
                                    _vm.$v.licence.automation.liveWorkflows,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.automation,
                                      "liveWorkflows",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.automation.liveWorkflows"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.automation.api.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Automation API")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.$v.licence.automation.api.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.automation.api,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.automation.api.$model"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(4),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.bureau.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.bureau.value.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.bureau.value,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.licence.bureau.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "bureau" },
                          model: {
                            value: _vm.$v.licence.bureau.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.bureau, "value", $$v)
                            },
                            expression: "$v.licence.bureau.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.bureau.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.bureau.threshold.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.licence.bureau.threshold.$model,
                                  expression:
                                    "$v.licence.bureau.threshold.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number" },
                              domProps: {
                                value: _vm.$v.licence.bureau.threshold.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.licence.bureau.threshold,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "This is the amount of unique SUN's per month"
                                )
                              ]
                            ),
                            _c("validation-messages", {
                              attrs: { name: "limit" },
                              model: {
                                value: _vm.$v.licence.bureau.threshold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.bureau,
                                    "threshold",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.bureau.threshold"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.licence.bureau.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.licence.bureau.api.$error }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Bureau API")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value: _vm.$v.licence.bureau.api.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.bureau.api,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.bureau.api.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [_vm._v("Other")]),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.other.sms.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("SMS Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.other.sms.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.other.sms, "$model", $$v)
                            },
                            expression: "$v.licence.other.sms.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "sms" },
                          model: {
                            value: _vm.$v.licence.other.sms,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.other, "sms", $$v)
                            },
                            expression: "$v.licence.other.sms"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.other.sms.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.other.smsThreshold.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("SMS Limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.$v.licence.other.smsThreshold.$model,
                                  expression:
                                    "$v.licence.other.smsThreshold.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number" },
                              domProps: {
                                value: _vm.$v.licence.other.smsThreshold.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.licence.other.smsThreshold,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [_vm._v("This is the amount of SMS's per month")]
                            ),
                            _c("validation-messages", {
                              attrs: { name: "limit" },
                              model: {
                                value: _vm.$v.licence.other.smsThreshold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.other,
                                    "smsThreshold",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.other.smsThreshold"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: {
                      invalid: _vm.$v.licence.other.emailThreshold.$error
                    }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Email Limit")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.licence.other.emailThreshold.$model,
                              expression:
                                "$v.licence.other.emailThreshold.$model"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.$v.licence.other.emailThreshold.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.licence.other.emailThreshold,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v("This is the amount of emails per month")
                        ]),
                        _c("validation-messages", {
                          attrs: { name: "limit" },
                          model: {
                            value: _vm.$v.licence.other.emailThreshold,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.other,
                                "emailThreshold",
                                $$v
                              )
                            },
                            expression: "$v.licence.other.emailThreshold"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.other.storage.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Secure Cloud Storage")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("div", { staticClass: "input-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.licence.other.storage.$model,
                                expression: "$v.licence.other.storage.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              readonly: "",
                              "aria-describedby": "storage-addon"
                            },
                            domProps: {
                              value: _vm.$v.licence.other.storage.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.licence.other.storage,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._m(5)
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.licence.other.storage.$model,
                              expression: "$v.licence.other.storage.$model"
                            }
                          ],
                          staticClass: "custom-range",
                          attrs: {
                            type: "range",
                            min: "1",
                            max: "10",
                            step: "1"
                          },
                          domProps: {
                            value: _vm.$v.licence.other.storage.$model
                          },
                          on: {
                            __r: function($event) {
                              return _vm.$set(
                                _vm.$v.licence.other.storage,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "sms" },
                          model: {
                            value: _vm.$v.licence.other.storage,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.other, "storage", $$v)
                            },
                            expression: "$v.licence.other.storage"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(6),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.mfa.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.mfa.value.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.mfa.value, "$model", $$v)
                            },
                            expression: "$v.licence.mfa.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "mfa" },
                          model: {
                            value: _vm.$v.licence.mfa.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.mfa, "value", $$v)
                            },
                            expression: "$v.licence.mfa.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.mfa.value.$model
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.mfa.authenticator.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Authenticator")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value:
                                    _vm.$v.licence.mfa.authenticator.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.mfa.authenticator,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.mfa.authenticator.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "authenticator" },
                                model: {
                                  value: _vm.$v.licence.mfa.authenticator,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.mfa,
                                      "authenticator",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.mfa.authenticator"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.mfa.usbTokens.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("USB Tokens")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.$v.licence.mfa.usbTokens.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.mfa.usbTokens,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.mfa.usbTokens.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "USB Tokens" },
                                model: {
                                  value: _vm.$v.licence.mfa.usbTokens,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.mfa,
                                      "usbTokens",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.mfa.usbTokens"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: { invalid: _vm.$v.licence.mfa.emails.$error }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Email")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.$v.licence.mfa.emails.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.mfa.emails,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.mfa.emails.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "email" },
                                model: {
                                  value: _vm.$v.licence.mfa.emails,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v.licence.mfa, "emails", $$v)
                                  },
                                  expression: "$v.licence.mfa.emails"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.$v.licence.other.sms.$model
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group row",
                              class: { invalid: _vm.$v.licence.mfa.sms.$error }
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "col-form-label col-md-3" },
                                [_vm._v("SMS")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("p-check", {
                                    staticClass: "p-switch p-fill",
                                    attrs: { color: "primary" },
                                    model: {
                                      value: _vm.$v.licence.mfa.sms.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.licence.mfa.sms,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.licence.mfa.sms.$model"
                                    }
                                  }),
                                  _c("validation-messages", {
                                    attrs: { name: "sms" },
                                    model: {
                                      value: _vm.$v.licence.mfa.sms,
                                      callback: function($$v) {
                                        _vm.$set(_vm.$v.licence.mfa, "sms", $$v)
                                      },
                                      expression: "$v.licence.mfa.sms"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-12 col-lg-6" }, [
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(7),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.validate.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.validate.value.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.validate.value,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.licence.validate.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "validate" },
                          model: {
                            value: _vm.$v.licence.validate.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.validate, "value", $$v)
                            },
                            expression: "$v.licence.validate.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.validate.value.$model
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.validate.modCheck.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("UK Bank Account")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value:
                                    _vm.$v.licence.validate.modCheck.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.validate.modCheck,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.validate.modCheck.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "modulus check" },
                                model: {
                                  value: _vm.$v.licence.validate.modCheck,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.validate,
                                      "modCheck",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.validate.modCheck"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.validate.iban.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("IBAN")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.$v.licence.validate.iban.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.validate.iban,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.validate.iban.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "iban" },
                                model: {
                                  value: _vm.$v.licence.validate.iban,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.validate,
                                      "iban",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.validate.iban"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: { invalid: _vm.$v.licence.validate.cc.$error }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Credit Card")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.$v.licence.validate.cc.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.validate.cc,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.validate.cc.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "credit card" },
                                model: {
                                  value: _vm.$v.licence.validate.cc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v.licence.validate, "cc", $$v)
                                  },
                                  expression: "$v.licence.validate.cc"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.licence.validate.threshold.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Limit")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.$v.licence.validate.threshold.$model,
                                    expression:
                                      "$v.licence.validate.threshold.$model"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number" },
                                domProps: {
                                  value:
                                    _vm.$v.licence.validate.threshold.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.licence.validate.threshold,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "small",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    "This is the amount of billable modchecks per month"
                                  )
                                ]
                              ),
                              _c("validation-messages", {
                                attrs: { name: "limit" },
                                model: {
                                  value: _vm.$v.licence.validate.threshold,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.validate,
                                      "threshold",
                                      $$v
                                    )
                                  },
                                  expression: "$v.licence.validate.threshold"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.$v.licence.validate.value.$model
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group row",
                              class: {
                                invalid: _vm.$v.licence.validate.api.$error
                              }
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "col-form-label col-md-3" },
                                [_vm._v("Validate API")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("p-check", {
                                    staticClass: "p-switch p-fill",
                                    attrs: { color: "primary" },
                                    model: {
                                      value: _vm.$v.licence.validate.api.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.licence.validate.api,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.licence.validate.api.$model"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(8),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.agent.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.agent.value.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.agent.value,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.licence.agent.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "enabled" },
                          model: {
                            value: _vm.$v.licence.agent.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.agent, "value", $$v)
                            },
                            expression: "$v.licence.agent.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.agent.value.$model
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid:
                              _vm.$v.licence.agent.useOwnCertificate.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Use Own Certificate")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value:
                                    _vm.$v.licence.agent.useOwnCertificate
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.agent.useOwnCertificate,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.agent.useOwnCertificate.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "use own certificate" },
                                model: {
                                  value: _vm.$v.licence.agent.useOwnCertificate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.agent,
                                      "useOwnCertificate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.agent.useOwnCertificate"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid:
                              _vm.$v.licence.agent.allowFtpAddressChange.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Allow FTP Address Change")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: { color: "primary" },
                                model: {
                                  value:
                                    _vm.$v.licence.agent.allowFtpAddressChange
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.agent
                                        .allowFtpAddressChange,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.agent.allowFtpAddressChange.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "allow FTP address change" },
                                model: {
                                  value:
                                    _vm.$v.licence.agent.allowFtpAddressChange,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.licence.agent,
                                      "allowFtpAddressChange",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.licence.agent.allowFtpAddressChange"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(9),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.collections.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.collections.value.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.collections.value,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.licence.collections.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "collections" },
                          model: {
                            value: _vm.$v.licence.collections.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.collections, "value", $$v)
                            },
                            expression: "$v.licence.collections.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.collections.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.collections.onBoarding.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Onboarding")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value:
                                  _vm.$v.licence.collections.onBoarding.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.collections.onBoarding,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.collections.onBoarding.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.licence.collections.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.collections.payerPortal.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Payer Portal")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value:
                                  _vm.$v.licence.collections.payerPortal.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.collections.payerPortal,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.collections.payerPortal.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.licence.collections.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid:
                            _vm.$v.licence.collections.payerThreshold.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Payer Limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.$v.licence.collections.payerThreshold
                                      .$model,
                                  expression:
                                    "$v.licence.collections.payerThreshold.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number" },
                              domProps: {
                                value:
                                  _vm.$v.licence.collections.payerThreshold
                                    .$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.licence.collections.payerThreshold,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "payer limit" },
                              model: {
                                value:
                                  _vm.$v.licence.collections.payerThreshold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.collections,
                                    "payerThreshold",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.collections.payerThreshold"
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "The soft limit on payers per group, can be overriden in the group config"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.licence.collections.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.collections.api.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Collections API")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value: _vm.$v.licence.collections.api.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.collections.api,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.collections.api.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.licence.collections.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.collections.refunds.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Allow Refunds")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value:
                                  _vm.$v.licence.collections.refunds.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.collections.refunds,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.collections.refunds.$model"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(10),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.hsm.value.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.hsm.value.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.hsm.value, "$model", $$v)
                            },
                            expression: "$v.licence.hsm.value.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "hsm" },
                          model: {
                            value: _vm.$v.licence.hsm.value,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.hsm, "value", $$v)
                            },
                            expression: "$v.licence.hsm.value"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.hsm.value.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid: _vm.$v.licence.hsm.hsmThreshold.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("HSM Limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.licence.hsm.hsmThreshold.$model,
                                  expression:
                                    "$v.licence.hsm.hsmThreshold.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number", min: "1" },
                              domProps: {
                                value: _vm.$v.licence.hsm.hsmThreshold.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.licence.hsm.hsmThreshold,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "This is the amount of valid customer certificates you can have at one time"
                                )
                              ]
                            ),
                            _c("validation-messages", {
                              attrs: { name: "limit" },
                              model: {
                                value: _vm.$v.licence.hsm.hsmThreshold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.hsm,
                                    "hsmThreshold",
                                    $$v
                                  )
                                },
                                expression: "$v.licence.hsm.hsmThreshold"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(11),
              _c("div", { staticClass: "idb-block-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.licence.antiFraud.enabled.$error }
                  },
                  [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Enabled")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.$v.licence.antiFraud.enabled.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.licence.antiFraud.enabled,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.licence.antiFraud.enabled.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "antiFraud" },
                          model: {
                            value: _vm.$v.licence.antiFraud.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.licence.antiFraud, "enabled", $$v)
                            },
                            expression: "$v.licence.antiFraud.enabled"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.$v.licence.antiFraud.enabled.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid:
                            _vm.$v.licence.antiFraud.whitelistThreshold.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Whitelist Limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.$v.licence.antiFraud.whitelistThreshold
                                      .$model,
                                  expression:
                                    "$v.licence.antiFraud.whitelistThreshold.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number" },
                              domProps: {
                                value:
                                  _vm.$v.licence.antiFraud.whitelistThreshold
                                    .$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.licence.antiFraud.whitelistThreshold,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "This is the amount of whitelists you can have at one time"
                                )
                              ]
                            ),
                            _c("validation-messages", {
                              attrs: { name: "limit" },
                              model: {
                                value:
                                  _vm.$v.licence.antiFraud.whitelistThreshold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.antiFraud,
                                    "whitelistThreshold",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.antiFraud.whitelistThreshold"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.licence.antiFraud.enabled.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid:
                            _vm.$v.licence.antiFraud.blacklistThreshold.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Blacklist Limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.$v.licence.antiFraud.blacklistThreshold
                                      .$model,
                                  expression:
                                    "$v.licence.antiFraud.blacklistThreshold.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number" },
                              domProps: {
                                value:
                                  _vm.$v.licence.antiFraud.blacklistThreshold
                                    .$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.licence.antiFraud.blacklistThreshold,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "This is the amount of blacklists you can have at one time"
                                )
                              ]
                            ),
                            _c("validation-messages", {
                              attrs: { name: "limit" },
                              model: {
                                value:
                                  _vm.$v.licence.antiFraud.blacklistThreshold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.licence.antiFraud,
                                    "blacklistThreshold",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.licence.antiFraud.blacklistThreshold"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                class: _vm.needsChecking ? "btn-warning" : "btn-success",
                attrs: {
                  type: "submit",
                  disabled:
                    (_vm.areErrors && !_vm.needsChecking) || _vm.isLoading
                }
              },
              [_vm._v(_vm._s(_vm.needsChecking ? "Check conflicts" : "Save"))]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Resources")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-2" }),
      _c("div", { staticClass: "col-md-3" }, [_vm._v("Current Limit")]),
      _c("div", { staticClass: "col-md-3" }, [_vm._v("Used")]),
      _c("div", { staticClass: "col-md-3" }, [_vm._v("Available")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Payments")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Automation")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Bureau")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c(
        "span",
        { staticClass: "input-group-text", attrs: { id: "storage-addon" } },
        [_vm._v("GB")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Multi-Factor Authentication (MFA)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Validation")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Agent")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Collections")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Hardware Security Module (HSM)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Anti-Fraud Module")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }