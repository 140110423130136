<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Users
          <help-icon docPath="/administration/users/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'enabled'">
              <tick v-model="props.row.enabled"></tick>
            </span>
            <span v-else-if="props.column.field == 'lockoutEnd'">
              <span v-if="new Date(props.row.lockoutEnd) < new Date()"></span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="createUser"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Create a new user'"
            >
              <i class="fa fa-user-plus dimmedIcon pointer"></i>
            </button>
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="createUser" type="button">
          <i class="fa fa-user-plus rpad"></i>Create User
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Phone Number',
          field: 'phoneNumber',
          filterOptions: {
            enabled: true
          },
          sortable: false
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Locked Until',
          field: 'lockoutEnd',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: 'Last Login',
          field: 'lastLogin',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: 'Enabled',
          field: 'enabled',
          type: 'bool',
          tdClass: 'text-center'
        }
      ],
      serverParams: {
        sort: [{ field: 'email', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var userId = params.row.id
      this.$router.push({ name: 'UserEdit', params: { userId } })
    },
    createUser () {
      this.$router.push({ name: 'UserCreate' })
    },
    async load () {
      try {
        this.isTableLoading = true

        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}users`, { params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer }, showerror: true, errormessage: 'Failed loading users' })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } finally {
        this.isTableLoading = false
      }
    }
  }
}

</script>
