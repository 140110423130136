var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c(
              "form",
              {
                staticClass: "col-md-12",
                attrs: { novalidate: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "label-control" }, [
                      _vm._v("Please choose the month you'd like to download")
                    ]),
                    _c("vuejsDatepicker", {
                      attrs: {
                        name: "date",
                        id: "date",
                        format: "MMMM yyyy",
                        "input-class": "form-control bg-white col-md-3",
                        "bootstrap-styling": true,
                        minimumView: "month",
                        maximumView: "year",
                        "initial-view": "month",
                        "use-utc": true,
                        "disabled-dates": {
                          from: new Date()
                        },
                        "calendar-button": true,
                        "calendar-button-icon": "fa fa-calendar"
                      },
                      on: { input: _vm.getAmountOfRecords },
                      model: {
                        value: _vm.selectedDate,
                        callback: function($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate"
                      }
                    }),
                    _vm.amountOfRecords
                      ? _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "There are " +
                              _vm._s(_vm.amountOfRecords) +
                              " records for this month"
                          )
                        ])
                      : _vm.isLoading
                      ? _c("small", { staticClass: "form-text text-info" }, [
                          _vm._v(" Counting records ... "),
                          _c("i", { staticClass: "fas fa-spinner fa-pulse" })
                        ])
                      : _c("small", { staticClass: "form-text text-warning" }, [
                          _vm._v("There are no records for this month")
                        ]),
                    _c("small", { staticClass: "form-text" }, [
                      _vm._v("This will download a report for all customers")
                    ])
                  ],
                  1
                )
              ]
            )
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.isLoading },
                on: { click: _vm.downloadFile }
              },
              [_vm._v("Download")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { attrs: { id: "title-of-block-check" } }, [
        _vm._v("Download Monthly Report")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }