var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [
      _vm._v("Log Entry"),
      _c("span", { staticClass: "pull-right" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                _vm.docUrl +
                "/automation/paygate-workflow/workflow-nodes/messaging/smsmessage/",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "far fa-question-circle" })]
        )
      ])
    ]),
    _c("hr"),
    _c("br"),
    _c("div", { staticClass: "textarea" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Message")]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s1.value,
              expression: "selectedNode.props.s1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s1.value.$error },
          attrs: { rows: "4", cols: "40" },
          domProps: { value: _vm.selectedNode.props.s1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s1.value.$touch()
              }
            ]
          }
        }),
        !_vm.$v.selectedNode.props.s1.value.required &&
        _vm.$v.selectedNode.props.s1.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The message field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.s1.value.maxLength
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum length is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.s1.value.$params.maxLength.max
                  ) +
                  " characters!"
              )
            ])
          : _vm._e()
      ])
    ]),
    _c("br"),
    _c("hr"),
    _c(
      "div",
      { staticClass: "pull-right" },
      [
        _c(
          "b-button",
          {
            staticClass: "btnPad",
            attrs: { variant: "danger" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnCancel($event)
              }
            }
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "success" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnSave($event)
              }
            }
          },
          [_vm._v(" OK ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }