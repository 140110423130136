var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h1", { staticClass: "text-center text-danger" }, [
    _vm._v(
      " You do not have access to this part of paygate. Please contact your system's administrator for guidance. "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }