<template>
  <div class="sidebar-panel">
    <vue-perfect-scrollbar :settings="settings">
      <div class="idb-sidebar-nav">
        <div class="ps-container ps-theme-default">
          <nav class="navigation scroll-area">
            <ul class="idb-nav list-unstyled list-hover-slide">
              <template v-for="module in $store.state.menu.menu.routes">
                <li
                  :key="module.path"
                  class="nav-item"
                  :class="{'active open': module.active}"
                  :id="module.name"
                  v-show="module.show"
                >
                  <a href="#" class="nav-link" @click="open(module,$store.state.menu.menu)">
                    <div>
                      <i :class="module.menu_icon"></i>
                      <span class="menu-title">
                        {{ module.menu_title }}
                        <b-badge
                          v-if="showParentAlert(module)"
                          pill
                          :variant="getSeverityLevelClass(getHighestChildSeverityLevel(module))"
                          class="ml-2"
                        >{{childAlertCount(module) > 1 ? '!' : childAlertCount(module)}}</b-badge>
                      </span>
                      <span class="float-right">
                        <i class="ti-angle-down"></i>
                      </span>
                    </div>
                  </a>
                  <ul class="idb-nav sub-menu list-unstyled list-hover-slide">
                    <template v-for="menu in module.routes">
                      <li
                        class="nav-item"
                        :class="{'active open': menu.active}"
                        v-if="menu.child_routes!=null"
                        :key="menu.menu_title"
                        v-show="menu.show"
                      >
                        <a href="#" class="nav-link" :id="menu.name" @click="open(menu,module)">
                          <div>
                            <i style="width:20px" :class="menu.menu_icon"></i>
                            <span class="menu-title">
                              {{ menu.menu_title }}
                              <b-badge
                                v-if="showSubMenuAlert(module, menu.menu_title)"
                                pill
                                variant="danger"
                                class="ml-2"
                              >!</b-badge>
                            </span>
                            <span class="float-right">
                              <i class="ti-angle-down"></i>
                            </span>
                          </div>
                        </a>
                        <ul class="list-unstyled sub-menu">
                          <router-link
                            :to="subMenu.path"
                            tag="li"
                            v-for="subMenu in menu.child_routes"
                            :id="subMenu.name"
                            v-show="subMenu.show"
                            :key="subMenu.path"
                          >
                            <a>
                              <span>
                                {{ subMenu.menu_title }}
                                <b-badge
                                  v-if="subMenu.counterData !== undefined && fromGetter(subMenu.counterData).count !== 0"
                                  pill
                                  :variant="getSeverityLevelClass(subMenu.severityData ? fromGetter(subMenu.severityData) : 0)"
                                  class="ml-2"
                                >{{(!fromGetter(subMenu.counterData).treatAsBoolean ? fromGetter(subMenu.counterData).count : '!')}}</b-badge>
                              </span>
                            </a>
                          </router-link>
                        </ul>
                      </li>
                      <router-link
                        :key="menu.menu_title"
                        :to="menu.path"
                        tag="li"
                        class="nav-item"
                        v-else
                        v-show="menu.show"
                      >
                        <a class="nav-link" @click="open(menu,module)">
                          <i style="width:20px" :class="menu.menu_icon"></i>
                          <span class="menu-title">
                            {{ menu.menu_title }}
                            <b-badge
                              v-if="menu.counterData !== undefined && fromGetter(menu.counterData).count !== 0"
                              pill
                              :variant="getSeverityLevelClass(menu.severityData ? fromGetter(menu.severityData) : 0)"
                              class="ml-2"
                            >{{(!fromGetter(menu.counterData).treatAsBoolean ? fromGetter(menu.counterData).count : '!')}}</b-badge>
                          </span>
                        </a>
                      </router-link>
                    </template>
                  </ul>
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EventBus from '@/Lib/eventBus'
import Licence from '@/Assets/Constants/licence'
import Roles from '@/Assets/Constants/roles'

export default {
  name: 'sidenav',
  data: function () {
    return {
      menuToggleClass: '',
      activeIndex: 0,
      settings: {
        maxScrollbarLength: 100
      },
      refreshPillsIntervalId: null,
      refreshPillsLongIntervalId: null
    }
  },
  components: {
    VuePerfectScrollbar
  },
  methods: {
    open (menuItem, parentMenu) {
      this.$store.commit('openMenu', { menuItem, parentMenu })

      EventBus.$emit('collapsedSidebar', false)
    },
    fromGetter (path) {
      return this.$store.getters[path]
    },
    showParentAlert (module) {
      var show = false
      for (var i = 0; i < module.routes.length; i++) {
        if (module.routes[i].counterData && this.fromGetter(module.routes[i].counterData).includeInTopLevel && this.fromGetter(module.routes[i].counterData).count > 0) {
          show = true
        }
        if (module.routes[i].child_routes && module.routes[i].child_routes.length > 0) {
          for (var a = 0; a < module.routes[i].child_routes.length; a++) {
            if (module.routes[i].child_routes[a].counterData && (this.fromGetter(module.routes[i].child_routes[a].counterData).includeInTopLevel && this.fromGetter(module.routes[i].child_routes[a].counterData).count > 0)) {
              show = true
            }
          }
        }
      }
      return show
    },
    showSubMenuAlert (module, menuTitle) {
      var show = false
      for (var i = 0; i < module.routes.length; i++) {
        if (menuTitle === module.routes[i].menu_title) {
          if (module.routes[i].child_routes && module.routes[i].child_routes.length > 0) {
            for (var a = 0; a < module.routes[i].child_routes.length; a++) {
              if (module.routes[i].child_routes[a].counterData && (this.fromGetter(module.routes[i].child_routes[a].counterData).includeInTopLevel && this.fromGetter(module.routes[i].child_routes[a].counterData).count > 0)) {
                show = true
              }
            }
          }
        }
      }
      return show
    },
    childAlertCount (module) {
      var childAlertCount = 0
      for (var i = 0; i < module.routes.length; i++) {
        if (module.routes[i].counterData && (this.fromGetter(module.routes[i].counterData).includeInTopLevel && this.fromGetter(module.routes[i].counterData).count > 0)) {
          childAlertCount += this.fromGetter(module.routes[i].counterData).count
        }
        if (module.routes[i].child_routes && module.routes[i].child_routes.length > 0) {
          for (var a = 0; a < module.routes[i].child_routes.length; a++) {
            if (module.routes[i].child_routes[a].counterData && (this.fromGetter(module.routes[i].child_routes[a].counterData).includeInTopLevel && this.fromGetter(module.routes[i].child_routes[a].counterData).count)) {
              childAlertCount += this.fromGetter(module.routes[i].child_routes[a].counterData).count
            }
          }
        }
      }
      return childAlertCount
    },
    getSeverityLevelClass (level) {
      let retVal = ''
      switch (level) {
        case 0:
          retVal = 'success'
          break
        case 1:
          retVal = 'warning'
          break
        default:
          retVal = 'danger'
          break
      }
      return retVal
    },
    getHighestChildSeverityLevel (module) {
      var highestAlertLevel = 0
      for (var i = 0; i < module.routes.length; i++) {
        if (module.routes[i].severityData && this.fromGetter(module.routes[i].severityData) > 0) {
          if (highestAlertLevel < this.fromGetter(module.routes[i].severityData)) {
            highestAlertLevel = this.fromGetter(module.routes[i].severityData)
          }
        }
        if (module.routes[i].child_routes && module.routes[i].child_routes.length > 0) {
          for (var a = 0; a < module.routes[i].child_routes.length; a++) {
            if (module.routes[i].child_routes[a].severityData && this.fromGetter(module.routes[i].child_routes[a].severityData)) {
              if (highestAlertLevel < this.fromGetter(module.routes[i].child_routes[a].severityData)) {
                highestAlertLevel = this.fromGetter(module.routes[i].child_routes[a].severityData)
              }
            }
          }
        }
      }
      return highestAlertLevel
    },
    triggerPillUpdate () {
      if (this.$store.getters.hasLicence(Licence.collections)) {
        this.$store.dispatch('updateCurrentCollectionsAlertCount')
        this.$store.dispatch('updateSubmissionsInProgressCount')
      }

      if (this.$store.getters.hasLicence(Licence.bureau)) {
        this.$store.dispatch('updateBureauTopSeverityLevel')
        this.$store.dispatch('updateBureauSubmissionPill')
        this.$store.dispatch('updateBureauCustomerImportPill')
      }

      if (this.$store.getters.hasLicence(Licence.collectionsPayerPortal)) {
        this.$store.dispatch('updatePortalMessagesCount')
      }

      if (this.$store.getters.hasLicence(Licence.automation_workflow) && this.$store.getters.isInRole(Roles.ViewWorkflow)) {
        this.$store.dispatch('updateCurrentTriggerCount')
      }

      this.$store.dispatch('updateBouncedEmailsCount')
    },
    triggerLongPillUpdate () {
      if (this.$store.getters.hasLicence('LicenceCollections')) {
        if (this.$store.getters.isInRole('ProcessSubmissions')) {
          this.$store.dispatch('updateCurrentCollectionsSubmissionCount')
          this.$store.dispatch('updateSubmissionSeverityLevel')
        }
      }
    }
  },
  async created () {
    await this.$store.dispatch('updateMenu')
    this.triggerPillUpdate()
    this.triggerLongPillUpdate()
  },
  mounted () {
    if (this.refreshPillsIntervalId === null) {
      this.refreshPillsIntervalId = setInterval(this.triggerPillUpdate, 60000)
    }
    if (this.refreshPillsLongIntervalId === null) {
      this.refreshPillsLongIntervalId = setInterval(this.triggerLongPillUpdate, 660000)
    }
  },
  destroyed () {
    if (this.refreshPillsIntervalId !== null) {
      clearInterval(this.refreshPillsIntervalId)
      clearInterval(this.refreshPillsLongIntervalId)
    }
  }
}
</script>

<style lang="scss">
.scroll-area {
  margin: auto;
  width: 100%;
  min-height: 100%;
}
</style>
