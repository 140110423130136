var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "alertsDiv" } },
    [
      _c(
        "vue-good-table",
        {
          ref: "payerAlerts",
          attrs: {
            columns: _vm.columns,
            rows: _vm.rows,
            "pagination-options": {
              enabled: _vm.enablePagination,
              perPage: 10,
              dropdownAllowAll: false
            },
            "search-options": {
              enabled: true
            },
            styleClass: "vgt-table striped bordered"
          },
          on: { "on-row-click": _vm.viewAlert },
          scopedSlots: _vm._u([
            {
              key: "table-row",
              fn: function(props) {
                return [
                  props.column.field == "alertLevel"
                    ? _c(
                        "span",
                        { staticClass: "alertContainer" },
                        [
                          _c(
                            "b-badge",
                            {
                              attrs: {
                                pill: "",
                                variant: _vm.getSeverityClass(
                                  props.row.alertLevel
                                )
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.getMessage(props.row.alertLevel))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c("span", [
                        _vm._v(_vm._s(props.formattedRow[props.column.field]))
                      ])
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "loadingContent" }, [
            _c("h1", [_vm._v("Loading...")])
          ]),
          _c(
            "div",
            { attrs: { slot: "table-actions" }, slot: "table-actions" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top.d500",
                      value: "Refresh the data in the table",
                      expression: "'Refresh the data in the table'",
                      modifiers: { hover: true, top: true, d500: true }
                    }
                  ],
                  attrs: { variant: "link", title: "Refresh Table" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.reloadTable($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-sync pointer dimmedIcon" })]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top.d500",
                      value: "Print out the contents of the Logs table",
                      expression: "'Print out the contents of the Logs table'",
                      modifiers: { hover: true, top: true, d500: true }
                    }
                  ],
                  attrs: { variant: "link", title: "Print Table" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.printTable($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-print pointer dimmedIcon" })]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top.d500",
                      value: "Export the contents of the Logs table",
                      expression: "'Export the contents of the Logs table'",
                      modifiers: { hover: true, top: true, d500: true }
                    }
                  ],
                  attrs: { variant: "link", title: "Export Table" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.exportTable($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-share-square pointer dimmedIcon"
                  })
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }