function lowerCaseFirstLetter (string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

function returnRouteObject ({ id, actionedObjectId, actionClass, actionSubClass }) {
  var objectToReturn = {}

  switch (actionClass) {
    case 'BankAccount':
      objectToReturn.name = 'BankAccountDetails'
      objectToReturn.query = { actionId: id, [lowerCaseFirstLetter(actionClass) + 'Id']: actionedObjectId }
      break
    case 'BureauCustomer':
      objectToReturn.name = 'BureauCustomerActionDetails'
      objectToReturn.query = { actionId: id, [lowerCaseFirstLetter(actionClass) + 'Id']: actionedObjectId }
      break
    case 'User':
      objectToReturn.name = 'UserDetails'
      objectToReturn.query = { actionId: id, [lowerCaseFirstLetter(actionClass) + 'Id']: actionedObjectId }
      break
    case 'Group':
      objectToReturn.name = 'GroupDetails'
      objectToReturn.query = { actionId: id, [lowerCaseFirstLetter(actionClass) + 'Id']: actionedObjectId }
      break
    case 'Bacs':
      objectToReturn.path = '/payments/bacsActions/actionWrapper'
      objectToReturn.query = { actionId: id, actionedObjectId, actionSubClass }
      break
    case 'System':
      objectToReturn.path = '/actions/info/'
      objectToReturn.query = { actionId: id }
      break
    case 'DDMS':
      switch (actionSubClass) {
        case 'PayerUploadSuccess':
        case 'PayerUploadError':
        case 'PayerUploadWarning':
          objectToReturn.path = `/collections/data/import/${actionedObjectId}`
          objectToReturn.query = { actionId: id, uploadId: actionedObjectId, actionSubClass }
          break
        case 'SynchronisationSuccess':
        case 'SynchronisationError':
          objectToReturn.path = `/collections/reconciliation/report/${actionedObjectId}/`
          objectToReturn.query = { actionId: id, syncId: actionedObjectId, actionSubClass }
          break
        case 'VariableAmountUpdateSuccess':
        case 'VariableAmountUpdateError':
          objectToReturn.path = `/collections/data/variableamount/${actionedObjectId}`
          objectToReturn.query = { actionId: id }
          break
        case 'SubmissionOverdue':
          objectToReturn.path = `/collections/submissions/uncollected/${actionedObjectId}`
          objectToReturn.query = { actionId: id }
          break
          case 'PayerExpirationWarning':
          objectToReturn.path = `/collections/actions/finalwarning/${actionedObjectId}`
          objectToReturn.query = { actionId: id }
          break
          case 'WithdrawalCancellationNeeded':
            objectToReturn.path = `/collections/cancelsubmission/${actionedObjectId}/1`
            objectToReturn.query = { actionId: id }
            break
          case 'WithdrawalCancellationPerformed':
            objectToReturn.path = `/collections/actions/withdrawalperformed/${actionedObjectId}/1`
            objectToReturn.query = { actionId: id }
            break
          case 'CollectionsMessagingFailure':
            objectToReturn.path = `/collections/actions/messaging/${actionedObjectId}/failed`
            objectToReturn.query = { actionId: id }
            break
          case 'CollectionsMessagingBounce':
            objectToReturn.path = `/collections/actions/messaging/${actionedObjectId}/bounced`
            objectToReturn.query = { actionId: id }
            break
      }
      break
    case 'AntiFraudList':
      objectToReturn.name = 'AntiFraudListDetails'
      objectToReturn.query = { actionId: id, [lowerCaseFirstLetter(actionClass) + 'Id']: actionedObjectId }
      break
    case 'Agent':
      switch (actionSubClass) {
        case 'AgentFileHashError':        
          objectToReturn.path = '/actions/info/'
          objectToReturn.query = { actionId: id }
          break
      }
      break
  }

  return objectToReturn
}

function getIcon ({ actionSubClass }) {
  switch (actionSubClass) {
    case 'Create':
      return 'fa-plus'
    case 'Edit':
      return 'fa-edit'
    case 'Delete':
      return 'fa-trash-alt'
    case 'Sign':
      return 'fa-file-signature'
    case 'Commit':
      return 'fa-handshake'
    case 'Approve':
      return 'fa-thumbs-up'
    case 'Send':
      return 'fa-paper-plane'
    case 'PayerUploadWarning':
      return 'fa-exclamation-triangle'
    case 'PayerUploadError':
      return 'fa-exclamation-circle'
    case 'PayerUploadSuccess':
      return 'fa-check-circle'
    case 'SynchronisationSuccess':
      return 'fa-check-circle'
    case 'SynchronisationError':
      return 'fa-exclamation-circle'
    case 'VariableAmountUpdateSuccess':
      return 'fa-check-circle'
    case 'VariableAmountUpdateError':
      return 'fa-exclamation-circle'
    case 'PayerExpirationWarning':
      return 'fa-exclamation-triangle'
    case 'Info':
      return 'fa-info-circle'
    case 'SubmissionOverdue':
      return 'fa-exclamation-triangle'
    default:
      return 'fa-exclamation-triangle'
  }
}

export { returnRouteObject, getIcon }
