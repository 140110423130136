var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      directives: [
        {
          name: "b-popover",
          rawName: "v-b-popover.hover.bottom",
          value: "Actions",
          expression: "'Actions'",
          modifiers: { hover: true, bottom: true }
        }
      ],
      attrs: { right: "", "no-caret": "" }
    },
    [
      _c(
        "template",
        { slot: "button-content" },
        [
          _c("i", {
            staticClass: "far fa-bell faa-ring animated-hover faa-slow",
            staticStyle: { "line-height": "30px" }
          }),
          _vm.actions.length > 0
            ? _c(
                "b-badge",
                {
                  staticClass: "badge-sm badge-top-right fa-notify",
                  attrs: { pill: "", variant: _vm.bellColour }
                },
                [_vm._v(_vm._s(_vm.actionsCount))]
              )
            : _vm._e(),
          !_vm.isConnected
            ? _c(
                "b-badge",
                {
                  staticClass: "badge-sm badge-top-right fa-notify",
                  attrs: { pill: "", variant: "warning" }
                },
                [_vm._v("!")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("b-dropdown-header", { staticClass: "bg-warning text-white" }, [
        _vm._v(
          "Actions " +
            _vm._s(
              _vm.actionsCount > 5 ? "(5 of " + _vm.actionsCount + ")" : ""
            )
        )
      ]),
      !_vm.isConnected
        ? _c(
            "b-dropdown-item",
            { staticClass: "mb-10", attrs: { exact: "" } },
            [
              _c("div", { staticClass: "media" }, [
                _c("div", { staticClass: "media-body pt-10" }, [
                  _c(
                    "p",
                    {
                      staticClass: "fw-bold text-danger",
                      staticStyle: {
                        "max-width": "320px",
                        "white-space": "pre-line"
                      }
                    },
                    [
                      _vm._v(
                        "You are not connected, please click the actions list button below as you may have actions!"
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._l(_vm.actions, function(action) {
        return _c(
          "b-dropdown-item",
          {
            key: action.id,
            staticClass: "pb-10 position-relative",
            attrs: { to: _vm.goToAction(action), exact: "" }
          },
          [
            _c("div", {
              staticClass: "group-colour",
              style: { "background-color": action.groupColour }
            }),
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "d-flex pos-relative mr-20" }, [
                _c("span", [
                  _c("i", {
                    staticClass: "fa fa-custom",
                    class: _vm.getIcon(action)
                  })
                ]),
                _c("span", {
                  staticClass:
                    "badge badge-label-sm badge-thumb-top-right badge-pill fa-notify",
                  class: _vm.dateColour(action)
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "media-body pt-10",
                  class: { "text-danger": action.rejected }
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "fw-bold",
                      class: { "text-danger": action.rejected },
                      staticStyle: {
                        "max-width": "340px",
                        "text-overflow": "ellipsis",
                        overflow: "hidden"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(action.title) +
                          " - " +
                          _vm._s(
                            action.groupName ? action.groupName + " - " : ""
                          ) +
                          _vm._s(_vm._f("formatDate")(action.createdDate))
                      )
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "m-0 small",
                      staticStyle: {
                        "max-width": "320px",
                        "text-overflow": "ellipsis",
                        overflow: "hidden"
                      }
                    },
                    [_vm._v(_vm._s(action.message))]
                  )
                ]
              )
            ])
          ]
        )
      }),
      _vm.actionsCount === 0 && _vm.isConnected
        ? _c(
            "b-dropdown-item",
            { staticClass: "mb-10", attrs: { exact: "" } },
            [
              _c("div", { staticClass: "media" }, [
                _c("div", { staticClass: "media-body pt-10" }, [
                  _c("p", { staticClass: "fw-bold" }, [
                    _vm._v("You have no actions!")
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          staticClass: "mb-10 text-center",
          attrs: { to: { name: "Actions" } }
        },
        [_vm._v("Full Actions List")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }