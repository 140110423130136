<template>
  <div class="idb-block">
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block-title">
      <h2>
        Submission details for '{{ bureauJob.bureauJobName }}'
        <help-icon></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <div class="idb-block-content">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="submissionReference">Submission Reference</label>
        </b-col>
        <b-col sm="6">
          <b-form-input type="text" v-model.trim="$v.submissionReference.$model"></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.submissionReference.$dirty">
          <label
            class="text-danger small"
            v-if="!$v.submissionReference.required"
          >A 'Submission Reference' is required</label>
          <label
            class="text-danger small"
            v-if="!$v.submissionReference.maxLength"
          >A 'Submission Reference' must be less than {{$v.submissionReference.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="contraNarrative">Contra Narrative</label>
        </b-col>
        <b-col sm="6">
          <b-form-input type="text" v-model.trim="$v.contraNarrative.$model"></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.contraNarrative.$dirty">
          <label
            class="text-danger small"
            v-if="!$v.contraNarrative.maxLength"
          >A 'Contra Narrative' must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="paymentDate">Payment Date</label>
        </b-col>
        <b-col sm="3">
          <vuejsDatepicker
            ref="paymentDate"
            name="paymentDate"
            id="paymentDate"
            v-model="paymentDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            v-on:selected="paymentDateChanged"
            @focusin.native="dateOnfocus"
            :monday-first="true"
            :disabled="disableDatePicker"
          ></vuejsDatepicker>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="processingDate">Processing Date</label>
        </b-col>
        <b-col sm="3">{{ processingDate }}</b-col>
      </b-row>
    </div>

    <div class="idb-block-footer">
      <button
        class="btn btn-primary mr-2"
        :disabled="$v.$invalid || isTableLoading"
        @click="onNextClick"
      >Next</button>
    </div>
  </div>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import Utility from '@/Assets/Mixins/Utility'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [tableFilterMixin,
    bacsMixin,
    Utility,
    loading
  ],
  props: {
    id: String
  },
  data () {
    return {
      submissionReference: '',
      contraNarrative: '',
      processingDate: '',
      paymentDate: '',
      disabledDates: {},
      processingDates: {},
      disableDatePicker: false,
      readonly: false, // get rid of this
      bureauJob: {},
      // bureauJobName: '',
      // bureauServiceUserNumber: '',
      // groupId: '',
      rows: [],
      columns: [
        {
          label: 'Bureau Customer',
          field: 'bureauCustomerName'
        },
        {
          label: 'Filename',
          field: 'filename'
        }
      ]
    }
  },

  async created () {
    console.log('bureaujobid from props', this.id)
    this.disabledDates = await this.setUpBACSDatePicker()
    this.paymentDate = await this.getNextPaymentDate()
    await this.load()
  },

  async mounted () {
    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'

    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate
        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }
      }
      )
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/GetBureauJobDetails/' + this.id, {})
        console.log('automatedbacsdetailselection job load', response)
        this.bureauJob = response.data
        this.rows = response.data.bureauJobDetailLines
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    async onNextClick () {
      try {
        var data = {
          submissionReference: this.submissionReference,
          bureauJobId: this.bureauJob.bureauJobId,
          contraNarrative: this.contraNarrative,
          processingDate: this.processingDate,
          groupId: this.bureauJob.groupId
        }
        console.log('data from submission', data)
        var sendupdata = JSON.stringify(data)
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/startbacsbureausubmission', sendupdata, { showload: true })

        console.log('bureau submission result', response)
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          var bureauSubmissionId = response.data.id
          console.log('bureau submission bureauSubmissionId', bureauSubmissionId)
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: bureauSubmissionId, startProcess: true } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
        // this.$store.dispatch('setSubmission', data)
      } catch (error) {
        this.$toastr.e(`Bureau Submission ${data.submissionReference} failed ${error}`, 'Bureau Submission')
      }
    },
    paymentDateChanged (value) {
      this.$store.dispatch('getNextProcessingDate', value.toJSON())
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
          console.log('processingDate: ', this.processingDate)
        })
      // await _.debounce(() => {
      //   this.processingDate = this.getProcessingDateFromPaymentDate(this.paymentDate)
      // }, 5)
    }
  },
  validations: {
    submissionReference: {
      required,
      maxLength: maxLength(150)
    },
    contraNarrative: {
      maxLength: maxLength(50)
    }
  }

}

</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
