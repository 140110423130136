<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Password Expiration Policy
            <help-icon docPath="/administration/securitypolicy/passwordexpirationpolicy/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row">
            <div class="col-md-7 offset-md-1">
              <p-radio
                class="p-default p-round"
                name="passwordExpirationBehaviour"
                value="Default"
                v-model="$v.policy.passwordExpirationBehaviour.$model"
              >Use Standard Behaviour (Passwords never expire)</p-radio>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-7 offset-md-1">
              <p-radio
                class="p-default p-round"
                name="passwordExpirationBehaviour"
                value="Custom"
                v-model="$v.policy.passwordExpirationBehaviour.$model"
              >Passwords expire after n days</p-radio>
            </div>
          </div>
          <transition name="fade">
            <div
              class="form-group row pl-5"
              v-if="$v.policy.passwordExpirationBehaviour.$model == 'Custom'"
              :class="{invalid: $v.policy.expirationDays.$error}"
            >
              <label class="col-form-label col-md-2 offset-md-1">Amount of Days</label>
              <div class="col-md-2">
                <input type="number" class="form-control" v-model="$v.policy.expirationDays.$model" />
                <validation-messages v-model="$v.policy.expirationDays"></validation-messages>
              </div>
            </div>
          </transition>
          <div class="form-group row">
            <label
              class="col-form-label col-md-4 offset-md-1"
            >Force user to change password after administrator reset?</label>
            <div class="col-md-7">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.forceChangeAfterReset.$model"
              ></p-check>
            </div>
          </div>
          <div class="form-group row">
            <label
              class="col-form-label col-md-4 offset-md-1"
            >Never expire passwords for specific users?</label>
            <div class="col-md-6">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.excludeSpecificUsers.$model"
              ></p-check>
            </div>
          </div>
          <transition name="fade">
            <div class="form-group row pl-5" v-if="$v.policy.excludeSpecificUsers.$model">
              <label class="col-form-label col-md-2 offset-md-1">Excluded users</label>
              <div class="col-md-7">
                <vue-select
                  multiple
                  v-model="policy.excludedUsers"
                  :options="users"
                  :closeOnSelect="false"
                ></vue-select>
              </div>
            </div>
          </transition>
        </div>
        <div class="idb-block-footer">
          <button type="submit" class="btn btn-primary" :disabled="isLoading">Save</button>
          <button
            type="button"
            @click="reset"
            class="btn btn-outline-warning ml-3"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { requiredIf, minValue, numeric } from 'vuelidate/lib/validators'
import VueSelect from 'vue-select'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    VueSelect
  },
  computed: {
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      policy: {
        passwordExpirationBehaviour: 'Default',
        expirationDays: 30,
        forceChangeAfterReset: true,
        excludeSpecificUsers: false,
        excludedUsers: []
      },
      users: [],
      errors: []
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PasswordExpiration`, this.policy,
          { showload: true, showerror: true, errormessage: 'Password expiration failed to save' })
        this.$toastr.s('Password Expiration policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        passwordExpirationBehaviour: 'Default',
        expirationDays: 30,
        forceChangeAfterReset: true,
        excludeSpecificUsers: false,
        excludedUsers: []
      }
      this.errors = []
      this.$toastr.i('Password Expiration policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        this.users = await this.$store.dispatch('getUsersAsDropDownSource')
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PasswordExpiration`,
          { showload: true, showerror: true, errormessage: 'Password expiration failed to load' })
        this.policy = response.data
      } catch { }
    }
  },
  validations () {
    return {
      policy: {
        passwordExpirationBehaviour: {},
        forceChangeAfterReset: {},
        excludeSpecificUsers: {},
        excludedUsers: {},
        expirationDays: {
          required: requiredIf((value) => {
            return value.passwordExpirationBehaviour === 'Custom'
          }),
          numeric,
          minValue: minValue(1)
        }
      }
    }
  }
}
</script>
