var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            "Cancel Submission Detail - " +
              _vm._s(_vm.submissionTitle) +
              " - " +
              _vm._s(_vm.submissionDate)
          ),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c("p", [
          _c("strong", [_vm._v("Service User Number:")]),
          _vm._v(" " + _vm._s(_vm.submissionSun))
        ]),
        _c("p", [
          _c("strong", [_vm._v("File Number:")]),
          _vm._v(" " + _vm._s(_vm.fileNumber))
        ]),
        _c(
          "vue-good-table",
          {
            ref: "table",
            attrs: {
              columns: _vm.columns,
              rows: _vm.records,
              mode: "remote",
              styleClass: "vgt-table striped bordered",
              lineNumbers: true,
              totalRows: _vm.totalRecords,
              "search-options": {
                enabled: true
              },
              paginationOptions: _vm.paginationOptions,
              "sort-options": _vm.sortOptions,
              isLoading: _vm.isTableLoading
            },
            on: {
              "on-page-change": _vm.onPageChange,
              "on-sort-change": _vm.onSortChange,
              "on-column-filter": _vm.onColumnFilter,
              "on-per-page-change": _vm.onPerPageChange,
              "on-search": _vm.onSearch,
              "update:isLoading": function($event) {
                _vm.isTableLoading = $event
              },
              "update:is-loading": function($event) {
                _vm.isTableLoading = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field === "viewPayerButton"
                      ? _c(
                          "span",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "editPayer",
                                    params: { ukPayerId: props.row.ukPayerId }
                                  }
                                }
                              },
                              [_c("span", [_vm._v("View Payer")])]
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(props.formattedRow[props.column.field]) +
                              " "
                          )
                        ])
                  ]
                }
              }
            ])
          },
          [
            _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
              _c("span", { staticStyle: { "text-align": "center" } }, [
                _vm._v("No entries have been found matching the criteria.")
              ])
            ]),
            _c(
              "div",
              { attrs: { slot: "table-actions" }, slot: "table-actions" },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Clear filters",
                        expression: "'Clear filters'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    staticClass: "btn btn-link",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.clearTableFilters($event)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "fa-stack",
                        staticStyle: { "font-size": "10px" }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                        }),
                        _c("i", {
                          staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                        })
                      ]
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the table",
                        expression: "'Refresh the data in the table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { disabled: _vm.isLoading, variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.load($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-sync pointer dimmedIcon" })]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Export the contents of the table",
                        expression: "'Export the contents of the table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.exportTable($event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-file-export pointer dimmedIcon"
                    })
                  ]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "idb-block-footer" },
      [
        _vm.actionId && _vm.actionId !== null
          ? _c(
              "b-button",
              {
                staticClass: "mr-2",
                attrs: { variant: "primary", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.removeAction($event)
                  }
                }
              },
              [_vm._v("Clear Action")]
            )
          : _vm._e(),
        _c(
          "b-button",
          { attrs: { variant: "danger" }, on: { click: _vm.cancelSubmission } },
          [
            _c("i", { staticClass: "fas fa-times mr-2" }),
            _vm._v("Cancel Submission")
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }