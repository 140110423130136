<template>
  <b-modal
    id="add-bureau-job-detail"
    ref="addBureauJobDetailModal"
    title="Add Bureau Job Detail"
    size="xl"
    hide-header-close
    static
    @shown="load"
    @ok="addJobDetail"
  >
    <div slot="modal-ok">Save</div>

    <div>
      <h3>AddBureauJobDetail.vue</h3>
    </div>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.bureauCustomerId">Bureau Customer</label>
      </b-col>
      <b-col sm="6">
        <vue-select
          single
          v-model="selectedBureauCustomer"
          :options="bureauCustomers"
          label="name"
          :closeOnSelect="true"
          @input="CheckBureauCustomerOnSelect"
        ></vue-select>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.paymentfile">Payment File</label>
      </b-col>
      <b-col sm="6">
        <b-form-input
          ref="filename"
          type="text"
          @input="onFilenameChanged()"
          v-model.trim="$v.jobDetailRecord.filename.$model"
        ></b-form-input>
        <span class="small" v-if="jobDetailRecord.filename.length > 0">Example: {{ parsedFilename }}</span>
        <span v-if="!filenameValid">
          <br />
          <span class="text-danger small">This filename is not valid</span>
        </span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="9" offset-sm="3">
        <label>
          <a class="text-primary small" @click="addLookup('[CUSTOMERREF]')">[CUSTOMERREF]</a>
          will be replace with Customer Reference
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label>
          <a class="text-primary small" @click="addLookup('[SUN]')">[SUN]</a>
          will be replace with customers Service Reference Number
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label>
          <a
            class="text-primary small"
            @click="addLookup('[DATETIME, DD-MM-YYYY]')"
          >[DATETIME, FORMAT]</a>
          will be replaced with the current formatted date/time
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label class="small">* and ? can be used as a wildcards</label>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.importSchema">Import Schema</label>
      </b-col>
      <b-col sm="5">
        <b-form-select v-model="jobDetailRecord.importSchema" :options="this.schemas"></b-form-select>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="jobDetailRecord.importSchema == 'Mapping'">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.importMapping">Mapping</label>
      </b-col>
      <b-col sm="5">
        <b-form-select v-model="jobDetailRecord.importMapping" :options="this.mappings"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'
import { maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import VueSelect from 'vue-select'

export default {
  mixins: [Utility, loading],
  components: {
    VueSelect
  },
  props: ['bureauJobId', 'bureauCustomers'],
  data () {
    return {
      jobDetailRecord: {
        filename: ''
      },
      mappings: [],

      parsedFilename: '',
      filenameValid: false,
      selectedBureauCustomer: {}
    }
  },

  async created () {
    console.log('modal this.bureauCustomers.display', this.bureauCustomers)
    console.log('modal this.bureauJobId', this.bureauJobId)
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('')
  },

  methods: {
    load () {
      // Loader
    },

    async addJobDetail () {
      var payload = {
        jobid: this.bureauJobId,
        filename: this.jobDetailRecord.filename,
        importschema: this.jobDetailRecord.importSchema,
        ImportMapping: this.jobDetailRecord.importMapping,
        bureaucustomerid: this.selectedBureauCustomer.id
      }
      console.log('payload')
      var data = JSON.stringify(payload)
      console.log('data', data)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/insertupdatebureaujobdetail', data, { showload: true })

      console.log('Persisted result', response)

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)

        if (this.cloning) {
          this.$router.push({ name: 'ManageJob', params: { id: this.response.bureauJobId } })
        } else {
          this.$emit('submit')
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },

    CheckBureauCustomerOnSelect (row) {
      console.log('BC Selected', row)
    },
    addLookup (lookup) {
      this.jobDetailRecord.filename += lookup
      this.onFilenameChanged()
      this.$refs.filename.$el.focus()
    },
    onFilenameChanged () {
      console.info('onFilenameChanged')
      let filename = this.jobDetailRecord.filename
      console.log('filename', filename)

      // Customer reference
      if (filename.includes('[CUSTOMERREF,')) {
        if (this.jobDetailRecord.customerReference !== 'undefined') {
          filename = filename.replace(/\[CUSTOMERREF\]/g, this.jobDetailRecord.customerReference)
        }
      }

      // Service user number
      if (filename.includes('[SUN,')) {
        if (this.jobDetailRecord.serviceUserNumber !== 'undefined') {
          filename = filename.replace(/\[SUN\]/g, this.selectedBureauCustomer.serviceUserNumber)
        }
      }

      // Date/Time
      if (filename.includes('[DATETIME,')) {
        const start = filename.indexOf('[DATETIME,')
        const end = filename.indexOf(']', start + 10)
        const stringToReplace = filename.substr(start, end - start + 1)
        console.info(`string to replace is: ${stringToReplace}`)
        const format = filename.substr(start + 11, end - start - 11)
        console.info(`format is "${format}"`)
        const value = moment().format(format)
        filename = filename.replace(stringToReplace, value)
        console.info(`${value}`)
      }
      this.parsedFilename = filename
      this.filenameValid = this.validateFilename(filename)
      console.info(`Parsed filename is: ${filename}`)
    },
    validateFilename (filename) {
      // Check length
      if (!filename || filename.length > 255) {
        console.log('1')
        return false
      }

      // Check for ..
      if (filename.includes('..')) {
        console.log('2')

        return false
      }

      // Check for \\
      if (filename.includes('\\\\')) {
        console.log('3')

        return false
      }

      // Check for ports
      if (/^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i.test(filename)) {
        console.log('4')

        return false
      }

      return true
    }
  },
  validations: {
    jobDetailRecord: {
      filename: { maxLength: maxLength(255) }
    }
  }

}

</script>
