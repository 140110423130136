var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Password Complexity Policy "),
                _c("help-icon", {
                  attrs: {
                    docPath:
                      "/administration/securitypolicy/passwordcomplexitypolicy/"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _vm._m(0),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-4 offset-md-1" },
                  [_vm._v("New password different to previous n passwords")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary" },
                      on: {
                        change: function($event) {
                          return _vm.$v.policy.numUnique.$touch()
                        }
                      },
                      model: {
                        value: _vm.$v.policy.enforceUnique.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.policy.enforceUnique, "$model", $$v)
                        },
                        expression: "$v.policy.enforceUnique.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.$v.policy.enforceUnique.$model
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.policy.numUnique.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-4 offset-md-1"
                          },
                          [_vm._v("Number of passwords in history")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.policy.numUnique.$model,
                                  expression: "$v.policy.numUnique.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number", min: "1", max: "10" },
                              domProps: {
                                value: _vm.$v.policy.numUnique.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.policy.numUnique,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _c("validation-messages", {
                              model: {
                                value: _vm.$v.policy.numUnique,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.policy, "numUnique", $$v)
                                },
                                expression: "$v.policy.numUnique"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-4 offset-md-1" },
                  [_vm._v("Enforce retrospectively")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.$v.policy.enforceRetrospectively.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.enforceRetrospectively,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.enforceRetrospectively.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-4 offset-md-1" },
                  [
                    _vm._v(
                      "Exclude specific users from password complexity rules"
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.$v.policy.excludeSpecificUsers.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.excludeSpecificUsers,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.excludeSpecificUsers.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.$v.policy.excludeSpecificUsers.$model
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-4 offset-md-1" },
                        [
                          _vm._v(
                            "Exclude specific users from password complexity rules"
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-5" },
                        [
                          _c("vue-select", {
                            attrs: {
                              multiple: "",
                              options: _vm.users,
                              closeOnSelect: false
                            },
                            model: {
                              value: _vm.$v.policy.excludedUsers.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.policy.excludedUsers,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.policy.excludedUsers.$model"
                            }
                          }),
                          _c("validation-messages", {
                            model: {
                              value: _vm.$v.policy.excludedUsers,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.policy, "excludedUsers", $$v)
                              },
                              expression: "$v.policy.excludedUsers"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-7 offset-md-1" },
                  [
                    _c(
                      "p-radio",
                      {
                        staticClass: "p-default p-round",
                        attrs: { value: "Default", name: "complexityOption" },
                        model: {
                          value: _vm.$v.policy.complexityOption.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.policy.complexityOption,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.policy.complexityOption.$model"
                        }
                      },
                      [_vm._v("Use Standard Complexity")]
                    )
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.$v.policy.complexityOption.$model == "Default"
                  ? _c("div", { staticClass: "pl-5" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { invalid: _vm.$v.policy.minLength.$error }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-4 offset-md-1"
                              },
                              [_vm._v("Minimum length")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-1" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch p-fill",
                                  attrs: { color: "primary", disabled: "" },
                                  model: {
                                    value:
                                      _vm.$v.policy.enforceMinLength.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.policy.enforceMinLength,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.policy.enforceMinLength.$model"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.$v.policy.minLength.$model,
                                    expression: "$v.policy.minLength.$model",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number", min: "7", max: "20" },
                                domProps: {
                                  value: _vm.$v.policy.minLength.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.policy.minLength,
                                      "$model",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-2 offset-md-6" },
                              [
                                _c("validation-messages", {
                                  model: {
                                    value: _vm.$v.policy.minLength,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$v.policy, "minLength", $$v)
                                    },
                                    expression: "$v.policy.minLength"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { invalid: _vm.$v.policy.maxLength.$error }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-4 offset-md-1"
                              },
                              [_vm._v("Maximum length")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-1" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch p-fill",
                                  attrs: { color: "primary" },
                                  on: {
                                    change: function($event) {
                                      return _vm.$v.policy.maxLength.$touch()
                                    }
                                  },
                                  model: {
                                    value:
                                      _vm.$v.policy.enforceMaxLength.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.policy.enforceMaxLength,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.policy.enforceMaxLength.$model"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.$v.policy.maxLength.$model,
                                    expression: "$v.policy.maxLength.$model",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  min: "7",
                                  max: "20",
                                  disabled: !_vm.$v.policy.enforceMaxLength
                                    .$model
                                },
                                domProps: {
                                  value: _vm.$v.policy.maxLength.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.policy.maxLength,
                                      "$model",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-2 offset-md-6" },
                              [
                                _c("validation-messages", {
                                  model: {
                                    value: _vm.$v.policy.maxLength,
                                    callback: function($$v) {
                                      _vm.$set(_vm.$v.policy, "maxLength", $$v)
                                    },
                                    expression: "$v.policy.maxLength"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { invalid: _vm.$v.policy.numUppercase.$error }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-4 offset-md-1"
                              },
                              [_vm._v("Include at least n upper-case letters")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-1" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch p-fill",
                                  attrs: { color: "primary" },
                                  on: {
                                    change: function($event) {
                                      return _vm.$v.policy.numUppercase.$touch()
                                    }
                                  },
                                  model: {
                                    value:
                                      _vm.$v.policy.enforceUppercase.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.policy.enforceUppercase,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.policy.enforceUppercase.$model"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.$v.policy.numUppercase.$model,
                                    expression: "$v.policy.numUppercase.$model",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  disabled: !_vm.$v.policy.enforceUppercase
                                    .$model
                                },
                                domProps: {
                                  value: _vm.$v.policy.numUppercase.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.policy.numUppercase,
                                      "$model",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-2 offset-md-6" },
                              [
                                _c(
                                  "validation-messages",
                                  {
                                    model: {
                                      value: _vm.$v.policy.numUppercase,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.policy,
                                          "numUppercase",
                                          $$v
                                        )
                                      },
                                      expression: "$v.policy.numUppercase"
                                    }
                                  },
                                  [
                                    !_vm.$v.policy.numUppercase.maxValueComplex
                                      ? _c(
                                          "small",
                                          { staticClass: "form-text small" },
                                          [
                                            _vm._v(
                                              "The combination of upper-case, numeric and non-alphanumeric characters must be lower than the max length"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { invalid: _vm.$v.policy.numNumbers.$error }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-4 offset-md-1"
                              },
                              [_vm._v("Include at least n numeric characters")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-1" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch p-fill",
                                  attrs: { color: "primary" },
                                  model: {
                                    value: _vm.policy.enforceNumbers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.policy,
                                        "enforceNumbers",
                                        $$v
                                      )
                                    },
                                    expression: "policy.enforceNumbers"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.$v.policy.numNumbers.$model,
                                    expression: "$v.policy.numNumbers.$model",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  disabled: !_vm.$v.policy.enforceNumbers.$model
                                },
                                domProps: {
                                  value: _vm.$v.policy.numNumbers.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.policy.numNumbers,
                                      "$model",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-2 offset-md-6" },
                              [
                                _c(
                                  "validation-messages",
                                  {
                                    model: {
                                      value: _vm.$v.policy.numNumbers,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.policy,
                                          "numNumbers",
                                          $$v
                                        )
                                      },
                                      expression: "$v.policy.numNumbers"
                                    }
                                  },
                                  [
                                    !_vm.$v.policy.numNumbers.maxValueComplex
                                      ? _c(
                                          "small",
                                          { staticClass: "form-text small" },
                                          [
                                            _vm._v(
                                              "The combination of upper-case, numeric and non-alphanumeric characters must be lower than the max length"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: {
                            invalid: _vm.$v.policy.numNonAlphaNumerics.$error
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-4 offset-md-1"
                              },
                              [
                                _vm._v(
                                  "Include at least n non-alphanumeric characters"
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-1" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch p-fill",
                                  attrs: { color: "primary" },
                                  model: {
                                    value:
                                      _vm.$v.policy.enforceNonAlphaNumeric
                                        .$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.policy.enforceNonAlphaNumeric,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.policy.enforceNonAlphaNumeric.$model"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.$v.policy.numNonAlphaNumerics.$model,
                                    expression:
                                      "$v.policy.numNonAlphaNumerics.$model",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  disabled: !_vm.$v.policy
                                    .enforceNonAlphaNumeric.$model
                                },
                                domProps: {
                                  value:
                                    _vm.$v.policy.numNonAlphaNumerics.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.policy.numNonAlphaNumerics,
                                      "$model",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-2 offset-md-6" },
                              [
                                _c(
                                  "validation-messages",
                                  {
                                    model: {
                                      value: _vm.$v.policy.numNonAlphaNumerics,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.policy,
                                          "numNonAlphaNumerics",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.policy.numNonAlphaNumerics"
                                    }
                                  },
                                  [
                                    !_vm.$v.policy.numUppercase.maxValueComplex
                                      ? _c(
                                          "small",
                                          { staticClass: "form-text small" },
                                          [
                                            _vm._v(
                                              "The combination of upper-case, numeric and non-alphanumeric characters must be lower than the max length"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-7 offset-md-1" },
                  [
                    _c(
                      "p-radio",
                      {
                        staticClass: "p-default p-round",
                        attrs: { value: "Regex", name: "complexityOption" },
                        model: {
                          value: _vm.policy.complexityOption,
                          callback: function($$v) {
                            _vm.$set(_vm.policy, "complexityOption", $$v)
                          },
                          expression: "policy.complexityOption"
                        }
                      },
                      [_vm._v("Use Regular Expression")]
                    )
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.policy.complexityOption == "Regex"
                  ? _c("div", { staticClass: "pl-5" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.$v.policy.regularExpression.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label col-md-2 offset-md-1"
                            },
                            [_vm._v("Regex")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.$v.policy.regularExpression.$model,
                                    expression:
                                      "$v.policy.regularExpression.$model"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.$v.policy.regularExpression.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.policy.regularExpression,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "validation-messages",
                                {
                                  model: {
                                    value: _vm.$v.policy.regularExpression,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.policy,
                                        "regularExpression",
                                        $$v
                                      )
                                    },
                                    expression: "$v.policy.regularExpression"
                                  }
                                },
                                [
                                  !_vm.$v.policy.regularExpression.isRegex
                                    ? _c(
                                        "small",
                                        { staticClass: "form-text small" },
                                        [_vm._v("Please enter a valid regex")]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-2" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-secondary",
                                attrs: { type: "button" },
                                on: { click: _vm.resetRegularExpression }
                              },
                              [_vm._v("Default")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.regexValid != null && !_vm.regexValid,
                            valid: _vm.regexValid != null && _vm.regexValid
                          }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label col-md-2 offset-md-1"
                            },
                            [_vm._v("Test Sample")]
                          ),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regexSample,
                                  expression: "regexSample"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.regexSample },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.regexSample = $event.target.value
                                  },
                                  _vm.testRegex
                                ]
                              }
                            }),
                            _vm.regexValid != null && !_vm.regexValid
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text small validation-messages"
                                  },
                                  [_vm._v("The Regex doesn't match the sample")]
                                )
                              : _vm._e(),
                            _vm.regexValid != null && _vm.regexValid
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text small validation-messages"
                                  },
                                  [_vm._v("The Regex matches the sample")]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-2 offset-md-1"
                          },
                          [_vm._v("Message on mis-match")]
                        ),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.policy.regExFailMessage,
                                expression: "policy.regExFailMessage"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", rows: "3" },
                            domProps: { value: _vm.policy.regExFailMessage },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.policy,
                                  "regExFailMessage",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-form-label col-md-12" }, [
        _vm._v(
          "Also known as Password Strength, Password Complexity determines how strong user passwords are. These rules are used when a user tries to change their password or a new password is set for the user. Password complexity can be controlled either using the standard options of password min/max length, number of upper case letters etc., alternatively you can use a regular expression to validate the password complexity."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }