var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "idb-block" },
    [
      _c("div", { staticClass: "idb-block-title" }, [
        _c("h2", [_c("help-icon"), _c("favourite-icon")], 1)
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "dropzone",
                    {
                      ref: "fileUploader",
                      attrs: {
                        id: "my-dropzone",
                        options: _vm.dropzoneOptions,
                        "use-custom-slot": true,
                        "max-file-size-in-m-b": 200,
                        useCustomSlot: true
                      },
                      on: {
                        "vdropzone-success": _vm.afterSuccess,
                        "vdropzone-file-added": _vm.fileAdded,
                        "vdropzone-removed-file": _vm.fileRemoved,
                        "vdropzone-sending": _vm.sendingEvent
                      }
                    },
                    [
                      _c("div", { staticClass: "dropzone-custom-content" }, [
                        _c("h3", { staticClass: "dropzone-custom-title" }, [
                          _vm._v(
                            "Drag and drop to upload your files for processing"
                          )
                        ]),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v(
                            "...or click to select a file from your computer"
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "vue-good-table",
            {
              ref: "submissionFilesTable",
              attrs: {
                paginationOptions: {
                  enabled: true,
                  perPage: 10
                },
                rows: _vm.submissionDetails,
                columns: _vm.columns,
                lineNumbers: true,
                "sort-options": {
                  enabled: true,
                  initialSortBy: { field: "paymentFilename", type: "asc" }
                },
                styleClass: "vgt-table striped bordered"
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "bureauCustomerName"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "11" } },
                                    [
                                      _c("model-list-select", {
                                        attrs: {
                                          id:
                                            "customerSelector-" +
                                            props.row.bureauSubmissionDetailId,
                                          list: _vm.bureauCustomers,
                                          "option-value": "bureauJobDetailId",
                                          "option-text": "customerName",
                                          placeholder: "Select Customer",
                                          isDisabled:
                                            props.row.status != "Uploaded" ||
                                            props.row.schema == "Standard18"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateSubmissionDetail(
                                              props.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: props.row.bureauJobDetailId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              props.row,
                                              "bureauJobDetailId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.row.bureauJobDetailId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm primary mt-1 reset-customer",
                                      attrs: {
                                        hidden:
                                          props.row.status != "Uploaded" ||
                                          props.row.schema == "Standard18",
                                        disabled: _vm.isLoading
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onResetSelectedCustomer(
                                            props.row
                                          )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-times" })]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : props.column.field == "schema"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12" } },
                                    [
                                      _c("model-list-select", {
                                        attrs: {
                                          id:
                                            "schemaSelector-" +
                                            props.row.bureauSubmissionDetailId,
                                          list: _vm.schemas,
                                          "option-value": "value",
                                          "option-text": "text",
                                          isDisabled:
                                            props.row.status != "Uploaded",
                                          placeholder: "Schema"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateSubmissionDetail(
                                              props.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: props.row.schema,
                                          callback: function($$v) {
                                            _vm.$set(props.row, "schema", $$v)
                                          },
                                          expression: "props.row.schema"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : props.column.field == "mapping"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12" } },
                                    [
                                      _c("model-list-select", {
                                        attrs: {
                                          id:
                                            "mappingSelector-" +
                                            props.row.bureauSubmissionDetailId,
                                          list: _vm.mappings,
                                          "option-value": "value",
                                          "option-text": "text",
                                          isDisabled:
                                            props.row.status != "Uploaded" ||
                                            props.row.schema != "Mapping" ||
                                            props.row.bureauJobDetailId ==
                                              "00000000-0000-0000-0000-000000000000",
                                          placeholder: "Mapping"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateSubmissionDetail(
                                              props.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: props.row.mapping,
                                          callback: function($$v) {
                                            _vm.$set(props.row, "mapping", $$v)
                                          },
                                          expression: "props.row.mapping"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : props.column.field == "delete"
                        ? _c("span", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-sm primary mt-1",
                                attrs: { disabled: _vm.isLoading },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onDeleteFileClick(props.row)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            )
                          ])
                        : _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { sm: "12" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "loadingContent" }, [
                _c("h1", [_vm._v("Loading...")])
              ]),
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Refresh the data in the table",
                          expression: "'Refresh the data in the table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-sync pointer dimmedIcon" })]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Print out the contents of the Logs table",
                          expression:
                            "'Print out the contents of the Logs table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.printTable($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-print pointer dimmedIcon" })]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Export the contents of the Logs table",
                          expression: "'Export the contents of the Logs table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.exportTable($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-export pointer dimmedIcon"
                      })
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary ml-1",
            attrs: { disabled: _vm.isLoading },
            on: {
              click: function($event) {
                return _vm.onImportClick()
              }
            }
          },
          [_vm._v("Import/Validate")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary ml-2",
            attrs: { disabled: _vm.isLoading },
            on: {
              click: function($event) {
                return _vm.onSubmissionDetailsClick()
              }
            }
          },
          [_vm._v("Submission Details")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary ml-2",
            attrs: { disabled: _vm.isLoading },
            on: {
              click: function($event) {
                return _vm.onViewPaymentsClick()
              }
            }
          },
          [_vm._v("View Payments")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-success ml-2",
            attrs: { disabled: _vm.isLoading },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onStartSubmissionClick()
              }
            }
          },
          [_vm._v("Start Submission Process")]
        )
      ]),
      _c("b-modal", {
        ref: "submissionDetailsModal",
        attrs: {
          id: "submissionDetailsModal",
          title: "Submission Details",
          size: "lg",
          "hide-header-close": "",
          lazy: ""
        }
      }),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }