<template>
  <div>
    <form>

      <!-- SFTP Password Requests -->
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Agent SFTP Password Requests</h2>
        </div>

        <div class="idb-block-content">
          <b-row>
              <b-col>
                <vue-good-table
                  :rows="passwordRequestItems"
                  :columns="passwordRequestColumns"
                  @on-row-click="onPasswordRequestRowClick"
                  :sort-options="{ enabled: false }"
                  styleClass="vgt-table condensed bordered">

                  <div slot="table-actions">
                    <button
                      @click.prevent="loadPasswordRequests"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Refresh the data in the table'">
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </button>
                  </div>

                </vue-good-table>
              </b-col>
          </b-row>
          <b-row v-if="passwordRequestItem.customerName != ''" class="mt-3">
            <b-col sm="2"><label for="passwordRequestItem.ftpFolder">FTP Folder</label></b-col>
            <b-col sm="3"><b-form-input type="text" readonly v-model.trim="passwordRequestItem.ftpFolder"></b-form-input></b-col>
          </b-row>
          <b-row v-if="passwordRequestItem.customerName != ''" class="mt-3">
            <b-col sm="2"><label for="passwordRequestItem.customerName">Customer</label></b-col>
            <b-col sm="3"><b-form-input type="text" readonly v-model.trim="passwordRequestItem.customerName"></b-form-input></b-col>
          </b-row>
          <b-row v-if="passwordRequestItem.customerName != ''" class="mt-3">
            <b-col sm="2"><label for="passwordRequestItem.userName">Requested by</label></b-col>
            <b-col sm="3"><b-form-input type="text" readonly v-model.trim="passwordRequestItem.userName"></b-form-input></b-col>
          </b-row>
          <b-row v-if="passwordRequestItem.customerName != ''" class="mt-3">
            <b-col sm="2"><label for="passwordRequestItem.sftpUser">SFTP User Name</label></b-col>
            <b-col sm="3"><b-form-input type="text" readonly v-model.trim="passwordRequestItem.sftpUser"></b-form-input></b-col>
            <b-col sm="2"><b-button variant="info" @click="copySftpUsername" :disabled="isCopyUsernameDisabled">Copy to clipboard</b-button></b-col>
          </b-row>
          <b-row v-if="passwordRequestItem.customerName != ''" class="mt-3">
            <b-col sm="2"><label for="passwordRequestItem.password">Password</label></b-col>
            <b-col sm="3"><b-form-input type="text" v-model.trim="passwordRequestItem.password"></b-form-input></b-col>
            <b-col sm="4">
              <b-button variant="info" @click="copyPassword" :disabled="isCopyPasswordDisabled">Copy to clipboard</b-button>
              <b-button variant="success" class="ml-2" @click="generatePassword">Generate Password</b-button>
            </b-col>
          </b-row>
          <b-row v-if="passwordRequestItem.customerName != ''" class="mt-3">
            <b-col sm="2"><label for="passwordRequestItem.pubkey">Public Key</label></b-col>
            <b-col sm="6">
              <b-form-textarea
                v-model="passwordRequestItem.pubkey"
                rows="9"
                max-rows="9"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </div>

        <div v-if="passwordRequestItem.customerName != ''" class="idb-block-footer ">
          <b-row>
            <b-col>
              <b-button variant="info" @click="savePublicKey"><i class="fas fa-save mr-2"></i>Save Public Key</b-button>
              <b-button class="ml-3" variant="primary" @click="sendPasswordEmail">Send email and mark complete</b-button>
              <b-button variant="danger" class="pull-right" @click="removeSFTPAccountRequest">Remove</b-button>
            </b-col>
          </b-row>
        </div>

      </div>

      <!-- SFTP IP Address Approvals -->
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>SFTP IP Address Approvals</h2>
        </div>

        <div class="idb-block-content">
          <b-row>
              <b-col>
                <vue-good-table
                  :rows="whiteListItems"
                  :columns="whiteListColumns"
                  @on-row-click="onWhiteListRowClick"
                  :sort-options="{ enabled: false }"
                  :search-options="{ enabled: true }"
                  styleClass="vgt-table condensed bordered">

                  <div slot="table-actions">
                    <button
                      @click.prevent="loadWhiteList"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Refresh the data in the table'">
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </button>
                  </div>

                </vue-good-table>
              </b-col>
          </b-row>
        </div>

        <div v-if="whiteListItem.customerName != ''" class="idb-block-footer ">
          <b-row>
            <b-col>
              <span class="ml-3">
                {{whiteListItem.customerName}} - {{whiteListItem.ipText}}
              </span>
              <b-button class="ml-3"
                        variant="warning "
                        :hidden="whiteListItem.active===false"
                        @click="setWhiteListItemState(false)">Disable</b-button>
              <b-button class="ml-3"
                        variant="primary"
                        :hidden="whiteListItem.active===true">Enable</b-button>
              <b-button class="ml-3"
                        variant="warning "
                        :hidden="whiteListItem.active===true">Reject</b-button>
              <b-button variant="danger"
                        class="pull-right"
                        :hidden="whiteListItem.active===false">Remove</b-button>
            </b-col>
          </b-row>
        </div>

      </div>

        <!-- AWS Security Groups -->
        <div class="idb-block">

        <div class="idb-block-title">
          <h2>AWS Security Groups</h2>
        </div>

        <div class="idb-block-content">
          <b-row>
            <b-col cols="3"><b-form-select v-model="staffName" :options="paygateStaff" @change="onPaygateStaffChange"></b-form-select></b-col>
            <b-col cols="3"><b-form-input v-model="newIpAddress" placeholder="Enter new IP address"></b-form-input></b-col>
            <b-button class="ml-3"
                      variant="primary"
                      @click="onSetSecurityGroupIPAddress()">Set IP Address</b-button>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <vue-good-table
                :rows="securityGroups"
                :columns="securityGroupColumns"
                styleClass="vgt-table condensed bordered">

                <div slot="table-actions">
                  <button
                    @click.prevent="onPaygateStaffChange"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </button>
                </div>

              </vue-good-table>
            </b-col>
          </b-row>
        </div>

      </div>

      <!-- AWS Single Instance Containers -->
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>AWS Single Instance Containers (ONLY CHANGE IF YOU KNOW IT'S CORRECT)</h2>
          <small>
            <i>This controls whether containers will automatically restart, e.g. BACS</i><br>
            <i>Single instance containers will need their Active status resetting before they will restart</i>
          </small>
        </div>

        <div class="idb-block-content">
          <b-row class="mt-2">
            <b-col>
              <vue-good-table
                ref="ContainerInstanceStateTable"
                :rows="containerInstanceStateItems"
                :columns="containerInstanceStateColumns"
                styleClass="vgt-table condensed bordered"
                :selectOptions="{
                  enabled: true,
                  selectOnCheckboxOnly: false
                }"
                :row-style-class="rowStyleClassFn">

                <div slot="table-actions">
                  <button
                    @click.prevent="loadContainerInstanceState"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </button>
                </div>

                <div slot="selected-row-actions">
                  <b-button class="ml-3"
                            variant="primary"
                            size="sm"
                            @click="onToggleIsSingleInstanceClick">Toggle Single Instance</b-button>
                  <b-button class="ml-3"
                            variant="primary"
                            size="sm"
                            @click="onToggleInstanceActiveClick">Toggle State</b-button>
                </div>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>

      </div>

    </form>

  </div>
</template>

<script>
import axios from 'axios'
import Moment from 'moment'
import { saveAs } from 'file-saver'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  data () {
    return {
      passwordRequestColumns: [
        { label: 'Request Id', field: 'requestId', hidden: true },
        { label: 'Paygate Id', field: 'paygateId', hidden: true },
        { label: 'User Id', field: 'userId', hidden: true },
        { label: 'Public Key', field: 'pubkey', hidden: true },
        { label: 'FTP Folder', field: 'ftpFolder', hidden: true },
        { label: 'Customer', field: 'customerName' },
        { label: 'Requested by', field: 'userName' },
        { label: 'Email', field: 'userEmail' },
        { label: 'SFTP User', field: 'sftpUser' },
        {
          label: 'Time Requested',
          field: 'timeRequested',
          type: 'date',
          formatFn: function (value) {
            return value != null ? Moment(value, 'YYYY-MM-DDTHH:mm:ss').format('Do MMMM YYYY HH:mm:ss') : null
          }
        }
      ],
      whiteListColumns: [
        { label: 'Paygate Id', field: 'paygateId', hidden: true },
        { label: 'Customer Name', field: 'customerName', hidden: false },
        { label: 'IP Text', field: 'ipText', hidden: false },
        { label: 'Active', field: 'active', hidden: false }
      ],
      securityGroupColumns: [
        { label: 'Account', field: 'account' },
        { label: 'Security Group', field: 'securityGroupName' },
        { label: 'IP Address', field: 'ipAddress' },
        { label: 'Protocol', field: 'protocol' },
        { label: 'Port', field: 'fromPort' }
      ],
      containerInstanceStateColumns: [
        { label: 'Name', field: 'containerName' },
        { label: 'Single Instance', field: 'singleInstance', type: 'boolean', sortable: false },
        { label: 'Active', field: 'active', type: 'boolean', sortable: false }
      ],
      staffName: '',
      newIpAddress: '',
      paygateStaff: [],
      passwordRequestItems: [],
      whiteListItems: [],
      securityGroups: [],
      containerInstanceStateItems: [],
      passwordRequestItem: {
        requestId: '',
        paygateId: '',
        pubkey: '',
        userId: '',
        customerName: '',
        ftpFolder: '',
        userName: '',
        userEmail: '',
        sftpUser: '',
        timeRequested: '',
        password: ''
      },
      whiteListItem: {
        paygateId: '',
        customerName: '',
        ipText: '',
        active: ''
      }
    }
  },
  async created () {
    await this.loadPasswordRequests()
    await this.loadWhiteList()
    await this.loadStaffList()
    await this.loadContainerInstanceState()
  },
  computed: {
    isCopyUsernameDisabled () {
      return (this.passwordRequestItem.sftpUser === null || this.passwordRequestItem.sftpUser.length === 0)
    },
    isCopyPasswordDisabled () {
      return (this.passwordRequestItem.password === null || this.passwordRequestItem.password.length === 0)
    }
  },
  methods: {
    async loadPasswordRequests () {
      var response = await axios.get(`${process.env.VUE_APP_DEVOPS_API_URL}SFTPDetails`)
      this.passwordRequestItems = response.data
      this.passwordRequestItem = {
        requestId: '',
        paygateId: '',
        pubkey: '',
        userId: '',
        customerName: '',
        ftpFolder: '',
        userName: '',
        userEmail: '',
        sftpUser: '',
        timeRequested: '',
        password: ''
      }
    },
    async loadWhiteList () {
      var response = await axios.get(`${process.env.VUE_APP_DEVOPS_API_URL}SFTPWhiteList`)
      this.whiteListItems = response.data
      this.whiteListItem = {
        paygateId: '',
        customerName: '',
        ipText: '',
        active: ''
      }
    },
    async loadStaffList () {
      var response = await axios.get(`${process.env.VUE_APP_DEVOPS_API_URL}SecurityGroup/GetStaffList`)
      console.log(response)
      this.paygateStaff = response.data
      this.staffName = ''
    },
    async loadContainerInstanceState () {
      var response = await axios.get(`${process.env.VUE_APP_DEVOPS_API_URL}Container`)
      console.log(response)
      this.containerInstanceStateItems = response.data
    },
    async onToggleInstanceActiveClick () {
      var toastr = this.$toastr
      this.$refs.ContainerInstanceStateTable.selectedRows.forEach(async row => {
        console.log(row.containerName)
        row.active = !row.active
        var response = await axios.put(`${process.env.VUE_APP_DEVOPS_API_URL}Container/SetState`, row)
        console.log(response)
        toastr.s(`${row.containerName} state has been to to ${row.active}`, 'Container State')
      })
      this.loadContainerInstanceState()
    },
    async onToggleIsSingleInstanceClick () {
      var toastr = this.$toastr
      this.$refs.ContainerInstanceStateTable.selectedRows.forEach(async row => {
        console.log(row.containerName)
        row.singleInstance = !row.singleInstance
        var response = await axios.put(`${process.env.VUE_APP_DEVOPS_API_URL}Container/SetSingleInstance`, row)
        console.log(response)
        toastr.s(`${row.containerName} single instance has been set to ${row.singleInstance}`, 'Container Single Instance')
      })
      this.loadContainerInstanceState()
    },
    onPasswordRequestRowClick (params) {
      document.activeElement.blur()
      this.passwordRequestItem = {
        requestId: params.row.requestId,
        paygateId: params.row.paygateId,
        userId: params.row.userId,
        pubkey: params.row.pubkey,
        customerName: params.row.customerName,
        ftpFolder: params.row.ftpFolder,
        userName: params.row.userName,
        userEmail: params.row.userEmail,
        sftpUser: params.row.sftpUser,
        timeRequested: params.row.timeRequested,
        password: ''
      }
    },
    onWhiteListRowClick (params) {
      document.activeElement.blur()
      this.whiteListItem = {
        paygateId: params.row.paygateId,
        customerName: params.row.customerName,
        ipText: params.row.ipText,
        active: params.row.active
      }
    },
    async onPaygateStaffChange () {
      console.log(this.staffName)
      var response = await axios.get(`${process.env.VUE_APP_DEVOPS_API_URL}SecurityGroup/GetByName/${this.staffName}`)
      this.securityGroups = response.data
      console.log(response)
    },
    async onSetSecurityGroupIPAddress () {
      var toastr = this.$toastr

      if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.newIpAddress)) {
        toastr.e('Invalid IP address', 'Security Group Request')
        return
      }

      console.log(`Setting IP address ${this.newIpAddress} for ${this.staffName}`)
      var updateIPAddressRequest = {
        Name: this.staffName,
        ToIPAddress: this.newIpAddress
      }
      await axios.put(`${process.env.VUE_APP_DEVOPS_API_URL}SecurityGroup/UpdateIPAddressByName`, updateIPAddressRequest)
        .then((response) => {
          toastr.s('New IP address has been set', 'Security Group Request')
          this.onPaygateStaffChange()
        })
        .catch((error) => {
          toastr.e('Failed to set new IP address', 'Security Group Request')
          console.log(error)
        })
    },
    savePublicKey () {
      var blob = new Blob([this.passwordRequestItem.pubkey], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${this.passwordRequestItem.sftpUser.replace('@', '-')}_pub.pub`)
    },
    copySftpUsername () {
      var toastr = this.$toastr
      navigator.clipboard.writeText(this.passwordRequestItem.sftpUser).then(function () {
        toastr.s('The SFTP Username has been copied to the clipboard', 'SFTP Username Copied')
      })
    },
    async generatePassword () {
      var response = await axios.get(`${process.env.VUE_APP_DEVOPS_API_URL}SFTPDetails/GeneratePassword`)
      this.passwordRequestItem.password = response.data
    },
    copyPassword () {
      var toastr = this.$toastr
      navigator.clipboard.writeText(this.passwordRequestItem.sftpUser).then(function () {
        toastr.s('The password has been copied to the clipboard', 'Password Copied')
      })
    },
    async sendPasswordEmail () {
      var toastr = this.$toastr
      const setPasswordRequest = {
        requestId: this.passwordRequestItem.requestId,
        paygateId: this.passwordRequestItem.paygateId,
        userId: this.passwordRequestItem.userId,
        password: this.passwordRequestItem.password
      }
      console.log(setPasswordRequest)
      await axios.post(`${process.env.VUE_APP_DEVOPS_API_URL}SFTPDetails/SetPassword`, setPasswordRequest)
        .then((response) => {
          toastr.s('The SFTP account request email has been sent', 'SFTP Account Request')
        })
        .catch((error) => {
          toastr.e('Failed to send SFTP account request email', 'SFTP Account Request')
          console.log(error)
        })

      this.loadPasswordRequests()
    },
    async removeSFTPAccountRequest () {
      var toastr = this.$toastr

      try {
        // Get confirmation from the user that they really want to delete the request
        var swalResult = await swal.fire({
          title: 'Delete SFTP Account Request',
          text: 'Are you sure you want to delete this request?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      const removeAccountRequest = {
        requestId: this.passwordRequestItem.requestId,
        paygateId: this.passwordRequestItem.paygateId,
        userId: this.passwordRequestItem.userId
      }

      console.log(removeAccountRequest)
      await axios.post(`${process.env.VUE_APP_DEVOPS_API_URL}SFTPDetails/DeletePasswordRequest`, removeAccountRequest)
        .then((response) => {
          toastr.s('The SFTP Account request has been removed', 'SFTP Account Request')
        })
        .catch((error) => {
          toastr.e('Failed to remove SFTP account request', 'SFTP Account Request')
          console.log(error)
        })

      this.loadPasswordRequests()
    },
    async setWhiteListItemState (state) {
      var toastr = this.$toastr
      var params = {
        paygateId: this.whiteListItem.paygateId,
        ipText: this.whiteListItem.ipText,
        active: state
      }
      var response = await axios.put(`${process.env.VUE_APP_DEVOPS_API_URL}SFTPWhiteList`, params)
      console.log(response)
      toastr.s(`${this.whiteListItem.ipText} has been ${state ? 'enabled' : 'disabled'}`, 'White List item state updated')
      this.loadWhiteList()
    },
    rowStyleClassFn (row) {
      return row.vgtSelected ? 'isselected' : ''
    }
  }
}
</script>

<style>
  .isselected {
    background-color: lightgray !important;
  }
</style>
