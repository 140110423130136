<template>
  <div>
     <b-form-row>
          <b-form-group
             :label-cols="5" class="col-sm-5"
            label-class="required"
            horizontal
            label="Plan Type"
          >
        <b-form-select
          id="selectPlanInput"
          class="form-control"
          @keyup.enter="addNewPaymentPlan"
          @change="addNewPaymentPlan"
          type="text"
          placeholder="New plan name..."
          :options="planNames"
          text-field="name"
          value-field="name"
          :disabled="outOfPlanNames"
          v-model="selectedPlanName"
        >
          <!-- This slot appears above the options from 'options' prop -->
          <template slot="first">
            <option :value="null" disabled>-- Please select an option --</option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-form-row>
    <payment-plan-input
        :plan="selectedPaymentPlan" ref="planControl"
        v-if="selectedPaymentPlan !== null && selectedPaymentPlan !== undefined"
        :createMode="true" @input="setValid"
        :label-cols="5" column-class="col-sm-5"
        :limitFirstPaymentDate="true"
        @isinvalid="setValidity"
      ></payment-plan-input>
      <div class="float-right pb-1 mr-2">
        Add another Payer on completion
        <p-check id="addAnotherCheckBox"
          class="p-switch p-fill"
          color="primary"
          v-model="addAnother"
        ></p-check>
      </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import Components from '@/Components/Collections'
import PrettyCheck from 'pretty-checkbox-vue/check'
export default {
  data () {
    return {
      planNames: [],
      outOfPlanNames: false,
      selectedPlanName: null,
      selectedPaymentPlan: null,
      planValid: true,
      groupConfig: {}
    }
  },
  methods: {
    checkPlanNames (input) {
      var plans = this.$store.state.payer.currentPayer.paymentPlans
      var usableNames = input
      for (var i = 0; i < plans.length; plans++) {
        if (usableNames.find(val => val.name === plans[i].planTemplateName)) {
          usableNames = usableNames.filter(item => item.name !== plans[i].planTemplateName)
        }
      }
      this.outOfPlanNames = usableNames.length === 0
      return usableNames
    },
    addNewPaymentPlan () {
      let selectedPlan
      let plan
      var newDate = new Date()
      newDate.setHours(0, 0, 0, 0)
      newDate.setDate(newDate.getDate() + this.advancedNoticeWithMedia)
      for (var i = 0; i < this.planNames.length; i++) {
        plan = this.planNames[i]
        if (plan && plan.name === this.selectedPlanName) {
          selectedPlan = this.planNames[i].plan
          selectedPlan.step = selectedPlan.step === null ? 0 : selectedPlan.step
          selectedPlan.reference = this.allowMultiplePlans ? `${this.$store.state.payer.reference} - 1` : this.$store.state.payer.reference
          selectedPlan.firstPaymentDate = newDate
          this.historicPlanCount = this.historicPlanCount + 1
          selectedPlan.futurePlanChanges = []
          selectedPlan.planTemplateName = plan.name
          selectedPlan.planStatus = 3
          selectedPlan.groupId = this.$store.state.payer.currentPayer.groupId
          selectedPlan.lastAmount = isNaN(selectedPlan.lastAmount) || selectedPlan.lastAmount === null ? 0 : selectedPlan.lastAmount
          selectedPlan.firstAmount = isNaN(selectedPlan.firstAmount) || selectedPlan.firstAmount === null ? 0 : selectedPlan.firstAmount
          selectedPlan.regularAmount = isNaN(selectedPlan.regularAmount) || selectedPlan.regularAmount === null ? 0 : selectedPlan.regularAmount
        }
      }
      if (this.$store.state.payer.currentPayer.paymentPlans.length === 0) {
        this.$store.state.payer.currentPayer.paymentPlans.push(selectedPlan)
      } else {
        this.$store.state.payer.currentPayer.paymentPlans[0] = selectedPlan
      }
      this.selectedPaymentPlan = this.$store.state.payer.currentPayer.paymentPlans[0]
    },
    setValid (val) {
      this.planValid = val
      this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
    },
    setValidity (e) {
      this.planValid = e.valid
    }
  },
  watch: {
    $v: {
      handler (val) {
        this._self.$emit('can-continue', { value: true })
        this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      },
      deep: true
    },
    clickedNext (val) {
      this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      this.$emit('can-continue', { value: true })
    },
    currentStep (val) {
      this.$emit('can-continue', { value: true })
    },
    selectedPaymentPlan: {
      handler (val) {
        this.$store.state.payer.currentPayer.paymentPlans[0] = val
        this.planValid = false
        if (!val.variable && val.recurrenceEndType < 4) {
          this.planValid = parseInt(val.regularAmount) > 0
        } else {
          this.planValid = true
        }
        if (val.recurrenceEndType < 4 && val.firstPaymentDate < this.minDate) {
          this.planValid = false
        }
        this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      },
      deep: true
    }
  },
  validations: {
    selectedPlanName: { required }
  },
  async mounted () {
    this.$emit('can-continue', { value: true })
    this.planNames = this.checkPlanNames(this.$store.state.payer.planNames)
  },
  components: {
    paymentPlanInput: Components.Payer.PaymentPlan,
    pCheck: PrettyCheck
  },
  props: {
    currentStep: Object
  },
  computed: {
    advancedNoticeWithMedia () {
      var notice = 0
      var groupConfig = this.$store.getters.collectionsGroupConfig
      if (groupConfig.advanceNoticeInDays) {
        notice = notice + groupConfig.advanceNoticeInDays
      }
      if (groupConfig.defaultPayerMessageType === 1) {
        notice = notice + 5
      }
      var weekendDays = 0
      for (var i = 0; i < notice; i++) {
        if (i % 5 === 0) {
          weekendDays = weekendDays + 2
        }
      }
      notice = notice + weekendDays
      return notice
    },
    addAnother: {
      get () { return this.$store.state.payer.addAnother },
      set (value) { this.$store.commit('updateAddAnother', value) }
    },
    allowMultiplePlans () {
      var groupConfig = this.$store.getters.collectionsGroupConfig
      return groupConfig.allowMultiplePlans
    },
    minDate () {
      let minDate = new Date()
      minDate.setDate(minDate.getDate() + this.advancedNoticeWithMedia - 1)
      return minDate
    }
  }
}
</script>
