var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0);", id: "app" },
                on: {
                  click: function($event) {
                    return _vm.clickBack()
                  }
                }
              },
              [_vm._v(" Log Entry ")]
            ),
            _vm._v(" / Event"),
            _c("span", { staticClass: "pull-right" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.docUrl + "/automation/logging/",
                    target: "_blank"
                  }
                },
                [_c("i", { staticClass: "far fa-question-circle" })]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", [
            _vm.workflowEvent.data
              ? _c(
                  "div",
                  [
                    _c("h4", [_vm._v("Event Data")]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "col-md-4" }, [
                        _vm._v(_vm._s(_vm.workflowEvent.title))
                      ]),
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Started")
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.reformatDate(_vm.workflowEvent.createdAt)
                            )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _vm.workflowEvent.currentStatus == "Failed"
                            ? _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "danger" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.workflowEvent.currentStatus)
                                  )
                                ]
                              )
                            : _vm.workflowEvent.currentStatus == "Complete"
                            ? _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "success" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.workflowEvent.currentStatus)
                                  )
                                ]
                              )
                            : _vm.workflowEvent.currentStatus ==
                              "Completed with warnings"
                            ? _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "warning" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.workflowEvent.currentStatus)
                                  )
                                ]
                              )
                            : _vm.workflowEvent.currentStatus == "Processing"
                            ? _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.workflowEvent.currentStatus)
                                  )
                                ]
                              )
                            : _vm.workflowEvent.currentStatus == "Paused"
                            ? _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "warning" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.workflowEvent.currentStatus)
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Last Updated")
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.reformatDate(_vm.workflowEvent.updatedAt)
                            )
                        )
                      ])
                    ]),
                    _c("br"),
                    _c("hr"),
                    _c("br"),
                    _c("h4", [_vm._v("Node Configuration")]),
                    _vm._l(this.workflowEvent.data.props, function(
                      item,
                      index
                    ) {
                      return _c("div", { key: index, staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("span", { staticClass: "ml-3" }),
                          _vm._v(_vm._s(item.title))
                        ]),
                        item.element && !item.element.startsWith("pw")
                          ? _c("div", { staticClass: "col-md-6" }, [
                              _vm._v(_vm._s(item.value))
                            ])
                          : _c("div", { staticClass: "col-md-6" }, [
                              _vm._v("********")
                            ])
                      ])
                    }),
                    _c("br"),
                    _c("hr"),
                    _c("br")
                  ],
                  2
                )
              : _vm._e(),
            _c("h4", [_vm._v("Log Messages")]),
            _c("div", [
              _vm._v("The individual log messages that this event created")
            ]),
            _c("br"),
            _c(
              "div",
              [
                _c(
                  "vue-good-table",
                  {
                    ref: "table",
                    attrs: {
                      paginationOptions: _vm.paginationOptions,
                      "sort-options": _vm.sortOptions,
                      isLoading: _vm.isTableLoading,
                      columns: _vm.columns,
                      rows: _vm.rows,
                      lineNumbers: true,
                      "search-options": { enabled: true },
                      styleClass: "vgt-table striped bordered table-hover"
                    },
                    on: {
                      "update:isLoading": function($event) {
                        _vm.isTableLoading = $event
                      },
                      "update:is-loading": function($event) {
                        _vm.isTableLoading = $event
                      },
                      "on-page-change": _vm.onPageChange,
                      "on-sort-change": _vm.onSortChange,
                      "on-column-filter": _vm.onColumnFilter,
                      "on-per-page-change": _vm.onPerPageChange,
                      "on-search": _vm.onSearch
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field == "datetime"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.reformatDate(
                                          props.formattedRow[props.column.field]
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.formattedRow[props.column.field]
                                      ) +
                                      " "
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "div",
                      {
                        attrs: { slot: "table-actions" },
                        slot: "table-actions"
                      },
                      [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Clear filters",
                                expression: "'Clear filters'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "btn btn-link",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.clearTableFilters($event)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fa-stack",
                                staticStyle: { "font-size": "10px" }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-filter fa-stack-1x dimmedIcon"
                                }),
                                _c("i", {
                                  staticClass:
                                    "fa fa-ban fa-stack-2x dimmedIcon"
                                })
                              ]
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Refresh the data in the table",
                                expression: "'Refresh the data in the table'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.load($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-sync-alt pointer dimmedIcon"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _vm._m(2)
      ])
    ]),
    _c("br"),
    _c("br")
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("span", { staticClass: "ml-3" }),
      _vm._v("Title")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("span", { staticClass: "ml-3" }),
      _vm._v("Status")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 col-sm-3" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }