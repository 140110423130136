var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _vm.approvalMethodWarning
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" }
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "Unable to action - " +
                          _vm._s(_vm.approvalMethodWarning)
                      )
                    ])
                  ]
                )
              : _vm._e()
          ]),
          _vm.action.rejected
            ? _c("div", { staticClass: "idb-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" }
                  },
                  [
                    _c("div", [
                      _vm._v("Rejected By: " + _vm._s(_vm.action.rejectedBy))
                    ]),
                    _c("div", [
                      _vm._v(
                        "Rejected On: " +
                          _vm._s(
                            _vm._f("formatDate")(
                              _vm.action.rejectedDate,
                              "DD/MM/YYYY HH:mm"
                            )
                          )
                      )
                    ]),
                    _vm.action.rejectionMessage
                      ? _c("div", [
                          _vm._v(" Message: "),
                          _c("br"),
                          _vm._v(
                            " " + _vm._s(_vm.action.rejectionMessage) + " "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _c("div", { staticClass: "idb-block" }, [
                _c(
                  "div",
                  { staticClass: "alert alert-info", attrs: { role: "alert" } },
                  [
                    _c("div", [
                      _vm._v("Action Created By: " + _vm._s(_vm.action.name))
                    ]),
                    _c("div", [
                      _vm._v(
                        "Action Created On: " +
                          _vm._s(
                            _vm._f("formatDate")(
                              _vm.action.createdDate,
                              "DD/MM/YYYY HH:mm"
                            )
                          )
                      )
                    ])
                  ]
                )
              ]),
          _vm._t("default"),
          _c("div", { staticClass: "idb-block" }, [
            !_vm.action.rejected
              ? _c("div", { staticClass: "idb-block-footer" }, [
                  !_vm.approvalMethodWarning
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { disabled: _vm.isLoading, type: "submit" },
                          on: {
                            click: function($event) {
                              return _vm.approve()
                            }
                          }
                        },
                        [_vm._v("Approve " + _vm._s(this.status))]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger pull-right",
                      attrs: { disabled: _vm.isLoading, type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.reject()
                        }
                      }
                    },
                    [_vm._v("Reject")]
                  )
                ])
              : _c("div", { staticClass: "idb-block-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.isLoading, type: "submit" },
                      on: {
                        click: function($event) {
                          return _vm.deleteRejection()
                        }
                      }
                    },
                    [_vm._v("Delete Action")]
                  )
                ])
          ])
        ],
        2
      ),
      _vm.action.id
        ? _c("actions-approve-modal", {
            ref: "modal",
            attrs: {
              type: _vm.approvalType,
              actionId: _vm.action.id,
              status: _vm.status,
              name: _vm.name,
              action: _vm.action
            },
            on: { submit: _vm.doneWithCode }
          })
        : _vm._e(),
      _c("plugin")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }