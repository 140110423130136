var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "90%" } },
    [
      _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
        _c("h4", { staticClass: "m-0 w-100" }, [
          _vm._v("Pending Submissons"),
          _c("i", {
            staticClass: "fas fa-sync-alt pull-right",
            attrs: { role: "button" },
            on: { click: _vm.populate }
          })
        ])
      ]),
      _vm.group !== null && _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("Group")]),
              _c("b-col", [_vm._v(_vm._s(_vm.groupName))])
            ],
            1
          )
        : _vm._e(),
      _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("AUDDIS")]),
              _c("b-col", [_vm._v(_vm._s(_vm.auddisCount))])
            ],
            1
          )
        : _vm._e(),
      _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("Payments")]),
              _c("b-col", [
                _vm._v(
                  _vm._s(
                    new Intl.NumberFormat("en-GB", {}).format(
                      _vm.collectionCount
                    )
                  )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("Value")]),
              _c("b-col", [
                _vm._v(
                  _vm._s(
                    new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP"
                    }).format(_vm.debitAmount)
                  )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.oneSquare
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.group !== null && !_vm.oneSquare
                ? _c("b-col", [
                    _c("h2", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.goToSub(true, false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.groupName))]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("b-col", [
                _c("div", { staticClass: "pos-relative" }, [
                  _c("h2", { class: _vm.auddisStyle }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToSub(false, true)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.numberFormatter(_vm.auddisCount)))]
                    )
                  ])
                ])
              ]),
              _c("b-col", [
                _c("div", { staticClass: "pos-relative" }, [
                  _c("h2", { class: _vm.ddiStyle }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToSub(false, false)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.numberFormatter(_vm.collectionCount)))]
                    )
                  ])
                ])
              ]),
              _c("b-col", [
                _c("div", { staticClass: "pos-relative" }, [
                  _c("h2", { class: _vm.ddiTotalStyle }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToSub(false, false)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "£" + _vm._s(_vm.numberFormatter(_vm.debitAmount))
                        )
                      ]
                    )
                  ]),
                  _vm.flaggedPayments > 0
                    ? _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass:
                          "fa fa-exclamation-circle text-danger align-top",
                        attrs: {
                          title:
                            _vm.flaggedPayments +
                            " payment(s) flagged for attention!"
                        }
                      })
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.oneSquare
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.group !== null && !_vm.oneSquare
                ? _c("b-col", [_c("span", { staticClass: "d-block" })])
                : _vm._e(),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(_vm._s(_vm.auddisCount))
                ])
              ]),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    _vm._s(
                      new Intl.NumberFormat("en-GB", {}).format(
                        _vm.collectionCount
                      )
                    )
                  )
                ])
              ]),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    _vm._s(
                      new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP"
                      }).format(_vm.debitAmount)
                    )
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.oneSquare
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.group !== null && !_vm.oneSquare
                ? _c("b-col", [
                    _c("span", { staticClass: "pb-10 d-block" }, [
                      _vm._v("Group")
                    ])
                  ])
                : _vm._e(),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [_vm._v("AUDDIS Count")])
              ]),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v("Direct Debit Count")
                ])
              ]),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v("Direct Debit Total")
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }