var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Actions "),
            _c("help-icon", { attrs: { docPath: "/common/actions" } })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                rows: _vm.rows,
                columns: _vm.columns,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "on-row-click": _vm.onRowClick,
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "icon"
                        ? _c("span", [
                            _c("span", [
                              _c("i", {
                                staticClass: "fa",
                                class: _vm.getIcon(props.row)
                              })
                            ])
                          ])
                        : props.column.field == "createdBy"
                        ? _c("span", [
                            _c("img", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover",
                                  value: props.row.createdByName,
                                  expression: "props.row.createdByName",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "rounded-circle",
                              attrs: {
                                src:
                                  _vm.platformApi +
                                  "User/GetAvatar/" +
                                  props.row.createdBy +
                                  "?access_token=" +
                                  _vm.getAccessToken,
                                width: "30",
                                height: "30"
                              }
                            })
                          ])
                        : props.column.field == "groupName"
                        ? _c("span", [
                            _c("div", {
                              staticClass: "group-colour",
                              style: {
                                "background-color": props.row.groupColour
                              }
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(props.formattedRow[props.column.field])
                              )
                            ])
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }