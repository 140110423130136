var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "manageBlockModal",
      attrs: {
        id: "manage-block-modal",
        title: "Block Details",
        size: "xl",
        "hide-header-close": "",
        static: ""
      },
      on: { ok: _vm.SaveBlockDetail, shown: _vm.loadBlockDetails }
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Save")
      ]),
      _c(
        "div",
        [
          _vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "customerName" } }, [
                      _vm._v("Originating Customer")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "7" } },
                    [
                      _c("model-list-select", {
                        attrs: {
                          list: _vm.bureauCustomers,
                          "option-value": "bureauCustomerId",
                          "option-text": "customerName",
                          placeholder:
                            "Select Customer or enter Service User Number",
                          disabled: _vm.readonly
                        },
                        on: {
                          input: function($event) {
                            return _vm.selectBureauCustomer()
                          }
                        },
                        model: {
                          value: _vm.$v.bureauCustomerId.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.bureauCustomerId, "$model", $$v)
                          },
                          expression: "$v.bureauCustomerId.$model"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isNew === false
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "customerName" } }, [
                      _vm._v("Originating Customer")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "7" } },
                    [
                      _c("b-form-input", {
                        attrs: { readonly: "", type: "text" },
                        model: {
                          value: _vm.$v.customerName.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.customerName,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.customerName.$model"
                        }
                      }),
                      _vm.$v.serviceUserNumber.$dirty
                        ? _c("span", [
                            !_vm.$v.serviceUserNumber.maxLength
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Service User Number must be less than " +
                                        _vm._s(
                                          _vm.$v.serviceUserNumber.$params
                                            .maxLength.max
                                        ) +
                                        " characters"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.serviceUserNumber.required
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [_vm._v("Service User Number is required")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "serviceUserNumber" }
                  },
                  [_vm._v("Service User Number")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "3" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", formatter: _vm.upperCase },
                    model: {
                      value: _vm.$v.serviceUserNumber.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.serviceUserNumber,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.serviceUserNumber.$model"
                    }
                  }),
                  _vm.$v.serviceUserNumber.$dirty
                    ? _c("span", [
                        !_vm.$v.serviceUserNumber.maxLength
                          ? _c(
                              "label",
                              { staticClass: "text-danger small text-nowrap" },
                              [
                                _vm._v(
                                  "Service User Number must be less than " +
                                    _vm._s(
                                      _vm.$v.serviceUserNumber.$params.maxLength
                                        .max
                                    ) +
                                    " characters"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.serviceUserNumber.required
                          ? _c(
                              "label",
                              { staticClass: "text-danger small text-nowrap" },
                              [_vm._v("Service User Number is required")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c(
                  "label",
                  { staticClass: "required", attrs: { for: "sortCode" } },
                  [_vm._v("Sort Code")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "2" } },
                [
                  _c("the-mask", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    ref: "sortCode",
                    staticClass: "form-control",
                    attrs: { type: "text", mask: ["##-##-##"], guide: true },
                    model: {
                      value: _vm.$v.sortCode.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.sortCode,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.sortCode.$model"
                    }
                  }),
                  _vm.$v.sortCode.$dirty
                    ? _c("span", [
                        !_vm.$v.sortCode.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [_vm._v("Sort Code is required")]
                            )
                          : _vm._e(),
                        !_vm.$v.sortCode.minLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Sort Code must be at least " +
                                    _vm._s(
                                      _vm.$v.sortCode.$params.minLength.min
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.sortCode.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Sort Code must be less than " +
                                    _vm._s(
                                      _vm.$v.sortCode.$params.maxLength.max
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.sortCodeValid && _vm.$v.sortCode.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-warning small text-nowrap"
                              },
                              [_vm._v(_vm._s(_vm.sortCodeErrorMessage))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm.sortCodeValid
                    ? _c("b-col", [
                        _c("i", { staticClass: "fas fa-check mt-2" })
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c(
                  "label",
                  { staticClass: "required", attrs: { for: "accountNumber" } },
                  [_vm._v("Account Number")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "2" } },
                [
                  _c("the-mask", {
                    ref: "originatingAccountNumber",
                    staticClass: "form-control",
                    attrs: { type: "text", mask: ["########"], guide: false },
                    model: {
                      value: _vm.$v.accountNumber.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.accountNumber,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.accountNumber.$model"
                    }
                  }),
                  _vm.$v.accountNumber.$dirty
                    ? _c("span", [
                        !_vm.$v.accountNumber.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [_vm._v("Account Number is required")]
                            )
                          : _vm._e(),
                        !_vm.$v.accountNumber.minLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Account Number must be at least " +
                                    _vm._s(
                                      _vm.$v.accountNumber.$params.minLength.min
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.accountNumber.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Account Number must be less than " +
                                    _vm._s(
                                      _vm.$v.accountNumber.$params.maxLength.max
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.accountNumberValid && _vm.$v.accountNumber.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-warning small text-nowrap"
                              },
                              [_vm._v(_vm._s(_vm.accountNumberErrorMessage))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm.accountNumberValid == true
                    ? _c("b-col", [
                        _c("i", { staticClass: "fas fa-check mt-2" })
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c("label", { attrs: { for: "contraNarrative" } }, [
                  _vm._v("Contra Narrative")
                ])
              ]),
              _c(
                "b-col",
                { attrs: { sm: "4" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", formatter: _vm.upperCase },
                    model: {
                      value: _vm.$v.contraNarrative.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.contraNarrative,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.contraNarrative.$model"
                    }
                  }),
                  _vm.$v.contraNarrative.$dirty
                    ? _c("span", [
                        !_vm.$v.contraNarrative.maxLength
                          ? _c(
                              "label",
                              { staticClass: "text-danger small text-nowrap" },
                              [
                                _vm._v(
                                  "Contra Narrative must be less than " +
                                    _vm._s(
                                      _vm.$v.contraNarrative.$params.maxLength
                                        .max
                                    ) +
                                    " characters"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c("label", { attrs: { for: "paymentDate" } }, [
                  _vm._v("Payment Date")
                ])
              ]),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c("vuejsDatepicker", {
                    ref: "paymentDate",
                    attrs: {
                      name: "paymentDate",
                      id: "paymentDate",
                      format: "dd/MM/yyyy",
                      "input-class": "form-control",
                      "bootstrap-styling": true,
                      disabledDates: _vm.disabledDates,
                      "monday-first": true
                    },
                    on: { selected: _vm.paymentDateChanged },
                    nativeOn: {
                      focusin: function($event) {
                        return _vm.dateOnfocus($event)
                      }
                    },
                    model: {
                      value: _vm.paymentDate,
                      callback: function($$v) {
                        _vm.paymentDate = $$v
                      },
                      expression: "paymentDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c("label", { attrs: { for: "processingDate" } }, [
                  _vm._v("Processing Date")
                ])
              ]),
              _c("b-col", { attrs: { sm: "7" } }, [
                _vm._v(_vm._s(_vm.processingDate))
              ])
            ],
            1
          ),
          _vm.validationErrors.length > 0 ? _c("hr") : _vm._e(),
          _vm.validationErrors.length > 0
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2" }, [
                    _c("label", [
                      _vm._v("Block Validation Warnings and Errors")
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.validationErrors, function(validationError) {
            return _c(
              "b-row",
              { key: validationError },
              [
                _c("b-col", { attrs: { sm: "12" } }, [
                  _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(validationError))
                  ])
                ])
              ],
              1
            )
          }),
          _c(
            "b-row",
            { attrs: { hidden: "" } },
            [_c("b-col", [_vm._v(_vm._s(_vm.$v.$invalid))])],
            1
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }