<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          {{ bureauJob.name }}
          <span class="pull-right">
            <a href="#" target="_blank">
              <i class="far fa-question-circle"></i>
            </a>
          </span>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <b-row>
          <b-col sm="2">
            <label class="mt-2" for="filter">Group</label>
          </b-col>
          <b-col sm="3">
            <!-- <b-form-select
              v-model="bureauJob.groupId"
              :options="groups"
              value-field="id"
              text-field="label"
            ></b-form-select>-->
            <group-select v-model="bureauJob.groupId" :groups="groups" :clearable="false"></group-select>
          </b-col>
          <b-col>
            <b-btn
              variant="outline-primary"
              class="float-right"
              @click="showCustomerSelector()"
              ref="btnShow"
              :disabled="isLoading"
            >
              <i class="fa fa-list pointer"></i>
              Bulk Insert
            </b-btn>
          </b-col>
        </b-row>

        <b-row>
          <hr />
        </b-row>
        <!--
        <b-row>
          <b-col sm="2">
            <b-btn
              variant="primary"
              @click="addNewJobDetail()"
              ref="btnShow"
              :disabled="isLoading"
            >Add Job Detail</b-btn>
          </b-col>
        </b-row>-->

        <b-row>
          <b-col sm="12">
            <vue-good-table
              id="jobDetailsTable"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :rows="rows"
              :columns="columns"
              :searchOptions="{ enabled: true }"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              ref="table"
              :isLoading.sync="isTableLoading"
              styleClass="vgt-table striped bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="fa fa-copy mr1"
                    id="onDuplicateJobDetailClick"
                    style="color: orange;
                           padding: 10;
                           border: none;
                           background: none;"
                    @click.stop="onDuplicateJobDetailClick(props.row)"
                    title="Duplicate Job Detail"
                    :disabled="isLoading"
                  ></button>
                  <button
                    class="fa fa-trash-alt mr1"
                    id="onDeleteJobDetailClick"
                    style="color: red;
                           padding: 10;
                           border: none;
                           background: none;"
                    @click="onDeleteJobDetailClick(props.row)"
                    title="Delete Job Detail"
                    :disabled="isLoading"
                  ></button>
                </span>
                <span v-else @click="onCellClick(props.row)">
                  {{
                  props.formattedRow[props.column.field]
                  }}
                </span>
              </template>

              <div slot="table-actions">
                <b-button
                  @click.prevent="onAddNewJobDetailClick"
                  class
                  variant="primary"
                  size="sm"
                  v-b-popover.hover.top.d500="'Add Job Detail'"
                >
                  <i class="fa fa-plus-circle pointer"></i>
                  Add Job Detail
                </b-button>

                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="printTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
                >
                  <i class="fa fa-print pointer dimmedIcon"></i>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
                >
                  <i class="fa fa-file-export pointer dimmedIcon"></i>
                </b-button>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

      <div class="idb-block-footer">
        <!-- <b-button class="mr-2" variant="primary" @click="onRunClick()">Run</b-button> -->
        <!-- <b-button variant="primary" @click="onSave()" :disabled="isLoading">Save</b-button> -->
        <b-button
          class="float-right"
          variant="danger"
          @click="onDeleteClick()"
          :disabled="isLoading"
        >Delete</b-button>
        <button
          class="btn btn-outline-primary ml-3"
          type="button"
          @click="showModal"
          :disabled="isLoading"
        >
          <i class="glyphicon ti-layers rpad"></i>Clone Bureau Job
        </button>
      </div>
    </div>

    <b-modal lazy size="lg" id="customerselector" title="Select Bureau Customer(s)" hide-footer>
      <bureau-customer-selector
        :customerIdsInJob="getCustomerIdsFromRows()"
        @hideCustomerSelector="hideCustomerSelector"
      ></bureau-customer-selector>
    </b-modal>
    <!--
    <b-modal
      size="lg"
      id="bureauJobDetailModal"
      ref="bureauJobDetailModal"
      title="Job Detail"
      hide-footer
    >
      <bureau-job-detail
        id="bureauJobDetailComponent"
        ref="bureauJobDetailComponent"
        :jobDetailRecord="selectedJobDetailRecord"
        @hideJobDetail="hideJobDetail"
      ></bureau-job-detail>
    </b-modal>-->

    <b-modal
      id="cloneModal"
      ref="clone-modal"
      title="Clone Bureau Job"
      ok-title="Clone"
      cancel-variant="secondary-outline"
      @ok="cloneBureauJob"
      @hidden="resetModal"
      no-close-on-backdrop
      :centered="true"
    >
      <div class="form-bureaujob row" :class="{ invalid: $v.bureauJob.clonedName.$error }">
        <label class="col-form-label col-md-3 required">New Name</label>
        <div class="col-md-6">
          <input type="text" class="form-control" v-model="$v.bureauJob.clonedName.$model" />
          <!-- Validation -->
          <validation-messages v-model="$v.bureauJob.clonedName" name="new name">
            <small
              class="form-text small"
              v-if="
                $v.bureauJob.clonedName.notSameAs != undefined &&
                !$v.bureauJob.clonedName.notSameAs
              "
            >Cloned name cannot be the same as the original name</small>
          </validation-messages>
        </div>
      </div>
    </b-modal>

    <div>
      <addBureauJobDetailModal
        :bureauJobId="id"
        :bureauCustomers="bureauCustomerDisplay"
        v-on:submit="addBureauJobDetailClosed"
      ></addBureauJobDetailModal>
    </div>

    <div>
      <editBureauJobDetailModal
        :bureauJobId="id"
        :bureauCustomers="bureauCustomerDisplay"
        :bureauJobDetailId="selectedJobDetailRecordId"
        v-on:submit="editBureauJobDetailClosed"
      ></editBureauJobDetailModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import BureauCustomerSelector from '@/Components/Bureau/BureauCustomer/BureauCustomerSelector.vue'
// import BureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/BureauJobDetail.vue'
import AddBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/AddBureauJobDetail.vue'
import EditBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/EditBureauJobDetail.vue'
import uuid from 'uuid/v4'
import _ from 'lodash'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'

// Validation helpers
import { maxLength, requiredIf, not, sameAs } from 'vuelidate/lib/validators'

export default {
  props: {
    id: String
  },

  mixins: [Utility, loading, tableFilterMixin, bacsMixin],
  components: {
    BureauCustomerSelector,
    // BureauJobDetail,
    addBureauJobDetailModal: AddBureauJobDetail,
    editBureauJobDetailModal: EditBureauJobDetail
  },
  data () {
    return {
      groups: [],
      bureauCustomers: [],
      bureauCustomerDisplay: [],
      selectedJobDetailRecord: {},
      selectedJobDetailRecordId: '',
      bureauJob: {},
      rows: [],
      totalRecords: 0,
      columns: [
        {
          label: 'Job Details Id',
          field: 'bureauJobDetailId',
          hidden: true
        },
        {
          label: 'Bureau Customer Id',
          field: 'bureauCustomerId',
          hidden: true
        },
        {
          label: 'Customer Name (SUN)',
          field: 'customerName'
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          hidden: true
        },
        {
          label: 'Payment File/URL',
          field: 'filename'
        },
        {
          label: 'Import Schema',
          field: 'importSchema'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }]
      },

      loading: false,
      cloning: false
    }
  },
  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    },
    gridInfo () {
      return document.getElementById('jobDetailsTable')
    }
  },
  async created () {
    await this.load()
  },
  async mounted () {
    // Register modal events
    this.$root.$on('bv::modal::shown', async (bvEvent, modalId) => {
      if (bvEvent.target.id === 'bureauJobDetailModal') {
        console.info('bureau job detail modal dialog shown')
        // this.$refs.bureauJobDetailComponent.$v.$reset()
        this.$refs.bureauJobDetailComponent.onFilenameChanged()
      }
    })
  },
  methods: {

    load:
      _.debounce(async function () {
        await this.getBureauJobData()
        await this.getBureauJobDetails()
        await this.getBureauCustomerData()
      }, 800),

    async getBureauJobDetails () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJobDetails/', { params: { ...this.buildGoodTableQuery(), id: this.id }, showload: true })
        console.log('getBureauJobDetails response', response)
        this.rows = response.data.data
        console.log('rows', this.rows)
      } catch (e) { console.log('Error for table load in get bureau job detail', e) } finally {
        this.isTableLoading = false
      }
    },

    async getBureauJobData () {
      const getGroupsResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaugroups/getbureaugroups', { showload: true })
      this.groups = getGroupsResponse.data

      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJob/' + this.id, { showload: true })
      this.bureauJob = response.data
      console.log('bureaujob getter', response)
      this.bureauJob = response.data
    },

    async getBureauCustomerData () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/getbureaucustomerlist', { showload: true })
      console.log('bureaujob getter', response)
      this.bureauCustomers = response.data

      this.bureauCustomerDisplay = []

      var index = 0
      for (index = 0; index < this.bureauCustomers.length; ++index) {
        var current = {
          id: this.bureauCustomers[index].bureauCustomerId,
          name: this.bureauCustomers[index].display,
          disabled: false
        }
        this.bureauCustomerDisplay.push(current)
      }
    },

    onAddNewJobDetailClick () {
      console.log('Add New Bureau Job Detail Click')
      this.$bvModal.show('add-bureau-job-detail')
    },
    async addBureauJobDetailClosed () {
      console.log('Add New Job Closed')
      await this.getBureauJobDetails()
    },

    onEditJobDetailClick () {
      console.log('Edit Bureau Job Detail Click')
      this.$bvModal.show('edit-bureau-job-detail')
    },
    async editBureauJobDetailClosed () {
      console.log('Edit Bureau Job Closed')
      await this.getBureauJobDetails()
    },

    showModal () {
      this.bureauJob.clonedName = `${this.bureauJob.name} Copy`
      this.$refs['clone-modal'].show()
      this.cloning = true
    },
    resetModal () {
      this.cloning = false
      this.bureauJob.clonedName = ''
    },
    async cloneBureauJob (e) {
      this.$v.bureauJob.clonedName.$touch()
      e.preventDefault()
      if (this.$v.bureauJob.clonedName.$invalid) {
        this.$toastr.e('There are validation errors on the form', 'Validation')
      } else {
        this.onSave()
        // try {
        //   this.loading = true
        //   var data = {
        //     name: this.bureauJob.name,
        //     paygateId: this.paygateId,
        //     groupId: this.bureauJob.groupId,
        //     bureauJobDetails: this.rows,
        //     bureauJobId: this.bureauJob.bureauJobId,
        //     cloning: this.cloning
        //   }

        //   var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/saveJob', data)
        //   if (response.data.toastType === 2) {
        //     this.$toastr.s(response.data.toastMessage)
        //     this.$v.$reset()
        //   } else { this.$toastr.e(response.data.toastMessage) }
        //   this.$v.$reset()
        // } catch (error) {
        //   this.$toastr.e(error)
        // }
      }
    },
    onSearch (params) {
      this.serverParams.searchKey = params.searchTerm
      this.reloadItems()
    },
    onCellClick (row) {
      this.selectedJobDetailRecordId = row.bureauJobDetailId

      this.onEditJobDetailClick()
      // this.$refs.bureauJobDetailModal.show()
      // this.$root.$emit('bv::show::modal', 'bureauJobDetailModal')
    },

    onCellClickOLD (params) {
      if (params.column.field === 'delete') {
        var bureauJobDetailId = params.row.bureauJobDetailId
        this.rows = this.rows.filter(function (jobrow) {
          return jobrow.bureauJobDetailId !== bureauJobDetailId
        })
        event.stopPropagation()
      }
    },

    async onDeleteJobDetailClick (row) {
      var _name = row.bureauCustomer.name
      var _filename = row.filename
      var messageText = _name + ' (' + _filename + ')'
      const swalResult = await swal.fire({
        title: 'Delete Bureau Job Detail',
        text: 'Are you sure you want to delete this job detail for ' + messageText + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        var bureauJobDetailId = row.bureauJobDetailId
        this.rows = this.rows.filter(function (jobrow) {
          return jobrow.bureauJobDetailId !== bureauJobDetailId
        })
        event.stopPropagation()
      }
    },

    async onDuplicateJobDetailClick (row) {
      var _name = row.bureauCustomer.name
      var _filename = row.filename
      var messageText = _name + ' (' + _filename + ')'
      const swalResult = await swal.fire({
        title: 'Duplicate Bureau Job Detail',
        text: 'Are you sure you want to duplicate this job detail for' + messageText + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        var bureauCustomerToDuplicate = this.rows.find(function (jobrow) {
          return jobrow.bureauJobDetailId === row.bureauJobDetailId
        })
        var newBureauCustomer = JSON.parse(JSON.stringify(bureauCustomerToDuplicate))

        var newBureauJobId = this.generateUUID()
        newBureauCustomer.bureauJobDetailId = newBureauJobId
        this.rows.push(newBureauCustomer)
        this.onCellClick(row)
      }
    },

    async onDeleteClick () {
      const swalResult = await swal.fire({
        title: 'Delete Bureau Job',
        text: `Are you sure you want to delete the job: ${this.jobName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/deleteJob/' + this.bureauJob.bureauJobId, { showload: true })
          .then(response => {
            this.$toastr.s(`Bureau Job ${this.jobName} deleted`, 'Bureau Job')
            this.$router.push({ name: 'ListJobs' })
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    onRunClick () {
      this.$router.push({ name: 'JobSubmissionDetails', params: { id: this.bureauJob.bureauJobId, name: this.bureauJob.name } })
    },
    async onSave () {
      var duplicateCheckResult = this.duplicateCheck(this.rows)
      console.log('duplicateCheckResult', duplicateCheckResult)
      if (duplicateCheckResult.length !== 0) {
        swal.fire('Duplicate Job Records', 'Sorry, you have duplicate job records for: ' + [...duplicateCheckResult], 'warning')
        return
      }

      console.info(`saving job as ${this.jobName}`)
      console.info(`job id is ${this.bureauJob.bureauJobId}`)
      var data = {
        name: this.bureauJob.name,
        paygateId: this.paygateId,
        groupId: this.bureauJob.groupId,
        bureauJobDetails: this.rows,
        bureauJobId: this.bureauJob.bureauJobId,
        cloning: this.cloning,
        clonedName: this.bureauJob.clonedName
      }
      console.log('data', data)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/SaveBureauJob', data, { showload: true })

      console.log('Persisted result', response)

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
        if (this.cloning) {
          this.$router.push({ name: 'ManageJob', params: { id: this.response.bureauJobId } })
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },
    async onSaveAs () {
      const swalResult = await swal.fire({
        title: 'Enter a job name',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Save'
      })

      if (swalResult.isConfirmed) {
        this.bureauJob.name = swalResult.value
      } else {
        return
      }

      console.log(`saving job as ${this.bureauJob.name}`)
      console.log(`job id is ${this.bureauJob.bureauJobId}`)

      var data = {
        name: this.bureauJob.name,
        paygateId: this.paygateId,
        bureauJobId: this.bureauJob.bureauJobId,
        groupId: this.bureauJob.groupId,
        bureauJobDetails: this.rows
      }
      console.info('Data for post')
      console.info(data)
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/saveJob', data, { showload: true })
        .then(response => {
          this.$toastr.s(`Bureau Job ${this.bureauJob.name} created`, 'Bureau Job')
          this.$store.commit('setBureauJob', response.data.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    showCustomerSelector () {
      this.$root.$emit('bv::show::modal', 'customerselector')
    },
    async hideCustomerSelector () {
      this.$root.$emit('bv::hide::modal', 'customerselector')
      // Process bureau customers selector
      // Process currently unallocated bureau customers
      var returnType = this.$store.getters.bureauCustomerSelectorReturnType
      if (returnType === 'AddUnallocated') {
        var query = { params: {} }
        query.params.fields = 'name,serviceUserNumber,customerReference,bureauCustomerId,defaultImportSchema,defaultImportMapping,defaultImportFilename'
        query.params.paygateId = this.paygateId
        query.params.groupId = this.bureauJob.groupId
        query.params.sort = 'name asc'
        query.params.perPage = 10000
        query.params.page = 1
        query.params.bureauCustomerList = 'bureauCustomerList'
        if (this.$store.getters.bureauCustomerSelectorFilter !== '') {
          query.params.searchFilter = this.$store.getters.bureauCustomerSelectorFilter
        }

        const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer`, query, { showload: true })
        const customers = response.data.data
        customers.forEach(customer => {
          var newRow = {
            bureauJobDetailId: uuid(),
            bureauCustomerId: customer.bureauCustomerId,
            customerName: `${customer.name} - (${customer.serviceUserNumber})`,
            customerReference: customer.customerReference,
            parsedFilename: customer.defaultImportFilename,
            importSchema: customer.defaultImportSchema,
            importMapping: customer.defaultImportMapping,
            serviceUserNumber: customer.serviceUserNumber
          }
          var found = this.rows.find(function (cust) {
            return cust.bureauCustomerId === newRow.bureauCustomerId
          })
          if (found === undefined) {
            this.rows.push(newRow)
          }
        })
      } else if (returnType === 'Selection') {
        // Process selected bureau customers
        console.log('Hit 1')
        var payload = []
        this.$store.getters.bureauCustomerSelectorSelection.forEach(customer => {
          var newRow = {
            JobId: this.id,
            bureauCustomerId: customer.bureauCustomerId,
            filename: customer.defaultImportFilename,
            importSchema: customer.defaultImportSchema,
            importMapping: customer.defaultImportMapping
          }
          payload.push(newRow)
        })
        console.log('payload', payload)
        if (payload.length > 0) {
          var data = JSON.stringify(payload)
          console.log('data', data)
          var result = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/insertupdatebureaujobdetailbulk', data, { showload: false })
          // call the API to add a new bureau job record
          console.log('newRow save result', result)

          if (result.data.toastType === 2) {
            this.$toastr.s(result.data.toastMessage)
            await this.getBureauJobDetails()
          } else {
            this.$toastr.e(result.data.toastMessage)
          }
        } else {
          console.log('payload is empty')
          this.$toastr.w('No records added')
        }
      }
    },
    hideJobDetail () {
      this.$root.$emit('bv::hide::modal', 'bureauJobDetailModal')
      var returnType = this.$store.getters.bureauJobDetailsReturnType
      if (returnType === 'Ok') {
        // post updated value back into rows
        for (let index = 0; index < this.rows.length; index++) {
          const row = this.rows[index]
          if (row.bureauJobDetailId === this.selectedJobDetailRecord.bureauJobDetailId) {
            this.selectedJobDetailRecord.serviceUserNumber = this.selectedJobDetailRecord.bureauCustomer.serviceUserNumber
            this.selectedJobDetailRecord.parsedFilename = this.buildFilename(this.selectedJobDetailRecord)
            this.rows[index] = this.selectedJobDetailRecord
            break
          }
        }
      }
    },
    getCustomerIdsFromRows () {
      var bureauCustomerIds = []
      if (this.rows !== null) {
        this.rows.forEach(customer => {
          bureauCustomerIds.push(customer.bureauCustomerId)
        })
      }
      return bureauCustomerIds
    },
    buildFilename (data) {
      var parsedFilename = data.filename
      parsedFilename = parsedFilename.replace('[CUSTOMERREF]', data.customerReference)
      parsedFilename = parsedFilename.replace('[SUN]', data.serviceUserNumber)
      return parsedFilename
    },
    duplicateCheck (dataToCheck) {
      var object = {}
      var candidates = []
      var results = []

      // Check for duplicate bureau customers
      dataToCheck.forEach(function (item) {
        if (!object[item.customerName]) {
          object[item.customerName] = 0
        }
        object[item.customerName] += 1
      })

      for (var prop in object) {
        if (object[prop] >= 2) {
          candidates.push(prop)
        }
      }

      // Now check filenames for duplicate candidates
      candidates.forEach(candidate => {
        var filenames = {}
        var jobRows = this.rows.filter(o => o.customerName === candidate)
        jobRows.forEach(jobRow => {
          if (!filenames[jobRow.filename]) {
            filenames[jobRow.filename] = 0
          }
          filenames[jobRow.filename] += 1
        })
        for (var filename in filenames) {
          if (filenames[filename] >= 2) {
            results.push(candidate)
          }
        }
      })
      return results
    }
  },

  validations: {
    bureauJob: {
      clonedName: {
        required: requiredIf(() => {
          return 'bureauJob.cloning'
        }),
        maxLength: maxLength(50),
        notSameAs: not(sameAs('name'))
      }
    }
  }
}
</script>

<style scoped>
input,
select {
  font-size: 100%;
  border: none;
  width: 100%;
}
</style>
