<template>
  <div class="cookie-message-box container-fluid" v-if="display">
    <div class="row">
      <div class="col-md-9 col-push-2 cookie-message-text">
        <p>
          We use cookies and similar technologies to
          recognize your repeat visits and preferences, as well as to
          measure the effectiveness of campaigns and analyze traffic. To learn more about cookies, including how to disable them, view our
          <a
            @click="settings"
            href="#"
          >Cookie Policy</a>. By clicking "Accept" or using our site, you consent to the use of cookies unless you have disabled them.
        </p>
      </div>
      <div class="col-md-1">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-outline-success" @click="accept">Accept</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-link btn-sm" @click="settings">Change Settings</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      display: false
    }
  },
  methods: {
    accept () {
      this.$store.commit('updateCookiePolicy', { cookies: { preference: true, tracking: true }, vm: this })
      this.$ga.enable()
      this.display = false
    },
    settings () {
      this.display = false
      this.$router.push('/cookies')
    }
  },
  beforeMount () {
    this.$store.dispatch('loadCookies', this)
    var display = this.$store.getters.doCookiesExist
    if (!display) {
      this.display = true
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-message-box {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  min-height: 100px;
  background: #efefef;
  padding: 15px;

  .cookie-message-text {
    color: #3c3c3c;
  }

  .cookie-message-title {
    h3 {
      color: #14171a;
    }
  }
}

.dark-mode {
  .cookie-message-box {
    background: #5f5f5f;
  }

  .cookie-message-text {
    color: #c5c5c5;
  }

  .cookie-message-title {
    h3 {
      color: #fff;
    }
  }
}
</style>
