var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "idb-block" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              attrs: {
                mode: "remote",
                "search-options": {
                  enabled: true
                },
                "sort-options": {
                  enabled: true
                },
                "pagination-options": {
                  enabled: true,
                  dropdownAllowAll: false
                },
                totalRows: _vm.totalRecords,
                columns: _vm.columns,
                rows: _vm.emailAddresses,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "on-row-click": _vm.onRowClick,
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "verified"
                        ? _c("span", [
                            _c("i", {
                              staticClass: "fa fa-fw",
                              class: props.row.verified
                                ? "fa-check"
                                : "fa-times"
                            })
                          ])
                        : props.column.field == "buttons"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteEmail(
                                        props.row["emailRecipientId"]
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash-alt" })]
                              )
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Refresh the data in the table",
                          expression: "'Refresh the data in the table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link", title: "Refresh Table" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.loadEmails($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-redo pointer dimmedIcon" })]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Print out the contents of the Emails table",
                          expression:
                            "'Print out the contents of the Emails table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link", title: "Print Table" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.printTable($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-print pointer dimmedIcon" })]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Export the contents of the Emails table",
                          expression:
                            "'Export the contents of the Emails table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link", title: "Export Table" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.exportTable($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-share-square pointer dimmedIcon"
                      })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.create($event)
              }
            }
          },
          [_vm._v("Add Email")]
        )
      ]),
      _c(
        "b-modal",
        {
          ref: "emailAddressModal",
          attrs: {
            id: "emailAddressModal",
            title: "Email Address",
            size: "lg",
            "hide-header-close": "",
            "cancel-variant": "secondary-outline",
            lazy: ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "emailAddressInput" }
                  },
                  [_vm._v("Email Address")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c("b-form-input", {
                    attrs: { id: "emailAddressInput", type: "email" },
                    model: {
                      value: _vm.selectedEmailAddress.recipientEmailAddress,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedEmailAddress,
                          "recipientEmailAddress",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "selectedEmailAddress.recipientEmailAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Payer Threshold Emails")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }