<template>
  <div id="importedPayments">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Imported Files Summary
          <help-icon></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="col-md-12">
        <span>
          <b>Submission Reference:</b>
          {{submissionReference}}
        </span>
      </div>
      <div class="col-md-12">
        <span>
          <b>Bureau Job Template:</b>
          {{bureauJobName}}
        </span>
      </div>

      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :rows="rows"
          :columns="columns"
          :searchOptions="{ enabled: true }"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-sort-change="onSortChange"
          ref="table"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'filename'">
              <div
                v-if="props.row.bureauFileImportStatus === 'Error'"
                class="group-colour"
                :style="{ 'background-color': 'red' }"
              ></div>
              <div v-else class="group-colour" :style="{ 'background-color': 'green' }"></div>
              {{props.row.filename}}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <div class="idb-block-footer">
      <button
        class="btn btn-primary ml-2"
        @click="onViewPaymentsClick()"
        :disabled="isTableLoading"
      >View Payments</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import Utility from '@/Assets/Mixins/Utility'

export default {
  props: {
    id: String,
    startProcess: Boolean
  },
  mixins: [
    Utility,
    tableFilterMixin
  ],
  data () {
    return {
      submissionReference: '',
      bureauJobName: '',
      paymentNetworkType: 0,
      columns: [
        {
          label: 'Filename',
          field: 'filename'
        },
        {
          label: 'Status',
          field: 'bureauFileImportStatus'
        },
        {
          label: 'Errors',
          field: 'errorText'
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'bureauFileImportStatus', type: 'asc' }]
      }
    }
  },

  async created () {
    await this.getBureauImportSummary()
    await this.load()
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getimportedpaymentfilessummary', { params: { ...this.buildGoodTableQuery(), id: this.id }, showload: true })
        console.log('response from import', response)
        this.rows = response.data.data
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    async getBureauImportSummary () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureauimportsummary/' + this.id)
        console.log('response from getbureauimportsummary', response)
        this.bureauJobName = response.data.bureauJobName
        this.submissionReference = response.data.submissionReference
        this.paymentNetworkType = response.data.paymentNetworkType
      } catch { } finally { }
    },
    onViewPaymentsClick () {
      if (this.paymentNetworkType === 0) { this.$router.push({ name: 'PaymentDetails', params: { id: this.id } }) } else {
        this.$router.push({ name: 'PaymentDetailsFPS', params: { id: this.id } })
      }
    }

  }

}

</script>
