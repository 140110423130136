<template>
  <div id="viewFlaggedSubmissions">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/submissions/" />Flagged Payments</h2>
          </div>
          <div class="idb-block-content">
            <b-container>
              <b-row style="margin-bottom: 0.8em;">
                <b-col md="2"><strong>Group:</strong> {{ flaggedPayments.length > 0 ? flaggedPayments[0].groupName : '' }} </b-col>
                <b-col md="2"></b-col>
              </b-row>
              <vue-good-table
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :columns="columns"
                :rows="flaggedPayments"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                  enabled: true
                }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                @on-row-click="onRowClick"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'viewBtn'">
                    <span style="font-weight: bold; color: blue;">View</span>
                  </span>
                  <span v-else-if="props.column.field == 'viewPayerBtn'">
                    <router-link :to="`/collections/payer/${props.row.ukPayerId}/${props.row.paymentPlanId}/schedule`"> <!-- eslint-disable-line -->
                        <span>View Schedule</span>
                      </router-link>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">
                      No Flagged Payments Found
                    </div>
                  </div>
                  <div slot="table-actions">
                    <button
                      @click.prevent="clearTableFilters"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Clear filters'" :disabled="isLoading"
                    >
                      <span class="fa-stack" style="font-size: 10px;">
                        <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                        <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                      </span>
                    </button>
                    <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </b-button>
                    <b-button @click.prevent="printTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Print out the contents of the table'">
                      <i class="fa fa-print pointer dimmedIcon"></i>
                    </b-button>
                    <b-button @click.prevent="exportTable" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Export the contents of the table'">
                      <i class="fa fa-share-square pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                </vue-good-table>
            </b-container>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  props: {
    groupId: String
  },
  data () {
    return {
      columns: [
        {
          'field': 'flaggedPaymentsCount',
          'hidden': true
        },
        {
          label: 'First Name',
          field: 'firstName',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Surname',
          field: 'surname',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Reference',
          field: 'payerReference',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Tx Code',
          field: 'txCode'
        },
        {
          label: 'Amount',
          field: 'amount',
          formatFn: (value) => {
            return `£${(value / 1).toFixed(2)}`
          }
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (val) => new Date(val).toLocaleDateString()
        },
        {
          label: 'Plan Reference',
          field: 'planReference'
        },
        {
          label: 'Reason',
          field: 'reasonFlagged'
        },
        {
          label: '',
          field: 'viewPayerBtn',
          sortable: false
        }
      ],
      totalRecords: 0,
      serverParams: {
        sort: [{ field: 'payerName', type: 'asc' }]        
      }
    }
  },
  methods: {
    getSubmissions () {
      this.$store.dispatch('loadSubmissions')
    },
    load: async function () {
      try {
        await this.$store.dispatch('loadFlagged', { serverParams: this.buildGoodTableQuery(), groupId: this.groupId })
        this.submissions = this.$store.getters.submissions
        var meta = this.$store.getters.metaData
        this.totalRecords = meta.totalItems
        console.log('meta in reload items', meta.totalItems)
      } catch (e) {
        this.$toastr.e(e)
      }
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (params) {
    }
  },
  computed: {
    selectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    customerGroups () {
      return this.$store.state.groups.groups
    },
    flaggedPayments () {
      return this.$store.state.submissions.flaggedPayments
    }
  },
  validations: {
    group: { required }
  },
  async mounted () {
    await this.reloadItems()
  }
}
</script>
