var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          !_vm.isLoading && _vm.checkValidation() && _vm.validate()
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                { attrs: { id: "title-of-block-check" } },
                [
                  _vm._v(" Card Validation "),
                  _c("help-icon", { attrs: { docPath: "/validate/cards/" } }),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c(
                "div",
                {
                  staticClass: "form-group col-md-8",
                  class: {
                    warn: _vm.$v.card.number.$error,
                    valid: _vm.card.result != null && _vm.card.result.valid,
                    invalid: _vm.card.result != null && !_vm.card.result.valid
                  }
                },
                [
                  _c("label", { staticClass: "label-control required" }, [
                    _vm._v("Card Number")
                  ]),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.card.number.$model,
                          expression: "$v.card.number.$model",
                          modifiers: { trim: true }
                        },
                        { name: "focus", rawName: "v-focus" }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", autocomplete: "cc-number" },
                      domProps: { value: _vm.$v.card.number.$model },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.card.number,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          _vm.onlyDigits
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm.card.image
                      ? _c("img", {
                          staticClass:
                            "input-group-addon img-fluid p-0 m-0 card-addon",
                          attrs: { src: _vm.card.image }
                        })
                      : _vm._e()
                  ]),
                  _vm.$v.card.number.$dirty
                    ? _c("span", [
                        !_vm.$v.card.number.required
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [_vm._v("A card number is required")]
                            )
                          : _vm._e(),
                        !_vm.$v.card.number.minLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [
                                _vm._v(
                                  "The card number is required to be at least " +
                                    _vm._s(_vm.validation.minLength) +
                                    " numbers long"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.card.number.maxLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [
                                _vm._v(
                                  "The card number is required to be less than 19 numbers long"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.card.result != null
                    ? _c("span", [
                        _vm.card.result.valid
                          ? _c(
                              "small",
                              { staticClass: "form-text text-success small" },
                              [_vm._v("The card is valid!")]
                            )
                          : _vm._e(),
                        !_vm.card.result.valid
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger small" },
                              [_vm._v("The card is invalid!")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              )
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v("Validate")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary ml-3",
                  attrs: { type: "button" },
                  on: { click: _vm.clear }
                },
                [_vm._v("Clear")]
              )
            ])
          ])
        ])
      ]),
      _vm.card.result != null && _vm.card.result.valid
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "idb-block" }, [
                _vm._m(0),
                _c("div", { staticClass: "idb-block-content" }, [
                  _c(
                    "form",
                    {
                      staticClass: "col-md-12",
                      attrs: { novalidate: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Issuer")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(
                            _vm._s(
                              _vm.card.result.issuer
                                ? _vm.card.result.issuer
                                : "No issuer found"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Scheme")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(
                            _vm._s(
                              _vm.card.result.cardScheme
                                ? _vm.card.result.cardScheme
                                : "No card scheme found"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Type")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(
                            _vm._s(
                              _vm.card.result.productType
                                ? _vm.card.result.productType
                                : "No product type found"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Usage")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(
                            _vm._s(
                              _vm.card.result.usage
                                ? _vm.card.result.usage
                                : "No usage found"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Industry")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(
                            _vm._s(
                              _vm.card.result.majorIndustryIndicator
                                ? _vm.card.result.majorIndustryIndicator
                                : "No industry found"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Notes")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(
                            _vm._s(
                              _vm.card.result.notes
                                ? _vm.card.result.notes
                                : "No notes found"
                            )
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Card Details")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }