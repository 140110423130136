var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " Show import progress for '" +
              _vm._s(this.submission.submissionReference) +
              "' "
          ),
          _c("help-icon"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "vue-good-table",
          {
            ref: "paymentFileImportProgress",
            attrs: {
              mode: "remote",
              columns: _vm.columns,
              rows: _vm.bureauSubmissionDetails,
              lineNumbers: true,
              totalRows: _vm.totalRecords,
              "search-options": {
                enabled: false
              },
              "pagination-options": {
                enabled: false
              },
              styleClass: "vgt-table striped bordered"
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.label === "Status"
                      ? _c("span", [
                          _c("div", { staticClass: "percentageContainer" }, [
                            _c("div", {
                              staticClass: "percentage",
                              class: _vm.buildPercentageBarClass(
                                props.row.percentComplete
                              ),
                              style: { width: props.row.percentComplete + "%" }
                            }),
                            props.row.percentComplete == 0
                              ? _c("div", { staticClass: "percentageLabel" }, [
                                  _vm._v(_vm._s(props.row.status))
                                ])
                              : _vm._e(),
                            props.row.percentComplete != 0
                              ? _c("div", { staticClass: "percentageLabel" }, [
                                  _vm._v(
                                    _vm._s(props.row.percentComplete) +
                                      "% " +
                                      _vm._s(props.row.status)
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : props.column.label === "Issues"
                      ? _c("span", [
                          _c("div", [
                            _c("a", [
                              props.row.importMessages != undefined &&
                              props.row.importMessages.length > 0
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.left",
                                        value: props.row.importMessages.join(
                                          "\n"
                                        ),
                                        expression:
                                          "props.row.importMessages.join('\\n')",
                                        modifiers: { hover: true, left: true }
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle",
                                    staticStyle: { padding: "0px" },
                                    attrs: { title: "Import Messages" }
                                  })
                                : _vm._e()
                            ])
                          ])
                        ])
                      : _c("span", [
                          _c("div", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                        ])
                  ]
                }
              }
            ])
          },
          [
            _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
              _c("div", { staticClass: "vgt-center-align vgt-text-disabled" }, [
                _vm._v("No payment files in job")
              ])
            ])
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary ml-1",
          on: { click: _vm.onViewPaymentsClick }
        },
        [_vm._v("View Payments")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-primary ml-1",
          on: { click: _vm.onAddFilesClick }
        },
        [_vm._v("Add Files")]
      )
    ]),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }