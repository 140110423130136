var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v(_vm._s(_vm.label))]),
        _c("input", {
          staticClass: "form-control",
          class: { invalid: _vm.$v.value.$error },
          attrs: {
            type: "number",
            name: _vm.name,
            placeholder: _vm.placeholder
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              _vm.$emit("input", $event.target.value)
              _vm.$v.value.$touch()
            }
          }
        }),
        !_vm.$v.value.numeric && _vm.$v.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("Numeric only!")
            ])
          : _vm._e(),
        !_vm.$v.value.maxValue && _vm.$v.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("Max Value is " + _vm._s(this.maxValue))
            ])
          : _vm._e(),
        !_vm.$v.value.minValue && _vm.$v.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("Min Value is " + _vm._s(this.minValue))
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }