/* eslint-disable no-return-await */
import sideBarLinks from '@/Menu'
import { vueInstance } from '@/main'
var initialMenu = null

function createMenu () {
  initialMenu = sideBarLinks
  initialMenu.routes = initialMenu.routes.map((r) => {
    r = { ...r, active: false, show: true }
    r.routes = r.routes.map((rr) => {
      rr = { ...rr, active: false, show: true }
      if (rr.child_routes) {
        rr.child_routes = rr.child_routes.map((cr) => {
          cr = { ...cr, active: false, show: true }
          return cr
        })
      }
      return rr
    })
    return r
  })
}
createMenu()

const state = {
  menu: initialMenu
}

const getters = {
  menu: (state) => {
    return state.menu
  }
}

const mutations = {
  saveMenu (state, newMenu) {
    state.menu.routes = newMenu.routes
  },
  openMenu (state, { menuItem, parentMenu }) {
    var statefulParentMenu
    if (parentMenu.path) {
      statefulParentMenu = state.menu.routes.filter(r => r.path === parentMenu.path)[0]
    } else {
      statefulParentMenu = state.menu
    }
    var statefulMenuItem = statefulParentMenu.routes.filter(r => r.menu_title === menuItem.menu_title)[0]

    if (statefulParentMenu && statefulMenuItem.active === false) {
      statefulParentMenu.routes.forEach((r) => {
        r.active = false
        if (r.routes) {
          r.routes.forEach((r) => {
            r.active = false
          })
        }
      })
    }
    statefulMenuItem.active = !statefulMenuItem.active
  }
}

const actions = {
  async updateMenu ({ commit, state }) {
    for (const r of state.menu.routes) {
      await setUpRoutes(r, 1)
    }
  }
}

async function setUpRoutes (a, i) {
  if (a.showMethod !== undefined) {
    a.show = await a.showMethod()
  }

  var path = vueInstance.$router.currentRoute.path

  if (i === 2) {
    const childRoutes = a.child_routes || []
    childRoutes.forEach(async (a) => {
      if (a.showMethod !== undefined) {
        a.show = await a.showMethod()
      }
    })
    a.active = childRoutes.some(cr => {
      try {
        const valueToCheck = cr.path.split('/')[i]
        if (valueToCheck) {
          return path.split('/')[i].includes(valueToCheck)
        } else {
          return false
        }
      } catch {
        return false
      }
    })
  } else {
    a.active = path.split('/')[i].includes(a.path.split('/')[i])
  }

  if (a.routes !== undefined && a.routes != null) {
    for (const r of a.routes) {
      await setUpRoutes(r, 2)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
