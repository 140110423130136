
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
const state = {
  browser: common.getBrowser(),
  deviceType: '',
  webExtensionRequired: false,
  pluginRequired: false,
  pluginUpdateRequired: false,
  useeSigner: false,
  eSignerLimitExceeded: false,
  installCancelled: false
}

// state.expectedVersion = state.browser === 'IE' ? '2' : '1'

const mutations = {
  setDeviceType (state, deviceType) {
    state.deviceType = deviceType
  },
  setWebExtensionRequired (state, webExtensionRequired) {
    state.webExtensionRequired = webExtensionRequired
  },
  setPluginRequired (state, pluginRequired) {
    state.pluginRequired = pluginRequired
  },
  setPluginUpdateRequired (state, pluginUpdateRequired) {
    state.pluginUpdateRequired = pluginUpdateRequired
  },
  seteSignerLimitExceeded (state, eSignerLimitExceeded) {
    state.eSignerLimitExceeded = eSignerLimitExceeded
  },
  setInstallCancelled (state, installCancelled) {
    state.installCancelled = installCancelled
  }
}

const getters = {
  getComponentVersion (state) {
    switch (state.deviceType) {
      case 'PGO USB Token Commit':
        state.expectedVersion = state.browser === 'IE' ? '2' : '1'
        return 0
      case 'USB Token':
        state.expectedVersion = state.browser === 'IE' ? '2' : '1'
        return 1
      case 'SmartCard':
        state.expectedVersion = state.browser === 'IE' ? '3' : '1'
        return 2
      case 'Restricted File Browser':
        state.expectedVersion = state.browser === 'IE' ? '3' : '1'
        return 3
    }
  },
  getShowSetupModal (state) {
    if (state.webExtensionRequired || state.pluginRequired || state.pluginUpdateRequired) {
      return true
    }
    return false
  },
  getUseeSigner (state) {
    return state.useeSigner
  },
  getInstallCancelled (state) {
    return state.installCancelled
  }
}
const actions = {
  /* #region  Chrome */
  async checkChromeExtensionLoaded ({ commit }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'ping' }, (response) => {
        if (response) {
          commit('setWebExtensionRequired', false)
          resolve()
        } else {
          commit('setWebExtensionRequired', true)
          commit('setPluginRequired', true)
          reject(new Error('Extension not installed'))
        }
      })
    })
  },
  async checkChromePluginInstalled ({ state, commit, getters }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'GetVersion' }, (response) => {
        if (response.response || response.indexOf('PluginNotFound') === -1) {
          commit('setPluginRequired', false)
          var pluginResponse = common.parsePluginResponse(response.response)
          var updateRequired = common.isLatestNonActiveXPlugin(pluginResponse.PluginVersion, getters.getComponentVersion, state.expectedVersion)
          if (updateRequired) {
            commit('setPluginUpdateRequired', true)
            reject(new Error('Plugin must be updated'))
          } else {
            commit('setPluginUpdateRequired', false)
            resolve()
          }
        } else {
          commit('setPluginRequired', true)
          reject(new Error('Plugin must be installed'))
        }
      })
    })
  },
  async checkChromePlugin ({ dispatch }) {
    await dispatch('checkChromeExtensionLoaded')
    await dispatch('checkChromePluginInstalled')
  },
  /* #endregion */

  /* #region  Firefox */
  async checkFirefoxExtensionLoaded ({ commit }) {
    var checked = false
    return new Promise((resolve, reject) => {
      document.addEventListener('getExtensionResponseWebPage', () => {
        commit('setWebExtensionRequired', false)
        checked = true
        resolve()
      }, { once: true })

      var pingEvent = new CustomEvent('ping')
      document.dispatchEvent(pingEvent)

      setTimeout(() => {
        if (checked) {
          return
        }
        commit('setWebExtensionRequired', true)
        commit('setPluginRequired', true)
        reject(new Error('Extension not installed'))
      }, 2000)
    })
  },
  async checkFireFoxPluginInstalled ({ state, commit, getters }) {
    return new Promise((resolve, reject) => {
      document.addEventListener('getPluginResponseWebPage', (response) => {
        if (response.detail) {
          commit('setPluginRequired', false)
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          var updateRequired = common.isLatestNonActiveXPlugin(pluginResponse.PluginVersion, getters.getComponentVersion, state.expectedVersion)
          if (updateRequired) {
            commit('setPluginUpdateRequired', true)
            reject(new Error('Plugin must be updated'))
          } else {
            commit('setPluginUpdateRequired', false)
            resolve()
          }
        } else {
          commit('setPluginRequired', true)
          reject(new Error('Plugin must be installed'))
        }
      }, { once: true })
      var pluginEvent = new CustomEvent('clientCallPlugin', { detail: { PluginTask: 'GetVersion' } })
      document.dispatchEvent(pluginEvent)
    })
  },
  async checkFirefoxPlugin ({ dispatch }) {
    await dispatch('checkFirefoxExtensionLoaded')
    await dispatch('checkFireFoxPluginInstalled')
  },
  /* #endregion */

  /* #region  IE */

  async checkIEPluginInstalled ({ state, commit, getters }) {
    return new Promise((resolve, reject) => {
      var pluginLoaded = common.pluginLoaded()
      if (pluginLoaded) {
        var updateRequired = common.isLatestPlugin(getters.getComponentVersion, state.expectedVersion)
        if (updateRequired) {
          commit('setPluginUpdateRequired', true)
          reject(new Error('Plugin must be updated'))
        } else {
          commit('setPluginUpdateRequired', false)
          resolve()
        }
      } else {
        console.log('deviceType: ' + state.deviceType)
        if (state.deviceType !== 'SmartCard') {
          commit('setPluginRequired', true)
          reject(new Error('Plugin must be installed'))
        } else {
          console.log('state.eSignerLimitExceeded: ' + state.eSignerLimitExceeded)
          if (!state.eSignerLimitExceeded) {
            state.useeSigner = true
            resolve()
          } else {
            commit('setPluginRequired', true)
            commit('seteSignerLimitExceeded', true)
            reject(new Error('Plugin must be installed'))
          }
        }
      }
    })
  },

  /* #endregion */

  async checkPlugin ({ state, dispatch }) {
    switch (state.browser) {
      case 'Chrome':
        try {
          await dispatch('checkChromePlugin')
          return
        } catch {
          throw new Error('Plugin or extension needed')
        }
      case 'Firefox':
        try {
          await dispatch('checkFirefoxPlugin')
          return
        } catch (e) {
          throw new Error('Plugin or extension needed')
        }
      case 'IE':
        try {
          await dispatch('checkIEPluginInstalled')
          return
        } catch (e) {
          throw new Error('Plugin or extension needed')
        }
    }
    throw new Error('Browser not supported')
  },
  async getUsbTokenDetails ({ dispatch }) {
    await dispatch('checkPlugin')
    return new Promise((resolve, reject) => {
      try {
        switch (state.browser) {
          case 'Chrome':
            // eslint-disable-next-line
            chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'GetTokenDetails', DllName: 'eTPKCS11.dll' }, ({ response }) => {
              var formattedResponse = common.parsePluginResponse(response)
              if (formattedResponse.CertificateThumbprint !== null) {
                resolve(formattedResponse)
              } else {
                reject(new Error(formattedResponse.ErrorMessage))
              }
            })
            break
          case 'Firefox':
            document.addEventListener('getPluginResponseWebPage', (response) => {
              var formattedResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
              if (formattedResponse.CertificateThumbprint !== null) {
                resolve(formattedResponse)
              } else {
                reject(new Error(formattedResponse.ErrorMessage))
              }
            }, { once: true })
            var pluginEvent = new CustomEvent('clientCallPlugin', { detail: { PluginTask: 'GetTokenDetails', DllName: 'eTPKCS11.dll' } })
            document.dispatchEvent(pluginEvent)
            break
          case 'IE':
            var response = document.PayGatePlugin.GetTokenDetails('eTPKCS11.dll')
            if (!response.Message || response.Message.indexOf('Error') === -1) {
              resolve({
                TokenSerialNumber: response.TokenSerialNumber,
                TokenSupplier: response.TokenSupplier,
                CertificateThumbprint: response.CertificateInfo.Thumbprint,
                CertificateSerialNumber: response.CertificateInfo.CertSerialNumberString,
                ValidFrom: response.CertificateInfo.ValidFromStr,
                ValidTo: response.CertificateInfo.ValidToStr
              })
            } else {
              reject(new Error(response.Message))
            }
            break
        }
      } catch (e) {
        reject(new Error('Plugin or extension not installed'))
      }
    })
  },
  async getUsbTokenThumbprint ({ dispatch }) {
    await dispatch('checkPlugin')
    return new Promise((resolve, reject) => {
      try {
        switch (state.browser) {
          case 'Chrome':
            // eslint-disable-next-line
            chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' }, ({ response }) => {
              var formattedResponse = common.parsePluginResponse(response)
              if (formattedResponse.CertificateThumbprint !== null) {
                resolve(formattedResponse.CertificateThumbprint)
              } else {
                reject(new Error(formattedResponse.ErrorMessage))
              }
            })
            break
          case 'Firefox':
            document.addEventListener('getPluginResponseWebPage', (response) => {
              var formattedResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
              if (formattedResponse.CertificateThumbprint !== null) {
                resolve(formattedResponse.CertificateThumbprint)
              } else {
                reject(new Error(formattedResponse.ErrorMessage))
              }
            }, { once: true })
            var pluginEvent = new CustomEvent('clientCallPlugin', { detail: { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' } })
            document.dispatchEvent(pluginEvent)
            break
          case 'IE':
            var response = document.PayGatePlugin.GetCertificateThumbprint('eTPKCS11.dll')
            if (!response || response.indexOf('Error') === -1) {
              resolve(response)
            } else {
              reject(new Error(response))
            }
            break
        }
      } catch (e) {
        console.log(e)
        reject(new Error('Plugin or extension not installed'))
      }
    })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
