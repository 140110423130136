var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Bureau Customer Verification "),
            _c("help-icon"),
            _c("favourite-icon")
          ],
          1
        ),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", {
            staticClass: "group-colour",
            style: { "background-color": "black" }
          }),
          _c("span", [
            _vm._v(" Filename: "),
            _c("b", [_vm._v('"' + _vm._s(_vm.Filename) + '"')])
          ])
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", {
            staticClass: "group-colour",
            style: { "background-color": "black" }
          }),
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.totalRecordsInImport))]),
            _vm._v(" bureau customers contained in import ")
          ])
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", {
            staticClass: "group-colour",
            style: { "background-color": "green" }
          }),
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.TotalRecordsVerified))]),
            _vm._v(" can be imported ")
          ])
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", {
            staticClass: "group-colour",
            style: { "background-color": "red" }
          }),
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.TotalRecordsNeedVerification))]),
            _vm._v(" contain issues that need be addressed ")
          ])
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", {
            staticClass: "group-colour",
            style: { "background-color": "orange" }
          }),
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.TotalRecordsDeleted))]),
            _vm._v(" records are removed from the import ")
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                rows: _vm.rows,
                columns: _vm.columns,
                searchOptions: { enabled: true },
                mode: "remote",
                totalRows: _vm.totalRecords,
                isLoading: _vm.isTableLoading,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "on-page-change": _vm.onPageChange,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch,
                "on-sort-change": _vm.onSortChange,
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field === "name"
                        ? _c("span", [
                            props.row.deleted
                              ? _c("div", {
                                  staticClass: "group-colour",
                                  style: { "background-color": "orange" }
                                })
                              : props.row.errorlevel > 0
                              ? _c("div", {
                                  staticClass: "group-colour",
                                  style: { "background-color": "red" }
                                })
                              : _c("div", {
                                  staticClass: "group-colour",
                                  style: { "background-color": "green" }
                                }),
                            props.row.deleted
                              ? _c("span", { staticClass: "pl-2" }, [
                                  _c("small", [
                                    _c("i", [_vm._v(_vm._s(props.row.name))])
                                  ])
                                ])
                              : _c("span", [_vm._v(_vm._s(props.row.name))])
                          ])
                        : props.column.field === "serviceUserNumber"
                        ? _c("span", [
                            props.row.deleted
                              ? _c("span", { staticClass: "pl-2" }, [
                                  _c("small", [
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(props.row.serviceUserNumber)
                                      )
                                    ])
                                  ])
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(props.row.serviceUserNumber))
                                ])
                          ])
                        : props.column.field === "errorMessages"
                        ? _c("span", [
                            props.row.bureauImportStatus == "Editing"
                              ? _c("span", [
                                  props.row.deleted === true
                                    ? _c("span", [
                                        _c("small", [
                                          _c("i", [
                                            _vm._v("Removed from import")
                                          ])
                                        ])
                                      ])
                                    : props.row.errorlevel > 0 &&
                                      props.row.deleted === false
                                    ? _c(
                                        "ul",
                                        _vm._l(
                                          props.row.errorMessages,
                                          function(error) {
                                            return _c("li", { key: error }, [
                                              _c("i", [_vm._v(_vm._s(error))])
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                ])
                              : _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(props.row.bureauImportStatus))
                                ])
                          ])
                        : props.column.field === "actions"
                        ? _c("span", [
                            _c("button", {
                              staticClass: "fa fa-edit mr-1",
                              staticStyle: {
                                color: "green",
                                padding: "0",
                                border: "none",
                                background: "none"
                              },
                              attrs: {
                                id: "onRecordClick",
                                title: "Edit this Bureau Customer",
                                disabled:
                                  props.row.bureauImportStatus !== "Editing"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onRecordClick(props.row)
                                }
                              }
                            }),
                            props.row.deleted
                              ? _c("button", {
                                  staticClass: "fa fa-trash-restore mr-1",
                                  staticStyle: {
                                    color: "orange",
                                    padding: "0",
                                    border: "none",
                                    background: "none"
                                  },
                                  attrs: {
                                    id: "onRecordRestore",
                                    title:
                                      "Restore this Bureau Customer to import",
                                    disabled:
                                      props.row.bureauImportStatus !== "Editing"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onRecordRestore(props.row)
                                    }
                                  }
                                })
                              : _c("button", {
                                  staticClass: "fa fa-trash-alt mr-1",
                                  staticStyle: {
                                    color: "red",
                                    padding: "0",
                                    border: "none",
                                    background: "none"
                                  },
                                  attrs: {
                                    id: "onRecordDelete",
                                    title:
                                      "Remove this Bureau Customer from import",
                                    disabled:
                                      props.row.bureauImportStatus !== "Editing"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onRecordDelete(props.row)
                                    }
                                  }
                                }),
                            props.row.deleted
                              ? _c("button", {
                                  staticClass:
                                    "fa fa-file-import mr-1 disabled",
                                  staticStyle: {
                                    color: "grey",
                                    padding: "0",
                                    border: "none",
                                    background: "none"
                                  },
                                  attrs: {
                                    id: "onImportSingleDisabled",
                                    title:
                                      "Cannot import this Bureau Customer while deleted",
                                    disabled:
                                      props.row.bureauImportStatus !== "Editing"
                                  }
                                })
                              : _c("button", {
                                  staticClass: "fa fa-file-import mr-1",
                                  staticStyle: {
                                    color: "black",
                                    padding: "0",
                                    border: "none",
                                    background: "none"
                                  },
                                  attrs: {
                                    id: "onImportSingle",
                                    title: "Import just this Bureau Customer",
                                    disabled:
                                      props.row.bureauImportStatus !== "Editing"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onImportSingle(props.row)
                                    }
                                  }
                                })
                          ])
                        : props.column.field === "checkbox"
                        ? _c(
                            "span",
                            [
                              _c("p-check", {
                                staticClass: "p-default p-curve",
                                attrs: {
                                  disabled:
                                    props.row.deleted === true ||
                                    props.row.bureauImportStatus !== "Editing"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.addToBCArray(props.row)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        this.checkboxesSelected === true
          ? _c("span", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.onVerifySelectedRecords()
                    }
                  }
                },
                [_vm._v("Verify Selected")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.onImportSelectedRecords()
                    }
                  }
                },
                [_vm._v("Import Selected")]
              )
            ])
          : _c("span", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.onVerifyRecords()
                    }
                  }
                },
                [_vm._v("Verify Records")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.onImportClick()
                    }
                  }
                },
                [_vm._v("Complete Import")]
              )
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }