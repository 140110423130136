<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <form @submit.prevent="processValid()">
              <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/updatevariableamounts/" />Variable Amount Import Details</h2>
              </div>
              <div class="idb-block-content">
                <b-form-group :label-cols="4" class="col-sm-4" horizontal label="Import status">
                  <b-badge v-b-tooltip.hover :title="translateStatus(uploadHistory.status)" :variant="getSeverityClass(uploadHistory.status)">{{ translateStatus(uploadHistory.status) }}</b-badge>
                </b-form-group>
                <b-form-group :label-cols="4" class="col-sm-4" horizontal label="Rows in File">
                  {{ uploadHistory.totalRows }}
                </b-form-group>
                <b-form-group :label-cols="4" class="col-sm-4" horizontal label="Rows Imported">
                  {{ uploadHistory.importedRows }}
                </b-form-group>
                <b-form-group :label-cols="4" class="col-sm-4" horizontal label="Invalid rows">
                  {{ uploadHistory.failedRows }}
                </b-form-group>
                <b-form-group  :label-cols="12" class="col-sm-12" label-horizontal :label="truncatedMessagesLabel">
                  <b-form-textarea id="messagesTextArea"
                     v-model="messagesToDisplay"
                     placeholder="No validation errors found"
                     :rows="3"
                     :max-rows="6" readonly/>
                </b-form-group>
                <b-form-group  :label-cols="12" class="col-sm-12" label-horizontal :label="truncatedCsvLabel">
                  <b-form-textarea id="textarea1"
                     v-model="csvToDisplay"
                     placeholder="No validation errors found"
                     :rows="3"
                     :max-rows="6" readonly/>
                </b-form-group>
                <b-button @click="download" :disabled="uploadHistory.badRows !== undefined">Download Failed Rows</b-button>
              </div>
              <div class="idb-block-footer">&nbsp;
                <b-button variant="primary" @click.prevent="removeAction" :disabled="isLoading" v-if="actionId && actionId !== null">Clear Action</b-button>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import FileSaver from 'file-saver'
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  props: {
    uploadId: String
  },
  computed: {
    csvToDisplay () {
      if (this.uploadHistory && this.uploadHistory.failedRows > 100) {
        var msgArray = this.uploadHistory.badRows.split('\n').slice(0, 100)
        return msgArray.join('\n')
      }
      return this.uploadHistory.badRows
    },
    messagesToDisplay () {
      if (this.uploadHistory && this.uploadHistory.failedRows > 100) {
        var rowArray = this.uploadHistory.messages.split('\n').slice(0, 100)
        return rowArray.join('\n')
      }
      return this.uploadHistory.messages
    },
    truncatedCsvLabel () {
      var addenda = ''
      if (this.uploadHistory && this.uploadHistory.failedRows > 100) {
        addenda = ' (limited to 100 lines)'
      }
      return 'Failed CSV Rows' + addenda
    },
    truncatedMessagesLabel () {
      var addenda = ''
      if (this.uploadHistory && this.uploadHistory.failedRows > 100) {
        addenda = ' (limited to 100 lines)'
      }
      return 'Validation Messages ' + addenda
    }
  },
  data () {
    return {
      importUrl: '',
      confirmUrl: '',
      uploadHistory: {
        uploadedFileId: '',
        filename: '',
        totalRows: 0,
        importedRows: 0,
        failedRows: 0,
        stagingProgress: 0,
        status: 0,
        uploadDate: '',
        paygateId: '',
        originalFilename: '',
        groupId: '',
        messages: '',
        badRows: null,
        cleared: false
      },
      actionId: null,
      vaStatusConnection: null,
      hubUrl: null
    }
  },
  async created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    this.hubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/variableupdatedetails?uploadid=' + this.uploadId
  },
  mounted () {
    this.importUrl = process.env.VUE_APP_DDMS_API_URL + 'variableamount/' + this.uploadId
    this.confirmUrl = process.env.VUE_APP_DDMS_API_URL + 'variableamount/' + this.uploadId + '/confirm'
    this.actionId = this.$route.query.actionId
    axios.get(this.importUrl, { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true }).then((response) => {
      this.uploadHistory = response.data
    })
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.vaStatusConnection = new HubConnectionBuilder().withUrl(this.hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.vaStatusConnection.start()
        this.vaStatusConnection.on('RefreshUploads', data => {
          this.uploadHistory = data
        })
      })
  },
  methods: {
    removeAction () {
      axios.delete(this.importUrl + '/clearaction', { params: { 'paygateid': this.$store.state.common.paygateId, actionId: this.actionId }, showload: true }).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
          this.$router.push('/actions')
        }
      )
    },
    download () {
      let blob = new Blob([ this.uploadHistory.invalidRowCsv ], { type: 'text/csv' })
      let url = window.URL.createObjectURL(blob)
      FileSaver.saveAs(blob, `${this.uploadHistory.uploadedFileId}-invalid-rows.csv`)
      window.URL.revokeObjectURL(url)
    },
    deleteUpload () {
      axios.post(this.importUrl + '/clear', { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('Record deleted.')
        this.$router.push({ path: '/data/variableamount/' })
      })
    },
    translateStatus (status) {
      var statusStr = ''
      switch (status) {
        case 0 :
          statusStr = 'Queued'
          break
        case 1 :
          statusStr = 'Preparing'
          break
        case 2 :
          statusStr = 'Staging'
          break
        case 3 :
          statusStr = 'Processing'
          break
        case 4 :
          statusStr = 'Complete'
          break
        case 5 :
          statusStr = 'Error'
          break
        case 6 :
          statusStr = 'Pending'
          break
        case 7 :
          statusStr = 'Warning'
          break
      }
      return statusStr
    },
    stopSocketListener () {
      if (this.vaStatusConnection && this.vaStatusConnection !== null) {
        this.vaStatusConnection.stop()
      }
    },
    getSeverityClass (statusInt) {
      var buttonClass = ''
      switch (statusInt) {
        case 2 : // Staging
          buttonClass = 'info'
          break
        case 4: // Complete
          buttonClass = 'success'
          break
        case 5 : // Error
          buttonClass = 'danger'
          break
        case 7 : // Warning
          buttonClass = 'warning'
          break
        default:
          buttonClass = 'primary'
          break
      }
      return buttonClass
    }
  }
}
</script>
