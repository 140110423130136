// Validation
import licence from '@/Assets/Constants/licence'

const CardValidation = () => import(/* webpackChunkName: "validate-validation" */ '@/Components/Validate/Card/CardValidation')
const BankAccountValidation = () => import(/* webpackChunkName: "validate-validation" */ '@/Components/Validate/BankAccount/BankAccountValidation')
const IbanValidation = () => import(/* webpackChunkName: "validate-validation" */ '@/Components/Validate/IBAN/IbanValidation')

export default [
  {
    path: '/validate/validation/card',
    component: CardValidation,
    meta: {
      licence: licence.validate_cc,
      title: 'Card Validation'
    }
  },
  {
    path: '/validate/validation/BankAccount',
    component: BankAccountValidation,
    meta: {
      licence: licence.validate_modcheck,
      title: 'UK Bank Account Validation'
    }
  },
  {
    path: '/validate/validation/IBAN',
    component: IbanValidation,
    meta: {
      licence: licence.validate_iban,
      title: 'International Bank Account Number Validation'
    }
  }
]
