import { helpers, maxValue } from 'vuelidate/lib/validators'

const numberCount = regexCountValidator(new RegExp('\\d', 'g'))

const upperCaseCount = regexCountValidator(new RegExp('[A-Z]', 'g'))

const nonAlphaNumericCount = regexCountValidator(new RegExp('[^\\w\\d]', 'g'))

const phoneNumber = regex(new RegExp('^^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$'))

const guid = regex(new RegExp('(^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$)'))

const email = regex(/^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i)

function regex (regex) {
  return (value) => {
    // We ain't here to check if it's required just if it matches the regex
    if (typeof value === 'undefined' || value === null || value === '') {
      return true
    }
    return regex.test(value)
  }
}

function regexCountValidator (regex) {
  return (count) => (value) => {
    if (!helpers.req(value)) {
      return false
    }
    if (!count) {
      return regex.test(value)
    } else {
      var matchResult = value.match(regex)
      return matchResult ? matchResult.length >= count : false
    }
  }
}

const exactLength = (exact) => helpers.withParams({ type: 'exactLength', exact },
  (value) => !helpers.req(value) || value.length === exact)

const int32 = maxValue(2147483647)

export { numberCount, upperCaseCount, nonAlphaNumericCount, regex, phoneNumber, exactLength, int32, guid, email }
