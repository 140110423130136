<template>
  <div>
    <!-- Group Details Start -->
    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Name</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.name}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Description</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.description}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Group Type</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.groupType}}</span>
        </div>
      </div>
    </div>

    <!-- Group Details End -->

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Service User Number</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserNumber}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Import Schema</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.importSchema}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.importSchema === 'Mapping'">
      <label class="col-3 offset-1 col-form-label">Mapping</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{mappingTitle}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Bank Account</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.reference}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Payment Siging Method</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.tokenType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Individual Payment Limit</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.paymentLimit}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Submission Payment Limit</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.submissionLimit}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Group Email Address</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.email}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Segregate Group Roles</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.segregateGroupRoles" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="fpsLicenceSettings.proxyGroup">
      <label class="col-3 offset-1 col-form-label">Allow Multi-Block</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.proxyGroup" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Delete Payment Data</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.deletePaymentData" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div v-if="containsAntiFraudList">
      <div class="form-group row">
        <label class="col-3 offset-1 col-form-label">Anti-Fraud List Type</label>
        <div class="col-8">
          <div class="form-control-plaintext">
            <span>{{group.details.antiFraudLists[0].listType}}</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 offset-1 col-form-label">Anti-Fraud List Pre-sub Message Severity</label>
        <div class="col-8">
          <div class="form-control-plaintext">
            <span>{{group.details.antiFraudLists[0].presubValMessageSeverity}}</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 offset-1 col-form-label">Anti-Fraud Lists</label>
        <div class="col-8">
          <div
            class="form-control-plaintext"
            v-for="antiFraudList in group.details.antiFraudLists"
            :key="antiFraudList.name"
          >
            <span>{{antiFraudList.name}}</span>
          </div>
        </div>
      </div>
    </div>

    <approval-settings-report :group="group"></approval-settings-report>

    <auto-file-import-report :group="group"></auto-file-import-report>

    <group-config-users :users="group.users"></group-config-users>
  </div>
</template>

<script>
import GroupConfigUsers from './GroupConfigUsers'
import AutoFileImportReport from './AutoFileImportReport'
import ApprovalSettingsReport from './ApprovalSettingsReport'
import axios from 'axios'

export default {
  props: {
    group: {
      required: true,
      type: Object
    },
    fpsLicenceSettings: {
      type: Object
    }
  },
  components: {
    GroupConfigUsers,
    AutoFileImportReport,
    ApprovalSettingsReport
  },
  data () {
    return {
      mappingTitle: null,
      containsAntiFraudList: false
    }
  },
  async created () {
    try {
      // Get Mapping
      if (this.group.details.mappingId) {
        const mappingResponse = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.group.details.mappingId}`, { showerror: true, errormessage: 'Failed to get group mapping', name: 'group' })
        this.mappingTitle = mappingResponse.data.mapping.title
      }
    } catch {

    }

    if (this.group.details.antiFraudLists.some(() => true)) {
      this.containsAntiFraudList = true
    }
  }
}
</script>

<style>
</style>
