<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          {{status}} News Story
          <help-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <!-- Title -->
        <div class="form-group row" :class="{invalid: $v.newsStory.title.$error}">
          <label class="label-control col-md-3 required">Title</label>
          <div class="col-md-9">
            <input type="text" class="form-control" v-model="$v.newsStory.title.$model" />
            <!-- Validation -->
            <validation-messages v-model="$v.newsStory.title" name="title"></validation-messages>
          </div>
        </div>

        <!-- Intro -->
        <div class="form-group row" :class="{invalid: $v.newsStory.intro.$error}">
          <label class="label-control col-md-3 required">Intro</label>
          <div class="col-md-9">
            <input type="text" class="form-control" v-model="$v.newsStory.intro.$model" />
            <!-- Validation -->
            <validation-messages v-model="$v.newsStory.intro" name="intro"></validation-messages>
          </div>
        </div>

        <!-- Image URL -->
        <div class="form-group row" :class="{invalid: $v.newsStory.imageUrl.$error}">
          <label class="label-control col-md-3 required">Image URL</label>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              title="Image"
              v-model="$v.newsStory.imageUrl.$model"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.newsStory.imageUrl" name="image URL"></validation-messages>
          </div>
        </div>

        <!-- Enabled -->
        <div class="form-group row">
          <label class="label-control col-md-3">
            <span
              v-b-popover.hover.top="'Determines if the story is enabled or disabled. Disabled stories will not be shown to users.'"
            >Enabled</span>
          </label>
          <div class="col-md-9">
            <p-check name="check" class="p-switch" color="primary " v-model="newsStory.enabled"></p-check>
          </div>
        </div>

        <!-- Sticky -->
        <div class="form-group row">
          <label class="label-control col-md-3">
            <span
              v-b-popover.hover.top="'Determines if the story is sticky.  Sticky stories always appear at the top of the story list giving it a prominent position.'"
            >Sticky</span>
          </label>
          <div class="col-md-9">
            <p-check name="check" class="p-switch" color="primary " v-model="newsStory.sticky"></p-check>
          </div>
        </div>

        <!-- Order -->
        <div class="form-group row" :class="{invalid: $v.newsStory.order.$error}">
          <label class="label-control col-md-3">
            <span
              v-b-popover.hover.top="'Determines the order, sticky will overwite this and always be at the top, if there are conflicting orders then created date will be used'"
            >Order</span>
          </label>
          <div class="col-md-2">
            <input type="number" class="form-control" v-model="$v.newsStory.order.$model" min="1" />

            <!-- Validation -->
            <validation-messages v-model="$v.newsStory.order" name="order number"></validation-messages>
          </div>
        </div>

        <!-- body -->
        <div class="form-group row" :class="{ invalid: $v.newsStory.body.$error }">
          <div class="col-md-12">
            <div ref="editor"></div>
            <!-- Validation -->
            <validation-messages v-model="$v.newsStory.body" name="body"></validation-messages>
          </div>
        </div>
        <br />
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="isLoading"
          @click="checkValidation() && saveStory()"
        >{{ status === "Edit" ? "Save" : status }}</button>
        <button
          class="btn btn-secondary-outline pull-right ml-3"
          type="button"
          @click="back"
          :disabled="isLoading"
        >Cancel</button>
        <button
          v-if="status === 'Edit'"
          class="btn btn-danger pull-right"
          @click="deleteStory"
          type="button"
          :disabled="isLoading"
        >Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { required, maxLength, url, minValue } from 'vuelidate/lib/validators'

import colours from '@/Assets/Constants/colours'

// Quill
import Quill from 'quill'

import { ImageDrop } from 'quill-image-drop-module'
import BlotFormatter from 'quill-blot-formatter'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Quill Setup
var Link = Quill.import('formats/link')
const Inline = Quill.import('blots/inline')

class CustomColor extends Inline {
  constructor (domNode, value) {
    super(domNode, value)

    // Map <font> properties
    domNode.style.color = domNode.color

    const span = this.replaceWith(new Inline(Inline.create()))

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span)
      if (child.unwrap) child.unwrap()
    })

    this.remove()

    return span
  }
}

CustomColor.blotName = 'customColor'
CustomColor.tagName = 'FONT'

Quill.register(CustomColor, true)
var builtInFunc = Link.sanitize
Link.sanitize = function customSanitizeLinkInput (linkValueInput) {
  var val = linkValueInput

  // do nothing, since this implies user's already using a custom protocol
  if (/^\w+:/.test(val));
  else if (!/^https?:/.test(val)) { val = 'http:' + val }

  return builtInFunc.call(this, val) // retain the built-in logic
}
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/blotFormatter', BlotFormatter)

export default {
  mixins: [DataLeaveMixin, loading],
  props: ['status', 'id'],
  data () {
    return {
      editor: null,
      newsStory: {
        title: '',
        intro: '',
        imageUrl: '',
        body: '',
        enabled: true,
        sticky: false,
        order: 1
      }
    }
  },
  async mounted () {
    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            [{
              color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', '#C7027F']
            }],
            [{ font: [] }],
            // [{ align: [] }],
            ['clean']
          ],
          table: true,
          handlers: {}
        },
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true
        },
        imageDrop: true,
        blotFormatter: {}
      },
      theme: 'snow'
    })

    var component = this
    this.editor.on('text-change', (delta, oldDelta, source) => {
      let html = this.$refs.editor.children[0].innerHTML

      if (html === '<p><br></p>' || html === '<p><br></p><p><br></p>') {
        html = ''
      }
      component.$v.newsStory.body.$model = html
    })
  },
  async created () {
    if (!this.id) {
      this.isNewStory = true
    } else {
      this.isNewStory = false
      await this.getStory()
    }
  },
  methods: {
    async getStory () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}newsstory/${this.id}`,
          {
            showload: true,
            showerror: true,
            errormessage: 'Maintenance window failed to load'
          })
        this.newsStory = response.data
        this.editor.pasteHTML(this.newsStory.body)
      } catch { }
    },
    async saveStory () {
      try {
        if (this.status === 'Create') {
          await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}newsstory`, this.newsStory,
            { showload: true, showerror: true, errormessage: 'News story failed to create' })
          this.$toastr.s(`The story '${this.newsStory.title}' was successfully created.`)
        } else {
          await axios.patch(`${process.env.VUE_APP_PLATFORM_API_URL}newsstory`, this.newsStory,
            { showload: true, showerror: true, errormessage: 'News story failed to create' })
          this.$toastr.s(`The story '${this.newsStory.title}' was successfully updated.`)
        }
        this.$v.$reset()
        this.$router.push('/admin/system/stories')
      } catch { }
    },
    async deleteStory () {
      try {
        var result = await this.$swal({
          title: 'Delete News Story',
          text: 'Are you sure you want to delete this news story?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })

        if (result) {
          try {
            await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}newsstory/${this.newsStory.id}`,
              { showload: true, showerror: true, errormessage: 'News Story failed to delete' })
            this.$v.$reset()

            this.$toastr.s(`The story '${this.newsStory.title}' was successfully deleted.`)
            this.$router.push('/admin/system/stories')
          } catch { }
        }
      } catch { }
    }
  },
  validations: {
    newsStory: {
      title: {
        required,
        maxLength: maxLength(256)
      },
      body: {
        required
      },
      intro: {
        required,
        maxLength: maxLength(256)
      },
      imageUrl: {
        required,
        url
      },
      order: {
        minValue: minValue(1)
      }
    }
  }
}
</script>
