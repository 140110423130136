var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "downloadReports" } }, [
    _c("object", {
      attrs: {
        id: "PayGatePlugin",
        classid: "clsid:8C1E006B-FDF1-4AEF-9B8A-83D8004C2E8B",
        hidden: "hidden"
      }
    }),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("help-icon", {
                  attrs: { docPath: "/paygate-collections/reconciliation/" }
                }),
                _vm._v("Synchronise " + _vm._s(this.networkName) + " Data"),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", [
              _c("p", [
                _vm._v(
                  "Manually synchronise your Direct Debit records with " +
                    _vm._s(this.networkName) +
                    " Advices. Once the login button has been pressed, you will be prompted to press a button to sign a login response."
                )
              ]),
              _c("p", [
                _vm._v(
                  "Once logged in, DDMS will synchronise with " +
                    _vm._s(this.networkName) +
                    " to update your records, and generate alerts if necessary."
                )
              ])
            ]),
            _c("div", { staticClass: "row form-group mb-3" }, [
              _c("div", { staticClass: "col-md-2 required" }, [
                _vm._v(" Select a Group ")
              ]),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("group-select", {
                    attrs: { groups: _vm.customerGroups, clearable: false },
                    on: {
                      input: function($event) {
                        return _vm.selectGroup()
                      }
                    },
                    model: {
                      value: _vm.selectedGroupId,
                      callback: function($$v) {
                        _vm.selectedGroupId = $$v
                      },
                      expression: "selectedGroupId"
                    }
                  })
                ],
                1
              )
            ]),
            _vm.isLoggedIn === false
              ? _c("div", { staticClass: "row form-group" }, [
                  _c("div", { staticClass: "col-md-1" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "button",
                          disabled: this.enableLogin === false || _vm.isLoading
                        },
                        on: { click: _vm.loginStart }
                      },
                      [_vm._v("Login")]
                    )
                  ]),
                  _vm._m(0),
                  _c("div", { staticClass: "col-md-1" }),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light",
                        attrs: {
                          type: "button",
                          disabled: this.enableLogin === false || _vm.isLoading
                        },
                        on: { click: _vm.viewVocalinkCertificate }
                      },
                      [_vm._v("Vocalink Certificate")]
                    )
                  ])
                ])
              : _c("div", [
                  _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            type: "button",
                            disabled: this.loggingIn === true || _vm.isLoading
                          },
                          on: { click: _vm.logoff }
                        },
                        [_vm._v("Log Off")]
                      )
                    ]),
                    _vm._m(1),
                    _c("div", { staticClass: "col-md-1" }),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: {
                            type: "button",
                            disabled: this.loggingIn === true || _vm.isLoading
                          },
                          on: { click: _vm.viewVocalinkCertificate }
                        },
                        [_vm._v("Vocalink Certificate")]
                      )
                    ])
                  ]),
                  _c("br"),
                  _vm.haveReportList === true
                    ? _c("div", { staticClass: "row form-group" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "vue-good-table",
                              {
                                ref: "reportList",
                                attrs: {
                                  columns: _vm.columns,
                                  rows: _vm.rows,
                                  "select-options": {
                                    enabled: true,
                                    selectOnCheckboxOnly: true,
                                    selectionInfoClass: "report-row-selected",
                                    clearSelectionText: ""
                                  },
                                  "pagination-options": {
                                    enabled: true,
                                    perPage: 10,
                                    dropdownAllowAll: false
                                  },
                                  styleClass: "vgt-table striped bordered"
                                },
                                on: { "on-cell-click": _vm.viewReportModal },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "table-row",
                                      fn: function(props) {
                                        return [
                                          props.column.field === "viewReportXml"
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  { staticClass: "grid-link" },
                                                  [_vm._v("XML")]
                                                )
                                              ])
                                            : props.column.field ===
                                              "viewReportHtml"
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  { staticClass: "grid-link" },
                                                  [_vm._v("HTML")]
                                                )
                                              ])
                                            : props.column.field ===
                                              "viewReportCsv"
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  { staticClass: "grid-link" },
                                                  [_vm._v("CSV")]
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.formattedRow[
                                                      props.column.field
                                                    ]
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2375276843
                                )
                              },
                              [
                                _c("template", { slot: "loadingContent" }, [
                                  _c("h1", [_vm._v("Loading...")])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { width: "800px" },
                                    attrs: { slot: "selected-row-actions" },
                                    slot: "selected-row-actions"
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-4" }),
                                      _c("div", { staticClass: "col-md-3" }, [
                                        _vm._v("Download Format")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-2" },
                                        [
                                          _c("b-select", {
                                            attrs: {
                                              options: _vm.downloadFormats
                                            },
                                            model: {
                                              value: _vm.selectedDownloadFormat,
                                              callback: function($$v) {
                                                _vm.selectedDownloadFormat =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression:
                                                "selectedDownloadFormat"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "col-md-3" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary pull-right",
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                this.selectedDownloadFormat ===
                                                  "" || _vm.isLoading
                                            },
                                            on: { click: _vm.downloadReports }
                                          },
                                          [_vm._v("Download")]
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPluginLink,
            expression: "showPluginLink"
          }
        ]
      },
      [
        _c("a", { attrs: { href: _vm.downloadPluginUrl } }, [
          _vm._v("A new version of the Paygate plugin is required.")
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showeSigner,
            expression: "showeSigner"
          }
        ],
        attrs: { id: "eSigner" }
      },
      [_c("span", { domProps: { innerHTML: _vm._s(_vm.eSignerHtml) } })]
    ),
    _c(
      "div",
      [
        _c("viewReportModal", {
          attrs: {
            reportData: _vm.reportContent,
            reportFormat: _vm.selectedReportFormat
          },
          on: { close: _vm.closeViewReport },
          model: {
            value: _vm.isModalVisible,
            callback: function($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("span", {
        staticClass: "fa fa-unlock",
        staticStyle: { "font-size": "200%" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("span", {
        staticClass: "fa fa-lock",
        staticStyle: { "font-size": "200%" }
      }),
      _vm._v(" Securely logged into Vocalink ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }