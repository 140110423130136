<template>
  <div>
    <div id="manageSchedule" class="pb-5">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <form @submit.prevent="checkValidation() && importData()">
              <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/importpayer/" />Import Data<favourite-icon></favourite-icon></h2>
              </div>
              <div class="idb-block-content">
                <div class="row form-group">
                  <div class="col-md-2 required">
                    Select a Group
                  </div>
                  <div class="col-md-4">
                    <group-select
                      v-model="$v.group.$model"
                      :groups="customerGroups"
                      @input="customerGroupsChanged"
                      :clearable="false"
                    ></group-select>
                    <validation-messages name="Group" v-model="$v.group" />
                  </div>
                </div>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  label="Tick if payers are new and require a mandate setting up with the bank (0N)"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="newPayers"
                    @change="checkNewMandate()"
                  ></p-check>
                </b-form-group>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  label="Tick if payers require migrating to AUDDIS (0S)"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="migration"
                    @change="checkMigration()"
                  ></p-check>
                </b-form-group>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  label="Update payer if duplicate reference found"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="updateIfDuplicate"
                  ></p-check>
                </b-form-group>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  v-if="hasPayerPortal"
                  label="Tick if payers should have a Payer Portal User created upon import"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="createPayerPortalUser"
                  ></p-check>
                </b-form-group>
                <b-container>
                  <dropzoneLike ref="serverFileUploader"
                    @fileAdded="publicFileAdded"
                    @fileRemoved="publicFileRemoved"
                    :groupId="group"
                    :dropzoneOptions="dropzoneOptions"
                    :dropzoneUrl="dropzoneUrl"
                    :secureUrl="secureUrl">
                    </dropzoneLike>
                </b-container>
              </div>
              <div class="idb-block-footer">
                <b-button type="submit" variant="primary" :disabled="!importEnabled || isLoading || !canUpload">Import</b-button>
              </div>
            </form>
          </div>
          <div class="idb-block mb-20">
            <div class="idb-block-title">
              <h2>Your Uploads</h2>
            </div>
            <div class="idb-block-content">
              <vue-good-table
                mode="remote"
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-row-click="onRowClick"
                @on-cell-click="onCellClick"
                :columns="columns"
                :rows="uploads"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                    enabled: false
                    }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Payers Available</div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button
                    @click.prevent="load" :disabled="isLoading"
                    class
                    variant="link"                    
                    v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  >
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button
                    @click.prevent="deleteRecords"
                    class
                    variant="link"                    
                    v-b-popover.hover.top.d500="'Clear incomplete uploads'"
                  >
                    <i class="fa fa-times pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'uploadStatus'">
                    <div class="fileNameAligner">
                      <b-badge
                        v-b-tooltip.hover
                        :title="convertStatus(props.row.uploadStatus)"
                        :variant="getSeverityClass(props.row.uploadStatus)"
                      >{{ convertStatus(props.row.uploadStatus) }}</b-badge>
                    </div>
                  </span>
                  <span v-else-if="props.column.label === 'Processed'">
                    <div class="uploadPercentageContainer">
                      <div
                        class="uploadPercentage"
                        :class="buildPercentageBarClass(percentageCompleted(props.row))"
                        :style="{ width: percentageCompleted(props.row) +'%' }"
                      >&nbsp;</div>
                      <div
                        class="uploadPercentageLabel"
                      >{{ props.row.processedRows +'/'+props.row.totalRows }}</div>
                    </div>
                  </span>
                  <span v-else-if="props.column.label === 'Validated'">
                    <div class="uploadPercentageContainer">
                      <div
                        class="uploadPercentage"
                        :class="buildPercentageBarClass(validationCompleted(props.row))"
                        :style="{ width: validationCompleted(props.row) +'%' }"
                      >&nbsp;</div>
                      <div class="uploadPercentageLabel">{{ validationCompleted(props.row) }}%</div>
                    </div>
                  </span>
                  <span
                    v-else-if="props.column.field === 'processedRows' || props.column.field === 'invalidRows' || props.column.field === 'totalRows'"
                  >
                    <div class="numericAligner">{{props.formattedRow[props.column.field]}}</div>
                  </span>
                  <span v-else-if="props.column.label === ''">
                    <b-button
                      v-b-tooltip.hover
                      title="Delete upload"
                      variant="outline-danger"
                      @click="clearUpload(props.row.uploadedFileId)"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i> Clear
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field === 'uploadDate'">
                    <div class="fileNameAligner">{{formatDate(props.row.uploadDate)}}</div>
                  </span>
                  <span v-else>
                    <div class="fileNameAligner">{{props.formattedRow[props.column.field]}}</div>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import { required } from 'vuelidate/lib/validators'
import ValidationMessages from '@/Assets/Components/Validation/ValidationMessages.vue'
import EventBus from '@/Lib/eventBus'
import moment from 'moment'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    const paygateId = this.$store.state.common.paygateId
    this.uploadHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/uploads'
    if (paygateId) {
      this.dropzoneUrl += '?paygateId=' + paygateId
      this.uploadHubUrl += '?paygateId=' + paygateId
    }
    this.dropzoneOptions.url = this.dropzoneUrl
    this.dropzoneOptions.secureUrl = this.dropzoneUrl
  },
  data () {
    return {
      uploadStatusConnection: null,
      uploadHubUrl: '',
      fileFormats: ['csv'], // xml, json
      dataTypes: ['Payer'],
      dropzoneOptions: {
        maxFilesize: 250, // MB
        maxFiles: 4,
        acceptedFiles: '.csv',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a delimited file such as a CSV file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload payers',
        subtitle: '...or click to select a file from your computer'
      },
      dataType: 'Payer',
      newPayers: false,
      migration: false,
      createPayerPortalUser: false,
      group: null,
      fileFormat: 'csv',
      bearerToken: '',
      fileIds: [],
      uploads: [],
      columns: [
        {
          label: 'File Name',
          field: 'originalFileName'
        },
        {
          label: 'Status',
          field: 'uploadStatus',
          sortable: false
        },
        {
          label: 'Upload Date',
          field: 'uploadDate'
        },
        {
          label: 'Validated',
          field: this.validationCompleted,
          sortable: false
        },
        {
          label: 'Invalid Rows',
          field: 'invalidRows',
          sortable: false
        },
        {
          label: 'Processed',
          field: this.percentageCompleted,
          sortable: false
        },
        {
          label: '',
          field: () => { }
        }
      ],      
      serverParams: {
        sort: [{ field: 'uploadDate', type: 'asc' }]        
      },
      publicFiles: [],
      updateIfDuplicate: false
    }
  },
  components: {
    ValidationMessages,
    dropzoneLike
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    importEnabled () {
      return this.fileIds.length > 0
    },
    dropzoneUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}import`
    },
    secureUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}import/${this.group}`
    },
    hasPayerPortal () {
      return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
    },
    canUpload () {
      return this.group !== null && this.fileIds.length > 0
    }
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.uploadStatusConnection = new HubConnectionBuilder().withUrl(this.uploadHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.uploadStatusConnection.start()
        this.uploadStatusConnection.on('RefreshUploads', data => {
          this.load()
        })
        this.uploadStatusConnection.on('UploadComplete', data => {
          this.$toastr.s(data.file.originalFileName + '. successfully uploaded')
        })
      })
    await this.loadGroups(this.paygateId)

    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.group = defaultGroupId
      }
    }
  },
  methods: {
    buildPercentageBarClass (percentDone) {
      var retVal = ''
      switch (percentDone) {
        case 0:
          retVal = 'NoPadding'
          break
        case 100:
          retVal = 'Completed'
          break
        default:
          retVal = 'Uploading'
          break
      }
      return retVal
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupId
      }
    },
    percentageCompleted (row) {
      var percentage = 0
      if (row.totalRows > 0) {
        percentage = (row.processedRows / row.totalRows) * 100
        if (percentage > 100) {
          percentage = 100
        }
      }
      return Math.ceil(percentage)
    },
    validationCompleted (row) {
      var percentage = 0
      if (row.totalRows > 0) {
        percentage = ((row.validRows + row.invalidRows) / row.totalRows) * 100
        if (percentage > 100) {
          percentage = 100
        }
      }
      return Math.ceil(percentage)
    },
    stopSocketListener () {
      this.uploadStatusConnection.stop()
    },
    load () {
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}import/`, { headers: { 'x-cust-meta': this.$store.state.common.paygateId }, params: { ...this.buildGoodTableQuery() }, showload: true }).then((response) => {
        this.uploads = response.data.data
        // state.metaData.tableLinks = response.data.links
        // this.serverParams.totalPages = response.data.meta.totalPages
        this.totalRecords = response.data.meta.totalItems
      })
    },
    importData () {
      // method
      console.log('IMPORT DATA CLICKED')
      console.log(this.createPayerPortalUser)
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}import/process`, {
        fileIds: this.fileIds,
        groupId: this.group,
        paygateId: this.paygateId,
        mandateRequired: this.newPayers !== null ? this.newPayers : false,
        migrationRequred: this.migration !== null ? this.migration : false,
        payerPortalUserRequired: !!this.createPayerPortalUser,
        fileFormat: 'csv',
        importType: 'Payer',
        updateIfDuplicate: this.updateIfDuplicate }).then((response) => {
        this.$toastr.s('Uploads queued for processing')
        this.$refs['serverFileUploader'].removeAllFiles()
        this.fileIds = []
      }, { params: { paygateid: this.$store.state.common.paygateId }, showload: true }).then(() => { this.load() })
    },
    afterSuccess (file, response) {
      this.fileIds.push(response)
    },
    fileAdded (file) {
      this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
    },
    fileRemoved (file, error, xhr) {

    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
      formData.append('groupId', this.group)
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (e) {
      // todo
    },
    onCellClick (e) {
      if (e.column.label !== '') {
        this.$router.push({ path: '/collections/data/import/' + e.row.uploadedFileId })
      }
    },
    convertStatus (statusInt) {
      var statusStr = ''
      switch (statusInt) {
        case 0:
          statusStr = 'Queued'
          break
        case 1:
          statusStr = 'Uploading'
          break
        case 2:
          statusStr = 'Complete'
          break
        case 3:
          statusStr = 'Warning'
          break
        case 4:
          statusStr = 'Error'
          break
        case 5:
          statusStr = 'Pending'
          break
        case 6:
          statusStr = 'Processing'
          break
        case 7:
          statusStr = 'Preparing'
          break
        case 8:
          statusStr = 'Validating'
          break
        case 9:
          statusStr = 'Duplicate References'
          break
        case 10:
          statusStr = 'Deleted'
          break
      }
      return statusStr
    },
    getSeverityClass (statusInt) {
      var buttonClass = ''
      switch (statusInt) {
        case 2: // Staging
          buttonClass = 'success'
          break
        case 4: // Error
          buttonClass = 'danger'
          break
        case 3: // Warning
        case 9:
          buttonClass = 'warning'
          break
        default:
          buttonClass = 'primary'
          break
      }
      return buttonClass
    },
    checkMigration () {
      this.newPayers = false
    },
    checkNewMandate () {
      this.migration = false
    },
    deleteRecords () {
      this.serverParams.paygateid = this.$store.state.common.paygateId
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}import/deleteinvalid`, { params: this.serverParams, headers: { 'x-cust-meta': this.$store.state.common.paygateId }, showload: true }).then((response) => {
        this.uploads = response.data.data
        // state.metaData.tableLinks = response.data.links
        // this.serverParams.totalPages = response.data.meta.totalPages
        this.totalRecords = response.data.meta.totalItems
      })
    },
    clearUpload (id) {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}import/` + id + '/', { headers: { 'x-cust-meta': this.$store.state.common.paygateId }, params: { paygateid: this.$store.state.common.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('Record deleted, along with all staged data and uploaded files.')
        this.load()
      })
    },
    formatDate (dateString) {
      return moment(dateString).format('MMMM Do YYYY, h:mm:ss a')
    },
    async customerGroupsChanged () {
      if (this.fileIds.length > 0) {
        this.importEnabled = false
        await axios.put(`${process.env.VUE_APP_DDMS_API_URL}import/updategroups`, { fileIds: this.fileIds, groupId: this.group }, { params: { paygateid: this.$store.state.common.paygateId }, showload: true })
        this.$toastr.s('Updated pending upload groups')
        this.importEnabled = true
      }
    },
    async publicFileAdded (addedFile) {
      console.log(addedFile)
      let response = null
      switch (addedFile.type) {
        case 'Secure Cloud Folder':
          response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}import/public/`, { groupId: this.group, path: addedFile.path, fileName: addedFile.fileName }, { showload: true })
          if (response) {
            addedFile.id = response.data
            this.publicFiles.push(addedFile)
            this.fileIds.push(response.data)
            console.log('file id added')
          }
          break
        case 'Browser Dialog':
          this.fileIds.push(addedFile.uploadResponse)
          break
        case 'Restricted File Browser':
          response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}import/lastuploaded/${this.group}/?fileName=${addedFile.fileName}`, { showload: true })
          this.fileIds.push(response.data)
          break
      }
      console.log('file added')
    },
    async publicFileRemoved (removedFile) {
      let removalIndex = 0
      for (var i = 0; i < this.publicFiles.length; i++) {
        if (removedFile.fileName === this.publicFiles[i].fileName) {
          removalIndex = i
          break
        }
      }
      for (var f = 0; f < this.fileIds.length; f++) {
        if (this.fileIds[f] === this.publicFiles[removalIndex].id) {
          this.fileIds.splice(f, 1)
          break
        }
      }
      this.publicFiles.splice(removalIndex, 1)
    }
  },
  validations: {
    group: { required },
    fileFormat: { required },
    dataType: { required }
  }
}
</script>
<style scoped>
.uploadPercentageContainer {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
}
.uploadPercentage {
  position: absolute;
  display: block;
  width: 0%;
  z-index: 0;
  padding: 10px 0px 10px 0px;
}
.uploadPercentageLabel {
  position: absolute;
  display: block;
  width: 100%;
  z-index: 1;
  text-align: center;
  padding: 10px 39px 10px 38px;
}
.numericAligner {
  text-align: center;
  padding: 10px 39px 10px 38px;
}
.fileNameAligner {
  padding: 10px 0px 10px 0px;
}
.NoPadding {
  padding: 0;
}
.messageContainer {
  padding: 10px 39px 10px 38px;
  width: 100%;
  display: block;
  text-align: center;
}
.Error,
.DuplicateReferences,
.Deleted {
  background-color: rgb(243, 102, 102);
  color: white;
}
.Warning {
  background-color: rgb(241, 196, 112);
}
.Pending {
  background-color: rgb(241, 196, 112);
}
.Uploading,
.Validating {
  background-color: lightskyblue;
}
.Complete {
  background-color: #86da9a;
}
.Uploading {
  background-color: lightskyblue;
}
.Completed {
  background-color: #86da9a;
}
</style>
