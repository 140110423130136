var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.action.id
    ? _c(
        "actions-block",
        {
          attrs: {
            action: _vm.action,
            status: _vm.status,
            from: _vm.from,
            name: "Anti-Fraud List"
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [_vm._v("Anti-Fraud List Details")])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _vm.status === "Edit"
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-secondary",
                      attrs: { role: "alert" }
                    },
                    [
                      _vm._v(" The previous details are shown in "),
                      _c("span", { staticClass: "del" }, [_vm._v("red")]),
                      _vm._v(", the new details in "),
                      _c("span", { staticClass: "ins" }, [_vm._v("green")]),
                      _vm._v(". ")
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Name")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.name, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Description")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.description, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("List Type")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.listType, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm.itemCount > 0
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "label",
                      { staticClass: "col-sm-2 text-right col-form-label" },
                      [_vm._v("Anti-Fraud List Items")]
                    )
                  ])
                : _vm._e(),
              _vm.itemCount > 0
                ? _c("div", { staticClass: "row form-group" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("vue-good-table", {
                          ref: "localItems",
                          attrs: {
                            columns: _vm.columns,
                            rows: _vm.pendingItems,
                            lineNumbers: true,
                            totalRows: _vm.itemCount,
                            "pagination-options": {
                              enabled: true,
                              perPage: 10,
                              dropdownAllowAll: false
                            },
                            styleClass: "vgt-table striped bordered"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }