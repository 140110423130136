<template>
  <div>
    <fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closePopup()">
    </fileBrowser>
    <h3>BACS Submission Validation<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <!-- <br/>
    <div class="form-group">
      <label>Group</label>
    <br/>
    <group-select v-model="selectedNode.props.s1.value" :groups="options" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>

    </div> -->
    <br/>
    <div class="form-group">
      <label>Service</label>
      <br>
      <p-radio value="BACS" color="primary" v-model="selectedNode.props.s4.value">BACS</p-radio>
      <p-radio value="Faster Payments" color="primary" v-model="selectedNode.props.s4.value">Faster Payments</p-radio>
    </div>
    <br/>
    <div class="form-group">
      <label class="required" >Submission Reference</label>
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The Submission Reference field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="">Contra Narrative</label>
      <input type="text"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        class="form-control"
        v-model="selectedNode.props.s3.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <br/>

    <div class="form-group">
      <label>Exception Handling Behaviour</label>
      <br>
      <div class="row">
        <div class="col-md-12">
          <p-radio value="All payment instructions must validate before the entire submission can continue" color="primary" v-model="selectedNode.props.s5.value">All payment instructions must validate before the entire submission can continue</p-radio>
        </div>
        <div class="col-md-12">
          &nbsp;
        </div>
        <div class="col-md-12">
          <p-radio value="Remove from the submision, those payment instructions that do not pass validation" color="primary" v-model="selectedNode.props.s5.value">Remove from the submision, those payment instructions that do not pass validation</p-radio>
        </div>
        <transition name="fadein">
          <div class="col-md-11 offset-md-1" v-if="selectedNode.props.s5.value === 'Remove from the submision, those payment instructions that do not pass validation'" >
            <br/>
            <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b4.value">Duplicates - Payment instructions with a validation result of Duplicate will be removed from the submission</p-check>
            <br/><br/>
            <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b1.value">Warnings - Payment instructions with a validation result of Warning will be removed from the submission</p-check>
            <br/><br/>
            <p-check disabled="true" name="check" class="p-switch" color="primary" v-model="selectedNode.props.b2.value">Errors - Payment instructions with a validation result of Fix will be removed from the submission</p-check>
            <br/>
            <br/>
            <div class="row"  >
              <div class="col-md-2">
                <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b3.value">Save removed transactions to a file</p-check>
              </div>
            </div>
            <br/>
            <br/>
            <transition name="fadein">
            <div class="row" v-if="selectedNode.props.b3.value">

              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-2 offset-md-1">
                    <label>Save Path</label>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="input-group mb-3">
                        <input type="text"
                          class="form-control"
                          :class="{invalid: $v.selectedNode.props.s7.value.$error}"
                          @input="$v.selectedNode.props.s7.value.$touch()"
                          v-model="selectedNode.props.s7.value">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
                        </div>
                      </div>
                        <p class="validationText" v-if="!$v.selectedNode.props.s7.value.maxLength">Maximum length is {{ $v.selectedNode.props.s7.value.$params.maxLength.max }} characters!</p>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-2 offset-md-1">
                    <p-radio value="Exporter" color="primary" v-model="selectedNode.props.s8.value">Exporter</p-radio>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <select v-if="selectedNode.props.s8.value==='Exporter'" class="form-control" :disabled="!selectedNode.props.b3.value"
                          :class="{invalid: $v.selectedNode.props.s6.value.$error}"
                          v-model="selectedNode.props.s6.value">
                          <option v-for="option in exporterOptions" :key="option.value" :value="option.value">
                          {{option.text}}
                        </option>
                      </select>
                      <p class="validationText" v-if="!$v.selectedNode.props.s6.value.required && $v.selectedNode.props.s6.value.$dirty ">If 'Export Errors' is selected, you must set an exporter.</p>
                      <div v-if="exporterOptions.length === 0">
                        <span><strong>Note</strong>  You do not have any available exporters.  Click </span>
                        <a href="/mapping/export/exporters">here</a><span> to create a new importer.</span>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <br v-if="selectedNode.props.s8.value!='Exporter'" /> -->
                <div class="row">
                  <div class="col-md-4 offset-md-1">
                    <p-radio value="Simple JSON" color="primary" v-model="selectedNode.props.s8.value">Simple JSON</p-radio>
                  </div>
                </div>
                <br/>
                <div class="row">
                  <div class="col-md-4 offset-md-1">
                    <p-radio value="Simple CSV" color="primary" v-model="selectedNode.props.s8.value">Simple CSV</p-radio>
                  </div>
                </div>
              </div>
            </div>
            </transition>
          </div>
        </transition>
      </div>
    </div>
    <br/>
    <div class="form-group">
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import axios from 'axios'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
export default {
  components: {
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s2: {
          value: {
            required,
            maxLength: maxLength(18)
          }
        },
        s3: {
          value: {
            maxLength: maxLength(18)
          }
        },
        s6: {
          value: {
            required: requiredIf(function () {
              // Bit of extra logic here to account for a checkbox that can be hiden while invalid
              // but we can ignore it befause it is hidden.
              const c2 = this.selectedNode.props.b3.value
              let c1 = false
              if (this.selectedNode.props.s5.value === 'All payment instructions must validate before the entire submission can continue') c1 = true
              if (c1 && c2) return false
              return c2
            }),
            maxLength: maxLength(128)
          }
        },
        s7: {
          value: {
            maxLength: maxLength(255)
          }
        }
      }
    }
  },
  data () {
    return {
      showModalFileBrowser: false,
      options: [],
      exporterOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s7.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s7.value)
      this.showModalFileBrowser = true
    },
    closePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }

      let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
      if (folder.endsWith('/') || folder.endsWith('\\')) {
        folder = folder.slice(0, -1)
      }
      this.selectedNode.props.s7.value = `${folder}\\${filename}`
    }
  },
  created: async function () {
    // let exporters
    // try {
    //   exporters = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}exporters`)
    //   if (exporters && exporters.data) {
    //     for (const exporter of exporters.data.exporters) {
    //       this.exporterOptions.push({
    //         text: exporter.title,
    //         value: exporter._id
    //       })
    //     }
    //   }
    // } catch (e) {
    //   console.error(e)
    //   this.$snapbar.e('Error getting list of available exporters. (002b)')
    // }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }

  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

</style>
