import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import Auth from '@/Assets/Components/Authentication/auth.js'

export default class {
  constructor (connectionUrl, opts = {}) {
    this.format = opts.format && opts.format.toLowerCase()

    this.connectionUrl = connectionUrl
    this.opts = opts

    this.reconnection = this.opts.reconnection || false
    this.reconnectionAttempts = this.opts.reconnectionAttempts || 50
    this.reconnectionDelay = this.opts.reconnectionDelay || 1000
    this.reconnectTimeoutId = 0
    this.reconnectionCount = 0

    this.passToStoreHandler = this.opts.passToStoreHandler || false
    this.events = this.opts.events || []
    this.pre = this.opts.pre || ''

    this.connect(connectionUrl, opts)

    if (opts.store) {
      this.store = opts.store
    }
    if (opts.mutations) {
      this.mutations = opts.mutations
    }
  }

  connect (connectionUrl, opts = {}) {
    this.WebSocket = new HubConnectionBuilder()
      .withUrl(connectionUrl, {
        accessTokenFactory: async () => Auth.getAccessToken()
      })
      .configureLogging(LogLevel.Debug)
      .build()
    this.WebSocket.serverTimeoutInMilliseconds = 1000 * 1000
    this.WebSocket.send = this.send.bind(this)
    this.WebSocket
      .start()
      .then(() => {
        if (this.store) {
          this.passToStore('SOCKET_' + 'onopen', this.WebSocket)
        }

        this.reconnectionCount = 0
        this.addEvents()
      })
      .catch(() => this.reconnect())
    return this.WebSocket
  }

  send (action, args) {
    this.WebSocket.invoke(action, args)
  }

  reconnect () {
    if (this.reconnectionCount < this.reconnectionAttempts) {
      this.reconnectionCount++
      clearTimeout(this.reconnectTimeoutId)

      this.reconnectTimeoutId = setTimeout(() => {
        if (this.store) {
          this.passToStore('SOCKET_RECONNECT', this.reconnectionCount)
        }

        this.connect(this.connectionUrl, this.opts)
      }, this.reconnectionDelay)
    } else {
      if (this.store) {
        this.passToStore('SOCKET_RECONNECT_ERROR', true)
      }
    }
  }

  addEvents () {
    this.events.forEach((eventType) => {
      this.WebSocket.on(eventType, (event) => {
        if (this.store) {
          this.passToStore('SOCKET_' + eventType, event)
        }
      })
    })

    this.WebSocket.connection.transport.webSocket.onopen = (event) => {
      this.reconnectionCount = 0
    }
    // Add Close and Error Events
    this.WebSocket.connection.transport.webSocket.onerror = (event) => {
      if (this.store) {
        this.passToStore('SOCKET_' + 'onerror', event)
      }
    }

    this.WebSocket.connection.transport.webSocket.onclose = (event) => {
      if (this.store) {
        this.passToStore('SOCKET_' + 'onclose', event)
        this.reconnect()
      }
    }
  }

  passToStore (eventName, event) {
    if (!eventName.startsWith('SOCKET_')) {
      return
    }
    let method = 'commit'
    let target = `${this.pre.toUpperCase()}${eventName.toUpperCase()}`
    let msg = event
    if (this.format === 'json' && event.data) {
      msg = JSON.parse(event.data)
      if (msg.mutation) {
        target = [msg.namespace || '', msg.mutation].filter((e) => !!e).join('/')
      } else if (msg.action) {
        method = 'dispatch'
        target = [msg.namespace || '', msg.action].filter((e) => !!e).join('/')
      }
    }
    if (this.mutations) {
      target = this.mutations[target] || target
    }
    this.store[method](target, msg)
  }

  close () {
    this.WebSocket.stop()
  }
}
