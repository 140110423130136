<template>
  <div id="manualBacsSubmissionBuilder">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Manual BACS Bureau Submission Builder
              <span class="pull-right">
                <a href="#" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>

          <!-- Group Selection -->
          <div class="idb-block-content">
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <strong>Select a Submission Group</strong>
              </div>
              <div class="col-sm-6">
                <group-select
                  v-model="SelectedGroupId"
                  :groups="Groups"
                  :clearable="false"
                  :change="ChangeGroup(SelectedGroupId)"
                ></group-select>
              </div>
            </div>

            <!-- Submission Detail Selection -->
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label class="required" for="submissionReference">Submission Reference</label>
              </div>
              <div class="col-md-6">
                <b-form-input type="text" v-model.trim="$v.submissionReference.$model"></b-form-input>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.submissionReference.$dirty">
                <label
                  class="text-danger small"
                  v-if="!$v.submissionReference.required"
                >A 'Submission Reference' is required</label>
                <label
                  class="text-danger small"
                  v-if="!$v.submissionReference.maxLength"
                >A 'Submission Reference' must be less than {{$v.submissionReference.$params.maxLength.max}} characters</label>
              </div>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label for="contraNarrative">Contra Narrative</label>
              </div>
              <div class="col-md-6">
                <b-form-input type="text" v-model.trim="$v.contraNarrative.$model"></b-form-input>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.contraNarrative.$dirty">
                <label
                  class="text-danger small"
                  v-if="!$v.contraNarrative.maxLength"
                >A 'Contra Narrative' must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
              </div>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label for="paymentDate">Payment Date</label>
              </div>
              <div class="col-md-3">
                <vuejsDatepicker
                  ref="paymentDate"
                  name="paymentDate"
                  id="paymentDate"
                  v-model="paymentDate"
                  format="dd/MM/yyyy"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  :disabledDates="disabledDates"
                  v-on:selected="paymentDateChanged"
                  @focusin.native="dateOnfocus"
                  :monday-first="true"
                  :disabled="disableDatePicker"
                ></vuejsDatepicker>
              </div>

              <div class="col-md-2">
                <label for="processingDate">Processing Date</label>
              </div>
              <div class="col-md-2">{{ processingDate }}</div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              class="btn btn-primary mr-2"
              :disabled="$v.$invalid"
              @click="onEditClick"
            >Edit Submission Details</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  mixins: [Utility, tableFilterMixin, bacsMixin, loading],
  props: {
    id: String
  },
  data () {
    return {
      SelectedGroupId: '',
      Groups: [],
      GroupDisplay: [],
      BUREAUGROUPTYPE: '1',
      paygateId: '',
      submissionReference: '',
      contraNarrative: '',
      processingDate: '',
      paymentDate: '',
      disabledDates: {},
      processingDates: {},
      disableDatePicker: false
    }
  },

  async created () {
    console.log('edit manual bacs submission id', this.id)

    await this.loadSubmission()

    this.disabledDates = await this.setUpBACSDatePicker()
    this.paymentDate = await this.getNextPaymentDate()
    await this.load()
  },
  async mounted () {
    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'

    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate
        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }
      }
      )
  },
  methods: {
    load: _.debounce(async function () {
      this.paygateId = this.$store.state.common.paygateId
      await this.loadGroups()
    }, 800),
    async loadSubmission () {
      console.log('in loadSubmission')
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualbacsbuildersubmission/' + this.id)
        console.log('loadSubmission response', response)
        this.SelectedGroupId = response.data.groupId
        this.submissionReference = response.data.submissionReference
        this.contraNarrative = response.data.contraNarrative
        this.paymentDate = response.data.paymentDate
      } catch (e) {
        console.log('exception ', e)
      }
    },
    async loadGroups () {
      console.log('grouptype in load groups', this.BUREAUGROUPTYPE)
      var payload = { paygateId: this.paygateId, groupType: this.BUREAUGROUPTYPE, groupRole: ['GroupAdministrator'] }
      await this.$store.dispatch('getGroups', payload)
      this.Groups = this.$store.getters.groups
      console.log('this.groups from loadgroups', this.groups)
    },

    ChangeGroup (SelectedGroupId) {
      // Needed?
    },

    async onEditClick () {
      console.log('on edit click')
      try {
        var params = {
          groupId: this.SelectedGroupId,
          submissionReference: this.submissionReference,
          contraNarrative: this.contraNarrative,
          processingDate: this.processingDate,
          bureauSubmissionId: this.id
        }
        console.log('add manual block detail params', params)
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/editmanualbacsbuildersubmission', params, { showload: true })
        console.log('response', response)

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          console.log('bureau submission for builder bureauSubmissionId', this.id)
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: this.id } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        console.log('error', e)
        this.$toastr.e('Failed to edit manual bacs submission', 'Bureau Submission')
      } finally {
      }
    },

    paymentDateChanged (value) {
      this.$store.dispatch('getNextProcessingDate', value.toJSON())
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
          console.log('processingDate: ', this.processingDate)
        })
    }
  },
  validations: {
    submissionReference: {
      required,
      maxLength: maxLength(150)
    },
    contraNarrative: {
      maxLength: maxLength(50)
    }
  }
}

</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
