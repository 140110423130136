var codes = {
  AF2200: 'AF2200',
  AF2201: 'AF2201',
  AF2202: 'AF2202',
  AF9999: 'AF9999',
  B1000: 'B1000',
  B1002: 'B1002',
  B1011: 'B1011',
  B1012: 'B1012',
  B1013: 'B1013',
  B9999: 'B9999',
  BACS1001: 'BACS1001',
  BACS1002: 'BACS1002',
  BACS1003: 'BACS1003',
  BACS1004: 'BACS1004',
  BACS1005: 'BACS1005',
  BACS1006: 'BACS1006',
  BACS1007: 'BACS1007',
  BACS1008: 'BACS1008',
  BACS1009: 'BACS1009',
  BACS1010: 'BACS1010',
  BACS1011: 'BACS1011',
  BACS1012: 'BACS1012',
  BACS1013: 'BACS1013',
  BACS1014: 'BACS1014',
  BACS9999: 'BACS9999',
  C1000: 'C1000',
  C1001: 'C1001',
  C1002: 'C1002',
  C1003: 'C1003',
  C1004: 'C1004',
  C1008: 'C1008',
  C1009: 'C1009',
  C1015: 'C1015',
  C1016: 'C1016',
  C9999: 'C9999',
  G2100: 'G2100',
  G2101: 'G2101',
  G2102: 'G2102',
  G2104: 'G2104',
  G2105: 'G2105',
  G2106: 'G2106',
  G2107: 'G2107',
  G2114: 'G2114',
  G2115: 'G2115',
  G2116: 'G2116',
  G9999: 'G9999',
  GDPR1000: 'GDPR1000',
  GDPR1001: 'GDPR1001',
  GDPR1002: 'GDPR1002',
  GDPR9999: 'GDPR9999',
  L1000: 'L1000',
  L1001: 'L1001',
  L1002: 'L1002',
  L1003: 'L1003',
  L9999: 'L9999',
  SA1000: 'SA1000',
  SA1001: 'SA1001',
  SA1002: 'SA1002',
  SA1003: 'SA1003',
  SA1004: 'SA1004',
  SA1005: 'SA1005',
  ST1000: 'ST1000',
  ST1001: 'ST1001',
  ST9999: 'ST9999',
  U2000: 'U2000',
  U2003: 'U2003',
  U2004: 'U2004',
  U2005: 'U2005',
  U2006: 'U2006',
  U2017: 'U2017',
  U2018: 'U2018',
  U2019: 'U2019',
  U2021: 'U2021',
  U2022: 'U2022',
  U2023: 'U2023',
  U9999: 'U9999',
  WF1002: 'WF1002',
  WF9999: 'WF9999'
}

var types = {
  AntiFraud: 'Anti-Fraud',
  BACS: 'BACS',
  BankAccount: 'BankAccount',
  Customer: 'Customer',
  GDPR: 'GDPR',
  Group: 'Group',
  Licence: 'Licence',
  PaymentProcessing: 'Payment Processing',
  SecurityToken: 'Security Token',
  ServiceAccount: 'Service Account',
  System: 'System',
  User: 'User',
  Workflow: 'Workflow'

}

var severities = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High'
}

export {
  codes,
  types,
  severities
}
