var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(0),
      _c("div", { staticClass: "idb-block-content" }, [
        _c("table", { staticClass: "table table-header-rotated" }, [
          _vm._m(1),
          _c(
            "tbody",
            _vm._l(_vm.loggingSettings, function(loggingSetting) {
              return _c("tr", { key: loggingSetting.application }, [
                _c("td", [_vm._v(_vm._s(loggingSetting.application))]),
                _c(
                  "td",
                  [
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      model: {
                        value: loggingSetting.trace,
                        callback: function($$v) {
                          _vm.$set(loggingSetting, "trace", $$v)
                        },
                        expression: "loggingSetting.trace"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      model: {
                        value: loggingSetting.debug,
                        callback: function($$v) {
                          _vm.$set(loggingSetting, "debug", $$v)
                        },
                        expression: "loggingSetting.debug"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      model: {
                        value: loggingSetting.info,
                        callback: function($$v) {
                          _vm.$set(loggingSetting, "info", $$v)
                        },
                        expression: "loggingSetting.info"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      model: {
                        value: loggingSetting.warning,
                        callback: function($$v) {
                          _vm.$set(loggingSetting, "warning", $$v)
                        },
                        expression: "loggingSetting.warning"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      model: {
                        value: loggingSetting.error,
                        callback: function($$v) {
                          _vm.$set(loggingSetting, "error", $$v)
                        },
                        expression: "loggingSetting.error"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      model: {
                        value: loggingSetting.critical,
                        callback: function($$v) {
                          _vm.$set(loggingSetting, "critical", $$v)
                        },
                        expression: "loggingSetting.critical"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit", disabled: _vm.isLoading },
            on: { click: _vm.save }
          },
          [_vm._v("Save")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Logging Settings")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", {}, [
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Application")])])
        ]),
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Trace")])])
        ]),
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Debug")])])
        ]),
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Information")])])
        ]),
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Warning")])])
        ]),
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Error")])])
        ]),
        _c("th", { staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Critical")])])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }