var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user != null
      ? _c(
          "form",
          {
            attrs: { novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "b-modal",
              {
                ref: "fileOptionsModal",
                attrs: {
                  id: "fileOptionsModal",
                  variant: "primary",
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                  "hide-header-close": "",
                  title:
                    "Choose the method by which the user will select files.",
                  centered: "",
                  "ok-only": "",
                  "ok-title": "Done"
                },
                on: { ok: _vm.okMethod },
                model: {
                  value: _vm.show,
                  callback: function($$v) {
                    _vm.show = $$v
                  },
                  expression: "show"
                }
              },
              [
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-5" }, [
                    _vm._v("Upload Type")
                  ]),
                  _c("div", { staticClass: "col-md-7" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.uploadType.$model,
                            expression: "user.uploadType.$model"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.user.uploadType,
                                "$model",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.resetPath
                          ]
                        }
                      },
                      _vm._l(_vm.uploadTypes, function(uploadType) {
                        return _c(
                          "option",
                          { key: uploadType, domProps: { value: uploadType } },
                          [_vm._v(_vm._s(uploadType))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _vm.user.uploadType.$model == "Secure Cloud Folder"
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.user.uploadPath.$error }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-5" },
                          [_vm._v("File Upload Directory")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-7" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.uploadPath.$model,
                                  expression: "user.uploadPath.$model"
                                }
                              ],
                              staticClass: "form-control",
                              domProps: { value: _vm.user.uploadPath.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user.uploadPath,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "File Upload Directory" },
                              model: {
                                value: _vm.user.uploadPath,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "uploadPath", $$v)
                                },
                                expression: "user.uploadPath"
                              }
                            }),
                            _c("br"),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.btnFolderBrowser($event)
                                  }
                                }
                              },
                              [_vm._v("Select Folder")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.user.uploadType.$model == "Restricted File Browser"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: { invalid: _vm.user.uploadPath.$error }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-5" },
                            [_vm._v("Restricted Path")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-7" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.uploadPath.$model,
                                    expression: "user.uploadPath.$model"
                                  }
                                ],
                                staticClass: "form-control",
                                domProps: { value: _vm.user.uploadPath.$model },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user.uploadPath,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "Restricted Path" },
                                model: {
                                  value: _vm.user.uploadPath,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "uploadPath", $$v)
                                  },
                                  expression: "user.uploadPath"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-5" },
                          [_vm._v("User Cannot Browse Sub-folders")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-7" },
                          [
                            _c("p-check", {
                              staticClass: "p-default p-curve",
                              model: {
                                value: _vm.user.directoryLocked.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.user.directoryLocked,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "user.directoryLocked.$model"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm.showFolderBrowser
              ? _c(
                  "folderBrowser",
                  {
                    on: {
                      close: function($event) {
                        return _vm.closeFolderPopup()
                      }
                    }
                  },
                  [
                    _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v("Folder Path")
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }