var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", [
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "ml-5 mt-3", attrs: { hidden: "" } },
          [
            _c("b-row", [_vm._v("Filename: " + _vm._s(_vm.filename))]),
            _c("b-row", [
              _vm._v("Imported Customer: " + _vm._s(_vm.importedCustomerName))
            ]),
            _c("b-row", [
              _vm._v(
                "Imported PaygateId: " + _vm._s(_vm.importedCustomerPaygateId)
              )
            ]),
            _c("b-row", [_vm._v("User PaygateId: " + _vm._s(_vm.paygateId))]),
            _c("b-row", [_vm._v("Last Error: " + _vm._s(_vm.lastError))])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _vm.canImport
              ? _c(
                  "b-row",
                  { staticClass: "mt-1" },
                  [
                    _c("b-col", [
                      _vm.canImport
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-2",
                              attrs: { type: "button" },
                              on: { click: _vm.importCustomer }
                            },
                            [_vm._v("Import")]
                          )
                        : _vm._e(),
                      _vm.importedCustomerName != ""
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-2",
                              attrs: { type: "button" },
                              on: { click: _vm.saveLog }
                            },
                            [_vm._v("Save Log")]
                          )
                        : _vm._e(),
                      _c("strong", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(this.lastError))
                      ]),
                      _vm.canDelete
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger ml-2 float-right",
                              attrs: { type: "button" },
                              on: { click: _vm.deleteCustomer }
                            },
                            [_vm._v("Delete")]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    !_vm.hubConnected
                      ? _c(
                          "b-alert",
                          {
                            staticClass: "mt-3",
                            attrs: { variant: "danger", show: "" }
                          },
                          [
                            _vm._v(
                              " SignalR Hub not connected - Status updates will NOT be shown "
                            )
                          ]
                        )
                      : _c(
                          "b-alert",
                          {
                            staticClass: "mt-3",
                            attrs: { variant: "success", show: "" }
                          },
                          [_vm._v(" SignalR Hub connected ")]
                        )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { attrs: { hidden: this.hideDropzone } },
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    _c(
                      "dropzone",
                      {
                        ref: "fileUploader",
                        attrs: {
                          id: "customer-import-dropzone",
                          options: _vm.dropzoneOptions,
                          "use-custom-slot": true,
                          useCustomSlot: true
                        },
                        on: {
                          "vdropzone-success": _vm.afterSuccess,
                          "vdropzone-file-added": _vm.fileAdded,
                          "vdropzone-removed-file": _vm.fileRemoved,
                          "vdropzone-sending": _vm.sendingEvent,
                          "vdropzone-error": _vm.uploadError,
                          "vdropzone-upload-progress": _vm.uploadProgress
                        }
                      },
                      [
                        _c("div", { staticClass: "dropzone-custom-content" }, [
                          _c("h3", { staticClass: "dropzone-custom-title" }, [
                            _vm._v(
                              "Drag and drop to upload your customer import file"
                            )
                          ]),
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(
                              "...or click to select a file from your computer"
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-col",
                  [
                    _c("vue-good-table", {
                      attrs: {
                        rows: _vm.tasks,
                        columns: _vm.columns,
                        styleClass: "vgt-table condensed bordered",
                        "sort-options": {
                          enabled: false
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "table-row",
                          fn: function(props) {
                            return [
                              props.column.label === "Complete"
                                ? _c("span", [
                                    props.row["complete"]
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-check mr-1"
                                          })
                                        ])
                                      : _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-times mr-1"
                                          })
                                        ])
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-col",
                  [
                    _c("b-progress", {
                      attrs: {
                        value: _vm.percentage,
                        max: 100,
                        variant: "success"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-row", { staticClass: "mt-3" }, [
              _c(
                "ul",
                _vm._l(_vm.outstandingItems, function(item, index) {
                  return _c("li", { key: index }, [
                    _vm._v(
                      " " +
                        _vm._s(item.category) +
                        " - " +
                        _vm._s(item.message) +
                        " "
                    )
                  ])
                }),
                0
              )
            ]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c("b-form-textarea", {
                      attrs: { id: "detailLog", rows: "40" },
                      model: {
                        value: _vm.log,
                        callback: function($$v) {
                          _vm.log = $$v
                        },
                        expression: "log"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "idb-block-footer" })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Customer Import")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }