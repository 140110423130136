<template>
  <div id="bureauSummary">
    <div class="row form-group">
      <div class="col-md-2"></div>
      <div class="col-md-1 alertValueRight">
        <strong>Items</strong>
      </div>
      <div class="col-md-2 alertValueRight">
        <strong>Total</strong>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-2">
        <strong>Credits</strong>
      </div>
      <div class="col-md-1 alertValueRight">{{ formatCount(BureauSummaryData.totalCreditCount) }}</div>
      <div class="col-md-2 alertValueRight">£{{ formatValue(BureauSummaryData.totalCreditValue) }}</div>
      <div class="col-md-1"></div>
    </div>
    <div class="row form-group">
      <div class="col-md-2">
        <strong>Debits</strong>
      </div>
      <div class="col-md-1 alertValueRight">{{ formatCount(BureauSummaryData.totalDebitCount) }}</div>
      <div class="col-md-2 alertValueRight">£{{ formatValue(BureauSummaryData.totalDebitValue) }}</div>
      <div class="col-md-1"></div>
    </div>
    <div class="row form-group">
      <div class="col-md-2">
        <strong>DDIs</strong>
      </div>
      <div class="col-md-1 alertValueRight">{{ formatCount(BureauSummaryData.totalDDICount) }}</div>
      <div class="col-md-3"></div>
    </div>
    <div class="row form-group">
      <div class="col-md-2">
        <strong>Contras</strong>
      </div>
      <div class="col-md-1 alertValueRight">{{ formatCount(BureauSummaryData.contraCount) }}</div>
      <div class="col-md-3"></div>
    </div>
    <div class="row form-group">
      <div class="col-md-2">
        <strong>Total Files</strong>
      </div>
      <div class="col-md-1 alertValueRight">{{ formatCount(BureauSummaryData.totalFiles) }}</div>
      <div class="col-md-3"></div>
    </div>
    <div class="row form-group"></div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [
    bacsMixin,
    loading
  ],
  data () {
    return {
      submissionId: '',
      BureauSummaryData: {}
    }
  },
  mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.BureauSummaryData = this.$store.getters.bureauSummaryData
  },
  async created () {
    await this.load()
  },
  methods: {
    async load () {

    }
  }

}
</script>
