<template>
  <div>
    <form novalidate v-on:submit.prevent="approveWithCode">
      <b-modal
        id="approveAction"
        ref="approveAction"
        variant="primary"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        centered
        title="Approve Action"
        ok-variant="success"
        cancel-variant="danger"
        cancel-title="Cancel"
        ok-title="Approve Action"
        static
        @shown="focusInput"
      >
        <div class="row">
          <div class="col-md-12">
            <p v-html="body"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p
              class="help-block"
              v-if="type !== 'Authenticator' && type !== 'Password'"
            >Once you've received the code, please enter it here.</p>
            <div class="form-group">
              <input
                id="code"
                class="form-control"
                :type="type === 'Password'?'password': 'text'"
                autocomplete="off"
                v-model="code"
                ref="code"
                autofocus
              />
              <span v-if="incorrectCode">
                <span class="form-text text-danger small">{{ incorrectCodeMsg }}</span>
              </span>
              <small
                v-if="type !== 'Authenticator' && type !== 'Password'"
                class="form-text text-muted"
              >
                If you haven't received anything, please
                <a
                  @click.prevent="resendCode"
                  href
                  class="alert-link"
                >Click Here</a> to resend the code.
              </small>
            </div>
          </div>
        </div>
        <template slot="modal-footer">
          <button
            type="button"
            :disabled="isLoading"
            class="btn btn-secondary"
            @click="hideModal"
          >Cancel</button>
          <button type="submit" :disabled="isLoading" class="btn btn-success">Approve Action</button>
        </template>
      </b-modal>
    </form>
  </div>
</template>
<script>

import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'

function getToastTitle (status) {
  if (status.slice(-1) === 'e') {
    return `${status}d`
  }
  return `${status}ed`
}

export default {
  name: 'approveActionModal',
  props: {
    type: {
      type: String
    },
    status: {
      type: String
    },
    name: {
      type: String
    },
    actionId: {
      type: String,
      required: true
    },
    action: {
      type: Object,
      required: true
    }
  },
  mixins: [loading],
  computed: {
    emailAddress () {
      return this.$store.getters.getClaim('email').value
    },
    phoneNumber () {
      return this.$store.getters.getClaim('phone').value
    },
    body () {
      switch (this.type) {
        case 'Email':
          return `You have been sent a code via email to the address <b>${this.emailAddress}</b>`
        case 'SMS':
          return `You have been sent a code via SMS to the phone number <b>${this.phoneNumber}</b>`
        case 'Authenticator':
          return 'Confirm Authenticator Code'
        case 'Password':
          return 'Enter Your Password'
        default:
          return null
      }
    },
    incorrectCodeMsg () {
      switch (this.type) {
        case 'Password':
          return 'The Password is incorrect'
        default:
          return 'The Code is incorrect'
      }
    },
    incorrectCodeMsgTitle () {
      switch (this.type) {
        case 'Password':
          return 'Invalid Password'
        default:
          return 'Invalid Code'
      }
    }
  },
  data () {
    return {
      code: null,
      incorrectCode: false
    }
  },
  methods: {
    async approveWithCode (e) {
      e.preventDefault()
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/Approve/Code`, { Code: this.code, ActionId: this.actionId }, { showload: true, showerror: true, errormessage: `Failed to approve ${this.status}` })
        if (response.data.codeValid) {
          if (response.data.amountRemaining === 0) {
            this.$toastr.s(`${this.name} ${getToastTitle(this.status)}`, getToastTitle(this.status))
            if (this.name === 'User' && this.action.data.clonedUserTempId !== '00000000-0000-0000-0000-000000000000') {
              this.action.data.clonedUserTempId = response.data.entityId
              const cloneResponse = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Users/CloneUserGroupBindings`, this.action.data)
              if (cloneResponse.data.status === 'Action') {
                this.$toastr.s('Adding cloned user to groups needs to be approved', 'Needs Approval')
              }
            }
          } else if (response.data.amountRemaining === -1) {
            this.$toastr.e('Not authorized to approve action', 'Not Authorized')
          } else {
            this.$toastr.s(`${this.name} needs ${response.data} more approval${response.data.amountRemaining > 1 ? 's' : ''}`, 'Approved')
          }
          this.$refs.approveAction.hide()
          this.$emit('submit')
        } else {
          this.$toastr.e(this.incorrectCodeMsg, this.incorrectCodeMsgTitle)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    async resendCode () {
      await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/Approve/${this.actionId}`)
    },
    hideModal () {
      this.$refs.approveAction.hide()
    },
    focusInput () {
      this.$refs.code.focus()
    }
  },
  validations () {
    return {
      code: { required }
    }
  }
}
</script>
