<template>
  <div>
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
            <div class="idb-block-title">
              <h2>Onboarding API Usage</h2>
            </div>
            <div class="idb-block-content">
              <b-row>
                <b-col>
                  <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Start Date">
                    <datepicker id="startDayPicker"
                      v-model="startDate"
                      :format="formatDate"
                      @input="getData"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="End Date">
                    <datepicker id="endDayPicker"
                      v-model="endDate"
                      :format="formatDate"
                      @input="getData"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <p>Requests to the onboarding api are shown below.</p>
              <vue-good-table
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    @on-row-click="onRowClick"
                    :columns="columns"
                    :rows="apiReports"
                    :lineNumbers="true"
                    :totalRows="totalRecords"
                    :search-options="{
                    enabled: true
                    }"
                    :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
                    styleClass="vgt-table striped bordered"
                  >
                    <template slot="loadingContent">
                      <h1>Loading...</h1>
                    </template>
                    <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">No Payers Available</div>
                    </div>
                    <div slot="table-actions">
                      <b-button
                        @click.prevent="reloadItems"
                        class
                        variant="link"
                        title="Refresh Table"
                        v-b-popover.hover.top.d500="'Refresh the data in the table'"
                      >
                        <i class="fa fa-sync pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="printTable"
                        class
                        variant="link"
                        title="Print Table"
                        v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
                      >
                        <i class="fa fa-print pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="exportTable"
                        class
                        variant="link"
                        title="Export Table"
                        v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
                      >
                        <i class="fa fa-share-square pointer dimmedIcon"></i>
                      </b-button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <!-- <router-link :to="{ path: '/payer/' + props.row.ukPayerId + '/edit', params: { ukPayerId: props.row.ukPayerId }, query: { retPath: 'manage' }  }"> eslint-disable-line -->
                      <span
                        v-if="props.column.field === 'status'"
                      >{{ displayStatus(props.row.status) }}</span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                      <!-- </router-link> -->
                    </template>
                  </vue-good-table>
            </div>
            <div class="idb-block-footer noprint-footer">
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
export default {
  components: {
    Datepicker
  },
  data () {
    return {
      apiReports: [],
      columns: [
        {
          label: 'Customer',
          field: 'customer'
        },
        {
          label: 'Report Date',
          field: 'reportDate',
          formatFn: this.formatDate
        },
        {
          label: 'Payers Created',
          field: 'createRequests'
        },
        {
          label: 'Address Lookups',
          field: 'addressLookups'
        },
        {
          label: 'Validate Requests',
          field: 'validateLookups'
        },
        {
          label: 'Sessions',
          field: 'sessionsCreated'
        }
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: { },
        sort: {
          field: 'customer',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      startDate: new Date(),
      endDate: new Date(),
      labelCols: 4,
      columnClass: 'col-sm-6'
    }
  },
  methods: {
    async getData () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}onboardingapireporting/`, { params: { startDate: this.startDate, endDate: this.endDate } })
      if (response !== null) {
        this.apiReports = response.data
        this.totalRecords = this.apiReports.length
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var retVal = `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      console.log(retVal)
      return retVal
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.reloadItems()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params.sortType,
          field: this.columns[params.columnIndex].field
        }
      })
      this.reloadItems()
    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
      this.updateParams(params)
      this.reloadItems()
    },
    onPerPageChange (params) {
      console.log('onPerPageChange', params)
      this.updateParams({ perPage: params.currentPerPage })
      console.log('perpage', this.perPage)
      this.reloadItems()
    },
    onRowClick (params) {
      this.$router.push(`/admin/email/bounces/${params.row.payerMessageBatchId}`)
    },
    onCellClick (params) {
      this.selectedRow = this.tableData[params.rowIndex]
    },
    onSearch (params) {
      console.log('in search', params)
      this.serverParams.searchKey = params.searchTerm
      this.reloadItems()
    },
    reloadItems () {
      this.getData()
    }
  },
  async mounted () {
    var date = new Date()
    this.startDate = new Date(date.getFullYear(), date.getMonth(), 1)
    this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    this.getData()
  }
}
</script>
