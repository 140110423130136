var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.searchGDPR()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" General Data Protection Regulation (GDPR) Search "),
                _c("help-icon", {
                  attrs: { docPath: "/administration/gdpr/" }
                }),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _vm._m(0),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group col",
                    class: { invalid: _vm.$v.search.sortCode.$error }
                  },
                  [
                    _c("label", { staticClass: "required" }, [
                      _vm._v("Sort Code")
                    ]),
                    _c("the-mask", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Sort Code",
                        mask: ["##-##-##"],
                        guide: false
                      },
                      model: {
                        value: _vm.$v.search.sortCode.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.search.sortCode, "$model", $$v)
                        },
                        expression: "$v.search.sortCode.$model"
                      }
                    }),
                    _c(
                      "validation-messages",
                      {
                        attrs: { name: "Sort Code" },
                        model: {
                          value: _vm.$v.search.sortCode,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.search, "sortCode", $$v)
                          },
                          expression: "$v.search.sortCode"
                        }
                      },
                      [
                        _vm.$v.search.sortCode.exactLengthCustom != undefined &&
                        !_vm.$v.search.sortCode.exactLengthCustom
                          ? _c("small", { staticClass: "form-text small" }, [
                              _vm._v(
                                "The sort code needs to be " +
                                  _vm._s(
                                    _vm.$v.search.sortCode.$params
                                      .exactLengthCustom.exact
                                  ) +
                                  " numbers long"
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group col",
                    class: { invalid: _vm.$v.search.accountNumber.$error }
                  },
                  [
                    _c("label", { staticClass: "required" }, [
                      _vm._v("Bank Account Number")
                    ]),
                    _c("the-mask", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Account Number",
                        mask: ["########"],
                        guide: false
                      },
                      model: {
                        value: _vm.$v.search.accountNumber.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.search.accountNumber, "$model", $$v)
                        },
                        expression: "$v.search.accountNumber.$model"
                      }
                    }),
                    _c(
                      "validation-messages",
                      {
                        attrs: { name: "Bank Account Number" },
                        model: {
                          value: _vm.$v.search.accountNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.search, "accountNumber", $$v)
                          },
                          expression: "$v.search.accountNumber"
                        }
                      },
                      [
                        _vm.$v.search.accountNumber.exactLengthCustom !=
                          undefined &&
                        !_vm.$v.search.accountNumber.exactLengthCustom
                          ? _c("small", { staticClass: "form-text small" }, [
                              _vm._v(
                                "The bank account number needs to be " +
                                  _vm._s(
                                    _vm.$v.search.accountNumber.$params
                                      .exactLengthCustom.exact
                                  ) +
                                  " numbers long"
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group col",
                    class: { invalid: _vm.$v.search.firstName.$error }
                  },
                  [
                    _c("label", [_vm._v("First Name")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.search.firstName.$model,
                          expression: "$v.search.firstName.$model",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "First Name" },
                      domProps: { value: _vm.$v.search.firstName.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.search.firstName,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c("validation-messages", {
                      attrs: { name: "First Name" },
                      model: {
                        value: _vm.$v.search.firstName,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.search, "firstName", $$v)
                        },
                        expression: "$v.search.firstName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group col",
                    class: { invalid: _vm.$v.search.lastName.$error }
                  },
                  [
                    _c("label", [_vm._v("Last Name")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.search.lastName.$model,
                          expression: "$v.search.lastName.$model",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "Last Name" },
                      domProps: { value: _vm.$v.search.lastName.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.search.lastName,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c("validation-messages", {
                      attrs: { name: "Last Name" },
                      model: {
                        value: _vm.$v.search.lastName,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.search, "lastName", $$v)
                        },
                        expression: "$v.search.lastName"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group col-6",
                    class: { invalid: _vm.$v.search.email.$error }
                  },
                  [
                    _c("label", [_vm._v("Email")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.search.email.$model,
                          expression: "$v.search.email.$model",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "Email" },
                      domProps: { value: _vm.$v.search.email.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.search.email,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c("validation-messages", {
                      attrs: { name: "Email" },
                      model: {
                        value: _vm.$v.search.email,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.search, "email", $$v)
                        },
                        expression: "$v.search.email"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.anyResults && _vm.finishedSearching
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-warning col",
                        attrs: { role: "alert" }
                      },
                      [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v(
                            "Important - The information shown here is a sample of the data available, an export may contain additional data including notes"
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "Files in your Secure Cloud folders are not searched for personal information and should be reviewed manually."
                          )
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.firstNameOrLastName,
                                expression: "!firstNameOrLastName"
                              }
                            ]
                          },
                          [
                            _c("hr"),
                            _vm._v(
                              "You have not entered a first and/or last name so are unable to redact personal information, once entered you must search again "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.anyResults && _vm.longRedaction
                ? _c("div", { staticClass: "row" }, [_vm._m(1)])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "idb-block-footer button-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Search")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.fullClear }
              },
              [_vm._v("Clear")]
            ),
            _vm.finishedSearching && _vm.anyResults
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    attrs: { type: "button", disabled: _vm.isLoading },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Export")]
                )
              : _vm._e(),
            _vm.finishedSearching && _vm.anyResults
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger pull-right",
                    attrs: {
                      type: "button",
                      disabled: _vm.isLoading || !_vm.firstNameOrLastName
                    },
                    on: { click: _vm.redactData }
                  },
                  [_vm._v("Redact Personal Information")]
                )
              : _vm._e()
          ])
        ])
      ]
    ),
    _vm.payments.some(function() {
      return true
    })
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(2),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm._m(3),
                    _c("vue-good-table", {
                      attrs: {
                        paginationOptions: {
                          enabled: true,
                          perPage: 10,
                          perPageDropdown: [10, 20, 30, 40, 50],
                          dropdownAllowAll: false
                        },
                        rows: _vm.payments,
                        columns: _vm.paymentsColumns,
                        lineNumbers: true,
                        "sort-options": {
                          enabled: true,
                          initialSortBy: [
                            { field: "processingDate", type: "desc" }
                          ]
                        },
                        styleClass: "vgt-table striped bordered"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm.collectionsPayers.some(function() {
      return true
    }) ||
    _vm.collectionsAuddis.some(function() {
      return true
    }) ||
    _vm.collectionsScheduledPayments.some(function() {
      return true
    })
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(4),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _vm.collectionsPayers.some(function() {
                return true
              })
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "idb-block" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName:
                                    "v-b-toggle.collectionsPayersCollapse",
                                  modifiers: { collectionsPayersCollapse: true }
                                }
                              ],
                              staticClass: "idb-block-title"
                            },
                            [
                              _vm._v(" Payers "),
                              _c("i", { staticClass: "fa fa-chevron-down" }),
                              _c("i", { staticClass: "fa fa-chevron-up" })
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "collectionsPayersCollapse",
                                visible: ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-light col mb-0",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "These are required for current direct debits, closed payers can be removed, to close a payer use the DDMS Payers screen"
                                    )
                                  ])
                                ]
                              ),
                              _c("vue-good-table", {
                                attrs: {
                                  paginationOptions: {
                                    enabled: true,
                                    perPage: 10,
                                    perPageDropdown: [10, 20, 30, 40, 50],
                                    dropdownAllowAll: false
                                  },
                                  rows: _vm.collectionsPayers,
                                  columns: _vm.collectionsPayersColumns,
                                  lineNumbers: true,
                                  "sort-options": {
                                    enabled: true
                                  },
                                  styleClass: "vgt-table striped bordered"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.collectionsAuddis.some(function() {
                return true
              })
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "idb-block" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName:
                                    "v-b-toggle.collectionsAuddisCollapse",
                                  modifiers: { collectionsAuddisCollapse: true }
                                }
                              ],
                              staticClass: "idb-block-title"
                            },
                            [
                              _vm._v(" AUDDIS Instructions "),
                              _c("i", { staticClass: "fa fa-chevron-down" }),
                              _c("i", { staticClass: "fa fa-chevron-up" })
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "collectionsAuddisCollapse",
                                visible: ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-light col mb-0",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "These are pending payment instructions for live payments, they cannot be redacted as they are required for current processes"
                                    )
                                  ])
                                ]
                              ),
                              _c("vue-good-table", {
                                attrs: {
                                  paginationOptions: {
                                    enabled: true,
                                    perPage: 10,
                                    perPageDropdown: [10, 20, 30, 40, 50],
                                    dropdownAllowAll: false
                                  },
                                  rows: _vm.collectionsAuddis,
                                  columns: _vm.collectionsAuddisColumns,
                                  lineNumbers: true,
                                  "sort-options": {
                                    enabled: true
                                  },
                                  styleClass: "vgt-table striped bordered"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.collectionsScheduledPayments.some(function() {
                return true
              })
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "idb-block" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName:
                                    "v-b-toggle.collectionsScheduledPaymentsCollapse",
                                  modifiers: {
                                    collectionsScheduledPaymentsCollapse: true
                                  }
                                }
                              ],
                              staticClass: "idb-block-title"
                            },
                            [
                              _vm._v(" Scheduled Payments "),
                              _c("i", { staticClass: "fa fa-chevron-down" }),
                              _c("i", { staticClass: "fa fa-chevron-up" })
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "collectionsScheduledPaymentsCollapse",
                                visible: ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-light col mb-0",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "These are scheduled payments and cannot be removed"
                                    )
                                  ])
                                ]
                              ),
                              _c("vue-good-table", {
                                attrs: {
                                  paginationOptions: {
                                    enabled: true,
                                    perPage: 10,
                                    perPageDropdown: [10, 20, 30, 40, 50],
                                    dropdownAllowAll: false
                                  },
                                  rows: _vm.collectionsScheduledPayments,
                                  columns:
                                    _vm.collectionsScheduledPaymentsColumns,
                                  lineNumbers: true,
                                  "sort-options": {
                                    enabled: true
                                  },
                                  styleClass: "vgt-table striped bordered"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e(),
    _vm.audits.some(function() {
      return true
    })
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(5),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm._m(6),
                    _c("vue-good-table", {
                      attrs: {
                        paginationOptions: {
                          enabled: true,
                          perPage: 10,
                          perPageDropdown: [10, 20, 30, 40, 50],
                          dropdownAllowAll: false
                        },
                        rows: _vm.audits,
                        columns: _vm.auditsColumns,
                        lineNumbers: true,
                        "sort-options": {
                          enabled: true
                        },
                        styleClass: "vgt-table striped bordered"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("p", [
        _vm._v(
          "Please note you must enter a First Name and/or Last Name to Redact Personal Information, in addition to Sort Code and Account Number."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "alert alert-info col", attrs: { role: "alert" } },
      [
        _c("h4", { staticClass: "alert-heading" }, [
          _vm._v("This process is taking a while")
        ]),
        _c("div", [
          _vm._v(
            "Please be patient, redacting data can take a long while to complete, be assured it is still running."
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("BACS/Faster Payment Records")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "alert alert-light col mb-0", attrs: { role: "alert" } },
      [
        _c("div", [
          _vm._v(
            "These are payment made through BACS or Faster Payments, if they are historical they can be redacted"
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Collections")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Audit Log")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "alert alert-light col mb-0", attrs: { role: "alert" } },
      [
        _c("div", [
          _vm._v(
            "These are audit entries containing personal data, they cannot be removed as they are required for fraud prevention"
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }