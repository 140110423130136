<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><span class="pull-right"></span>Workflow Queue<favourite-icon></favourite-icon></h2>
          </div>
          <form>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                  <div class="mb-3">
                    <span class="mr-2">Backlog Queue</span>
                    <b-badge pill  v-if = "row_data_backlogQueue.length > 0" variant="primary">{{row_data_backlogQueue.length}}</b-badge>
                  </div>

                  <vue-good-table
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    :isLoading.sync="isTableLoading"
                    ref="table1"
                    :columns="columns"
                    :rows="rowsBacklogQueue"
                    :lineNumbers="true"
                    :search-options="{ enabled: true }"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    @on-row-click="onRowClick"
                    styleClass="vgt-table striped bordered table-hover">
                    <template slot="table-row" slot-scope="props">
                      <span v-if = "props.column.field == 'lastExecutionDate'">
                        {{ props.formattedRow[props.column.field] }}
                        <div v-if="props.formattedRow[props.column.field]">
                        </div>
                      </span>
                      <span v-else-if = "props.column.field == 'buttons'">
                        <!-- <b-button @click.prevent="btnRemoveFrombBacklog(props)" variant="link" class="pointer" title="Remove"><i class="glyphicon text-danger ti-trash"></i></b-button> -->
                        <div class="dropdown">
                          <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="glyphicon text-secondary ti-more-alt"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click.prevent="btnRemoveFrombBacklog(props)">Remove from queue</a>
                            <!-- <div class="dropdown-divider"></div> -->
                            <!-- <a class="dropdown-item" href="#">Move to Processing Queue</a> -->
                          </div>
                        </div>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                    <div slot="table-actions">
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'">
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i></b-button>
                    </div>
                  </vue-good-table>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="mb-3">
                    <span class="mr-2">Processing Queue</span>
                    <b-badge pill v-if = "row_data_processingQueue.length > 0 && row_data_processingQueue.length <= 5" variant="primary">{{row_data_processingQueue.length}}</b-badge>
                    <b-badge pill v-else-if = "row_data_processingQueue.length > 0 && row_data_processingQueue.length <= 9" variant="warning">{{row_data_processingQueue.length}}</b-badge>
                    <b-badge pill v-else-if = "row_data_processingQueue.length > 0 && row_data_processingQueue.length === 10" variant="danger">{{row_data_processingQueue.length}}</b-badge>
                  </div>
                  <vue-good-table
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    :isLoading.sync="isTableLoading"
                    ref="table2"
                    :columns="columns"
                    :rows="rowsProcessingQueue"
                    :lineNumbers="true"
                    :search-options="{ enabled: true }"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    @on-row-click="onRowClick"
                    styleClass="vgt-table striped bordered table-hover">
                    <template slot="table-row" slot-scope="props">
                      <span v-if = "props.column.field == 'lastExecutionDate'">
                        {{ props.formattedRow[props.column.field] }}
                        <div v-if="props.formattedRow[props.column.field]">
                        </div>
                      </span>
                      <span v-else-if = "props.column.field == 'buttons'">
                        <!-- <b-button @click.prevent="btnRemoveFromProcessing(props)" variant="link" class="pointer" title="Remove"><i class="glyphicon text-danger ti-trash"></i></b-button> -->
                        <div class="dropdown">
                          <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="glyphicon text-secondary ti-more-alt"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click.prevent="btnRemoveFromProcessing(props)">Remove from queue</a>
                            <!-- <div class="dropdown-divider"></div> -->
                            <!-- <a class="dropdown-item" href="#">Move to Processing Queue</a> -->
                          </div>
                        </div>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                    <div slot="table-actions">
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'">
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i></b-button>
                    </div>
                  </vue-good-table>
                </div>
              </div>
            </div>
            <!-- <div class="idb-block-footer">
              <div class="row" >
                <div class="col-md-12 col-sm-3">
                  <b-button @click.prevent="btnRefresh" :disabled="isLoading" variant="primary"><i class="fa fa-refresh mr-2"></i>Refresh</b-button>
                </div>
              </div>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  components: {
    swal
  },
  mixins: [tableFilterMixin, loading],
  data: () => ({
    paygateId: '',
    config: {},
    docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
    columns: [
      {
        label: 'Id',
        field: '_id',
        hidden: true
      },
      {
        label: 'Workflow',
        field: 'workflowTitle',
        filterOptions: { enabled: false }
      },
      // {
      //   label: 'executionId',
      //   field: 'executionId',
      //   filterOptions: { enabled: false }
      // },
      {
        label: 'Customer Code',
        field: 'customerId',
        filterOptions: { enabled: false }
      },
      {
        label: 'Queued Date',
        field: 'creationDate',
        type: 'date',
        dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX',
        dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
        filterOptions: { enabled: false }
      },
      {
        label: '',
        field: 'buttons',
        sortable: false,
        filterOptions: { enabled: false },
        tdClass: 'text-center',
        thClass: 'text-center',
        hidden: false
      }
    ],
    row_data_backlogQueue: [],
    row_data_processingQueue: [],
    tableRefreshTimer: ''
  }),
  computed: {
    rowsBacklogQueue: function () {
      return this.row_data_backlogQueue
    },
    rowsProcessingQueue: function () {
      return this.row_data_processingQueue
    }
    // corvidSelectedPaygateId () {
    //   return this.$store.state.common.paygateId
    // }
  },
  methods: {
    async load () {
      // Backlog Queue
      try {
        const res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}backlogqueue`, { showload: true })
        if (res) {
          console.log(res.data)
          this.row_data_backlogQueue = res.data.items
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view queue data.')
        } else {
          this.$snapbar.e(`Could not get the backlog queue data - ${e.message}`)
        }
        this.row_data_backlogQueue = []
      }

      // Processing Queue
      try {
        const res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}processingqueue`, { showload: true })
        if (res) {
          console.log(res.data)
          this.row_data_processingQueue = res.data.items
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view queue data.')
        } else {
          this.$snapbar.e(`Could not get the processing queue data - ${e.message}`)
        }
        this.row_data_processingQueue = []
      }
    },
    cancelAutoUpdate () {
      clearInterval(this.tableRefreshTimer)
    },
    async btnRemoveFrombBacklog (props) {
      console.log(props.formattedRow._id)
      // Confirmation!
      const result = await swal.fire({
        title: 'Are you sure you want to permanently remove this item from the backlog queue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}backlogqueue/${props.formattedRow._id}`, { showload: true })
          if (res) {
            this.$toastr.s('The item was successfully removed.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this item.')
          } else {
            this.$snapbar.e(`Could not delete this item - ${e.message}`)
          }
        }
      }
    },
    async btnRemoveFromProcessing (props) {
      console.log(props.formattedRow._id)
      // Confirmation!
      const result = await swal.fire({
        title: 'Are you sure you want to permanently remove this item from the processing queue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}processingqueue/${props.formattedRow._id}`, { showload: true })
          if (res) {
            this.$toastr.s('The item was successfully removed.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this item.')
          } else {
            this.$snapbar.e(`Could not delete this item - ${e.message}`)
          }
        }
      }
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.tableRefreshTimer = setInterval(this.load, 30000) // Refresh the VGT's every 30 seconds
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
  .dropdown-toggle:after { content: none }
</style>
