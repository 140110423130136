<template>
  <div>
    <b-row v-if="!templateView && !createMode" class="border-bottom pb-2 mb-2">
      <b-col cols="6">
        <h3>{{ plan.planTemplateName }} <router-link v-if="plan.paymentPlanId !== '00000000-0000-0000-0000-000000000000'"
          :to="{ name: 'PayerSchedule', params: { id: ukPayerId, planId: plan.paymentPlanId } }" class="float-right"
        >
          <!-- eslint-disable-line -->
          <b-button variant="outline-secondary">
            <i class="fa fa-calendar mr-2"></i>View Schedule
          </b-button>
        </router-link></h3>
      </b-col>
    </b-row>
    <b-form-row v-if="templateView">
      <b-form-group :label-cols="labelCols" :class="columnClass" label-class="required" horizontal label="Plan Template Name">
        <b-form-input id="planTemplateNameInput"
          class="form-control"
          v-model="plan.planTemplateName"
          @input="dirtyTouch($v.plan.planTemplateName)"
          :disabled="!editable"
        />
        <b-col v-if="$v.plan.planTemplateName.$dirty">
          <label id="planTemplateNameRequiredLabel"
            class="text-danger small"
            v-if="!$v.plan.planTemplateName.required"
          >Plan Template Name required</label>
          <label id="planTemplateNameExistsLabel"
            class="text-danger small"
            v-if="!$v.plan.planTemplateName.nameNotInUse"
          >Plan Template Name already exists</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Plan Reference">
        <b-form-input id="planReferenceInput"
          class="form-control"
          v-model="plan.reference"
          @change="dirtyTouch($v.plan.reference)"
          :disabled="true"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Plan Status">
        <b-form-select id="planStatusSelect"
          v-model.number="plan.planStatus"
          :options="statae"
          text-field="name"
          @change="dirtyTouch($v.plan.planStatus)"
          value-field="value"
          :disabled="!editable || planClosed || createMode"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="hasBureau && !sharedTemplate && isBureauOrIndirect">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Bank Account">
        <b-form-select id="bankAccountSelect"
          v-model="plan.customerBankAccountId"
          :options="bankAccounts"
          text-field="text"
          value-field="value"
          :disabled="!editable || !isNew"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="hasBureau && !sharedTemplate && isBureauOrIndirect">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Contra Narrative">
        <b-form-input id="contraNarrativeInput"
          class="form-control"
          v-model="plan.contraNarrative"
          :disabled="!editable" />
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="hasBureau && !sharedTemplate && isBureauOrIndirect">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label-class="required" label="Indirect Service User Number">
        <b-form-input id="planStatusSelect"
          v-model="plan.indirectSun"
          :disabled="!editable || !isNew"
        />
        <!-- Validation -->
        <b-col v-if="$v.plan.indirectSun.$dirty">
          <label id="indirectSunRequiredLabel"
            class="text-danger small"
            v-if="!$v.plan.indirectSun.required"
          >Indirect SUN required.</label>
          <label id="indirectSunInvalidLabel"
            class="text-danger small"
            v-if="!$v.plan.indirectSun.sunValidator"
          >Indirect SUN is in invalid format</label>
        </b-col>
        <small
          class="form-text text-muted"
        >The format for a Service User Number is 6 numbers or a B followed by 5 numbers.</small>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Direct Debit Type">
        <b-form-select id="recurrenceEndTypeSelect"
          v-model.number="plan.recurrenceEndType"
          :options="paymentType"
          text-field="name"
          value-field="ordinal"
          @change="dirtyTouch($v.plan.recurrenceEndType)"
          :disabled="!editable"
        />
      </b-form-group>
    </b-form-row>
    <section v-if="!isAdHoc">
      <b-form-row>
        <transition name="fade">
          <b-form-group
            v-if="plan.recurrenceEndType===2"
            :label-cols="labelCols"
            :class="columnClass"
            horizontal
            label="Number Of Payments"
          >
            <b-form-input id="totalPaymentsInput"
              class="col-sm-4"
              type="number"
              v-model.number="plan.totalPayments"
              placeholder="1"
              text-field="name"
              @change="dirtyTouch($v.plan.ordinal)"
              value-field="value"
              :disabled="!editable"
              :min="1"
            />
            <b-col v-if="$v.plan.totalPayments.$dirty">
              <label id="totalPaymentsRequiredLabel"
                class="text-danger small"
                v-if="!$v.plan.totalPayments.required"
              >Regular Collection Amount required </label>
              <label id="totalPaymentsPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.totalPayments.positive"
              >Value must be positive </label>
            </b-col>
          </b-form-group>
        </transition>
      </b-form-row>
      <b-form-row>
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Frequency">
          <b-form-select id="recurrenceTypeSelect"
            v-model.number="plan.recurrenceType"
            :options="recurrenceType"
            text-field="name"
            @change="recurrenceTypeChanged"
            value-field="ordinal"
            :disabled="!editable"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="plan.recurrenceType===1">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Day of the Month">
          <b-form-input id="ordinalSelect"
            :number="true" type="number"
            v-model.number="plan.ordinal"
            :min="ordinalOpts[0]"
            :max="ordinalOpts[ordinalOpts.length-1]"
            @change="ordinalChanged"
            :disabled="!editable"
          />
          <div class="col" v-if="showAdvice"><label class="small" :class="adivceLabelClass">{{adviceMessage}} <a href="#" @click.prevent="heatmapVisible = !heatmapVisible">{{ heatmapVisible ? 'Show less info.' : 'Show more info.'}}</a></label></div>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="plan.recurrenceType===2">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Day of the Year">
          <div class="right-inner-addon">
            <datepicker id="dayOfYearDayPicker"
              v-model="dayOfYear"
              :format="formatDate"
              @input="dirtyTouchYear()"
              :disabled="!editable"
              input-class="form-control datepicker"
            />
            <i class="fa fa-calendar form-control-feedback"></i>
          </div>
          <div class="col" v-if="showAdvice"><label class="small" :class="adivceLabelClass">{{adviceMessage}} <a href="#" @click.prevent="heatmapVisible = !heatmapVisible">{{ heatmapVisible ? 'Show less info.' : 'Show more info.'}}</a></label></div>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group
          v-if="plan.recurrenceType===0"
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          label="Day of the Week"
        >
          <b-form-select id="weekdaySelect"
            v-model.number="plan.ordinal"
            :options="weekdays"
            text-field="name"
            @change="ordinalChangedWeekday"
            value-field="value"
            :disabled="!editable"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!stepDisabled">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Step">
          <b-form-input :number="true"
            id="stepSelect"
            v-model.number="plan.step"
            :min="1"
            :max="steps[steps.length-1]"
            :disabled="stepDisabled || !editable"
            @change="dirtyTouchStep($v.plan.step)"
             type="number"
          />
          <b-col v-if="$v.plan.step.$dirty">
            <label id="stepRequiredLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.required"
            >Step required </label>
            <label id="stepPositiveLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.positive"
            >Value must be positive </label>
            <label id="stepNumericLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.numeric"
            >Value must be a number </label>
          </b-col>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="First Collection Date">
          <div class="right-inner-addon">
            <datepicker id="firstPaymentDatePicker"
              v-model="plan.firstPaymentDate"
              :disabled="!editable || !isNew"
              :disabledDates="disabledDates"
              :format="formatDate"
              input-class="form-control datepicker"
              @input="dirtyTouch($v.plan.firstPaymentDate)"
            />
            <i class="fa fa-calendar form-control-feedback"></i>
          </div>
          <b-col v-if="isNew && $v.plan.firstPaymentDate.$dirty && !templateView">
              <label id="firstPaymentGreaterLabel"
                class="text-danger small"
                v-if="!$v.plan.firstPaymentDate.newerThanNow"
              >A date must be greater than today </label>
            </b-col>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="plan.recurrenceEndType === 1">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Final Collection Date">
          <div class="right-inner-addon">
            <datepicker id="endPaymentDatePicker"
              v-model="plan.untilDate"
              :disabledDates="finalPaymentsDisabledDates"
              :format="formatDate"
              @input="dirtyTouch($v.plan.untilDate)"
              input-class="form-control datepicker"
              :disabled="!editable"
            />
            <i class="fa fa-calendar form-control-feedback"></i>
          </div>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Variable Amount">
          <p-check id="variableCheck"
            class="p-switch p-fill"
            color="primary"
            v-model="plan.variable"
            :disabled="!editable"
          ></p-check>
        </b-form-group>
      </b-form-row>
      <transition name="fade">
        <b-form-row v-if="!plan.variable">
          <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="First Collection Amount">
            <currency-input
              id="firstAmountInput"
              class="form-control"
              required
              @input="dirtyTouchCurrency($v.plan.firstAmount)"
              v-model="firstAmount"
              :disabled="!editable"
              currency="GBP"
              locale="en"
            />
            <b-col v-if="$v.plan.firstAmount.$dirty">
              <label id="firstAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.firstAmount.decimal"
              >Value must be a number </label>
              <label id="firstAmountPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.firstAmount.zeroOrPositive"
              >Value must be positive </label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </transition>
      <transition name="fade">
        <b-form-row v-if="!plan.variable">
          <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Regular Collection Amount">
            <currency-input
              id="regularAmountInput"
              class="form-control"
              :label-class="plan.variable ? '' : 'required'"
              @input="dirtyTouchCurrency($v.plan.regularAmount)"
              v-model="regularAmount"
              :disabled="!editable"
              currency="GBP"
              locale="en"
            />
            <b-col v-if="$v.plan.regularAmount.$dirty">
              <label id="regularAmountRequiredLabel"
                class="text-danger small"
                v-if="!$v.plan.regularAmount.required"
              >Regular Collection Amount required </label>
              <label id="regularAmountPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.regularAmount.positive"
              >Value must be positive </label>
            </b-col>
            <b-col v-if="$v.plan.regularAmount.$dirty">
              <label id="regularAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.regularAmount.decimal"
              >Value must be a number </label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </transition>
      <transition name="fade">
        <b-form-row v-if="!plan.variable">
          <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Final Collection Amount">
            <currency-input  id="lastAmountInput"
              class="form-control"
              @input="dirtyTouchCurrency($v.plan.lastAmount)"
              v-model="lastAmount"
              :disabled="!editable"
              currency="GBP"
              locale="en"
            />
            <b-col v-if="$v.plan.lastAmount.$dirty">
              <label id="lastAmountPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.lastAmount.zeroOrPositive"
              >Value must be a number </label>
            </b-col>
            <b-col v-if="$v.plan.lastAmount.$dirty">
              <label id="lastAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.lastAmount.decimal"
              >Value must be a number </label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </transition>
      <b-form-row>
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Gift Aid">
          <p-check
            id="giftAidCheck"
            class="p-switch p-fill"
            color="primary"
            v-model="plan.giftAid"
            :disabled="!editable"
          ></p-check>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!templateView && !plan.variable && !createMode">
        <strong>Scheduled Plan Changes</strong>
      </b-form-row>
      <b-form-row v-if="!templateView && !plan.variable && !createMode">
        <!-- :sort-options="{ enabled: true, initialSortBy: {field: 'startDate', type: 'asc'} }" -->
        <vue-good-table
          :columns="columns"
          :rows="plan.futurePlanChanges"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :rowStyleClass="rowStyler"
          :search-options="{
            enabled: false
            }"
          :pagination-options="{
            enabled: false
          }"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          styleClass="vgt-table striped bordered"
          ref="futurePlanTable"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No Plan Templates Available</div>
          </div>
          <div slot="table-actions">
            <b-button
              @click.prevent="addPayment"
              class
              variant="link"
              title="Add Payment"
              v-b-popover.hover.top.d500="'Add payment'"
            >
              <i class="fa fa-plus pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="reloadItems"
              class
              variant="link"
              title="Refresh Table"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="printTable"
              class
              variant="link"
              title="Print Table"
              v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
            >
              <i class="fa fa-print pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              title="Export Table"
              v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="editRowIndex == props.index">
              <span v-if="props.column.label=='Amount'">
                <input type="number" v-model="props.row.changeAmount" />
              </span>
              <span v-if="props.column.label=='Trigger'">
                <datepicker v-if="props.row.triggerType === 0" v-model="props.row.startDate" :disabledDates="disabledDates" />
                <input type="number" v-if="props.row.triggerType === 1" v-model="props.row.paymentsMade" />
              </span>
              <span v-if="props.column.label=='Applied'">
                <input type="checkbox" v-model="props.row.applied" :disabled="true" />
              </span>
              <span v-if="props.column.label=='Change Type'">
                <b-form-select
                  :options="changeTypes"
                  text-field="name"
                  value-field="value"
                  v-model="props.row.changeType"
                ></b-form-select>
              </span>
              <span v-if="props.column.label === 'Trigger Type'">
                <b-form-select
                  :options="triggerTypes"
                  text-field="name"
                  value-field="value"
                  v-model="props.row.triggerType"
                ></b-form-select>
              </span>
              <span v-if="props.column.label == ''">
                <input type="hidden" v-model="props.row.orderIndex" value="props.index" />
                <b-button variant="primary" @click="saveFpc(props)">
                  <i class="fa fa-save mr-1"></i>Save
                </b-button>
                <b-button variant="outline-warning" @click="cancelFpc(props)">
                  <i class="fa fa-times mr-1"></i>Cancel
                </b-button>
                <b-button
                  variant="outline-danger"
                  @click="deleteFpc(props)"
                  :disabled="props.row.applied"
                >
                  <i class="fa fa-times mr-1"></i>Delete
                </b-button>
              </span>
            </span>
            <span v-else>
              <span v-if="props.column.label=='Applied'">
                <input type="checkbox" v-model="props.row.applied" :disabled="true" />
              </span>
              <span v-else-if="props.column.label == ''">
                <span v-if="fpcRowLocked(props.row)">
                  <i class="fa fa-lock"></i>
                </span>
                <span v-if="!fpcRowLocked(props.row)">
                  <i class="fa fa-lock-open"></i>
                </span>
              </span>
              <span v-else-if="props.column.field == 'changeAmount'">{{formatValue(props.row)}}</span>
              <span
                v-else-if="props.column.field == 'changeType'"
              >{{ formatChangeType(props.row.changeType )}}</span>
              <span v-else-if="props.column.label == 'Trigger'">{{ props.row.triggerType === 0 ? formatFpcDate(props.row.startDate) : props.row.paymentsMade}}</span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </span>
          </template>
        </vue-good-table>
      </b-form-row>
    </section>
    <payer-ref-control v-if="templateView" v-model="plan.payerReferencePattern" />
    <b-form-row v-if="templateView">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Generate API Key">
        <b-input-group>
        <b-form-input
          id="apiKeyInput"
          type="text"
          class="form-control"
          v-model.number="apiKey"
          :disabled="true"
        />
        <template v-slot:append>
          <b-button variant="secondary" @click.prevent="generateApiKey">Get</b-button>
        </template>
        </b-input-group>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="templateView">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Allow Date Choice">
          <p-check
            id="dateChoiceCheck"
            class="p-switch p-fill"
            color="primary"
            v-model="plan.allowDateChoice"
            :disabled="!editable"
          ></p-check>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="templateView">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Date Range Allowed">
          <input type="number" v-model="plan.dateRangeAllowed" />
        </b-form-group>
      </b-form-row>
    <b-form-row v-if="!templateView && !createMode && plan.paymentPlanId !== '00000000-0000-0000-0000-000000000000'">
      <b-col>
        <router-link
          :to="{ name: 'PayerSchedule', params: { id: ukPayerId, planId: plan.paymentPlanId } }"
        >
          <!-- eslint-disable-line -->
          <b-button variant="outline-secondary" class="mt-3">
            <i class="fa fa-calendar rpad"></i>View Schedule
          </b-button>
        </router-link>
        <b-button v-if="showDeleteButton" @click="deletePlan" variant="danger" :disabled="plan.planStatus !== 0" class="mt-3 pull-right">
          <i class="fa fa-trash rpad"></i>Delete Plan
        </b-button>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import { decimal, required, numeric, helpers } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import PrettyCheck from 'pretty-checkbox-vue/check'
import Enum from '@/Enums/Collections'
import PayerRefControl from '@/Components/Collections/Payer/PayerReference'
import _ from 'lodash'
import axios from 'axios'
const positive = (val) => {
  return parseInt(val) > 0
}
const zeroOrPositive = (val) => {
  return parseInt(val) >= 0
}
const newerThanNow = (val) => {
  return val > new Date()
}
const sunValidator = helpers.regex('alpha', /^B\d{5}$|^\d{6}$/)
const nameNotInUse = function (val) { return !(this.templates.filter(x => x.name === this.plan.planTemplateName && x.paymentPlanTemplateId !== this.plan.planTemplateId).length > 0) }
export default {
  data () {
    return {
      firstPaymentDate: new Date(),
      futureChangeDate: new Date(),
      newAmount: 0,
      recurrenceType: Enum.Payer.RecurrenceType.enumValues,
      isValid: false,
      weekdays: [
        { name: 'Monday', value: 1 },
        { name: 'Tuesday', value: 2 },
        { name: 'Wednesday', value: 3 },
        { name: 'Thursday', value: 4 },
        { name: 'Friday', value: 5 }
      ],
      dayOfYear: new Date(),
      statae: [
        { name: 'Closed', value: 0 },
        // { name: 'Alert', value: 1 },
        { name: 'Suspended', value: 2 },
        { name: 'Operational', value: 3 }
      ],
      editRowIndex: -1,
      newRowIndex: -1,
      columns: [
        {
          label: 'Amount',
          field: 'changeAmount'
        },
        {
          label: 'Trigger',
          field: 'planId'
        },
        {
          label: 'Applied',
          field: 'applied'
        },
        {
          label: 'Change Type',
          field: 'changeType'
        },
        {
          label: 'Trigger Type',
          field: 'triggerType',
          formatFn: (value) => {
            return value === 0 ? 'After Date' : 'After X Payments Made'
          }
        },
        {
          label: '',
          field: 'control'
        }
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: 'surname',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      selectedRow: null,
      changeTypes: [
        { name: 'Replace old amount', value: 'NewValue' },
        { name: 'Numeric Change', value: 'NumericModifier' },
        { name: 'Percentage Change', value: 'PercentageModifier' }
      ],
      triggerTypes: [{ name: 'After Date', value: 0 }, { name: 'After X Payments', value: 1 }],
      apiKey: '',
      adviceMessage: '',
      showAdvice: false,
      showHeatMap: false,
      heatmapData: [],
      adivceLabelClass: '',
      heatmapVisible: false
    }
  },
  props: {
    open: Boolean,
    plan: {},
    ukPayerId: String,
    templateView: Boolean,
    createMode: Boolean,
    planClosed: Boolean,
    columnClass: { type: String, default: 'col-sm-6' },
    labelCols: { type: Number, default: 4 },
    showDeleteButton: { type: Boolean, default: false },
    insightFeedback: { type: Boolean, default: false },
    limitFirstPaymentDate: { type: Boolean, default: false },
    sharedTemplate: { type: Boolean, default: false }
  },
  async mounted () {
    if (this.$store.getters.collectionsAllGroups === undefined || this.$store.getters.collectionsAllGroups === null) {
      await this.$store.dispatch('populateAllCollectionsGroups')
    }
    if (this.$store.getters.planTemplateTree === undefined || this.$store.getters.planTemplateTree.length === 0) {
      await this.$store.dispatch('updateAvailablePlanTemplates', this.plan.groupId)
    }
    // this.dayOfYear = new Date(this.dayOfYear.getFullYear(), 0, 0)
    // var date = new Date(this.dayOfYear.getFullYear(), 0) // initialize a date in `year-01-01`
    this.initialize()
    // this.dayOfYear = new Date(date.setDate(this.plan.ordinal + 1))
    // this.plan.lastAmount = this.currencyFormatter(this.plan.lastAmount)
    // this.plan.regularAmount = this.currencyFormatter(this.plan.regularAmount)
    // this.plan.firstAmount = this.currencyFormatter(this.plan.firstAmount)
    if (this.plan.customerBankAccountId === undefined || this.plan.customerBankAccountId === null || this.plan.customerBankAccountId === '00000000-0000-0000-0000-000000000000') {
      this.plan.customerBankAccountId = this.defaultBankAccount
    }
    if (this.$store.getters.availableBankAccounts === undefined || this.$store.getters.availableBankAccounts === null || this.$store.getters.availableBankAccounts.length === 0) {
      await this.$store.dispatch('getAvailableBankAccounts')
    }
    this.buildValidationMessage()
    this.$v.$touch()
  },
  methods: {
    deletePlannedChange (index) {
      this.plan.futurePlanChanges.splice(index, 1)
      this.$forceUpdate()
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatCurrency (value) {
      var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(value)
    },
    dirtyTouchStep (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.plan.$invalid
      if (!isValid) {
        this.$v.plan.step.$model = 1
      }
      this.$store.dispatch('setCurrentPayerPlan', { plan: isValid })
    },
    dirtyTouch (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.plan.$invalid
      this.$store.dispatch('setCurrentPayerPlan', { plan: isValid })
    },
    dirtyTouchCurrency (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.plan.$invalid
      this.$store.dispatch('setCurrentPayerPlan', { plan: isValid })
    },
    async dirtyTouchYear () {
      var start = new Date(this.dayOfYear.getFullYear(), 0, 0)
      var diff = this.dayOfYear - start
      var oneDay = 1000 * 60 * 60 * 24
      var day = Math.floor(diff / oneDay)
      this.plan.ordinal = day
      this.$v.plan.ordinal.$touch()
      let isValid = !this.$v.plan.$invalid
      this.$store.dispatch('setCurrentPayerPlan', { plan: isValid })
    },
    navigateToSchedule () {
      this.$router.push({ name: 'PayerSchedule', params: { id: this.ukPayerId, planId: this.plan.paymentPlanId } })
    },
    reloadItems: async function () {

    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {
    },
    onSortChange (params) {
    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
    },
    onPerPageChange (params) {
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (!this.fpcRowLocked(params.row)) {
        if (params.column.field !== 'control') {
          this.oldVersion = _.clone(params.row)
          this.editRowIndex = params.rowIndex
        }
      }
    },
    onSearch (params) {
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    addPayment () {
      var newPayment = {
        ukddmsFuturePlanChangeId: '00000000-0000-0000-0000-000000000000',
        planTemplateId: '00000000-0000-0000-0000-000000000000',
        changeAmount: 0,
        startDate: new Date().toISOString(),
        applied: false,
        appliedDate: '',
        planId: this.plan.paymentPlanId,
        changeType: 'NewValue',
        orderIndex: this.plan.futurePlanChanges.length - 1
      }
      this.plan.futurePlanChanges.push(newPayment)
      this.editRowIndex = this.plan.futurePlanChanges.length - 1
      this.newRowIndex = this.editRowIndex
    },
    rowStyler (row) {
      return row.status === 10 && (row !== this.selectedRow) ? 'deleted' : ''
    },
    saveFpc (e) {
      console.log(e)
      this.editRowIndex = -1
      this.plan.futurePlanChanges[e.index] = e.row
    },
    cancelFpc (e) {
      this.editRowIndex = -1
      if (this.oldVersion) {
        e.row = this.oldVersion
        this.plan.futurePlanChanges[e.index] = this.oldVersion
      } else {
        this.plan.futurePlanChanges.splice(e.index, 1)
      }
    },
    deleteFpc (e) {
      this.editRowIndex = -1
      this.currentFpc.splice(e.index, 1)
    },
    fpcRowLocked (row) {
      return (row.planId === '00000000-0000-0000-0000-000000000000') || row.applied
    },
    formatChangeType (value) {
      if (!isNaN(value)) {
        return this.changeTypes[value].name
      } else {
        return this.changeTypes.find(f => f.value === value).name
      }
    },
    formatValue (row) {
      var val = ''
      switch (row.changeType) {
        case 0:
        case 'NewValue':
          val = this.formatCurrency(row.changeAmount)
          break
        case 1:
        case 'NumericModifier':
          val = row.changeAmount > 0 ? '+' + this.formatCurrency(row.changeAmount) : '-' + this.formatCurrency(row.changeAmount * -1)
          break
        case 2:
        case 'PercentageModifier':
          val = row.changeAmount + '%'
          break
      }
      return val
    },
    deletePlan () {
      this.$emit('planDeleted')
    },
    buildValidationMessage () {
      var fields = []
      if (this.$store.state.payer.currentPayer.paymentPlans.length === 0) {
        fields.push('No Plans Specified')
      }
      if (this.$v.reference) {
        fields.push('Plan Reference')
      }
      if (this.$v.planTemplateName) {
        fields.push('Plan Template Name')
      }
      if (this.$v.recurrenceEndType) {
        fields.push('Recurrence End Type')
      }
      if (this.$v.recurrenceType) {
        fields.push('Recurrence Type')
      }
      if (this.$v.firstPaymentDate) {
        fields.push('First Payment Date')
      }
      if (this.$v.firstAmount) {
        fields.push('First Amount')
      }
      if (this.$v.regularAmount) {
        fields.push('Regular Amount')
      }
      if (this.$v.lastAmount) {
        fields.push('Last Amount')
      }
      if (this.$v.ordinal) {
        fields.push('Ordinal')
      }
      if (this.$v.step) {
        fields.push('Step')
      }
      if (this.$v.totalPayments) {
        fields.push('Total Payments')
      }
      var message = fields.length > 0 ? `Invalid Plan Details: ${fields.join(', ')}` : ''
      this.$store.commit('setPlanValidationMessage', message)
    },
    async generateApiKey () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'plantemplate/apikey/' + this.plan.planTemplateId
      var result = await axios.post(url, {})
      this.apiKey = result.data
      navigator.clipboard.writeText(this.apiKey)
      this.$toastr.s('The API Key has been copied', 'API Key Copied')
    },
    reset () {
      this.$v.$reset()
    },
    async ordinalChanged () {
      this.dirtyTouch(this.$v.plan.ordinal)
    },
    async ordinalChangedWeekday () {
      this.dirtyTouch(this.$v.plan.ordinal)
    },
    ordinalSuffix (i) {
      var j = i % 10
      var k = i % 100
      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    },
    async recurrenceTypeChanged () {
      switch (this.plan.recurrenceType) {
        case 0:
          await this.ordinalChangedWeekday()
          break
        case 1:
          await this.ordinalChanged()
          break
        case 2:
          await this.dirtyTouchYear()
          break
      }
      this.plan.step = 1
      this.dirtyTouch(this.$v.plan.recurrenceType)
    },
    formatFpcDate (value) {
      if (value.getDate) {
        value = value.toISOString()
      }
      let retVal = value
      if (value.split) {
        retVal = value.split('T')[0]
      }
      return retVal
    },
    initialize () {
      var date = new Date()
      date.setHours(0, 0, 0, 0)
      if (this.plan.ordinal) {
        date.setMonth(0)
        date.setDate(0)
        var timeOfFirst = date.getTime() // this is the time in milliseconds of 1/1/YYYY
        var dayMilli = 1000 * 60 * 60 * 24
        var dayNumMilli = this.plan.ordinal * dayMilli
        date.setTime(timeOfFirst + dayNumMilli)
      }
      this.dayOfYear = date
    }
  },
  components: {
    Datepicker,
    pCheck: PrettyCheck,
    PayerRefControl
  },
  computed: {
    firstAmount: {
      get () {
        return this.plan.firstAmount ? this.plan.firstAmount : 0
      },
      set (value) {
        this.plan.firstAmount = value
      }
    },
    regularAmount: {
      get () {
        return this.plan.regularAmount ? this.plan.regularAmount : 0
      },
      set (value) {
        this.plan.regularAmount = value
      }
    },
    lastAmount: {
      get () {
        return this.plan.lastAmount ? this.plan.lastAmount : 0
      },
      set (value) {
        this.plan.lastAmount = value
      }
    },
    editable () {
      return this.$store.state.payer.currentPayer.editable
    },
    ordinalOpts () {
      var range
      switch (this.plan.recurrenceType) {
        case Enum.Payer.RecurrenceType.Weekly.ordinal:
          range = 5
          break
        case Enum.Payer.RecurrenceType.Yearly.ordinal:
          range = 365
          break
        case Enum.Payer.RecurrenceType.Monthly.ordinal:
        default:
          range = 28
          break
      }
      var retVal = []
      for (var i = 1; i <= range; i++) {
        retVal.push(i)
      }
      return retVal
    },
    stepDisabled () {
      return this.plan.recurrenceType === Enum.Payer.RecurrenceType.Yearly.ordinal
    },
    paymentType () {
      let output = []
      for (let i = 0; i < Enum.Payer.RecurrenceEndType.enumValues.length; i++) {
        let words = Enum.Payer.RecurrenceEndType.enumValues[i].name.match(/[A-Z][a-z]+/g)
        output.push({ name: words.join(' '), ordinal: Enum.Payer.RecurrenceEndType.enumValues[i].ordinal })
      }
      return output
    },
    disabledDates () {
      let maxDate = new Date(8640000000000000)
      if (this.limitFirstPaymentDate) {
        var now = new Date()
        maxDate = new Date(now.setMonth(now.getMonth() + 6))
      }
      let minDate = this.minDate
      return {
        days: [0, 6],
        daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    finalPaymentsDisabledDates () {
      let maxDate = new Date(8640000000000000)
      let minDate = this.minDate
      return {
        days: [0, 6],
        daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    minDate () {
      let minDate = new Date()
      minDate.setDate(minDate.getDate() + this.advancedNoticeWithMedia - 1)
      return minDate
    },
    isAdHoc () {
      return this.plan.recurrenceEndType === Enum.Payer.RecurrenceEndType.AdHoc.ordinal
    },
    advancedNoticeWithMedia () {
      var notice = 0
      if (!this.isShared && this.$store.getters.collectionsAllGroups !== null) {
        var settings = this.$store.getters.collectionsAllGroups[this.plan.groupId]
        // var groupConfig = this.$store.getters.collectionsGroupConfig
        if (settings.advanceNoticeInDays) {
          notice = notice + settings.advanceNoticeInDays
        }
        if (settings.defaultPayerMessageType === 1) {
          notice = notice + 5
        }
      } else {
        // assume email
        notice = notice + 5
      }
      var weekendDays = 0
      for (var i = 0; i < notice; i++) {
        if (i % 5 === 0) {
          weekendDays = weekendDays + 2
        }
      }
      notice = notice + weekendDays
      return notice
    },
    isNew () {
      return this.plan.paymentPlanId === '00000000-0000-0000-0000-000000000000'
    },
    steps () {
      var retVal = []
      switch (this.plan.recurrenceType) {
        case 0: // weekly
          retVal = []
          for (var i = 1; i <= 56; i++) {
            retVal.push(i)
          }
          break
        case 1: // monthly
          retVal = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13 ]
          break
        case 2: // annually
          retVal = [ 1 ]
          break
      }
      return retVal
    },
    numPaymentsRequired () {
      return this.plan.recurrenceEndType === 2
    },
    untilDateRequired () {
      return this.plan.recurrenceEndType === 1
    },
    templates () {
      return this.$store.getters.planTemplateTree !== undefined && this.$store.getters.planTemplateTree.length !== 0 ? this.$store.getters.planTemplateTree[this.plan.groupId] : []
    },
    planNameExists () {
      return this.templateView ? false : this.templates.filter(x => x.name === this.plan.planTemplateName && x.paymentPlanTemplateId !== this.plan.planTemplateId).length > 0
    },
    hasBureau () {
      return this.$store.getters.hasLicence('LicenceBureau')
    },
    bankAccounts () {
      return this.$store.getters.availableBankAccounts
    },
    isShared () {
      return this.plan.groupId === undefined || this.plan.groupId === '00000000-0000-0000-0000-000000000000'
    },
    defaultBankAccount () {
      var account = null
      if (this.isShared) {
        account = this.bankAccounts[0].id
      } else {
        var settings = this.$store.getters.collectionsAllGroups[this.plan.groupId]
        console.log(settings)
        account = settings.bacnkAccountId
      }
      return account
    },
    isBureauOrIndirect () {
      var retVal = false
      if (this.$store.getters.collectionsAllGroups !== undefined && this.$store.getters.collectionsAllGroups !== null) {
        var settings = this.$store.getters.collectionsAllGroups[this.plan.groupId]
        retVal = this.isShared ? false : (settings.submissionType !== 0)
      }
      return retVal
    }
  },
  watch: {
    $v: {
      handler (val) {
        this.buildValidationMessage()
        this.$emit('isinvalid', { valid: !this.$v.$anyError, plan: this.plan })
      },
      deep: true
    }
  },
  validations () {
    if (this.plan.variable) {
      return {
        plan: {
          reference: { required },
          planTemplateName: { required, nameNotInUse },
          recurrenceEndType: { positive },
          recurrenceType: { numeric },
          firstPaymentDate: !this.isNew || this.templateView ? {} : { Date, newerThanNow },
          firstAmount: { decimal, zeroOrPositive },
          regularAmount: { decimal, zeroOrPositive },
          lastAmount: { decimal, zeroOrPositive },
          ordinal: { numeric },
          step: { numeric, required, positive },
          giftAid: { },
          variable: { },
          planStatus: { required },
          totalPayments: this.numPaymentsRequired ? { required, positive } : {},
          indirectSun: this.isBureauOrIndirect ? { required, sunValidator } : {},
          untilDate: this.untilDateRequired ? { required, Date } : {}
        }
      }
    } else {
      return {
        plan: {
          reference: { required },
          planTemplateName: { required, nameNotInUse },
          recurrenceEndType: { positive },
          recurrenceType: { numeric },
          firstPaymentDate: !this.isNew || this.templateView ? {} : { Date, newerThanNow },
          firstAmount: { decimal, zeroOrPositive },
          regularAmount: { required, decimal, positive },
          lastAmount: { decimal, zeroOrPositive },
          ordinal: { numeric },
          step: { numeric, required, positive },
          giftAid: { },
          variable: { },
          planStatus: { required },
          totalPayments: this.numPaymentsRequired ? { required, positive } : {},
          indirectSun: this.isBureauOrIndirect ? { required, sunValidator } : {},
          untilDate: this.untilDateRequired ? { required, Date } : {}
        }
      }
    }
  }
}
</script>
<style>
#firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
