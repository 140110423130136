var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              _vm.checkValidation() && _vm.saveAccountDetails()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c(
                    "h2",
                    { attrs: { id: "title-of-block-check" } },
                    [
                      _vm._v(" Account Details "),
                      _c("help-icon", {
                        attrs: { docPath: "/common/accountsettings/" }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "idb-block-content" }, [
                  _vm.anyErrors
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger",
                          attrs: { role: "alert" }
                        },
                        _vm._l(_vm.errors, function(error) {
                          return _c("span", { key: error }, [
                            _vm._v(_vm._s(error))
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("div", { attrs: { id: "avatar-wrapper" } }, [
                            _c("img", {
                              staticClass: "avatar-image",
                              attrs: { src: _vm.avatar }
                            }),
                            _c(
                              "a",
                              {
                                attrs: { id: "change-picture" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeAvatar(true)
                                  }
                                }
                              },
                              [_vm._v("Change Avatar")]
                            )
                          ]),
                          _c("my-upload", {
                            attrs: {
                              field: "img",
                              value: _vm.show,
                              width: 300,
                              height: 300,
                              url: _vm.uploadURL,
                              headers: _vm.headers,
                              "img-format": "png",
                              langType: "en"
                            },
                            on: {
                              "crop-success": _vm.cropSuccess,
                              "crop-upload-success": _vm.cropUploadSuccess,
                              "crop-upload-fail": _vm.cropUploadFail,
                              "update:value": function($event) {
                                _vm.show = $event
                              },
                              input: _vm.changeAvatar
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-lg-9" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Password")]
                        ),
                        _c("div", { staticClass: "col-md-9" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-info",
                              attrs: {
                                type: "button",
                                disabled: _vm.isLoading
                              },
                              on: { click: _vm.changePassword }
                            },
                            [_vm._v("Change Password")]
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: { invalid: _vm.$v.emailAddress.$error }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3 required" },
                            [_vm._v("Email")]
                          ),
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.emailAddress,
                                  expression: "emailAddress"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "email", placeholder: "Email" },
                              domProps: { value: _vm.emailAddress },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.emailAddress = $event.target.value
                                }
                              }
                            }),
                            _vm.$v.emailAddress.$dirty
                              ? _c("span", [
                                  !_vm.$v.emailAddress.required
                                    ? _c(
                                        "small",
                                        {
                                          staticClass:
                                            "form-text text-danger small"
                                        },
                                        [_vm._v("An email address is required")]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.emailAddress.email
                                    ? _c(
                                        "small",
                                        {
                                          staticClass:
                                            "form-text text-danger small"
                                        },
                                        [
                                          _vm._v(
                                            "A valid email address is required"
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Phone Number")]
                        ),
                        _c("div", { staticClass: "col-md-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.phoneNumber,
                                expression: "phoneNumber"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel", placeholder: "Phone Number" },
                            domProps: { value: _vm.phoneNumber },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.phoneNumber = $event.target.value
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Dark Theme")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-9" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch",
                              staticStyle: { padding: "0.5rem 0" },
                              attrs: { name: "check", color: "default" },
                              model: {
                                value: _vm.darkTheme,
                                callback: function($$v) {
                                  _vm.darkTheme = $$v
                                },
                                expression: "darkTheme"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Default Group")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-9" },
                          [
                            _c("group-select", {
                              attrs: { groups: _vm.groups },
                              model: {
                                value: _vm.defaultGroup,
                                callback: function($$v) {
                                  _vm.defaultGroup = $$v
                                },
                                expression: "defaultGroup"
                              }
                            }),
                            _c(
                              "small",
                              {
                                staticClass: "form-text-text-muted",
                                attrs: { id: "helpBlock" }
                              },
                              [
                                _vm._v(
                                  "When used, this option automatically sets the selected group in various pages across the application"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Cookies")]
                        ),
                        _c("div", { staticClass: "col-md-9" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-info",
                              attrs: {
                                type: "button",
                                disabled: _vm.isLoading
                              },
                              on: { click: _vm.changeCookies }
                            },
                            [_vm._v("Change Cookie Settings")]
                          )
                        ])
                      ]),
                      this.canChangeViewUsageSettings
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("View Usage Settings")]
                            ),
                            _c("div", { staticClass: "col-md-9" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-info",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.isLoading
                                  },
                                  on: { click: _vm.changeUsageReportSettings }
                                },
                                [_vm._v("Change View Usage Settings")]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm.authenticatorLicence || _vm.emailLicence || _vm.smsLicence
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "idb-block" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "idb-block-content" }, [
                      _vm.emailLicence
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Email Confirmed")]
                            ),
                            _c("div", { staticClass: "col-md-9" }, [
                              _vm.emailConfirmed
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "form-control-static text-success"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-check",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(" Email Confirmed ")
                                    ]
                                  )
                                : _c("div", [
                                    _vm._m(1),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.hasEmailAndNotChanged,
                                            expression: "hasEmailAndNotChanged"
                                          }
                                        ],
                                        staticClass:
                                          "form-control-static text-warning"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-exclamation-triangle",
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                        _vm._v(
                                          " Please save your changes before confirming your new email "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning",
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.isLoading ||
                                            _vm.hasEmailAndNotChanged
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.verifyModal("Email")
                                          }
                                        }
                                      },
                                      [_vm._v("Confirm Email")]
                                    )
                                  ])
                            ])
                          ])
                        : _vm._e(),
                      _vm.smsLicence
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Phone Number Confirmed")]
                            ),
                            _c("div", { staticClass: "col-md-9" }, [
                              _vm.phoneNumberConfirmed &&
                              !_vm.hasPhoneNumberAndNotChanged
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "form-control-static text-success"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-check",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(" Phone Number ")
                                    ]
                                  )
                                : _c("div", [
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.saved.phoneNumber,
                                            expression: "saved.phoneNumber"
                                          }
                                        ],
                                        staticClass:
                                          "form-control-static text-warning"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-exclamation-triangle",
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                        _vm._v(
                                          " Phone Number has not been confirmed! "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.hasPhoneNumberAndNotChanged,
                                            expression:
                                              "hasPhoneNumberAndNotChanged"
                                          }
                                        ],
                                        staticClass:
                                          "form-control-static text-warning"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-exclamation-triangle",
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                        _vm._v(
                                          " Please save your changes before confirming your new phone number "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.saved.phoneNumber,
                                            expression: "!saved.phoneNumber"
                                          }
                                        ],
                                        staticClass:
                                          "form-control-static text-warning"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-exclamation-triangle",
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                        _vm._v(
                                          " Please enter a phone number and save your changes before confirming your phone number "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning",
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.isLoading ||
                                            _vm.hasPhoneNumberAndNotChanged
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.verifyModal("Phone")
                                          }
                                        }
                                      },
                                      [_vm._v("Confirm Phone Number")]
                                    )
                                  ])
                            ])
                          ])
                        : _vm._e(),
                      _vm.authenticatorLicence
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Authenticator Confirmed")]
                            ),
                            _c("div", { staticClass: "col-md" }, [
                              _vm.authenticatorConfirmed
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "form-control-static text-success"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-check",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(" Authenticator Confirmed "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-danger ml-2",
                                          attrs: { type: "button" },
                                          on: {
                                            click: _vm.unConfirmAuthenticator
                                          }
                                        },
                                        [_vm._v("Reset Authenticator")]
                                      )
                                    ]
                                  )
                                : _c("div", [
                                    _vm._m(2),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning confirm",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.isLoading
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.verifyModal(
                                              "Authenticator"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Confirm Authenticator")]
                                    )
                                  ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit", disabled: _vm.isLoading }
                    },
                    [_vm._v("Save Account Details")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger pull-right",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _c("confirm-modal", {
        attrs: { type: _vm.modalDetails.type },
        on: { submit: _vm.load }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { attrs: { id: "title-of-block-check" } }, [
        _vm._v("Two-Factor Authentication")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-control-static text-warning" }, [
      _c("i", {
        staticClass: "fa fa-exclamation-triangle",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" Email has not been confirmed! ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-control-static text-warning" }, [
      _c("i", {
        staticClass: "fa fa-exclamation-triangle",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" Authenticator has not been confirmed! ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }