var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade-long" } }, [
        !_vm.loading
          ? _c(
              "div",
              {
                staticClass: "app-container",
                class: {
                  "collapsed-sidebar": _vm.sidebarCollapse,
                  "dark-mode": _vm.darkModeEvent
                }
              },
              [
                _c("app-header", {
                  attrs: {
                    displayCustomerSelector: _vm.displayCustomerSelector,
                    displayHeader: _vm.displayHeader,
                    sidebarCollapsed: _vm.sidebarCollapse
                  }
                }),
                _c(
                  "div",
                  { staticClass: "sidebar-wrapper" },
                  [_c("app-sidebar-nav")],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "main-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "base-container",
                        attrs: { id: "base-container" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "inner-container",
                            attrs: { id: "inner-container" }
                          },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "fade", mode: "out-in" } },
                              [_c("router-view", { key: _vm.$route.fullPath })],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("cookie-message")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }