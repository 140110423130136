var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "manualBacsSubmissionBuilder" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Manual FPS Bureau Submission Builder "),
                _vm._m(0),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("group-select", {
                    attrs: {
                      groups: _vm.Groups,
                      clearable: false,
                      change: _vm.ChangeGroup(_vm.SelectedGroupId)
                    },
                    model: {
                      value: _vm.SelectedGroupId,
                      callback: function($$v) {
                        _vm.SelectedGroupId = $$v
                      },
                      expression: "SelectedGroupId"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.submissionReference.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.submissionReference,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.submissionReference.$model"
                    }
                  })
                ],
                1
              ),
              _vm.$v.submissionReference.$dirty
                ? _c(
                    "div",
                    { staticClass: "col-md-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.submissionReference.required
                        ? _c("label", { staticClass: "text-danger small" }, [
                            _vm._v("A 'Submission Reference' is required")
                          ])
                        : _vm._e(),
                      !_vm.$v.submissionReference.maxLength
                        ? _c("label", { staticClass: "text-danger small" }, [
                            _vm._v(
                              "A 'Submission Reference' must be less than " +
                                _vm._s(
                                  _vm.$v.submissionReference.$params.maxLength
                                    .max
                                ) +
                                " characters"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.contraNarrative.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.contraNarrative,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.contraNarrative.$model"
                    }
                  })
                ],
                1
              ),
              _vm.$v.contraNarrative.$dirty
                ? _c(
                    "div",
                    { staticClass: "col-md-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.contraNarrative.maxLength
                        ? _c("label", { staticClass: "text-danger small" }, [
                            _vm._v(
                              "A 'Contra Narrative' must be less than " +
                                _vm._s(
                                  _vm.$v.contraNarrative.$params.maxLength.max
                                ) +
                                " characters"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { disabled: _vm.$v.$invalid },
                on: { click: _vm.onCreateClick }
              },
              [_vm._v("Create Submission")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Select a Submission Group")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c(
        "label",
        { staticClass: "required", attrs: { for: "submissionReference" } },
        [_vm._v("Submission Reference")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "contraNarrative" } }, [
        _vm._v("Contra Narrative")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }