var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm._m(0),
      _c("hr"),
      _c(
        "VuePerfectScrollbar",
        { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
        [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _vm.rows.length > 0
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v("Reference")])
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v("Processing Date")])
                      ]),
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("strong", [_vm._v("Files")])
                      ]),
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("strong", [_vm._v("Status")])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.rows, function(row) {
                return _c(
                  "b-row",
                  { key: row.bureauSubmissionId },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onViewPaymentsClick(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.submissionReference))]
                      )
                    ]),
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            row.processingDate,
                            "Do MMMM YYYY"
                          )
                        )
                      )
                    ]),
                    _c("b-col", { attrs: { cols: "2" } }, [
                      _vm._v(_vm._s(row.numberOfFiles))
                    ]),
                    _c("b-col", { attrs: { cols: "2" } }, [
                      _vm._v(_vm._s(row.status))
                    ])
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c("div", { staticClass: "col-md-12" }, [
            _vm.rows.length == 0
              ? _c("span", [
                  _vm._v(" You do not currently have any pending bureau jobs ")
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("Pending Bureau Jobs")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }